import { React, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    InputLabel,
    MenuItem,
    Stack,
    Backdrop,
    Snackbar,
    Alert,
} from "@mui/material";
import {
    Add,
    BorderColorOutlined
} from "@mui/icons-material";

import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import ButtonControla from "../../components/buttons/buttonController.jsx";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import SelectControla from "../../components/selects/selectControla.jsx";

import { useLocation } from "react-router-dom";
import MyUserAdmin from "../../api/MyUserAdmin.jsx";
import MyUsers from "../../api/MyUsers.jsx";
import ReturnBack from "../../components/buttons/returnBackControla.jsx";

const purple = "#8B8BD8";

function UserCompany() {
    const location = useLocation();
    const nameCompany = location.state.name_company;
    const idCompany = location.state.id_companies;

    //Message Alert
    const [stateAlert, setStateAlert] = useState({
        openAlert: false,
        vertical: "top",
        horizontal: "center",
        severityAlert: "success",
        messageAlert: "",
    });

    const { severityAlert, messageAlert, vertical, horizontal, openAlert } = stateAlert;

    const handleOpenAlert = (severityAlert, messageAlert) => {
        setStateAlert({
            openAlert: true,
            vertical: "top",
            horizontal: "right",
            severityAlert: severityAlert,
            messageAlert: messageAlert,
        });
    }

    const handleCloseAlert = () => {
        setStateAlert({ ...stateAlert, openAlert: false });
    }
    //

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    }
    //modal de confirmación
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");

    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm, id) => {
        setOpenModalConfirm(true);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setId(id);
    }
    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }


    const typeDocument = [{ id_type: 1, name_type_document: "L.E / DNI" },
    { id_type: 2, name_type_document: "CARNET DE EXTRANJERÍA" },
    { id_type: 3, name_type_document: "PASAPORTE" },
    { id_type: 4, name_type_document: "RUC" }]

    /*modal*/
    const [openModalUser, setOpenModalUser] = useState(false);
    const [buttonActionType, setButtonActionType] = useState("");
    const [id, setId] = useState("");

    const handleOpenModalUser = (buttonActionType, user) => {
        setOpenModalUser(true);
        setId(user.id_users);

        if (buttonActionType === "editar") {
            setNameUser(user.first_name);
            setLastnameUser(user.last_name);
            setTypeDocumentSelected(user.id_users_documents_types);
            setNroDocument(user.document);
            setEmail(user.email);
            setPhone(user.phone);

        } else {
            //limpiar
            setNameUser("");
            setLastnameUser("");
            setTypeDocumentSelected("");
            setNroDocument("");
            setEmail("");
            setPhone("");

        }


        setButtonActionType(buttonActionType);


    }
    const handleCloseModalUser = () => {
        setOpenModalUser(false);

    }

    const handleActionModal = (event) => {
        switch (buttonActionType) {
            case "generar":
                handlePostUserByCompany(event);
                break;
            case "editar":
                handleUpdateUserByCompany(event);
                break;
        }

    }

    const [nameUser, setNameUser] = useState("");
    const [lastnameUser, setLastnameUser] = useState("");
    const [typeDocumentSelected, setTypeDocumentSelected] = useState("");
    const [NroDocument, setNroDocument] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const [user, setUser] = useState([])

    const handleGetUserByCompany = async () => {
        setOpenBackdrop(true);
        const resp = await MyUserAdmin.getUserByCompany(idCompany);
        const userByCompany = resp.data || [];
        setUser(userByCompany);

        handleCloseBackdrop();
    }

    const handlePostUserByCompany = async (event) => {
        event.preventDefault();

        setOpenBackdrop(true);
        const data = {
            "first_name": nameUser,
            "last_name": lastnameUser,
            "id_users_genders": null,
            "phone": phone,
            "id_users_documents_types": typeDocumentSelected,
            "document": NroDocument,
            "birthday": null,
            "email": email,
            "id_positions": null,
            "disability": null,
            "sctr": null,
            "unionized": null,
            "id_job_types": null,
            "start_date": null,
            "end_date": null,
            "id_health_entity": null,
            "id_companies": idCompany
        }

        const resp = await MyUserAdmin.postUserByCompany(data);

        if (resp !== undefined) {
            handleGetUserByCompany();
            handleOpenAlert("success", "Se activó al usuario con éxito");

        } else {
            handleOpenAlert("error", "Ocurrió un error");
        }

        handleCloseBackdrop();
        handleCloseModalUser();

    }

    const handleUpdateUserByCompany = async (event) => {
        event.preventDefault();

        setOpenBackdrop(true);

        const resp = await MyUsers.updateUsers({
            idUsers: id,
            first_name: nameUser,
            last_name: lastnameUser,
            phone: phone,
            id_documents_types: typeDocumentSelected,
            document: NroDocument,
            email: email,
            idCompany: idCompany
        });

        if (resp.success === true) {
            handleOpenAlert("success", "Se actualizó el registro con éxito");
            handleGetUserByCompany();

        } else {
            handleCloseAlert("error", "Ocurrió un error");
        }

        handleCloseBackdrop();
        handleCloseModalUser();

    }
    const handleDeleteUserByCompany = async () => {
        setOpenBackdrop(true);
        const resp = await MyUsers.deleteUser({
            idUsers: id,
        });

        if (resp.success === true) {
            handleOpenAlert("success", resp.message);
            handleGetUserByCompany();

        } else {
            handleCloseAlert("error", "Ocurrió un error");
        }

        handleCloseBackdrop();
        handleCloseModalConfirm();

    }

    useEffect(() => {
        handleGetUserByCompany();
    }, [])


    return (
        <Box >
            <ReturnBack/>
            
            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleDeleteUserByCompany}
            />

            {/* componente Alert */}
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={3000}
            >
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleCloseAlert();
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity={severityAlert}
                    size="small"
                >
                    {messageAlert}
                </Alert>
            </Snackbar>

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{ marginTop: 3, marginBottom: 1 }}
                    >
                        <Box>
                            <InputLabel
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                {nameCompany} - USUARIO
                            </InputLabel>
                        </Box>
                        <Box>
                            {user.length === 0 ?
                                (
                                    <ButtonControla
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Generar usuario"}
                                        functionOnClick={() => {
                                            handleOpenModalUser(
                                                "generar",
                                                ""
                                            )
                                        }}
                                    />
                                ) : ""}

                        </Box>
                    </Stack>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item md={12}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color: "#1638F2",
                                                    fontSize: "18px",
                                                    width: "14%",
                                                }}
                                            >
                                                Nombres
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color: "#1638F2",
                                                    fontSize: "18px",
                                                    width: "14%",
                                                }}
                                            >
                                                Apellido
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color: "#1638F2",
                                                    fontSize: "18px",
                                                    width: "14%",
                                                }}
                                            >
                                                Email
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color: "#1638F2",
                                                    fontSize: "18px",
                                                    width: "14%",
                                                }}
                                            >
                                                Acciones
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            user.map((u) => (
                                                <TableRow
                                                    hover
                                                    key={u.id_users}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {u.first_name}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {u.last_name}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {u.email}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignContent="center"
                                                        >
                                                            <ButtonIconControla
                                                                icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                                                backgroundColor={"#2D9CDB"}
                                                                backgroundColorHover={"#33AEF4"}
                                                                textTooltip={"Editar"}
                                                                functionOnClick={() => {
                                                                    handleOpenModalUser("editar", u);
                                                                }}
                                                            />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>

                                </Table>

                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            {/* modal de creación de usuario */}
            <Dialog
                open={openModalUser}
                onClose={handleCloseModalUser}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitleControla
                    titleText={"Agregar usuario"}
                    functionOnClose={handleCloseModalUser}
                />
                <form onSubmit={handleActionModal}>
                    <DialogContent>
                        <InputControlaVertical
                            text={"Nombre"}
                            inputType={"text"}
                            inputValue={setNameUser}
                            modalType={true}
                            value={nameUser || ''}
                            required
                        />
                        <InputControlaVertical
                            text={"Apellido"}
                            inputType={"text"}
                            inputValue={setLastnameUser}
                            modalType={true}
                            value={lastnameUser || ''}
                            required
                        />
                        <SelectControla
                            text="Tipo documento:"
                            inputValue={setTypeDocumentSelected}
                            modalType={true}
                            required
                            value={typeDocumentSelected || ''}
                            childrenRows={typeDocument.map((rows) => (
                                <MenuItem key={rows.id_type} value={rows.id_type}>
                                    {rows.name_type_document}
                                </MenuItem>
                            ))}
                        />
                        <InputControlaVertical
                            text={"N° de documento"}
                            inputType={"number"}
                            inputValue={setNroDocument}
                            modalType={true}
                            value={NroDocument || ''}
                            required
                        />
                        <InputControlaVertical
                            text={"Email"}
                            inputType={"text"}
                            inputValue={setEmail}
                            modalType={true}
                            value={email || ''}
                            required
                        />
                        <InputControlaVertical
                            text={"Celular / Teléfono"}
                            inputType={"number"}
                            inputValue={setPhone}
                            modalType={true}
                            value={phone || ''}
                            required
                        />

                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={handleCloseModalUser}
                                />
                            </Box>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={"Guardar"}
                                    typeButton={"submit"}
                                />
                            </Box>
                        </Stack>
                    </DialogActions>

                </form>

            </Dialog>

            {/* mostrar loading  */}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 9999,
                }}
                open={openBackdrop}
            >
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>
        </Box>

    );

}

export default UserCompany;