import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";

import {
    BorderColorOutlined,
    Delete
} from "@mui/icons-material";

function ListOcurrences(props) {
    const {
        comments,
        colorCell,
        idUserLogged,
        handleOpenModal,
        handleOpenModalConfirm
    } = props
    return (
        <TableBody>
            {comments.map((c, index) => (
                <TableRow>
                    <TableCell sx={{ color: colorCell }}
                        component="th"
                        scope="row"
                        align="center">
                        {index + 1}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }} component="th"
                        scope="row" align="center">
                        {c.description}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }} component="th"
                        scope="row" align="center">
                        {c.created_date}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }} component="th"
                        scope="row" align="center">
                        {c.first_name + " " + c.last_name}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }} component="th"
                        scope="row" align="center">
                        {
                            idUserLogged == c.id_users_created ? (
                                <Stack
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                >

                                    <ButtonIconControla
                                        icon={
                                            <BorderColorOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#169073"}
                                        textTooltip={"Editar"}
                                        functionOnClick={() => { handleOpenModal("editar", c) }}
                                    />
                                    <ButtonIconControla
                                        icon={<Delete sx={{ color: "white" }} />}
                                        backgroundColor={"#EB5757"}
                                        backgroundColorHover={"#EB5757"}
                                        textTooltip={"Eliminar"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                "¿Estás seguro de eliminar este comentario?",
                                                "Una vez eliminado no podrás recuperar el comentario",
                                                "Sí, eliminar",
                                                c
                                            )
                                        }
                                        }
                                    />

                                </Stack>

                            ) : ""
                        }

                    </TableCell>

                </TableRow>
            ))}
        </TableBody>
    )


}
export default ListOcurrences;