import MyConfigurationApi from "../MyConfigurationApi";

function getContractorsEmployees(id_contractor) {
    const url = 'contractors-employees/' + id_contractor;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: 'GET',
        url: url
    });
}

function getContractorEmployeeById(id) {
    const url = 'contractors-employees/employees/' + id;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: 'GET',
        url: url
    });
}

// * Para buscar los empleados de los contratistas selecciondos en el select box
function getContractorsEmployeesByIdContractor(ids_contractor) {
    const url = 'contractors-employees/contractors/' + ids_contractor;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: 'GET',
        url: url
    });
}

// * Para obtener los empleados de los contratistas accidentados
function getContractorsEmployeesByIdRegister({ id_register, register }) {
    const url = 'contractors-relations-employees/registers?register=' + register + '&id_register=' + id_register;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: 'GET',
        url: url
    });
}

function registerEmployee(data) {
    const url = 'contractors-employees';
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: 'POST',
        url: url
    });
}

function updateEmployee(id_employee, data) {
    const url = 'contractors-employees/' + id_employee;
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: 'PUT',
        url: url
    });
}

function deleteEmployee(id_employee) {
    const url = 'contractors-employees/' + id_employee;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: 'DELETE',
        url: url
    });
}

const MyContractorsEmployees = {
    getContractorsEmployees,
    getContractorEmployeeById,
    getContractorsEmployeesByIdContractor,
    getContractorsEmployeesByIdRegister,
    registerEmployee,
    updateEmployee,
    deleteEmployee
};

export default MyContractorsEmployees;