import { React, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { Button, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { Add } from '@mui/icons-material';
import SubtitleText from '../../components/text/subtitleText';
import TitleText from '../../components/text/titleText';
import BannerControla from '../../components/cards/bannerControla';
import ButtonControla from '../../components/buttons/buttonController';
import MyCommitteeElectionProcess from "../../api/MyCommitteeElectionProcess";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import ReturnBack from "../../components/buttons/returnBackControla";

const grey = "#9191B0";

const StyledEnterButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: "#2D9CDB",
    color: "white",
    width: "70%"
});
const colorTitle = "#9191B0";


function Index() {

    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    // Dialogos de confirmacion INICIO

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");

    const handleOpenModalConfirm = (
        id,
        titleText,
        contentText,
        buttonTextConfirm,
        buttonActionType
    ) => {
        // setIdPrograms(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    };

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    };

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case "startProcess":
                handleStoreProcessCompanies();
                break;
            case "trabajador":
                // updatestatusesPrograms(2);
                break;
        }
        setOpenModalConfirm(false);
    };

    const handleGetProcessCompanies = async () => {
        handleOpenLoading();
        const response = await MyCommitteeElectionProcess.getProcessActives();

        if (response === null) {
            handleOpenAlert('No hay procesos en curso', 'success');
        } else {
            window.location.href = '/committee/electionProcess/process?idProcess=' + response.id_committee_process;
            handleOpenAlert('Tienes un proceso en curso', 'success');
        }
    };

    const companyData = MyConfigurationApi.companyData();
    const companyName = companyData.name;

    useEffect(() => {
        handleGetProcessCompanies();
    }, []);

    const handleStoreProcessCompanies = async () => {
        handleOpenLoading();
        const response = await MyCommitteeElectionProcess.storeProcessCompanies();
        if (response.success === true) {
            window.location.href = '/committee/electionProcess/process?idProcess=' + response.id_committee_process;
        } else {
            handleOpenAlert(response.msg, 'error');
        }
    };

    return (
        <>
            <Box sx={{ height: '100%' }}>
                <ReturnBack returnBack="/committee"/>
                <LoadingControla
                    openLoading={openLoading}
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                    messageAlert={messageAlert}
                    typeAlert={typeAlert}
                />
                <DialogConfirmControla
                    openModalConfirm={openModalConfirm}
                    handleCloseModalConfirm={handleCloseModalConfirm}
                    titleText={titleText}
                    contentText={contentText}
                    buttonTextConfirm={buttonTextConfirm}
                    handleActionConfirm={handleActionConfirm}
                />
                <Container>

                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container sx={{ marginBottom: '15px' }}>
                            <Grid item xs={6} sm={5} md={6} lg={6} xl={12}>
                                <SubtitleText
                                    text={"Comité"}
                                    color={grey}>
                                </SubtitleText>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <BannerControla
                                    text={'Proceso de elecciones'}
                                    image={"/assets/images/committee/bannerVotaciones.png"}
                                />

                            </Grid>
                        </Grid>

                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Box sx={{ maxWidth: '500px', margin: 'auto', textAlign: 'center' }}>
                                    <TitleText text={"¡Bienvenido " + companyName + "!"} color={colorTitle}></TitleText>
                                    <p>
                                        A continuación comenzaremos con el proceso de elecciones. Gracias a él podrás gestionar y administrar las pasos a seguir en tu comité.
                                    </p>
                                    <img src="/assets/images/committee/continueProcess.png" style={{ maxWidth: '400px', maxHeight: '400px', marginBottom: '15px' }} />
                                    <ButtonControla
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Comenzar ahora"}
                                        functionOnClick={() => { handleOpenModalConfirm(1, 'Iniciar proceso', '¿Estas seguro de iniciar un proceso de elecciones para elegir a tu nuevo comité?', 'Sí, iniciar', 'startProcess'); }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
                </Container >
            </Box >
        </>
    )
}

export default Index