import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function consultationRecieve() {
  const url = "mailbox/consultations/receive/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function consultationSent() {
  const url = "mailbox/consultations/sent/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}
function getSubjectsConsults() {
  const url = "consultations";
  return MyConfigurationApi.connectFormApiGeneral({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function getSubjects() {
  const url = "mailbox/reports/themes/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function updateConsultationAnswer({
  idConsultations,
  idPrioritiesReceiving,
  answer,
  document_url
}) {
  if (document_url !== null && document_url !== undefined) {
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();
    document_url = await uploadFileCompanies(document_url, 'mailbox/consultations/' + year + '/' + month + '/' + day + '/');

}

  const url = "mailbox/consultations/update/" + idConsultations;
  const data = {
    id_priorities_receiving: idPrioritiesReceiving,
    answer: answer,
    document_url:document_url
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function updateConsultationStatus({ idConsultations, idStatus }) {
  const url = "mailbox/consultations/update/statuses/" + idConsultations;
  const data = {
    id_consultations_statuses: idStatus,
  };

  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

async function storeConsultations({
  idUsersReceiving,
  consultation,
  idPrioritiesSender,
  idConsultationsTheme,
  document_url
}) {
  if (document_url !== null && document_url !== undefined) {
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();
    document_url = await uploadFileCompanies(document_url, 'mailbox/consultations/' + year + '/' + month + '/' + day + '/');

}
  const url = "mailbox/consultations/store";
  const data = {
    id_users_receiving: idUsersReceiving,
    consultation: consultation,
    id_priorities_sender: idPrioritiesSender,
    id_consultations_theme: idConsultationsTheme,
    document_url:document_url
  };
  console.log(data);
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// dowloand csv

function getDowloandConsultations() {
  const url = "mailbox/consultations/receive/download/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function getDowloandReports() {
  const url = "mailbox/reports/receive/download/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function getDowloandCongratulations() {
  const url = "mailbox/congratulations/receive/download/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// get mailbox unido

function getMailBoxConsultations() {
  const url = "mailbox/consultations/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

const MyMailbox = {
  consultationRecieve,
  updateConsultationAnswer,
  updateConsultationStatus,
  consultationSent,
  getSubjects,
  storeConsultations,
  getSubjectsConsults,

  // dowloand
  getDowloandConsultations,
  getDowloandReports,
  getDowloandCongratulations,

  getMailBoxConsultations
};

export default MyMailbox;
