import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    Typography,
} from '@mui/material';
import DialogTitleControla from "./dialogTitleControla";

function DialogInformativeControla({open, onClose ,title = "Aviso",message, imageLogo = "/assets/images/publicidad.png" }) {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitleControla
                titleText={title}
                functionOnClose={onClose}
                image ={imageLogo}
            />
            <DialogContent sx={{ paddingTop: "0" }}>
                <Typography
                    sx={{
                        fontSize: '18px',
                        color: "#1638F2",
                        textAlign: "center" 
                    }}
                >
                   {message}
                </Typography>

            </DialogContent>

        </Dialog>
    );

}

export default DialogInformativeControla;
