import MyConfigurationApi from "./MyConfigurationApi";

function getRegistersSecurityEmergencyEquipment() {
  const url = "registers_general_security_emergency_equipment";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createRegistersSecurityEmergencyEquipment({ code }) {
  const url = "registers_general_security_emergency_equipment";
  const data = {
    code: code,
    status: "Creado",
    id_registers_security_emergency_equipment_type:'',
    id_registers_security_emergency_equipment_status:''    
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// // typos de equipos
function getTypeEquipment() {
  const url = "security_emergency_equipment_type";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// // actualizar 

async function updateRegistersSecurityEmergencyEquipment({
  idRegistersSecurityEmergencyEquipment,
  code,
  id_registers_security_emergency_equipment_type,
  register_status
}) {
  const url = "registers_general_security_emergency_equipment/" + idRegistersSecurityEmergencyEquipment;
  const data = {
    code: code,
    id_registers_security_emergency_equipment_type:id_registers_security_emergency_equipment_type,
    register_status:register_status   
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function editRegistersSecurityEmergencyEquipment({ idRegistersSecurityEmergencyEquipment }) {
  const url = "registers_general_security_emergency_equipment/" + idRegistersSecurityEmergencyEquipment;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}


// // EQUIPOS
// // registro 
async function createEquipment({ idRegistersSecurityEmergencyEquipment, name, description}) {
  const url = "sec/security_emergency_equipment_add_equipment/" + idRegistersSecurityEmergencyEquipment;
  const data = {   
    "name":name,
    "description":description   
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// // jalar registros 
function getEquipments({ idRegistersSecurityEmergencyEquipment }) {
    const url = "sec/security_emergency_equipment_add_equipment/" + idRegistersSecurityEmergencyEquipment;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "GET",
      url: url,
    });
  }

//   // eliminar 
  async function deleteWEquipment({ idEquipment }) {
    const url ="sec/security_emergency_equipment_add_equipment/detail/"+ idEquipment;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }

// TRABAJADOR INVOLUCRADO
// registro de trabajadores involucrados
async function createWorkersInvolved({ idRegistersSecurityEmergencyEquipment, id_users, area, date_renewal, date_delivery, nameResponsibleInput,responsibleDocumentInput}) {
  const url = "sec/security_emergency_equipment_workers_involved/" + idRegistersSecurityEmergencyEquipment;
  const data = {   
    area: area,
    date_renewal: date_renewal,
    date_delivery:date_delivery,
    id_users: id_users,
    name_user_responsible:nameResponsibleInput,
    document_user_responsible:responsibleDocumentInput,
    firm:0,
    firm_date:''    
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// jalar registros de trabajadores involucrados
function getWorkersInvolved({ idRegistersSecurityEmergencyEquipment }) {
    const url = "sec/security_emergency_equipment_workers_involved/" + idRegistersSecurityEmergencyEquipment;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "GET",
      url: url,
    });
  }

  // eliminar trabajador involucrado
  async function deleteWorkerInvolved({ idWorkerInvolved }) {
    const url ="sec/security_emergency_equipment_workers_involved/detail/"+ idWorkerInvolved;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }
// // // mandar para la firma
async function updateSignWorkerInvolved({idResponsible, id_users}) {  
    const url = "sec/security_emergency_equipment_workers_involved/detail/" + idResponsible;

    const data = {  
      "id_users": id_users,
      "firm": 1,
      "firm_date": new Date()
    };
    return MyConfigurationApi.connectFormApi({
      dataJson: data,
      method: "PUT",
      url: url,
    });
  }
  
// TRABAJADORES RESPONSABLES
//get de trabajadores responsables
function getResponsibleWorkers({ idRegistersSecurityEmergencyEquipment }) {
  const url = idRegistersSecurityEmergencyEquipment +"/responsible";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// // // crear trabajadores responsables
async function createResponsibleWorkers({
  idRegistersSecurityEmergencyEquipment,
  id_users
}) {
  const url = idRegistersSecurityEmergencyEquipment +"/responsible";
  const data = {
    id_users: id_users,
    firm: 0,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}


// // mandar para la firma
async function updateResponsibleWorkers({idResponsible, id_users}) {  

  const url = "responsible/detail/" + idResponsible;

  const data = {  
    "id_users": id_users,
    "firm": 1,
    "firm_date": null
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

//   // eliminar medida correctiva
  async function deleteResponsibleWorker({ idResponsible }) {
    const url ="responsible/detail/"+ idResponsible;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }

// cierre de registro
async function updateRegistersSecurityEmergencyEquipmentStatus({idRegistersSecurityEmergencyEquipment}) {  
  const url = idRegistersSecurityEmergencyEquipment+"/status/end";   
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "PUT",
    url: url,
  });
}
const MySecurityEmergencyEquipment = {
    getRegistersSecurityEmergencyEquipment,
    createRegistersSecurityEmergencyEquipment,
    getTypeEquipment,
    updateRegistersSecurityEmergencyEquipment,
    editRegistersSecurityEmergencyEquipment,

    // agregar equipos
    createEquipment,
    getEquipments,
    deleteWEquipment,

    // trabajador involucrado
    createWorkersInvolved,
    getWorkersInvolved,
    deleteWorkerInvolved ,
    updateSignWorkerInvolved,

  // responsable
  getResponsibleWorkers,
  createResponsibleWorkers,
  deleteResponsibleWorker,
  updateResponsibleWorkers,

  // cierre de registro
  updateRegistersSecurityEmergencyEquipmentStatus
};
export default MySecurityEmergencyEquipment;
