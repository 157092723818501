import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function pets({ year }) {
    const url = 'pets/get?year=' + year;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

async function createPets({ id_pets_statuses, code, version, name, document_url, model }) {
    if (document_url !== null && document_url !== undefined) {
        const current = new Date();
        const year = current.getFullYear();
        const month = current.getMonth() + 1;
        const day = current.getDate();
        document_url = await uploadFileCompanies(document_url, 'documentation/documents/procedures/pets/' + year + '/' + month + '/' + day + '/');
    
    }
    const url = 'pets/store';
    const data = {
        id_pets_statuses: id_pets_statuses,        
        name: name,
        code: code,
        version: version,
        document_url: document_url,
        model: model,
        // 'archived': '0'
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function searchPets({ id_pets }) {
    const url = 'pets/edit/' + id_pets;
    //const url = 'pets/editpets?id_pets='+id_pets;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

async function editPets({ 
    id_pets,
    name, 
    area,
    code, 
    version, 
    staff, 
    tools, 
    procedure, 
    restriction, 
    observation, 
    id_users_revisions,
    users_revisions_firm,
    id_users_prepare,
    users_prepare_firm,
    id_users_review_1,
    users_review_1_firm,
    id_users_review_2,
    users_review_2_firm,
    id_users_approve,
    users_approve_firm,
    document_url
 }) {
    if (document_url !== null && document_url !== undefined) {
        const current = new Date();
        const year = current.getFullYear();
        const month = current.getMonth() + 1;
        const day = current.getDate();
        document_url = await uploadFileCompanies(document_url, 'documentation/documents/procedures/pets/' + year + '/' + month + '/' + day + '/');
    }
    const url = 'pets/update/' + id_pets;
    const data = {
        name:name, 
        area:area,
        code:code, 
        version:version, 
        staff:staff, 
        tools:tools, 
        procedure:procedure, 
        restriction:restriction, 
        observation:observation, 
        id_users_revisions:id_users_revisions,
        users_revisions_firm:users_revisions_firm,
        id_users_prepare:id_users_prepare,
        users_prepare_firm:users_prepare_firm,
        id_users_review_1:id_users_review_1,
        users_review_1_firm:users_review_1_firm,
        id_users_review_2:id_users_review_2,
        users_review_2_firm:users_review_2_firm,
        id_users_approve:id_users_approve,
        users_approve_firm:users_approve_firm,
        document_url:document_url

    };

    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });

}

function deletePets({ id_pets }) {
    const url = 'pets/delete/' + id_pets;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

// creado 1 - publicado 2      no existe ->    enviado a trabajadores 2 - publicado 3 - anulado 4
function updatestatusesPets({ id_pets, id_pets_statuses }) {
    const url = 'pets/updatestatuses/' + id_pets;
    const data = {
        'id_pets_statuses': id_pets_statuses
    };

    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function updatearchivedPets({ id_pets }) {
    const url = 'pets/updatearchived/' + id_pets;
    const data = {
        'archived': 1
    };

    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function updateunarchivedPets({ id_pets }) {
    const url = 'pets/updatearchived/' + id_pets;
    const data = {
        'archived': 0
    };

    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

// usar modelo pets --> EPPS
function eppsStore({ id_pets, id_epps }) {
    const url = 'pets/epps/store/' + id_pets;
    const data = {
        'id_epps': id_epps,
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function eppsPetsGet({ id_pets }) {
    const url = 'pets/epps/get/' + id_pets;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function eppsNoPetsGet({ id_pets }) {
    const url = 'pets/epps/get/epps/' + id_pets;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function eppsDelete({ id_pets_epps }) {
    const url = 'pets/epps/delete/' + id_pets_epps;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

// Usar modelo pets
async function storePetsModel({ id_pets }) {
    const url = 'pets/store/pets/model/' + id_pets;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'POST', url: url });
}

// firmar preparado por
function signPreparedBy({ id_pets, id_users_prepare, date_prepare }) {
    const url = 'pets/update/prepare/' + id_pets;
    const data = {
        id_users_prepare:id_users_prepare,
        date_prepare:date_prepare
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

// firmar review1 por
function signReview1By({ id_pets, id_users_review_1, date_review_1 }) {
    const url = 'pets/update/review1/' + id_pets;
    const data = {
        id_users_review_1:id_users_review_1,
        date_review_1:date_review_1
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

// firmar review2 por
function signReview2By({ id_pets, id_users_review_2, date_review_2 }) {
    const url = 'pets/update/review2/' + id_pets;
    const data = {
        id_users_review_2:id_users_review_2,
        date_review_2:date_review_2
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

// firmar aprobado por
function signApprovedBy({ id_pets, id_users_approve, date_approve }) {
    const url = 'pets/update/approve/' + id_pets;
    const data = {
        id_users_approve:id_users_approve,
        date_approve:date_approve
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function generatePDF({ id_pets }) {
    const url = 'pets/update/generate/pdf/' + id_pets;
    return MyConfigurationApi.connectApiPDF({ dataJson: null, method: 'GET', url: url });
}


const MyPets = {
    pets, 
    createPets, 
    editPets, 
    searchPets, 
    deletePets, 
    updatestatusesPets, 
    updatearchivedPets, 
    updateunarchivedPets,

    // epps
    eppsStore,
    eppsPetsGet,
    eppsNoPetsGet,
    eppsDelete,

    // modelo
    storePetsModel,

    // firmar
    signPreparedBy,
    signReview1By,
    signReview2By,
    signApprovedBy,

    //generar pdf
    generatePDF

}

export default MyPets;