import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputBase,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  PictureAsPdf,
} from "@mui/icons-material";
import Container from "@mui/material/Container";


import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import SubtitleText from "../../components/text/subtitleText";
import ButtonControla from "../../components/buttons/buttonController";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import TitleText from "../../components/text/titleText";
import MySsoPolitics from "../../api/MySsoPolitics";
import MyUsers from "../../api/MyUsers";
import SearchUsersControla from "../../components/search/searchUsersControla";
///
import MyConfigurationApi from "../../api/MyConfigurationApi";
import SearchButtonControla from "../../components/search/searchButtonControla";
import { EditorSsoPoliticsControlaThree } from "../../components/editor/editorSsoPoliticsControlaThree";
import { useAppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import ButtonSignatureControla from "../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";

function TemplateThree() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const idRistTemplate = params.get("idSsoPolitics");
  //validar solo lectura
  const readOnly =  params.get("readOnly") == "true"? true : false ;

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  ///USER LOGGED
  const [idUserLogged, setIdUserLogged] = useState();
  const handleUserActive = async () => {
    const responsable = await MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);
  };
  ///

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };


  //fin de alerts

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN
  const [usersActives, setUsersActives] = useState([]);
  const handleGetUsersActives = async () => {
    const response = await MyUsers.users();
    if (response.success === false) {
      alert("Error");
    } else {
      setUsersActives(response.users_actives);
    }
  };



  const [version, setVersion] = useState("");
  const [title, setTitle] = useState("");
  const [documentText, setDocumentText] = useState(null);
  const [code, setCode] = useState("");

  const [develop, setDevelop] = useState("");
  const [dateDevelop, setDateDevelop] = useState("");
  const [revised, setRevised] = useState("");
  const [dateRevised, setDateRevised] = useState("");
  const [approved, setApproved] = useState("");
  const [dateApproved, setDateApproved] = useState("");

  const [dateManagerSign, setDateManagerSign] = useState("");
  /// manager //
  const [managerName, setManagerName] = useState("");

  const [idManager, setIdManager] = useState(0);

  const [developSign, setDevelopSign] = useState(0);
  const [revisedSign, setRevisedSign] = useState(0);
  const [approvedSign, setApprovedSign] = useState(0);

  //FIRMA URL
  const [developUrlFirm, setDevelopUrlFirm] = useState("");
  const [revisedUrlFirm, setRevisedUrlFirm] = useState("");
  const [approvedUrlFirm, setApprovedUrlFirm] = useState("");

  const [managerSign, setManagerSign] = useState(0);
  const [firmUrl, setFirmUrl] = useState("");
  ///

  const templateText = (textdata) => {
    setDocumentText(textdata);
  };
  // jalar y mostrar datos del usuario preparado
  const [idUsersDevelop, setIdUsersDevelop] = useState("");
  const [openModalDevelop, setOpenModalDevelop] = useState(false);
  const handleOpenModalDevelop = () => {
    setOpenModalDevelop(true);
  };

  const handleGetUsersDevelop = async (idUser) => {
    handleOpenLoading();
    //limpiar input
    setDateDevelop("");
    setDevelopSign(0);

    handleOpenLoading();
    setRegisterStatus(1);

    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      const tmp_name = response.first_name + " " + response.last_name;
      const tmp_id = response.id_users;
      setDevelop(tmp_name);
      setIdUsersDevelop(tmp_id);

      setDevelopUrlFirm(response.firm_url);
      
      handleSaveUsersDevelop({ tmp_name: tmp_name, tmp_id: tmp_id });
      setOpenModalDevelop(false);
      handleOpenAlert("", "success");
    }
  };

  const handleSaveUsersDevelop = async ({ tmp_name, tmp_id }) => {
    handleOpenLoading();

    const response = await MySsoPolitics.updateSsoPoliticsTemplate({
      idSsoPoliticsTemplate: idRistTemplate,
      develop: tmp_name,
      idUsersDevelop: tmp_id,
      developSign: "",
    });
    setOpenModalDevelop(false);
    handleEditSsoPoliticsTemplate();
    handleOpenAlert("", "success");
  };

  // jalar y mostrar datos del usuario revisado
  const [idUsersRevised, setIdUsersRevised] = useState("");
  const [openModalRevised, setOpenModalRevised] = useState(false);
  const handleOpenModalRevised = () => {
    setOpenModalRevised(true);
  };

  const handleGetUsersRevised = async (idUser) => {
    handleOpenLoading();
    //limpiar input
    setDateRevised("");
    setRevisedSign(0);

    setRegisterStatus(1);
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      const tmp_name = response.first_name + " " + response.last_name;
      const tmp_id = response.id_users;
      const tmp_url_firm = response.firm_url;
      setRevised(tmp_name);
      setIdUsersRevised(tmp_id);
      setRevisedUrlFirm(tmp_url_firm);

      await handleSaveUsersRevised({ tmp_name: tmp_name, tmp_id: tmp_id, tmp_url_firm: tmp_url_firm});
      setOpenModalRevised(false);
      handleOpenAlert("", "success");
    }
  };

  const handleSaveUsersRevised = async ({ tmp_name, tmp_id , tmp_url_firm}) => {
    handleOpenLoading();

    const response = await MySsoPolitics.updateSsoPoliticsTemplate({
      idSsoPoliticsTemplate: idRistTemplate,
      revised: tmp_name,
      idUsersRevised: tmp_id,
      revisedSign: "",
      revisedUrlFirm: tmp_url_firm,
    });
    setOpenModalDevelop(false);
    await handleEditSsoPoliticsTemplate();
    handleOpenAlert("", "success");
  };

  // jalar y mostrar datos del usuario revisado
  const [idUsersApproved, setIdUsersApproved] = useState("");
  const [openModalApproved, setOpenModalApproved] = useState(false);
  const handleOpenModalApproved = () => {
    setOpenModalApproved(true);
  };

  const handleGetUsersApproved = async (idUser) => {
    handleOpenLoading();

    //limpiar input
    setDateApproved("");
    setApprovedSign(0);

    setRegisterStatus(1);
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      const tmp_name = response.first_name + " " + response.last_name;
      const tmp_id = response.id_users;
      const tmp_url_firm = response.firm_url;

      setApproved(tmp_name);
      setIdUsersApproved(tmp_id);
      setApprovedUrlFirm(response.firm_url);

      handleSaveUsersApproved({ tmp_name: tmp_name, tmp_id: tmp_id , tmp_url_firm: tmp_url_firm });
      setOpenModalApproved(false);
      handleOpenAlert("", "success");
    }
  };
  const handleSaveUsersApproved = async ({ tmp_name, tmp_id , tmp_url_firm }) => {
    handleOpenLoading();

    const response = await MySsoPolitics.updateSsoPoliticsTemplate({
      idSsoPoliticsTemplate: idRistTemplate,
      approved: tmp_name,
      idUsersApproved: tmp_id,
      approvedSign: "",
      //
      approvedUrlFirm: tmp_url_firm
    });
    setOpenModalDevelop(false);
    await handleEditSsoPoliticsTemplate();
    handleOpenAlert("", "success");
  };

  //   crear
  const handleCreateSsoPoliticsemplate = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MySsoPolitics.createSsoPoliticsTemplate({
      code: code,
      develop: develop,
      revised: revised,
      approved: approved,
      version: version,
      title: title,
      document_text: documentText,
      idUsersDevelop,
      idUsersRevised,
      idUsersApproved,
      dateDevelop,
      dateRevised,
      dateApproved,
      managerName: managerName,
      idManager: idManager,
      //
      developUrlFirm: developUrlFirm,
      revisedUrlFirm: revisedUrlFirm,
      approvedUrlFirm: approvedUrlFirm
    });

    //guardar firmas
    
    if(developSign == 1) {
      const resp = await MySsoPolitics.signDevelop({
        idSsoPoliticsTemplate: response.id,
      });
    }
    if(revisedSign == 1){
      const resp = await MySsoPolitics.signRevised({
        idSsoPoliticsTemplate: response.id,
        dateRevised: dateRevised,
      });
    }
    if(approvedSign == 1){
      const resp = await MySsoPolitics.signApproved({
        idSsoPoliticsTemplate: response.id,
        dateApproved: dateApproved,
      });
    }

    if (response.success !== false) {
      handleOpenAlert("El documento se ha creado con éxito", "success");
      navigate("/documentation/ssopolitics?idTabs=0");
    } else {
      handleOpenAlert("Ocurrió un error al crear el archivo", "error");
    }
    return response;
  };

  //Gerente
  const handleGetGeneralManager = async () => {
    const response = await MyUsers.getUsersByRole(10);
    if(response.length > 0) {
      setManagerName(response[0].first_name + " " + response[0].last_name);
      setIdManager(response[0].id_users);
      setFirmUrl(response[0].firm_url);
    }
  };

  // Editar temlplate
  // editar rist document
  //status
  const [registerStatus, setRegisterStatus] = useState(0);
  const handleEditSsoPoliticsTemplate = async () => {
    if (idRistTemplate != null) {
      const response = await MySsoPolitics.editSsoPoliticsTemplate({
        idSsoPoliticsTemplate: idRistTemplate,
      });
      if (response.success === false) {
        console.log("error");
      } else {
        setDevelop(response.develop);
        setCode(response.code);
        setApproved(response.approved);
        setDevelop(response.develop);
        setRevised(response.revised);
        //
        setManagerName(response.manager_name);
        //
        setVersion(response.version);
        setTitle(response.title);
        setDocumentText(response.document_text);
        setDateApproved(response.date_approved);
        setDateRevised(response.date_revised);
        setDateDevelop(response.date_develop);
        setDateManagerSign(response.date_manager);
        //////////////
        setIdUsersDevelop(response.id_users_develop);
        setIdUsersRevised(response.id_users_revised);
        setIdUsersApproved(response.id_users_approved);
  
        //
        setIdManager(response.id_users_manager);
        //
  
        setDevelopSign(response.develop_sign);
        setApprovedSign(response.approved_sign);
        setRevisedSign(response.revised_sign);
        //
        setManagerSign(response.manager_sign);
        setRegisterStatus(response.register_status);
      }
    }
   
    handleGetGeneralManager();
  };
  const handleUpdateSsoPoliticsTemplate = async () => {
    handleOpenLoading();
    const response = await MySsoPolitics.updateSsoPoliticsTemplate({
      idSsoPoliticsTemplate: idRistTemplate,
      code: code,
      develop: develop,
      revised: revised,
      approved: approved,
      version: version,
      title: title,
      document_text: documentText,
      dateDevelop: dateDevelop,
      dateRevised: dateRevised,
      dateApproved: dateApproved,
      idUsersDevelop: idUsersDevelop,
      idUsersRevised: idUsersRevised,
      idUsersApproved: idUsersApproved,

      managerName: managerName,
      idManager: idManager,
      managerSign: managerSign,
      managerSignDate: dateManagerSign,
    });
    handleOpenAlert("Los datos fueron editados de forma correcta", "success");
    handleEditSsoPoliticsTemplate();
    return response;
  };
  //////
  const handleSignDevelop = async () => {
    if (idRistTemplate == null) {
      setDevelopSign(1);
    } else {
      handleOpenLoading();
      const response = await MySsoPolitics.signDevelop({
        idSsoPoliticsTemplate: idRistTemplate,
      });
      handleEditSsoPoliticsTemplate();
      handleOpenAlert("Se firmó con éxito", "success");
      return response;

    }
  };

  const handleSignRevised = async () => {
    if (idRistTemplate == null) {
      setRevisedSign(1);
    } else {
      handleOpenLoading();
      const response = await MySsoPolitics.signRevised({
        idSsoPoliticsTemplate: idRistTemplate,
        dateRevised: dateRevised,
      });
      handleEditSsoPoliticsTemplate();
      handleOpenAlert("Se firmó con éxito", "success");
      return response;

    }
  };

  const handleSignApproved = async () => {
    if (idRistTemplate == null) {
      setApprovedSign(1);
    } else {
      handleOpenLoading();
      const response = await MySsoPolitics.signApproved({
        idSsoPoliticsTemplate: idRistTemplate,
        dateApproved: dateApproved,
      });
      handleEditSsoPoliticsTemplate();
      handleOpenAlert("Se firmó con éxito", "success");

      return response;
    }

  };

  const handleSignManager = async () => {
    handleOpenLoading();
    const response = await MySsoPolitics.signManager({
      idSsoPoliticsTemplate: idRistTemplate,
    });
    handleEditSsoPoliticsTemplate();
    handleOpenAlert("Se firmó con éxito", "success");
    return response;
  };

  /////

  //////// Manager Data

  const [companyName, setCompanyName] = useState("");

  const handleCompanyData = async () => {
    const response = await MyConfigurationApi.companyData();
    setCompanyName(response.name);
  };

  useEffect(() => {
    handleUserActive();
    handleEditSsoPoliticsTemplate();
    handleGetUsersActives();
    handleCompanyData();
    handleGetGeneralManager();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>

      <ReturnBack returnBack='/documentation/ssopolitics'/>
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <form onSubmit={handleCreateSsoPoliticsemplate}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Política SSO"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={
                  registerStatus === 0
                    ? "Plantilla Política Integrada de Medio Ambiente y Seguridad y Salud Ocupacional"
                    : "Documento Política Integrada de Medio Ambiente y Seguridad y Salud Ocupacional"
                }
              >
                {registerStatus}
              </TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <InputControlaVertical
                roles={permissions.DOC_SSO_BORRADOR_EDITAR}
                text={"Código:"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <InputControlaVertical
                roles={permissions.DOC_SSO_BORRADOR_EDITAR}
                text={"Versión:"}
                inputType={"text"}
                inputValue={setVersion}
                value={version}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                text={"Título documento:"}
                inputType={"text"}
                inputValue={setTitle}
                value={title}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>            
                <SearchButtonControla
                roles={permissions.DOC_SSO_BORRADOR_EDITAR}
                titleLabel="Elaborado por: "
                inputValue={develop}
                functionClick={handleOpenModalDevelop}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <InputControlaVertical
                text={"Fecha de Elaboración:"}
                inputType={"date"}
                inputValue={setDateDevelop}
                value={dateDevelop}
                required
                readOnly={readOnly? true : (idUsersDevelop == idUserLogged ? false : true)}
              />
            </Grid>
            {/* * */}
            <Grid item xs={12} sm={12} md={1} lg={1} xl={1}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Stack>
                <Box>
                  <InputLabel
                    sx={{
                      padding: "8px",
                      fontSize: "18px",
                      color: "#305AD9",
                      fontWeight: "bold",
                      textAlign: "center"
                    }}
                  >
                    Firma
                  </InputLabel>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <ButtonSignatureControla
                    idUser={idUsersDevelop}
                    firmStatus={developSign}
                    firmUrl={developUrlFirm}
                    functionOnClick={() => handleSignDevelop()}
                  />
                </Box>
              </Stack>
            </Grid>
            {/* * */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <SearchButtonControla
                roles={permissions.DOC_SSO_BORRADOR_EDITAR}
                titleLabel="Revisado por: "
                inputValue={revised}
                functionClick={handleOpenModalRevised}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <InputControlaVertical
                text={"Fecha de Revisión:"}
                inputType={"date"}
                inputValue={setDateRevised}
                value={dateRevised}
                modalType={false}
                readOnly={readOnly? true : (idUsersRevised == idUserLogged ? false : true)}
              />
            </Grid>
            {/* * */}
            <Grid item xs={12} sm={12} md={1} lg={1} xl={1}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Stack>
                <Box>
                  <InputLabel
                   sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                  >
                    Firma
                  </InputLabel>
                </Box>

                <Box sx={{textAlign: "center"}}>
                  <ButtonSignatureControla
                     idUser={idUsersRevised}
                     firmStatus={revisedSign}
                     firmUrl={revisedUrlFirm}
                     functionOnClick={
                      dateRevised == ""
                        ? () => handleOpenModalConfirm()
                        : () => handleSignRevised()
                     }
                  />
                </Box>
              </Stack>
            </Grid>
            {/* * */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>         
                <SearchButtonControla
                roles={permissions.DOC_SSO_BORRADOR_EDITAR}
                titleLabel="Aprobado por: "
                inputValue={approved}
                functionClick={handleOpenModalApproved}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <InputControlaVertical
                text={"Fecha de Aprobación:"}
                inputType={"date"}
                inputValue={setDateApproved}
                value={dateApproved}
                modalType={false}
                readOnly={readOnly? true : (idUsersApproved == idUserLogged ? false : true)}
              />
            </Grid>
            {/* * */}
            <Grid item xs={12} sm={12} md={1} lg={1} xl={1}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Stack>
                <Box>
                  <InputLabel
                   sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                  >
                    Firma
                  </InputLabel>
                </Box>

                <Box sx={{textAlign: "center"}} >
                  <ButtonSignatureControla
                     idUser={idUsersApproved}
                     firmStatus={approvedSign}
                     firmUrl={approvedUrlFirm}
                     functionOnClick={
                      dateApproved == ""
                        ? () => handleOpenModalConfirm()
                        : () => handleSignApproved()
                     }
                  />
                </Box>
              </Stack>
            </Grid>
            {/* * */}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EditorSsoPoliticsControlaThree
                roles={permissions.DOC_SSO_BORRADOR_EDITAR}
                dataEdit={documentText}
                handleDataEditor={templateText}
                companyName={companyName}
              />
            </Grid>
            {/* GERENTE GENERAL */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputLabel
                sx={{
                  padding: "8px",
                  fontSize: "18px",
                  color: "#305AD9",
                  fontWeight: "bold",
                }}
              >
                Gerente General:
              </InputLabel>
              <InputBase
                type="text"
                value={managerName}
                required={false}
                sx={{
                  pointerEvents: "none",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  fontFamily: "Roboto",
                  width: "100%",
                  padding: "4px 10px",
                  border: "1px solid transparent",
                  backgroundColor: "#FFFFFF",
                  color: "#305AD9",
                  borderTopLeftRadius: "8px",
                  borderBottomLeftRadius: "8px",
                  "&.Mui-focused": {
                    border: "1px solid blue",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <InputControlaVertical
                roles={permissions.DOC_SSO_BORRADOR_EDITAR}
                text={"Fecha de Firma:"}
                inputType={"date"}
                inputValue={setDateManagerSign}
                value={dateManagerSign}
                modalType={false}
                readOnly={readOnly? true : (idManager == idUserLogged ? false : true)}
              />
            </Grid>
            {/* * */}
            <Grid item xs={12} sm={12} md={1} lg={1} xl={1}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Stack>
                <Box>
                  <InputLabel
                   sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                  >
                    Firma
                  </InputLabel>
                </Box>

                <Box sx={{textAlign: "center"}}>
                  <ButtonSignatureControla
                     idUser={idManager}
                     firmStatus={managerSign}
                     firmUrl={firmUrl}
                     functionOnClick={() => handleSignManager()}
                  />
                </Box>
              </Stack>
            </Grid>
            {/* * */}
          </Grid>

          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"center"}
            mt={2}
            mb={2}
            sx={{
              paddingTop: "2em",
            }}
          >
            <Box>
              <ButtonControla
                iconButton={<ArrowBack sx={{ color: "white" }} />}
                backgroundColor={"#C61C39"}
                backgroundColorHover={"#D61F3E"}
                textButton={"Regresar"}
                typeButton={"button"}
                isNavegationOrigin={true}
                url={"/documentation/ssopolitics"}
              />
            </Box>{" "}
            {idRistTemplate === null || idRistTemplate === undefined ? (
              <Box>
                <ButtonControla
                  iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Guardar"}
                  typeButton={"submit"}
                />
              </Box>
            ) : registerStatus == 9 || registerStatus == 8 ? (
              ""
            ) : (
                  <Box>
                    {
                      readOnly == "true" ? "" :
                        (
                          <ButtonControla
                            roles={registerStatus === 4 ? [16] : [1, 7, 8]}
                            backgroundColor={"#169073"}
                            backgroundColorHover={"#1BAA88"}
                            textButton={"Actualizar"}
                            functionOnClick={() => handleUpdateSsoPoliticsTemplate()}
                          />
                        )
                    }
                  </Box>
            )}
            {registerStatus == 4 ? (
              <Box>
                <ButtonControla
                  roles={[1, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13]}
                  iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                  backgroundColor={"#F2994A"}
                  backgroundColorHover={"#FF881E"}
                  textButton={"Ver documento PDF"}
                  typeButton={"button"}
                  url={
                    process.env.REACT_APP_API_DJANGO +
                    "report/" +
                    idRistTemplate
                  }
                  target={"_blank"}
                />
              </Box>
            ) : (
              <></>
            )}
          </Stack>
        </form>
      </Container>

      {/* Buscar responsables */}
      {/* Elaborado por */}
      <Dialog
        open={openModalDevelop}
        onClose={() => {
          setOpenModalDevelop(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de elaborar la plantilla de Política SSO
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersDevelop}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalDevelop(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Revisado por */}
      <Dialog
        open={openModalRevised}
        onClose={() => {
          setOpenModalRevised(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Revisar la plantilla Política SSO
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersRevised}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalRevised(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Revisado por */}
      <Dialog
        open={openModalApproved}
        onClose={() => {
          setOpenModalApproved(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Aprobar la plantilla de Políticas SSO
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersApproved}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalApproved(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModalConfirm}
        onClose={() => setOpenModalConfirm(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Para firmar es necesario consignar fecha.
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{
              textTransform: "none",
              // backgroundColor: '#169073',
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => setOpenModalConfirm(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default TemplateThree;
