import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { Stack } from "@mui/system";
import {
  Add,
  ArrowBackIosNew,
  ArrowForwardIos,
  Clear,
  DriveFolderUpload,
  Search,
} from "@mui/icons-material";
import MyPetar from "../../../../api/MyPetar";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import BannerControla from "../../../../components/cards/bannerControla";
import LoadingControla from "../../../../components/load/loadingControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import ListModels from "./components/list-models";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import { useAppContext } from "../../../../context/AppContext";
import ListActives from "./components/list-actives";
import ListArchived from "./components/list-archived";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../../../components/buttons/returnBackControla";


const purple = "#8B8BD8";

function Index() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );

  //Todo: --------- Alets
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  if (!permissions) {
    handleOpenAlert('No se pudieron obtener los permisos', 'error')
  }
  // -------- ./ Alerts

  //* ----------- Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [archivado, setArchivado] = useState(2);

  const handleChangeTab = (event, newValue) => {
    setArchivado(newValue);
    setPage(0);
  }

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  //* ----------- Search
  const [search, setSearch] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const handleClearSearch = () => {
    setSearch('');
    setPage(0)
    handleGetPetar(year, '', 0, archivado)
  }

  const handleSearch = (event) => {
    const input = event.target.value.trim();
    if (input === search) return;
    setSearch(input);
    setPage(0);

    if (debounceTimeout) clearTimeout(debounceTimeout);

    const newTimeout = setTimeout(() => {
      if (input || input === "") {
        handleGetPetar(year, input, 0, archivado)
      }
    }, 500);
    setDebounceTimeout(newTimeout);
  }

  const [data, setData] = useState(null);

  const handleGetPetar = async (inputYear, search = '', pageN, filed) => {
    handleOpenLoading();
    try {
      const response = await MyPetar.petar({
        year: inputYear ?? year, archivado: filed ?? archivado, page: pageN ?? page, search, perPage: rowsPerPage
      });
      if (!response || response.status === 'error') {
        return handleOpenAlert("Ocurrio un error al listar los Petar", "error");
      }
      if (response.data) setData(response.data);
      if (response.current_page) setPage(parseInt(response.current_page));
      if (response.perPage) setRowsPerPage(parseInt(response.per_page));
      if (response.total) setTotalRows(parseInt(response.total));
      if (response.last_page) setLastPage(parseInt(response.last_page));
      if (inputYear !== undefined) {
        setDocumentType(1);
        handleOpenAlert(
          "Listando Petar activos del año " + inputYear, "success"
        );
      }
      setOpenLoading(false);
      return response;
    }
    catch (error) {
      console.log(`Error: ${error}`);
    }
    return null;
  };

  const handlePostCreatePetar = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    if (documentUrl !== null) {
      const response = await MyPetar.createPetar({
        name,
        code,
        area,
        place,
        start_time: hourStart,
        end_time: hourFinish,
        model: 0,
        document_url: documentUrl,
      });
      if (response.success === true) {
        await handleGetPetar(year, search, page, archivado);
      }
    } else {
      const response = await MyPetar.createPetar({
        name, code, area, place, start_time: hourStart,
        end_time: hourFinish, model: 0, document_url: documentUrl,
      });
      if (response.success === true) {
        handleGetPetar(year, search, page, archivado)
        navigate("/editPetar?id_petar=" + response.id_petar);
      }
    }
    handleCloseModalUpload();
    setOpenLoading(false);
  };

  const handlePutUpdatePetar = async (idPetar) => {
    const response = await MyPetar.updatePetar({
      id_petar: idPetar,
      name,
      code,
      document_url: documentUrl,
    });
    handleGetPetar(year, search, page, archivado)
    setOpenModalUploadUpdate(false);
  };

  const handleDeletePetar = async () => {
    handleOpenLoading();
    const response = await MyPetar.deletePetar({ idPetar });
    if (response.success) {
      handleGetPetar(year, search, page, archivado)
      handleOpenAlert("Se eliminó el registro con éxito", "success");
    }
  };

  const handlePostCreateModel = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPetar.createPetar({
      name,
      code,
      model: 1,
    });
    if (response.success) {
      navigate("/documentation/documents/procedures/petar/editModel?id_petar=" + response.id_petar);

    } else {
      handleOpenAlert("Ocurrio un error al crear el modelo PETAR", "error");
    }
  };

  const sendToWorkers = async (idPetar) => {
    try {
      const response = await MyPetar.sendToWorkers({ id_petar: idPetar });
      if (response.success) {
        handleOpenAlert(response.msg);
        handleGetPetar(year, search, page, archivado)
      } else {
        handleOpenAlert(response.msg, "error");
      }
    } catch (e) {
      handleOpenAlert(e);
    }
  };

  const publishPetar = async (idPetar) => {

    handleOpenLoading();
    try {
      const response = await MyPetar.publishPetar({ id_petar: idPetar });

      if (response.success) {
        handleGetPetar(year, search, page, archivado)
        handleOpenAlert(response.msg, "success");
      } else {
        handleOpenAlert(response.msg, "error");
      }
    } catch (e) {
      handleOpenAlert(e, 'error');
    }
  };

  const toArchive = async (idPetar) => {
    const response = await MyPetar.archivePetar({ id_petar: idPetar });
    handleGetPetar(year, search, page, archivado)
  };

  const toUnarchive = async (idPetar) => {
    const response = await MyPetar.unarchivePetar({ id_petar: idPetar });
    handleGetPetar(year, search, page, archivado)
  };

  //traer id usuario logeado
  const user = MyConfigurationApi.userData();
  const idUserLogged = user.id_users;

  useEffect(() => {
    handleGetPetar(year, search, page, archivado);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, archivado]);

  const [openModalCreate, setOpenModalCreate] = useState(false);
  const handleOpenModalCreate = () => setOpenModalCreate(true);
  const handleCloseModalCreate = () => setOpenModalCreate(false);

  const [openAlertModal, setOpenAlertModal] = useState(false);
  const handleOpenAlertModal = () => setOpenAlertModal(true);
  const handleCloseAlertModal = () => setOpenAlertModal(false);

  const [idPetar, setIdPetar] = useState(null);
  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [area, setArea] = useState(null);
  const [place, setPlace] = useState(null);
  const [hourStart, setHourStart] = useState(null);
  const [hourFinish, setHourFinish] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  const currentTime = new Date();
  const [year, setYear] = useState(currentTime.getFullYear());

  const [documentType, setDocumentType] = useState(
    idTabs !== undefined && idTabs !== null && idTabs !== "" ? idTabs : 0
  );

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  const handleIncreaseYear = (event, newValue) => {
    setYear(year + 1);
    handleGetPetar(year + 1, search, page, archivado)
  };

  const handleDecreaseYear = (event, newValue) => {
    setYear(year - 1);
    handleGetPetar(year - 1, search, page, archivado)
  };

  const [openModalUpload, setOpenModalUpload] = useState(false);

  const handleOpenModalUpload = () => {
    setName(null);
    setCode(null);
    setArea(null);
    setPlace(null);
    setHourStart(null);
    setHourFinish(null);
    setDocumentUrl(null);
    setOpenModalUpload(true);
  };

  const handleCloseModalUpload = () => setOpenModalUpload(false);

  const [openModalUploadUpdate, setOpenModalUploadUpdate] = useState(false);

  const handleOpenModalUploadUpdate = (idPetar, name, code) => {
    setIdPetar(idPetar);
    setName(name);
    setCode(code);
    setOpenModalUploadUpdate(true);
  };

  const handleCloseModalUploadUpdate = () => {
    setOpenModalUploadUpdate(false);
  };

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  /////////////////////// ALERT
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
    // dataNotification
  ) => {
    setIdPetar(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
    // setDataNotification(dataNotification);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    const actions = {
      "delete": () => handleDeletePetar(),
      "sendWorkers": () => sendToWorkers(idPetar),
      "public": () => publishPetar(idPetar),
      "archived": () => toArchive(idPetar),
      "unarchived": () => toUnarchive(idPetar)
    }
    // Ejecutar
    actions[buttonActionType]
      ? actions[buttonActionType]() : console.log('Acción no controlada!');
    setOpenModalConfirm(false);
  };

  const arrTHeadModels = ['#', 'Nombre del documento', 'Fecha de creación'];
  const arrTHead = ['#', 'Nombre del documento', 'Área', 'Tipo', 'Fecha', 'Estado'];

  const tableBodies = {
    0: () => (<ListActives
      rowsActives={data}
      idUserLogged={idUserLogged}
      colorCell={purple}
      handleOpenModalConfirm={handleOpenModalConfirm}
      handleOpenModalUploadUpdate={handleOpenModalUploadUpdate}
    />),
    1: () => (<ListArchived
      rowsArchives={data}
      colorCell={purple}
      handleOpenModalConfirm={handleOpenModalConfirm}
    />),
    2: () => (<ListModels
      rowsModels={data}
      colorCell={purple}
      handleOpenModalConfirm={handleOpenModalConfirm}
    />)
  }
  return (
    <>
      <Box sx={{ height: "100%", width: "100%" }}>
        <ReturnBack />
        <LoadingControla
          openLoading={openLoading}
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          messageAlert={messageAlert}
          typeAlert={typeAlert}
        />
        <DialogConfirmControla
          openModalConfirm={openModalConfirm}
          handleCloseModalConfirm={handleCloseModalConfirm}
          titleText={titleText}
          contentText={contentText}
          buttonTextConfirm={buttonTextConfirm}
          handleActionConfirm={handleActionConfirm}
        />
        <Container sx={{ flexGrow: 1, width: '100%' }}>
          <Box sx={{ flexGrow: 1, width: '100%' }}>
            <Grid
              container rowSpacing={2} columnSpacing={6}
              sx={{ marginBottom: '2rem' }}
            >
              <Grid item xs={12}>
                <ButtonMailboxControla
                  originUrl={"/documentation/documents/procedures/petar"}
                />
              </Grid>
              <Grid item xs={12}>
                <BannerControla
                  image="/assets/images/banners/documents/petarBanner.png"
                  color2="#2F67BC"
                  color1="#8FBCFF"
                  text="Permiso Escrito para Trabajos de Alto Riesgo"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} direction={"row"}>
              <Grid
                item xs={6} sm={4} md={4}
                alignItems="center"
                justifyContent={"right"}
                direction={"row"}
              >
                <Grid container>
                  <Grid item xs={3} sm={2} md={3} lg={3}>
                    <InputLabel
                      sx={{
                        padding: "8px 0px 8px 0px",
                        fontSize: "14px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Periodo:
                    </InputLabel>
                  </Grid>
                  { /* //* --------- Input year  */}
                  <Grid item xs={9} sm={10} md={9} lg={9}>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="number"
                      value={year}
                      onChange={handleGetPetar}
                      readOnly
                      sx={{
                        width: "100%",
                        textAlign: 'center',
                        padding: "0px",
                        backgroundColor: "white",
                        color: "#305AD9",
                        fontSize: "14px",
                        borderRadius: "8px",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              sx={{
                                backgroundColor: "white",
                                borderRadius: "15px",
                                color: "#305AD9",
                                padding: '0'
                              }}
                              onClick={handleDecreaseYear}
                            >
                              <ArrowBackIosNew />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{
                                backgroundColor: "white",
                                borderRadius: "15px",
                                color: "#305AD9",
                                padding: '0'
                              }}
                              onClick={handleIncreaseYear}
                            >
                              <ArrowForwardIos />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* //? ------ Search input ------ */}
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder={"Buscar registros..."}
                  type={"text"}
                  value={search}
                  onChange={handleSearch}
                  style={{
                    width: '100%',
                    backgroundColor: "#FFF",
                    borderRadius: "16px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      search.length > 0 && (
                        <InputAdornment position="end">
                          <Clear sx={{ color: "#1638F2" }} onClick={handleClearSearch} />
                        </InputAdornment>
                      )
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <ButtonControla
                    roles={permissions.DOC_HG_PROC_PETAR_MOD_AGREGAR_MODELO}
                    textButton={"Agregar modelo Petar"}
                    iconButton={<Add />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#4db2eb"}
                    functionOnClick={() => handleOpenModalCreate()}
                  />
                  <ButtonControla
                    roles={permissions.DOC_HG_PROC_PETAR_MOD_SUBIR_ARCHIVO}
                    textButton={"Subir Petar"}
                    iconButton={<DriveFolderUpload />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#2bcda6"}
                    functionOnClick={() => handleOpenModalUpload()}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 2, width: '100%', marginBottom: '1rem' }}>
              <Grid item xs={12}>
                <TableContainer sx={{ maxHeight: '60vh', marginBottom: '0', overflowY: 'auto', backgroundColor: 'white' }}>
                  <Table sx={{ minWidth: '100%', }} stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {(archivado === 2 ? arrTHeadModels : arrTHead).map((th, index) => (
                          <TableCell sx={{ color: "blue", lineHeight: '1.1rem' }} key={index}>{th}</TableCell>
                        ))}
                        <TableCell sx={{ position: 'sticky', right: 0, background: 'white' }}>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Tabs value={archivado} onChange={handleChangeTab} aria-label="status">
                              <Tab label="Modelos" value={2} />
                              <Tab label="Activos" value={0} />
                              <Tab label="Archivados" value={1} />
                            </Tabs>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {
                      (!data || data.length < 1)
                        ? <TableBody><TableRow>
                          <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                            No hay datos!
                          </TableCell>
                        </TableRow></TableBody>
                        : (<>{tableBodies[archivado]()}</>)
                    }
                  </Table>
                </TableContainer>
                {/* //TODO: ------------- Paginación en español ------------- */}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Filas por página:"
                  labelDisplayedRows={() => `${page + 1}-${lastPage} de ${totalRows}`}
                  getItemAriaLabel={(type) => {
                    if (type === 'first') {
                      return 'Ir a la primera página';
                    }
                    if (type === 'last') {
                      return 'Ir a la última página';
                    }
                    if (type === 'next') {
                      return 'Ir a la página siguiente';
                    }
                    if (type === 'previous') {
                      return 'Ir a la página anterior';
                    }
                  }}
                  sx={{
                    width: '100%',
                    backgroundColor: "#FFF",
                    border: "1px solid #E0E0E0",
                    color: "#1638F2",
                    fontSize: "18px",
                    lineHeight: "1.2rem",
                  }}
                />
              </Grid>
            </Grid>
            <Dialog
              open={openModalCreate}
              onClose={handleCloseModalCreate}
              maxWidth="sm"
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "55%",
                  padding: "10px",
                  borderRadius: "15px",
                },
              }}
              fullWidth={true}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla
                titleText="Nuevo Modelo de PETAR"
                functionOnClose={handleCloseModalCreate}
              />
              <form onSubmit={handlePostCreateModel}>
                <DialogContent>
                  <InputControlaVertical
                    text="Código"
                    inputType={"text"}
                    inputValue={setCode}
                    modalType={false}
                    required
                  />
                  <InputControlaVertical
                    text="Nombre del modelo de PETAR"
                    inputType={"text"}
                    inputValue={setName}
                    modalType={false}
                    required
                  />
                </DialogContent>
                <DialogActions>
                  <Box>
                    <ButtonControla
                      backgroundColor={"#CBCBFF"}
                      textButton={"Cancelar"}
                      backgroundColorHover={"#CBCBFF"}
                      typeButton={"button"}
                      functionOnClick={handleCloseModalCreate}
                    >
                      Cancelar
                    </ButtonControla>
                  </Box>
                  <Box>
                    <ButtonControla
                      iconButton={<Add />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Crear modelo de PETAR"}
                      typeButton={"submit"}
                    >
                      Agregar nuevo modelo
                    </ButtonControla>
                  </Box>
                </DialogActions>
              </form>
            </Dialog>
            <Dialog
              open={openModalUpload}
              onClose={handleCloseModalUpload}
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "65%",
                  padding: "10px",
                  borderRadius: "15px",
                },
              }}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla
                titleText="Subir documento PETAR"
                functionOnClose={handleCloseModalUpload}
              />
              <form onSubmit={handlePostCreatePetar}>
                <DialogContent>
                  <InputControlaVertical
                    text={"Título del documento"}
                    inputType={"text"}
                    inputValue={setName}
                    modalType={false}
                    required
                  />
                  <InputControlaVertical
                    text={"Código"}
                    inputType={"text"}
                    inputValue={setCode}
                    modalType={false}
                    required
                  />
                  <InputControlaVertical
                    text={"Subir Archivo"}
                    inputType={"file"}
                    inputValue={setDocumentUrl}
                    modalType={false}
                    required
                  />
                </DialogContent>
                <DialogActions>
                  <Box>
                    <ButtonControla
                      backgroundColor={"#CBCBFF"}
                      textButton={"Cancelar"}
                      backgroundColorHover={"#CBCBFF"}
                      typeButton={"button"}
                      functionOnClick={handleCloseModalUpload}
                    ></ButtonControla>
                  </Box>
                  <Box>
                    <ButtonControla
                      iconButton={<Add />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Subir archivo PETAR"}
                      typeButton={"submit"}
                    ></ButtonControla>
                  </Box>
                </DialogActions>
              </form>
            </Dialog>
            <Dialog open={openAlertModal} onClose={handleCloseAlertModal}>
              <DialogTitle>
                ¿Seguro que desea eliminar este documento?
              </DialogTitle>
              <DialogActions>
                <Button>Eliminar</Button>
                <Button onClick={handleCloseAlertModal}>Cancelar</Button>
              </DialogActions>
            </Dialog>
            {/* modal actualizar subir doc */}
            <Dialog
              open={openModalUploadUpdate}
              onClose={handleCloseModalUploadUpdate}
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "65%",
                  padding: "10px",
                  borderRadius: "15px",
                },
              }}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitle>
                <Typography
                  id="modal-modal-title"
                  variant="h4"
                  component="h2"
                  sx={{
                    color: "#1638F2",
                    fontWeight: "bold",
                  }}
                >
                  Editar PETAR
                </Typography>
              </DialogTitle>
              <DialogContent>
                <InputControlaVertical
                  text={"Título del documento"}
                  inputType={"text"}
                  value={name}
                  inputValue={setName}
                  modalType={false}
                  required
                />
                <InputControlaVertical
                  text={"Código"}
                  inputType={"text"}
                  value={code}
                  inputValue={setCode}
                  modalType={false}
                  required
                />
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  value={documentUrl}
                  inputValue={setDocumentUrl}
                  modalType={false}
                  required
                />
              </DialogContent>
              <DialogActions>

                <Button
                  variant="contained"
                  // color="success"
                  sx={{
                    textTransform: "none",
                    color: "#6969D6",
                    backgroundColor: "#CBCBFF",
                    borderRadius: "16px",
                    marginRight: "10px",
                  }}
                  onClick={handleCloseModalUploadUpdate}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    textTransform: "none",
                    // backgroundColor: '#169073',
                    borderRadius: "16px",
                    marginRight: "10px",
                  }}
                  onClick={() => handlePutUpdatePetar(idPetar)}
                >
                  Editar
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openConfirmModal}
              onClose={handleCloseConfirmModal}
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "65%",
                  padding: "10px",
                  borderRadius: "15px",
                },
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent></DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </Box >
        </Container >
      </Box >
    </>
  );
}

export default Index;
