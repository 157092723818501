import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import SubtitleText from "../../../../components/text/subtitleText";
import {
    Grid,
    Stack,
  } from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

  import Table from "@mui/material/Table";
  import TableBody from "@mui/material/TableBody";
  import TableCell from "@mui/material/TableCell";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import Paper from "@mui/material/Paper";

import BannerControla from "../../../../components/cards/bannerControla";
import { useState } from "react";
import MyStatisticalReport from "../../../../api/MyStatisticalReports";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import LoadingControla from "../../../../components/load/loadingControla";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";

function Index() {
    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    //fin de alerts

    const [rowsModules, setRowsModules] = useState([
        {
            id: 1,
            name: "Capacitaciones",
            function: () => handleGetDowloandReportTrainings()
        },
        {
            id: 2,
            name: "Inspecciones",
            function: () => handleGetDowloandReportInspection()
        }
    ])

    // exportar data csv
    const [dowloandData, setDowloandData] = useState([]);

    const handleGetDowloandReportTrainings = async () => {
        handleOpenLoading();
        try {
            const resp = await MyStatisticalReport.getDataStadisticsTrainings(); // Llama a tu función que usa connectApiExcel
            const blob = await resp.blob(); // Convertir la respuesta en un blob

            // Crear un enlace temporal para descargar el archivo
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a'); // Aquí debe ser 'a' en lugar de 'r'
            link.href = url;
            link.setAttribute('download', `reporte-capacitaciones.xlsx`); // Establece el nombre del archivo
            document.body.appendChild(link);
            link.click();
            link.remove(); // Elimina el enlace después de la descarga

        } catch (error) {
            console.error('Hubo un error con la generacion del EXCEL', error);
        }
        setOpenLoading();
    }

    const handleGetDowloandReportInspection = async () => {
        handleOpenLoading();
        try {
            const res = await MyStatisticalReport.getDataStadisticsInspections();

            const blob = await res.blob(); // Convertir la respuesta en un blob

            // Crear un enlace temporal para descargar el archivo
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a'); // Aquí debe ser 'a' en lugar de 'r'
            link.href = url;
            link.setAttribute('download', `reporte-inspecciones.xlsx`); // Establece el nombre del archivo
            document.body.appendChild(link);
            link.click();
            link.remove(); // Elimina el enlace después de la descarga

        } catch (error) {
            handleOpenAlert("Hubo un error en la generación del excel", "error");
            console.error('Hubo un error con la generacion del EXCEL', error);
        }

        setOpenLoading(false);

    }


    return (
        <Box>
            <ReturnBack/>

            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <ButtonMailboxControla
                            originUrl={"/documentation/registers/generals/statisticalReports"}
                        />

                        <Grid item xs={12} md={12}>
                            <SubtitleText
                                text={"Registros"}
                                color={colorTitle}
                            ></SubtitleText>
                            <BannerControla
                                image="/assets/images/banners/documents/bannerStatisticalData.png"
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Reportes estadísticos"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={12}>
                            <Grid item md={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        color: "#1638F2",
                                                        fontSize: "18px",
                                                        width: "50%",
                                                    }}
                                                >
                                                    Módulo
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: "#1638F2",
                                                        fontSize: "18px",
                                                        width: "30%",
                                                    }}
                                                >
                                                    Acción
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {
                                                rowsModules.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                            padding: "4px",
                                                        }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.name}
                                                        </TableCell>

                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                            >
                                                                <ButtonIconControla
                                                                    icon={<CloudDownloadIcon sx={{ color: "white" }} />}
                                                                    backgroundColor={"#169073"}
                                                                    backgroundColorHover={"#1AA483"}
                                                                    textTooltip={"Descargar"}
                                                                    functionOnClick={row.function}
                                                                />

                                                            </Stack>
                                                        </TableCell>

                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );

}
export default Index;