import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React from "react";

export const Register = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "300px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h2>Registro</h2>
        </div>

        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-name">Nombres</InputLabel>
          <OutlinedInput id="outlined-name" label="Amount" />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-lastname">Apellidos</InputLabel>
          <OutlinedInput id="outlined-lastname" label="Amount" />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-phone">Telefono</InputLabel>
          <OutlinedInput
            id="outlined-phone"
            startAdornment={
              <InputAdornment position="start">+51</InputAdornment>
            }
            label="Amount"
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-email">Correo Electronico</InputLabel>
          <OutlinedInput id="outlined-email" type="email" label="Amount" />
        </FormControl>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained">Registrar</Button>
        </div>
      </div>
    </Box>
  );
};
