import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

function ListContractors(props) {
    const {
        rowsContractors,
        colorCell,
        handleOpenModalConfirm,
        handleOpenModalEdit
    } = props;

    return (
        <TableBody>
            {rowsContractors.map((row) => (
                <TableRow
                    hover
                    key={row.id_contractors} // Assumes unique identifier is `id_contractors`
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell sx={{ color: colorCell }}>
                        {row.company_name}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.ruc}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.address}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.economic_activity_type}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.total_workers}
                    </TableCell>
                    <TableCell>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                icon={<Edit sx={{ color: "white" }} />}
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#2D9CDB"}
                                textTooltip={"Editar"}
                                isNavegationOrigin={true}
                                //functionOnClick={() => handleOpenModalEdit(row)}
                                directionUrl={"/documentation/contractors/edit?id_contractor=" + row.id_contractors}
                            />

                            <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#EB5757"}
                                textTooltip={"Delete"}
                                functionOnClick={() => handleOpenModalConfirm(
                                    "¿Estás seguro de que deseas eliminar este contratista?",
                                    `Una vez eliminado, el contratista "${row.company_name}" no podrá ser recuperado.`,
                                    "Sí, Eliminar",
                                    "delete",
                                    row.id_contractors
                                )}
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
}

export default ListContractors;
