// colocar lo de Workaccidents.jsx
import * as React from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  Clear,
  Search,
  VisibilityOutlined,
} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import BannerControla from "../../../../components/cards/bannerControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import MyRegistersAccidents from "../../../../api/MyRegistersAccidents";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import LoadingControla from "../../../../components/load/loadingControla";
import { useAppContext } from "../../../../context/AppContext";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";

function Index() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();

  // alerts
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };
  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts
  const [code, setCode] = React.useState("");
  const [rowsActives, setRowsActives] = React.useState([]);
  const [openModalUpload, setOpenModalUpload] = React.useState(false);

  // Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = React.useState(0);
  const [lastPage, setLastPage] = React.useState(0);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  // Search
  const [search, setSearch] = React.useState("");
  const [debounceTimeout, setDebounceTimeout] = React.useState(null);

  const handleClearSearch = () => {
    setSearch("");
    handleGetRegistersAccidents();
  }

  const handleSearch = (event) => {
    // Agregar el debounceSearch
    const input = event.target.value.trim();
    if (input === search) return;
    setSearch(input);
    setPage(0);

    // Limpiar el timeout anterior
    if (debounceTimeout) clearTimeout(debounceTimeout);

    // Crear un nuevo timeout
    const newTimeout = setTimeout(() => {
      if (input || input === "") {
        handleGetRegistersAccidents(input, 0);
      }
    }, 500);
    // Guardar el timeout en el estado
    setDebounceTimeout(newTimeout);
  };

  // Modal nuevo registro
  const handleOpenModalUpload = () => {
    setCode(null);
    setOpenModalUpload(true);
  };

  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
  };

  // Obtener registros de accidentes
  const handleGetRegistersAccidents = async (search = '', pageN) => {
    handleOpenLoading();
    const response = await MyRegistersAccidents.registersAccidents({ page: pageN ?? page, rowsPerPage, search });
    if (!response || response.success === false) {
      return handleOpenAlert("Ocurrio un error", "error");
    }
    handleOpenAlert(response.msg, "success");
    setRowsActives(response.data);
    if (response.current_page) setPage(parseInt(response.current_page - 1));
    if (response.per_page) setRowsPerPage(parseInt(response.per_page));
    if (response.total) setTotalRows(parseInt(response.total));
    if (response.last_page) setLastPage(parseInt(response.last_page));
    return response;
  };

  const handlePostCreateRegistersAccidents = async (event) => {
    event.preventDefault();
    const response = await MyRegistersAccidents.createRegistersAccidents({ code });
    if (response.success === true) {
      navigate('/documentation/registers/generals/accidents/edit?idRegistersAccidents=' + response.id_registers_accidents);
    } else {
      handleOpenAlert("Ocurrio un error", "error");
    }
    return response;
  };

  React.useEffect(() => {
    handleGetRegistersAccidents(search, page);
  }, [page, rowsPerPage]);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack />

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <ButtonMailboxControla
              originUrl={"/documentation/registers/generals/accidents"}
            />

            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/bannerworkaccidents.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Accidentes de trabajo"
              />
            </Grid>
          </Grid>

          {!permissions
            ? (<Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                <h3>¡No hay permisos o no hay conexión al servicio de permisos!</h3>
              </Grid>
            </Grid>
            ) : (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={3}>
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AT_CREAR}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Crear nuevo registro"}
                    functionOnClick={handleOpenModalUpload}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder={"Buscar registros..."}
                    type={"text"}
                    value={search}
                    onChange={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        search.length > 0 && (
                          <InputAdornment position="end">
                            <Clear sx={{ color: "#1638F2" }} onClick={handleClearSearch} />
                          </InputAdornment>
                        )
                      ),
                    }}
                    sx={{
                      backgroundColor: "#FFF",
                      borderRadius: "16px",
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Grid item md={12} sx={{ marginBottom: '1rem' }}>
                    <TableContainer component={Paper} sx={{ maxHeight: '60vh', marginBottom: '0' }}>
                      <Table sx={{ minWidth: '100%', }} stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {
                              [
                                '#', 'Código', 'Gravedad', 'Fecha', 'Lugar', 'Contratista','Estado', ''
                              ].map((item, index) => (
                                <TableCell
                                  key={index}
                                  sx={{
                                    color: "#1638F2",
                                    fontSize: "18px",
                                    lineHeight: "1.2rem",
                                    alignSelf: "flex-start",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    ...(index === 0 ? { width: 'min-content', backgroundColor: "#fafafa", } : {}),
                                  }}
                                >
                                  {item}
                                </TableCell>
                              ))
                            }
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {rowsActives.map((row, index) => (
                            <TableRow
                              key={row.id_registers_accidents}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                                padding: "4px",
                              }}
                            >
                              <TableCell component="th" scope="row" sx={{
                                width: 'min-content', color: "#1638F2", backgroundColor: "#fafafa"
                              }}>
                                {index + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.code}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                                  {row.leves > 0 ? <span>Leves: {row.leves},</span> : ''}
                                  {row.incapacitantes > 0 ? <span>Incapacitantes: {row.incapacitantes},</span> : ''}
                                  {row.mortales && row.mortales > 0 ? <span>Motarles: {row.mortales}</span> : ''}
                                </div>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {
                                  // Formateo el timestap para el formato de fecha de Perú
                                  row.timestamp_accident ? new Date(row.timestamp_accident).toLocaleString('es-PE', { timeZone: 'America/Lima' }) : ''
                                }
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.location}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.company_names ?? ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.status}
                              </TableCell>

                              <TableCell align="right">
                                <Stack
                                  direction="row"
                                  justifyContent="end"
                                  alignItems="center"
                                >{row.status === 'Creado' ? (
                                  <ButtonIconControla
                                    roles={permissions.DOC_REGIS_AT_EDITAR}
                                    icon={
                                      <BorderColorOutlined
                                        sx={{ color: "white" }}
                                      />
                                    }
                                    backgroundColor={"#2D9CDB"}
                                    backgroundColorHover={"#33AEF4"}
                                    textTooltip={"Editar"}
                                    isNavegationOrigin={true}
                                    directionUrl={'/documentation/registers/generals/accidents/edit?idRegistersAccidents=' + row.id_registers_accidents}
                                  />) : ''}
                                  {row.status === 'Finalizado' ? (

                                    <ButtonIconControla
                                      roles={permissions.DOC_REGIS_AT_VER}
                                      icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                      backgroundColor={"#305AD9"}
                                      backgroundColorHover={"#0E4AFF"}
                                      textTooltip={"Ver"}
                                      isNavegationOrigin={true}
                                      directionUrl={'/documentation/registers/generals/accidents/edit?idRegistersAccidents=' + row.id_registers_accidents + "&readOnly=true"}
                                    />) : ''}
                                </Stack>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* //TODO: ------------- Paginación en español ------------- */}
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      component="div"
                      count={totalRows}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage="Filas por página:"
                      labelDisplayedRows={() => `${page + 1}-${lastPage} de ${totalRows}`}
                      getItemAriaLabel={(type) => {
                        if (type === 'first') {
                          return 'Ir a la primera página';
                        }
                        if (type === 'last') {
                          return 'Ir a la última página';
                        }
                        if (type === 'next') {
                          return 'Ir a la página siguiente';
                        }
                        if (type === 'previous') {
                          return 'Ir a la página anterior';
                        }
                      }}
                      sx={{
                        width: '100%',
                        backgroundColor: "#FFF",
                        border: "1px solid #E0E0E0",
                        color: "#1638F2",
                        fontSize: "18px",
                        lineHeight: "1.2rem",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          }
        </Box>
      </Container >

      <Dialog
        open={openModalUpload}
        onClose={handleCloseModalUpload}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar código del registro del accidente
          </Typography>
        </DialogTitle>
        <form onSubmit={handlePostCreateRegistersAccidents}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <InputControlaVertical
              text={"Código"}
              inputType={"text"}
              inputValue={setCode}
              modalType={true}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUpload}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

    </Box >
  );
}

export default Index;
