import { NavigateBefore, NavigateNext } from "@mui/icons-material"
import { Box, Button } from "@mui/material"

const Pagination = ({handlePageChange, currentPage, lastPage }) => {
  return (
    <Box display='flex' justifyContent='center' gap='10px' marginTop='10px' marginBottom='10px'>
      <Button
        variant="contained"
        startIcon={<NavigateBefore />}
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Anterior
      </Button>
      <Button
        variant="contained"
        endIcon={<NavigateNext />}
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage >= lastPage}
      >
        Siguiente
      </Button>
    </Box>
  )
}

export default Pagination