import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import {
    BorderColorOutlined,
    VisibilityOutlined,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import { useAppContext } from "../../../../../context/AppContext";


function ListActives(props) {
    const {permissions} = useAppContext();
    const {
        rowsActives,
        colorcell
    } = props;
    return (
        <TableBody>
            {rowsActives.map((row) => (
                <TableRow
                    key={row.id}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        padding: "4px",
                    }}
                >
                    <TableCell sx={{ color: colorcell }} component="th" scope="row">
                        {row.code}
                    </TableCell>
                    <TableCell sx={{ color: colorcell }} component="th" scope="row">
                        {row.register_status === 1 ? 'Creado' : 'Finalizado'}
                    </TableCell>

                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >{row.register_status === 1 ? (
                            <ButtonIconControla
                                roles={permissions.DOC_REGIS_INCID_PELIGRO_EDITAR}
                                icon={<BorderColorOutlined  sx={{ color: "white" }}/>}
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                isNavegationOrigin={true}
                                directionUrl={'/documentation/registers/generals/incidentsdangerousincidents/edit?idRegistersDangerousIncidents=' + row.id}
                            />) : ''}

                            {row.register_status === 2 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_REGIS_INCID_PELIGRO_VER}
                                    icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                    backgroundColor={"#305AD9"}
                                    backgroundColorHover={"#0E4AFF"}
                                    textTooltip={"Ver"}
                                    isNavegationOrigin={true}
                                    directionUrl={'/documentation/registers/generals/incidentsdangerousincidents/edit?idRegistersDangerousIncidents=' + row.id + "&readOnly=true"}
                                />) : ''}
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>

    );
}
export default ListActives;