import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Grid,
  IconButton,
  MenuItem,
  Snackbar,
  Stack,
} from "@mui/material";
import Container from "@mui/material/Container";
import { Add, ArrowBack, Close } from "@mui/icons-material";
import SubtitleText from "../../components/text/subtitleText";
import TitleText from "../../components/text/titleText";
import MyUsers from "../../api/MyUsers";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import SelectYesNotControla from "../../components/selects/selectYesNotControla";
import ButtonControla from "../../components/buttons/buttonController";
import SelectControla from "../../components/selects/selectControla";
import MyCampus from "../../api/MyCampus";
import MyCompanies from "../../api/MyCompanies";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../components/buttons/returnBackControla";

const grey = "#9191B0";

function Create() {
  const navigate= useNavigate();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, /*vertical, horizontal,*/ openAlert } =
    stateAlert;

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [timestampBirth, setTimestampBirth] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [documentType, setDocumentType] = useState(1);
  const [document, setDocument] = useState("");
  const [disability, setDisability] = useState(1);
  const [timestampStart, setTimestampStart] = useState("");
  const [timestampEnd, setTimestampEnd] = useState("");
  const [position, setPosition] = useState("");
  const [campus, setCampus] = useState("");
  const [sctr, setSctr] = useState("");
  const [unionized, setUnionized] = useState("");
  const [jobType, setJobType] = useState("");
  const [healthEntity, setHealthEntity] = useState("");
  const [firm, setFirm] = useState(null);

  const [rowsDocumentsTypes, setRowsDocumentsTypes] = useState([]);
  const [rowsGenders, setRowsGenders] = useState([]);
  const [rowsPositions, setRowsPositions] = useState([]);
  const [rowsCampus, setRowsCampus] = useState([]);
  const [rowsJobTypes, setRowsJobTypes] = useState([]);
  const [rowsHealthEntity, setRowsHealthEntity] = useState([]);

  const handleGetDocumentsTypes = async () => {
    const response = await MyUsers.documentsTypes();
    if (response.success !== false) {
      setRowsDocumentsTypes(response);
    } else {
      alert("Error al listar los documentos!");
    }
  };

  const handleGetGenders = async () => {
    const response = await MyUsers.genders();
    if (response.success !== false) {
      setRowsGenders(response);
    } else {
      alert("Error al listar los generos!");
    }
  };

  const handleGetPositions = async () => {
    const response = await MyUsers.positions();
    if (response.success !== false) {
      setRowsPositions(response);
    } else {
      alert("Error al listar los cargos!");
    }
  };

  const handleGetCampus = async () => {
    const response = await MyCampus.campus();
    if (response.success !== false) {
      setRowsCampus(response);
    } else {
      alert("Error al listar las sedes!");
    }
  };

  const handleGetJobTypes = async () => {
    const response = await MyUsers.jobTypes();

    if (response.success !== false) {
      setRowsJobTypes(response);
    } else {
      alert("Error al listar los tipos de puesto!");
    }
  };

  const handleGetHealthEntity = async () => {
    const response = await MyCompanies.healthEntity();

    if (response.success !== false) {
      setRowsHealthEntity(response);
    } else {
      alert("Error al listar las entidades de salud!");
    }
  };

  useEffect(() => {
    handleGetDocumentsTypes();
    handleGetGenders();
    handleGetPositions();
    handleGetCampus();
    handleGetJobTypes();
    handleGetHealthEntity();
  }, []);

  const handleStoreUsers = async (event) => {
    event.preventDefault();
    setOpenBackdrop(true);
    const response = await MyUsers.storeUsers({
      first_name: firstName,
      last_name: lastName,
      id_genders: gender,
      phone: phone,
      id_documents_types: documentType,
      document: document,
      birthday: timestampBirth,
      email: email,
      id_positions: position,
      disability: disability,
      sctr: sctr,
      unionized: unionized,
      id_campus: campus,
      id_job_types: jobType,
      start_date: timestampStart,
      end_date: timestampEnd,
      id_health_entity: healthEntity,
      firm_url: firm,
    });
    if (response.success !== false) {
      navigate("/settings/users")
    } else {
      setStateAlert({
        openAlert: true,
        severityAlert: "error",
        messageAlert: response.msg,
      });
      setOpenBackdrop(false);
    }
  };

  return (
    <>
      <Box>
        <ReturnBack/>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openAlert}
          onClose={handleCloseAlert}
          key={"top" + "center"}
          autoHideDuration={3000}
        >
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  handleCloseAlert();
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            severity={severityAlert}
            size="small"
          >
            {messageAlert}
          </Alert>
        </Snackbar>

        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
                <SubtitleText text={"Usuarios"} color={grey}></SubtitleText>
                <TitleText text={"Crear usuario"} />
              </Grid>
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                <CompanyLogoControla />
              </Grid>
            </Grid>
            <form onSubmit={handleStoreUsers}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Nombres:"}
                    inputType={"text"}
                    inputValue={setFirstName}
                    modalType={false}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Apellidos:"}
                    inputType={"text"}
                    inputValue={setLastName}
                    modalType={false}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Correo:"}
                    inputType={"text"}
                    inputValue={setEmail}
                    modalType={false}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Fecha de nacimiento:"}
                    inputType={"date"}
                    inputValue={setTimestampBirth}
                    modalType={false}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"N° de celular:"}
                    inputType={"number"}
                    inputValue={setPhone}
                    modalType={false}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectControla
                    text={"Genero:"}
                    inputValue={setGender}
                    modalType={false}
                    childrenRows={rowsGenders.map((row) => (
                      <MenuItem
                        key={row.id_users_genders}
                        value={row.id_users_genders}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectControla
                    text={"Tipo de documento:"}
                    inputValue={setDocumentType}
                    modalType={false}
                    childrenRows={rowsDocumentsTypes.map((row) => (
                      <MenuItem
                        key={row.id_users_documents_types}
                        value={row.id_users_documents_types}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Documento de identidad:"}
                    inputType={"text"}
                    inputValue={setDocument}
                    modalType={false}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectYesNotControla
                    text={"Discapacidad:"}
                    inputValue={setDisability}
                    modalType={false}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Fecha de inicio:"}
                    inputType={"date"}
                    inputValue={setTimestampStart}
                    modalType={false}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Fecha de fin:"}
                    inputType={"date"}
                    inputValue={setTimestampEnd}
                    modalType={false}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectControla
                    text={"Cargo:"}
                    inputValue={setPosition}
                    modalType={false}
                    childrenRows={rowsPositions.map((row) => (
                      <MenuItem key={row.id_positions} value={row.id_positions}>
                        {row.name}
                      </MenuItem>
                    ))}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectControla
                    text={"Sede:"}
                    inputValue={setCampus}
                    modalType={false}
                    childrenRows={rowsCampus.map((row) => (
                      <MenuItem key={row.id_campus} value={row.id_campus}>
                        {row.name}
                      </MenuItem>
                    ))}
                  />
                </Grid> */}
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectYesNotControla
                    text={"SCTR:"}
                    inputValue={setSctr}
                    modalType={false}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectYesNotControla
                    text={"Sindicalizado:"}
                    inputValue={setUnionized}
                    modalType={false}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectControla
                    text={"Tipo de puesto:"}
                    inputValue={setJobType}
                    modalType={false}
                    childrenRows={rowsJobTypes.map((row) => (
                      <MenuItem key={row.id_job_types} value={row.id_job_types}>
                        {row.name}
                      </MenuItem>
                    ))}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectControla
                    text={"Entidad de salud:"}
                    inputValue={setHealthEntity}
                    modalType={false}
                    childrenRows={rowsHealthEntity.map((row) => (
                      <MenuItem
                        key={row.id_health_entity}
                        value={row.id_health_entity}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Firma:"}
                    inputType={"file"}
                    inputValue={setFirm}
                    modalType={false}
                  />
                </Grid>
              </Grid>

              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="start"
                sx={{ marginTop: 3 }}
              >
                <Box>
                  <ButtonControla
                  roles={[1,5,6,7,8]}
                    iconButton={<ArrowBack sx={{ color: "white" }} />}
                    textButton={"Regresar"}
                    backgroundColor={"#CBCBFF"}
                    backgroundColorHover={"#d6d6ff"}
                    isNavegationOrigin={true}
                    url={"/settings/users/"}
                  />
                </Box>
                <Box>
                  <ButtonControla
                  roles={[1,5,6,7,8]}
                    iconButton={<Add />}
                    textButton={"Crear usuario"}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    typeButton={"submit"}
                    tooltipText={
                      "Recuerda que una vez creado el usuario debes asignarle roles para que pueda acceder al sistema"
                    }
                  />
                </Box>
              </Stack>
            </form>
          </Box>
        </Container>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openBackdrop}
        >
          {/* <CircularProgress color="inherit" /> */}
          <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
        </Backdrop>
      </Box>
    </>
  );
}

export default Create;
