import MyConfigurationApi from "./MyConfigurationApi";

function getPermissions (){
    const url = "permissions";
    return MyConfigurationApi.connectFormApiGeneral({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

const MyPermissions = {
    getPermissions
}

export default MyPermissions;