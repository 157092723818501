import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getRegistersDangerousIncident() {
  const url = "registers_general_dangerous_incident_store";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createRegistersIncidentDangerous({ code }) {
  const url = "registers_general_dangerous_incident_store";
  const data = {
    code: code,
    status: "Creado",
    registers_dangerous_incident_type:'',
    id_registers_dangerous_incident_status:1,
    affected_workers: '',
    affected_residents: '',
    detail_type_attention: '',
    timestamp_dangerous_incident: '',
    timestamp_investigation: '',
    location_dangerous_incident: '',
    description: '',
    document_url_1: '',
    document_url_2: '',
    document_url_3: '',
    causes_description: '',
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// typos de incidente pelogroso
function getRegistersDangerousIncidentType() {
  const url = "dangerous_incident_type";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// actualizar registros de incidentes peligrosos

async function updateRegistersDangerousIncident({
  idRegistersDangerousIncidents,
  code,
  registers_dangerous_incident_type,
  timestamp_accident,
  timestamp_investigation,
  location,
  affected_residents,
  detail_type_attention,
  affected_workers,
  description,
  document_url_1,
  document_url_2,
  document_url_3,
  causes_description,
  register_status
}) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();
 
if (document_url_1 === '') {
  document_url_1 = document_url_1;
} else {
  if (typeof document_url_1 === 'string' &&  document_url_1.substring(0,38)==='https://firebasestorage.googleapis.com') {
    document_url_1 = document_url_1;
  } else {
    document_url_1 = await uploadFileCompanies(
      document_url_1,
      "documentation/registers/generals/accidents/" +
      year +
      "/" +
      month +
      "/" +
      day +
      "/"
    );
  }
}

  if (document_url_2 !== '' && document_url_2 !== undefined && document_url_2.length !== 0) {
    if (typeof document_url_2 === 'string' && document_url_2.substring(0,38)==='https://firebasestorage.googleapis.com') {
      document_url_2 = document_url_2;
    } else {
      document_url_2 = await uploadFileCompanies(
        document_url_2,
        "documentation/registers/generals/accidents/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
      );
    }
  }

if (document_url_3 !== '' && document_url_3 !== undefined && document_url_3.length !== 0) {
    if (typeof document_url_3 === 'string' && document_url_3.substring(0,38)==='https://firebasestorage.googleapis.com') {
      document_url_3 = document_url_3;
    } else {
      document_url_3 = await uploadFileCompanies(
        document_url_3,
        "documentation/registers/generals/accidents/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
      );
    }
  }

  const url = "registers_general_dangerous_incident_store/" + idRegistersDangerousIncidents;

  const data = {
    code: code,
    registers_dangerous_incident_type:registers_dangerous_incident_type,
    affected_workers:affected_workers,
    affected_residents:affected_residents,
    detail_type_attention:detail_type_attention,
    timestamp_dangerous_incident: timestamp_accident,
    timestamp_investigation: timestamp_investigation,
    location_dangerous_incident: location, 
    description: description,
    document_url_1: document_url_1,
    document_url_2: document_url_2,
    document_url_3: document_url_3,
    causes_description: causes_description,
    register_status:register_status
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function editRegistersDangerousIncident({ idRegistersDangerousIncidents }) {
  const url = "registers_general_dangerous_incident_store/" + idRegistersDangerousIncidents;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}
// TRABAJADOR INVOLUCRADO
// registro de trabajadores involucrados
async function createWorkersInvolved({ idRegistersDangerousIncident, id_users, area, shift, day_experience, month_experience, year_experience,  work_hours_before, nameResponsibleInput,responsibleDocumentInput}) {
  const url = "sec/dangerous_incident_workers_involved/" + idRegistersDangerousIncident;
  const data = {   
    "day_experience":day_experience,
    "month_experience":month_experience,
    "year_experience":year_experience,
    "area": area,
    "shift": shift,
    "work_hours_before": work_hours_before,
    "id_users": id_users,
    "name_user_responsible":nameResponsibleInput,
    "document_user_responsible":responsibleDocumentInput,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// jalar registros de trabajadores involucrados
function getWorkersInvolved({ idRegistersDangerousIncident }) {
    const url = "sec/dangerous_incident_workers_involved/" + idRegistersDangerousIncident;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "GET",
      url: url,
    });
  }

  // eliminar trabajador involucrado
  async function deleteWorkerInvolved({ idWorkerInvolved }) {
    const url ="sec/dangerous_incident_workers_involved/detail/"+ idWorkerInvolved;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }

//   // Usuarios a buscar al agregar trabajadores
// function getUserSearchsAddsWorkers({ idRegistersDangerousIncident }) {
//   const url =
//     "registers/general/accidents/workers/users/get/" + idRegistersDangerousIncident;
//   return MyConfigurationApi.connectApi({
//     dataJson: null,
//     method: "GET",
//     url: url,
//   });
// }

// MEDIDAS CORRECTIVAS
// jalar status de medidas correctivas
function getCorrectiveActionsSatus() {
  const url = "corrective_actions_status";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// registro de 
async function createCorrectiveActions({ 
  idRegistersDangerousIncident,
   id_dangerous_incident_corrective_actions_status,
   id_users,name_user_responsible,
   document_user_responsible,timestamp_start,
   timestamp_end,description
  }) {
  const url = "sec/dangerous_incident_corrective_actions/" + idRegistersDangerousIncident;
  const data = {   
    "id_dangerous_incident_corrective_actions_status":id_dangerous_incident_corrective_actions_status,
    "id_users":id_users,
    "name_user_responsible":name_user_responsible,
    "document_user_responsible": document_user_responsible,
    "timestamp_start": timestamp_start,
    "timestamp_end": timestamp_end === null ? '': timestamp_end,
    "description": description
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// jalar registros de medidas correctivas
function getCorrectiveActions({ idRegistersDangerousIncident }) {
  const url = "sec/dangerous_incident_corrective_actions/" + idRegistersDangerousIncident;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}


  // eliminar medida correctiva
  async function deleteCorrectivesActions({ idMeassure }) {
    const url ="sec/dangerous_incident_corrective_actions/detail/"+ idMeassure;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }

  // jalar datos de la medida correctiva 
  async function editCorrectiveActions({ idCorrectiveActions }) {
    const url ="sec/dangerous_incident_corrective_actions/detail/"+ idCorrectiveActions;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "GET",
      url: url,
    });
  }
  // editar medida correctiva 
  async function   UpdateCorrectiveActions({ idCorrectiveActions, id_dangerous_incident_corrective_actions_status }) {  
   
    const url = "sec/dangerous_incident_corrective_actions/detail/" + idCorrectiveActions;
    const data = {
      "id_dangerous_incident_corrective_actions_status":id_dangerous_incident_corrective_actions_status,
    };
    return MyConfigurationApi.connectFormApi({
      dataJson: data,
      method: "PUT",
      url: url,
    });
  }
// TRABAJADORES RESPONSABLES
//get de trabajadores responsables
function getResponsibleWorkers({ idRegistersDangerousIncident }) {
  const url = idRegistersDangerousIncident +"/responsible";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// // crear trabajadores responsables
async function createResponsibleWorkers({
  idRegistersDangerousIncident,
  id_users
}) {
  const url = idRegistersDangerousIncident +"/responsible";
  const data = {
    id_users: id_users,
    firm: 0,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}


// mandar para la firma
async function updateResponsibleWorkers({idResponsible, id_users}) {  
  const url = "responsible/detail/" + idResponsible;
  const data = {  
    "id_users": id_users,
    "firm": 1,
    "firm_date": null
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

  // eliminar 
  async function deleteResponsibleWorker({ idResponsible }) {
    const url ="responsible/detail/"+ idResponsible;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }

  // cierre de registro
async function updateDangerousIncidentStatus({idRegistersDangerousIncidents}) {  
    const url = idRegistersDangerousIncidents+"/status/end";   
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "PUT",
      url: url,
    });
  }
  


const MyDangerousIncident = {
  getRegistersDangerousIncident,
  createRegistersIncidentDangerous,
  getRegistersDangerousIncidentType,
  updateRegistersDangerousIncident,
  editRegistersDangerousIncident,

  // trabajador involucrado
  createWorkersInvolved,
  getWorkersInvolved,
  deleteWorkerInvolved,

  // medidas correctivas
  getCorrectiveActionsSatus,
  createCorrectiveActions,
  getCorrectiveActions,
  deleteCorrectivesActions,
  editCorrectiveActions,
  UpdateCorrectiveActions,

  // responsable
  getResponsibleWorkers,
  createResponsibleWorkers,
  deleteResponsibleWorker,
  updateResponsibleWorkers,

  // cierre de registro
  updateDangerousIncidentStatus,

};
export default MyDangerousIncident;
