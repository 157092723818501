import { React, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Card,
    CardContent,
    CardMedia,
    Grid,
    InputLabel,
    Typography,
    Stack,
    MenuItem,
    Backdrop,
    Snackbar,
    Alert
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
    Add,
    Delete,
    BorderColorOutlined
} from "@mui/icons-material";
import { styled } from "@mui/system";


import DialogConfirmControla from "../components/dialog/dialogConfirmControla.jsx";
import TitleText from "../components/text/titleText.jsx";
import ButtonIconControla from "../components/buttons/buttonIconControla.jsx";
import DialogTitleControla from "../components/dialog/dialogTitleControla.jsx";
import InputControlaVertical from "../components/textfields/inputControlaVertical.jsx";
import SelectControla from "../components/selects/selectControla.jsx";
import ButtonControla from "../components/buttons/buttonController.jsx";
import DialogInformativeControla from "../components/dialog/dialogInformativeControla.jsx";

import MyMemberships from "../api/MyMemberships.jsx";

import { useNavigate } from 'react-router-dom';
import ButtonMailboxControla from "../components/buttons/buttonMailboxControla.jsx";
import ReturnBack from "../components/buttons/returnBackControla.jsx";

const purple = "#8B8BD8";
const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em",
});

function CompanyMemberships () {
    const navigate = useNavigate();


      //Message Alert
      const [stateAlert, setStateAlert] = useState({
        openAlert: false,
        vertical: "top",
        horizontal: "center",
        severityAlert: "success",
        messageAlert: "",
      });
    
    const { severityAlert, messageAlert, vertical, horizontal, openAlert } = stateAlert;

    const handleOpenAlert = (severityAlert, messageAlert)=>{
        setStateAlert({
            openAlert: true,
            vertical: "top",
            horizontal: "right",
            severityAlert: severityAlert,
            messageAlert: messageAlert,
          });
    }

    const handleCloseAlert = () =>{
        setStateAlert({ ...stateAlert, openAlert: false });
    }
    //
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText]= useState("");
    const [contentText, setContentText]= useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");

    const [memberships, setMemberships] = useState([]);
    const [idMemberships, setIdMemberships] = useState(null);
    

    const handleOpenModalConfirm = ( titleText , contentText, buttonTextConfirm, buttonActionType, id) =>{
        setOpenModalConfirm(true);
        
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        buttonActionType === "eliminar sección" ? setIdSectionByMembership(id): setIdMemberships(id);     
    }
    const handleCloseModalConfirm = ()=>{
        setOpenModalConfirm(false);
    }
    const handleActionConfirm = (event)=>{
        switch(buttonActionType){
            case "eliminar membresia":
                handleDeleteMembership();
                break;
            case "eliminar sección":
                handleDeleteSectionByMembership();
                break;
        }
    }


    const [openModalAddMemberships, setOpenModalAddMemberships] = useState(false);

    const [nameMembership, setNameMembership] = useState("");
    const [status, setStatus] = useState("");
   

    const handleOpenModalAddMemberhips = ()=>{
        setOpenModalAddMemberships(true);

        //limpiar
        setNameMembership(null);
        setStatus(null);
    }

    const handleCloseModalAddMemberships = ()=>{
        setOpenModalAddMemberships(false);

        setNameMembership("");
        setStatus("");
    }

    

    const [openModalEditMemberships, setOpenModalEditMemberships] = useState(false);

    const handleOpenModalEditMemberships = (memberships)=>{
        setOpenModalEditMemberships(true);

        setIdMemberships(memberships.id_memberships);
        setNameMembership(memberships.name);
        setStatus(memberships.status === 0 ? 2 : 1);
    }
    const handleCloseModalEditMemberships = ()=>{
        setOpenModalEditMemberships(false);
    }

    const typeStatus = [{"id_status" : 1 , "name": "Activo"},{"id_status" : 2 , "name" : "Inactivo"}];

    const getMemberships = async()=>{
        const res = await MyMemberships.getMemberships();
        setMemberships(res.data); 
    }

    const hanledPostMemberships = async(event) =>{
        event.preventDefault();
        setOpenBackdrop(true);
        const data = {
            "name": nameMembership,
            "status": status === 1 ? 1 : 0
        }
        const resp = await MyMemberships.postMemberships(data);
        if(resp.success === true){
            getMemberships();
            getSection();
            handleCloseBackdrop();
            handleOpenAlert("success", "Se registró membresía con éxito");
        } else{
            handleOpenAlert("error","Ocurrió un error");
        }

        handleCloseModalAddMemberships();

        setStatus("");
        setNameMembership("");
    }
    const hanledUpdateMemberships = async(event) =>{
        event.preventDefault();
        setOpenBackdrop(true);
        const data = {
            "name": nameMembership,
            "status": status === 1 ? 1 : 0
        }

        const resp  = await MyMemberships.updateMemberships(idMemberships,data);
        if(resp.success === true){
            getMemberships();
            getSection();
            handleOpenAlert("success", "Se actualizó la membresía con éxito")

        }else {
            handleOpenAlert("error","Ocurrió un error");
        }
        handleCloseBackdrop();
        handleCloseModalEditMemberships();

        setStatus("");
        setNameMembership("");
    }
    const handleDeleteMembership = async()=>{
        setOpenBackdrop(true);
       
        const resp = await MyMemberships.deleteMemberships(idMemberships);
        if(resp.success === true){
            getMemberships();
            getSection();
            handleCloseModalConfirm();
            handleCloseBackdrop();
            handleOpenAlert("success", "Se eliminó membresía con éxito");

        } else{
            handleOpenAlert("error", "Ocurrió un error");
        } 
    }

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    }

    /*seccion */
    const [openModalSection, setOpenModalSection] = useState(false);

    const handleOpenModalSection = ()=>{
        setOpenModalSection(true);

        // limpiar select
        setTypeMemberships(null);
        setSectionSelected(null);
    }

    const handleCloseModalSection = () =>{
        setOpenModalSection(false);        
    }


    const [sections, setSections] = useState([]);
    const [typeMemberships, setTypeMemberships] = useState("");
    const [sectionSelected, setSectionSelected]= useState("");
    const [sectionAll, setSectionAll] = useState([]);
    const [idSectionByMembership, setIdSectionByMembership] =  useState("");



    const getSection = async()=>{
        const r = await MyMemberships.getSections();
        const arraySections =[];

        const res = await MyMemberships.getMemberships();
 
        res.data.forEach((m, i)=>{
            arraySections.push({
                "name_memberships" : m.name,
                "sections":[]
            })
            r.data.forEach((s)=>{
                if(m.id_memberships === s.id_memberships){
                    arraySections[i].sections.push({
                        "id_config_memberships": s.id_config_memberships,
                        "id_section": s.id_section,
                        "label": s.label,
                        "image":s.image
                    });

                }
            });

        });

        setSections(arraySections);

    }
    const getListSections= async()=>{
        const resp = await MyMemberships.getAllSection();
        setSectionAll(resp.data);
    }

    const handlePostSectionByMembership = async (event) => {
        event.preventDefault();

        setOpenBackdrop(true);

        //verificar si existe la sección en la membresia
        const r = await MyMemberships.getSections();
        const sectionExists = r.data.filter((s) => s.id_memberships === typeMemberships && s.id_section === sectionSelected);

        if (sectionExists.length === 0) {

            const data = {
                "id_memberships": typeMemberships,
                "id_section": sectionSelected,
                "active": 1
            }

            const res = await MyMemberships.postSectionByMembership(data);

            if (res.success === true) {
                getSection();
                handleOpenAlert("success", "Se agregó sección a membresía con éxito");
            } else {
                handleOpenAlert("error", "Ocurrió un error");
            }
        } else {
            setOpenModalInformative(true);
            setMessageModal("La sección ya existe en la membresía");
        }

        handleCloseBackdrop();
        handleCloseModalSection();
    }

    const handleDeleteSectionByMembership = async()=>{
        setOpenBackdrop(true);

        const res = await MyMemberships.deleteSectionByMembership(idSectionByMembership);
 
        if(res.success === true){
            getSection();
            handleOpenAlert("success" , "Se eliminó la sección de la membresía con éxito");
        } else{
            handleOpenAlert("error","Ocurrió un error");
        }

        handleCloseBackdrop();
        handleCloseModalConfirm();
    }

    //modal de información
    const [openModalInformative, setOpenModalInformative] = useState(false);
    const [messageModal, setMessageModal] = useState("");

    const handleCloseModalInformative = ()=>{
        setOpenModalInformative(false);
    }


    useEffect(()=>{
        getMemberships(); 
        getListSections();
           
            
    },[]);

    useEffect(()=>{
        getSection();
    },[]);

    return (
        <Box >
            <ReturnBack/>
            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />

            {/* componente Alert */}
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={3000}
            >
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleCloseAlert();
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity={severityAlert}
                    size="small"
                >
                    {messageAlert}
                </Alert>
            </Snackbar>

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <ButtonMailboxControla
                                originUrl={"/memberships"}
                            />
                        </Grid>
                        <Grid item xs={7} md={7}>
                            <TitleText text="Gestión de membresía" />
                        </Grid>
                    </Grid>

                    <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{ marginTop: 3, marginBottom: 1 }}
                    >
                        <Box>
                            <InputLabel
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Membresía
                            </InputLabel>
                        </Box>
                        <Box>
                            <ButtonControla
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Agregar nueva membresía"}
                                functionOnClick={handleOpenModalAddMemberhips}
                            />
                        </Box>
                    </Stack>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item md={12}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color: "#1638F2",
                                                    fontSize: "18px",
                                                    width: "14%",
                                                }}
                                            >
                                                Membresía
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color: "#1638F2",
                                                    fontSize: "18px",
                                                    width: "14%",
                                                }}
                                            >
                                                Estado
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color: "#1638F2",
                                                    fontSize: "18px",
                                                    width: "14%",
                                                }}
                                            >
                                                Acciones
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {memberships.map((membership) => (
                                            <TableRow
                                                hover
                                                key={membership.id_memberships}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{ color: purple }}
                                                    align="center"
                                                >
                                                    {membership.name}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{ color: purple }}
                                                    align="center"
                                                >
                                                    {membership.status === 1 ? "Activo" : "Inactivo"}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="end"
                                                        alignContent="center"
                                                    >
                                                        <ButtonIconControla
                                                            icon={
                                                                <BorderColorOutlined
                                                                    sx={{ color: "white" }}
                                                                />
                                                            }
                                                            backgroundColor={"#2D9CDB"}
                                                            backgroundColorHover={"#33AEF4"}
                                                            textTooltip={"Editar"}
                                                            functionOnClick={() => { handleOpenModalEditMemberships(membership) }}
                                                        />
                                                        <ButtonIconControla
                                                            icon={<Delete sx={{ color: "white" }} />}
                                                            backgroundColor={"#EB5757"}
                                                            backgroundColorHover={"#FF4040"}
                                                            textTooltip={"Eliminar"}
                                                            functionOnClick={() => {
                                                                handleOpenModalConfirm(
                                                                    "¿Estás seguro de eliminar esta membresía?",
                                                                    "Una vez eliminado no se podrá recuperar información",
                                                                    "Sí, eliminar",
                                                                    "eliminar membresia",
                                                                    membership.id_memberships)
                                                            }}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </Grid>
                    </Grid>

                    <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{ marginTop: 3, marginBottom: 1 }}
                    >
                        <Box>
                            <InputLabel
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Secciones del menú
                            </InputLabel>
                        </Box>
                        <Box>
                            <ButtonControla
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Agregar nueva sección a membresía"}
                                functionOnClick={() => { handleOpenModalSection() }}
                            />
                        </Box>
                    </Stack>
                    {
                        sections.map((m, i) => (
                            <Grid key={i} item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <StyledTypography
                                    sx={{
                                        padding: "8px",
                                        fontSize: "20px",
                                        color: "#305AD9",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Membresía - {m.name_memberships}
                                </StyledTypography>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Paper sx={{ overflow: "hidden" }}>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{ width: "100%", margin: "auto", display: "flex", flexDirection: 'row', flexWrap: 'wrap', columnGap: '80px', rowGap: "-1px" }}
                                        >
                                            {sections[i].sections.length === 0 ? (
                                                <StyledTypography
                                                    sx={{
                                                        padding: "8px",
                                                        fontSize: "18px",
                                                        color: "#305AD9",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    No contiene Secciones.
                                                </StyledTypography>
                                            ) : ""
                                            }
                                            {sections[i].sections.map((s, index) => (
                                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}
                                                    key={index} sx={{ p: 2 }}>
                                                    <Card
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            height: 100,
                                                            width: 350,

                                                            "&:hover": {
                                                                cursor: "pointer",
                                                                border: "1px solid #0F1255",
                                                                boxShadow: "0px 4px #0F1255",
                                                            },
                                                        }}
                                                        onClick={() => {
                                                            navigate("/modulesBySectionMembership?id_config_memberships=" + s.id_config_memberships + "&id_section=" + s.id_section + "&title=" + s.label + "&membresia=" + m.name_memberships);
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{ display: "flex", flexDirection: "column" }}
                                                        >
                                                            <CardContent sx={{ flex: "1 0 auto", display: "flex", alignItems: 'center', textAlign: "center" }}>
                                                                <Typography sx={{ fontSize: "14px" }}>
                                                                    {s.label}
                                                                </Typography>
                                                            </CardContent>
                                                        </Box>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{
                                                                maxWidth: 100,
                                                                marginLeft: 0,
                                                                maxHeight: 100,
                                                                objectFit: "contain",
                                                            }}
                                                            image={s.image}
                                                            alt="Live from space album cover"
                                                        />
                                                        <Box
                                                            sx={{ display: "flex", flexDirection: "row", gap: "3px", width: "auto" }}
                                                        >
                                                            <CardContent sx={{ flex: "1 0 auto", display: "flex", flexDirection: "row", gap: "3px", alignItems: 'center' }}>
                                                                <ButtonIconControla
                                                                    icon={<Delete sx={{ color: "white" }} />}
                                                                    backgroundColor={"#EB5757"}
                                                                    backgroundColorHover={"#FF4040"}
                                                                    textTooltip={"Eliminar"}
                                                                    functionOnClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleOpenModalConfirm(
                                                                            "¿Estás seguro de eliminar esta sección de la membresía seleccionada?",
                                                                            "Una vez eliminado no se podrá recuperar información",
                                                                            "Sí, eliminar",
                                                                            "eliminar sección",
                                                                            s.id_config_memberships)
                                                                    }}
                                                                />

                                                                {/* {
                                                                    s.active === 0 ? (
                                                                        <ButtonIconControla
                                                                            icon={<ToggleOnIcon sx={{ color: "white" }} />}
                                                                            backgroundColor={"#169073"}
                                                                            backgroundColorHover={"#1AA483"}
                                                                            textTooltip={"Activar"}
                                                                            functionOnClick={() => {
                                                                                
                                                                            }}
                                                                        />
                                                                    ) : ""
                                                                } */}
                                                                {/* {
                                                                    s.active === 1 ? (
                                                                        <ButtonIconControla
                                                                            icon={<ToggleOffIcon sx={{ color: "white" }} />}
                                                                            backgroundColor={"#EB5757"}
                                                                            backgroundColorHover={"#FF4040"}
                                                                            textTooltip={"Desactivar"}
                                                                            functionOnClick={() => {
                                                                             
                                                                                   
                                                                            }}
                                                                        />

                                                                    ) : ""

                                                                } */}
                                                            </CardContent>
                                                        </Box>

                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>

                        ))
                    }




                </Box>

            </Container>

            {/* modal agregar membresia */}
            <Dialog
                open={openModalAddMemberships}
                onClose={handleCloseModalAddMemberships}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitleControla
                    titleText="Agregar Membresía"
                    functionOnClose={handleCloseModalAddMemberships}
                />
                
                <form onSubmit={hanledPostMemberships}>
                    <DialogContent>
                        <InputControlaVertical
                            text={"Nombre"}
                            inputType={"text"}
                            inputValue={setNameMembership}
                            modalType={true}
                            value={nameMembership || ''}
                            required
                        />
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SelectControla
                                text="Estado:"
                                inputValue={setStatus}
                                modalType={true}
                                required
                                value={status || ''}
                                childrenRows={typeStatus.map((rows) => (
                                    <MenuItem key={rows.id_status} value={rows.id_status}>
                                        {rows.name}
                                    </MenuItem>
                                ))}
                            />
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={handleCloseModalAddMemberships}
                                />

                            </Box>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={"Guardar"}
                                    typeButton={"submit"}
                                />

                            </Box>
                        </Stack>

                    </DialogActions>

                </form>
                

            </Dialog>

            {/* modal de editar */}
            <Dialog
                open={openModalEditMemberships}
                onClose={handleCloseModalEditMemberships}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitleControla
                    titleText="Editar Membresía"
                    functionOnClose={handleCloseModalEditMemberships}
                />
                <form onSubmit={hanledUpdateMemberships}>
                    <DialogContent>
                        <InputControlaVertical
                            text={"Nombre"}
                            inputType={"text"}
                            inputValue={setNameMembership}
                            modalType={true}
                            value={nameMembership || ''}
                            required
                        />
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SelectControla
                                text="Estado:"
                                inputValue={setStatus}
                                modalType={true}
                                required
                                value={status || ''}
                                childrenRows={typeStatus.map((rows) => (
                                    <MenuItem key={rows.id_status} value={rows.id_status}>
                                        {rows.name}
                                    </MenuItem>
                                ))}
                            />
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={handleCloseModalEditMemberships}
                                />
                            </Box>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={"Actualizar"}
                                    typeButton={"submit"}
                                />

                            </Box>
                        </Stack>

                    </DialogActions>

                </form>

            </Dialog>

            {/* modal de secciones */}
            <Dialog
                open={openModalSection}
                onClose={handleCloseModalSection}
                maxWidth="sm"
                fullWidth={true}

            >
                <DialogTitleControla
                    titleText={"Agregar sección a membresía"}
                    functionOnClose={handleCloseModalSection}
                />
                <form onSubmit={handlePostSectionByMembership}> 
                    <DialogContent>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SelectControla
                                text="Membresia:"
                                inputValue={setTypeMemberships}
                                modalType={true}
                                required
                                value={typeMemberships || ''}
                                childrenRows={memberships.map((rows) => (
                                    <MenuItem key={rows.id_memberships} value={rows.id_memberships}>
                                        {rows.name}
                                    </MenuItem>
                                ))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SelectControla
                                text="Sección:"
                                inputValue={setSectionSelected}
                                modalType={true}
                                required
                                value={sectionSelected || ''}
                                childrenRows={sectionAll.map((rows) => (
                                    <MenuItem key={rows.id_section} value={rows.id_section}>
                                        {rows.label}
                                    </MenuItem>
                                ))}
                            />
                        </Grid>


                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={handleCloseModalSection}
                                />
                            </Box>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={"Guardar"}
                                    typeButton={"submit"}
                                />
                            </Box>
                        </Stack>

                    </DialogActions>

                </form>
            </Dialog>
            <DialogInformativeControla
             open={openModalInformative}
             onClose={handleCloseModalInformative}
             message={messageModal}

            />

             {/* mostrar loading  */}
             <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 9999,
                }}
                open={openBackdrop}
            >
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>

        </Box>
    );

}
export default CompanyMemberships;