import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import {
  BorderColorOutlined,
  VisibilityOutlined,
  Archive,
  FileUploadOutlined,
  Delete,
  Send,
} from "@mui/icons-material";

import { useAppContext } from "../../../../../context/AppContext";

function ListActives(props) {
  const { permissions } = useAppContext();

  const {
    rowsActives,
    documentType,
    idUserLogged,
    handleOpenModalConfirm,
    handleOpenModalEditUpload,
  } = props;

  return (
    <TableBody>
      {rowsActives.map((rowsActives, index) => (
        <TableRow
          hover
          key={rowsActives.id_ats}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell component="th" scope="row">
            {index + 1}
          </TableCell>
          <TableCell component="th" scope="row">
            {rowsActives.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {rowsActives.area}
          </TableCell>
          <TableCell component="th" scope="row">
            {rowsActives.document_url !== null &&
            rowsActives.document_url !== undefined
              ? "Archivo" : "Sistema"}
          </TableCell>
          <TableCell component="th" scope="row">
            {rowsActives.timestamp_modified ? new Date(rowsActives.timestamp_modified).toLocaleString('es-PE', { timeZone: 'America/Lima' }) : ''}
          </TableCell>
          <TableCell component="th" scope="row">
            {rowsActives.status}
          </TableCell>
          <TableCell align="right">
            <Stack direction="row" justifyContent="end" alignItems="center">
              {rowsActives.id_ats_statuses === 1 &&
              rowsActives.document_url === null ? (
                <ButtonIconControla
                  roles={permissions.DOC_HG_PROC_ATS_ACTI_EDITAR}
                  icon={<BorderColorOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textTooltip={"Editar"}
                  isNavegationOrigin={true}
                  directionUrl={`/ats/edit?id_ats=${rowsActives.id_ats}`}
                />
              ) : (
                ""
              )}

              {rowsActives.id_ats_statuses === 2 &&
              rowsActives.document_url === null ? (
                rowsActives.personal_executors > 0 ||
                rowsActives.firm_supervisor_area == null ||
                rowsActives.firm_supervisor_responsible_work == null ? (
                  <ButtonIconControla
                    roles={
                      rowsActives.personal_executors > 0 ||
                      rowsActives.supervisor_responsible_work == idUserLogged ||
                      rowsActives.supervisor_area == idUserLogged
                        ? []
                        : permissions.DOC_HG_PROC_ATS_ACTI_EDITAR
                    }
                    icon={<BorderColorOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#33AEF4"}
                    textTooltip={"Editar"}
                    isNavegationOrigin={true}
                    directionUrl={"/ats/edit?id_ats=" + rowsActives.id_ats}
                  />
                ) : (
                  ""
                )
              ) : (rowsActives.id_ats_statuses === 1 ||
                  rowsActives.id_ats_statuses === 2) &&
                rowsActives.document_url !== null ? (
                <ButtonIconControla
                  roles={permissions.DOC_HG_PROC_ATS_ACTI_EDITAR}
                  icon={<BorderColorOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textTooltip={"Editar"}
                  functionOnClick={() => {
                    handleOpenModalEditUpload(
                      rowsActives.id_ats,
                      rowsActives.name,
                      rowsActives.code
                    );
                  }}
                />
              ) : (
                ""
              )}

              {rowsActives.id_ats_statuses === 2 ||
              (rowsActives.id_ats_statuses === 1 &&
                rowsActives.document_url !== null) ? (
                <ButtonIconControla
                  roles={permissions.DOC_HG_PROC_ATS_ACTI_PUBLICAR}
                  icon={<FileUploadOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1AA483"}
                  textTooltip={"Publicar"}
                  functionOnClick={() => {
                    const data = {
                      message: "Se publicó un nuevo ATS",
                      document_url: "/ats/edit?id_ats=" + rowsActives.id_ats,
                      id_users_created: rowsActives.id_users_created,
                      id_users_modified: rowsActives.id_users_modified,
                      id_users_sender: idUserLogged,
                      id_users_receiving: null,
                    };

                    handleOpenModalConfirm(
                      rowsActives.id_ats,
                      "¿Estás seguro de publicar el ATS?",
                      'Una vez publicado se cerrará el ATS:  "' +
                        rowsActives.name +
                        '"',
                      "Si, publicar",
                      "public",
                      data
                    );
                  }}
                />
              ) : (
                <></>
              )}

              {rowsActives.document_url !== null ? (
                <ButtonIconControla
                  roles={permissions.DOC_HG_PROC_ATS_ACTI_VER}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver"}
                  directionUrl={rowsActives.document_url}
                  directionTarget={"_blank"}
                />
              ) : rowsActives.id_ats_statuses == 3 ? (
                <ButtonIconControla
                  roles={permissions.DOC_HG_PROC_ATS_ACTI_VER}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver"}
                  isNavegationOrigin={true}
                  directionUrl={`/ats/edit?id_ats=${rowsActives.id_ats}&readOnly=true`}
                />
              ) : (
                ""
              )}
              {rowsActives.document_url == null &&
              rowsActives.id_ats_statuses == 2 ? (
                <ButtonIconControla
                  roles={
                    rowsActives.personal_executors > 0 ||
                    rowsActives.supervisor_responsible_work == idUserLogged ||
                    rowsActives.supervisor_area == idUserLogged ||
                    rowsActives.team_leader_work == idUserLogged
                      ? []
                      : permissions.DOC_HG_PROC_ATS_MOD_AGREGAR_MODELO
                  }
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver"}
                  isNavegationOrigin={true}
                  directionUrl={`/ats/edit?id_ats=${rowsActives.id_ats}&readOnly=true`}
                />
              ) : (
                ""
              )}

              {rowsActives.id_ats_statuses === 3 ? (
                <ButtonIconControla
                  roles={permissions.DOC_HG_PROC_ATS_ACTI_ARCHIVAR}
                  icon={<Archive sx={{ color: "white" }} />}
                  backgroundColor={"#F2994A"}
                  backgroundColorHover={"#FF881E"}
                  textTooltip={"Archivar"}
                  functionOnClick={() =>
                    handleOpenModalConfirm(
                      rowsActives.id_ats,
                      "¿Estás seguro de archivar el ATS?",
                      'ATS a archivar:  "' + rowsActives.name + '"',
                      "Si, archivar",
                      "archived"
                    )
                  }
                />
              ) : (
                ""
              )}
              {rowsActives.id_ats_statuses === 1 ? (
                <ButtonIconControla
                  roles={permissions.DOC_HG_PROC_ATS_ACTI_ELIMINAR}
                  icon={<Delete sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textTooltip={"Eliminar"}
                  functionOnClick={() =>
                    handleOpenModalConfirm(
                      rowsActives.id_ats,
                      "¿Estás seguro de eliminar el ATS?",
                      'Una vez eliminado no se podrá recuperar todo lo incluido en el ATS:  "' +
                        rowsActives.name +
                        '"',
                      "Si, eliminar",
                      "delete"
                    )
                  }
                />
              ) : (
                ""
              )}
              {rowsActives.id_ats_statuses === 1 &&
              rowsActives.document_url === null ? (
                <ButtonIconControla
                  roles={permissions.DOC_HG_PROC_ATS_ACTI_EDITAR}
                  icon={<Send sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1AA483"}
                  textTooltip={"Enviar a trabajadores"}
                  functionOnClick={() => {
                    handleOpenModalConfirm(
                      rowsActives.id_ats,
                      "Enviar a trabajadores",
                      "¿Estás seguro de enviar a trabajadores?",
                      "Si, enviar",
                      "sendWorkersByTemplate",
                      ""
                    );
                  }}
                />
              ) : (
                ""
              )}
            </Stack>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}

export default ListActives;
