import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { React, useState } from "react";

function SelectControlaHorizontal(props) {

    const { disabled = false,text, inputValue, modalType = false, value = undefined, childrenRows, ...other } = props;

    return (
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}
        justifyContent="space-between"
                    direction="row"
                    alignItems="center"

        >
            <Grid item xs={7} sm={7} md={7} lg={7} xl={7} disabled={disabled}>
                <InputLabel
                    sx={{
                        padding: '8px',
                        fontSize: '16px',
                        color: '#305AD9',
                        fontWeight: 'bold'
                    }}> {text} </InputLabel>
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <Select
                    size="small"
                    value={(value === undefined || value === null ? undefined : value)}
                    // defaultValue={value}
                    displayEmpty
                    onChange={(event) => { inputValue(event.target.value) }}
                    sx={{
                        fontFamily: 'Roboto',
                        width: "100%",
                        backgroundColor: (modalType === true ? '#F5F5F5' : '#FFFFFF'),
                        height: "42px",
                        color: '#305AD9',
                    }}
                    {...other}
                >
                    {/* <MenuItem value={0}>Seleccione</MenuItem> */}
                    {childrenRows}
                </Select>

            </Grid>
        </Grid >
    );
}

export default SelectControlaHorizontal;