import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import SubtitleText from "../components/text/subtitleText";
import TitleText from "../components/text/titleText";
import MyBaseline from "../api/MyBaseline";
import InputControlaVertical from "../components/textfields/inputControlaVertical";
import { Add } from "@mui/icons-material";
import ButtonControla from "../components/buttons/buttonController";
import SearchButtonControla from "../components/search/searchButtonControla";
import MyTasks from "../api/MyTasks";
import SearchUsersControla from "../components/search/searchUsersControla";
import MyUsers from "../api/MyUsers";
import { useModulesFree } from "./hooks/userModulesFree";
import DialogInformativeControla from "../components/dialog/dialogInformativeControla";
import ReturnBack from "../components/buttons/returnBackControla";

const grey = "#9191B0";
const blue = "#034AFF";
const purple = "#8B8BD8";

const StyledBorderLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
});

const StyledPercentageText = styled(Typography)({
  color: "9191B0",
  fontSize: 12,
});

function BaselineShowResults() {
  const params = new URLSearchParams(window.location.search);
  const idBaseline = params.get("id_baseline");
  const [baselineQuestions, setBaselineQuestions] = useState([]);
  const [baselineLineaments, setBaselineLineaments] = useState([]);
  const [baselineAnswers, setBaselineAnswers] = useState([]);

  const [baselineResults, setBaselineResults] = useState([]);
  const { isBaselineFree } = useModulesFree();

  //Obtener los datos
  const handleGetBaselineLineaments = async () => {
    setOpenBackdrop(true);
    const response = await MyBaseline.getBaselineShowResults({ idBaseline });
    setBaselineResults(response);
    handleCloseBackdrop();
    return response;
  };

  // variables
  const [initialDate, setInitialDate] = useState();
  const [endDate, setEndDate] = useState();
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();

  //responsibles
  const [nameResponsibleInput, setNameResponsibleInput] = useState();
  const [idResponsible, setIdResponsible] = useState();

  //Funciones

  // Obtener usuarios Activos
  const [rowsUsersSearchs, setRowsUsersSearchs] = useState([]);
  const handleGetUsersSearchsAddsWorkers = async () => {
    const response = await MyUsers.users();

    setRowsUsersSearchs(response.users_actives);
  };
  //seleccionar responsable
  const handleSelectUsersResponsible = async (idUsers) => {
    setOpenBackdrop(true);
    setIdResponsible(idUsers);
    // handleOpenLoading();

    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setNameResponsibleInput(response.first_name + " " + response.last_name);
      setIdResponsible(response.id_users);
      setOpenDialogResponsible(false);
      handleCloseBackdrop();
      // handleOpenAlert(response.msg, "success");
    } else {
    }
  };

  //NewTask
  const handleNewTask = async (event) => {
    event.preventDefault();
    setOpenBackdrop(true);
    const response = await MyTasks.newTask({
      initialDate: initialDate,
      endDate: endDate,
      description: description,
      idResponsible: idResponsible,
      idTaskType: 1,
      title: title,
    });
    handleCloseBackdrop();
    setOpenDialogAddTask(false);
  };

  ///modales
  //añadir una tarea
  const [openDialogAddTask, setOpenDialogAddTask] = useState(false);

  const handleOpenDialogAddTask = ({ title }) => {
    setInitialDate("");
    setEndDate("");
    setDescription("");
    setIdResponsible("");
    setTitle(title);
    setOpenDialogAddTask(true);
    setNameResponsibleInput(null);
  };

  //agregar responsable
  const [openDialogResponsible, setOpenDialogResponsible] = useState(false);

  const handleOpenDialogResponsible = () => {
    setOpenDialogResponsible(true);
  };
  ////////////////////////////
  ///Backdrop

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleActionAddTask = (indicator) => {
    if (isBaselineFree) {
      handleOpenModalInformative();
      return;
    }

    handleOpenDialogAddTask({
      title: indicator.actions,
    });
  };

  useEffect(() => {
    handleGetBaselineLineaments();
    handleGetUsersSearchsAddsWorkers();
  }, []);

  const [openModalInformative, setOpenModalInformative] = useState(false);
  const handleOpenModalInformative = () => {
    setOpenModalInformative(true);
  }

  const handleCloseModalInformative = () => {
    setOpenModalInformative(false);
  }

  return (
    <>

      <DialogInformativeControla
         open={openModalInformative}
         onClose={handleCloseModalInformative}
         message={"Acceso limitado. Activa tu suscripción para desbloquear esta función premium."}
      />

      <Box sx={{ height: "100%" }}>
        <ReturnBack/>

        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item md={12}>
                <SubtitleText text={"Línea base"} color={grey}></SubtitleText>
                <TitleText text={"Respuestas de línea base"}></TitleText>
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                paddingBottom="40px"
              >
                {baselineResults.map((lineament) => (
                  <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Paper sx={{ overflow: "hidden" }}>
                        <TableContainer>
                          <Table
                            stickyHeader
                            aria-label="simple table"
                            // sx={{
                            //   maxWidth: "200px",
                            //   background: "black"
                            // }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" sx={{ color: blue }}>
                                  {lineament.baseline_companie_lineament}
                                </TableCell>
                                <TableCell align="center" sx={{ color: blue }}>
                                  Respuesta
                                </TableCell>
                                <TableCell align="center" sx={{ color: blue }}>
                                  Comentario
                                </TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {lineament.indicators.map((indicator) => (
                                <TableRow>
                                  <TableCell
                                    sx={{ color: purple }}
                                    align="left"
                                  >
                                    {indicator.questions}
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: purple }}
                                    align="center"
                                  >
                                    {indicator.answer}
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: purple }}
                                    align="left"
                                  >
                                    {indicator.comment}
                                  </TableCell>
                                  <TableCell align="center">
                                    <ButtonControla
                                      roles={[1, 7, 8]}
                                      iconButton={<Add sx={{ color: "white" }} /> }
                                      backgroundColor={"#1cb591"}
                                      backgroundColorHover={"#169073"}
                                      textButton={"Tarea"}
                                      functionOnClick={() =>
                                        handleActionAddTask(indicator)
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Container>

        {/* Dialog agregar tareas */}
        <Dialog
          open={openDialogAddTask}
          onClose={() => setOpenDialogAddTask(false)}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Agregar tarea
            </Typography>
          </DialogTitle>
          <form onSubmit={handleNewTask}>
            <DialogContent sx={{ paddingTop: "0" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputControlaVertical
                    text={"Título"}
                    modalType={true}
                    inputType={"textArea"}
                    inputValue={setTitle}
                    value={title}
                    required
                  ></InputControlaVertical>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <InputControlaVertical
                    text={"Fecha de inicio"}
                    inputType={"date"}
                    inputValue={setInitialDate}
                    value={initialDate}
                    modalType={true}
                    required
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <InputControlaVertical
                    text={"Fecha de fin"}
                    inputType={"date"}
                    inputValue={setEndDate}
                    value={endDate}
                    modalType={true}
                    // required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputControlaVertical
                    text={"Descripción"}
                    inputType={"textArea"}
                    inputValue={setDescription}
                    value={description}
                    modalType={true}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SearchButtonControla
                    titleLabel="Responsable: "
                    inputValue={nameResponsibleInput}
                    functionClick={handleOpenDialogResponsible}
                    modalType={true}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#6969D6",
                  backgroundColor: "#CBCBFF",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
                onClick={() => setOpenDialogAddTask(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#169073",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
              >
                Agregar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        {/* Añadir Responsable */}
        <Dialog
          open={openDialogResponsible}
          onClose={() => {
            setOpenDialogResponsible(false);
          }}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Seleccionar Responsable
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ paddingTop: "0" }}>
            <SearchUsersControla
              rowsUser={rowsUsersSearchs}
              handleUsersActionButton={handleSelectUsersResponsible}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDialogResponsible(false);
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openBackdrop}
        >
          {/* <CircularProgress color="inherit" /> */}
          <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
        </Backdrop>
      </Box>
    </>
  );
}

export default BaselineShowResults;
