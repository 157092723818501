import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { React, useState } from "react";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import { validationRoles } from "../../utils/validation_rol";

function SelectControlaRoles({
  disabled = false,
  text,
  inputValue,
  modalType = false,
  value = undefined,
  childrenRows,
  roles = [],
  ...other
}) {
  const showButton = validationRoles(roles)

  if (showButton === true) {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} disabled={disabled}>
          { text !== undefined ? (
            <InputLabel
            sx={{
              padding: "8px",
              fontSize: "18px",
              color: "#305AD9",
              fontWeight: "bold",
            }}
          >
            {" "}
            {text}{" "}
          </InputLabel>
          ) : ""}
          
          <Select
            size="small"
            value={value === undefined || value === null ? undefined : value}
            // defaultValue={value}
            displayEmpty
            onChange={(event) => {
              inputValue(event.target.value);
            }}
            sx={{
              fontFamily: "Roboto",
              width: "100%",
              backgroundColor: modalType === true ? "#F5F5F5" : "#FFFFFF",
              height: "42px",
              color: "#305AD9",
            }}
            {...other}
          >
            {/* <MenuItem value={0}>Seleccione</MenuItem> */}
            {childrenRows}
          </Select>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {value === 1
          ? "En curso"
          : value === 2
          ? "Completado"
          : value === 3
          ? "Anulado"
          : ""}
      </Grid>
    );
  }
}

export default SelectControlaRoles;
