import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  PictureAsPdf,
  VisibilityOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import MyRegistersAccidents from "../../../../api/MyRegistersAccidents";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import MyRegistersAudits from "../../../../api/MyRegistersAudits";
import InputControlaVerticalIcon from "../../../../components/textfields/inputControlaVerticalIcon";
import { useAppContext } from "../../../../context/AppContext";
import MyTasksNew from "../../../../api/MyTasksNew";
import DialogInformativeControla from "../../../../components/dialog/dialogInformativeControla";
import { useNavigate } from "react-router-dom";
import ButtonSignatureControla from "../../../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";
const blue = "#034AFF";

function Edit() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const idRegisterAudit = params.get("idRegisterAudit");

  const [idRegisterAuditWorkers, setidRegisterAuditWorkers] = useState();

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");
  ////
  // id del responsable
  const [idRegisterResponsible, setIdRegisterResponsible] = useState("");
  //// id auditor (tabla 1)
  const [idAuditor, setIdAuditor] = useState("");
  const [idAuditoringAudit, setIdAuditoringAudit] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setidRegisterAuditWorkers(id);
    setIdRegisterMeasure(id);
    setIdAuditoringAudit(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
    setIdRegisterResponsible(id);
    setIdAuditor(id);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "deleteAuditors":
        handleDeleteAuditors(idAuditor);
        break;
      case "deleteMeasures":
        handleDeleteCorrectivesActions();
        break;
      case "deleteResponsible":
        handleDeleteResponsibleAudit({ idUsers: idRegisterResponsible });
        break;
      case "deleteAudit":
        handleDeleteAuditoringAudit();
        break;
    }
    setOpenModalConfirm(false);
  };
  // Dialogos de confirmacion FIN

  // modal añadir auditor
  const [modalAddAuditor, setModalAddAuditor] = useState(false);
  const handleOpenModalAddAuditor = () => {
    setAuditorName("");
    setRegisterNumber("");
    setModalAddAuditor(true);
  };
  const handleCloseModalAddAuditor = () => {
    setModalAddAuditor(false);
  };

  // modal añadir auditoria
  const [modalAddAudit, setModalAddAudit] = useState(false);
  const handleOpenModalAddAudit = () => {
    setAuditoringProcess("");
    setAuditoringDate();
    setResponsibleCorrectiveActions(null);
    setModalAddAudit(true);
  };

  // agregar medidas correctivas DIALOG
  const [openModalUploadCorrectiveActions, setOpenModalUploadCorrectiveActions] = useState(false);

  const handleOpenModalUploadCorrectiveActions = () => {
    setTimestampStart(null);
    setTimestampEnd(null);
    setDescription(null);
    setStatusCorrectivesActions(null);
    setResponsibleCorrectiveActions(null);

    handleGetStatusCorrectivesActions();
    setOpenModalUploadCorrectiveActions(true);
  };

  const handleCloseModalUploadCorrectiveActions = () => {
    setResponsibleCorrectiveActions("");
    handleGetCorrectivesActions();
    setOpenModalUploadCorrectiveActions(false);
  };

  // agregar RESPONSABLES  DIALOG
  const [
    openModalUploadResponsibleRegistration,
    setOpenModalUploadCResponsibleRegistration,
  ] = useState(false);

  const handleOpenModalUploadResponsibleRegistration = () => {
    handleGetSearchsResponsiblesWorkers();
    setOpenModalUploadCResponsibleRegistration(true);
  };

  const handleCloseModalUploadResponsibleRegistration = () => {
    setOpenModalUploadCResponsibleRegistration(false);
  };


  const [code, setCode] = useState("");
  const [conformityNumber, setConformityNumber] = useState("");
  const [documentUrl1, setDocumentUrl1] = useState("");
  const [documentUrl2, setDocumentUrl2] = useState("");
  const [causesDescription, setcausesDescription] = useState("");
  const [descriptionWork, setDescriptionWork] = useState("");

  // BACKDROP
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };


  /***************************  MEDIDAS CORRECTIVAS  *****************************/
  // buscar usuario por ID
  const [responsibleCorrectiveActions, setResponsibleCorrectiveActions] =
    useState(null);
  const [idResponsible, setIdResponsible] = useState(" ");

  const handleSelectUsersResponsible = async (idUsers) => {
    setIdResponsible(idUsers);

    setOpenBackdrop(!openBackdrop);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setResponsibleCorrectiveActions(
        response.first_name + " " + response.last_name
      );
      handleCloseBackdrop();
      setOpenModalResponsible(false);
    } else {
      alert("Error al obtener el usuario!");
    }
  };

 
  // abrir modal de busqueda de usuarios de medidas correctivas
  const [openModalResponsible, setOpenModalResponsible] = useState(false);
  const handleOpenModalResponsible = () => {
    setOpenModalResponsible(true);
  };

  // estado de la medida correctiva
  const [statusCorrectivesActions, setStatusCorrectivesActions] = useState(null);
  // mostrar estado de la medida correctiva
  const [rowsStatusCorrectivesActions, setRowsStatusCorrectivesActions] = useState([]);
  const handleGetStatusCorrectivesActions = async () => {
    const response = await MyRegistersAccidents.getStatusCorrectivesActions();
    if (response.success === false) {
    } else {
      setRowsStatusCorrectivesActions(response);
    }
    return response;
  };

  // mostrar medidas correctivas
  const [correctivesActions, setCorrectivesActions] = useState([]);
  const handleGetCorrectivesActions = async () => {
    const response = await MyRegistersAudits.getCorrectiveActions({
      idRegistersAudits: idRegisterAudit,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setCorrectivesActions(response);
    }
  };

  // crear medidas correctivas
  const [timestampStart, setTimestampStart] = useState("");
  const [timestampEnd, setTimestampEnd] = useState("");
  const [description, setDescription] = useState("");

  const handleStoreCorrectivesActions = async (event) => {
    event.preventDefault();

    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAudits.createCorrectiveActions({
      idRegistersAudits: idRegisterAudit,
      idAuditsCorrectiveActionsStatus: statusCorrectivesActions,
      idUsers: idResponsible,
      timeStampStart: timestampStart,
      timeStampEnd: timestampEnd == null ? "" : timestampEnd,
      description: description,
    });

    // agregar a tareas
    const data = {
      date_start: timestampStart,
      date_end: timestampEnd,
      description: description,
      id_status: 1,
      id_users: idResponsible,
      id_tasks_types: 2,
      title: "Tarea asignada en registro de auditoría",
      origin_url: `documentation/registers/generals/audits/edit?idRegisterAudit=${idRegisterAudit}`,
    };

    const resp = await MyTasksNew.postTasks(data);

    if (response.success === false) {
      handleClickAlert("error", "Ocurrió un error al agregar medida correctiva");
    } else {
      await handleGetCorrectivesActions();
      handleClickAlert("success", "Se agregó la medida correctiva con éxito");
      handleCloseModalUploadCorrectiveActions();
    }

    handleCloseBackdrop();
    return response;
  };

  // eliminar medidas correctives
  const [idRegisterMeasure, setIdRegisterMeasure] = useState(0);

  const handleDeleteCorrectivesActions = async () => {
    setOpenBackdrop(true);

    const response = await MyRegistersAudits.deleteCorrectiveActions({
      idRegistersAudits: idRegisterMeasure,
    });
    if (response.success === true) {
      await handleGetCorrectivesActions();
      handleClickAlert("success", "Se eliminó la medida correctiva con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al eliminar medida correctiva");
    }

    handleCloseBackdrop();
    return response;
  };

  
  // TRABAJADORES RESPONSABLES
  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);


  // Buscar trabajadores en medidas correctivas
  const [rowsUsersActives, setRowsUsersActives] = useState([]);
  const [rowsSearchsResponsiblesWorkers, setSearchsResponsiblesWorkers] = useState([]);

  const handleGetUsersActives = async () => {
    const response = await MyUsers.users();
    setRowsUsersActives(response.users_actives);
  }

  const handleGetSearchsResponsiblesWorkers = async () => {
    let users = rowsUsersActives;
    responsibleWorkers.forEach((user) => {
      users = users.filter((u) => u.id_users !== user.id_users);
    });

    setSearchsResponsiblesWorkers(users);
  };

 
  //modal de información
  const [openModalInformative, setOpenModalInformative] = useState(false);
  const [message, setMessage] = useState("");

  const handleCloseModalInformative = () => {
    setOpenModalInformative(false);
  }

  // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(true);

  const handleGetRegisterStatus = async () => {
    //verificar firma
    if (responsibleWorkers.filter((u) => u.firm == 0).length == 0) {
      setOpenBackdrop(!openBackdrop);
      const response = await MyRegistersAudits.getRegisterStatus({
        idRegister: idRegisterAudit,
      });

      if (response.success === false) {
        alert("Error");
      } else {
        navigate(`/documentation/registers/generals/audits/edit?idRegisterAudit=${idRegisterAudit}&readOnly=true`);
        await handleEditRegisterAudit();
        handleCloseBackdrop();
      }
      return response;

    } else {
      setOpenModalInformative(true);
      setMessage("Falta firma de algún responsable del registro");
    }
  };

 
  /////////////////////////////////

  const [auditorName, setAuditorName] = useState("");
  const [registerNumber, setRegisterNumber] = useState("");


  ///Auditores y Registro
  const [rowsAuditors, setRowsAuditors] = useState([]);

  const handleGetAuditors = async () => {
    const response = await MyRegistersAudits.getAuditorsAudit({
      idRegister: idRegisterAudit,
    });
    setRowsAuditors(response);
  };

  const handleAddAuditors = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    try{
      const response = await MyRegistersAudits.addAuditorsAudit({
        idRegister: idRegisterAudit,
        auditName: auditorName,
        registerNumber: registerNumber,
      });
      await handleGetAuditors();
      handleCloseModalAddAuditor();
      handleClickAlert("success", "Se agregó auditor con éxito");
    }catch(e){
      handleClickAlert("error", "Ocurrió un error al agregar auditor");
    }

    handleCloseBackdrop();
  };

  const handleDeleteAuditors = async () => {
    setOpenBackdrop(true);

    const response = await MyRegistersAudits.deleteAuditorsAudit({
      idSubregister: idRegisterAuditWorkers,
    });
    if (response.success) {
      await handleGetAuditors();
      handleCloseModalConfirm();
      handleClickAlert("success", "Se eliminó auditor con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al eliminar auditor");
    }

    handleCloseBackdrop();
  };


  //Responsables del registro y de la investigación
  //obtiene responsables
  const handleGetResponsibleAudit = async () => {
    const response = await MyRegistersAudits.getResponsibleAudit({
      idRegister: idRegisterAudit,
    });
    setResponsibleActions(response);
  };
  //agrega responsables
  const handleStoreResponsibleAudit = async (idUsers) => {
    setOpenBackdrop(!openBackdrop);
    try{
      const response = await MyRegistersAudits.addResponsibleAddAudit({
        idUser: idUsers,
        idRegister: idRegisterAudit,
      });
      if (response != null) {
        await handleGetResponsibleAudit();
        handleClickAlert("success","Se agregó responsable con éxito");
        handleCloseModalUploadResponsibleRegistration();
      }
    }catch(e){
      handleClickAlert("error", "Ocurrió un error al agregar responsable");
    }

    handleCloseBackdrop();
  };
  //elimina responsables
  const handleDeleteResponsibleAudit = async ({ idUsers }) => {
    setOpenBackdrop(true);
    try {
      const response = await MyRegistersAudits.deleteResponsibleAudit({
        idUser: idUsers,
        idRegister: idRegisterAudit,
      });

      await handleGetResponsibleAudit();
      handleClickAlert("success", "Se eliminó el responsable con éxito");
    } catch (e) {
      handleClickAlert("error", "Ocurrió un error al eliminar responsable");
    }

    handleCloseBackdrop();
  };
  //firma responsables
  const handleSignResponsibleAudit = async (idRegister, idUser) => {
    setOpenBackdrop(true);
    try {
      const response = await MyRegistersAudits.signResponsibleAudit({
        idUserId: idUser,
        idUser: idRegister,
      });

      await handleGetResponsibleAudit();
      handleClickAlert("success", "Responsable firmó con éxito");
    } catch (e) {
      handleClickAlert("error", "Ocurrió un error al firmar");
    }

    handleCloseBackdrop();
  };

  const handleEditRegisterAudit = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAudits.editRegisterAudit({
      idRegister: idRegisterAudit,
    });

    if (response != null) {
      setRegisterStatus(response.register_status === 1 ? true : false);
      setCode(response.code);
      setConformityNumber(response.non_conformities_number);
      setcausesDescription(response.non_conformity_causes);
      setDescriptionWork(response.non_conformity_description);
      setDocumentUrl1(response.document_url_1);
      setDocumentUrl2(response.document_url_2);
      handleCloseBackdrop();
    } else {
      console.log("Error, no se pudo obtener datos del registro");
    }
  };

  const handleUpdateRegistersAudit = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAudits.updateRegisterAudit({
      idRegister: idRegisterAudit,
      code: code,
      nonConformitiesNumber: conformityNumber,
      nonConformityDescription: descriptionWork,
      nonConformityCauses: causesDescription,
      documentUrl1: documentUrl1,
      documentUrl2: documentUrl2,
    });

    if (response.success === false) {
      console.log("error al actualizar");
    } else {
      handleEditRegisterAudit();
      handleCloseBackdrop();
    }
    return response;
  };

  ///auditoringaudit
  const [auditoringAudit, setAuditoringAudit] = useState([]);

  const handleGetAuditoringAudit = async () => {
    const response = await MyRegistersAudits.getAuditoringAudit({
      idRegistersAudits: idRegisterAudit,
    });
    setAuditoringAudit(response);
  };

  const [auditoringProcess, setAuditoringProcess] = useState("");
  const [auditoringDate, setAuditoringDate] = useState("");

  const handleCreateAuditoringAudit = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    try{
      const response = await MyRegistersAudits.createAuditoringAudit({
        idRegistersAudits: idRegisterAudit,
        idUsers: idResponsible,
        auditoringProcess: auditoringProcess,
        auditoringDate: auditoringDate,
      });
      await handleGetAuditoringAudit();
      setModalAddAudit(false);
      handleClickAlert("success", "Se agregó el proceso auditado con éxito");
    }catch(e){
      handleClickAlert("error", "Ocurrió un error al agregar un proceso auditado");
    }
    
    handleCloseBackdrop();

  };

  const handleDeleteAuditoringAudit = async () => {
    setOpenBackdrop(true);
    const response = await MyRegistersAudits.deleteAuditoringAudit({
      idAuditoringAudit: idAuditoringAudit,
    });

    if (response.success) {
      await handleGetAuditoringAudit();
      handleClickAlert("success", "Se eliminó el proceso auditado con éxito");
      handleCloseModalConfirm();
    } else {
      handleClickAlert("error", "Ocurrio un error al eliminar proceso auditado");
    }

    handleCloseBackdrop();
  };

  useEffect(() => {
    handleGetUsersActives();

    handleEditRegisterAudit();

    handleGetResponsibleAudit();
    // medidas correctivas
    handleGetCorrectivesActions();
    handleGetAuditors();
    handleGetAuditoringAudit();
  }, []);

  return (
    <Box sx={{height: "100%" }}>
      <ReturnBack/>

      {/* <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      /> */}
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText text={"Registro de Auditorías"}></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUpdateRegistersAudit}>
            <Grid container sx={{ marginTop: "4px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_AUDITORIAS_EDITAR}
                  text={"Nro. registro"}
                  inputType={"text"}
                  inputValue={setCode}
                  value={code}
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Auditores y registros
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                textAlign="right"
                mt={2}
              >
                {registerStatus === true && (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar auditores"}
                    functionOnClick={handleOpenModalAddAuditor}
                  />
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        N° de registro
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rowsAuditors.map((row, key) => (
                      <TableRow
                        key={key}
                        hover
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.register_number}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {registerStatus === true && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador",
                                    "Si, Eliminar",
                                    "deleteAuditors"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                textAlign="right"
                mt={2}
              >
                {registerStatus === true && (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AUDITORIAS_AGREGAR_DATOS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={handleOpenModalAddAudit}
                  />
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de auditoria
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Procesos auditados
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre de los responsables de los procesos auditados
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {auditoringAudit.map((auditoringAudit) => (
                      <TableRow
                        hover
                        key={auditoringAudit.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {auditoringAudit.auditoring_date}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {auditoringAudit.auditoring_process}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {auditoringAudit.first_name +
                            " " +
                            auditoringAudit.last_name}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {
                              registerStatus === true && (
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      auditoringAudit.id,
                                      "¿Esta seguro de eliminar los datos del trabajador?",
                                      "Una vez eliminado no se podra recuperar los datos de la auditoría",
                                      "Si, Eliminar",
                                      "deleteAudit"
                                    );
                                  }}
                                />
                              )
                            }
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} mt={3}>
                  <InputControlaVertical
                    roles={permissions.DOC_REGIS_AUDITORIAS_EDITAR}
                    text={"Nro. De no conformidades"}
                    inputType={"number"}
                    inputValue={setConformityNumber}
                    value={conformityNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SubtitleText text={"Información a adjuntar"} color={blue} />
                </Grid>

                <Grid item xs={11} md={11}>
                  <InputControlaVerticalIcon
                    roles={permissions.DOC_REGIS_AUDITORIAS_EDITAR}
                    text={"a) Informe de auditoría"}
                    inputType={"file"}
                    inputValue={setDocumentUrl1}
                    textTooltip={
                      "Indicar los hallazgos encontrados, así como no conformidades, observaciones, entre otros, con la respectiva firma del auditor o auditores."
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ButtonIconControla
                    icon={<VisibilityOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#305AD9"}
                    backgroundColorHover={"#0E4AFF"}
                    textTooltip={"Ver documento"}
                    directionUrl={typeof(documentUrl1) == "object" ? "" :documentUrl1 }
                    directionTarget={"_blank"}
                  />
                </Grid>
                <Grid item xs={11} md={11}>
                  <InputControlaVerticalIcon
                    roles={permissions.DOC_REGIS_AUDITORIAS_EDITAR}
                    text={
                      "b) Plan de acción para cierre de no conformidades (posterior a la auditoría)."
                    }
                    inputType={"file"}
                    inputValue={setDocumentUrl2}
                    textTooltip={
                      "El plan de acción contiene la descripción de las causas que originaron cada no conformidad, propuesta de las medidas correctivas para cada no conformidad, responsable de implementación, fecha de ejecución, estado de la acción correctiva."
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ButtonIconControla
                    icon={<VisibilityOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#305AD9"}
                    backgroundColorHover={"#0E4AFF"}
                    textTooltip={"Ver documento"}
                    directionUrl={typeof(documentUrl2) == "object" ? "" : documentUrl2}
                    directionTarget={"_blank"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SubtitleText
                    text={
                      "Modelo de encabezados para el plan de acción de cierre de no conformidades"
                    }
                    color={blue}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputControlaVertical
                    roles={permissions.DOC_REGIS_AUDITORIAS_EDITAR}
                    text={"Descripción de la no conformidad"}
                    inputType={"textArea"}
                    value={descriptionWork}
                    inputValue={setDescriptionWork}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputControlaVertical
                    roles={permissions.DOC_REGIS_AUDITORIAS_EDITAR}
                    text={"Causas de la no conformidad"}
                    inputType={"textArea"}
                    value={causesDescription}
                    inputValue={setcausesDescription}
                  />
                </Grid>
              </Grid>

              {/********************************  medidas correctivas  ********************************************** */}
              <Grid container>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                  <Box>
                    <InputLabel
                      sx={{
                        padding: "8px",
                        fontSize: "18px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      Medidas correctivas
                    </InputLabel>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ color: "#F2994A", ml: 1 }}
                    >
                      Este campo es obligatorio
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right" mt={2}>
                  <Box>
                    {registerStatus === true && (
                      <ButtonControla
                        roles={permissions.DOC_REGIS_AUDITORIAS_AGREGAR_MEDIDAS}
                        iconButton={<Add sx={{ color: "white" }} />}
                        backgroundColor={"#169073"}
                        backgroundColorHover={"#1BAA88"}
                        textButton={"Agregar medidas correctivas"}
                        functionOnClick={handleOpenModalUploadCorrectiveActions}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Grid container sx={{ marginTop: "4px" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Descripción de la medida correctiva
                        </TableCell>
                        {/* Descripción de la
                          medida correctiva a implementarse para eliminar la
                          causa y prevenir la consecuencia */}

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Responsable
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Fecha de inicio
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Fecha de fin
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {/* Completar en la fecha de ejecución propuesta, el
                          ESTADO de la implementación de la medida correctiva
                          (realizada, pendiente, en ejecución)*/}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {correctivesActions.map((rows) => (
                        <TableRow
                          hover
                          key={rows.id_users}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {rows.description}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {rows.first_name + " " + rows.last_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {rows.timestamp_start ? rows.timestamp_start.substring(0, 10) : ""}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {
                              rows.timestamp_end == "" ? "No definido" : rows.timestamp_end
                            }
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {rows.status}
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + rows.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                              {registerStatus === true && (
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      rows.id,
                                      "¿Esta seguro de eliminar los datos de la medida correctiva?",
                                      "Una vez eliminado no se podra recuperar los datos de la medida correctiva",
                                      "Si, Eliminar",
                                      "deleteMeasures"
                                    );
                                  }}
                                />
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              {/* responsables del registro y de la investigación */}
              <Grid container>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                  <InputLabel
                    sx={{
                      padding: "8px",
                      fontSize: "18px",
                      color: "#305AD9",
                      fontWeight: "bold",
                    }}
                  >
                    Responsables del registro y de la investigación
                  </InputLabel>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    sx={{ color: "#F2994A", ml: 1 }}
                  >
                    Este campo es obligatorio
                  </Typography>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right" mt={2}>
                  {registerStatus === true && (
                    <ButtonControla
                      roles={permissions.DOC_REGIS_AUDITORIAS_AGREGAR_RESPON}
                      iconButton={<Add sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Agregar responsables"}
                      functionOnClick={
                        handleOpenModalUploadResponsibleRegistration
                      }
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container sx={{ marginTop: "4px" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                          align="center"
                        >
                          Nombre y Apellido
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                          align="center"
                        >
                          Cargo
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                          align="center"
                        >
                          Fecha de firma
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                          align="center"
                        >
                          Firma
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {responsibleWorkers.map((row) => (
                        <TableRow
                          hover
                          key={row.id_users}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            {row.first_name + " " + row.last_name}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {row.position_name}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {row.firm_date === null ||
                              row.firm_date === undefined
                              ? ""
                              : row.firm_date.substring(0, 19)}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            <ButtonSignatureControla
                               idUser={row.id_users}
                               firmStatus={row.firm}
                               firmUrl={row.firm_url}
                               functionOnClick={async() => {
                                await handleSignResponsibleAudit(row.id,row.id_users)
                               }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + row.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                              {registerStatus === true && (
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                      "Una vez eliminado no se podra recuperar los datos del trabajador responsable",
                                      "Si, Eliminar",
                                      "deleteResponsible"
                                    );
                                  }}
                                />
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              {/* Botones de impresión, guardar y cancelar */}
              <Stack
                spacing={2}
                sx={{
                  display: "flex",
                  width: "100%",
                  paddingY: 4,
                  justifyContent: "center",
                }}
                direction="row"
              >
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<ArrowBack sx={{ color: "white" }} />}
                    backgroundColor={"#EB5757"}
                    backgroundColorHover={"#FF4040"}
                    textButton={"Regresar"}
                    isNavegationOrigin={true}
                    url={"/documentation/registers/generals/audits"}
                  />
                </Grid>
                {registerStatus === true && (
                  <Grid item xs={12} md={2}>
                    <ButtonControla
                      roles={permissions.DOC_REGIS_AUDITORIAS_ACTUALIZAR_REGIS}
                      iconButton={<EditOutlined sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Actualizar registro"}
                      typeButton="submit"
                    />
                  </Grid>
                )}
                {registerStatus === false && (
                  <Grid item xs={12} md={2}>
                    <ButtonControla
                      roles={[1, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13]}
                      iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                      backgroundColor={"#F2994A"}
                      backgroundColorHover={"#FF881E"}
                      textButton={"Ver documento PDF"}
                      typeButton={"button"}
                      url={
                        process.env.REACT_APP_API_DJANGO +
                        "report/" +
                        idRegisterAudit
                      }
                      target={"_blank"}
                    />
                  </Grid>
                )}

                {registerStatus === true && (
                  <Grid item xs={12} md={2}>
                    <ButtonControla
                      roles={permissions.DOC_REGIS_AUDITORIAS_CERRAR_REGIS}
                      iconButton={<CloseIcon fontSize="inherit" />}
                      backgroundColor={"black"}
                      backgroundColorHover={"#2C2B29"}
                      textButton={"Cerrar registro"}
                      functionOnClick={handleGetRegisterStatus}
                    />
                  </Grid>
                )}
              </Stack>
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog agregar nuevo auditor */}
      <Dialog
        open={modalAddAuditor}
        onClose={() => setModalAddAuditor(false)}
        maxWidth="xs"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar auditores
          </Typography>
        </DialogTitle>
        <form onSubmit={handleAddAuditors}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre del auditor:"}
                  inputType={"text"}
                  inputValue={setAuditorName}
                  value={auditorName}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"N° de registro:"}
                  inputType={"text"}
                  inputValue={setRegisterNumber}
                  value={registerNumber}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setModalAddAuditor(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog para agregar nueva auditoria */}
      <Dialog
        open={modalAddAudit}
        onClose={() => setModalAddAudit(false)}
        maxWidth="xs"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar auditoría
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateAuditoringAudit}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Fecha de la auditoría:"}
                  inputType={"date"}
                  inputValue={setAuditoringDate}
                  value={auditoringDate}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Proceso auditado:"}
                  inputType={"text"}
                  inputValue={setAuditoringProcess}
                  value={auditoringProcess}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SearchButtonControla
                    titleLabel="Responsable: "
                    inputValue={responsibleCorrectiveActions}
                    functionClick={handleOpenModalResponsible}
                    modalType={true}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setModalAddAudit(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog agregar medidas correctivas */}
      <Dialog
        open={openModalUploadCorrectiveActions}
        onClose={handleCloseModalUploadCorrectiveActions}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar medidas correctivas
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreCorrectivesActions}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inicio"}
                  inputType={"date"}
                  inputValue={setTimestampStart}
                  value={timestampStart}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setTimestampEnd}
                  value={timestampEnd}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Estado de medida correctiva:"
                  inputValue={setStatusCorrectivesActions}
                  modalType={true}
                  required
                  value={statusCorrectivesActions}
                  childrenRows={rowsStatusCorrectivesActions.map((rows) => (
                    <MenuItem
                      key={rows.id_registers_accidents_measures_statuses}
                      value={rows.id_registers_accidents_measures_statuses}
                    >
                      {rows.statuses}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable: "
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalResponsible}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadCorrectiveActions}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>


      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleAudit}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables */}
      <Dialog
        open={openModalResponsible}
        onClose={() => {
          setOpenModalResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsUsersActives}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* modal de información */}
      <DialogInformativeControla
        open={openModalInformative}
        onClose={handleCloseModalInformative}
        message={message}
      />

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Edit;
