import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Box
} from "@mui/material";
import React from "react";
import { validationRoles } from "../../utils/validation_rol";
import { useNavigate } from "react-router-dom";

function BannerVerticalControla({
  color1,
  color2,
  image,
  title,
  heightCard = '350px',
  widthCard='270px',
  functionButton,
  textButton,
  backgrounColorButton,
  hoverButton,
  url = null,
  functionClick,
  roles = []
}) {

  const navigate = useNavigate();


  const showButton = validationRoles(roles)

  return showButton && (
    <Card
      sx={{
        maxWidth: 450,
        height: `${heightCard}`,
        borderRadius: "16px",
        background: `linear-gradient(90deg, ${color2}, ${color1})`,
        backgroundSize: "100%",
        color: "white",
        marginBottom: "20px",
        width:`${widthCard}`,
        filter: 'drop-shadow(0 5px 10px 0)',
        overflow: 'hidden',
        '&:hover': {
            borderColor: "red",
            transform: 'translateX(2px)',
          },
          '& > *': { minWidth: 'clamp(0px, (360px - 100%) * 999,100%)' },
      }}
    >
      <CardActionArea
        sx={{
          width: "100%",
          height: "100%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center"
        }}
        // href={url}
        onClick={url == null ? functionClick : ()=>{navigate(url)}}
      >
        <CardContent
          sx={{
            width: "100%",
            height: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px  10px 0 10px !important",
          }}
        >
          <Typography gutterBottom variant="h6" sx={{ width: "100%",height: "40%" , fontWeight: "bold" , alignContent: "center"}}>
            {title}
          </Typography> 
          
           <Box component="img" src={image} alt={"Imagen no encontrada"} sx={{ height: "60%", width: "80%" }} />
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default BannerVerticalControla;
