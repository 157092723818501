import { Close } from "@mui/icons-material";
import { Snackbar, Alert, IconButton, Select, Backdrop } from "@mui/material";
import { Box } from "@mui/system";
import { React, useEffect, useState } from "react";

function LoadingControla({ openLoading = false, openAlert = false, handleCloseAlert, messageAlert = '', typeAlert = 'success' }) {

    return (
        <Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={'top' + 'right'}
                autoHideDuration={3000}
            >
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleCloseAlert();
                            }}
                        >
                            <Close fontSize="inherit" />
                        </IconButton>
                    }
                    severity={typeAlert}
                    size="small"
                >
                    {messageAlert}
                </Alert>
            </Snackbar>

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 99,
                }}
                open={openLoading}
            >
                {/* <CircularProgress color="inherit" /> */}
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>
        </Box>
    );
}

export default LoadingControla;

// COPIAR TODO ESTE CONTENIDO
// ------------------------------------------------------------------
// const [openAlert, setOpenAlert] = useState(false);
// const [openLoading, setOpenLoading] = useState(false);
// const [messageAlert, setMessageAlert] = useState('');
// const [typeAlert, setTypeAlert] = useState('success');

// const handleOpenLoading = () => {
//     setOpenAlert(false);
//     setOpenLoading(true);
//     setMessageAlert('');
//     setTypeAlert('success');
// }

// const handleOpenAlert = (alertMessage, alertType) => {
//     setOpenAlert(true);
//     setOpenLoading(false);
//     setMessageAlert(alertMessage);
//     setTypeAlert(alertType);
// }

// const handleCloseAlert = () => {
//     setOpenAlert(false);
// }
// ------------------------------------------------------------------
// SOLO LLAMAR SUS FUNCTIONES handleOpenLoading() al iniciar y
// handleOpenAlert(alertMessage, alertType) al finalizar donde
// alertMessage es el mensaje que mostrara el alert y alertType el tipo de mensaje ('success', 'warning', 'error')

// PARA CREAR LA CARGA Y EL ALERT
// <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

// AÑADIR A LOS DIALOGS LO SIGUIENTE  sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}