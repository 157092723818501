import MyConfigurationApi from "./MyConfigurationApi";

function sendNotificacion( notification ) {
  const url = "notifications/procedures";
  const data = {
    message: notification.message,
    document_url: notification.document_url,
    id_users_created: notification.id_users_created,
    id_users_modified: notification.id_users_modified,
    id_users_sender: notification.id_users_sender,
    id_users_receiving: notification.id_users_receiving,
  };

  return MyConfigurationApi.connectFormApiGeneral({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

const MyNotificacionNew = {
  sendNotificacion
};

export default MyNotificacionNew;