import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import {BorderColorOutlined, Delete } from "@mui/icons-material";

import { useAppContext } from "../../context/AppContext";

function ListOccupationalPositions (props) {
    const { permissions } = useAppContext();

    const {
        rowPositions,
        positionsReferenced,
        colorCell,
        handleOpenModalConfirm,
        handleOpenModalEdit
    } = props

    return (
        <TableBody>
            {rowPositions.map((positions, key) => (
                <TableRow
                    hover
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    key={key}
                >
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {positions.name}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {positions.area}
                    </TableCell>

                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                roles={permissions.CONF_CARGO_EDITAR}
                                icon={
                                    <BorderColorOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                functionOnClick={() =>
                                    handleOpenModalEdit(
                                        positions.id_positions,
                                        positions.name,
                                        positions.id_areas
                                    )
                                }
                            />
                            {
                                positionsReferenced.some((p)=> p.id_positions == positions.id_positions) ? " ": (
                                    <ButtonIconControla
                                    roles={permissions.CONF_CARGO_ELIMINAR}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() => {
                                        handleOpenModalConfirm(
                                            positions.id_positions,
                                            "¿Esta seguro de eliminar este cargo ocupacional?",
                                            'Una vez eliminado no se podrá recuperar. Se eliminará:  "' +
                                            positions.name +
                                            '"',
                                            "Si, Eliminar",
                                            "delete"
                                        );
                                    }}
                                />

                                )
                            }
                           
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListOccupationalPositions;