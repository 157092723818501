import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import {
    Add
  } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import DialogTitleControla from "../dialog/dialogTitleControla";
import ButtonControla from "./buttonController";
import InputControlaVertical from "../textfields/inputControlaVertical";
import { validationRoles } from "../../utils/validation_rol";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import MySuggestionsMailbox from "../../api/MySuggestionsMailbox";
import LoadingControla from "../load/loadingControla";

const skyblue = "#2D9CDB";
const StyledAddButton = styled(Button)({
    textTransform: "none",
    backgroundColor: skyblue,
    borderRadius: 16,
});

function ButtonMailboxControla({ roles = [], originUrl = "" }) {
    //loading
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //

    const [openModalCreateMailbox, setOpenModalCreateMailbox ] = useState(false);

    const handleOpenModalCreateMailbox = ()=>{
        setOpenModalCreateMailbox(true);

        // limpiar
        setDescription("");
    }

    const handleCloseModalCreateMailbox = ()=>{
        setOpenModalCreateMailbox(false);
    }

    const [description, setDescription] = useState("");
    const [documentUrl, setDocumentUrl] = useState("");


    // TRAER DATOS DEL USUARIO
    const userData = MyConfigurationApi.userData();
    const idUser = userData.id_users;
    const companyData = MyConfigurationApi.companyData();
    const idCompany = companyData.id_companies;

    const handlePostSuggestionsMailbox= async(event)=>{
        handleOpenLoading();
        event.preventDefault();

        const data = {
            document_url: documentUrl,
            url: originUrl,
            comment: description
        }

        try{
            const resp = await MySuggestionsMailbox.postSuggestion(data);
            handleOpenAlert("Se registró su sugerencia con éxito","success");

        }catch(e){
            handleOpenAlert("Ha ocurrido un error", "error");
        }

        handleCloseModalCreateMailbox();

    }

    const showButton = validationRoles(roles);


    return showButton && (

        <Grid container>
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <Grid item xd={12} md={12} >
                <StyledAddButton
                    variant="text"
                    startIcon={<AttachEmailIcon />}
                    disableElevation
                    size="large"
                    style={{
                        backgroundColor: "#FFC700",
                        borderRadius: "15px",
                        color: "white",
                        padding: "8px 25px",
                    }}
                    onClick={handleOpenModalCreateMailbox}
                >
                    Tus sugerencias nos importan! Coméntalas aquí.
                </StyledAddButton>

            </Grid>

            <Dialog
                open={openModalCreateMailbox}
                onClose={handleCloseModalCreateMailbox}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Registro de sugerencias y observaciones del módulo"
                    functionOnClose={handleCloseModalCreateMailbox}
                />
                <form onSubmit={handlePostSuggestionsMailbox}>
                    <DialogContent>
                        <InputControlaVertical
                            text={"Descripción:"}
                            inputType={"textArea"}
                            modalType={true}
                            inputValue={setDescription}
                            value={description}
                            required
                        />

                        <InputControlaVertical
                            text={"Subir evidencia:"}
                            inputType={"file"}
                            modalType={true}
                            inputValue={setDocumentUrl}
                            value={documentUrl}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={handleCloseModalCreateMailbox}
                                />
                            </Box>
                            <Box>
                                <ButtonControla
                                    iconButton={<Add sx={{ color: "white" }} />}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#33AEF4"}
                                    textButton={"Crear"}
                                    typeButton={"submit"}
                                />
                            </Box>


                        </Stack>


                    </DialogActions>

                </form>

            </Dialog>
        </Grid>
    )

}
export default ButtonMailboxControla;