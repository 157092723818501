import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import { Box } from "@mui/system";

function BannerButtonControla({
  color1,
  color2,
  image,
  title = null,
  subtitle,
  url,
  functionClick
}) {
  return (
    <Card
      sx={{
        width: "100%",
        height: "154px",
        borderRadius: "16px",
        background: `linear-gradient(90deg, ${color2}, ${color1})`,
        backgroundSize: "100%",
        opacity: "0.85",
        color: "white",
        padding: "20px",
        position: "relative",
        fontSize: "90%",
      }}
    >
      <CardActionArea
        href={url}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
        onClick={functionClick}
      >
        <CardContent
          sx={{
            padding: 0,
            width: "70%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {
            (title !== null) && (
              <Typography
                gutterBottom
                variant="h5"
                component="span"
                sx={{
                  fontWeight: "bold",
                  width: "60%",
                  marginBottom: "10px"
                }}
              >
                {title}
              </Typography>
            )
          }

          <Typography
            gutterBottom
            variant="h6"
            component="span"
          >
            {subtitle}
          </Typography>

        </CardContent>
        <Box
          sx={{
            width: "100%",
            maxWidth: "200px",
            height: "100%"
          }}
        >
          <img
            style={{
              objectFit: 'contain',
              width: "100%",
              height: "100%"
            }}
            src={image}
            alt="logo"
          />
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default BannerButtonControla;
