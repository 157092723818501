import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import {
    Unarchive
} from "@mui/icons-material";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import { useAppContext } from "../../context/AppContext";


function ListArchived(props) {
    const { permissions } = useAppContext();
    const {
        rowsArchived,
        handleOpenModalConfirm,
        colorCell,
    } = props;

    return (
        <TableBody>
            {rowsArchived.map((row) => (
                <TableRow
                    hover
                    key={row.id_standards}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell sx={{ color: colorCell }}>
                        {row.document_title}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.archive == 1 ? "Archivo" : "Sistema"}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.id_standards_statuses == 4 ? "Archivado" : ""}
                    </TableCell>
                    <TableCell>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {
                                row.archive == 1 ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_STANDAR_ACTI_VER}
                                        icon={
                                            <VisibilityOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        directionUrl={row.document_url}
                                        directionTarget={"_blank"}
                                    />
                                ) : ""
                            }
                            {
                                row.active == 1 && row.model == 1 ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_STANDAR_ARCHI_VER}
                                        icon={
                                            <VisibilityOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        isNavegationOrigin={true}
                                        directionUrl={`/documentation/documents/standards/edit?id_standard=${row.id_standards}&readOnly=true`}
                                    />
                                ) : ""
                            }

                            <ButtonIconControla
                                roles={permissions.DOC_HG_STANDAR_ARCHI_DESARCHI}
                                icon={<Unarchive sx={{ color: "white" }} />}
                                backgroundColor={"#F2994A"}
                                backgroundColorHover={"#FF881E"}
                                textTooltip={"Desarchivar"}
                                functionOnClick={() => handleOpenModalConfirm(
                                    "Desarchivar documento",
                                    "¿Estás seguro de desarchivar este documento?",
                                    "Si, desarchivar",
                                    "unarchived",
                                    row.id_standards,
                                    row.firm_prepare
                                )}
                            />

                        </Stack>
                    </TableCell>
                </TableRow>
            ))
            }
        </TableBody>
    );
}
export default ListArchived;