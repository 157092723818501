import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import {
    Grid,
  } from "@mui/material";

import TitleText from "../../../components/text/titleText";
import SubtitleText from "../../../components/text/subtitleText";
import CompanyLogoControla from "../../../components/images/companyLogoControla";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import ButtonControla from "../../../components/buttons/buttonController";
import ReturnBack from "../../../components/buttons/returnBackControla";

const blue = "#034AFF";
const lilac = "#CBCBFF";

function TemplateMenuRegulations() {

    return (
        <Box sx={{ height: "100%" }}>
            <ReturnBack/>

            <Container>
                <Box>
                    <Grid container>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <SubtitleText text={"Documentación"} color={"#9191B0"} />
                            <TitleText
                                text={"Plantilla del Reglamento Interno del Comité"}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <CompanyLogoControla />
                        </Grid>
                        <SubtitleText
                            text={
                                "Te presentamos una(s) plantilla(s) para facilitar la elaboración de tu documento"
                            }
                            color={blue}
                        />
                    </Grid>
                </Box>

                <Grid container sx={{ mt: 2 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            color: blue,
                                        }}
                                    >
                                        Nombre del documento
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">
                                        General - Industrial
                                    </TableCell>
                                    <TableCell>
                                        <ButtonIconControla
                                            icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                            backgroundColor={"#305AD9"}
                                            backgroundColorHover={"#0E4AFF"}
                                            textTooltip={"Ver"}
                                            isNavegationOrigin={true}
                                            directionUrl={"/committee/presentCommittee/internalRegulationsCommittee/templateMenu/template"}
                                        />

                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">
                                        Minero
                                    </TableCell>
                                    <TableCell>
                                        <ButtonIconControla
                                            icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                            backgroundColor={"#305AD9"}
                                            backgroundColorHover={"#0E4AFF"}
                                            textTooltip={"Ver"}
                                        />
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid container sx={{ mt: 4 }}>
                    <Grid item xs={7} sm={7} md={11} lg={11} xl={11}></Grid>
                    <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                        <ButtonControla
                            textButton={"Regresar"}
                            backgroundColor={lilac}
                            isNavegationOrigin={true}
                            url={"/committee/presentCommittee/internalRegulationsCommittee/"}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default TemplateMenuRegulations;