import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import {
    DoDisturbOn,
    LoginOutlined,
} from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useAppContext } from "../../../context/AppContext";


function ListNotified(props) {
    const {permissions} = useAppContext();

    const {
        notifiedMeetings,
        colorCell,
        meetNotProceeding,
        handleSearchActivedBook,
        handleOpenModalConfirm
    } = props

    return (
        <TableBody>
            {notifiedMeetings.map((meeting) => (
                <TableRow
                    hover
                    key={meeting.id_committee_meeting}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.id_committee_meeting}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.description}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.date}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.session === "1" ? "ordinario" : "extraordinario"}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.modality === "1" ? "presencial" : "remoto"}
                    </TableCell>

                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignContent="center"
                        >
                            <ButtonIconControla
                                icon={ <VisibilityOutlined sx={{ color: "white" }}/> }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                isNavegationOrigin={true}
                                directionUrl={"/proceedingBook/scheduleMeetings/editScheduledMeeting?id_meeting=" + meeting.id_committee_meeting + "&readOnly=true"}
                            />
                            {meetNotProceeding.filter((m) => m.id_committee_meeting === meeting.id_committee_meeting).length === 1 ? (
                                <ButtonIconControla
                                    icon={<LoginOutlined sx={{ color: "white" }} />}
                                    backgroundColor={"#24996A"}
                                    backgroundColorHover={"#1AA483"}
                                    textTooltip={"Crear Acta"}
                                    functionOnClick={() => { handleSearchActivedBook(meeting.id_committee_meeting) }}
                                />
                            ) : ""}

                            <ButtonIconControla
                                icon={ <DoDisturbOn sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Anular reunión"}
                                functionOnClick={() => {
                                    handleOpenModalConfirm(
                                        meeting.id_committee_meeting,
                                        "¿Estás seguro de anular la reunión?",
                                        "Una vez anulado no podrás recuperar la reunión",
                                        "Sí, anular",
                                        "cancel"
                                    )
                                }}
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListNotified;