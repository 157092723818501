import MyConfigurationApi from "../../api/MyConfigurationApi";
const company = MyConfigurationApi.companyData();
const nameCompany = company.name

export  const templateGeneral =` 
<p>&Iacute;NDICE</p>
<p>T&Iacute;TULO I<br />DISPOSICIONES GENERALES</p>
<p>Cap&iacute;tulo I <br />Alcance y Objetivos<br />Art. 1&deg; al Art. 4&deg;</p>
<p>Cap&iacute;tulo II <br />Definiciones<br />Art. 5&deg;</p>
<p>T&Iacute;TULO II<br />ESTRUCTURA Y ORGANIZACION DEL COMITE</p>
<p>Cap&iacute;tulo I<br />De la Estructura<br />Art. 6&deg; y 7&deg;</p>
<p>Cap&iacute;tulo II<br />De la Organizaci&oacute;n<br />Art. 8&deg;</p>
<p>T&Iacute;TULO III<br />DEL COMIT&Eacute; DE SEGURIDAD Y SALUD OCUPACIONAL</p>
<p>Cap&iacute;tulo I<br />Constituci&oacute;n del Comit&eacute; de Seguridad y Salud Ocupacional <br />Art. 9&deg; al 12&deg;</p>
<p>Cap&iacute;tulo II <br />Funciones del Comit&eacute;<br />Art. 13&deg;</p>
<p>Cap&iacute;tulo III<br />Obligaciones del Comit&eacute; y sus miembros<br />Art. 14&deg; y 15&deg;</p>
<p>Cap&iacute;tulo IV <br />Capacitaci&oacute;n y goce de la licencia de los representantes de los trabajadores del Comit&eacute; de Seguridad y Salud Ocupacional<br />Art. 16&deg; al 18&deg;</p>
<p>Cap&iacute;tulo V<br />Vacancia de los miembros del Comit&eacute; de Seguridad y Salud Ocupacional<br />Art. 19&deg; al 23&deg;</p>
<p>Cap&iacute;tulo VI<br />Instalaci&oacute;n para la primera reuni&oacute;n del Comit&eacute; de Seguridad y Salud Ocupacional<br />Art. 24&deg; al 26&deg;</p>
<p>Cap&iacute;tulo VII <br />Elecci&oacute;n del Presidente del Comit&eacute; de Seguridad y Salud Ocupacional<br />Art. 27&deg;</p>
<p>&nbsp;</p> 
<p><strong>T&Iacute;TULO I DISPOSICIONES GENERALES </strong></p>
<p><strong>Cap&iacute;tulo I Alcance y Objetivos</strong></p>
<p>Art&iacute;culo 1: El presente reglamento se aplica a todos los miembros titulares y suplentes elegidos por el empleador y los trabajadores que conforman el Comit&eacute; de Seguridad y Salud Ocupacional.</p>
<p>Art&iacute;culo 2&deg;.- Conforme a la Ley N&deg; 29783, Ley de Seguridad y Salud en el Trabajo y su Reglamento, aprobado por el Decreto Supremo N&deg; 005-2012-TR, ${nameCompany} debe constituir un Comit&eacute; de Seguridad y Salud Ocupacional.</p>
<p>Art&iacute;culo 3&deg;.- El presente Estatuto regula la constituci&oacute;n y el funcionamiento del Comit&eacute; de Seguridad y Salud Ocupacional de ${nameCompany}, as&iacute; como la designaci&oacute;n de los miembros, sus funciones y responsabilidades.</p>
<p>Art&iacute;culo 4&deg;.- El prop&oacute;sito del Comit&eacute; es promover la salud y la seguridad en el trabajo en toda la organizaci&oacute;n, formulando recomendaciones a ${nameCompany} y fiscalizando el cumplimiento de las normas legales e internas de la compa&ntilde;&iacute;a en materia de seguridad y salud en el trabajo tanto por parte de la organizaci&oacute;n como por los propios trabajadores y terceros contratistas.</p>
<p><strong>Cap&iacute;tulo II Definiciones </strong></p>
<p>Art&iacute;culo 5&deg;.- Las siguientes definiciones se aplican al presente reglamento.</p>
<p>5.1. Comit&eacute; de Seguridad y Salud Ocupacional: &Oacute;rgano bipartito y paritario constituido por representantes del empleador y de los trabajadores, con las facultades y obligaciones previstas por la legislaci&oacute;n y la pr&aacute;ctica nacional, destinado a la consulta regular y peri&oacute;dica de las actuaciones del empleador en materia de prevenci&oacute;n de riesgos en Seguridad y Salud Ocupacional.</p>
<p>5.2. Participaci&oacute;n y Consulta: Proceso mediante el cual los trabajadores se ven involucrados y pueden expresar su opini&oacute;n respecto a temas de Seguridad y Salud en el Trabajo.</p>
<p>5.3. Representante de los trabajadores: Trabajador que no forma parte del personal de direcci&oacute;n y/o personal calificado como de confianza de la empresa, preferentemente con capacitaci&oacute;n recibida en Seguridad y Salud en el Trabajo, elegido por medio de elecciones convocadas por la Organizaci&oacute;n m&aacute;s representativa de los trabajadores o por ${nameCompany} en su ausencia o, en el caso de incumplimientos de los plazos establecidos, para representar a los trabajadores ante el Comit&eacute; de Seguridad y Salud Ocupacional.</p>
<p>5.4. Representantes del empleador: Trabajadores pertenecientes al personal de direcci&oacute;n y confianza de ${nameCompany} que participan en el Comit&eacute; de Seguridad y Salud Ocupacional.</p>
<p><strong>T&Iacute;TULO II ESTRUCTURA Y ORGANIZACI&Oacute;N DEL COMIT&Eacute; </strong></p>
<p><strong>Cap&iacute;tulo I </strong></p>
<p><strong>De la Estructura </strong></p>
<p>Art&iacute;culo 6.- El Comit&eacute; de Seguridad y Salud Ocupacional de ${nameCompany} tiene car&aacute;cter bipartito y paritario, est&aacute; conformado por igual n&uacute;mero de representantes del empleador y de los trabajadores de la misma.</p>
<p>Art&iacute;culo 7.- En caso la empresa cuente con un sindicato mayoritario, deber&aacute; incorporar un miembro del respectivo sindicato en calidad de observador sin voz ni voto al comit&eacute;.</p>
<p><strong>Cap&iacute;tulo II </strong></p>
<p><strong>De la Organizaci&oacute;n </strong></p>
<p>Art&iacute;culo 8.- La estructura org&aacute;nica del comit&eacute; es la siguiente:</p>
<ol style="list-style-type: lower-alpha">
<li>Presidente, quien es elegido por el comit&eacute; entre sus propios representantes, representa al comit&eacute; ante el empleador y se encarga de convocar, presidir y dirigir las reuniones del comit&eacute; y facilitar la aplicaci&oacute;n de los acuerdos.</li>
<li>Secretario, quien es elegido por el comit&eacute; entre sus propios representantes y es el encargado de las labores administrativas del comit&eacute;.</li>
<li>Vocales, los dem&aacute;s miembros que conforman el comit&eacute; y se encargan de aportar iniciativas propias o del personal del empleador para ser tratadas en las sesiones, as&iacute; como fomentar y hacer cumplir los acuerdos tomados.</li>
</ol>
<p><strong>T&Iacute;TULO III </strong></p>
<p><strong>DEL COMIT&Eacute; DE SEGURIDAD Y SALUD OCUPACIONAL</strong></p>
<p><strong> Cap&iacute;tulo I </strong></p>
<p><strong>Constituci&oacute;n del Comit&eacute; de Seguridad y Salud Ocupacional </strong></p>
<p>Art&iacute;culo 9.- El n&uacute;mero de personas que componen el comit&eacute; es definido por acuerdo de partes, no pudiendo ser menor de cuatro (4) ni mayor de doce (12) miembros. A fin de determinar el n&uacute;mero de miembros, entre otros criterios, se puede tomar en cuenta el nivel de riesgo y el n&uacute;mero de trabajadores. A falta de acuerdo, el n&uacute;mero de miembros del comit&eacute; no es menor de seis (6) en los centros de trabajo con m&aacute;s de cien (100) trabajadores, agreg&aacute;ndose al menos a dos (2) miembros por cada cien (100) trabajadores adicionales, hasta un m&aacute;ximo de doce (12) miembros.</p>
<p>Art&iacute;culo 10.- Para ser elegido representante por parte de los trabajadores se requiere:</p>
<ol style="list-style-type: lower-alpha;">
<li>Ser trabajador con contrato vigente, estar en planilla.</li>
<li>Tener dieciocho (18) a&ntilde;os de edad como m&iacute;nimo.</li>
<li>De preferencia tener capacitaci&oacute;n en temas de seguridad y salud en el trabajo o laborar en puestos que permitan tener conocimiento o informaci&oacute;n sobre riesgos laborales.</li>
</ol>
<p>Art&iacute;culo 11.- Para ser elegido como representante del empleador se requiere:</p>
<ol style="list-style-type: lower-alpha;">
<li>Ser trabajador con contrato vigente, estar en planilla.</li>
<li>Tener dieciocho (18) a&ntilde;os de edad como m&iacute;nimo.</li>
<li>De preferencia tener capacitaci&oacute;n en temas de seguridad y salud en el trabajo o laborar en puestos que permitan tener conocimiento o informaci&oacute;n sobre riesgos laborales.</li>
<li>Ser personal de direcci&oacute;n y confianza de acuerdo a la estructura organizacional de la empresa.</li>
</ol>
<p>Art&iacute;culo 12.- El mandato de los representantes de los trabajadores dura un (1) a&ntilde;o como m&iacute;nimo y dos (2) a&ntilde;os como m&aacute;ximo. Los representantes por parte del empleador ejercer&aacute;n el mandato por plazo que &eacute;ste determine.</p>
<p><strong>Cap&iacute;tulo II </strong></p>
<p><strong>Funciones del Comit&eacute; </strong></p>
<p>Art&iacute;culo 13.- Son funciones del Comit&eacute; de Seguridad y Salud Ocupacional las siguientes:</p>
<ol style="list-style-type: lower-alpha;">
<li>Vigilar el cumplimiento de la normativa de seguridad y salud ocupacional general, espec&iacute;fica, normas internas y especificaciones t&eacute;cnicas del trabajo, armonizando las actividades de sus miembros y fomentando el trabajo en equipo.</li>
<li>Elaborar y aprobar el presente reglamento de constituci&oacute;n.</li>
<li>Aprobar el Programa Anual de Seguridad y Salud Ocupacional (PASSO).</li>
<li>Realizar las recomendaciones apropiadas para el mejoramiento de las condiciones relacionadas con la seguridad y salud ocupacional y el medio ambiente de trabajo, as&iacute; como verificar que se lleven a efecto las medidas acordadas y evaluar su eficiencia.</li>
<li>Mensualmente, analizar y evaluar los resultados del mes anterior, as&iacute; como el avance de los objetivos y metas establecidos en el Programa Anual de Seguridad y Salud Ocupacional.</li>
<li>Realizar inspecciones peri&oacute;dicas de todas las instalaciones, lugar de trabajo, maquinaria y equipos a fin de reforzar la gesti&oacute;n preventiva.</li>
<li>Aprobar el Reglamento Interno de Seguridad y Salud Ocupacional (RISST).</li>
<li>Conocer, aprobar y dar seguimiento al cumplimiento del Programa Anual de Seguridad y Salud Ocupacional, Programa Anual del Servicio de Seguridad y Salud Ocupacional y del Programa Anual de Capacitaci&oacute;n.</li>
<li>Revisar y analizar las causas y las estad&iacute;sticas de los incidentes, incidentes peligrosos, accidentes de trabajo y enfermedades ocupacionales, emitiendo las recomendaciones pertinentes.</li>
<li>Poner en conocimiento de la alta gerencia o del &oacute;rgano que se precise en el Reglamento Interno el resultado de la investigaci&oacute;n de las causas de los incidentes, incidentes peligrosos, accidentes de trabajo y enfermedades ocupacionales, para que se inicie la investigaci&oacute;n correspondiente.</li>
<li>Promover que los trabajadores nuevos reciban la correspondiente inducci&oacute;n, capacitacion y entrenamiento sobre la prevenci&oacute;n de riesgos laborales presentes en el lugar y puesto de trabajo, as&iacute; como en temas de prevenci&oacute;n de riesgos.</li>
<li>Participar en la elaboraci&oacute;n, aprobaci&oacute;n, puesta en pr&aacute;ctica y evaluaci&oacute;n de las pol&iacute;ticas, planes y programas de promoci&oacute;n de la seguridad y salud ocupacional, de la prevenci&oacute;n de accidentes y enfermedades ocupacionales.</li>
<li>Promover que los trabajadores est&eacute;n informados y conozcan los reglamentos, instrucciones, especificaciones t&eacute;cnicas de trabajo, avisos y dem&aacute;s documentos descritoso gr&aacute;ficos relativos a la prevenci&oacute;n de los riesgos en el lugar de trabajo.</li>
<li>Colaborar con los servicios m&eacute;dicos y de primeros auxilios.</li>
</ol>
<p><strong>Cap&iacute;tulo III </strong></p>
<p><strong>Obligaciones del Comit&eacute; y sus miembros </strong></p>
<p>Art&iacute;culo 14.- Son obligaciones del Comit&eacute; de Seguridad y Salud Ocupacional y de sus miembros las siguientes:</p>
<p>a. El Presidente es el encargado de convocar, presidir y dirigir las reuniones del comit&eacute; y facilitar la aplicaci&oacute;n y vigencia de los acuerdos de &eacute;ste. Representa al Comit&eacute; ante el empleador.</p>
<p>b. El Secretario es el encargado de las labores administrativas del comit&eacute;.</p>
<p>c. Programar previamente las reuniones mensuales ordinarias del Comit&eacute; de Seguridad y Salud Ocupacional que se llevan a cabo un d&iacute;a laborable. La programaci&oacute;n de las reuniones extraordinarias se efect&uacute;a para analizar los accidentes mortales o cuando las circunstancias lo exijan de acuerdo a la normativa vigente.</p>
<p>d. Reunirse en forma ordinaria una vez por mes, en d&iacute;a previamente fijado o, en forma extraordinaria a convocatoria de su Presidente, a solicitud de al menos dos (2) de sus miembros, o en caso de ocurrir un accidente mortal.</p>
<p>e. Llevar en el Libro de Actas del Comit&eacute; de todas las reuniones, que puede llevarse de manera electr&oacute;nica si es que se cuenta con sistema de firmas digitalizadas, donde se anota todo lo tratado en las sesiones del Comit&eacute; de Seguridad y Salud Ocupacional; y llevar el control del cumplimiento de los acuerdos registrados en el Libro de Actas.</p>
<p>f. Portar una tarjeta de identificaci&oacute;n o distintivo especial que acredite su condici&oacute;n de miembro del Comit&eacute; de Seguridad y Salud Ocupacional, lo cual es suministrado por el empleador.</p>
<p>g. Los miembros del Comit&eacute; aportan iniciativas propias o del personal para ser tratados en las reuniones y son los encargados de fomentar y hacer cumplir las disposiciones o acuerdos tomados por el comit&eacute;.</p>
<p>h. Los miembros del Comit&eacute; pueden solicitar la informaci&oacute;n y asesor&iacute;a t&eacute;cnica que crean necesaria para cumplir con sus fines.</p>
<p>i. Crear, cuando la magnitud de la organizaci&oacute;n del titular de actividad minera lo requiera, comisiones t&eacute;cnicas para el desarrollo de tareas espec&iacute;ficas, tales como, la investigaci&oacute;n de accidentes de trabajo, el dise&ntilde;o del programa de capacitaci&oacute;n, la elaboraci&oacute;n de procedimientos, entre otras. La composici&oacute;n de estas comisiones es determinada por el Comit&eacute;.</p>
<p>j. En caso no se alcance el qu&oacute;rum m&iacute;nimo para sesi&oacute;n del comit&eacute; - que es la mitad m&aacute;s uno de sus integrantes- este &oacute;rgano deber&aacute; reunirse nuevamente, bajo nueva convocatoria emitida por el Presidente del Comit&eacute;; esta reuni&oacute;n se llevar&aacute; a cabo con el n&uacute;mero de asistentes que hubiere, levant&aacute;ndose en cada caso el acta respectiva.</p>
<p>k. Procurar que los acuerdos sean adoptados por consenso y no por el sistema de votaci&oacute;n. En el caso de no alcanzar consenso, se requiere mayor&iacute;a simple. En caso de empate, el Presidente tiene el voto dirimente.</p>
<p>l. Al t&eacute;rmino de cada sesi&oacute;n se levanta la respectiva acta que ser&aacute; asentada en el correspondiente Libro de Actas. Una copia de &eacute;sta se entrega a cada uno de los integrantes del Comit&eacute; y a la m&aacute;xima instancia de gerencia o decisi&oacute;n del titular de actividad minera.</p>
<p>m. Reportar a la Gerencia General la siguiente informaci&oacute;n:</p>
<p>m.1. El accidente fatal o incidente peligroso, de manera inmediata. m.2. La investigaci&oacute;n de cada accidente fatal y acciones correctivas adoptadas dentro de los diez (10) d&iacute;as de ocurrido.</p>
<p>m.3. Las estad&iacute;sticas trimestrales de accidentes y enfermedades ocupacionales.</p>
<p>m.4. Las actividades trimestrales del Comit&eacute; de Seguridad y Salud en el Trabajo. n. Redactar un Informe Anual, donde se resumen las labores realizadas. o. Las reuniones del Comit&eacute; s&oacute;lo versar&aacute;n de temas relacionados a la Seguridad y Salud Ocupacional.</p>
<p>Art&iacute;culo 15.- Las reuniones del comit&eacute; se realizan en un espacio adecuado y debidamente implementado con las condiciones necesarias para el desarrollo de las mismas, el cual es proporcionado por el empleador. Asimismo, las reuniones se realizan dentro de la jornada del trabajo.</p>
<p><strong>Cap&iacute;tulo IV </strong></p>
<p><strong>Capacitaci&oacute;n y goce de la licencia de los representantes de los trabajadores del Comit&eacute; de Seguridad y Salud Ocupacional </strong></p>
<p>Art&iacute;culo 16.- Los miembros del Comit&eacute; Seguridad y Salud Ocupacional deben recibir capacitaciones especializadas en Seguridad y Salud Ocupacional a cargo del empleador. Estas capacitaciones deber&aacute;n realizarse dentro de la jornada laboral.</p>
<p>Art&iacute;culo 17.- Los trabajadores miembros del Comit&eacute; de Seguridad y Salud Ocupacional gozan de licencia con goce de haber por treinta (30) d&iacute;as naturales por a&ntilde;o calendario para la realizaci&oacute;n de sus funciones. En caso las actividades tengan duraci&oacute;n menor a un a&ntilde;o, el n&uacute;mero de d&iacute;as de licencia ser&aacute; computado en forma proporcional. Los d&iacute;as de licencia o su fracci&oacute;n se consideran efectivamente laborados para todo efecto legal.</p>
<p>Art&iacute;culo 18.- La licencia ser&aacute; otorgada solo si el miembro cuenta con la previa autorizaci&oacute;n del Comit&eacute; de Seguridad y Salud Ocupacional para el uso de la misma. Dicha decisi&oacute;n deber&aacute; ser tomada conforme a las disposiciones relativas a la adopci&oacute;n de acuerdos del comit&eacute;. Los d&iacute;as de licencia que sean otorgados se consideran como efectivamente laborados para todo efecto legal.</p>
<p><strong>Cap&iacute;tulo V </strong></p>
<p><strong>Vacancia de los miembros del Comit&eacute; de Seguridad y Salud Ocupacional </strong></p>
<p>Art&iacute;culo 19.- El cargo de miembro del comit&eacute; queda vacante por alguna de las siguientes causales:</p>
<ol style="list-style-type: lower-alpha;">
<li>Vencimiento del plazo establecido para el ejercicio del cargo.</li>
<li>Inasistencia injustificada a tres (03) sesiones consecutivas del comit&eacute; o inasistencia a cuatro (04) alternadas en el lapso de sus vigencia.</li>
<li>Enfermedad f&iacute;sica o mental que inhabilita para el ejercicio del cargo.</li>
<li>Por cualquier otra causa que extinga la relaci&oacute;n laboral del trabajador.</li>
</ol>
<p>Art&iacute;culo 20.- En caso de vacancia durante la vigencia del mandato de los miembros del Comit&eacute; de Seguridad y Salud Ocupacional, trat&aacute;ndose de los miembros de los representantes de los trabajadores, el o los cargos vacantes deben ser cubiertos por el suplente o los suplentes que hubieren, hasta que se complete el periodo.</p>
<p>Art&iacute;culo 22.- Los representantes de los trabajadores suplentes asumen los cargos vacantes de acuerdo al orden por mayor n&uacute;mero de votos obtenidos durante el proceso de elecci&oacute;n, en caso de haber empate entre los suplentes, la decisi&oacute;n debe ser tomada por el Comit&eacute; de Seguridad y Salud en el Ocupacional.</p>
<p>Art&iacute;culo 23.- En caso de vacancia de los representantes de la parte empleadora, el empleador deber&aacute; designar al suplente correspondiente.</p>
<p><strong>Cap&iacute;tulo VI </strong></p>
<p><strong>Instalaci&oacute;n para la primera reuni&oacute;n del Comit&eacute; de Seguridad y Salud Ocupacional </strong></p>
<p>Art&iacute;culo 24.- La convocatoria a la instalaci&oacute;n del Comit&eacute; de Seguridad y Salud en el Trabajo corresponde a ${nameCompany} . La instalaci&oacute;n del Comit&eacute; se realiza en los siguientes d&iacute;as de realizada la elecci&oacute;n. Dicho acto se lleva a cabo en el local de la empresa levant&aacute;ndose el Acta de Constituci&oacute;n del Comit&eacute; de Seguridad y Salud Ocupacional, la que debe contener:</p>
<ul>
<li>Nombre de la empresa</li>
<li>Nombres y cargos de los miembros titulares del Comit&eacute;.</li>
<li>Nombres y cargos de los miembros suplentes.</li>
<li>Nombre y cargo del observador designado por la organizaci&oacute;n sindical m&aacute;s representativa de los trabajadores, de ser el caso.</li>
<li>Lugar, fecha y hora de la instalaci&oacute;n.</li>
<li>Otros de importancia.</li>
</ul>
<p>Art&iacute;culo 25.- El acto de constituci&oacute;n e instalaci&oacute;n, as&iacute; como toda reuni&oacute;n, acuerdo o evento del Comit&eacute; de Seguridad y Salud Ocupacional debe ser asentado en el Libro de Actas del Comit&eacute; de Seguridad y Salud Ocupacional.</p>
<p>Art&iacute;culo 26.- Concluido el acto de instalaci&oacute;n, el Comit&eacute; de Seguridad y Salud Ocupacional asume las responsabilidades de ley y empezar&aacute; a desarrollar sus funciones en prevenci&oacute;n en materia de seguridad y salud en el trabajo, debiendo observar los alcances del presente reglamento.</p>
<p><strong>Cap&iacute;tulo VII </strong></p>
<p><strong>Elecci&oacute;n del Presidente del Comit&eacute; de Seguridad y Salud Ocupacional</strong></p>
<p>Art&iacute;culo 27.- De no alcanzarse consenso en la elecci&oacute;n del presidente y del secretario del comit&eacute; en dos (02) sesiones sucesivas, la designaci&oacute;n del presidente se decide por sorteo; y la otra parte asume autom&aacute;ticamente la secretar&iacute;a.</p>
`;