import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Switch, FormControl, OutlinedInput,
  FormControlLabel
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  VisibilityOutlined,
  BorderColorOutlined,
  PictureAsPdf,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import MyRegistersAccidents from "../../../../api/MyRegistersAccidents";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import MyAreas from "../../../../api/MyAreas";
import CardUserControlaAccident from "../../../../components/cards/cardUserControlaAccidents";
import { useAppContext } from "../../../../context/AppContext";
import MyTasksNew from "../../../../api/MyTasksNew";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import ButtonSignatureControla from "../../../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../../../components/buttons/returnBackControla";
import MyContractors from "../../../../api/MyContractors";
import MyContractorsEmployees from "../../../../api/contractors/MyContractorsEmployees";
import ModalAddWorker from "./components/modalAddWorker";
import MyContractorsRelationEmployees from "../../../../api/contractors/MyContractorsRelationEmployees";

const colorTitle = "#9191B0";
const blue = "#034AFF";

// Componente principal
function Edit() {
  const { permissions } = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const idRegistersAccidents = params.get("idRegistersAccidents");

  // BACKDROP
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } = stateAlert;

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };
  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  // * Estados para trabajadores involucrados
  const [idRegistersAccidentsWorkers, setIdRegistersAccidentsWorkers] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const handleClickOpenDelete = (idRegisterAccidentsWorkersValue) => {
    setOpenDelete(true);
    setIdRegistersAccidentsWorkers(idRegisterAccidentsWorkersValue);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdRegistersAccidentsWorkers(id);
    setIdAccidentsMeasures(id);
    setIdRegister(id);
    setIdRegistersAccidentsResponsible(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    const actions = {
      "deleteWorkers": () => handleDeleteAccidentsWorkers(),
      "deleteMeasures": () => handleDeleteCorrectivesActions(),
      "deleteResponsible": () => handleDeleteResponsibleWorkers(),
      "deleteTypeMildAccident": () => handleDeleteTypeMildAccident(),
      "deleteTypeDisablingAccident": () => handleDeleteTypeDisablingAccident(),
      "deleteTypeMortalAccident": () => handleDeleteTypeMortalAccident(),
    }
    actions[buttonActionType]
      ? actions[buttonActionType]() : console.log('Acción no controlada!');
    setOpenModalConfirm(false);
  };
  // ./ Dialogos de confirmacion FIN

  //! ------------------------------ Contratistas ------------------------------
  const [rowsContractors, setRowsContractors] = useState([]);
  const getContractors = async () => {
    const resp = await MyContractors.getContractors() ?? [];
    setRowsContractors(resp.data);
  }

  const [checked, setChecked] = useState(false);
  const [selectedContractorsIds, setSelectedContractorsIds] = useState([]);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (selectedContractorsIds.length > 0) {
      setChecked(true)
    }
  }, [selectedContractorsIds])

  useEffect(() => {
    if (checked === false) {
      // setSelectedContractorsIds([])
    } else {
      handleGetContractorsEmployeesByIdRegister();
    }
  }, [checked])

  // Manejar la selección de contratistas
  const handleSelectChange = (event) => {
    const { target: { value }, } = event;
    // Guardar solo los IDs de los contratistas seleccionados
    setSelectedContractorsIds(value.map(option => option.id_contractors));
  };

  const [contractorsEmployees, setContractorsEmployees] = useState([]);
  //! Obtener para filtrar los trabajadores de los contratistas seleccionados
  const handleGetContractorsEmployees = async () => {
    try {
      if (selectedContractorsIds.length < 1) {
        return handleClickAlert('info','Debe seleccionar al menos un contratista primero.');
      }
      const ids = selectedContractorsIds.join(',');
      const response = await MyContractorsEmployees.getContractorsEmployeesByIdContractor(ids);
      if (!response || response.success === false) {
        handleClickAlert('error', 'No se pudo obtener datos de los trabajadores de los contratistas.')
      }
      setContractorsEmployees(response.data ?? [])
    }
    catch (err) {
      handleClickAlert('error', `Error al obtener los trabajadores de contratista(s). ${err}`.trimEnd())
    }
  }

  const [rowContractorsEmployees, setRowContractorsEmployees] = useState([]);
  //! Obtener los trabajadores de los contratistas accidentados
  const handleGetContractorsEmployeesByIdRegister = async () => {
    try {
      if (!idRegistersAccidents) {
        return handleClickAlert('error', 'No se encontró el id del registro.');
      }
      const resp = await MyContractorsEmployees.getContractorsEmployeesByIdRegister({
        id_register: idRegistersAccidents, register: 'accidentes'
      });
      if (resp.success === true) {
        setRowContractorsEmployees(resp.data)
      }
      setContractorsEmployees(resp.data ?? [])
    }
    catch (err) {
      handleClickAlert('error', `Error al obtener los trabajadores de contratista(s). ${err}`.trimEnd())
    }
  }

  // agregar datos del trabajador DIALOG
  const [openModalUploadAddDates, setOpenModalUploadAddDates] = useState(false);
  const handleOpenModalWorkersInvolved = () => {
    setWorkExperience(null);
    setArea(null);
    setShift(null);
    setWorkHoursBefore(null);
    setResponsibleCorrectiveActions("");
    if (!checked) {
      handleGetUsersSearchsAddsWorkers();
    } else {
      handleGetContractorsEmployees();
    }
    setOpenModalUploadAddDates(true);
  };

  const handleCloseModalUploadAddDates = () => {
    handleGetAccidentsWorkers();
    setOpenModalUploadAddDates(false);
  };

  // agregar medidas correctivas DIALOG
  const [
    openModalUploadCorrectiveActions,
    setOpenModalUploadCorrectiveActions,
  ] = useState(false);

  const handleOpenModalUploadCorrectiveActions = () => {
    setTimestampStart(null);
    setTimestampEnd(null);
    setDescription(null);
    setStatusCorrectivesActions(null);
    handleGetStatusCorrectivesActions();
    handleGetSearchsCorrectivesActionWorkers();
    setOpenModalUploadCorrectiveActions(true);
  };

  const handleCloseModalUploadCorrectiveActions = () => {
    setResponsibleCorrectiveActions("");
    handleGetCorrectivesActions();
    setOpenModalUploadCorrectiveActions(false);
  };

  // agregar RESPONSABLES  DIALOG
  const [
    openModalUploadResponsibleRegistration,
    setOpenModalUploadCResponsibleRegistration,
  ] = useState(false);

  const handleOpenModalUploadResponsibleRegistration = () => {
    handleGetSearchsResponsiblesWorkers();
    setOpenModalUploadCResponsibleRegistration(true);
  };

  const handleCloseModalUploadResponsibleRegistration = () => {
    handleGetResponsibleWorkers();
    setOpenModalUploadCResponsibleRegistration(false);
  };

  // ACCIDENTE DE TRABAJO

  // Gravedad del accidente de trabajo
  const [severityAccidents, setSeverityAccidents] = useState(0);
  const [rowsSeverityWorkAccidents, setRowsSeverityWorkAccidents] = useState([]);

  const handleGetSeverityAccidents = async () => {
    const response = await MyRegistersAccidents.severityWorkAccident();
    if (response.success === false) {
    } else {
      setRowsSeverityWorkAccidents(response);
    }
    return response;
  };

  const [code, setCode] = useState("");
  const [affectedWorkers, setAffectedWorkers] = useState("");
  const [causesDescription, setcausesDescription] = useState("");
  const [descriptionWork, setDescriptionWork] = useState("");
  const [injuredBodyPartDescription, setInjuredBodyPartDescription] = useState("");
  const [location, setLocation] = useState("");
  const [medicalLeaveDays, setMedicalLeaveDays] = useState("");
  const [timestampAccident, setTimestampAccident] = useState("");
  const [timestampInvestigation, setTimestampInvestigation] = useState("");
  const [documentUrl1, setDocumentUrl1] = useState(null);
  const [documentUrl2, setDocumentUrl2] = useState(null);
  const [documentUrl3, setDocumentUrl3] = useState(null);

  // severidad del accidente
  const [severityIncidents, setSeverityIncidents] = useState(0);
  const [rowsSeverityIncidents, setRowsSeverityIncidents] = useState([]);

  const handleGetSeverityInccidents = async () => {
    const response = await MyRegistersAccidents.severityIncidents();
    if (response.success === false) {
    } else {
      setRowsSeverityIncidents(response);
    }
    return response;
  };

  // get Usuarios a buscar al agregar trabajadores
  const [rowsUsersSearchsAddsWorkers, setRowsUsersSearchsAddsWorkers] = useState([]);

  const handleGetUsersSearchsAddsWorkers = async () => {
    const response = await MyRegistersAccidents.getUserSearchsAddsWorkers({
      idRegistersAccidents,
    });
    setRowsUsersSearchsAddsWorkers(response);
  };
  // update register accidents
  const handleUpdateRegistersAccidents = async (event) => {
    event.preventDefault();

    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.updateRegistersAccidents({
      idRegistersAccidents,
      code: code,
      timestamp_accident: timestampAccident,
      timestamp_investigation: timestampInvestigation,
      location: location,
      id_registers_accidents_severities: severityAccidents,
      id_registers_accidents_severities_incident: severityIncidents,
      medical_leave_days: medicalLeaveDays,
      affected_workers: affectedWorkers,
      injured_body_part_desciption: injuredBodyPartDescription,
      description: descriptionWork,
      document_url_1: documentUrl1,
      document_url_2: documentUrl2,
      document_url_3: documentUrl3,
      causes_description: causesDescription,
      contractor_ids: selectedContractorsIds
    });

    if (!response || response.success === false) {
      handleClickAlert("error", "Error al actualizar");
    } else {
      handleGetAccidentsWorkers();
      handleClickAlert(
        "success",
        "Se actualizaron de forma correcta los datos del registro"
      );
    }
    handleCloseBackdrop();
    return response;
  };

  // edit register acidents (get data)
  const handleEditRegistersAccidents = async () => {
    setOpenBackdrop(true);

    if (Object.keys(permissions).length !== 0) {
      let response = await MyRegistersAccidents.editRegistersAccidents({
        idRegistersAccidents,
      });

      if (!response || response.success === false) {
        handleClickAlert('error', "No se pudo obtener los datos")
      } else {
        response = response.data;
        setRegisterStatus(
          response.id_registers_accidents_statuses === 1 ? true : false
        );
        setCode(response.code);
        setAffectedWorkers(response.affected_workers);
        setcausesDescription(response.causes_description);
        setInjuredBodyPartDescription(response.injured_body_part_desciption);
        setLocation(response.location);
        setMedicalLeaveDays(response.medical_leave_days);
        setTimestampAccident(
          response.timestamp_accident === null
            ? ""
            : response.timestamp_accident.substring(0, 16)
        );
        setTimestampInvestigation(
          response.timestamp_investigation === null
            ? ""
            : response.timestamp_investigation.substring(0, 10)
        );
        setDocumentUrl1(response.document_url_1);
        setDocumentUrl2(response.document_url_2);
        setDocumentUrl3(response.document_url_3);
        setDescriptionWork(response.description);
        setSeverityAccidents(response.id_registers_accidents_severities);
        setSeverityIncidents(response.id_registers_accidents_severities_incident);

        const contractorIdsString = response.contractor_ids;
        const contractorIdsArray = contractorIdsString != null ? contractorIdsString.split(',').map(id => parseInt(id, 10)) : [];
        setSelectedContractorsIds(contractorIdsArray)
      }

      handleCloseBackdrop();
      return response;
    }
  };

  // !----------- TRABAJADORES ACCIDENTADOS --------------------------
  const [handleOpenModalResponsableWorker, sethandleOpenModalResponsableWorker] = useState(false);
  const handleOpenModalResponsibleInvolvedWorkers = () => {
    setResponsibleCorrectiveActions("");
    // ! Obtener los datos de los empleados de los contratistas seleccionados
    handleGetContractorsEmployees();
    sethandleOpenModalResponsableWorker(true);
  };

  //* crear trabajadores accidentados
  const yearActually = new Date().getFullYear();
  const [accidentsWorkers, setAccidentsWorkers] = useState([]);
  const [area, setArea] = useState("");
  const [rowsAreaWorkerInvolved, setRowsAreaWorkerInvolved] = useState([]);

  //* mostrar las áreas desde configuración
  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setRowsAreaWorkerInvolved(response);
    return response;
  };

  const [shift, setShift] = useState("");
  const handleChangeShift = (event) => {
    setShift(event.target.value);
  };

  const [workExperience, setWorkExperience] = useState("");
  const [workHoursBefore, setWorkHoursBefore] = useState("");

  // * mostrar trabajadores accidentados de la empresa
  const handleGetAccidentsWorkers = async () => {
    const response = await MyRegistersAccidents.getAccidentsWorkers({
      idRegistersAccidents,
    });
    if (response.success === false) {
      return handleClickAlert("error", "No se pudo obtener datos de los accidentados");
    }
    setAccidentsWorkers(response);
  };

  // * guardar trabajadores accidentados
  const handleStoreAccidentsWorkers = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.creatAccidentsWorkers({
      idRegistersAccidents: idRegistersAccidents,
      id_users: idResponsible,
      area: area,
      shift: shift,
      work_experience: workExperience,
      work_hours_before: workHoursBefore,
    });
    if (!response || response.success === false) {
      console.log("info", response);
      handleCloseBackdrop();
      handleClickAlert('error', 'Error al guardar trabajadores accidentados');
    } else {
      await handleGetAccidentsWorkers();
      handleCloseModalUploadAddDates();
      handleCloseBackdrop();
      handleClickAlert("success","Se agregó correctamente los datos del trabajador");
    }
    return response;
  };

  // * eliminar trabajadores accidentados
  const handleDeleteAccidentsWorkers = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = checked
      ? await MyContractorsRelationEmployees.deleteRelationEmployee(idRegistersAccidentsWorkers)
      : await MyRegistersAccidents.deleteAccidentsWorkers({
        id_registers_accidents_workers: idRegistersAccidentsWorkers,
      });
    if (!response || response.success === false) {
      handleCloseBackdrop();
      return handleClickAlert("error", "Ha ocurrido un error");
    }
    (checked)
      ? await handleGetContractorsEmployeesByIdRegister()
      : await handleGetAccidentsWorkers();
    handleCloseDelete();
    handleCloseBackdrop();
    handleClickAlert("success", "Se eliminó correctamente el trabajador accidentado");
    return response;
  };

  /***************************  MEDIDAS CORRECTIVAS  *****************************/
  // buscar usuario por ID
  const [responsibleCorrectiveActions, setResponsibleCorrectiveActions] = useState(null);
  const [idResponsible, setIdResponsible] = useState(" ");

  const handleSelectUsersResponsible = async (idUsers) => {
    try {
      if (rowContractorsEmployees.some(employee => employee.id_users === idUsers)) {
        handleClickAlert("error", "El usuario seleccionado ya se encuentra en la lista de trabajadores.");
        return;
      }
      setIdResponsible(idUsers);
      let response = checked ? await MyContractorsEmployees.getContractorEmployeeById(idUsers)
        : await MyUsers.editUsers({ idUsers: idUsers });
      if (response.success !== false) {
        if (checked) {
          response = response.data[0];
        }
        setResponsibleCorrectiveActions(
          response.first_name + " " + response.last_name
        );
        setOpenModalUpload(false);
        sethandleOpenModalResponsableWorker(false);
      } else {
        handleClickAlert("error", "Error al obtener el usuario!");
      }
    }
    catch (err) {
      handleClickAlert('error', `Error al seleccionar los datos del trabajador. ${err}`.trimEnd());
    }
  };

  // abrir modal de busqueda de usuarios de medidas correctivas
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const handleOpenModalResponsible = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUpload(true);
  };

  // mostrar estado de la medida correctiva
  const [rowsStatusCorrectivesActions, setRowsStatusCorrectivesActions] = useState([]);
  const handleGetStatusCorrectivesActions = async () => {
    const response = await MyRegistersAccidents.getStatusCorrectivesActions();
    if (response.success === false) {
    } else {
      setRowsStatusCorrectivesActions(response);
    }
    return response;
  };

  // estado de la medida correctiva
  const [statusCorrectivesActions, setStatusCorrectivesActions] =
    useState(null);

  // crear medidas correctivas
  const [timestampStart, setTimestampStart] = useState("");
  const [timestampEnd, setTimestampEnd] = useState("");
  const [description, setDescription] = useState("");

  const handleStoreCorrectivesActions = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.createCorrectivesActions({
      idRegistersAccidents: idRegistersAccidents,
      id_registers_accidents_measures_statuses: statusCorrectivesActions,
      id_users: idResponsible,
      timestamp_start: timestampStart,
      timestamp_end: timestampEnd,
      description: description,
    });

    // agregar a tarea
    const data = {
      date_start: timestampStart,
      date_end: timestampEnd,
      description: description,
      id_status: 1,
      id_users: idResponsible,
      id_tasks_types: 2,
      title: "Tarea asignada en accidentes de trabajo",
      origin_url: `documentation/registers/generals/accidents/edit?idRegistersAccidents=${idRegistersAccidents}`
    }

    const res = await MyTasksNew.postTasks(data);

    if (response.success === false) {
      console.log("error", response);
    } else {
      await handleGetCorrectivesActions();
      handleCloseModalUploadAddDates();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente la medida correctiva"
      );
    }
    return response;
  };

  // mostrar medidas correctivas
  const [correctivesActions, setCorrectivesActions] = useState([]);
  const handleGetCorrectivesActions = async () => {
    const response = await MyRegistersAccidents.getCorrectivesActionWorkers({
      idRegistersAccidents,
    });
    if (response.success === false) {
      handleClickAlert("Error al obtener medidas correctivas");
    } else {
      setCorrectivesActions(response);
    }
  };

  // listar trabajadores para buscar en medidas correctivas
  const [rowsSearchsCorrectivesActionWorkers, setSearchsCorrectivesActionWorkers] = useState([]);
  const handleGetSearchsCorrectivesActionWorkers = async () => {
    const resp = await MyUsers.users() ?? [];
    setSearchsCorrectivesActionWorkers(resp.users_actives);
  };

  // eliminar medidas correctives
  const [idAccidentsMeasures, setIdAccidentsMeasures] = useState(0);
  const handleDeleteCorrectivesActions = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.deleteCorrectivesActions({
      id_registers_accidents_measures: idAccidentsMeasures,
    });
    if (response.success === true) {
      await handleGetCorrectivesActions();
      handleCloseBackdrop();
      handleCloseDelete();
      handleClickAlert("success", "Se eliminó  la medida correctiva");
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // TRABAJADORES RESPONSABLES
  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response = await MyRegistersAccidents.getResponsibleWorkers({
      idRegistersAccidents,
    });
    if (response.success === false) {
      handleClickAlert("error", "Ha ocurrido un error al obtener los responsables");
    } else {
      setResponsibleActions(response);
      setFirm(response.firm);
    }
  };

  //crear trabajadores responsables
  const [firm, setFirm] = useState();
  const handleStoreResponsibleWorkers = async (idUsers) => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.createResponsibleWorkers({
      idRegistersAccidents: idRegistersAccidents,
      id_users: idUsers,
      firm: firm,
    });

    if (response.success === false) {
      console.log("error", response);
    } else {
      await handleGetResponsibleWorkers();
      handleCloseModalUploadResponsibleRegistration();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert("success", "Se agregó correctamente ");
    }
    return response;
  };

  // eliminar trabajador responsable
  const [idRegistersAccidentsResponsible, setIdRegistersAccidentsResponsible] = useState(0);

  const handleDeleteResponsibleWorkers = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.deleteResponsibleWorkers({
      id_registers_accidents_responsible: idRegistersAccidentsResponsible,
    });
    if (response.success === true) {
      await handleGetResponsibleWorkers();
      handleCloseDelete();
      handleCloseBackdrop();
      handleClickAlert("success", "Se eliminó  al trabajador responsable");
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // Buscar trabajadores en medidas correctivas
  const [rowsSearchsResponsiblesWorkers, setSearchsResponsiblesWorkers] = useState([]);

  const handleGetSearchsResponsiblesWorkers = async () => {
    const response = await MyRegistersAccidents.getSearchsResponsiblesWorkers({
      idRegistersAccidents,
    });
    setSearchsResponsiblesWorkers(response);
  };

  const handleSignResponsible = async (idAccidentResponsible) => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.signResponsible({
      id_accidents_responsible: idAccidentResponsible
    });
    if (response.success !== false) {
      await handleGetResponsibleWorkers();
      handleClickAlert("success", "Se registró la firma");
    } else {
      handleClickAlert("error", "Error al obtener el usuario!");
    }
    handleCloseBackdrop();
  };

  const [openModalDatesUsers, setOpenModalDatesUers] = useState(false);

  const [userJsonDatesUsers, setuserJsonDatesUsers] = useState(false);
  const [documentDatesUsers, setDocumentDatesUsers] = useState(false);
  const [emailDatesUsers, setEmailDatesUsers] = useState(false);
  const [sexDatesUsers, setSexDatesUsers] = useState(false);
  const [phoneDatesUsers, setPhoneDatesUsers] = useState(false);
  const [ageDatesUsers, setAgeDatesUsers] = useState(false);
  const [currentTimeDatesUsers, setCurrentTimeDatesUsers] = useState(false);
  const [campusDatesUsers, setCampusDatesUsers] = useState(false);
  const [jobTypeDatesUsers, setJobTypeDatesUsers] = useState(false);

  const handleOpenModalDataUsers = (
    userJson, document, email, phone, sex, age, currentTime, campus, jobType
  ) => {
    setuserJsonDatesUsers(userJson);
    setDocumentDatesUsers(document);
    setEmailDatesUsers(email);
    setPhoneDatesUsers(phone);
    setSexDatesUsers(sex);
    setAgeDatesUsers(age);
    setCurrentTimeDatesUsers(currentTime);
    setCampusDatesUsers(campus);
    setJobTypeDatesUsers(jobType);
    setOpenModalDatesUers(true);
  };

  //------------- dialogo por tipo de gravedad del accidente-----------
  const [openModalTypeMild, setOpenModalTypeMild] = useState(false);
  const [openModalTypeDisabling, setOpenModalTypeDisabling] = useState(false);
  const [openModalTypeMortal, setOpenModalTypeMortal] = useState(false);
  const [typeActionButtonModal, setTypeActionButtonModal] = useState(null);
  const [typeModal, setTypeModal] = useState(null);
  const [idRegister, setIdRegister] = useState(null);

  const [rowsTypeMildAccident, setRowsTypeMildAccident] = useState([]);
  const [rowsTypeDisablingAccident, setRowsTypeDisablingAccident] = useState([]);
  const [rowsTypeMortalAccident, setRowsTypeMortalAccident] = useState([]);
  const [numberAffected, setNumberAffected] = useState(null);
  const [numberMedicalRest, setNumberMedicalRest] = useState(null);

  const handleGetRowsParts = (data) => {
    let rowsParts = [];
    data.forEach((p) => {
      rowsParts.push(p.body_part);
    });
    return rowsParts;
  }

  const handleGetRowsIdPartsSelected = (data) => {
    let rowsIdParts = [];
    data.forEach((p) => {
      rowsIdParts.push(p.id_body_parts);
    });
    return rowsIdParts;
  }

  const handleOpenModalTypeMild = (typeModal, typeAcctionButton, data) => {
    setOpenModalTypeMild(true);
    setTypeModal(typeModal);
    setTypeActionButtonModal(typeAcctionButton);
    if (typeModal == "update") {
      setBodyPartsLists(handleGetRowsParts(data.body_parts));
      setBodyPartSelected({});
      setIdRegister(data.id_accident_type_minor)
      setNumberAffected(data.affected_workers);
      setNumberMedicalRest(data.medical_rest_days);
    } else {
      setNumberAffected(1);
      setNumberMedicalRest(null);
      setBodyPartsLists([]);
      setBodyPartSelected({});
    }
  }
  const handleCloseModalTypeMild = () => setOpenModalTypeMild(false);

  const handleOpenModalTypeDisabling = (typeModal, typeAcctionButton, data) => {
    setOpenModalTypeDisabling(true);
    setTypeModal(typeModal);
    setTypeActionButtonModal(typeAcctionButton);
    if (typeModal == "update") {
      setBodyPartsLists(handleGetRowsParts(data.body_parts));
      setBodyPartSelected({})
      setIdRegister(data.id_accident_type_disabling)
      setSeverityIncidents(data.id_registers_accidents_severities_incident)
      setNumberAffected(data.affected_workers)
      setNumberMedicalRest(data.medical_rest_days)
    }
    else {
      setSeverityIncidents(null)
      setNumberAffected(1)
      setNumberMedicalRest(null)
      setBodyPartsLists([])
      setBodyPartSelected({})
    }
  }

  const handleCloseModalTypeDisabling = () => setOpenModalTypeDisabling(false);

  const handleOpenModalTypeMortal = (typeModal, typeAcctionButton, data) => {
    setOpenModalTypeMortal(true);
    setTypeModal(typeModal);
    setTypeActionButtonModal(typeAcctionButton);
    if (typeModal === "update") {
      setBodyPartsLists(handleGetRowsParts(data.body_parts));
      setBodyPartSelected({})
      setIdRegister(data.id_accident_type_mortal)
      setNumberAffected(data.affected_workers);
      setNumberMedicalRest(data.medical_rest_days);
    } else {
      setNumberAffected(1);
      setNumberMedicalRest(null);
      setBodyPartsLists([]);
      setBodyPartSelected({})
    }
  }

  const handleCloseModalTypeMortal = () => setOpenModalTypeMortal(false);
  const handleActionButtonModal = (e) => {
    e.preventDefault();
    const actions = {
      "createTypeMild": () => handlePostTypeMildAccident(),
      "updateTypeMild": () => handleUpdateTypeMildAccident(),
      "createTypeDisabling": () => handlePostTypeDisablingAccident(),
      "updateTypeDisabling": () => handleUpdateTypeDisablingAccident(),
      "createTypeMortal": () => handlePostTypeMortalAccident(),
      "updateTypeMortal": () => handleUpdateTypeMortalAccident(),
    }
    if (actions[typeActionButtonModal]) {
      actions[typeActionButtonModal]();
    }
  }

  const handleGetTypeMildAccident = async () => {
    const resp = await MyRegistersAccidents.getMildSeverityAccidents(idRegistersAccidents) ?? [];
    if (resp.success == true) {
      setRowsTypeMildAccident(resp.data);
    } else {
      handleClickAlert("error", "Problemas al traer la lista de accidentes leves");
    }

  }
  const handlePostTypeMildAccident = async () => {
    setOpenBackdrop(true);

    const data = {
      id_registers_accidents: idRegistersAccidents,
      medical_rest_days: numberMedicalRest,
      affected_workers: numberAffected,
      body_part_ids: handleGetRowsIdPartsSelected(bodyPartsLists)
    }

    const resp = await MyRegistersAccidents.postMildSeverityAccident(data);
    if (resp.success) {
      await handleGetTypeMildAccident();
      handleClickAlert("success", "Se registró el accidente leve con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un problema al registrar");
    }

    handleCloseBackdrop();
    handleCloseModalTypeMild();
  }

  const handleUpdateTypeMildAccident = async () => {
    setOpenBackdrop(true);
    const data = {
      affected_workers: numberAffected,
      medical_rest_days: numberMedicalRest,
      body_part_ids: handleGetRowsIdPartsSelected(bodyPartsLists),
    }

    const resp = await MyRegistersAccidents.updateMildSeverityAccident(data, idRegister)
    if (resp.success) {
      await handleGetTypeMildAccident();
      handleClickAlert("success", "Se actualizó el registro con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al actualizar");
    }

    handleCloseBackdrop();
    handleCloseModalTypeMild();

  }
  const handleDeleteTypeMildAccident = async () => {
    setOpenBackdrop(true);
    try {
      const resp = await MyRegistersAccidents.deleteMildSeverityAccident(idRegister);
      await handleGetTypeMildAccident();
      handleClickAlert("success", "Se eliminó el registro con éxito");
    } catch (e) {
      handleClickAlert("error", "Ocurrió un error al eliminar");
    }

    handleCloseBackdrop();
    handleCloseModalConfirm();
  }
  // * ----------------- Disabling accidents -----------------
  const handleGetTypeDisablingAccident = async () => {
    const resp = await MyRegistersAccidents.getDisablingSeverityAccident(idRegistersAccidents) ?? [];
    if (resp.success == true) {
      setRowsTypeDisablingAccident(resp.data);
    } else {
      handleClickAlert("error", "Problemas al traer accidentes de tipo incapacitante");
    }
  }
  const handlePostTypeDisablingAccident = async () => {
    setOpenBackdrop(true);
    const data = {
      id_registers_accidents: idRegistersAccidents,
      id_registers_accidents_severities_incident: severityIncidents,
      medical_rest_days: numberMedicalRest,
      affected_workers: numberAffected,
      body_part_ids: handleGetRowsIdPartsSelected(bodyPartsLists)
    }

    const resp = await MyRegistersAccidents.postDisablingSeverityAccident(data);
    if (resp.success) {
      await handleGetTypeDisablingAccident();
      handleClickAlert("success", "Se registró el accidente de tipo incapacitante con éxito");

    } else {
      handleClickAlert("error", "Ocurrió un error al registrar");
    }

    handleCloseBackdrop();
    handleCloseModalTypeDisabling();
  }

  const handleUpdateTypeDisablingAccident = async () => {
    setOpenBackdrop(true);
    const data = {
      id_registers_accidents_severities_incident: severityIncidents,
      medical_rest_days: numberMedicalRest,
      affected_workers: numberAffected,
      body_part_ids: handleGetRowsIdPartsSelected(bodyPartsLists)
    }
    const resp = await MyRegistersAccidents.updateDisablingSeverityAccident(data, idRegister);
    if (resp.success) {
      await handleGetTypeDisablingAccident();
      handleClickAlert("success", "Se actualizó el registro con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al actualizar");
    }
    handleCloseModalTypeDisabling();
    handleCloseBackdrop();
  }

  const handleDeleteTypeDisablingAccident = async () => {
    setOpenBackdrop(true);
    try {
      const resp = await MyRegistersAccidents.deleteDisablingSeverityAccident(idRegister);
      await handleGetTypeDisablingAccident();
      handleClickAlert("success", "Se eliminó el registro con éxito");
    } catch (e) {
      handleClickAlert("error", "Ocurrió un error al eliminar");
    }
    handleCloseModalConfirm();
    handleCloseBackdrop();
  }

  // * ----------------- Mortal accidents -----------------
  const handleGetTypeMortalAccident = async () => {
    const resp = await MyRegistersAccidents.getMortalSeveretyAccident(idRegistersAccidents) ?? [];
    if (resp.success == true) {
      setRowsTypeMortalAccident(resp.data);
    } else {
      handleClickAlert("error", "Problemas al traer accidentes de tipo mortal");
    }
  }

  const handlePostTypeMortalAccident = async () => {
    setOpenBackdrop(true);
    const data = {
      id_registers_accidents: idRegistersAccidents,
      medical_rest_days: numberMedicalRest,
      affected_workers: numberAffected,
      body_part_ids: handleGetRowsIdPartsSelected(bodyPartsLists)
    }
    const resp = await MyRegistersAccidents.postMortalSeveretyAccident(data);
    if (resp.success) {
      await handleGetTypeMortalAccident();
      handleClickAlert("success", "Se registró el accidente de tipo mortal con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al registrar");
    }
    handleCloseModalTypeMortal();
    handleCloseBackdrop();
  }

  const handleUpdateTypeMortalAccident = async () => {
    setOpenBackdrop(true);
    const data = {
      medical_rest_days: numberMedicalRest,
      affected_workers: numberAffected,
      body_part_ids: handleGetRowsIdPartsSelected(bodyPartsLists)
    }
    const resp = await MyRegistersAccidents.updateMortalSeveretyAccident(data, idRegister);
    if (resp.success) {
      await handleGetTypeMortalAccident();
      handleClickAlert("success", "Se actualizó el registro con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al actualizar");
    }
    handleCloseModalTypeMortal();
    handleCloseBackdrop();
  }

  const handleDeleteTypeMortalAccident = async () => {
    setOpenBackdrop(true);
    try {
      const resp = await MyRegistersAccidents.deleteMortalSeveretyAccident(idRegister);
      await handleGetTypeMortalAccident();
      handleClickAlert("success", "Se eliminó el registro con éxito");
    } catch (e) {
      handleClickAlert("error", "Ocurrió un error al eliminar");
    }
    handleCloseModalConfirm();
    handleCloseBackdrop();
  }

  //partes del cuerpo lesionado
  const [bodyParts, setBodyParts] = useState([]);
  const [bodyPartSelected, setBodyPartSelected] = useState({});
  const [bodyPartsLists, setBodyPartsLists] = useState([]);

  const handleGetBodyParts = async () => {
    const resp = await MyRegistersAccidents.getBodyParts();
    setBodyParts(resp);
  }

  const handleOnChangeBodyPart = (e) => {
    setBodyPartSelected(e);
    const isExist = bodyPartsLists.some((part) => part.id_body_parts === e.id_body_parts);
    if (!isExist) {
      setBodyPartsLists((parts) => [...parts, e]);
    }
  }

  const handleDeleteBodyParts = (e) => {
    const newArray = bodyPartsLists.filter((part) => part !== e);
    setBodyPartsLists(newArray);
  }


  // ! ------------------------------- CERRAR ESTADO DEL REGISTRO -------------------------------
  const [registerStatus, setRegisterStatus] = useState(true);

  const handleGetRegisterStatus = async () => {
    if (
      responsibleWorkers.length >= 1 &&
      correctivesActions.length >= 1 &&
      accidentsWorkers.length >= 1
    ) {
      const finded = responsibleWorkers.find((item) => item.firm === 0);

      if (finded === undefined) {
        const response = await MyRegistersAccidents.getRegisterStatus({
          idRegistersAccidents: idRegistersAccidents,
        });
        if (response.success === false) {
          handleClickAlert("error", "Error al obtener el estado");
        } else {
          handleEditRegistersAccidents();
        }
        return response;
      } else {
        handleClickAlert("error", "Registre firmas pendientes");
      }
    } else {
      handleClickAlert("error", "Registre datos en las secciones: Trabajador involucrado, medidas correctivas y agregar responsable");
    }
  };

  // ! ------------------------------- PDF -------------------------------
  const handleGeneratePDF = async () => {
    try {
      setOpenBackdrop(true);
      const response = await MyRegistersAccidents.generatePDF({ idRegistersAccidents });
      const blob = await response.blob();

      // Crear un enlace temporal para descargar el archivo
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `register-${idRegistersAccidents}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setOpenBackdrop(false);
    } catch {
      setOpenBackdrop(false);
      console.error('Hubo un error con la generacion del pdf', 'error');
    }
  };

  useEffect(() => {
    handleGetAreas();
    handleGetSeverityAccidents();
    handleGetSeverityInccidents();
    getContractors()
    // medidas correctivas
    handleGetCorrectivesActions();
    // trabajadores responsables
    handleGetResponsibleWorkers();
    handleGetBodyParts();
    handleGetTypeMildAccident();
    handleGetTypeDisablingAccident();
    handleGetTypeMortalAccident();

    if (!checked) {
      handleGetAccidentsWorkers();
    }
    else {
      // ! obtener los trabajadores de los contratistas accidentados
      if (selectedContractorsIds.length < 1) return;
      handleGetContractorsEmployeesByIdRegister();
    }
  }, []);

  useEffect(() => {
    handleEditRegistersAccidents();
  }, [permissions])


  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack />

      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      {/* //* -------------------- Alerts -------------------- */}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText text={"Accidentes de trabajo"}></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!registerStatus}
                    checked={checked}
                    onChange={handleCheckboxChange}
                    label="Habilitar contratistas"
                  />
                }
                label="Habilitar contratistas"
              />
              {checked && (<>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="multi-select-label">Contratistas</InputLabel>
                    <Select
                      labelId="multi-select-label"
                      multiple
                      disabled={!registerStatus}
                      value={rowsContractors.filter(option => selectedContractorsIds.includes(option.id_contractors))} // Selecciona solo los contratistas por ID
                      onChange={handleSelectChange}
                      input={<OutlinedInput label="Opciones" />}
                      renderValue={(selected) => (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {selected.map((contractor) => (
                            <Chip key={contractor.id_contractors} label={contractor.company_name} style={{ margin: 2 }} />
                          ))}
                        </div>
                      )}
                    >
                      {rowsContractors.map((option) => (
                        <MenuItem key={option.id_contractors} value={option}>
                          {option.company_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
              )}
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <InputControlaVertical
                roles={permissions.DOC_REGIS_AT_EDITAR}
                text={"Nro registro"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUpdateRegistersAccidents}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  {checked ? 'Datos del trabajador del contratista' : 'Datos del trabajador'}
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus && (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AT_EDITAR}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos del trabajador"}
                    functionOnClick={handleOpenModalWorkersInvolved}
                  />
                )}
              </Box>
            </Stack>
            <Grid container sx={{ marginTop: "4px" }}>
              {checked ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {[
                          'Contratista', 'Nombre y Apellido', 'Documento de Identidad', 'Edad', 'Área', 'Turno', 'Tiempo de experiencia en el puesto de trabajo', 'N.º de horas trabajadas antes del accidente', ''
                        ].map((col, index) => (
                          <TableCell key={index} sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}>{col}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowContractorsEmployees.length > 0 ? (
                        rowContractorsEmployees.map((employee) => (
                          <TableRow key={employee.id}>
                            <TableCell>{employee.company_name}</TableCell>
                            <TableCell>{employee.first_name} {employee.last_name}</TableCell>
                            <TableCell>{employee.document}</TableCell>
                            <TableCell>{employee.birthday ? new Date().getFullYear() - new Date(employee.birthday).getFullYear() : ''}</TableCell>
                            <TableCell>{employee.area}</TableCell>
                            <TableCell>{employee.shift}</TableCell>
                            <TableCell>{employee.work_experience}</TableCell>
                            <TableCell>{employee.work_hours_before}</TableCell>
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                                spacing={1}
                              >
                                <ButtonIconControla
                                  roles={[1, 3, 4, 7, 8, 12, 13]}
                                  icon={
                                    <VisibilityOutlined sx={{ color: "white" }} />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  functionOnClick={() =>
                                    handleOpenModalDataUsers(
                                      employee, true, true, true, true, true, true, true, true
                                    )
                                  }
                                />
                                {registerStatus === true ? (
                                  <ButtonIconControla
                                    roles={permissions.DOC_REGIS_AT_AGREGAR_DATOS}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() => {
                                      handleOpenModalConfirm(
                                        employee.id,
                                        "¿Esta seguro de eliminar los datos del trabajador?",
                                        "Una vez eliminado no se podra recuperar los datos del trabajador",
                                        "Si, Eliminar",
                                        "deleteWorkers"
                                      );
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8} sx={{ textAlign: "center" }}>- Sin datos -</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (<>
                <Grid container sx={{ marginTop: "4px" }}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {['Nombre y Apellido', 'Documento de Identidad', 'Edad', 'Área', 'Turno', 'Tiempo de experiencia en el puesto de trabajo', 'N.º de horas trabajadas antes del accidente', ''].map((col, index) => (
                            <TableCell key={index} sx={{
                              color: "#305ad9",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}>{col}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {accidentsWorkers.map((rowsAccidentsWorkers) => (
                          <TableRow
                            hover
                            key={rowsAccidentsWorkers.id_users}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {rowsAccidentsWorkers.first_name +
                                " " +
                                rowsAccidentsWorkers.last_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {rowsAccidentsWorkers.document}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {rowsAccidentsWorkers.birthday ?
                                yearActually - rowsAccidentsWorkers.birthday.substring(0, 4)
                                : 'Fecha de nacimiento no disponible'
                              }
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {rowsAccidentsWorkers.area}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {rowsAccidentsWorkers.shift}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {rowsAccidentsWorkers.work_experience}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {rowsAccidentsWorkers.work_hours_before}
                            </TableCell>

                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                                spacing={1}
                              >
                                <ButtonIconControla
                                  roles={[1, 3, 4, 7, 8, 12, 13]}
                                  icon={
                                    <VisibilityOutlined sx={{ color: "white" }} />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  functionOnClick={() =>
                                    handleOpenModalDataUsers(
                                      rowsAccidentsWorkers,
                                      true,
                                      true,
                                      true,
                                      true,
                                      true,
                                      true,
                                      true,
                                      true
                                    )
                                  }
                                />
                                {registerStatus === true ? (
                                  <ButtonIconControla
                                    roles={permissions.DOC_REGIS_AT_AGREGAR_DATOS}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() => {
                                      handleOpenModalConfirm(
                                        rowsAccidentsWorkers.id_registers_accidents_workers,
                                        "¿Esta seguro de eliminar los datos del trabajador?",
                                        "Una vez eliminado no se podra recuperar los datos del trabajador",
                                        "Si, Eliminar",
                                        "deleteWorkers"
                                      );
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>)}

            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Investigación del accidente de trabajo"}
                  color={blue}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_AT_EDITAR}
                  text={"Fecha y hora de ocurrencia del accidente"}
                  inputType={"datetime-local"}
                  inputValue={setTimestampAccident}
                  value={timestampAccident}
                  required
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_AT_EDITAR}
                  text={"Fecha de inicio de la investigación"}
                  inputType={"date"}
                  inputValue={setTimestampInvestigation}
                  value={timestampInvestigation}
                  required
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_AT_EDITAR}
                  text={"Lugar exacto donde ocurrió el accidente"}
                  inputType={"text"}
                  inputValue={setLocation}
                  value={location}
                  required
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
            </Grid>

            {/* Investigación del accidente de trabajo */}
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Investigación del accidente de trabajo"}
                  color={blue}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>

              {/* tipo leve */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: 3, marginBottom: 1 }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        padding: "8px",
                        fontSize: "18px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      Gravedad del accidente del trabajo: Leve
                    </InputLabel>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ color: "#F2994A", ml: 1 }}
                    >
                      Este campo es opcional de acuerdo al accidente
                    </Typography>
                  </Box>
                  <Box>
                    {
                      registerStatus && (
                        <ButtonControla
                          iconButton={<Add sx={{ color: "white" }} />}
                          backgroundColor={"#169073"}
                          backgroundColorHover={"#1BAA88"}
                          textButton={"Agregar datos"}
                          functionOnClick={() => handleOpenModalTypeMild("create", "createTypeMild", null)}
                        />
                      )}
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 600 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {['N° trabajador afectado', 'N° días de descanso médico', 'Partes del cuerpo lesionado', registerStatus && (
                          <TableCell
                            sx={{
                              color: "#305ad9",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          ></TableCell>
                        )].map(col => (
                          <TableCell
                            sx={{
                              color: "#305ad9",
                              fontSize: "16px",
                              fontWeight: "bold",
                              textAlign: "center"
                            }}
                          >{col}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        rowsTypeMildAccident.map((row) => (
                          <TableRow
                            hover
                            key={row.id_accident_type_minor}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row" align="center">
                              {row.affected_workers}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                              {row.medical_rest_days}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <ul>
                                {row.body_parts.map((item, index) => (
                                  <li key={index}>{item.body_part.name}</li>
                                ))}
                              </ul>
                            </TableCell>
                            {
                              registerStatus && (
                                <TableCell align="right">
                                  <Stack
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <ButtonIconControla
                                      icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                      backgroundColor={"#2D9CDB"}
                                      backgroundColorHover={"#33AEF4"}
                                      textTooltip={"Editar"}
                                      functionOnClick={() => handleOpenModalTypeMild("update", "updateTypeMild", row)}
                                    />

                                    <ButtonIconControla
                                      icon={<Delete sx={{ color: "white" }} />}
                                      backgroundColor={"#EB5757"}
                                      backgroundColorHover={"#FF4040"}
                                      textTooltip={"Eliminar"}
                                      functionOnClick={() => handleOpenModalConfirm(
                                        row.id_accident_type_minor,
                                        "Estás seguro de eliminar los datos del accidente leve?",
                                        "Una vez eliminado no se podrá recuperar la información.",
                                        "Si, eliminar",
                                        "deleteTypeMildAccident"
                                      )}
                                    />
                                  </Stack>
                                </TableCell>
                              )
                            }
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              {/* tipo incapacitante */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: 3, marginBottom: 1 }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        padding: "8px",
                        fontSize: "18px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      Gravedad del accidente del trabajo: Incapacitante
                    </InputLabel>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ color: "#F2994A", ml: 1 }}
                    >
                      Este campo es opcional de acuerdo al accidente
                    </Typography>
                  </Box>
                  <Box>
                    {
                      registerStatus && (
                        <ButtonControla
                          iconButton={<Add sx={{ color: "white" }} />}
                          backgroundColor={"#169073"}
                          backgroundColorHover={"#1BAA88"}
                          textButton={"Agregar datos"}
                          functionOnClick={() => handleOpenModalTypeDisabling("create", "createTypeDisabling", null)}
                        />
                      )
                    }
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 600 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {['N° trabajador afectado', 'Gravedad del accidente incapacitante', 'N° días de descanso médico', 'Partes del cuerpo lesionado', (
                          registerStatus && (
                            <TableCell
                              sx={{
                                color: "#305ad9",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            ></TableCell>
                          ))].map(col => (
                            <TableCell
                              sx={{
                                color: "#305ad9",
                                fontSize: "16px",
                                fontWeight: "bold",
                                textAlign: "center"
                              }}
                            >{col}</TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        rowsTypeDisablingAccident.map((row) => (
                          <TableRow
                            hover
                            key={row.id_accident_type_disabling}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row" align="center">
                              {row.affected_workers}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                              {rowsSeverityIncidents.filter((d) => d.id_registers_accidents_severities_incident === row.id_registers_accidents_severities_incident)[0]?.register_accident_severity_incident}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                              {row.medical_rest_days}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <ul>
                                {row.body_parts.map((p) => (
                                  <li key={p.body_part.id_body_parts}>{p.body_part.name}</li>
                                ))}
                              </ul>
                            </TableCell>
                            {
                              registerStatus && (
                                <TableCell align="right">
                                  <Stack
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <ButtonIconControla
                                      icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                      backgroundColor={"#2D9CDB"}
                                      backgroundColorHover={"#33AEF4"}
                                      textTooltip={"Editar"}
                                      functionOnClick={() => handleOpenModalTypeDisabling(
                                        "update",
                                        "updateTypeDisabling",
                                        row
                                      )}
                                    />
                                    <ButtonIconControla
                                      icon={<Delete sx={{ color: "white" }} />}
                                      backgroundColor={"#EB5757"}
                                      backgroundColorHover={"#FF4040"}
                                      textTooltip={"Eliminar"}
                                      functionOnClick={() => handleOpenModalConfirm(
                                        row.id_accident_type_disabling,
                                        "¿Estás seguro de eliminar los datos del accidente incapacitante?",
                                        "Una vez eliminado no se podrá recuperar la información.",
                                        "Si, eliminar",
                                        "deleteTypeDisablingAccident"
                                      )}
                                    />

                                  </Stack>
                                </TableCell>
                              )
                            }
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              {/* //! tipo mortal */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: 3, marginBottom: 1 }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        padding: "8px",
                        fontSize: "18px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      Gravedad del accidente del trabajo: Mortal
                    </InputLabel>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ color: "#F2994A", ml: 1 }}
                    >
                      Este campo es opcional de acuerdo al accidente
                    </Typography>
                  </Box>
                  <Box>
                    {
                      registerStatus && (
                        <ButtonControla
                          iconButton={<Add sx={{ color: "white" }} />}
                          backgroundColor={"#169073"}
                          backgroundColorHover={"#1BAA88"}
                          textButton={"Agregar datos"}
                          functionOnClick={() => handleOpenModalTypeMortal("create", "createTypeMortal", null)}
                        />
                      )
                    }
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 600 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {['N° trabajador afectado', 'N° días de descanso médico', 'Partes del cuerpo lesionado', <>{
                          registerStatus && (
                            <TableCell
                              sx={{
                                color: "#305ad9",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            ></TableCell>
                          )}</>].map(col => (
                            <TableCell
                              sx={{
                                color: "#305ad9",
                                fontSize: "16px",
                                fontWeight: "bold",
                                textAlign: "center"
                              }}
                            >{col}</TableCell>
                          ))}
                      </TableRow>

                    </TableHead>
                    <TableBody>
                      {
                        rowsTypeMortalAccident.map((row) => (
                          <TableRow
                            hover
                            key={row.id_accident_type_mortal}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row" align="center">
                              {row.affected_workers}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                              {row.medical_rest_days}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <ul>
                                {row.body_parts.map((p) => (
                                  <li key={p.body_part.id_body_parts}>{p.body_part.name}</li>
                                ))}
                              </ul>
                            </TableCell>
                            {
                              registerStatus && (
                                <TableCell align="right">
                                  <Stack
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <ButtonIconControla
                                      icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                      backgroundColor={"#2D9CDB"}
                                      backgroundColorHover={"#33AEF4"}
                                      textTooltip={"Editar"}
                                      functionOnClick={() => handleOpenModalTypeMortal("update", "updateTypeMortal", row)}
                                    />

                                    <ButtonIconControla
                                      icon={<Delete sx={{ color: "white" }} />}
                                      backgroundColor={"#EB5757"}
                                      backgroundColorHover={"#FF4040"}
                                      textTooltip={"Eliminar"}
                                      functionOnClick={() => handleOpenModalConfirm(
                                        row.id_accident_type_mortal,
                                        "¿Estás seguro de eliminar los datos del accidente mortal?",
                                        "Una vez eliminado no se podrá recuperar la información.",
                                        "Si, eliminar",
                                        "deleteTypeMortalAccident"
                                      )}
                                    />
                                  </Stack>
                                </TableCell>
                              )}
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_AT_EDITAR}
                  text={"Descripción del accidente de trabajo"}
                  inputType={"textArea"}
                  inputValue={setDescriptionWork}
                  value={descriptionWork}
                />
              </Grid>
            </Grid>

            {/* adjuntar */}
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText text={"Adjuntar"} color={blue} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10} md={10}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_AT_EDITAR}
                  text={
                    "Declaración del afectado sobre el accidente de trabajo"
                  }
                  inputType={"file"}
                  inputValue={setDocumentUrl1}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <ButtonIconControla
                  roles={[1, 3, 4, 7, 8, 12, 13]}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl1}
                  directionTarget={"_blank"}
                />
              </Grid>
              <Grid item xs={10} md={10}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_AT_EDITAR}
                  text={"Declaración de testigos(de ser el caso)"}
                  inputType={"file"}
                  inputValue={setDocumentUrl2}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <ButtonIconControla
                  roles={[1, 3, 4, 7, 8, 12, 13]}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl2}
                  directionTarget={"_blank"}
                />
              </Grid>
              <Grid item xs={10} md={10}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_AT_EDITAR}
                  text={
                    "Procedimientos, planos, registros, entre otros que ayuden a la investigación de ser el caso"
                  }
                  inputType={"file"}
                  inputValue={setDocumentUrl3}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <ButtonIconControla
                  roles={[1, 3, 4, 7, 8, 12, 13]}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl3}
                  directionTarget={"_blank"}
                />
              </Grid>
            </Grid>

            {/* descripcion de las causas del accidente de trabajo */}
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Descripcion de las causas del accidente de trabajo"}
                  color={blue}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <InputControlaVertical
                roles={permissions.DOC_REGIS_AT_EDITAR}
                inputType={"textArea"}
                inputValue={setcausesDescription}
                value={causesDescription}
                required
              />
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{ color: "#F2994A", ml: 1 }}
              >
                Este campo es obligatorio
              </Typography>
            </Grid>

            {/********************************  medidas correctivas  ********************************************** */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Medidas correctivas
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus && (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AT_AGREGAR_MEDIDAS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar medidas correctivas"}
                    functionOnClick={handleOpenModalUploadCorrectiveActions}
                  />
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {['Descripción de la medida correctiva', 'Responsable de la implementación', 'Estado', 'Fecha de inicio', 'Fecha de fin'].map(col => (
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {col}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {correctivesActions.map((rows) => (
                      <TableRow
                        hover
                        key={rows.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rows.description}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.first_name + " " + rows.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.id_registers_accidents_measures_statuses === 1
                            ? "En proceso"
                            : rows.id_registers_accidents_measures_statuses ===
                              2
                              ? "Finalizada"
                              : rows.id_registers_accidents_measures_statuses ===
                                3
                                ? "Vencida"
                                : "Cancelada"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.timestamp_start.substring(0, 10)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.timestamp_end === null
                            ? "No definido"
                            : rows.timestamp_end.substring(0, 10)}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus && (
                              <ButtonIconControla
                                roles={[1, 3, 4, 7, 8]}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    rows.id_registers_accidents_measures,
                                    "¿Esta seguro de eliminar los datos de la medida correctiva?",
                                    "Una vez eliminado no se podra recuperar los datos de la medida correctiva",
                                    "Si, Eliminar",
                                    "deleteMeasures"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro y de la investigación
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus && (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AT_AGREGAR_RESPONS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={
                      handleOpenModalUploadResponsibleRegistration
                    }
                  />
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {['Nombre y Apellido', 'Documento de identidad', 'Fecha de firma', 'Firma'].map(col => (
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                            textAlign: "center"
                          }}
                        >
                          {col}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.firm_date === null
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <ButtonSignatureControla
                            idUser={row.id_users}
                            firmStatus={row.firm}
                            firmUrl={row.firm_url}
                            functionOnClick={async () => {
                              await handleSignResponsible(row.id_registers_accidents_responsible)
                            }}
                          />

                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus && (
                              <ButtonIconControla
                                roles={[1, 3, 4, 7, 8]}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id_registers_accidents_responsible,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador responsable",
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={"/documentation/registers/generals/accidents"}
                />
              </Grid>
              {registerStatus && (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AT_ACTUALIZAR_REGIS}
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton={"submit"}
                  />
                </Grid>
              )}

              {registerStatus == false && (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AT_CERRAR_REGIS}
                    iconButton={<PictureAsPdf fontSize="inherit" />}
                    backgroundColor={"#F2994A"}
                    backgroundColorHover={"#FF881E"}
                    textButton={"Ver Documento PDF"}
                    functionOnClick={handleGeneratePDF}
                  />
                </Grid>
              )}

              {registerStatus && (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AT_CERRAR_REGIS}
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    functionOnClick={handleGetRegisterStatus}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Modal para agregar trabajador involucrado */}
      <ModalAddWorker
        openModalUploadAddDates={openModalUploadAddDates}
        handleCloseModalUploadAddDates={handleCloseModalUploadAddDates}
        checked={checked}
        handleStoreAccidentsWorkers={handleStoreAccidentsWorkers}
        setWorkExperience={setWorkExperience}
        workExperience={workExperience}
        setArea={setArea}
        area={area}
        rowsAreaWorkerInvolved={rowsAreaWorkerInvolved}
        shift={shift}
        handleChangeShift={handleChangeShift}
        setWorkHoursBefore={setWorkHoursBefore}
        workHoursBefore={workHoursBefore}
        responsibleCorrectiveActions={responsibleCorrectiveActions}
        handleOpenModalResponsibleInvolvedWorkers={handleOpenModalResponsibleInvolvedWorkers}
        selectedIds={selectedContractorsIds}
        callbackAlert={handleClickAlert}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
        handleCloseBackdrop={handleCloseBackdrop}
        id_employee={idResponsible}
        id_register_accident={idRegistersAccidents}
        fetchAccidentedWorkers={checked ? handleGetContractorsEmployeesByIdRegister : handleGetAccidentsWorkers}
      />

      {/* Dialog agregar medidas correctivas */}
      <Dialog
        open={openModalUploadCorrectiveActions}
        onClose={handleCloseModalUploadCorrectiveActions}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar medidas correctivas
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreCorrectivesActions}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inicio"}
                  inputType={"date"}
                  inputValue={setTimestampStart}
                  value={timestampStart}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setTimestampEnd}
                  value={timestampEnd}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Estado de medida correctiva:"
                  inputValue={setStatusCorrectivesActions}
                  modalType={true}
                  required
                  value={statusCorrectivesActions}
                  childrenRows={rowsStatusCorrectivesActions.map((rows) => (
                    <MenuItem
                      key={rows.id_registers_accidents_measures_statuses}
                      value={rows.id_registers_accidents_measures_statuses}
                    >
                      {rows.statuses}
                    </MenuItem>
                  ))}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable de la implementación: "
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalResponsible}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadCorrectiveActions}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog de agregar repsonsables  del registro  y de la investigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          severity: severityAlert,
          zIndex: (theme) => theme.zIndex.drawer + 0,
        }}
        open={openDelete}
        onClose={handleCloseDelete}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          sx={{
            color: "#1638F2",
            fontWeight: "bold",
          }}
        >
          Eliminar datos del trabajador accidentado
        </DialogTitle>
        <DialogContent>
          <Typography>
            Los datos del trabajador accidentado se eliminará de forma
            permanente
          </Typography>
          <Typography>¿Deseas continuar?.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancelar</Button>
          <Button onClick={handleDeleteAccidentsWorkers}>Si, eliminar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar usuarios de medidas correctivas */}
      <Dialog
        open={openModalUpload}
        onClose={() => {
          setOpenModalUpload(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsSearchsCorrectivesActionWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUpload(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables */}
      <Dialog
        open={handleOpenModalResponsableWorker}
        onClose={() => {
          sethandleOpenModalResponsableWorker(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={checked ? contractorsEmployees : rowsUsersSearchsAddsWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              sethandleOpenModalResponsableWorker(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* modal users information */}
      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          severity: severityAlert,
          zIndex: (theme) => theme.zIndex.drawer + 0,
        }}
        open={openDelete}
        onClose={handleCloseDelete}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          sx={{
            color: "#1638F2",
            fontWeight: "bold",
          }}
        >
          Eliminar datos del trabajador accidentado
        </DialogTitle>
        <DialogContent>
          <Typography>
            Los datos del trabajador accidentado se eliminará de forma
            permanente
          </Typography>
          <Typography>¿Deseas continuar?.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancelar</Button>
          <Button onClick={handleDeleteAccidentsWorkers}>Si, eliminar</Button>
        </DialogActions>
      </Dialog>

      {/* users information */}
      <Dialog
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        open={openModalDatesUsers}
        onClose={() => setOpenModalDatesUers(false)}
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent>
          <CardUserControlaAccident
            users_json={userJsonDatesUsers}
            document={documentDatesUsers}
            email={emailDatesUsers}
            phone={phoneDatesUsers}
            sex={sexDatesUsers}
            age={ageDatesUsers}
            current_time={currentTimeDatesUsers}
            campus={campusDatesUsers}
            job_type={jobTypeDatesUsers}
          />{" "}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalDatesUers(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      {/* dialogo de por  gravedad leve */}
      <Dialog
        open={openModalTypeMild}
        onClose={handleCloseModalTypeMild}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitleControla
          titleText={typeModal == "update" ? "Actualizar datos del accidente leve" : "Agregar datos del accidente leve"}
          functionOnClose={handleCloseModalTypeMild}
        />
        <form onSubmit={handleActionButtonModal}>
          <DialogContent>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de trabajador afectado"}
                  inputType={"number"}
                  inputValue={setNumberAffected}
                  value={numberAffected}
                  modalType={true}
                  readOnly
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de días de descanso médico"}
                  inputType={"number"}
                  inputValue={setNumberMedicalRest}
                  value={numberMedicalRest}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"flex-start"}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SelectControla
                    text="Seleccione partes del cuerpo lesionado:"
                    inputValue={(e) => handleOnChangeBodyPart(e)}
                    modalType={true}
                    value={bodyPartSelected}
                    childrenRows={bodyParts.map((row) => (
                      <MenuItem
                        key={row}
                        value={row}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      listStyle: "none",
                      gap: "5px",
                      paddingTop: "10px"
                    }}
                  >
                    {
                      bodyPartsLists.map((e) => {
                        return (
                          <li key={e.id_body_parts}>
                            <Chip sx={{ color: "#305AD9" }}
                              label={e.name}
                              onDelete={() => { handleDeleteBodyParts(e) }}
                            />
                          </li>
                        );
                      })}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <ButtonControla
                textButton={"Cancelar"}
                backgroundColor={"#CBCBFF"}
                functionOnClick={handleCloseModalTypeMild}
              />
              <ButtonControla
                textButton={typeModal === "create" ? "Crear" : "Actualizar"}
                backgroundColor={"#389532"}
                typeButton={"submit"}
              />
            </Stack>
          </DialogActions>
        </form>
      </Dialog>

      {/* dialogo de por  gravedad incapacitante */}
      <Dialog
        open={openModalTypeDisabling}
        onClose={handleCloseModalTypeDisabling}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitleControla
          titleText={typeModal ==="create" ? "Agregar datos del accidente incapacitante" : "Actualizar datos del accidente incapacitante"}
          functionOnClose={handleCloseModalTypeDisabling}
        />
        <form onSubmit={handleActionButtonModal}>
          <DialogContent>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° trabajador afectado"}
                  inputType={"number"}
                  inputValue={setNumberAffected}
                  value={numberAffected}
                  modalType={true}
                  readOnly
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <SelectControla
                  text="Gravedad del accidente incapacitante:"
                  inputValue={setSeverityIncidents}
                  modalType={true}
                  value={severityIncidents}
                  childrenRows={rowsSeverityIncidents.map((row) => (
                    <MenuItem
                      key={row.id_registers_accidents_severities_incident}
                      value={row.id_registers_accidents_severities_incident}
                    >
                      {row.register_accident_severity_incident}
                    </MenuItem>
                  ))}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de días de descanso médico"}
                  inputType={"number"}
                  inputValue={setNumberMedicalRest}
                  value={numberMedicalRest}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"flex-start"}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SelectControla
                    text="Seleccione partes del cuerpo lesionado:"
                    inputValue={(e) => handleOnChangeBodyPart(e)}
                    modalType={true}
                    value={bodyPartSelected}
                    childrenRows={bodyParts.map((row) => (
                      <MenuItem
                        key={row}
                        value={row}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      listStyle: "none",
                      gap: "5px",
                      paddingTop: "10px"
                    }}
                  >
                    {
                      bodyPartsLists.map((e) => {
                        return (
                          <li key={e.id_body_parts}>
                            <Chip sx={{ color: "#305AD9" }}
                              label={e.name}
                              onDelete={() => { handleDeleteBodyParts(e) }}
                            />
                          </li>
                        );
                      })}
                  </Box>
                </Grid>

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <ButtonControla
                textButton={"Cancelar"}
                backgroundColor={"#CBCBFF"}
                functionOnClick={handleCloseModalTypeDisabling}
              />
              <ButtonControla
                textButton={typeModal === "create" ? "Crear" : "Actualizar"}
                backgroundColor={"#389532"}
                typeButton={"submit"}
              />
            </Stack>
          </DialogActions>
        </form>
      </Dialog>

      {/* dialogo de por  gravedad mortal */}
      <Dialog
        open={openModalTypeMortal}
        onClose={handleCloseModalTypeMortal}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitleControla
          titleText={typeModal == "create" ? "Agregar datos del accidente mortal" : "Actualizar datos del accidente mortal"}
          functionOnClose={handleCloseModalTypeMortal}
        />
        <form onSubmit={handleActionButtonModal}>
          <DialogContent>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de trabajador afectado"}
                  inputType={"number"}
                  inputValue={setNumberAffected}
                  value={numberAffected}
                  modalType={true}
                  readOnly
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de días de descanso médico"}
                  inputType={"number"}
                  inputValue={setNumberMedicalRest}
                  value={numberMedicalRest}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"flex-start"}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SelectControla
                    text="Seleccione partes del cuerpo lesionado:"
                    inputValue={(e) => handleOnChangeBodyPart(e)}
                    modalType={true}
                    value={bodyPartSelected}
                    childrenRows={bodyParts.map((row) => (
                      <MenuItem
                        key={row}
                        value={row}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      listStyle: "none",
                      gap: "5px",
                      paddingTop: "10px"
                    }}
                  >
                    {
                      bodyPartsLists.map((e) => {
                        return (
                          <li key={e.id_body_parts}>
                            <Chip sx={{ color: "#305AD9" }}
                              label={e.name}
                              onDelete={() => { handleDeleteBodyParts(e) }}
                            />
                          </li>
                        );
                      })}
                  </Box>
                </Grid>

              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <ButtonControla
                textButton={"Cancelar"}
                backgroundColor={"#CBCBFF"}
                functionOnClick={handleCloseModalTypeMortal}
              />
              <ButtonControla
                textButton={typeModal == "create" ? "Crear" : "Actualizar"}
                backgroundColor={"#389532"}
                typeButton={"submit"}
              />
            </Stack>
          </DialogActions>
        </form>
      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 99,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Edit;
