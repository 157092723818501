import SideNav from "./sideNav";
import React from 'react';
import { Outlet } from 'react-router-dom';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";


const LayoutSidenav = () => {
  return (
    <Box sx={{ display: 'flex'}}>
      <SideNav />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Container maxWidth="xl">
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};
export default LayoutSidenav;
