import {React, useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { 
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    Stack,
 } from '@mui/material';
 import {
    Add
} from "@mui/icons-material";

import BannerControla from '../../../components/cards/bannerControla';
import ButtonControla from "../../../components/buttons/buttonController";
import MyMeeting from "../../../api/MyMeetings";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import DialogConfirmControla from "../../../components/dialog/dialogConfirmControla";
import MyProceedingsBook from "../../../api/MyProceedingsBook";

import { useAppContext } from "../../../context/AppContext";
import ListActives from "./components/list-actives";
import ListArchived from "./components/list-archived";
import TableControla from "../../../components/tables/table-controla";
import LoadingControla from "../../../components/load/loadingControla";
import ButtonMailboxControla from "../../../components/buttons/buttonMailboxControla";
import ReturnBack from "../../../components/buttons/returnBackControla";

const purple = "#8B8BD8";

function AddProceedingsBook() {
    const { permissions } = useAppContext();

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    //fin de alerts

    const params = new URLSearchParams(window.location.search);
    const id_meeting = params.get("id_meeting");


    const handleMeetingArchived = async () => {
        const data = {
            id_status_meeting: 3,
        }
        await MyMeeting.updateMeeting(id_meeting, data);
    }

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");
    const [idBook, setIdBoook] = useState("");

    const handleOpenModal = (titleText, contentText, buttonTextConfirm, buttonActionType, idBook, titleBook, openModal, openModalConfirm) => {
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModal(openModal);
        setOpenModalConfirm(openModalConfirm);
        setIdBoook(idBook);
        setTitleBook(titleBook);
    }
    const handleCloseModal = () => {
        setTitleBook("");
        setOpenModal(false);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionModal = (event) => {
        switch (buttonActionType) {
            case "crear":
                handleCreateBook(event);
                break;
            case "editar":
                editBook(event);
                break;
            case "cerrar":
                closeBook();
                break;
        }
    }

    const [titleBook, setTitleBook] = useState("");

    const handleCreateBook = async (event) => {
        event.preventDefault();

        handleOpenLoading();
        handleCloseModal();

        const data = {
            title: titleBook
        }
        const response = await MyProceedingsBook.createProceedingsBook(data);

        if (response.success === true) {
            listBook();
            handleOpenAlert("Libro creado con éxito", "success");

        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }

        setTitleBook("");
    }

    const editBook = async (event) => {
        event.preventDefault();

        handleOpenLoading();
        handleCloseModal();

        const dataBook = {
            title: titleBook
        }
        const response = await MyProceedingsBook.updateProceedingsBook(idBook, dataBook);
        if (response.success === true) {
            handleOpenAlert("Título del libro modificado con éxito", "success");
            listBook();
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const closeBook = async () => {
        handleOpenLoading();
        handleCloseModalConfirm();

        const date = new Date();
        const currentYear = date.getFullYear();
        const currentMonth = date.getMonth() + 1;
        const today = date.getDate();

        const statusBook = {
            active: 0,
            end_date: `${currentYear}-${currentMonth}-${today}`,
        }
        const response = await MyProceedingsBook.updateProceedingsBook(idBook, statusBook);

        if (response.success === true) {
            listBook();
            handleOpenAlert("Se cerró el libro con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }

       
    }

    const [statusBook, setStatusBook] = useState(0);
    const [activeBook, setActiveBook] = useState([]);
    const [archivedBooks, setArchivedBooks] = useState([]);

    const listBook = async () => {
        handleOpenLoading();

        const response = await MyProceedingsBook.listProceedingsBook();
        setActiveBook(response.data.filter((b) => b.active === 1))
        setArchivedBooks(response.data.filter((b) => b.active === 0));

        if (response.success == true) {
            setOpenLoading(false);

        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleChangeStatusBook = (event, newValue) => {
        setStatusBook(newValue)
    }

    useEffect(() => {
        handleMeetingArchived();
    }, []);

    useEffect(() => {
        listBook();
    }, []);


    return (
        <Box>
            <ReturnBack/>

            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionModal}
            />

            <Container>
                <Box sx={{ flexGrow: 1 }}>

                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} >
                            <ButtonMailboxControla
                                originUrl={"/committee/proceedingsBook/addProceedingsBook"}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                            <BannerControla
                                image="/assets/images/banners/committee/bannerMeeting.png"
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Libro de Actas"
                            />
                        </Grid>
                    </Grid>
                    {
                        activeBook.length !== 0 ? "" : (
                            <Grid container
                                spacing={2}
                                sx={{
                                    mt: 2,
                                }}
                                justifyContent="space-between"
                                direction="row"
                                alignItems="center">
                                <Grid item>
                                    <ButtonControla
                                        roles={permissions.COMITE_LIBRO_ACTA_CREAR}
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar nuevo libro"}
                                        functionOnClick={() => handleOpenModal(
                                            "Agregar nuevo Libro de Actas",
                                            "",
                                            "Agregar Libro",
                                            "crear",
                                            "",
                                            "",
                                            true,
                                            false
                                        )}
                                    />
                                </Grid>
                            </Grid>

                        )
                    }

                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableControla
                                loading={openLoading}
                                tabs={[
                                    {
                                        label: "Activos",
                                        columnNames: ["Título", "Fecha de Creación"]
                                    },
                                    {
                                        label: "Archivados",
                                        columnNames: ["Título", "Fecha de Creación", "Fecha de Cierre"]
                                    }
                                ]}
                            >
                                <ListActives
                                    activeBook={activeBook}
                                    colorCell={purple}
                                    handleOpenModal={handleOpenModal}
                                />

                                <ListArchived
                                    archivedBooks={archivedBooks}
                                    colorCell={purple}
                                />

                            </TableControla>
                        </Grid>
                    </Grid>

                </Box>
            </Container>

            {/* modal de crear libro y editar */}
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitleControla
                    titleText={titleText}
                    functionOnClose={handleCloseModal}
                />
                <form onSubmit={handleActionModal}>
                    <DialogContent>

                        <InputControlaVertical
                            text={"Título"}
                            inputType={"text"}
                            inputValue={setTitleBook}
                            modalType={true}
                            value={titleBook || ''}
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={() => handleCloseModal()}
                                />
                            </Box>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={buttonTextConfirm}
                                    typeButton={"submit"}
                                />
                            </Box>
                        </Stack>

                    </DialogActions>

                </form>

            </Dialog>

        </Box>


    );

}

export default AddProceedingsBook;