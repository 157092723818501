import MyConfigurationApi from "./MyConfigurationApi";

function createMeeting (dataMeeting) {
    //url de la api(endpoint)
    const url = "committee/meetings/store";
    const data = {
        code : null,
        description: dataMeeting.description,
        location: dataMeeting.place,
        url_session: null,
        date: dataMeeting.date,
        start_time: dataMeeting.hourStart,
        end_time: dataMeeting.hourEnd,
        session: dataMeeting.session,
        modality: dataMeeting.modality,
        number_of_participants: null,
        quorum: null,
        status: 1,
        id_users_created: dataMeeting.userCreated,
        id_users_modified: dataMeeting.userCreated
    }

    //llamar al metodo post
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

function getMeeting(){
    const url = "committee/meetings/get";

    return MyConfigurationApi.connectApi({
        dataJson:null,
        method:"GET",
        url: url
    })
}

function deleteMeeting (id_meeting) {
    const url = "committee/meetings/remove/"+ id_meeting ;

    return MyConfigurationApi.connectApi({
        dataJson:null,
        method: "DELETE",
        url: url
    })
}

function searchMeeting(id_meeting){
    const url = "committee/meetings/get/" + id_meeting;

    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url
    })
}

function updateMeeting (id_meeting, data_meeting){
    const url = "committee/meetings/update/" + id_meeting;
    return MyConfigurationApi.connectApi({
        dataJson: data_meeting,
        method: "PUT",
        url: url
    })
}
function updateStatus (id_meeting, data_meeting){
    const url = "committee/meetings/updateStatus/" + id_meeting;
    return MyConfigurationApi.connectApi({
        dataJson: data_meeting,
        method: "PUT",
        url: url
    })
}

function createGuest (data_guest) {
    const url = "committee/meetings/guest/store";
    return MyConfigurationApi.connectApi({
        dataJson: data_guest,
        method: "POST",
        url: url
    })
}

function getGuestByMeeting(id_meeting){
    const url = "committee/meetings/guest/get/meeting/"+ id_meeting;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url
    })
}

function deleteGuest(id_guest){
    const url = "committee/meetings/guest/remove/" + id_guest;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url
    })
}
function postParcitipants(data){
    const url = "committee/meetings/participant/store";
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: "POST",
        url: url
    })
}

function postParcitipantsSmallCompany(data_roles){
    const url = "committee/meetings/participant/small-company/store";
    return MyConfigurationApi.connectApi({
        dataJson: data_roles,
        method: "POST",
        url: url
    })
}

function getParticipantsByMeeting(id_meeting){
    const url ="committee/meetings/participant/get/meeting/" + id_meeting;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url
    })
}

function getParticipantsByMeetingSmallCompany(id_meeting){
    const url ="committee/meetings/participant/small-company/get/meeting/" + id_meeting;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url
    })
}

function getThemes(){
    const url= "committee/themes";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url
    })
}

function getThemesSmallCompany(){
    const url= "committee/small-company/themes";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url
    })
}


function getThemesByMeetingId(id_meeting){
    const url = "committee/meetings/themes/get/meeting/" + id_meeting;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url
    })
}

function postThemeByMeeting(data_theme){
    const url = "committee/meetings/themes/store";
    return MyConfigurationApi.connectApi({
        dataJson: data_theme,
        method: "POST",
        url: url
    })
}
function deleteThemeByMeeting(id_theme_meeting){
    const url = "committee/meetings/themes/remove/" + id_theme_meeting;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url
    })
}

function updateThemeByMeeting(id_theme_meeting, data_theme){
    const url = "committee/meetings/themes/update/" + id_theme_meeting;
    return MyConfigurationApi.connectApi({
        dataJson: data_theme,
        method: "PUT",
        url: url
    })
}

const MyMeeting = {
    createMeeting,
    updateMeeting,
    updateStatus, 
    getMeeting, 
    deleteMeeting, 
    searchMeeting,
    createGuest, 
    getGuestByMeeting, 
    deleteGuest,
    postParcitipants,
    postParcitipantsSmallCompany,
    getParticipantsByMeeting,
    getParticipantsByMeetingSmallCompany,
    getThemes,
    getThemesSmallCompany,
    getThemesByMeetingId,
    postThemeByMeeting,
    deleteThemeByMeeting,
    updateThemeByMeeting
}

export default MyMeeting;