import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
    Container,
    Grid,
    MenuItem,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import {
   
  } from "@mui/icons-material";
import SubtitleText from "../../components/text/subtitleText";
import TitleText from "../../components/text/titleText";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import SelectControla from "../../components/selects/selectControla";
import { styled } from "@mui/system";
import MyMeeting from "../../api/MyMeetings";
import { useAppContext } from "../../context/AppContext";
import LoadingControla from "../../components/load/loadingControla";
import ReturnBack from "../../components/buttons/returnBackControla";


//colores
const grey = "#9191B0";
const blue = "#034AFF";

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em",
  });

function  ViewScheduleMeeting (){
    const { permissions } = useAppContext();
    //obtener el id de la reunión por el enlace 
    const params = new URLSearchParams(window.location.search);
    const id_meeting = params.get("id_meeting");

    // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts


    const searchMeeting = async (id_meeting) =>{
        handleOpenLoading();
      const response = await MyMeeting.searchMeeting(id_meeting);
    
      setDateMeeting(response.data.date);
      setDescriptionMeeting(response.data.description);
      setHourStart(response.data.start_time.substr(0,5));
      setHourEnd(response.data.end_time.substr(0,5));
      setSession(response.data.session);
      setModality(response.data.modality);
      setPlace(response.data.location);

      if(modality != ""){
        setOpenLoading(false);
      }

    }

    //array que muestra en el select 
    const typeSession = [{ id_session:1,nombre:"ordinario"},{ id_session:2, nombre:"extraordinario"}];
    const typeModality = [{id_modality: 1, nombre: "presencial"}, {id_modality: 2, nombre: "remoto"}];

    //datos para la agenda de la reunión
    const [dateMeeting, setDateMeeting] =  useState("");
    const [descriptionMeeting, setDescriptionMeeting] = useState("");
    const [hourStart, setHourStart] = useState("");
    const [hourEnd, setHourEnd] = useState("");
    const [session, setSession] = useState("");
    const [modality, setModality] = useState("");
    const [place, setPlace] = useState("");

    //array de usuarios del sistema
    // const [users, setUsers]= useState([]);

    const [participants, setParticipants] = useState([]);

    const handleGetParticipants = async () => {
        const resp = await MyMeeting.getParticipantsByMeeting(id_meeting);
        setParticipants(resp.data);
    }
    //array de invitados(externos)
    const [arrayGuest, setArrayGuest] = useState([]);
    const getGuestByMeeting = async ()=>{
        const response = await MyMeeting.getGuestByMeeting(id_meeting);
        setArrayGuest(response.data);
    }

    // get temas de la reunión
    const [listThemesByMeeting, setListThemesByMeeting] = useState([]);

    const getListThemesByMeeting = async() => {
        const response  = await MyMeeting.getThemesByMeetingId(id_meeting);
        
        if(response.success === true){
            setListThemesByMeeting(response.data);
        }

    }

    useEffect(()=>{
        searchMeeting(id_meeting);
        getGuestByMeeting();
        getListThemesByMeeting();
        handleGetParticipants();
    },[modality]);
    

    return(
 
        <Box sx={{ height: "100%" }}>
            <ReturnBack/>

            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <Container  sx={{ paddingBottom: 4 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    alignItems="center"
                    >
                        <Grid item md={12}>
                            <SubtitleText
                            text={"Reuniones"}
                             color={grey}
                            ></SubtitleText>
                        </Grid>
                        <Grid item md={10}>
                            <TitleText
                                text={"Detalles de la reunión programada"}
                            />
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                            <CompanyLogoControla />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <InputControlaVertical
                                text={"Fecha de la reunión"}
                                inputType={"date"}
                                inputValue={setDateMeeting}
                                modalType={false}
                                value={dateMeeting}
                                readOnly={true}
                            />
                        </Grid>
                        <Grid item xs={6}  md={8} >
                            <InputControlaVertical
                                text={"Descripción"}
                                inputType={"text"}
                                inputValue={setDescriptionMeeting}
                                modalType={false}
                                value={descriptionMeeting}
                                readOnly={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <InputControlaVertical
                                text={"Hora de Inicio"}
                                inputType={"time"}
                                inputValue={setHourStart}
                                modalType={false}
                                value={hourStart}
                                readOnly={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                            <InputControlaVertical
                                roles={[1, 7, 8, 9, 15]}
                                text={"Hora de Fin"}
                                inputType={"time"}
                                inputValue={setHourEnd}
                                modalType={false}
                                value={hourEnd}
                                readOnly={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SelectControla
                                text="Sesión:"
                                inputValue={setSession}
                                modalType={true}
                                required
                                value={session}
                                readOnly={true}
                                childrenRows={typeSession.map((rows) => (
                                    <MenuItem key={rows.id_session} value={rows.id_session}>
                                        {rows.nombre}
                                    </MenuItem>
                                ))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SelectControla
                                text="Modalidad:"
                                inputValue={setModality}
                                modalType={true}
                                required
                                value={modality}
                                readOnly={true}
                                childrenRows={typeModality.map((rows) => (
                                    <MenuItem key={rows.id_modality} value={rows.id_modality}>
                                        {rows.nombre}
                                    </MenuItem>
                                ))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <InputControlaVertical
                                //verificar que roles
                                display={modality === "1" ? 'block' : 'none'}
                                roles={[1, 7, 8, 9, 15]}
                                text={"Lugar"}
                                inputType={"text"}
                                inputValue={setPlace}
                                modalType={false}
                                value={place}
                                readOnly={true}
                            />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <StyledTypography
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Invitados de la Reunión
                            </StyledTypography>
                        </Grid> 
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Nombre
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Email
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {arrayGuest.map((guest)=>(
                                            <TableRow
                                                hover
                                                key={guest.id_committee_guest}
                                            >
                                                <TableCell align="center">
                                                    {guest.name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {guest.email}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <StyledTypography
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Participantes de la Reunión
                            </StyledTypography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Nombre
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Email
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {participants.map((participant)=>(
                                            <TableRow
                                                hover
                                                key={participant.id_users}
                                            >
                                                <TableCell align="center">
                                                    {participant.first_name + " " +participant.last_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {participant.email}
                                                </TableCell>
                                            </TableRow>
                                         ))} 
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <StyledTypography
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                              Temas a tratar
                            </StyledTypography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Título
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listThemesByMeeting.map((theme,index)=>(
                                            <TableRow
                                                hover
                                                key={index}
                                            >
                                                <TableCell align="center">
                                                    {theme.name}
                                                </TableCell>
                                            </TableRow>
                                         ))} 
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
            
                        
                    </Grid> 
                </Box>
            </Container>

        </Box>
        
    );
}

export default ViewScheduleMeeting;
