import { Box, Button, Container, Grid, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppContext } from '../../context/AppContext';
import ButtonControla from '../../components/buttons/buttonController';
import { Add, Delete, Edit } from '@mui/icons-material';
import ReturnBack from '../../components/buttons/returnBackControla';
import LoadingControla from '../../components/load/loadingControla';
import MyContractorsEmployees from '../../api/contractors/MyContractorsEmployees';
import ContractorEmployeeModal from './components/modalContractorEmployee';
import MyContractors from '../../api/MyContractors';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import DialogConfirmControla from '../../components/dialog/dialogConfirmControla';

// Edit contractors
export default function EditContractors(props) {

    const { permissions } = useAppContext();

    const params = new URLSearchParams(window.location.search);
    const id_contractors = params.has("id_contractor") ? params.get("id_contractor")
        : window.location.href = "/documentation/contractors";
    const readOnly = params.get("readOnly");


    // Todo: ----------------------------------- Alerts and Loading -----------------------------------
    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    // Todo: ----------------------------------- Employees section -----------------------------------
    const initialFormData = {
        id_contractor: '',
        nombres: '',
        apellido_paterno: '',
        apellido_materno: '',
        n_documento: '',
        fecha_nacimiento: '',
        created_user: '',
        modified_user: ''
    };
    const [formData, setFormData] = useState(initialFormData);

    const [contractorsEmployees, setContractorsEmployees] = useState([]);
    const handleGetEmployees = async () => {
        let response = await MyContractorsEmployees.getContractorsEmployees(id_contractors) ?? [];
        if (!response || response.success === false) {
            return handleOpenAlert('No se pudo obtener la lista de empleados', 'error');
        }
        setContractorsEmployees(response.data);
    }

    // Fetch contractors
    const getContractors = async () => {
        handleOpenLoading();
        const response = await MyContractors.getContractorById(id_contractors) ?? [];
        setContractor(response.data);
        setOpenLoading(false);
    }

    useEffect(() => {
        getContractors();
        handleGetEmployees(id_contractors);
    }, [id_contractors]);


    const [editingId, setEditingId] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = () => setOpenModal(true);

    const handleClose = () => {
        setOpenModal(false);
        resetForm();
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setEditingId(null);
    };

    const handleRegisterEmployee = async (data) => {
        try {
            handleOpenLoading();
            const response = await MyContractorsEmployees.registerEmployee(data);
            if (!response || response.success === false) {
                return handleOpenAlert('No se pudo registrar el trabajador.', 'error');
            }
            handleGetEmployees(id_contractors);
            handleClose();
            return handleOpenAlert('Se registró correctamente el trabajador.', 'success');
        }
        catch (err) {
            handleOpenAlert(`Ocurrió un error al registrar el trabajador. ${err}`, 'error');
        }
    }

    const handleUpdateEmployee = async (id_employee, data) => {
        try {
            handleOpenLoading();
            const response = await MyContractorsEmployees.updateEmployee(id_employee, data);
            if (!response || response.success === false) {
                return handleOpenAlert('No se pudo actualizar el trabajador.', 'error');
            }
            handleGetEmployees(id_contractors);
            handleClose();
            return handleOpenAlert('Se actualizó correctamente el trabajador.', 'success');
        }
        catch (err) {
            handleOpenAlert(`Ocurrió un error al registrar el trabajador. ${err}`, 'error')
        }
    }

    const handleSubmit = async (data) => {
        data.id_contractor = id_contractors;
        if (editingId) {
            handleUpdateEmployee(editingId, data);
        } else {
            handleRegisterEmployee(data);
        }
    };

    const handleEdit = (employee) => {
        setFormData(employee);
        setEditingId(employee.id);
        handleOpen();
    };

    const handleDelete = async (id) => {
        try {
            handleOpenLoading();
            const response = await MyContractorsEmployees.deleteEmployee(id);
            if (!response || response.success === false) {
                return handleOpenAlert(`No se pudo eliminar el trabajador. ${response.error}}`.trimEnd(), 'error');
            }
            handleGetEmployees(id_contractors);
            handleClose();
            handleCloseModalConfirm();
            return handleOpenAlert('Se eliminó correctamente el trabajador.', 'success');
        }
        catch (err) {
            handleOpenAlert(`Ocurrió un error al eliminar el trabajador. ${err}`, 'error')
        }
    };

    // Confirm dialog
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");
    const [idToDelet, setIdToDelete] = useState(null);

    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm, buttonActionType, id_employee) => {
        setOpenModalConfirm(true);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setIdToDelete(id_employee);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionConfirm = () => {
        const actions = {
            'delete': () => {
                handleDelete(idToDelet);
            }
        }
        if (actions[buttonActionType]) {
            actions[buttonActionType]();
        }
    }

    const [contractor, setContractor] = useState({
        id_contractors: '',
        company_name: '',
        ruc: '',
        address: '',
        economic_activity_type: '',
        total_workers: '',
        id_companies: '',
    })

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setContractor(prevState => ({ ...prevState, [name]: value }));
    }, [setContractor]);

    const handlePostUpdateContractor = async (e) => {
        e.preventDefault();
        handleOpenLoading();
        const resp = await MyContractors.updateContractorById(contractor, id_contractors);
        if (resp.success) {
            await getContractors();
            handleOpenAlert("Contractor updated successfully", "success");
        } else {
            handleOpenAlert("Error updating contractor", "error");
        }
    }

    return (
        <>
            <ReturnBack returnBack="/documentation/contractors" />
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />

            <Container maxWidth="sm" style={{ marginTop: '20px' }}>
                <form>
                    <Grid container spacing={2}>
                        {!contractor || Object.keys(contractor).length === 0 ? (
                            <Grid item xs={12} sx={{
                                display: 'flex', justifyContent: 'center'
                            }}>
                                <Skeleton variant="text" width={'80%'} height={'5rem'} />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Nombre del Contratista"
                                        name="company_name"
                                        fullWidth
                                        required
                                        value={contractor.company_name}
                                        onChange={handleChange}
                                        sx={{
                                            fontFamily: 'Roboto',
                                            width: '100%',
                                            padding: '4px 10px',
                                            border: '1px solid transparent',
                                            backgroundColor: '#FFFFFF',
                                            color: '#305AD9',
                                            borderRadius: '8px',
                                            "&.Mui-focused": {
                                              border: "1px solid blue",
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="RUC"
                                        name="ruc"
                                        fullWidth
                                        required
                                        value={contractor.ruc}
                                        onChange={handleChange}
                                        sx={{
                                            fontFamily: 'Roboto',
                                            width: '100%',
                                            padding: '4px 10px',
                                            border: '1px solid transparent',
                                            backgroundColor: '#FFFFFF',
                                            color: '#305AD9',
                                            borderRadius: '8px',
                                            "&.Mui-focused": {
                                              border: "1px solid blue",
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Número de Trabajadores"
                                        name="total_workers"
                                        fullWidth
                                        type="number"
                                        value={contractor.total_workers}
                                        onChange={handleChange}
                                        sx={{
                                            fontFamily: 'Roboto',
                                            width: '100%',
                                            padding: '4px 10px',
                                            border: '1px solid transparent',
                                            backgroundColor: '#FFFFFF',
                                            color: '#305AD9',
                                            borderRadius: '8px',
                                            "&.Mui-focused": {
                                              border: "1px solid blue",
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label="Domicilio"
                                        name="address"
                                        fullWidth
                                        value={contractor.address}
                                        onChange={handleChange}
                                        sx={{
                                            fontFamily: 'Roboto',
                                            width: '100%',
                                            padding: '4px 10px',
                                            border: '1px solid transparent',
                                            backgroundColor: '#FFFFFF',
                                            color: '#305AD9',
                                            borderRadius: '8px',
                                            "&.Mui-focused": {
                                              border: "1px solid blue",
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Tipo de Actividad Económica"
                                        name="economic_activity_type"
                                        fullWidth
                                        value={contractor.economic_activity_type}
                                        onChange={handleChange}
                                        sx={{
                                            fontFamily: 'Roboto',
                                            width: '100%',
                                            padding: '4px 10px',
                                            border: '1px solid transparent',
                                            backgroundColor: '#FFFFFF',
                                            color: '#305AD9',
                                            borderRadius: '8px',
                                            "&.Mui-focused": {
                                              border: "1px solid blue",
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '20px' }} sx={{
                                    display: 'flex', flexDirection: 'row', justifyContent: 'center',
                                }}>
                                    <Box sx={{ margin: '0 0.5rem' }}>
                                        <ButtonControla
                                            backgroundColor={"#CBCBFF"}
                                            textButton={"Regresar"}
                                            backgroundColorHover={"#CBCBFF"}
                                            typeButton={"button"}
                                            isNavegationOrigin={true}
                                            url={"/documentation/contractors"}
                                        />
                                    </Box>
                                    {readOnly !== "true" && (
                                        <Box>
                                            <ButtonControla
                                                roles={permissions.DOC_HG_PROC_PETAR_ACTI_ACTUALIZAR_PETAR}
                                                iconButton={<Edit sx={{ color: "white" }} />}
                                                backgroundColor={"#169073"}
                                                backgroundColorHover={"#1BAA88"}
                                                textButton={"Actualizar Contratista"}
                                                typeButton={"button"}
                                                functionOnClick={handlePostUpdateContractor}
                                            />
                                        </Box>
                                    )}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </form>
            </Container >
            <Container maxWidth="md" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                <h4>Lista de Empleados por Contratista</h4>
                <Grid container sx={{ mt: 0 }}>
                    <Grid item xs={12} >
                        <Stack direction={"row"} spacing={2} sx={{ justifyContent: 'flex-end', }}>
                            <Box>
                                <ButtonControla
                                    roles={permissions.CONTRACTOR_ADD}
                                    iconButton={<Add sx={{ color: "white" }} />}
                                    textButton={"Agregar Trabajador"}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#169073"}
                                    functionOnClick={() => { resetForm(); handleOpen(); }}
                                />
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ minWidth: '100%', overflowX: 'auto' }}>
                    <Table aria-label="simple table" sx={{ minWidth: 650, overflow: 'break-all' }}>
                        <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableRow>
                                {[
                                    'Nombres', 'Apellido Paterno', 'Apellido Materno', 'N° Documento', 'Fecha Nacimiento', 'Sexo', 'Celular', 'Puesto', 'Antigüedad', 'Contrato', 'Acciones'
                                ].map((col, index) => (
                                    <TableCell key={index} sx={{ overflow: 'break-all', fontWeight: 'bold', lineHeight: '1rem' }}>{col}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!contractorsEmployees || contractorsEmployees.length < 1 ? (
                                <TableRow>
                                    <TableCell colSpan={16} sx={{ textAlign: 'center', overflow: 'break-all' }}>No hay empleados</TableCell>
                                </TableRow>
                            ) : (
                                contractorsEmployees.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell sx={{ overflow: 'break-all' }}>{row.nombres}</TableCell>
                                        <TableCell sx={{ overflow: 'break-all' }}>{row.apellido_paterno}</TableCell>
                                        <TableCell sx={{ overflow: 'break-all' }}>{row.apellido_materno}</TableCell>
                                        <TableCell sx={{ overflow: 'break-all' }}>{row.n_documento}</TableCell>
                                        <TableCell sx={{ overflow: 'break-all' }}>{row.fecha_nacimiento ? new Date(row.fecha_nacimiento).toLocaleDateString() : ''}</TableCell>
                                        <TableCell sx={{ overflow: 'break-all' }}>{row.sexo ?? ''}</TableCell>
                                        <TableCell sx={{ overflow: 'break-all' }}>{row.celular ?? ''}</TableCell>
                                        <TableCell sx={{ overflow: 'break-all' }}>{row.puesto_trabajo ?? ''}</TableCell>
                                        <TableCell sx={{ overflow: 'break-all' }}>{row.fecha_inicio_actividades ?? ''}</TableCell>
                                        <TableCell sx={{ overflow: 'break-all' }}>{row.tipo_contrato ?? ''}</TableCell>
                                        <TableCell sx={{ overflow: 'break-all' }}>
                                            <ButtonIconControla
                                                icon={<Edit sx={{ color: "white" }} />}
                                                backgroundColor={"#2D9CDB"}
                                                backgroundColorHover={"#2D9CDB"}
                                                textTooltip={"Editar"}
                                                functionOnClick={() => handleEdit(row)}
                                            />

                                            <ButtonIconControla
                                                icon={<Delete sx={{ color: "white" }} />}
                                                backgroundColor={"#EB5757"}
                                                backgroundColorHover={"#EB5757"}
                                                textTooltip={"Delete"}
                                                functionOnClick={() => handleOpenModalConfirm(
                                                    "¿Estás seguro de que deseas eliminar este trabajador?",
                                                    `Una vez eliminado, el trabajador no podrá ser recuperado.`,
                                                    "Sí, Elimiar",
                                                    "delete",
                                                    row.id
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>

            {/* Modal para agregar/editar empleado */}
            <ContractorEmployeeModal
                open={openModal}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                initialData={editingId ? formData : null}
            />
        </>
    )
}
