import { React, useState, useEffect } from "react";

import SubtitleText from "../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Typography,
  Divider,
  DialogActions,
} from "@mui/material";
import {
  ArrowBack, 
  Save,
} from "@mui/icons-material";
import TitleText from "../../components/text/titleText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import ButtonControla from "../../components/buttons/buttonController";
import SelectYesNotControla from "../../components/selects/selectYesNotControla";
import MyCompanies from "../../api/MyCompanies";
import SearchUsersControla from "../../components/search/searchUsersControla";
import MyUsers from "../../api/MyUsers";
import { useAppContext } from "../../context/AppContext";
import SearchButtonControla from "../../components/search/searchButtonControla";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";
import ReturnBack from "../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";

function Organization() {
  const { permissions } = useAppContext();
  const [rowsUsers, setRowsUsers] = useState([]);

  const [openModalCreateSector, setOpenModalCreateSector] = useState(false);
  const [sectors, setSectors] = useState(0);

  const handleOpenModalCreateSector = () => {
    setSectors("0");
    setOpenModalCreateSector(true);
  };

  const [openModalSelectSecurityBoss, setOpenModalSelectSecurityBoss] =
    useState(false);

  const [openModalSelectMedicalBoss, setOpenModalSelectMedicalBoss] =
    useState(false);

  const [operatingLicense, setOperatingLicense] = useState("");
  const [operatingLicenseDate, setOperatingLicenseDate] = useState("");
  const [itse, setItse] = useState("");
  const [itseEmisionDate, setItseEmisionDate] = useState("");
  const [itseExpirationDate, setItseExpirationDate] = useState("");
  const [hireStaff, setHireStaff] = useState("");
  const [personalHighlights, setPersonalHighlights] = useState("");

  const [securityBoss, setSecurityBoss] = useState(" ");
  const [idSecurityBoss, setIdSecurityBoss] = useState("");

  const [medicalBoss, setMedicalBoss] = useState(" ");
  const [idMedicalBoss, setIdMedicalBoss] = useState("");

  const handleGetCompany = async () => {
    const response = await MyCompanies.company();
    if (response.success !== false) {
      setOperatingLicense(response.operating_license);
      setOperatingLicenseDate(response.operating_license_date);
      setItse(response.itse);
      setItseEmisionDate(response.itse_emision_date);
      setItseExpirationDate(response.itse_expiration_date);
      setHireStaff(response.hire_staff);
      setPersonalHighlights(response.personal_highlights);

      if (response.id_users_security_boss != null) {
        handleSelectUsersSecurityBoss(response.id_users_security_boss);
      }
      if (response.id_users_medical_boss != null) {
        handleSelectUsersMedicalBoss(response.id_users_medical_boss);
      }
    } else {
      alert("Error al listar los datos de empresa!");
    }
  };

  const handleGetUsers = async (event) => {
    const response = await MyUsers.users();
    if (response.success !== false) {
      setRowsUsers(response.users_actives);
    } else {
      alert("Error al listar la Los usuarios!");
    }
  };

  useEffect(() => {
    handleGetCompany();
    handleGetUsers();
  }, []);

  const handleSelectUsersSecurityBoss = async (idUsers) => {
    setIdSecurityBoss(idUsers);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setSecurityBoss(
        response.document +
          " - " +
          response.first_name +
          " " +
          response.last_name
      );
      setOpenModalSelectSecurityBoss(false);
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  const handleSelectUsersMedicalBoss = async (idUsers) => {
    setIdMedicalBoss(idUsers);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setMedicalBoss(
        response.document +
          " - " +
          response.first_name +
          " " +
          response.last_name
      );
      setOpenModalSelectMedicalBoss(false);
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  const handleUpdateOrganizationCompany = async (event) => {
    event.preventDefault();
    const response = await MyCompanies.updateOrganization({
      operating_license: operatingLicense,
      operating_license_date: operatingLicenseDate,
      itse: itse,
      itse_emision_date: itseEmisionDate,
      itse_expiration_date: itseExpirationDate,
      hire_staff: hireStaff,
      personal_highlights: personalHighlights,
      id_users_security_boss: idSecurityBoss,
      id_users_medical_boss: idMedicalBoss,
    });
    if (response.success !== false) {
      alert("Datos actualizados correctamente!");
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  return (
    <Box >
      <ReturnBack/>

      <Container>
        <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ButtonMailboxControla
                originUrl={"/settings/company/organization"}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <SubtitleText text={"Configuración"} color={colorTitle} />
              <TitleText text={"Organización"} />
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            </Grid>

            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>

          <form onSubmit={handleUpdateOrganizationCompany}>
            <Box sx={{ marginTop: 3 }}>
              {/* <SubtitleText text={"Organizacion"} color={blue} /> */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
                  <SelectYesNotControla
                    text={"Licencia de funcionamiento:"}
                    inputValue={setOperatingLicense}
                    value={operatingLicense}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Fecha de emisión:"}
                    inputType={"date"}
                    inputValue={setOperatingLicenseDate}
                    value={operatingLicenseDate}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SelectYesNotControla
                    text={
                      "Certificado de Inspección Técnica de Seguridad en la Edificación - ITSE:"
                    }
                    inputValue={setItse}
                    value={itse}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputControlaVertical
                    text={"Fecha de emisión:"}
                    inputType={"date"}
                    inputValue={setItseEmisionDate}
                    value={itseEmisionDate}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputControlaVertical
                    text={"Fecha de vencimiento:"}
                    inputType={"date"}
                    inputValue={setItseExpirationDate}
                    value={itseExpirationDate}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SelectYesNotControla
                    text={"¿Su empresa contrata personal destacado?:"}
                    inputValue={setHireStaff}
                    value={hireStaff}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SelectYesNotControla
                    text={"¿Su empresa destaca personal(contratista)?:"}
                    inputValue={setPersonalHighlights}
                    value={personalHighlights}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ marginTop: 3 }} />

            <Box sx={{ marginTop: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SearchButtonControla
                    roles={permissions.CONF_EMP_ORGA_VER}
                    titleLabel="Jefe/ Gerente / Supervisor del Área de Seguridad:"
                    inputValue={securityBoss}
                    functionClick={() => setOpenModalSelectSecurityBoss(true)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ marginTop: 3 }} />

            <Box sx={{ marginTop: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SearchButtonControla
                    roles={permissions.CONF_EMP_ORGA_VER}
                    titleLabel="Médico Ocupacional / Responsable de la Salud Ocupacional:"
                    inputValue={medicalBoss}
                    functionClick={() => setOpenModalSelectMedicalBoss(true)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ marginTop: 3 }} />

            <Stack
              direction={"row"}
              spacing={2}
              justifyContent="start"
              sx={{ marginTop: 3 }}
            >
              <Box>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  textButton={"Regresar"}
                  backgroundColor={"#CBCBFF"}
                  backgroundColorHover={"#d6d6ff"}
                  isNavegationOrigin={true}
                  url={-1}
                />
              </Box>
              <Box>
                <ButtonControla
                  roles={permissions.CONF_EMP_ORGA_EDITAR}
                  iconButton={<Save />}
                  textButton={"Actualizar"}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  typeButton={"submit"}
                />
              </Box>
            </Stack>
          </form>
        </Box>

        <Dialog
          open={openModalSelectSecurityBoss}
          onClose={() => {
            setOpenModalSelectSecurityBoss(false);
          }}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Seleccionar Jefe/ Gerente / Supervisor del Área de Seguridad
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ paddingTop: "0" }}>
            <SearchUsersControla
              handleUsersActionButton={handleSelectUsersSecurityBoss}
              rowsUser={rowsUsers}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenModalSelectSecurityBoss(false);
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openModalSelectMedicalBoss}
          onClose={() => {
            setOpenModalSelectMedicalBoss(false);
          }}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Seleccionar representante del sindicato
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ paddingTop: "0" }}>
            <SearchUsersControla
              handleUsersActionButton={handleSelectUsersMedicalBoss}
              rowsUser={rowsUsers}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenModalSelectMedicalBoss(false);
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
}

export default Organization;
