import { Stack, TableBody, TableCell, TableRow , MenuItem} from "@mui/material";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import { Delete } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import SelectControla from "../../../../components/selects/selectControla";
 
function ListHeadlines(props){
    const {
        membersHeadlines,
        listPosition,
        setCommitteePosition,
        colorCell,
        handleOpenModalComment,
        handleOpenModalConfirm,
        handleChangeCommitteePosition
    } = props
    return (
        <TableBody>
            {membersHeadlines.map((member) => (
                <TableRow
                    hover
                    key={member.id_committee_members}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {member.first_name + " " + member.last_name}
                    </TableCell>
                    <TableCell component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center">
                        {member.document}
                    </TableCell>
                    <TableCell component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center">
                        {member.position_name}
                    </TableCell>
                    <TableCell component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center">
                        {member.selection_type === 1 ? "Representante del empleador" : "Representante de los trabajadores"}
                    </TableCell>
                    <TableCell component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center">
                        {member.status === 1 ? "Habilitado" : "Deshabilitado"}
                    </TableCell>
                    <TableCell component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center">
                        <SelectControla
                            inputValue={setCommitteePosition}
                            value={member.position_committe_member || ''}
                            childrenRows={listPosition.map((rows) => (
                                <MenuItem
                                    key={rows.id_committee_position}
                                    value={rows.id_committee_position}
                                >
                                    {rows.nombre}
                                </MenuItem>
                            ))}
                            onChange={
                                (event) => {
                                    handleChangeCommitteePosition(member.id_committee_members, event.target.value)
                                }

                            }
                        />

                    </TableCell>

                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignContent="center"
                        >
                            {member.status !== 1 ? (
                                <ButtonIconControla
                                    icon={
                                        <PersonIcon
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1AA483"}
                                    textTooltip={"Habilitar"}
                                    functionOnClick={() => { handleOpenModalComment(member.id_committee_members, member.status, "changeMember") }}
                                />
                            ) : ''}
                            {member.status === 1 ? (
                                <ButtonIconControla
                                    roles={[1, 7, 8]}
                                    icon={
                                        <PersonOffIcon
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Deshabilitar"}
                                    functionOnClick={() => { handleOpenModalComment(member.id_committee_members, member.status, "changeMember") }}
                                />
                            ) : ''}

                            <ButtonIconControla
                                roles={[1, 7, 8]}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => { 
                                    handleOpenModalConfirm(
                                    member.id_committee_members,
                                    "¿Estás seguro de eliminar este miembro del comité?",
                                    "",
                                    "Si, eliminar",
                                    "deleteMember"
                                    ) }}
                            />
                        </Stack>
                    </TableCell>
                    <TableCell component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center">
                        {member.comment}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>

    )

}

export default ListHeadlines;