import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
    Grid,
    FormControl,
    Radio,
    CircularProgress,
} from "@mui/material";
import {
    InfoOutlined,
} from "@mui/icons-material";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import SideNav from "../../components/sidenav/sideNav";
import SubtitleText from "../../components/text/subtitleText";
import ButtonControla from "../../components/buttons/buttonController";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import MyRit from "../../api/MyRit";
import LoadingControla from "../../components/load/loadingControla";

const blue = "#034AFF";
const purple = "#8B8BD8";

const arr_posible_rit_topics = [
    {
        id: 6,
        topic: "Licencia para la asistencia médica y la terapia de rehabilitación que requieran los hijos menores con discapacidad, menores con discapacidad sujetos a su tutela, mayores de edad con discapacidad en condición de dependencia o sujetos a su curatela.",
    },
    {
        id: 7,
        topic: "Licencia en caso el trabajador sea peticionario de adopción.",
    },
    {
        id: 4,
        topic: "Licencia en los casos de tener un hijo, padre o madre, cónyuge o conviviente enfermo diagnosticado en estado grave o terminal.",
    },
    {
        id: 9,
        topic: "Licencia por fallecimiento de cónyuge/conviviente, padres, hijos o hermanos.",
    },
    {
        id: 3,
        topic: "Licencia por partenidad.",
    },
    {
        id: 1,
        topic: "Licencia por descanso prenatal y postnatal de la trabajadora gestante.",
    },
    {
        id: 10,
        topic: "Otros permisos y licencias que la empresa establezca o que la normativa laboral señale.",
    },
    {
        id: 5,
        topic: "Permiso en caso el trabajador tenga familiares directos y cuidadores a cargo de la atención de los pacientes con enfermedad de Alzheimer y otras demencias.",
    },
    {
        id: 8,
        topic: "Permiso en caso del trabajador con tuberculosis a fin de recibir su respectivo tratamiento.",
    },
    {
        id: 2,
        topic: "Permiso por lactancia materna, que es diferente al uso del lactario.",
    },
];

export default function Fulfillments() {
    const params = new URLSearchParams(window.location.search);
    const idRit = params.get("idRit");

    const [loading, setLoading] = useState(false);
    const [workerEntrance, setWorkerEntrance] = useState("");
    const handleChangeWorkerEntrance = (event) => {
        setWorkerEntrance(event.target.value);
    }

    const [workingHours, setWorkingHours] = useState("");
    const handleChangeWorkingHours = (event) => {
        setWorkingHours(event.target.value);
    }

    const [attendanceControl, setAttendanceControl] = useState("");
    const handleChangeAttendance = (event) => {
        setAttendanceControl(event.target.value);
    }

    const [permanenceRules, setPermanenceRules] = useState("");
    const handleChangePermanenceRules = (event) => {
        setPermanenceRules(event.target.value);
    }

    const [weeklyLeave, setWeeklyLeave] = useState("");
    const handleChangeWeeklyLeave = (event) => {
        setWeeklyLeave(event.target.value);
    }

    const [employerRightsResponsabilities, setEmployerRightsResponsabilities] = useState("");
    const handleChangeEmployerRightsResponsabilities = (event) => {
        setEmployerRightsResponsabilities(event.target.value);
    }

    const [employeeRightsResponsabilities, setEmployeeRightsResponsabilities] = useState("");
    const handleChangeEmployeeResponsabilities = (event) => {
        setEmployeeRightsResponsabilities(event.target.value);
    }

    const [employerEmployeeHarmonyRules, setEmployerEmployeeHarmonyRules] = useState("");
    const handleChangeEmployerEmployeeHarmony = (event) => {
        setEmployerEmployeeHarmonyRules(event.target.value);
    }

    const [disciplinaryMeasures, setDisciplinaryMeasures] = useState("");
    const handleChangeDisciplinaryMeasures = (event) => {
        setDisciplinaryMeasures(event.target.value);
    }

    const [laboralMattersEntity, setLaboralMattersEntity] = useState("");
    const handleChangeLaboralMattersEntity = (event) => {
        setLaboralMattersEntity(event.target.value);
    }

    const [fundamentalRulesActivities, setFundamentalRulesActivities] = useState("");
    const handleChangeFundamentalRulesActivites = (event) => {
        setFundamentalRulesActivities(event.target.value);
    }

    const [accidentAvoidanceRules, setAccidentAvoidanceRules] = useState("");
    const handleChangeAccidentAvoidRules = (event) => {
        setAccidentAvoidanceRules(event.target.value);
    }

    const [authorityEntityApproved, setAuthorityEntityApproved] = useState("");
    const handleChangeAuthorityEntityApproved = (event) => {
        setAuthorityEntityApproved(event.target.value);
    }

    const [sucamec, setSucamec] = useState("");
    const handleChangeSucamec = (event) => {
        setSucamec(event.target.value);
    }

    const [tuberculosisAntidiscriminationRules, setTuberculosisAntidiscriminationRules] = useState("");
    const handleChangeTuberculosisAntidiscriminationRules = (event) => {
        setTuberculosisAntidiscriminationRules(event.target.value);
    }

    const [smokingProhibition, setSmokingProhibition] = useState("");
    const handleChangeSmokingProhibition = (event) => {
        setSmokingProhibition(event.target.value);
    }

    const [sexualHarassmentPolicies, setSexualHarassmentPolicies] = useState("");
    const handleChangeSexualHarassmentPolicies = (event) => {
        setSexualHarassmentPolicies(event.target.value);
    }

    const [investigationSanction, setInvestigationSanction] = useState("");
    const handleChangeInvestigtionSanction = (event) => {
        setInvestigationSanction(event.target.value);
    }

    const [hivPrevention, setHivPrevention] = useState("");
    const handleChangeHivPrevention = (event) => {
        setHivPrevention(event.target.value);
    }

    const [lactancyTime, setLactancyTime] = useState("");
    const handleChangeLactancyTime = (event) => {
        setLactancyTime(event.target.value);
    }

    const [maternityLeave, setMaternityLeave] = useState("");
    const handleChangeMaternityLeave = (event) => {
        setMaternityLeave(event.target.value);
    }

    const [bikeUsage, setBikeUsage] = useState("");
    const handleChangeBikeUsage = (event) => {
        setBikeUsage(event.target.value);
    }

    const [uniformFlexibility, setUniformFlexibility] = useState("");
    const handleChangeUniformFlexibility = (event) => {
        setUniformFlexibility(event.target.value);
    }

    const [diabetesAntidiscrimination, setDiabetesAntidiscrimination] = useState("");
    const handleChangeDiabetesAntidiscrimination = (event) => {
        setDiabetesAntidiscrimination(event.target.value);
    }

    const [ritTopics, setRitTopics] = useState("");
    const handleChangeRitTopics = (event) => {
        setRitTopics(event.target.value);
    }

    const [openAlert, setOpenAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const arr_rit_1 = [
        {
            label: "Admisión o ingreso de los trabajadores.",
            state: workerEntrance,
            onChange: handleChangeWorkerEntrance
        },
        {
            label: "Con relación al uso de la bicicleta, como medio de transporte al centro laboral, se recomienda contar con una política interna para la promoción del uso de la bicicleta, que puede estar incluida en el RIT, o en documento aparte. Esta política debe incluir la flexibilización del horario de trabajo, otorgamiento de horas o días libres remunerados y las medidas necesarias para verificar el uso de la bicicleta.",
            state: bikeUsage,
            onChange: handleChangeBikeUsage
        },
        {
            label: "Contiene las medidas para prevenir y sancionar los actos discriminatorios hacia una persona afectada por tuberculosis.",
            state: tuberculosisAntidiscriminationRules,
            onChange: handleChangeTuberculosisAntidiscriminationRules,
            tooltip: "Recuerda que el acto de discriminación contra una persona afectada por tuberculosis se considera un acto de hostilidad equiparable al despido."
        },
        {
            label: "Contiene una política para prevenir y sancionar el hostigamiento sexual, especificando los canales y par la presentación de quejas o denuncias.",
            state: sexualHarassmentPolicies,
            onChange: handleChangeSexualHarassmentPolicies
        },
        {
            label: "Contiene una políþica de prevención y control de VIH/SIDA, así como sobre sancionar cualuier actitud o acción respecto de a discriminación y rechzo de las personas con real o supuestamente VIH positivo.",
            state: hivPrevention,
            onChange: handleChangeHivPrevention
        },
        {
            label: "Debe incluir la prohibición expresa de fumar en todas sus instalaciones, y los mecanismos internos para denunciar a quienes fmen donde esté prohibido",
            state: smokingProhibition,
            onChange: handleChangeSmokingProhibition
        },
        {
            label: "Derecho y obligaciones del empleador",
            state: employerRightsResponsabilities,
            onChange: handleChangeEmployerRightsResponsabilities
        },
        {
            label: "Derecho y obligaciones del trabajador",
            state: employeeRightsResponsabilities,
            onChange: handleChangeEmployeeResponsabilities
        },
        {
            label: "Establece el procedimiento de investigación y sanción, así como los plazos de cada etapa en caso de denuncia por hostigamiento sexual.",
            state: investigationSanction,
            onChange: handleChangeInvestigtionSanction,
            tooltip: "Recomendamos verificar el procedimiento y sus plazos en la Resolución Ministerial N° 223-2019-TR que aprueba la Guía práctica para prevención y sanción del hostigamiento sexual en el lugar de trabajo en el sector privado y público."
        },
        {
            label: "Está debidamente aprobado por la autoridad administrativa de trabajo.",
            state: authorityEntityApproved,
            onChange: handleChangeAuthorityEntityApproved,
            tooltip: "Recuerda que tanto el RIT inicial, así como sus modificaciones y/o actualizaciones deben ser presentadas a la autoridad administrativa de trabajo."
        },
        {
            label: "Está visado por la autoridad competente (SUCAMEC)",
            state: sucamec,
            onChange: handleChangeSucamec,
            tooltip: "Este requisito es para las empresas que prestan servicios de seguridad privada y cuya autoridad es la SUCAMEC."
        },
        {
            label: "Indicaciones para evitar accidentes y otros riesgos profesionales, así como las instrucciones respectivas para la prestación de primeros auxilios.",
            state: accidentAvoidanceRules,
            onChange: handleChangeAccidentAvoidRules,
            tooltip: "Se puede incluir aquí el procedimiento de primeros auxilios que se tiene en la empresa, así como los estándares generales de seguridad y salud ocupacional."
        },
        {
            label: "La flexibilización en el uso de la vestimenta formal o uniforme para los usuarios de bicicletas como medio de transporte as su centro laboral, sin perjuicio de las medidas de seguridad y salud ocupacional.",
            state: uniformFlexibility,
            onChange: handleChangeUniformFlexibility
        },
        {
            label: "Las jornadas y horarios de trabajo; tiempo de la alimentación principal.",
            state: workingHours,
            onChange: handleChangeWorkingHours
        },
        {
            label: "Medidas disciplinarias.",
            state: disciplinaryMeasures,
            onChange: handleChangeDisciplinaryMeasures
        },
        {
            label: "Modalidad de los descansos semanales.",
            state: weeklyLeave,
            onChange: handleChangeWeeklyLeave
        },
        {
            label: "Normas de control de asistencia al trabajo",
            state: attendanceControl,
            onChange: handleChangeAttendance
        },
        {
            label: "Normas de permanencia en el puesto conteniendo: permisos, licencias e inasistencias.",
            state: permanenceRules,
            onChange: handleChangePermanenceRules
        },
        {
            label: "Normas elementales que se deben observar dentro del desarrollo de la actividad laboral para prevenir la higiene, salud y seguridad ocupacional.",
            state: fundamentalRulesActivities,
            onChange: handleChangeFundamentalRulesActivites,
            tooltip: "Entre las principales tenemos: Ley N° 29783, Ley de Seguridad y Salud en el Trabajo. Decreto Supremo N° 005-2012-TR, Reglamento de la Ley de Seguridad y Salud en el Trabajo"
        },
        {
            label: "Normas tendientes al fomento y mantenimiento de la armonía entre trabajadores y empleadores.",
            state: employerEmployeeHarmonyRules,
            onChange: handleChangeEmployerEmployeeHarmony
        },
        {
            label: "Persona o dependencia encargada de atender los asuntos laborales y la tramitación de los mismos.",
            state: laboralMattersEntity,
            onChange: handleChangeLaboralMattersEntity
        },
        {
            label: "Se puede incluir dentro del RIT las acciones para usar (por ejemplo tiempo de uso de 1 hora) y acceder al servicio del lactario, así como para su mantenimiento, registros que se deben llevar y responsable de los mismos.",
            state: lactancyTime,
            onChange: handleChangeLactancyTime
        },
        {
            label: "Se puede incluir dentro del RIT la politica de no discriminación por razón de diabetes de acuerdo con el Decreto Supremo Nro 008-2023-SA",
            state: diabetesAntidiscrimination,
            onChange: handleChangeDiabetesAntidiscrimination
        },
        {
            label: "También puedes incluir el trámite a realizar por el permiso para lactancia materna hasta que el niño cumpla un año de edad.",
            state: maternityLeave,
            onChange: handleChangeMaternityLeave
        },
        {
            label: "Recuerda considerar incluir en el RIT los siguientes temas:",
            state: ritTopics,
            onChange: handleChangeRitTopics,
            subItems: arr_posible_rit_topics.map(item => item.topic)
        }
    ];

    const handleOpenAlert = (alertMessage) => {
        setOpenAlert(true);
        setLoading(false);
        setErrorMessage(alertMessage);
    };
    
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    // funciones
    const handleGetFulfillments = async () => {
        // Show loading
        await setLoading(true);
        const response = await MyRit.editRit({ idRit });

        setWorkerEntrance(response.workers_entrance);
        setWorkingHours(response.working_hours);
        setAttendanceControl(response.attendance_control);
        setPermanenceRules(response.permanence_rules);
        setWeeklyLeave(response.weekly_leave);
        setEmployerRightsResponsabilities(response.employer_rights_responsabilities);
        setEmployeeRightsResponsabilities(response.employee_rights_responsabilities);
        setEmployerEmployeeHarmonyRules(response.employer_employee_harmony_rules);
        setDisciplinaryMeasures(response.disciplinary_measures);
        setLaboralMattersEntity(response.laboral_matters_entity);
        setFundamentalRulesActivities(response.fundamental_rules_activities);
        setAccidentAvoidanceRules(response.accident_avoidance_rules);
        setAuthorityEntityApproved(response.authority_entity_approved);
        setSucamec(response.sucamec);
        setTuberculosisAntidiscriminationRules(response.tuberculosis_antidiscrimination_rules);
        setSmokingProhibition(response.smoking_prohibition);
        setSexualHarassmentPolicies(response.sexual_harassment_policies);
        setInvestigationSanction(response.investigation_sanction);
        setHivPrevention(response.hiv_prevention);
        setLactancyTime(response.lactancy_time);
        setMaternityLeave(response.maternity_leave);
        setBikeUsage(response.bike_usage);
        setUniformFlexibility(response.uniform_flexibility);
        setDiabetesAntidiscrimination(response.diabetes_antidiscrimination);
        setRitTopics(response.rit_topics);
        // Hide loading
        setLoading(false);
    };

    const handleSaveFulfillments = async (event) => {
        event.preventDefault();
        const response = await MyRit.updateRit({
            idRit: idRit, workerEntrance: workerEntrance, workingHours: workingHours, attendanceControl: attendanceControl, permanenceRules: permanenceRules, weeklyLeave: weeklyLeave, employeeRightsResponsabilities: employeeRightsResponsabilities, employerRightsResponsabilities: employerRightsResponsabilities, employerEmployeeHarmonyRules: employerEmployeeHarmonyRules, disciplinaryMeasures: disciplinaryMeasures, laboralMattersEntity: laboralMattersEntity, fundamentalRulesActivities: fundamentalRulesActivities, accidentAvoidanceRules: accidentAvoidanceRules, authorityEntityApproved: authorityEntityApproved, sucamec: sucamec, tuberculosisAntidiscrimnationRules: tuberculosisAntidiscriminationRules, smokingProhibition: smokingProhibition, sexualHarassmentPolicies: sexualHarassmentPolicies, investigationSanction: investigationSanction, hivPrevention: hivPrevention, lactancyTime: lactancyTime, maternityLeave: maternityLeave, bikeUsage: bikeUsage, uniformFlexibility: uniformFlexibility, diabetesAntidiscrimination: diabetesAntidiscrimination, ritTopics: ritTopics
        })
        // Control de errores del response
        if (response?.error === true) {
            handleOpenAlert(response?.message);
            return;
        }
        window.location.href = "/documentation/rit";
    };

    useEffect(() => {
        handleGetFulfillments();
    }, []);

    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav returnBack="/documentation/rit" />
            <Container>
                <Box>
                    <form onSubmit={handleSaveFulfillments}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <SubtitleText
                                    text={"Cumplimientos"}
                                    color={"#9191B0"}
                                ></SubtitleText>
                                <strong style={{ color: blue, lineHeight: "1.3rem", fontSize: "1.6rem", fontWeight: "500" }}>
                                    A continuación te presentamos una lista para que verifiques si el RIT cumple los requisitos legales:
                                </strong>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2, paddingTop: 2 }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ width: "100%" }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="left">
                                                #
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Requisito de Cumplimiento
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Cumple
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                No Cumple
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {loading ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    <div className="loadingMask" style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        minHeight: "80vh",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}>
                                                        <CircularProgress size="3rem" />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>{(
                                            arr_rit_1.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell sx={{ color: purple }} align="left">
                                                        <span>{index + 1}</span>
                                                    </TableCell>
                                                    <TableCell sx={{ color: purple }} align="left">
                                                        <Grid container spacing={2} sx={{ display: "flex", alignItems: "center", alignContent: "center" }}>
                                                            <Grid item>
                                                                {item.label}
                                                                {item.subItems && (
                                                                    <ol type="a">
                                                                        {item.subItems.map((subItem, subIndex) => (
                                                                            <li key={subIndex}>{subItem}</li>
                                                                        ))}
                                                                    </ol>
                                                                )}
                                                            </Grid>
                                                            {item.tooltip && (
                                                                <Grid item>
                                                                    <ButtonIconControla
                                                                        icon={<InfoOutlined sx={{ color: "white" }} />}
                                                                        backgroundColor={"#FFC300"}
                                                                        backgroundColorHover={"#FACB32"}
                                                                        textTooltip={item.tooltip}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <FormControl>
                                                            <Radio
                                                                checked={item.state === "true"}
                                                                value={true}
                                                                onChange={item.onChange}
                                                                name="radio-buttons"
                                                            />
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <FormControl>
                                                            <Radio
                                                                checked={item.state === "false"}
                                                                value={false}
                                                                onChange={item.onChange}
                                                                name="radio-buttons"
                                                            />
                                                        </FormControl>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}</TableBody>
                                    )}
                                    {/* */}
                                </Table>
                            </TableContainer>
                        </Grid>
                        {/* Botones de guardar y regresar */}
                        <Grid container sx={{ mt: 4, paddingBottom: 10, width: "100%", display: "flex", justifyContent: "space-evenly" }}>
                            <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                                <ButtonControla
                                    textButton={"Guardar"}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1AA483"}
                                    typeButton={"submit"}
                                />
                            </Grid>

                            <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                                <ButtonControla
                                    textButton={"Regresar"}
                                    backgroundColor={purple}
                                    functionOnClick={() =>
                                        (window.location.href = "/documentation/rit")
                                    }
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
                {/* Alerta de error */}
                <LoadingControla
                    openLoading={loading}
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                    messageAlert={errorMessage}
                    typeAlert={"error"}
                />
            </Container >
        </Box >
    );
}
