import React from 'react';

import SubtitleText from '../components/text/subtitleText';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Grid, Button,  FormControl, Typography } from '@mui/material';
import { Add, ArrowBackIosNew, ArrowForwardIos, DriveFileRenameOutlineOutlined,  } from '@mui/icons-material';
import TitleText from '../components/text/titleText';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import InputControla from '../components/textfields/inputControla';
import LabelControla from '../components/textfields/labelControla';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

const colorTitle = "#9191B0";
const blue = "#034AFF";
const green = "#2C8C75";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";

const StyledSaveButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: green,
    color: 'white',
    width: "100%"
});

const StyledCancelButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: lilac,
    color: '#6969D6',
    width: "100%"
});


const StyledAddButton = styled(Button)({
    textTransform: 'none',
    backgroundColor: skyblue,
    borderRadius: 16
});

function createData(name, address) {
    return { name, address };
}

const rows = [
    createData('Nuevo personal','DNI - 12345678' )
];

function Company() {

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpen2] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // const handleClickOpenEdit = () => {
    //     setOpen2(true);
    // };

    const handleCloseEdit = () => {
        setOpen2(false);
    };


    return (

        <Box sx={{ display: "flex", height: '100%' }}>

            <Container>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} md={5}>
                            <SubtitleText text={"Configuración"} color={colorTitle} ></SubtitleText>
                            <TitleText text={"Perfil de mi empresa"}></TitleText>
                        </Grid>

                        <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
                            <img src={"/assets/images/logoempresaejemplo.png"} style={{ width: "150px", textAlign: "left" }} alt="logo"/>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ flexGrow: 1, mt: 2 }} >
                    <Grid container spacing={2} >
                        <Grid item md={6}>
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="left"
                                alignItems="center">
                                <Button
                                    size="large" variant="text"
                                    style={{ backgroundColor: '#599CFF', borderRadius: '15px', color: 'white', padding: '8px 25px', textTransform: 'none' }}
                                    href={"/company"}>
                                    Detalles de mi empresa
                                </Button>
                                <Button
                                    size="large" variant="text"
                                    style={{ textTransform: 'none', borderRadius: '15px' }}
                                    href={"/company/headoffices"}>
                                    Mis sedes
                                </Button>
                                <Button
                                    size="large" variant="text"
                                    style={{ textTransform: 'none', borderRadius: '15px' }}
                                    href={"/company/organizationchart"}>
                                    Organigrama
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item md={6} >
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="right"
                                alignItems="center">

                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                    background="#000"
                                    sx={{ mr: 2 }}
                                    style={{ backgroundColor: 'white', borderRadius: '15px', color: '#305AD9' }}

                                >
                                    <ArrowBackIosNew />
                                </IconButton>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                    background="#000"
                                    style={{ backgroundColor: 'white', borderRadius: '15px', color: '#305AD9' }}
                                >
                                    <ArrowForwardIos />
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ flexGrow: 1, mt: 2, mb: 5 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={5} md={5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    
                                    <Grid container spacing={2} >
                                        <Grid item md={6} justifyContent="left" alignItems="center">
                                            <SubtitleText text={"Datos Generales"} color={blue} ></SubtitleText>
                                        </Grid>
                                        <Grid item md={6} >
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                justifyContent="right"
                                                alignItems="center">

                                                <IconButton
                                                    size="large"
                                                    edge="start"
                                                    color="inherit"
                                                    aria-label="open drawer"
                                                    background="#000"
                                                    sx={{ mr: 2 }}
                                                    style={{ backgroundColor: 'white', borderRadius: '15px', color: '#305AD9' }}

                                                >
                                                    <DriveFileRenameOutlineOutlined />
                                                </IconButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LabelControla text="Nombre de la empresa"></LabelControla>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <InputControla inputType={"text"} />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LabelControla text="RUC"></LabelControla>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <InputControla inputType={"text"} />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LabelControla text="Organización jurídica"></LabelControla>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <InputControla inputType={"text"} />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LabelControla text="Régimen"></LabelControla>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <InputControla inputType={"text"} />
                                </Grid>

                                <Grid item xs={12} md={12}>

                                    <Grid container spacing={2} >
                                        <Grid item md={6} justifyContent="left" alignItems="center">
                                            <SubtitleText text={"Representante Legal"} color={blue} ></SubtitleText>
                                        </Grid>
                                        <Grid item md={6} >
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                justifyContent="right"
                                                alignItems="center">

                                                <IconButton
                                                    size="large"
                                                    edge="start"
                                                    color="inherit"
                                                    aria-label="open drawer"
                                                    background="#000"
                                                    sx={{ mr: 2 }}
                                                    style={{ backgroundColor: 'white', borderRadius: '15px', color: '#305AD9' }}

                                                >
                                                    <DriveFileRenameOutlineOutlined />
                                                </IconButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LabelControla text="Nombres y apellidos"></LabelControla>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <InputControla inputType={"text"} />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <LabelControla text="DNI"></LabelControla>
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <InputControla inputType={"text"} />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <LabelControla text="Cargo"></LabelControla>
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <InputControla inputType={"text"} />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LabelControla text="Celular"></LabelControla>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <InputControla inputType={"text"} />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LabelControla text="Correo"></LabelControla>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <InputControla inputType={"text"} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={7} md={7}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>

                                    <Grid container spacing={2} >
                                        <Grid item md={6} justifyContent="left" alignItems="center">
                                            <SubtitleText text={"Dirección y Contacto"} color={blue} ></SubtitleText>
                                        </Grid>
                                        <Grid item md={6} >
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                justifyContent="right"
                                                alignItems="center">

                                                <IconButton
                                                    size="large"
                                                    edge="start"
                                                    color="inherit"
                                                    aria-label="open drawer"
                                                    background="#000"
                                                    sx={{ mr: 2 }}
                                                    style={{ backgroundColor: 'white', borderRadius: '15px', color: '#305AD9' }}

                                                >
                                                    <DriveFileRenameOutlineOutlined />
                                                </IconButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <LabelControla text="Departamento"></LabelControla>
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <InputControla inputType={"text"} />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <LabelControla text="Provincia"></LabelControla>
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <InputControla inputType={"text"} />
                                </Grid>

                                <Grid item xs={3} md={3}>
                                    <LabelControla text="Distrito"></LabelControla>
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <InputControla inputType={"text"} />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <LabelControla text="Urbanización"></LabelControla>
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <InputControla inputType={"text"} />
                                </Grid>

                                <Grid item xs={4} md={4}>
                                    <LabelControla text="Dirección"></LabelControla>
                                </Grid>
                                <Grid item xs={8} md={8}>
                                    <InputControla inputType={"text"} />
                                </Grid>

                                <Grid item xs={3} md={3}>
                                    <LabelControla text="Web"></LabelControla>
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <InputControla inputType={"text"} />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <LabelControla text="Teléfono"></LabelControla>
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <InputControla inputType={"text"} />
                                </Grid>

                                <Grid item xs={4} md={4}>
                                    <LabelControla text="Correo"></LabelControla>
                                </Grid>
                                <Grid item xs={8} md={8}>
                                    <InputControla inputType={"text"} />
                                </Grid>

                                <Grid item xs={12} md={12}>

                                    <Grid container spacing={2} >
                                        <Grid item md={6} justifyContent="left" alignItems="center">
                                            <SubtitleText text={"Actividades"} color={blue} ></SubtitleText>
                                        </Grid>
                                        <Grid item md={6} >
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                justifyContent="right"
                                                alignItems="center">

                                                <IconButton
                                                    size="large"
                                                    edge="start"
                                                    color="inherit"
                                                    aria-label="open drawer"
                                                    background="#000"
                                                    sx={{ mr: 2 }}
                                                    style={{ backgroundColor: 'white', borderRadius: '15px', color: '#305AD9' }}

                                                >
                                                    <DriveFileRenameOutlineOutlined />
                                                </IconButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LabelControla text="Actividad principal (CIIU)"></LabelControla>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <InputControla inputType={"text"} />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LabelControla text="Actividad secundaria (CIIU)"></LabelControla>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <InputControla inputType={"text"} />
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            
                        </Grid>
                    </Grid>



                    <Grid container spacing={2}>
                        <Grid item xs={5} md={5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>

                                    <Grid container spacing={2} >
                                        <Grid item md={6} justifyContent="left" alignItems="center">
                                            <SubtitleText text={"Sindicato"} color={blue} ></SubtitleText>
                                        </Grid>
                                        <Grid item md={6} >
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                justifyContent="right"
                                                alignItems="center">

                                                <IconButton
                                                    size="large"
                                                    edge="start"
                                                    color="inherit"
                                                    aria-label="open drawer"
                                                    background="#000"
                                                    sx={{ mr: 2 }}
                                                    style={{ backgroundColor: 'white', borderRadius: '15px', color: '#305AD9' }}

                                                >
                                                    <DriveFileRenameOutlineOutlined />
                                                </IconButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} md={12}>

                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">¿Tiene sindicato?</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel value="Si" control={<Radio />} label="Si tengo sindicato" />
                                            <FormControlLabel value="No" control={<Radio />} label="No tengo sindicato" />
                                        </RadioGroup>
                                    </FormControl>
                                
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            
                        <Card sx={{ mt: 2 }}
                                style={{ backgroundColor: 'white', borderRadius: '16px', padding: '17px' }}
                            >
                                <CardContent>
                                    <Grid container spacing={2} >
                                        <Grid item md={8} style={{ textAlign: 'left' }}>
                                            <Typography sx={{ fontSize: '20px', color: '#305AD9', fontWeight: '600' }}>
                                                Datos del trabajador
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} style={{ textAlign: 'right' }} >
                                            <StyledAddButton variant='text'
                                                startIcon={<Add />}
                                                disableElevation
                                                size="large"
                                                style={{ backgroundColor: '#2D9CDB', borderRadius: '15px', color: 'white', padding: '8px 25px', }}
                                                onClick={handleClickOpen}
                                            >
                                                Agregar datos
                                            </StyledAddButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions >
                                    
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Nombres y apellidos</TableCell>
                                                    <TableCell align="center">Documento de identidad</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
                                                            {row.address}
                                                        </TableCell>
                                                        
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </CardActions>

                            </Card>
                            

                        </Grid>

                    </Grid>



                    <Grid container justifyContent="flex-end" sx={{ mt: 3,mb: 3 }} >
                        <Grid item md={2} sx={{ mr: 3 }}>
                            {/* <StyledCancelButton>Cancelar</StyledCancelButton> */}
                        </Grid>
                    </Grid>

                </Box>

            </Container>




            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}
                PaperProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        width: "55%",
                    },
                }}
            >
                <DialogTitle> <TitleText text={"Agregar representante de sindicato"}></TitleText><IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton></DialogTitle>

                <DialogContent>
                    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                        <Grid item xs={4} md={4}>
                            <LabelControla text="Documento de identidad"></LabelControla>
                        </Grid>
                        <Grid item xs={8} md={8}>
                        <InputControla inputType={"text"} />
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <LabelControla text="Nombre y apellido"></LabelControla>
                        </Grid>
                        <Grid item xs={8} md={8}>
                            <InputControla inputType={"text"} />
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <LabelControla text="Documento de identidad"></LabelControla>
                        </Grid>
                        <Grid item xs={8} md={8}>
                            <InputControla inputType={"text"} />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center" sx={{ mt: 3 }}>
                        <Grid item md={2} sx={{ mr: 3 }}>
                            <StyledCancelButton>Cancelar</StyledCancelButton>
                        </Grid>
                        <Grid item md={2} >
                            <StyledSaveButton>Agregar</StyledSaveButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>


            <Dialog open={openEdit} onClose={handleCloseEdit} maxWidth="md" fullWidth={true}
                PaperProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        width: "55%",
                    },
                }}
            >
                <DialogTitle> <TitleText text={"Editar mi sede"}></TitleText><IconButton
                    aria-label="close"
                    onClick={handleCloseEdit}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton></DialogTitle>

                <DialogContent>
                    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>

                        <Grid item xs={12} md={12}>
                            <Typography sx={{ fontSize: '20px', color: '#305AD9', fontWeight: '600' }}>
                                Sede principal
                            </Typography>
                        </Grid>

                        <Grid item xs={4} md={4}>
                            <LabelControla text="Nombre"></LabelControla>
                        </Grid>
                        <Grid item xs={8} md={8}>
                        <InputControla inputType={"text"} />
                        </Grid>
                        

                        <Grid item xs={4} md={4}>
                            <LabelControla text="Dirección"></LabelControla>
                        </Grid>
                        <Grid item xs={8} md={8}>
                            <InputControla inputType={"text"} />
                        </Grid>

                    </Grid>

                    <Grid container justifyContent="center" sx={{ mt: 3 }}>
                        <Grid item md={2} sx={{ mr: 3 }}>
                            <StyledCancelButton>Cancelar</StyledCancelButton>
                        </Grid>
                        <Grid item md={2} >
                            <StyledSaveButton>Agregar</StyledSaveButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>



        </Box>

    );

}

export default Company;

