import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getRisst() {
  const url = "document_risst";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createRisst({ develop, title, date, document_url }) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (document_url !== null && document_url !== undefined) {
    document_url = await uploadFileCompanies(
      document_url,
      "documentation/risst/" + year + "/" + month + "/" + day + "/"
    );
  }
  const url = "document_risst";
  const data = {
    develop: develop,
    title: title,
    date: date,
    document_url: document_url,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function editRisst({ idRisst }) {
  const url = "document_risst/" + idRisst;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}
  
async function UpdateRisst({ develop, title, date, document_url, idRisst }) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (document_url !== null && document_url !== undefined) {
    document_url = await uploadFileCompanies(
      document_url,
      "documentation/risst/" + year + "/" + month + "/" + day + "/"
    );
  }
  const url = "document_risst/" + idRisst;
  const data = {
    develop: develop,
    title: title,
    date: date,
    document_url: document_url,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

// TEMPLATE
async function createRisstTemplate({
  code,
  develop,
  revised,
  approved,
  version,
  title,
  document_text,
  idUsersDevelop,
  idUsersRevised,
  idUsersApproved,
  dateDevelop,
  dateRevised,
  dateApproved,
  //
  developSign,
  revisedSign,
  approvedSign,
  //
  developUrlFirm,
  revisedUrlFirm,
  approvedUrlFirm
}) {
  const url = "document_risst_template";
  const data = {
    code: code,
    develop: develop,
    revised: revised,
    approved: approved,
    version: version,
    title: title,
    document_text: document_text,
    id_users_develop: idUsersDevelop,
    id_users_revised: idUsersRevised,
    id_users_approved: idUsersApproved,
    date_develop: dateDevelop,
    date_revised: dateRevised,
    date_approved: dateApproved,
    //
    develop_sign: developSign,
    revised_sign: revisedSign,
    approved_sign: approvedSign,
    //
    develop_url_firm: developUrlFirm,
    revised_url_firm: revisedUrlFirm ,
    approved_url_firm: approvedUrlFirm
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function getRisstTemplate() {
  const url = "document_risst_template";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function editRisstTemplate({ idRisstTemplate }) {
  const url = "document_risst_template/" + idRisstTemplate;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}
async function updateRisstTemplate({
  idRisstTemplate,
  code,
  develop,
  revised,
  approved,
  version,
  title,
  document_text,
  idUsersDevelop,
  idUsersRevised,
  idUsersApproved,
  dateDevelop,
  dateRevised,
  dateApproved,
}) {
  const url = "document_risst_template/" + idRisstTemplate;
  const data = {
    code: code,
    develop: develop,
    revised: revised,
    approved: approved,
    version: version,
    title: title,
    document_text: document_text,
    id_users_develop: idUsersDevelop,
    id_users_revised: idUsersRevised,
    id_users_approved: idUsersApproved,
    date_develop: dateDevelop,
    date_revised: dateRevised,
    date_approved: dateApproved,
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

async function updatestatusRisstTemplate({
  idRisstTemplate,  
  register_status
}) {

  const url = "document_risst_template/" + idRisstTemplate;
  const data = {   
    register_status:register_status   
  };    
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

async function updatestatusRisst({
  idRisst,  
  register_status
}) {
  const url = "document_risst/" + idRisst;
  const data = {   
    register_status:register_status   
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

  // eliminar
  async function deleteRisst({ idRisst }) {
    const url ="document_risst/"+ idRisst;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }

    // eliminar
    async function deleteRisstTemplate({ idRisstTemplate }) {
      const url ="document_risst_template/"+ idRisstTemplate;
      return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "DELETE",
        url: url,
      });
    }
  
function signDevelop({idRisstTemplate}) {
  const url = "document_risst_template/" + idRisstTemplate;
  const data = {
    develop_sign:1
  }

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function signRevised({idRisstTemplate}){
  const url = "document_risst_template/" + idRisstTemplate;
  const data = {
    revised_sign:1
  }

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function signApproved({idRisstTemplate}){
  const url = "document_risst_template/" + idRisstTemplate;
  const data = {
    approved_sign: 1
  }

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

const MyRisst = {
  getRisst,
  createRisst,
  editRisst,
  UpdateRisst,
  updatestatusRisst,
  deleteRisst,

  // template
  createRisstTemplate,
  getRisstTemplate,
  editRisstTemplate,
  updateRisstTemplate,
  updatestatusRisstTemplate,
  deleteRisstTemplate,

  signDevelop,
  signRevised,
  signApproved
};
export default MyRisst;
