import { useEffect,useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from "@mui/material/Stack";
import {
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
 } from '@mui/material';

 import {
    Add,
    Upload
} from "@mui/icons-material";

import BannerControla from '../../../components/cards/bannerControla';
import ButtonControla from '../../../components/buttons/buttonController';
import DialogTitleControla from '../../../components/dialog/dialogTitleControla';
import InputControlaVertical from '../../../components/textfields/inputControlaVertical';
import MyInternalRegulationsCommittee from '../../../api/MyInternalRegulationsCommittee';
import LoadingControla from '../../../components/load/loadingControla';
import DialogConfirmControla from '../../../components/dialog/dialogConfirmControla';

import TableControla from '../../../components/tables/table-controla';
import ListArchived from './components/list-archived';
import ListActives from './components/list-actives';
import { useAppContext } from '../../../context/AppContext';
import MyConfigurationApi from '../../../api/MyConfigurationApi';
import ButtonMailboxControla from '../../../components/buttons/buttonMailboxControla';
import ReturnBack from '../../../components/buttons/returnBackControla';

const purple = "#8B8BD8";

function InternalRegulationsCommittee() {
    const { permissions } = useAppContext();

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    //fin de alerts

    // Dialogos de confirmacion INICIO

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");
    const [idCommitteeRegulations, setIdCommitteeRegulations] = useState();

    const handleOpenModalConfirm = (id, titleText, contentText, buttonTextConfirm, buttonActionType) => {
        setOpenModalConfirm(true);
        setIdCommitteeRegulations(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
    }
    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    };

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case "delete":
                handleDeleteInternalRegulationsCommittee();
                break;
            case "sendToCommittee":
                handleUpdateStatusInternalRegulationsCommittee(2);
                break;
            case "publish":
                handleUpdateStatusInternalRegulationsCommittee(3);
                break;
            case "archived":
                handleUpdateStatusInternalRegulationsCommittee(4);
                break;
            case "unarchived":
                handleUpdateStatusInternalRegulationsCommittee(3);
                break;
        }

    }

    //fin de confirmación

    //abrir modal de creación registro
    const [openModalTemplate, setOpenModalTemplate] = useState(false);
    const [openModalUploadFile, setOpenModalUploadFile] = useState(false);

    const handleOpenModalTemplate = () => {
        setOpenModalTemplate(true);
    }
    const handleCloseModalTemplate = () => {
        setOpenModalTemplate(false);
    }

    //modal subir archivo

    //editar archivo reglamento interno
    const [openModalEditFile, setOpenModalEditFile] = useState(false);

    const handleOpenModalEditFile = (row) => {

        setIdCommitteeRegulations(row.id_committee_regulations)
        setDevelop(nameUserLogged);
        setDateDevelop(row.date_elaboration);
        setTitle(row.title);
        setDocumentUrl(row.document);

        setOpenModalEditFile(true);
    }

    const handleCloseModalEditFile = () => {
        setOpenModalEditFile(false);
    }



    const [develop, setDevelop] = useState("");
    const [dateDevelop, setDateDevelop] = useState("");
    const [title, setTitle] = useState("");
    const [documentUrl, setDocumentUrl] = useState(null);

    const handleOpenModalUploadFile = () => {
        setOpenModalUploadFile(true);
    }


    const handleCloseModalUploadFile = () => {
        setOpenModalUploadFile(false);
    }

    const handleCreateInternalRegulationsCommittee = async (e) => {
        e.preventDefault();
        handleOpenLoading();

        //crear
        const data = {
            code: null,
            version: null,
            title: title,
            produced: idUserLogged,
            reviewed: null,
            approved: null,
            date_elaboration: dateDevelop,
            date_review: null,
            date_approval: null,
            format: null,
            model: 0,
            document: documentUrl
        }

        const resp = await MyInternalRegulationsCommittee.createInternalRegulationsCommittee(data);
        if (resp.success == true) {
            await getInternalRegulationsCommittee();
            handleOpenAlert("Se creó el reglamento con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error al subir el archivo", "error");
        }

        handleCloseModalUploadFile();


    }

    const handleDeleteInternalRegulationsCommittee = async () => {
        handleOpenLoading();
        const resp = await MyInternalRegulationsCommittee.deleteInternalRegulationsCommittee(idCommitteeRegulations);

        if (resp.success == true) {
            await getInternalRegulationsCommittee();
            handleOpenAlert("Se eliminó el reglamento con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error al eliminar el reglamento", "error");
        }

        handleCloseModalConfirm();
    }

    const handleUpdateInternalRegulationsCommittee = async (event) => {
        handleOpenLoading();
        event.preventDefault();

        const data = {
            produced: develop,
            date_elaboration: dateDevelop,
            title: title,
            document: documentUrl
        }
        const resp = await MyInternalRegulationsCommittee.updatedInternalRegulationsCommittee(data, idCommitteeRegulations);

        if (resp.success == true) {
            await getInternalRegulationsCommittee();
            handleOpenAlert("Reglamento interno del comité actualizada exitosamente");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }

        handleCloseModalEditFile();

    }

    const handleUpdateStatusInternalRegulationsCommittee = async (idStatus) => {
        let msgAlert = ""
        idStatus == 2 ? msgAlert = "El reglamento se envío a comité con éxito" : (idStatus == 3 ? (buttonActionType == "unarchived" ? msgAlert = "El reglamento se desarchivó con éxito" : msgAlert = "El reglamento se publicó con éxito") : msgAlert = "El reglamento se archivó con éxito");
        handleOpenLoading();
        const data = {
            id_status: idStatus
        }
        const resp = await MyInternalRegulationsCommittee.updateStatusInternalRegulationsCommittee(idCommitteeRegulations, data);

        if (resp.success == true) {
            await getInternalRegulationsCommittee();
            handleOpenAlert(msgAlert, "success");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
        handleCloseModalConfirm();
    }

    //
    const [regulationsCommittee, setRegulationsCommittee] = useState([]);
    const [rowsActives, setRowsActives] = useState([]);
    const [rowsArchives, setRowsArchives] = useState([]);

    const getInternalRegulationsCommittee = async () => {
        handleOpenLoading();
        setDevelop(nameUserLogged);
        const resp = await MyInternalRegulationsCommittee.getInternalRegulationsCommittee();

        if (resp.success == true) {
            setRegulationsCommittee(resp.data);
            setRowsActives(resp.data.filter((r) => r.id_status != 4));
            setRowsArchives(resp.data.filter((r) => r.id_status == 4));
        } else {
            handleOpenAlert("Ocurrió un error al listar los reglamentos", "error");
        }

        setOpenLoading(false);
    }

    const user = MyConfigurationApi.userData();
    const idUserLogged = user.id_users;
    const nameUserLogged = user.first_name + " " + user.last_name

    useEffect(() => {
        getInternalRegulationsCommittee();
    }, []);

    return (
        <Box>
            <ReturnBack/>

            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />

            <Container>
                <Box sx={{ flexGrow: 1 }}>

                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <ButtonMailboxControla
                                originUrl={"/committee/presentCommittee/internalRegulationsCommittee/"}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <BannerControla
                                image="/assets/images/banners/committee/bannerMeeting.png"
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Reglamento interno del comité"
                            />
                        </Grid>
                    </Grid>

                    <Grid container
                        spacing={2}
                        sx={{ mt: 2 }}
                        justifyContent="flex-start"
                        direction="row"
                        alignItems="center">
                        <Grid item>
                            <ButtonControla
                                roles={permissions.COMITE_COMITE_RIC_ACTIVOS_AGREGAR}
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#1cb591"}
                                backgroundColorHover={"#169073"}
                                textButton={"Agregar usando plantilla"}
                                functionOnClick={handleOpenModalTemplate}
                            />
                        </Grid>
                        <Grid item>
                            <ButtonControla
                                roles={permissions.COMITE_COMITE_RIC_ACTIVOS_SUBIR_ARCHI}
                                iconButton={<Upload sx={{ color: "white" }} />}
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#1976d2"}
                                textButton={"Subir mi documento"}
                                functionOnClick={handleOpenModalUploadFile}
                            />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <TableControla
                                loading={openLoading}
                                tabs={[
                                    {
                                        label: "Activos",
                                        columnNames: ["Nombre del documento", "Tipo", "Estado"]
                                    },
                                    {
                                        label: "Archivados",
                                        columnNames: ["Nombre del documento", "Tipo", "Estado"]
                                    }
                                ]}
                            >
                                <ListActives
                                    rowsActives={rowsActives}
                                    colorCell={purple}
                                    idUserLogged={idUserLogged}
                                    handleOpenModalConfirm={handleOpenModalConfirm}
                                    handleOpenModalEditFile={handleOpenModalEditFile}
                                />
                                <ListArchived
                                    rowsArchives={rowsArchives}
                                    colorCell={purple}
                                    idUserLogged={idUserLogged}
                                    handleOpenModalConfirm={handleOpenModalConfirm}
                                />

                            </TableControla>
                        </Grid>

                    </Grid>
                </Box>

            </Container>

            {/* modal de creación template */}
            <Dialog
                open={openModalTemplate}
                onClose={handleCloseModalTemplate}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText={"Te presentamos unas plantillas para facilitar la elaboración de tu documento"}
                    functionOnClose={handleCloseModalTemplate}
                />
                <DialogContent sx={{ paddingTop: "0" }}>

                </DialogContent>
                <DialogActions>
                    <Stack direction={"row"} spacing={2}>
                        <ButtonControla
                            textButton={"Cancelar"}
                            backgroundColor={"#CBCBFF"}
                            backgroundColorHover={"#CBCBFF"}
                            typeButton={"button"}
                            functionOnClick={handleCloseModalTemplate}
                        />
                        <ButtonControla
                            textButton={"Ver"}
                            backgroundColor={"#169073"}
                            backgroundColorHover={"#1BAA88"}
                            typeButton={"button"}
                            isNavegationOrigin={true}
                            url={"/committee/presentCommittee/internalRegulationsCommittee/templateMenu"}
                        />

                    </Stack>

                </DialogActions>

            </Dialog>

            {/* modal subir archivo */}
            <Dialog
                open={openModalUploadFile}
                onClose={handleCloseModalUploadFile}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText={"Reglamento Interno del Comité"}
                    functionOnClose={handleCloseModalUploadFile}
                />
                <form onSubmit={handleCreateInternalRegulationsCommittee}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Elaborado por:"}
                                    inputType={"text"}
                                    inputValue={setDevelop}
                                    value={develop}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Fecha de elaboración:"}
                                    inputType={"date"}
                                    inputValue={setDateDevelop}
                                    value={dateDevelop}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Título documento"}
                                    inputType={"text"}
                                    inputValue={setTitle}
                                    value={title}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Subir Archivo"}
                                    inputType={"file"}
                                    inputValue={setDocumentUrl}
                                    value={documentUrl}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                backgroundColorHover={"#CBCBFF"}
                                typeButton={"button"}
                                functionOnClick={handleCloseModalUploadFile}
                            />
                            <ButtonControla
                                textButton={"Guardar"}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                typeButton={"submit"}
                            // functionOnClick={() => { }}
                            />
                        </Stack>

                    </DialogActions>

                </form>
            </Dialog>

            {/* modal para editar archivo */}
            <Dialog
                open={openModalEditFile}
                onClose={handleCloseModalEditFile}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText={"Reglamento Interno del Comité"}
                    functionOnClose={handleCloseModalEditFile}
                />
                <form onSubmit={handleUpdateInternalRegulationsCommittee}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Elaborado por:"}
                                    inputType={"text"}
                                    inputValue={setDevelop}
                                    value={develop}
                                    modalType={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Fecha de elaboración:"}
                                    inputType={"date"}
                                    inputValue={setDateDevelop}
                                    value={dateDevelop}
                                    modalType={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Título documento"}
                                    inputType={"text"}
                                    inputValue={setTitle}
                                    value={title}
                                    modalType={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Subir Archivo"}
                                    inputType={"file"}
                                    inputValue={setDocumentUrl}
                                    value={documentUrl}
                                    modalType={true}
                                />
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                backgroundColorHover={"#CBCBFF"}
                                typeButton={"button"}
                                functionOnClick={handleCloseModalEditFile}
                            />
                            <ButtonControla
                                textButton={"Actualizar"}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                typeButton={"submit"}
                            />
                        </Stack>

                    </DialogActions>

                </form>
            </Dialog>

        </Box>
    );

}
export default InternalRegulationsCommittee;