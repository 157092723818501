import MyConfigurationApi from "./MyConfigurationApi";

function getContractors() {
    const url = 'contractors';
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: 'GET',
        url: url
    });
}

function getContractorById(id) {
    const url = 'contractors/' + id;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: 'GET',
        url: url
    });
}

function postContractor(data) {
    const url = 'contractors';
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: 'POST',
        url: url
    });
}

function updateContractorById(data, id) {
    const url = 'contractors/' + id;
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: 'PUT',
        url: url
    });
}

function deleteContractorById(id) {
    const url = 'contractors/' + id;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: 'DELETE',
        url: url
    });
}

const MyContractors = {
    getContractors,
    getContractorById,
    postContractor,
    updateContractorById,
    deleteContractorById
};

export default MyContractors;