import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";

import {
    BorderColorOutlined,
    Delete
  } from "@mui/icons-material";

function ListModels(props) {
    const {
        rowsModels,
        documentType,
        colorCell,
        handleOpenModalConfirm
    } = props
    return (
        <TableBody>
            {rowsModels.map((rowsModels, index) => (
                <TableRow hover key={rowsModels.id_petar}>
                    <TableCell sx={{ color: colorCell }}>
                        {index + 1}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {rowsModels.name}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {rowsModels.timestamp_created ? new Date(rowsModels.timestamp_created).toLocaleString('es-PE', { timeZone: 'America/Lima' }) : ''}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }} align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                roles={[1, 7, 8, 15]}
                                icon={
                                    <BorderColorOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                isNavegationOrigin={true}
                                directionUrl={"/documentation/documents/procedures/petar/editModel?id_petar=" + rowsModels.id_petar}                         
                            />
                            <ButtonIconControla
                                roles={[1, 7, 8, 15]}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() =>
                                    handleOpenModalConfirm(
                                        rowsModels.id_petar,
                                        "¿Esta seguro de eliminar este documento?",
                                        'Una vez eliminado no podrá recuperar el PETAR:  "' +
                                        rowsModels.name +
                                        '"',
                                        "Si, eliminar",
                                        "delete"
                                    )
                                }
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListModels;