import React, { Component, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { templateGeneral } from "./templateGeneral";
import "./editorControlaStyle.css"
import ValidationEditByRoles from "../../utils/validation_edit_roles";

export const EditorInternalRegulationsCommitteeGeneral = ({ dataEdit, defaultTemplate, handleDataEditor = alert,roles=[], ...rest })=>{
    const template = templateGeneral ;

    //prueba de roles para habilitar o deshabilitar editar componente
    const isEdit = roles.length == 0 ? true : ValidationEditByRoles(roles);

    return (
        <div className="Container-editor">
            <CKEditor
                editor={ClassicEditor}
                {...rest}
                data={dataEdit === null||dataEdit === ""  ? template : dataEdit}
                disabled={isEdit? false : true}
                onReady={(editor) => {
                    const data = editor.getData();
                    handleDataEditor(data);
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    handleDataEditor(data);
                }}

                onBlur={(event, editor) => {
                    const data = editor.getData();
                    handleDataEditor(data);
                }}
                onFocus={(event, editor) => {
                    const data = editor.getData();
                    handleDataEditor(data);
                }}
            />
        </div>
    );
};