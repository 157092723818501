import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import { Add, Edit } from "@mui/icons-material";
import SubtitleText from "../../../../components/text/subtitleText";
import TitleText from "../../../../components/text/titleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import MyPetar from "../../../../api/MyPetar";
import ButtonControla from "../../../../components/buttons/buttonController";
import LoadingControla from "../../../../components/load/loadingControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import SearchEppsControla from "../../../../components/search/searchEppsControla";
import MySessionStorage from "../../../../api/MySessionStorage";
import SelectControla from "../../../../components/selects/selectControla";
import MyAreas from "../../../../api/MyAreas";
import { useAppContext } from "../../../../context/AppContext";
import { validationEditInputsByWorker } from "../../../../utils/validation_edit";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const grey = "#9191B0";
const lilac = "#D1D1E9";

const StyledCancelButton = styled(Button)({
  textTransform: "none",
  borderRadius: 14,
  backgroundColor: lilac,
  color: "black",
  width: "100%",
});

const StyledTypography = styled(Typography)({
  color: "#305AD9",
  fontSize: 16,
  fontWeight: 600,
  gap: "2%",
  padding: "0.5em",
});

function EditModel() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const id_petar = params.get("id_petar");
  const isWorker = validationEditInputsByWorker()

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [area, setArea] = useState("");
  const [place, setPlace] = useState("");
  const [hourStart, setHourStart] = useState("");
  const [hourFinish, setHourFinish] = useState("");
  const [description, setDescription] = useState("");
  const [tools, setTools] = useState("");
  const [procedure, setProcedure] = useState("");
  const [observation, setObservation] = useState("");

  const [rowsEppsPetar, setRowsEppsPetar] = useState([]);

  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");
  const [rowsAreaWorkerInvolved, setRowsAreaWorkerInvolved] = useState([]);

  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setRowsAreaWorkerInvolved(response);
    return response;
  };

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // Pantallas de carga y alertas FIN

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [handleActionConfirm, setHandleActionConfirm] = useState();

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonAction
  ) => {
    // set(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setOpenModalConfirm(true);
    setHandleActionConfirm(buttonAction);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  const handleStorePetarModel = async () => {
    handleOpenLoading();
    const response = await MyPetar.storePetarModel({ id_petar: id_petar });

    if (response.success !== false) {
      // handleOpenAlert(response.msg, 'success');
      MySessionStorage.setSession("messageAlert", response.msg);
      MySessionStorage.setSession("typeAlert", "success");

      navigate("/editPetar?id_petar=" + response.id_petar);
    } else {
      handleOpenAlert("Hubo un error al actualizar", "success");
    }
    return response;
  };

  const [openModalEpps, setOpenModalEpps] = useState(false);
  const [rowsEpps, setRowsEpps] = useState([]);

  const handleOpenModalEpps = () => {
    handlePetarNoEpps();

    setOpenModalEpps(true);
  };

  const handleCloseModalEpps = () => {
    setOpenModalEpps(false);
  };

  const handleSelectEpps = async (idEpps) => {
    handleOpenLoading();
    const response = await MyPetar.eppsStore({ id_petar, id_epps: idEpps });

    if (response.success !== false) {
      setRowsEpps([]);
      setOpenModalEpps(false);
      handlePetarNoEpps();
      handlePetarEpps();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Hubo un error", "error");
    }
    return response;
  };

  const handleSelectDeleteEpps = async (idPetarEpps) => {
    handleOpenLoading();
    const response = await MyPetar.eppsDelete({ id_petar_epps: idPetarEpps });

    if (response.success !== false) {
      setRowsEpps([]);
      setOpenModalEpps(false);
      handlePetarNoEpps();
      handlePetarEpps();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Hubo un error", "error");
    }
    return response;
  };

  const handlePetarNoEpps = async () => {
    const response = await MyPetar.eppsNoPetarGet({ id_petar });

    if (response.success === false) {
      alert("Error");
    } else {
      setRowsEpps(response);
    }
    return response;
  };

  const handlePetarEpps = async () => {
    const response = await MyPetar.eppPetarGet({ id_petar });

    if (response.success === false) {
      alert("Error listando los epps");
    } else {
      setRowsEppsPetar(response);
    }
    return response;
  };

  const handleEditPetar = async () => {
    const response = await MyPetar.editPetar({ id_petar });
    console.log(response);
    if (response.success === false) {
      alert("Error");
    } else {
      setName(response.name);
      setCode(response.code);
      setArea(response.area);
      setPlace(response.place);
      setHourStart(response.start_time === null ? "" : response.start_time);
      setHourFinish(response.end_time === null ? "" : response.end_time);
      setDescription(response.description);
      setTools(response.tools);
      setProcedure(response.procedure);
      setObservation(response.observation);
    }
    return response;
  };

  useEffect(() => {
    handleEditPetar();
    handlePetarEpps();
    handleGetAreas();
  }, []);

  const handlePutUpdatePetar = async () => {
    handleOpenLoading();
    const response = await MyPetar.updatePetar({
      id_petar,
      name,
      code,
      area,
      place,
      start_time: hourStart,
      end_time: hourFinish,
      description,
      observation,
      tools,
      procedure,
    });
    if (response.success !== false) {
      handleOpenAlert(response.msg, "success");
      // return (window.location.href =
      //   "/documentation/documents/procedures/petar");
    } else {
      handleOpenAlert("Hubo un error al actualizar", "error");
    }
    return response;
  };

  return (
    <>
      <Box sx={{ height: "100%" }}>

        <ReturnBack/>

        <LoadingControla
          openLoading={openLoading}
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          messageAlert={messageAlert}
          typeAlert={typeAlert}
        />

        <DialogConfirmControla
          openModalConfirm={openModalConfirm}
          handleCloseModalConfirm={handleCloseModalConfirm}
          titleText={titleText}
          contentText={contentText}
          buttonTextConfirm={buttonTextConfirm}
          handleActionConfirm={handleActionConfirm}
        />

        <SearchEppsControla
          openModalEpps={openModalEpps}
          handleCloseModalEpps={handleCloseModalEpps}
          handleClickSelectEpps={handleSelectEpps}
          rowsEpps={rowsEpps}
        />

        <Container sx={{ paddingBottom: 4 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              alignItems="center"
            >
              <Grid item md={12}>
                <SubtitleText
                  text={"Procedimientos"}
                  color={grey}
                ></SubtitleText>
              </Grid>
              <Grid item md={10}>
                <TitleText
                  text={"Permiso Escrito de trabajo de alto riesgo (PETAR) "}
                />
              </Grid>
              <Grid item md={2}>
                <img src="\assets\icons\companylogo.png" alt="logo" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={9} xl={9}>
                <InputControlaVertical
                  text={"Nombre del PETAR"}
                  inputType={"text"}
                  inputValue={setName}
                  disabled={isWorker}
                  modalType={false}
                  value={name}
                />
                {/* <StyledTypography>Nombre del ATS</StyledTypography> */}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Código"}
                  inputType={"text"}
                  inputValue={setCode}
                  modalType={false}
                  disabled={isWorker}
                  value={code}
                />
                {/* <StyledTextField /> */}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <SelectControla
                  text="Área:"
                  inputValue={setArea}
                  modalType={false}
                  required
                  value={area}
                  disabled={isWorker}
                  childrenRows={rowsAreaWorkerInvolved.map((rows) => (
                    <MenuItem key={rows.id_areas} value={rows.id_areas}>
                      {rows.area}
                    </MenuItem>
                  ))}
                />

                {/* <StyledTypography>Área</StyledTypography> */}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Lugar"}
                  inputType={"text"}
                  inputValue={setPlace}
                  disabled={isWorker}
                  modalType={false}
                  value={place}
                />

                {/* <StyledTypography>Lugar</StyledTypography> */}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Hora de Inicio"}
                  inputType={"time"}
                  inputValue={setHourStart}
                  modalType={false}
                  disabled={isWorker}
                  value={hourStart}
                />

                {/* <StyledTypography>Hora de Inicio</StyledTypography> */}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Hora de Fin"}
                  inputType={"time"}
                  inputValue={setHourFinish}
                  modalType={false}
                  disabled={isWorker}
                  value={hourFinish}
                />
                {/* <StyledTypography>Hora de Fin</StyledTypography> */}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción del Trabajo"}
                  inputType={"textArea"}
                  inputValue={setDescription}
                  modalType={false}
                  disabled={isWorker}
                  value={description}
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <StyledTypography
                  sx={{
                    padding: "8px",
                    fontSize: "20px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  EPPS
                </StyledTypography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                <ButtonControla
                  roles={permissions.DOC_HG_PROC_PETAR_MOD_AGREGAR_EPP}
                  iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Agregar EPP"}
                  disabled={isWorker}
                  functionOnClick={handleOpenModalEpps}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper sx={{ overflow: "hidden" }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ width: "100%", margin: "auto" }}
                  >
                    {rowsEppsPetar.map((rowsEpps) => (
                      <Grid
                        item
                        key={rowsEpps.id_epps}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                        sx={{ p: 2 }}
                      >
                        <Card
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: 80,
                            "&:hover": {
                              cursor: "pointer",
                              border: "1px solid #0F1255",
                              boxShadow: "0px 4px #0F1255",
                            },
                          }}
                          onClick={() => {
                            handleSelectDeleteEpps(rowsEpps.id_petar_epps);
                          }}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <Typography sx={{ fontSize: "14px" }}>
                                {rowsEpps.epp}
                              </Typography>
                            </CardContent>
                          </Box>
                          <CardMedia
                            component="img"
                            sx={{
                              maxWidth: 80,
                              marginLeft: 0,
                              maxHeight: 80,
                              objectFit: "contain",
                            }}
                            image={rowsEpps.photo_url}
                            alt="Live from space album cover"
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Herramientas, equipo y material:"}
                  inputType={"textArea"}
                  inputValue={setTools}
                  modalType={false}
                  disabled={isWorker}
                  value={tools}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Procedimiento:"}
                  inputType={"textArea"}
                  disabled={isWorker}
                  inputValue={setProcedure}
                  modalType={false}
                  value={procedure}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Observaciones:"}
                  inputType={"textArea"}
                  inputValue={setObservation}
                  modalType={false}
                  value={observation}
                  disabled={isWorker}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                  <Box>
                    <ButtonControla
                      roles = {permissions.DOC_HG_PROC_PETAR_MOD_REGRESAR}
                      backgroundColor={"#CBCBFF"}
                      textButton={"Regresar"}
                      backgroundColorHover={"#CBCBFF"}
                      typeButton={"button"}            
                      isNavegationOrigin={true}
                      url={"/documentation/documents/procedures/petar"}
                    />
                  </Box>
                  <Box>
                    <ButtonControla
                      roles={permissions.DOC_HG_PROC_PETAR_MOD_ACTUALIZAR_MODELO}
                      iconButton={<Edit sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Actualizar modelo"}
                      typeButton={"button"}
                      functionOnClick={handlePutUpdatePetar}
                    />
                  </Box>
                  <Box>
                    <ButtonControla
                      roles={permissions.DOC_HG_PROC_PETAR_MOD_USAR_MODELO}
                      backgroundColor={"#F2994A"}
                      backgroundColorHover={"#FF881E"}
                      textButton={"Usar modelo"}
                      typeButton={"button"}
                      functionOnClick={handleStorePetarModel}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default EditModel;
