import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Grid, Button, Typography, TextField, } from '@mui/material';
import { Info } from '@mui/icons-material';
import { styled } from '@mui/system';
import SubtitleText from '../components/text/subtitleText';
import TitleText from '../components/text/titleText';

const colorTitle = "#9191B0";
const green = "#2C8C75";
const lilac = "#D1D1E9";

const StyledSaveButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: green,
    color: 'white',
    width: "100%"
});

const StyledCancelButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: lilac,
    color: 'black',
    width: "100%"
});

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
});

const StyledTextField = styled(TextField)({
    //label
    "& label.Mui-focused": {
        color: "#305AD9",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#305AD9",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "white",
            background: "white",
            borderRadius: " 16px"
        },
        "&:hover fieldset": {
            borderColor: "white",
            background: "white"
        },
        "&.Mui-focused fieldset": {
            borderColor: "white",
            background: "white"
        },
        "& .MuiFilledInput-root": {
            background: "white",
        },
    },
    width: "100%",
});

function ScheduleCalendarThree() {
    return (

        <Box sx={{ display: "flex", height: '100%' }}>

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={12} md={8}>
                            <SubtitleText text={"Cronogramas"} color={colorTitle} ></SubtitleText>
                            <TitleText text={"Calendario de programación"}></TitleText>
                            {/* <DocBanner color2='#2F67BC' color1='#8FBCFF' text='Accidentes de trabajo' image={imageBanner} /> */}
                        </Grid>
                        <Grid item xs={12} md={4} justifyContent="flex-end">
                            <img src={"/assets/images/logoempresaejemplo.png"} style={{ width: "150px", textAlign: "left" }} alt='logo' />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1, mt: 3 }}>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item md={4}>
                            <StyledTypography>Fecha de la elección, lugar y horario</StyledTypography>
                        </Grid>
                        <Grid item md={1}>
                            <Info />
                        </Grid>
                        <Grid item md={3}>
                            <StyledTextField></StyledTextField>
                        </Grid>
                        <Grid item md={4}>

                        </Grid>
                        <Grid item md={4}>
                            <StyledTypography>Personal habilitado para elegir a los/las representates de los/las trabajadores</StyledTypography>
                        </Grid>
                        <Grid item md={1}>
                            <Info />
                        </Grid>
                        <Grid item md={3}>
                            <StyledTextField></StyledTextField>
                        </Grid>
                        <Grid item md={4}>

                        </Grid>
                        <Grid item md={4}>
                            <StyledTypography>Publicación de plazo para las tachas e impugnaciones</StyledTypography>
                        </Grid>
                        <Grid item md={1}>
                            <Info />
                        </Grid>
                        <Grid item md={3}>
                            <StyledTextField></StyledTextField>
                        </Grid>
                        <Grid item md={4}>

                        </Grid>
                        <Grid item md={8}>
                            <StyledTypography>
                                Ley No 29783, Ley de Seguridad y Salud en el trabajo.
                                Decreto Supremo No 005-2012-TR, Reglamento de la Ley No 29783, Ley
                                de Seguridad y Salud en el trabajo y sus modificadoras.
                                Opcional: Adjuntar modelo de carta de postulación.
                            </StyledTypography>
                        </Grid>
                        <Grid item md={4}>
                            <StyledTypography>
                                __ , __de _______ de 20..
                            </StyledTypography>
                        </Grid>
                        <Grid container item md={12} gap={2} direction="row" justifyContent="center" alignItems="center">
                            <Grid item >
                                <StyledTypography>Insertar Representante</StyledTypography>
                            </Grid>
                            <Grid item>
                                <StyledTextField />
                            </Grid>
                            <Grid item>
                                <StyledTextField />
                            </Grid>

                        </Grid>
                        <Grid container item md={12} gap={2} direction="row" justifyContent="center" alignItems="center">
                            <Grid item md={1}>
                                <StyledCancelButton>Cancelar</StyledCancelButton>
                            </Grid>
                            <Grid item md={1}>
                                <StyledSaveButton>Guardar</StyledSaveButton>
                            </Grid>


                        </Grid>

                    </Grid>
                </Box>
            </Container>
        </Box>

    );

}

export default ScheduleCalendarThree;

