import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import {
    Unarchive
} from "@mui/icons-material";

import { useAppContext } from "../../../../../context/AppContext";

function ListArchived(props) {
    const {permissions} = useAppContext();
    const {
        rowsArchived,
        documentType,
        colorCell,
        handleOpenModalConfirm
    } = props
    console.log(documentType)
    return (
        <TableBody
            sx={{ display: documentType !== 2 ? "none" : "" }}
        >
            {rowsArchived.map((rowsArchives) => (
                <TableRow
                    hover
                    key={rowsArchives.id_pets}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell sx={{ color: colorCell }}>
                        {rowsArchives.name}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {rowsArchives.document_url != null &&
                            rowsArchives.document_url != undefined
                            ? "Archivo"
                            : "Sistema"}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {rowsArchives.status}
                    </TableCell>
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {rowsArchives.document_url !== null ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_HG_PROC_PETS_ARCHI_VER}
                                    icon={
                                        <VisibilityOutlined
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#305AD9"}
                                    backgroundColorHover={"#0E4AFF"}
                                    textTooltip={"Ver"}
                                    directionUrl={rowsArchives.document_url}
                                    directionTarget={"_blank"}
                                />
                            ) : rowsArchives.id_pets_statuses !== 1 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_HG_PROC_PETS_ARCHI_VER}
                                    icon={
                                        <VisibilityOutlined
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#305AD9"}
                                    backgroundColorHover={"#0E4AFF"}
                                    textTooltip={"Ver"}                                
                                    isNavegationOrigin={true}
                                    directionUrl={"/documentation/documents/procedures/pets/edit?idPets=" + rowsArchives.id_pets + "&readOnly=" + true}
                                />
                            ) : (
                                ""
                            )}
                            <ButtonIconControla
                                roles={permissions.DOC_HG_PROC_PETS_ARCHI_DESARCHIVAR}
                                icon={<Unarchive sx={{ color: "white" }} />}
                                backgroundColor={"#F2994A"}
                                backgroundColorHover={"#FF881E"}
                                textTooltip={"Desarchivar"}
                                functionOnClick={() =>
                                    handleOpenModalConfirm(
                                        rowsArchives.id_pets,
                                        "¿Estás seguro de desarchivar el documento?",
                                        'PETS a desarchivar:  "' +
                                        rowsArchives.name +
                                        '"',
                                        "Sí, desarchivar",
                                        "unarchived"
                                    )
                                }
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>

    )

}
export default ListArchived;