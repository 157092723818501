import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import {
    BorderColorOutlined,
    Delete
} from "@mui/icons-material";

import ButtonIconControla from "../../components/buttons/buttonIconControla";
import { useAppContext } from "../../context/AppContext";

function ListModels(props) {
    const { permissions } = useAppContext();
    const {
        rowsModels,
        colorCell,
        handleOpenModalConfirm
    } = props;

    return (
        <TableBody>
            {rowsModels.map((row) => (
                <TableRow
                    hover
                    key={row.id_standards}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell sx={{ color: colorCell }}>
                        {row.document_title}
                    </TableCell>
                    <TableCell>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                roles={permissions.DOC_HG_STANDAR_MOD_EDIT}
                                icon={
                                    <BorderColorOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                isNavegationOrigin={true}
                                directionUrl={`/documentation/documents/standards/model?id_standard=${row.id_standards}`}
                            />

                            <ButtonIconControla
                                roles={permissions.DOC_HG_STANDAR_MOD_ELIMINAR}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => handleOpenModalConfirm(
                                    "¿Estás seguro de eliminar el modelo?",
                                    `Una vez eliminado no se podrá recuperar todo lo incluido en este modelo Estándar: "${row.document_title}"`,
                                    "Si, Eliminar",
                                    "deleteModel",
                                    row.id_standards
                                )}
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))
            }
        </TableBody>
    );
}
export default ListModels;