import MyConfigurationApi from "../api/MyConfigurationApi";

function validationEditInputsByWorker () {
  const userData = MyConfigurationApi.userData();
  let usersRoles = userData.roles ?? [];

  const isWorker = usersRoles.length === 1 && usersRoles[0] === 9;

  return isWorker;
}

export {
  validationEditInputsByWorker
}