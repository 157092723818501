import MyConfigurationApi from "../api/MyConfigurationApi";

function validationRoles (roles) {
  
  const userData = MyConfigurationApi.userData();
  let usersRoles = userData.roles ?? [];
  let myRoles = roles.length === 0 ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,14,15] : roles;

  const existeRoles = myRoles.some(elemento => usersRoles.includes(elemento));

  return existeRoles;
}

export {
  validationRoles
}