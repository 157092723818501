import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import {
    Unarchive,
    VisibilityOutlined,
} from "@mui/icons-material";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import { useAppContext } from "../../../../context/AppContext";

function ListArchives(props) {
    const { permissions } = useAppContext();
    const {
        rowsArchived,
        handleOpenModalConfirm,
        colorCell
    } = props;

    return (
        <TableBody>
            {rowsArchived.map((rowsArchived) => (
                <TableRow
                    hover
                    key={rowsArchived.id_programs}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell sx={{ color: colorCell }} align="left">{rowsArchived.name}</TableCell>
                    <TableCell sx={{ color: colorCell }} align="center">
                        {rowsArchived.document_url != null &&
                            rowsArchived.document_url != undefined
                            ? "Archivo"
                            : "Sistema"}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }} align="center" component="th" scope="row">
                        {rowsArchived.status}
                    </TableCell>

                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {rowsArchived.document_url !== null ? (
                                <ButtonIconControla
                                    icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                    backgroundColor={"#305AD9"}
                                    backgroundColorHover={"#0E4AFF"}
                                    textTooltip={"Ver"}
                                    directionUrl={rowsArchived.document_url}
                                    directionTarget={"_blank"}
                                />
                            ) : rowsArchived.id_programs_statuses !== 1 ? (
                                <ButtonIconControla
                                    icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                    backgroundColor={"#305AD9"}
                                    backgroundColorHover={"#0E4AFF"}
                                    textTooltip={"Ver"}
                                    isNavegationOrigin={true}
                                    directionUrl={"/documentation/programs/sso/editprograms?id_programs=" + rowsArchived.id_programs + "&readOnly=" + true}
                                />
                            ) : (
                                ""
                            )}
                            <ButtonIconControla
                                roles={permissions.DOC_PROG_PASSO_ARCHI_DESARCHIVAR}
                                icon={<Unarchive sx={{ color: "white" }} />}
                                backgroundColor={"#F2994A"}
                                backgroundColorHover={"#FF881E"}
                                textTooltip={"Desarchivar"}
                                functionOnClick={() =>
                                    handleOpenModalConfirm(
                                        rowsArchived.id_programs,
                                        "¿Estás seguro de desarchivar el programa?",
                                        'Programa a desarchivar:  "' +
                                        rowsArchived.name +
                                        '"',
                                        "Si, desarchivar",
                                        "unarchived"
                                    )
                                }
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
}
export default ListArchives;