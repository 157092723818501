import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function healthEntity() {
    const url = 'companies/health/entity/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function regimes() {
    const url = 'companies/regimes/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function types() {
    const url = 'companies/types/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function activities() {
    const url = 'companies/activites/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function sectorsNotCompanies() {
    const url = 'companies/sectors/companies/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function sectorsCompanies() {
    const url = 'companies/sectors/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function storeSectorsCompanies({ id_companies_sectors }) {
    const url = 'companies/sectors/store';
    const data = {
        'id_companies_sectors': id_companies_sectors
    };
    console.log(data);
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function deleteSectorsCompanies({ id_companies_sectors_rels_companies }) {
    const url = 'companies/sectors/delete';
    const data = {
        'id_companies_sectors_rels_companies': id_companies_sectors_rels_companies
    };
    console.log(data);
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'DELETE', url: url });
}


function company() {
    const url = 'company/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

async function updateGeneralInformation({ name, ruc, address, department, province, district, urbanization, phone, web, email, logo_url, id_companies_regimes, id_companies_types, id_activities_primaries, id_activities_secondaries, syndicate, id_users_syndicate_representation, id_users_legally_representative, logo_isSaved }) {

    if (logo_url !== null && logo_url !== undefined && logo_isSaved == false) {
        logo_url = await uploadFileCompanies(logo_url, '/logo/');
    }

    const data = { 'name': name, 'ruc': ruc, 'address': address, 'department': department, 'province': province, 'district': district, 'urbanization': urbanization, 'phone': phone, 'web': web, 'email': email, 'logo_url': logo_url, 'id_companies_regimes': id_companies_regimes, 'id_companies_types': id_companies_types, 'id_companies_activities_primaries': id_activities_primaries, 'id_companies_activities_secondaries': id_activities_secondaries, 'syndicate': syndicate, 'id_users_syndicate_representation': id_users_syndicate_representation, 'id_users_legally_representative': id_users_legally_representative };
    
    const url = 'company/update/generalinformation';
    const apiResult = await MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });

    const result = {
        ...apiResult,
        logo_url: logo_url
    };

    return result;
}

function updateOrganization({ operating_license, operating_license_date, itse, itse_emision_date, itse_expiration_date, hire_staff, personal_highlights, id_users_security_boss, id_users_medical_boss }) {

    const data = { 'operating_license': operating_license, 'operating_license_date': operating_license_date, 'itse': itse, 'itse_emision_date': itse_emision_date, 'itse_expiration_date': itse_expiration_date, 'hire_staff': hire_staff, 'personal_highlights': personal_highlights, 'id_users_security_boss': id_users_security_boss, 'id_users_medical_boss': id_users_medical_boss };
    const url = 'company/update/organization';
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function certificationsCompanies() {
    const url = 'companies/certifications/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function certificationsNotCompanies() {
    const url = 'companies/certifications/companies/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function storeCertificationsCompanies({ id_companies_certifications }) {
    const data = {
        'id_companies_certifications': id_companies_certifications
    }
    const url = 'companies/certifications/store';
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function deleteCertificationsCompanies({ id_companies_certifications_rels_companies }) {
    const url = 'companies/certifications/delete/' + id_companies_certifications_rels_companies;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

/*creación de empresa */
function getCompany (){
    const url = 'companies/get';
    return MyConfigurationApi.connectApi(
        { dataJson: null, 
          method: 'GET', 
          url: url 
        });
}

function postCompany (data){
    const url = 'company/create';
    return MyConfigurationApi.connectApi(
        { dataJson: data, 
          method: 'POST', 
          url: url 
        });
}

function updateCompany (data, idCompany){
    const url = 'company/update-company/' + idCompany;
    return MyConfigurationApi.connectApi(
        { dataJson: data, 
          method: 'PUT', 
          url: url 
        });
}

function deleteCompany (idCompany){
    const url = 'company/remove/' + idCompany;
    return MyConfigurationApi.connectApi(
        { dataJson: null, 
          method: 'DELETE', 
          url: url 
        });
}

const MyCompanies = {
    healthEntity, 
    regimes, 
    types, 
    activities, 
    company, 
    sectorsNotCompanies, 
    sectorsCompanies, 
    storeSectorsCompanies, 
    deleteSectorsCompanies, 
    updateGeneralInformation, 
    updateOrganization, 
    certificationsCompanies, 
    certificationsNotCompanies, 
    storeCertificationsCompanies, 
    deleteCertificationsCompanies, 
    getCompany, 
    postCompany, 
    updateCompany, 
    deleteCompany
}

export default MyCompanies;
