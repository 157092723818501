import MyConfigurationApi from "../api/MyConfigurationApi";

export default function ValidationEditByRoles (rolesToEdit) {
    //verificar si es de lectura o editar vista
    const params = new URLSearchParams(window.location.search);
    const readOnly = params.get("readOnly") == "true" ? true : false;

    const rolesUserLogged = MyConfigurationApi.userData().roles;
    let isEdit = false;

    rolesToEdit?.forEach(rol => {
        if(rolesUserLogged.some((r)=> r == rol)){
            isEdit= true
        }
    });

    //verifica si esta en editar o lectura 
    isEdit = !readOnly && isEdit ? true : false;

    return isEdit;
}
