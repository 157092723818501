
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {Stack} from "@mui/material";
import {
    Archive,
    BorderColorOutlined,
    Delete,
    FileUploadOutlined,
    ForumOutlined,
    Send,
    ThumbDownAlt,
    ThumbUpAlt,
    VisibilityOutlined,
} from "@mui/icons-material";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import { useAppContext } from "../../../../context/AppContext";

function ListActives(props) {
    const {permissions} = useAppContext();
    const {
        rowsNotArchived,
        handleOpenModalConfirm,
        handleOpenModalEditFile,
        colorcell,
    } = props;

    return (
        <TableBody>
            {rowsNotArchived.map((rowsNotArchived) => (
                <TableRow
                    hover
                    key={rowsNotArchived.id_programs}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell sx={{ color: colorcell }} align="left">
                        {rowsNotArchived.name}
                    </TableCell>
                    <TableCell sx={{ color: colorcell }} align="center">
                        {rowsNotArchived.document_url != null &&
                            rowsNotArchived.document_url != undefined
                            ? "Archivo"
                            : "Sistema"}
                    </TableCell>
                    <TableCell sx={{ color: colorcell }} align="center" component="th" scope="row">
                        {rowsNotArchived.status}
                    </TableCell>
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {rowsNotArchived.id_programs_statuses === 1 &&
                                rowsNotArchived.document_url !== null ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_PROG_CAPACIT_VIGE_EDIT}
                                    icon={ <BorderColorOutlined sx={{ color: "white" }}/>}
                                    backgroundColor={"#2D9CDB"}
                                    backgroundColorHover={"#33AEF4"}
                                    textTooltip={"Editar"}
                                    functionOnClick={() =>
                                        handleOpenModalEditFile(
                                            rowsNotArchived.id_programs,
                                            rowsNotArchived.code,
                                            rowsNotArchived.version,
                                            rowsNotArchived.name,
                                            rowsNotArchived.date_created,
                                            rowsNotArchived.year
                                        )
                                    }
                                />
                            ) : (rowsNotArchived.id_programs_statuses === 1 || rowsNotArchived.id_programs_statuses === 2 || rowsNotArchived.id_programs_statuses === 3) &&
                                rowsNotArchived.document_url === null ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_PROG_CAPACIT_VIGE_EDIT}
                                    icon={<BorderColorOutlined  sx={{ color: "white" }}/>}
                                    backgroundColor={"#2D9CDB"}
                                    backgroundColorHover={"#33AEF4"}
                                    textTooltip={"Editar"}
                                    isNavegationOrigin={true}
                                    directionUrl={"/documentation/programs/training/editprograms?id_programs=" + rowsNotArchived.id_programs}
                                />
                            ) : ''
                            }

                            {rowsNotArchived.document_url === null ?
                                rowsNotArchived.id_programs_statuses === 1 ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_PROG_CAPACIT_VIGE_ENVIA_TRABAJ}
                                        icon={<Send sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Enviar a trabajadores"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                rowsNotArchived.id_programs,
                                                "¿Estás seguro de enviar a trabajadores?",
                                                'Una vez enviado no podra revertir la operación del documento:  "' +
                                                rowsNotArchived.name +
                                                '"',
                                                "Si, Enviar a trabajadores",
                                                "trabajador"
                                            );
                                        }}
                                    />
                                ) :
                                    rowsNotArchived.id_programs_statuses === 2 ? (
                                        <>
                                            <ButtonIconControla
                                                roles={permissions.DOC_PROG_CAPACIT_VIGE_BUZON}
                                                icon={ <ForumOutlined sx={{ color: "white" }} />}
                                                backgroundColor={"#FFC300"}
                                                backgroundColorHover={"#FFC300"}
                                                textTooltip={"Buzon de participación"}
                                                directionUrl={"/mailbox/consults"}
                                                directionTarget={"_blank"}
                                            />
                                            <ButtonIconControla
                                                roles={permissions.DOC_PROG_CAPACIT_VIGE_ENVIA_COMITE}
                                                icon={<Send sx={{ color: "white" }} />}
                                                backgroundColor={"#804000"}
                                                backgroundColorHover={"#A45200"}
                                                textTooltip={"Enviar a comité"}
                                                functionOnClick={() => {
                                                    handleOpenModalConfirm(
                                                        rowsNotArchived.id_programs,
                                                        "¿Estás seguro de enviar a comité?",
                                                        'Recuerda que para enviar al comite tienes que tener en cuenta las sugerencias de los trabajadores:  "' +
                                                        rowsNotArchived.name +
                                                        '"',
                                                        "Si, enviar a comité",
                                                        "comite"
                                                    );
                                                }}
                                            />
                                        </>
                                    ) : rowsNotArchived.id_programs_statuses === 3 ? (
                                        <>
                                            <ButtonIconControla
                                                roles={permissions.DOC_PROG_CAPACIT_VIGE_APROBAR}
                                                icon={<ThumbUpAlt sx={{ color: "white" }} />}
                                                backgroundColor={"#169073"}
                                                backgroundColorHover={"#1AA483"}
                                                textTooltip={"Aprobar"}
                                                functionOnClick={() => {
                                                    handleOpenModalConfirm(
                                                        rowsNotArchived.id_programs,
                                                        "¿Estás seguro de aprobar el programa?",
                                                        'Para marcar la opción aprobado, debes verificar que el comité lo haya aprobado y conste en la respectiva acta. Una vez aprobado no podrá deshacer la operación:  "' +
                                                        rowsNotArchived.name +
                                                        '"',
                                                        "Aprobado",
                                                        "aprobar"
                                                    );
                                                }}
                                            />
                                            <ButtonIconControla
                                                roles={permissions.DOC_PROG_CAPACIT_VIGE_DESAPROBAR}
                                                icon={<ThumbDownAlt sx={{ color: "white" }} />}
                                                backgroundColor={"#CA2323"}
                                                backgroundColorHover={"#DF2F24"}
                                                textTooltip={"Desaprobar"}
                                                functionOnClick={() => {
                                                    handleOpenModalConfirm(
                                                        rowsNotArchived.id_programs,
                                                        "¿Estás seguro de desaprobar el programa?",
                                                        'Para marcar la opción desaprobado, debes verificar que el comité lo haya desaprobado y conste en la respectiva acta. Una vez desaprobado no podrá deshacer la operación:  "' +
                                                        rowsNotArchived.name +
                                                        '"',
                                                        "Desaprobado",
                                                        "desaprobar"
                                                    );
                                                }}
                                            />
                                        </>

                                    ) : rowsNotArchived.id_programs_statuses === 4 ? (
                                        <ButtonIconControla
                                            icon={<FileUploadOutlined sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1AA483"}
                                            textTooltip={"Publicar"}
                                            functionOnClick={() => {
                                                handleOpenModalConfirm(
                                                    rowsNotArchived.id_programs,
                                                    "¿Estás seguro de publicar el programa?",
                                                    'Una vez publicado  se cerrará el programa:  "' +
                                                    rowsNotArchived.name +
                                                    '"',
                                                    "Si, publicar",
                                                    "public"
                                                );
                                            }}
                                        />
                                    ) : rowsNotArchived.id_programs_statuses === 4 || rowsNotArchived.id_programs_statuses === 5 || rowsNotArchived.id_programs_statuses === 6 ? (
                                        <ButtonIconControla
                                            roles={permissions.DOC_PROG_CAPACIT_VIGE_ARCHIVAR}
                                            icon={<Archive sx={{ color: "white" }} />}
                                            backgroundColor={"#F2994A"}
                                            backgroundColorHover={"#FF881E"}
                                            textTooltip={"Archivar"}
                                            functionOnClick={() => {
                                                handleOpenModalConfirm(
                                                    rowsNotArchived.id_programs,
                                                    "¿Estás seguro de archivar el programa?",
                                                    'Programa a archivar  "' +
                                                    rowsNotArchived.name +
                                                    '"',
                                                    "Si, archivar",
                                                    "archived"
                                                );
                                            }}
                                        />
                                    ) : (
                                        ""
                                    ) :
                                rowsNotArchived.id_programs_statuses === 1 ?
                                    <ButtonIconControla
                                        roles={permissions.DOC_PROG_CAPACIT_VIGE_PUBLICAR}
                                        icon={<FileUploadOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Publicar"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                rowsNotArchived.id_programs,
                                                "¿Estás seguro de publicar el programa?",
                                                'Para publicar debes asegurarte que este Programa ha sido aprobado por el Comité. Una vez publicado  se cerrará el programa:  "' +
                                                rowsNotArchived.name +
                                                '"',
                                                "Si, publicar",
                                                "public"
                                            );
                                        }}
                                    /> : ""}

                            {rowsNotArchived.document_url !== null ? (
                                rowsNotArchived.id_programs_statuses === 6 ? (
                                    <>
                                        <ButtonIconControla
                                            roles={permissions.DOC_PROG_CAPACIT_VIGE_ARCHIVAR}
                                            icon={<Archive sx={{ color: "white" }} />}
                                            backgroundColor={"#F2994A"}
                                            backgroundColorHover={"#FF881E"}
                                            textTooltip={"Archivar"}
                                            functionOnClick={() => {
                                                handleOpenModalConfirm(
                                                    rowsNotArchived.id_programs,
                                                    "¿Estás seguro de archivar el programa?",
                                                    'Programa a archivar  "' +
                                                    rowsNotArchived.name +
                                                    '"',
                                                    "Si, archivar",
                                                    "archived"
                                                );
                                            }}
                                        />

                                        <ButtonIconControla
                                            roles={permissions.DOC_PROG_CAPACIT_VIGE_VER_DESPUES_PUBLIC}
                                            icon={ <VisibilityOutlined sx={{ color: "white" }} />}
                                            backgroundColor={"#305AD9"}
                                            backgroundColorHover={"#0E4AFF"}
                                            textTooltip={"Ver"}
                                            directionUrl={rowsNotArchived.document_url}
                                            directionTarget={"_blank"}
                                        />
                                    </>
                                ) :
                                    <ButtonIconControla
                                        roles={permissions.DOC_PROG_CAPACIT_VIGE_VER_ANTES_PUBLIC}
                                        icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        directionUrl={rowsNotArchived.document_url}
                                        directionTarget={"_blank"}
                                    />
                            ) : rowsNotArchived.id_programs_statuses === 4 || rowsNotArchived.id_programs_statuses === 5 || rowsNotArchived.id_programs_statuses === 6 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_PROG_CAPACIT_VIGE_VER_DESPUES_PUBLIC}
                                    icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                    backgroundColor={"#305AD9"}
                                    backgroundColorHover={"#0E4AFF"}
                                    textTooltip={"Ver"}
                                    isNavegationOrigin={true}
                                    directionUrl={"/documentation/programs/training/editprograms?id_programs=" + rowsNotArchived.id_programs + "&readOnly=true"}
                                />
                            ) : (
                                ""
                            )}
                            {rowsNotArchived.id_programs_statuses === 1 ?
                                <ButtonIconControla
                                    roles={permissions.DOC_PROG_CAPACIT_VIGE_ELIMINAR}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() => {
                                        handleOpenModalConfirm(
                                            rowsNotArchived.id_programs,
                                            "¿Estás seguro de eliminar el programa?",
                                            'Una vez eliminado no se podra recuperar todo lo incluido en este Programa:  "' +
                                            rowsNotArchived.name +
                                            '"',
                                            "Si, Eliminar",
                                            "delete"
                                        );
                                    }}
                                /> : ''}
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>

    );
}

export default ListActives;