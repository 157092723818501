import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  DialogActions,
  Typography,
} from "@mui/material";

import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import SubtitleText from "../../components/text/subtitleText";
import ButtonControla from "../../components/buttons/buttonController";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import TitleText from "../../components/text/titleText";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../components/buttons/returnBackControla";

const blue = "#034AFF";
const lilac = "#CBCBFF";
function TemplateMenu() {
  const navigate = useNavigate();
  // Dialog para ver plantilla

  const [openModalTemplateView, setOpenModalTemplateView] = useState(false);
  const handleOpenModalTemplateView = () => {
    setOpenModalTemplateView(true);
  };

  // Dialog para ver plantilla II

  const [openModalTemplateViewTwo, setOpenModalTemplateViewTwo] =
    useState(false);
  const handleOpenModalTemplateViewTwo = () => {
    setOpenModalTemplateViewTwo(true);
  };

  // Dialog para ver plantilla III

  const [openModalTemplateViewThree, setOpenModalTemplateViewThree] =
    useState(false);
  const handleOpenModalTemplateViewThree = () => {
    setOpenModalTemplateViewThree(true);
  };

  //usar plantilla

  const handleUseTemplate = async (event) => {
    event.preventDefault();
    navigate("/documentation/ssopolitics/template");
  };

  const handleUseTemplateTwo = async (event) => {
    event.preventDefault();
    navigate("/documentation/ssopolitics/templatetwo");
  };

  const handleUseTemplateThree = async (event) => {
    event.preventDefault();
    navigate("/documentation/ssopolitics/templatethree");
  };

  return (
    <Box sx={{height: "100%" }}>
      <ReturnBack/>

      <Container>
        <Box>
          <Grid container>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText text={"Documentación"} color={"#9191B0"} />
              <TitleText
                text={"Plantilla Política de Seguridad y Salud Ocupacional"}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
            <SubtitleText
              text={
                "Te presentamos una(s) plantilla(s) para facilitar la elaboración de tu documento"
              }
              color={blue}
            />
          </Grid>
        </Box>
        <Grid container sx={{ mt: 2 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: blue,
                    }}
                  >
                    Nombre del documento
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    Política de Seguridad y Salud Ocupacional - Modelo 1
                  </TableCell>
                  <TableCell align="center">
                    <ButtonIconControla
                      icon={<VisibilityOutlined sx={{ color: "white" }} />}
                      backgroundColor={"#305AD9"}
                      backgroundColorHover={"#0E4AFF"}
                      textTooltip={"Ver"}
                      functionOnClick={() => handleOpenModalTemplateView()}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    Política de Seguridad y Salud Ocupacional - Modelo 2{" "}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <ButtonIconControla
                      icon={<VisibilityOutlined sx={{ color: "white" }} />}
                      backgroundColor={"#305AD9"}
                      backgroundColorHover={"#0E4AFF"}
                      textTooltip={"Ver"}
                      functionOnClick={() => handleOpenModalTemplateViewTwo()}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    Política de Seguridad y Salud Ocupacional y Medio Ambiente
                    (Integrada) - Modelo 3
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <ButtonIconControla
                      icon={<VisibilityOutlined sx={{ color: "white" }} />}
                      backgroundColor={"#305AD9"}
                      backgroundColorHover={"#0E4AFF"}
                      textTooltip={"Ver"}
                      functionOnClick={() => handleOpenModalTemplateViewThree()}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid container sx={{ mt: 4 }}>
          <Grid item xs={7} sm={7} md={11} lg={11} xl={11}></Grid>
          <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
            <ButtonControla
              textButton={"Regresar"}
              backgroundColor={lilac}
              isNavegationOrigin={true}
              url={"/documentation/ssopolitics"}
            />
          </Grid>
        </Grid>
      </Container>

      {/* Modelo de plantilla */}
      <Dialog
        open={openModalTemplateView}
        onClose={() => setOpenModalTemplateView(false)}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0, marginTop: "15px" }}
      >
        <form onSubmit={handleUseTemplate}>
          <DialogContent>
            <Grid container>
              <Grid item md={12}>
                <Typography>
                  INGRESAR NOMBRE, empresa dedicada a INGRESAR OBJETO SOCIAL,
                  convencida que su capital más importante es su personal, ha
                  elaborado la siguiente política de seguridad y salud en
                  trabajo, mediante la que se compromete a:
                </Typography>
              </Grid>
              <Grid item md={12}>
                - Desarrollar sus actividades protegiendo la integridad de sus
                colaboradores, proveedores y la de terceros en las instalaciones
                pertenecientes a la empresa, mediante la prevención de las
                lesiones, dolencias, enfermedades e incidentes relacionados con
                el trabajo, identificando los peligros, evaluando los riesgos
                relacionados a las actividades de la empresa y estableciendo
                medidas de control.
              </Grid>
              <Grid item md={12}>
                - Cumplir con la normativa legal vigente, regulaciones internas,
                compromisos voluntarios suscritos por la organización en materia
                de seguridad y salud en el trabajo, así como estándares
                internacionales.
              </Grid>
              <Grid item md={12}>
                - Asegurar la consulta y participación activa de nuestro
                personal y de sus representantes en todos los aspectos del
                Sistema de Gestión de Seguridad y Salud en el Trabajo de nuestra
                empresa.
              </Grid>
              <Grid item md={12}>
                - Informar, comunicar y poner a disposición de manera
                responsable y oportuna a nuestro personal, sus representantes y
                grupos de interés los aspectos del Sistema de Gestión de
                Seguridad y Salud en el Trabajo de nuestra empresa para eliminar
                los peligros y reducir los riesgos.
              </Grid>
              <Grid item md={12}>
                - Capacitar, entrenar y comprometer a nuestro personal con la
                cultura de prevención de la seguridad y salud en todos los
                aspectos del Sistema de Gestión de Seguridad y Salud en el
                Trabajo de nuestra empresa.
              </Grid>
              <Grid item md={12}>
                - Mejorar continuamente el desempeño del Sistema de Gestión de
                Seguridad y Salud en el trabajo e integrarlo a las demás
                actividades y otros sistemas de la empresa.
              </Grid>
              <Grid item md={12}>
                Firma Fecha:
              </Grid>
              <Grid item md={12}>
                ______________________
              </Grid>
              <Grid item md={12}>
                Gerente General o equivalente (director / presidente / CEO,
                etc.)
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalTemplateView(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Usar plantilla
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* Modelo de plantilla II*/}
      <Dialog
        open={openModalTemplateViewTwo}
        onClose={() => setOpenModalTemplateViewTwo(false)}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0, marginTop: "15px" }}
      >
        {/* <DialogTitleControla titleText="Política de Seguridad y Salud Ocupacional" /> */}

        <form onSubmit={handleUseTemplateTwo}>
          <DialogContent>
            <Grid container>
              <Grid item md={12}>
                <Typography>
                  INGRESAR NOMBRE, empresa dedicada a INGRESAR OBJETO SOCIAL del
                  sector INGRESAR SECTOR está comprometida en propiciar el
                  mejoramiento de las condiciones de trabajo, salud y seguridad
                  en todos los niveles de nuestra organización, trabajadores,
                  contratistas y partes interesadas mediante el desarrollo del
                  Sistema de Gestión de Seguridad en el Trabajo.
                </Typography>
                <Typography>
                  Este sistema está orientado al desarrollo de un proceso lógico
                  y por etapas basado en la mejora continua que incluye la
                  Política, la planificación, la organización, la aplicación, la
                  evaluación, la auditoría y acciones de mejora con el objetivo
                  de anticipar, reconocer, evaluar y controlar los riesgos que
                  pueden afectar la seguridad y salud en el trabajo en toda
                  nuestra cadena de valor.
                </Typography>
                <Typography>
                  INGRESAR NOMBRE asume la responsabilidad de proteger la salud
                  y la seguridad de todos los trabajadores independientemente de
                  su vinculación laboral en los diferentes ambientes de trabajo,
                  por tanto, mantiene unas condiciones seguras y saludables en
                  los lugares de trabajo.
                </Typography>
              </Grid>
              <Grid item md={12}>
                • Desarrollar sus actividades protegiendo la integridad física
                de sus colaboradores, proveedores y la de terceros en las
                instalaciones pertenecientes a la empresa, mediante la
                prevención de las lesiones, dolencias, enfermedades e incidentes
                relacionados con el trabajo, gestionando los peligros
                relacionados a las actividades de la empresa.
              </Grid>
              <Grid item md={12}>
                • Cumplir con la normativa legal vigente, regulaciones y
                compromisos voluntarios, suscritos por la organización en
                materia de seguridad y salud en el trabajo.
              </Grid>
              <Grid item md={12}>
                • Asegurar la consulta, participación, información y
                capacitación activa de nuestro personal en todos los aspectos
                del Sistema de Gestión de Seguridad y Salud en el Trabajo de
                nuestra empresa.
              </Grid>
              <Grid item md={12}>
                • Mejorar continuamente el desempeño del Sistema de Gestión de
                Seguridad y Salud en el trabajo e integrarlo a las demás
                actividades y otros sistemas de la empresa.
              </Grid>
              <Grid item md={12}>
                Firma Fecha:
              </Grid>
              <Grid item md={12}>
                ______________________
              </Grid>
              <Grid item md={12}>
                Gerente General o equivalente (director / presidente / CEO,
                etc.)
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalTemplateViewTwo(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Usar plantilla
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* Modelo de plantilla  III*/}
      <Dialog
        open={openModalTemplateViewThree}
        onClose={() => setOpenModalTemplateViewThree(false)}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0, marginTop: "15px" }}
      >
        {/* <DialogTitleControla titleText="Política de Seguridad y Salud Ocupacional" /> */}

        <form onSubmit={handleUseTemplateThree}>
          <DialogContent>
            <Grid container>
              <Grid item md={12}>
                <Typography>
                  INGRESAR NOMBRE, empresa dedicada a INGRESAR OBJETO SOCIAL,
                  está comprometida con el desarrollo, implementación y
                  mantenimiento del Sistema Integrado de Gestión en Medio
                  Ambiente y Seguridad y Salud Ocupacional. Nuestros objetivos
                  la protección del medio ambiente y la protección de la
                  seguridad y la salud de los trabajadores, los contratistas y
                  las partes interesadas que interactúan en todos los niveles de
                  nuestra organización. Para alcanzar estos objetivos la empresa
                  se compromete a:
                </Typography>
              </Grid>
              <Grid item md={12}>
                • Desarrollar acciones para el manejo eficiente de los recursos
                naturales que se utilizan, como agua, suelo, energía,
                biodiversidad entre otros y reducir el impacto ambiental de las
                actividades, aplicando la prevención y la vigilancia de los
                aspectos ambientales en los procesos de la organización.
                Asimismo, la empresa implementa acciones para otorgar las
                condiciones adecuadas de trabajo, salud y seguridad en la
                organización.
              </Grid>
              <Grid item md={12}>
                • Identificar los aspectos ambientales relacionados a las
                actividades de la empresa y establecer medidas de control para
                la reducción, mitigación y prevención de impactos ambientales
                negativos, así como identificar los peligros, evaluar los
                riesgos relacionados a las actividades de la empresa y
                establecer medidas de control en materia de seguridad y salud de
                los trabajadores.
              </Grid>
              <Grid item md={12}>
                • Cumplir con la normativa legal vigente, recomendaciones y
                mandatos de la autoridad ambiental, regulaciones internas,
                compromisos voluntarios suscritos por la organización,
                incluyendo los señalados en los instrumentos de gestión
                ambiental, así como estándares internacionales en materia de
                medio ambiente y seguridad y salud en el trabajo.
              </Grid>
              <Grid item md={12}>
                • Asegurar la consulta y participación activa de nuestro
                personal y de sus representantes en todos los aspectos del
                Sistema de Gestión de Seguridad y Salud en el Trabajo de nuestra
                empresa.
              </Grid>
              <Grid item md={12}>
                • Informar, comunicar y poner a disposición de manera
                responsable y oportuna a nuestro personal, sus representantes y
                grupos de interés los aspectos del Sistema Integrado de Gestión
                de Medio Ambiente y Seguridad y Salud en el Trabajo de nuestra
                empresa.
              </Grid>
              <Grid item md={12}>
                • Capacitar, entrenar y comprometer a nuestro personal con la
                cultura de ecoeficiencia y la cultura de la prevención en todos
                los aspectos del Sistema Integrado de Gestión de Medio Ambiente
                y Seguridad y Salud de nuestra empresa.
              </Grid>
              <Grid item md={12}>
                • Mejorar continuamente el desempeño del Sistema Integrado de
                Gestión de Medio Ambiente y de Seguridad y Salud en el Trabajo.
              </Grid>
              <Grid item md={12}>
                Firma Fecha:
              </Grid>
              <Grid item md={12}>
                ______________________
              </Grid>
              <Grid item md={12}>
                Gerente General o equivalente (director / presidente / CEO,
                etc.)
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalTemplateViewThree(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Usar plantilla
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
export default TemplateMenu;
