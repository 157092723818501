import { React } from 'react'
import SubtitleText from '../components/text/subtitleText';
import TitleText from '../components/text/titleText';
import Container from '@mui/material/Container';
import DocCard from "../components/cards/docCard";
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

const colorTitle = "#9191B0";
const colorOneCardOne = "#e7e7e7";

// const handleGetBaselineDiagnostics = async () => {
//     const response = await MyBaseline.baselineDiagnostics();

//     if (response.success === false) {
//         //console.log("error no se puedo traer lois diagnósticos")
//     } else {
//         const archivedBaselines = response.baseLine_archived
//         const activeBaselines = response.baseLine_current
//         const inProgressBaselines = response.baseLine_in_progress

//         if ((JSON.stringify(archivedBaselines) === "[]") && (JSON.stringify(activeBaselines) === "[]") && (JSON.stringify(inProgressBaselines) === "[]")) {
//             window.location.href = '/baseline/welcome';
//         } else {
//             console.log("hay algo lleno")
//             window.location.href = '/baseline/main';
//         }
//     }
//     return response;
// }

function BaselineTools() {

    return (
        <Box sx={{ display: "flex", backgroundColor: "#F5F4F6" }}>

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid xs={12} md={12}>
                            <SubtitleText text={"Línea Base"} color={colorTitle}></SubtitleText>
                            <TitleText text={"Línea Base"} color={colorTitle}></TitleText>
                        </Grid>
                        <Grid xs={12} md={12}>
                            <img src="/assets/images/baselinebanner.png" style={{ width: "100%" }} alt={'banner'} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3.5} sx={{ mt: 3 }}>
                            <Link href={"/"} underline="none" style={{cursor: "pointer"}}>
                                <DocCard size={"medium"} color1={colorOneCardOne} color2={colorOneCardOne} text={"Respuestas Líneas Base"} img={"/assets/images/baselineanswers.png"} alt="banner"></DocCard>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={3.5} sx={{ mt: 3 }}>
                            <Link href={"/"} underline="none" style={{cursor: "pointer"}}>
                                <DocCard size={"medium"} color1={colorOneCardOne} color2={colorOneCardOne} text={"Resultados Línea Base"} img={"/assets/images/baselineresults.png"} alt="banner"></DocCard>
                            </Link>
                        </Grid>
                        {/* <Grid item xs={12} md={3.5} sx={{ mt: 3 }}>
                            <Link onClick={() => handleGetBaselineDiagnostics()} underline="none">
                                <DocCard size={"medium"} color1={colorOneCardOne} color2={colorOneCardOne} text={"Mis diagnósticos"} img={"/assets/images/mydiagnosticsbanner.png"} alt="banner"></DocCard>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={3.5} sx={{ mt: 3 }}>
                            <Link href={"/"} underline="none" >
                                <DocCard size={"medium"} color1={colorOneCardOne} color2={colorOneCardOne} text={"Herramientas para Línea base actual"} img={"/assets/images/baselinetoolsbanner.png"} alt="banner"></DocCard>
                            </Link>
                        </Grid> */}
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default BaselineTools;

