import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function baselineDiagnosis() {
  const url = "baseline/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function editBaseline({ idBaseline }) {
  const url = "baseline/edit/" + idBaseline;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function storeBaseline({ name, document_url, id_configuration_modules }) {
  if (document_url !== null && document_url !== undefined) {
    document_url = await uploadFileCompanies(document_url);
  }
  const url = "baseline/store";
  const data = {
    name: name,
    document_url: document_url,
    id_configuration_modules,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function deleteBaseline({ idBaseline }) {
  const url = "baseline/delete/" + idBaseline;
  console.log(url);
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

function completionPercentage({ idBaseline }) {
  const url = "baseline/lineaments/percentage/get/" + idBaseline;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function completionLineamentPercentage({ idLineaments }) {
  const url = "baseline/lineaments/indicators/percentage/get/" + idLineaments;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function updateCompanyAnswers({ idIndicators, answer }) {
  const url = "baseline/lineaments/indicators/answers/update/" + idIndicators;
  const data = {
    answer: answer,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function companyLineaments({ idLineaments }) {
  const url = "baseline/lineaments/get/" + idLineaments;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function companyIndicators({ idBaselineCompaniesLineaments }) {
  const url =
    "baseline/lineaments/indicators/get/" + idBaselineCompaniesLineaments;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function baselineResults({ idBaseline }) {
  const url = "baseline/update/status/end/" + idBaseline;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "PUT",
    url: url,
  });
}

function updateBaselineEditComment({ idBaselineCompaniesLineaments, comment }) {
  const url =
    "baseline/lineaments/indicators/comments/update/" +
    idBaselineCompaniesLineaments;
  const data = {
    comment: comment,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function archiveBaselineDiagnosis({ idBaseline }) {
  const url = "baseline/update/archived/" + idBaseline;
  const data = {
    archived: 1,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}
//////////////Results
function getBaselineShowResults({ idBaseline }) {
  const url = "baseline/lineaments/indicators/show/get/" + idBaseline;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}
//////////////ADMIN
function indicators() {
  const url = "admin/baseline/indicators/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function storeIndicators({
  idLineaments,
  questions,
  actions,
  configuration_modules,
}) {
  const data = {
    id_baseline_lineaments: idLineaments,
    questions: questions,
    actions: actions,
    configuration_modules,
  };
  const url = "admin/baseline/indicators/store";
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

async function updateIndicators({
  idIndicators,
  idLineaments,
  questions,
  actions,
  configuration_modules,
}) {
  const data = {
    id_baseline_lineaments: idLineaments,
    questions: questions,
    actions: actions,
    configuration_modules,
  };
  const url = "admin/baseline/indicators/update/" + idIndicators;
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

async function deleteIndicators({ idIndicators }) {
  const url = "admin/baseline/indicators/delete/" + idIndicators;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

function lineaments() {
  const url = "admin/baseline/lineaments/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function storeLineaments({ lineaments }) {
  const data = {
    baseline_lineament: lineaments,
  };
  const url = "admin/baseline/lineaments/store";
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

async function updateLineaments({ idLineaments, lineaments }) {
  const data = {
    baseline_lineament: lineaments,
  };
  const url = "admin/baseline/lineaments/update/" + idLineaments;
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

async function deleteLineaments({ idLineaments }) {
  const url = "admin/baseline/lineaments/delete/" + idLineaments;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}
///////////////ADMIN

/////descargar acciones de mejora

function downloadImprovements({ idBaseline }) {
  const url =
    "baseline/lineaments/indicators/improvement/actions/get/" + idBaseline;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}
const MyBaseline = {
  companyLineaments,
  updateBaselineEditComment,
  deleteBaseline,
  editBaseline,
  baselineResults,
  companyIndicators,
  updateIndicators,
  updateCompanyAnswers,
  completionLineamentPercentage,
  baselineDiagnosis,
  completionPercentage,
  storeBaseline,
  indicators,
  storeIndicators,
  deleteIndicators,
  lineaments,
  storeLineaments,
  updateLineaments,
  deleteLineaments,
  archiveBaselineDiagnosis,
  getBaselineShowResults,
  downloadImprovements,
};

export default MyBaseline;
