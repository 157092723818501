import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
    CloudDownloadOutlined,
    LoginOutlined
} from "@mui/icons-material";
import MyProceedingsBook from "../../../../api/MyProceedingsBook";

function ListArchived(props) {

    const {
        archivedBooks,
        documentType,
        colorCell
    } = props

    const downloadPDF = async (id_minute_book) => {
        try {
            const resp = await MyProceedingsBook.downloadPDF(id_minute_book); // Llama a tu función que usa connectApiExcel
            const blob = await resp.blob(); // Convertir la respuesta en un blob

            // Crear un enlace temporal para descargar el archivo
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a'); // Aquí debe ser 'a' en lugar de 'r'
            link.href = url;
            link.setAttribute('download', `libro_acta_${id_minute_book}.pdf`); // Establece el nombre del archivo
            document.body.appendChild(link);
            link.click();
            link.remove(); // Elimina el enlace después de la descarga

        } catch (error) {
            console.error('Hubo un error con la generacion del PDF', error);
        }
    }

    return (
        <TableBody
            sx={{ display: documentType === 1 ? "" : "none" }}
        >
            {archivedBooks.map((book) => (
                <TableRow>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {book.title}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {book.start_date}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {book.end_date}
                    </TableCell>
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignContent="center"
                        >

                            <ButtonIconControla
                                icon={
                                    <LoginOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#24996A"}
                                backgroundColorHover={"#1AA483"}
                                textTooltip={"Ver Actas"}
                                isNavegationOrigin={true}
                                directionUrl={"/committee/proceedingsBook/addProceedingsBook/proceeedingsMeeting"}
                                paramsIntUrl={{
                                    id_book: book.id_minute_book,
                                    name_book: book.title,
                                    date_created: book.start_date,
                                    end_date: book.end_date,
                                    typeBook: "archivedBook"
                                }}
                            />

                           <ButtonIconControla
                                icon={<CloudDownloadIcon sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1AA483"}
                                textTooltip={"Descargar"}
                                functionOnClick={() => {downloadPDF(book.id_minute_book)}}
                            />
                        </Stack>
                    </TableCell>

                </TableRow>

            ))}

        </TableBody>

    )

}
export default ListArchived;