import { DataArrayRounded } from "@mui/icons-material";
import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase"

function getEvaluationsSSO() {
    const url = "form_evaluation_system";
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function getEvaluationSSOById(idEvaluation) {
    const url = "form_evaluation_system/" + idEvaluation;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });

}

function postEvaluation(dataEvaluation) {
    const url = "form_evaluation_system";
    return MyConfigurationApi.connectFormApi({
        dataJson: dataEvaluation,
        method: "POST",
        url: url,
    });
}

async function updatedEvalutionById(dataEvaluation, idEvaluation) {
    //fecha actual
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();

    if (typeof (dataEvaluation.document_url_audit_report) == "object") {
        dataEvaluation.document_url_audit_report = await uploadFileCompanies(
            dataEvaluation.document_url_audit_report,
            `registerMypes/evaluationSSO/auditReport/${year}/${month}/${day}/ `
        )
    }
    if (typeof (dataEvaluation.document_url_action_plan) == "object") {
        dataEvaluation.document_url_action_plan = await uploadFileCompanies(
            dataEvaluation.document_url_action_plan,
            `registerMypes/evaluationSSO/actionPlan/${year}/${month}/${day}/`
        )
    }

    const url = "form_evaluation_system/" + idEvaluation;
    return MyConfigurationApi.connectFormApi({
        dataJson: dataEvaluation,
        method: "PUT",
        url: url,
    });
}
function updateStatusEvaluationById(data,idEvaluation){
    const url = "form_evaluation_system/" + idEvaluation;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });
}

function getAudit(idEvaluation) {
    const url = `sec/form_evaluation_system_audits_involved/${idEvaluation}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function postAudit(data, idEvaluation) {
    const url = `sec/form_evaluation_system_audits_involved/${idEvaluation}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

function updateAuditById(data, idAudit){
    const url = `sec/form_evaluation_system_audits_involved/detail/${idAudit}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });
}

function deleteAuditById(idAudit) {
    const url = `sec/form_evaluation_system_audits_involved/detail/${idAudit}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}

function getNonconformities(idEvaluation){
    const url = `sec/form_evaluation_system_non_conformities_involved/${idEvaluation}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function postNonconformities(data,idEvaluation){
    const url = `sec/form_evaluation_system_non_conformities_involved/${idEvaluation}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

function deleteNonconformitieById (idNonConformitie){
    const url = `sec/form_evaluation_system_non_conformities_involved/detail/${idNonConformitie}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}

function updateNonconformitieById (data,idNonConformitie){
    const url = `sec/form_evaluation_system_non_conformities_involved/detail/${idNonConformitie}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });
}

function postInternalInspection(data,idEvaluation){
    const url = `sec/form_evaluation_system_internal_inspection_involved/${idEvaluation}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

function getInternalInspection(idEvaluation){
    const url = `sec/form_evaluation_system_internal_inspection_involved/${idEvaluation}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function deleteInternalInspectionById(idInternalInspection){
    const url = `sec/form_evaluation_system_internal_inspection_involved/detail/${idInternalInspection}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}

function updateInternalInspectionById(data,idInternalInspection){
    const url = `sec/form_evaluation_system_internal_inspection_involved/detail/${idInternalInspection}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });
}

function getResultInspection(idEvaluation){
    const url =  `sec/form_evaluation_system_result_internal_inspection_involved/${idEvaluation}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function postResultInspection(data,idEvaluation){
    const url = `sec/form_evaluation_system_result_internal_inspection_involved/${idEvaluation}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

function updateResultInspectionById(data,idResultInspection){
    const url = `sec/form_evaluation_system_result_internal_inspection_involved/detail/${idResultInspection}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });
}

function deleteResultInspectionByID(idResultInspection){
    const url = `sec/form_evaluation_system_result_internal_inspection_involved/detail/${idResultInspection}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
} 


function postResponsibleRegister(data, idEvaluation) {
    const url = `${idEvaluation}/responsible`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

function getResponsibleRegister(idEvaluation) {
    const url = `${idEvaluation}/responsible`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function deleteResponsibleRegister(idResponsibleRegister) {
    const url = `responsible/detail/${idResponsibleRegister}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}

function signResponsibleRegister(data,idResponsibleRegister){
    const url = `responsible/detail/${idResponsibleRegister}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });
}

const MyEvaluationSSO = {
    getEvaluationsSSO,
    getEvaluationSSOById,
    postEvaluation,
    updatedEvalutionById,
    updateStatusEvaluationById,
    getAudit,
    postAudit,
    updateAuditById,
    deleteAuditById,
    getNonconformities,
    postNonconformities,
    deleteNonconformitieById,
    updateNonconformitieById,
    postInternalInspection,
    getInternalInspection,
    deleteInternalInspectionById,
    updateInternalInspectionById,
    getResultInspection,
    postResultInspection,
    updateResultInspectionById,
    deleteResultInspectionByID,
    postResponsibleRegister,
    getResponsibleRegister,
    deleteResponsibleRegister,
    signResponsibleRegister

}
export default MyEvaluationSSO;