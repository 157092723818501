import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getSsoPolitics() {
  const url = "sso_politics";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createSsoPolitics({ develop, title, date, document_url, idUsersManager, managerName }) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (document_url !== null && document_url !== undefined) {
    document_url = await uploadFileCompanies(
      document_url,
      "documentation/ssopolitics/" + year + "/" + month + "/" + day + "/"
    );
  }
  const url = "sso_politics_template";
  const data = {
    develop: develop,
    title: title,
    date_develop: date,
    document_url: document_url,
    id_users_manager: idUsersManager,
    manager_name: managerName,
    register_status: 4,
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function editSsoPolitics({ idSsoPolitics }) {
  const url = "sso_politics_template/" + idSsoPolitics;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function deleteSsoPolitics({ idSsoPolitics }) {
  const url = "sso_politics_template/" + idSsoPolitics;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

async function UpdateSsoPolitics({
  develop,
  title,
  date,
  document_url,
  idSsoPolitics,
}) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (document_url !== null && document_url !== undefined) {
    document_url = await uploadFileCompanies(
      document_url,
      "documentation/ssopolitics/" + year + "/" + month + "/" + day + "/"
    );
  }
  const url = "sso_politics_template/" + idSsoPolitics;
  const data = {
    develop: develop,
    title: title,
    date_develop: date,
    document_url: document_url,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function updateStatusSsoPolitics({ idSsoPolitics, status }) {
  const url = "sso_politics_template/" + idSsoPolitics;
  const data = {
    register_status: status,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function getFulfillmentsSsoPolitics({ idSsoPolitics }) {
  const url = "sec/sso_politics_fulfillments/" + idSsoPolitics;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function postFulfillmentsSsoPolitics({
  idSsoPolitics,
  orgSigned,
  dated,
  concise,
  coherent,
  memberProtection,
  normativityCompliance,
  consultCommitment,
  continuousImprovement,
  clearRedaction,
  recurringReview,
  diffused,
  provisioned,
  exposed,
}) {
  const url = "sso_politics_template/" + idSsoPolitics;

  // Validación de campos
  const camposRequeridos = [
    orgSigned,
    dated,
    concise,
    coherent,
    memberProtection,
    normativityCompliance,
    consultCommitment,
    continuousImprovement,
    clearRedaction,
    recurringReview,
    diffused,
    provisioned,
    exposed
  ];

  if (camposRequeridos.some(campo => campo === undefined || campo === null || campo === "")) {
    return {
      error: true,
      message: "Todos los campos son obligatorios"
    }
  }

  const data = {
    firmada_organizacion: orgSigned,
    fechada: dated,
    concisa: concise,
    coherente: coherent,
    proteccion_miembros: memberProtection,
    cumplimiento_normatividad: normativityCompliance,
    compromiso_consulta_participacion_sso: consultCommitment,
    compromiso_mejora_continua: continuousImprovement,
    redactada_claridad: clearRedaction,
    consultada_documentada_revisada_periodicamente: recurringReview,
    difundida_organizacion: diffused,
    disposicion_partes_interesadas: provisioned,
    expuesta_escrito: exposed,
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

// TEMPLATE

async function createSsoPoliticsTemplate({
  code,
  develop,
  revised,
  approved,
  version,
  title,
  document_text,
  idUsersDevelop,
  idUsersRevised,
  idUsersApproved,
  dateDevelop,
  dateRevised,
  dateApproved,
  docEnv,
  managerName,
  idManager,
  //
  developUrlFirm,
  revisedUrlFirm,
  approvedUrlFirm
}) {
  const url = "sso_politics_template";
  const data = {
    code: code,
    develop: develop,
    revised: revised,
    approved: approved,
    version: version,
    title: title,
    document_text: document_text,
    id_users_develop: idUsersDevelop,
    id_users_revised: idUsersRevised,
    id_users_approved: idUsersApproved,
    date_develop: dateDevelop,
    date_revised: dateRevised,
    date_approved: dateApproved,
    doc_env: docEnv,
    manager_name: managerName,
    id_users_manager: idManager,
    //
    develop_url_firm: developUrlFirm,
    revised_url_firm: revisedUrlFirm,
    approved_url_firm: approvedUrlFirm

  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}
function getSsoPoliticsTemplate() {
  const url = "sso_politics_template";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function editSsoPoliticsTemplate({ idSsoPoliticsTemplate }) {
  const url = "sso_politics_template/" + idSsoPoliticsTemplate;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function deleteSsoPoliticsTemplate({ idSsoPoliticsTemplate }) {
  const url = "sso_politics_template/" + idSsoPoliticsTemplate;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

async function updateSsoPoliticsTemplate({
  idSsoPoliticsTemplate,
  code,
  develop,
  revised,
  approved,
  version,
  title,
  document_text,
  idUsersDevelop,
  idUsersRevised,
  idUsersApproved,
  dateDevelop,
  dateRevised,
  dateApproved,
  managerName,
  idManager,
  managerSign,
  managerSignDate,
  revisedSign,
  approvedSign,
  developSign,
  registerStatus,

  //
  developUrlFirm,
  revisedUrlFirm,
  approvedUrlFirm
}) {
  const url = "sso_politics_template/" + idSsoPoliticsTemplate;
  const data = {
    code: code,
    develop: develop,
    revised: revised,
    approved: approved,
    version: version,
    title: title,
    document_text: document_text,
    id_users_develop: idUsersDevelop,
    id_users_revised: idUsersRevised,
    id_users_approved: idUsersApproved,
    date_develop: dateDevelop,
    date_revised: dateRevised,
    date_approved: dateApproved,
    manager_sign: managerSign,
    manager_name: managerName,
    date_manager: managerSignDate,
    id_users_manager: idManager,
    revised_sign: revisedSign,
    approved_sign: approvedSign,
    develop_sign: developSign,
    register_status: registerStatus,
    //

    develop_url_firm: developUrlFirm,
    revised_url_firm: revisedUrlFirm,
    approved_url_firm: approvedUrlFirm
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

/// FIRMAR

function signDevelop({ idSsoPoliticsTemplate }) {
  const url = "sso_politics_template/" + idSsoPoliticsTemplate;
  const data = {
    develop_sign: 1,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function signRevised({ idSsoPoliticsTemplate, dateRevised }) {

  const url = "sso_politics_template/" + idSsoPoliticsTemplate;
  const data = {
    revised_sign: 1,
    date_revised: dateRevised,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function signApproved({ idSsoPoliticsTemplate, dateApproved }) {

  const url = "sso_politics_template/" + idSsoPoliticsTemplate;
  const data = {
    approved_sign: 1,
    date_approved: dateApproved,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function signManager({ idSsoPoliticsTemplate }) {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;

  const url = "sso_politics_template/" + idSsoPoliticsTemplate;
  const data = {
    manager_sign: 1,
    date_manager: today,
    register_status: 9,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

const MySsoPolitics = {
  getSsoPolitics,
  createSsoPolitics,
  editSsoPolitics,
  UpdateSsoPolitics,
  updateStatusSsoPolitics,
  deleteSsoPolitics,

  //template
  createSsoPoliticsTemplate,
  getSsoPoliticsTemplate,
  editSsoPoliticsTemplate,
  updateSsoPoliticsTemplate,
  deleteSsoPoliticsTemplate,

  //fulfillments
  getFulfillmentsSsoPolitics,
  postFulfillmentsSsoPolitics,

  //firmas
  signDevelop,
  signRevised,
  signApproved,
  signManager,
};

export default MySsoPolitics;
