import React, { useEffect, useState } from "react";
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, Tabs, Tab, CircularProgress, Stack, Box } from "@mui/material";
import { useAppContext } from "../../context/AppContext";
import { useLocation } from "react-router-dom";

import { styled } from '@mui/system';
import {
  TablePagination,
  tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Grid } from "@mui/joy";


const blue = "#034AFF";

const TableControla = ({ tabs, children, loading }) => {
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(0);
  const { loadingPermissions } = useAppContext();

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const newIdTabs = parseInt(params.get('idTabs') || 0);
    setSelectedTab(newIdTabs);
  }, [location])


  //
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const Root = styled('div')(
    ({ theme }) => `
    table {
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      width: 100%;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      box-shadow: 0px 4px 16px ${theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.3)' : grey[200]
      };
      border-radius: 12px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      overflow: hidden;
    }
  
    td,
    th {
      padding: 16px;
    }
  
    th {
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    }
    `,
  );

  const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
       color: ${theme.palette.mode === 'dark' ? grey[300] : blue};
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      font-family: 'IBM Plex Sans', sans-serif;
      padding: 2px 0 2px 4px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 6px; 
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : blue};
      transition: all 100ms ease;
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
        border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
      }
  
      &:focus {
        outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
        border-color: ${blue[400]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      display: flex;
      gap: 6px;
      border: transparent;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      display: flex;
      align-items: center;
      padding: 0;
      border: transparent;
      border-radius: 50%; 
      background-color: transparent;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : blue};
      transition: all 100ms ease;
  
      > svg {
        font-size: 22px;
      }
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
        border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
      }
  
      &:focus {
        outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
        border-color: ${blue[400]};
      }
  
      &:disabled {
        opacity: 0.3;
        &:hover {
          border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
          background-color: transparent;
        }
      }
    }
    `,
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  useEffect(() => {
    if (Object.keys(tabs[selectedTab]).length > 2) {

      // Filtrar filas para mostrar solo las de la página actual
      tabs.forEach((t, index) => {
        if (index == selectedTab) {
          const newArrayFilter = t.arrayData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
          t.setArrayData(newArrayFilter);
        }
      });
    }
  }, [rowsPerPage, page])

  useEffect(() => {
    if (Object.keys(tabs[selectedTab]).length > 2) {
      setPage(0);
      tabs.forEach((t, index) => {
        if (index == selectedTab) {
          const newArrayFilter = t.arrayData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
          t.setArrayData(newArrayFilter);
        }
      });
    }
  }, [tabs[selectedTab].arrayData?.length])
 


  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper sx={{ overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table
              stickyHeader
              sx={{ width: "100%", minWidth: "700px" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {tabs && tabs.map((tab, index) => {
                    if (index == selectedTab) {
                      return tab.columnNames.map((name, index) => (
                        <TableCell key={index} sx={{ color: "blue" }}>
                          {name}
                        </TableCell>
                      ))
                    }
                  })}
                  {
                    tabs.some((e) => 'label' in e) ? (
                      <TableCell colSpan={4}>
                        <Stack
                          direction="row"
                          justifyContent="end"
                          alignItems="center"
                        >
                          <Tabs
                            value={selectedTab}
                            onChange={handleChangeTab}
                            aria-label="Tipos de documento"
                          >
                            {tabs && tabs.map((tab, index) => {
                              if (tab.label) {
                                return <Tab key={index} label={tab.label} />
                              }
                            })}
                          </Tabs>
                        </Stack>
                      </TableCell>
                    ) : ""
                  }
                </TableRow>
              </TableHead>

              {loading || loadingPermissions ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                (children.length != undefined ? children[selectedTab] : children) && (React.cloneElement(children.length != undefined ? children[selectedTab] : children, { documentType: selectedTab }))
              )}

            </Table>
          </TableContainer>
        </Paper>

      </Grid>

      {
        (Object.keys(tabs[selectedTab]).length > 2 && !(loading || loadingPermissions)) && (
          <>
            <Grid item>
              <Root sx={{ width: 500, maxWidth: '100%' }}>
                <table aria-label="custom pagination table">
                  <tfoot>
                    <tr>
                      <CustomTablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                        colSpan={3}
                        count={tabs.filter((t, index) => index == selectedTab)[0].arrayData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        slotProps={{
                          select: {
                            'aria-label': 'Filas por página',
                          },
                          actions: {
                            showFirstButton: true,
                            showLastButton: true,
                            slots: {
                              firstPageIcon: FirstPageRoundedIcon,
                              lastPageIcon: LastPageRoundedIcon,
                              nextPageIcon: ChevronRightRoundedIcon,
                              backPageIcon: ChevronLeftRoundedIcon,
                            },
                          },
                        }}
                        labelRowsPerPage="Filas por página:"
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </tr>
                  </tfoot>
                </table>
              </Root>
            </Grid>
          </>
        )
      }

    </Grid>

  );
};

export default TableControla;
