import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import { Unarchive } from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useAppContext } from "../../../context/AppContext";


const ListArchived = (props) => {
  const { permissions } = useAppContext();

  const {
    rowsSso,
    colorCell,
    handleOpenModalConfirm } = props

  return (
    <TableBody>
      {rowsSso.map((rowsArchives) =>
        rowsArchives.register_status == 5 ? (
          <TableRow
            hover
            key={rowsArchives.id}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell sx={{ color: colorCell }}>
              {rowsArchives.title}
            </TableCell>
            <TableCell sx={{ color: colorCell }}>
              {rowsArchives.document_url != ""
                ? "Archivo"
                : "Sistema"}
            </TableCell>
            <TableCell sx={{ color: colorCell }}>
              {rowsArchives.status}
            </TableCell>
            <TableCell align="right">
              <Stack
                direction="row"
                justifyContent="end"
                alignItems="center"
              >
                {rowsArchives.document_url !== "" ? (
                  <ButtonIconControla
                    roles={permissions.DOC_SSO_ARCHIVADOS_VER_PDF}
                    icon={<VisibilityOutlined sx={{ color: "white" }}/>}
                    backgroundColor={"#305AD9"}
                    backgroundColorHover={"#0E4AFF"}
                    textTooltip={"Ver"}
                    directionUrl={rowsArchives.document_url}
                    directionTarget={"_blank"}
                  />
                ) : rowsArchives.register_status !== 1 ? (
                  <ButtonIconControla
                    roles={permissions.DOC_SSO_ARCHIVADOS_VER_SISTEMA}
                    icon={ <VisibilityOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#305AD9"}
                    backgroundColorHover={"#0E4AFF"}
                    textTooltip={"Ver"}
                    isNavegationOrigin={true}
                    directionUrl={
                      "/documentation/ssopolitics/template?idSsoPolitics=" +
                      rowsArchives.id + "&readOnly=true"
                    }
                  />
                ) : (
                  ""
                )}

                <ButtonIconControla
                  roles={permissions.DOC_SSO_ARCHIVADOS_DESARCHIVAR}
                  icon={<Unarchive sx={{ color: "white" }} />}
                  backgroundColor={"#F2994A"}
                  backgroundColorHover={"#FF881E"}
                  textTooltip={"Desarchivar"}
                  functionOnClick={() =>
                    handleOpenModalConfirm(
                      rowsArchives.id,
                      "¿Estás seguro de desarchivar la Política de SSO?",
                      'Política a desarchivar:  "' +
                      rowsArchives.title +
                      '"',
                      "Si, desarchivar",
                      "unarchived"
                    )
                  }
                />
              </Stack>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )
      )}
    </TableBody>
  )
}

export default ListArchived