import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getRegisters() {
  const url = "registers_mype_tracking";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createRegisters({ code }) {
  const url = "registers_mype_tracking";
  const data = {
    code: code,
    area: "",
    number_workers_area: "",
    id_type_severity_work_accident: "",
    theme: "",
    date: "",
    number_hours: "",
    name_coach: "",
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function editRegisters({ idRegistersMype }) {
  const url = "registers_mype_tracking/" + idRegistersMype;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function updateRegisters({
  idRegistersMype,
  code,
  area,
  register_status,
  number_workers_area,
  id_type_severity_work_accident,
  theme,
  date,
  number_hours,
  name_coach,
}) {
  const url = "registers_mype_tracking/" + idRegistersMype;
  const data = {
    code: code,
    area: area,
    number_workers_area: number_workers_area,
    id_type_severity_work_accident: id_type_severity_work_accident,
    theme: theme,
    date: date,
    number_hours: number_hours,
    name_coach: name_coach,
    register_status: register_status,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

// monitoreo de agentes
// jalar registros de agentes
function getMonitoringAgents({ idRegistersMype }) {
  const url =
    "sec/registers_mype_tracking_monitoring_agents/" + idRegistersMype;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// crear monitoreo de agentes
async function createMonitoringAgents({
  idRegistersMype,
  workstation,
  id_type_agent,
  date_monitoring,
  poor_outcome,
  agent_generate_occupational_disease,
  corrective_actions,
  id_users,
  date_ejecution,
  end_date,
  file,    name_type_severity
}) {
  if (file !== null && file !== undefined && file.length !== 0) {
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();
    file = await uploadFileCompanies(file,"documentation/registers/mypes/tracking/" + year + '/' + month + '/' + day + '/');
  } 

  const url =
    "sec/registers_mype_tracking_monitoring_agents/" + idRegistersMype;
  const data = {
    workstation: workstation,
    id_type_agent: id_type_agent,
    date_monitoring: date_monitoring,
    poor_outcome: poor_outcome,
    agent_generate_occupational_disease: agent_generate_occupational_disease,
    corrective_actions: corrective_actions,
    id_users: id_users,
    date_ejecution: date_ejecution,
    end_date: end_date,
    file:file,
    name_type_severity:name_type_severity

  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// //   // eliminar 
  async function deleteMonitoringAgents({ idRegistersMype }) {
    const url ="sec/registers_mype_tracking_monitoring_agents/detail/"+ idRegistersMype;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }

// equipo
// jalar registros de agentes
function getEquipment({ idRegistersMype }) {
  const url =
    "sec/registers_mype_tracking_equipment/" + idRegistersMype;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// crear monitoreo de agentes
async function createEquipment({
  idRegistersMype,
  body_part_protected,
  equipment_detail,
  date_delivery,
  date_revision,
  date_renewal_applicable,
  tranning_importance,
  number_workers_tranning,
  file
}) {
  if (file !== null && file !== undefined && file.length !== 0) {
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();
    file = await uploadFileCompanies(file,"documentation/registers/mypes/tracking/equipes/" + year + '/' + month + '/' + day + '/');
  } 

  const url =
    "sec/registers_mype_tracking_equipment/" + idRegistersMype;
  const data = {
    "body_part_protected": body_part_protected,
    "equipment_detail":equipment_detail,
    "date_delivery":date_delivery,
    "date_revision":date_revision,
    "date_renewal_applicable":date_renewal_applicable,
     "tranning_importance":tranning_importance,
     "number_workers_tranning":number_workers_tranning,
     "file":file
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// //   // eliminar 
  async function deleteEquipment({ idRegistersMype }) {
    const url ="sec/registers_mype_tracking_equipment/detail/"+ idRegistersMype;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }

  // // TRABAJADOR INVOLUCRADO
// // registro de trabajadores involucrados
async function createWorkersInvolved({ idRegistersMype, id_users, area, observation}) {
  const url = "sec/registers_mype_tracking_assistant/" + idRegistersMype;
  const data = {   
    "area": area,
    "observation": observation,   
    "id_users": id_users, 
    "firm":0,
    "firm_date":''    
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// // jalar registros de trabajadores involucrados
function getWorkersInvolved({ idRegistersMype }) {
    const url = "sec/registers_mype_tracking_assistant/" + idRegistersMype;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "GET",
      url: url,
    });
  }

  // // // mandar para la firma
async function updateSignWorkerInvolved({idResponsible, id_users}) {  
  const url = "sec/registers_mype_tracking_assistant/detail/" + idResponsible;

  const data = {  
    "id_users": id_users,
    "firm": 1,
    "firm_date": new Date()
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}
//   // eliminar trabajador involucrado
  async function deleteWorkerInvolved({ idWorkerInvolved }) {
    const url ="sec/registers_mype_tracking_assistant/detail/"+ idWorkerInvolved;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }
// // TRABAJADORES RESPONSABLES
// //get de trabajadores responsables
function getResponsibleWorkers({ idRegistersMype }) {
  const url = idRegistersMype +"/responsible";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// // // crear trabajadores responsables
async function createResponsibleWorkers({
  idRegistersMype,
  id_users
}) {
  const url = idRegistersMype +"/responsible";
  const data = {
    id_users: id_users,
    firm: 0,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// // // // mandar para la firma
async function updateResponsibleWorkers({idResponsible, id_users}) {
  const url = "responsible/detail/" + idResponsible;

  const data = {
    "id_users": id_users,
    "firm": 1,
    "firm_date": null
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

// // //   // eliminar
  async function deleteResponsibleWorker({ idResponsible }) {
    const url ="responsible/detail/"+ idResponsible;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }


//   // cierre de registro
  async function updateRegistersCloseStatus({idRegistersMype}) {
      const url = idRegistersMype+"/status/end";
      return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "PUT",
        url: url,
      });
    }
//capacitaciones
function getInduction ({idRegistersMype}){
  const url = `sec/registers_mype_tracking_induction/${idRegistersMype}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function postInduction({
  idRegistersMype,
  id_theme,
  date,
  number_hours,
  name_trainer
}) {
  const url = `sec/registers_mype_tracking_induction/${idRegistersMype}`;
  const data = {
    id_theme: id_theme,
    date: date,
    number_hours: number_hours,
    name_trainer: name_trainer
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function updateInduction({
  idInductionRegisterMype,
  id_theme,
  date,
  number_hours,
  name_trainer
}){
  const url = `sec/registers_mype_tracking_induction/detail/${idInductionRegisterMype}`;
  const data = {
    id_theme: id_theme,
    date: date,
    number_hours: number_hours,
    name_trainer: name_trainer
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function deleteInduction({idInductionRegisterMype}){
  const url = `sec/registers_mype_tracking_induction/detail/${idInductionRegisterMype}`;
  return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
  });

}

function getInstruction({idRegistersMype}){
  const url = `sec/registers_mype_tracking_instruction/${idRegistersMype}`;
  return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "GET",
      url: url,
  });
}
function postInstruction(
  {
    idRegistersMype,
    id_theme,
    date,
    number_hours,
    name_trainer
  }){
  const url = `sec/registers_mype_tracking_instruction/${idRegistersMype}`;
  const data = {
    id_theme: id_theme,
    date: date,
    number_hours: number_hours,
    name_trainer: name_trainer
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}
function updateInstruction({
  idInstructionRegisterMype,
  id_theme,
  date,
  number_hours,
  name_trainer
}){
  const url = `sec/registers_mype_tracking_instruction/detail/${idInstructionRegisterMype}`;
  const data = {
    id_theme: id_theme,
    date: date,
    number_hours: number_hours,
    name_trainer: name_trainer
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}
function deleteInstruction({idInstructionRegisterMype}){
  const url = `sec/registers_mype_tracking_instruction/detail/${idInstructionRegisterMype}`;
  return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
  });
}

function getTraining({idRegistersMype}){
  const url = `sec/registers_mype_tracking_training/${idRegistersMype}`;
  return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "GET",
      url: url,
  });
}
function postTraining({
  idRegistersMype,
  id_theme,
  date,
  number_hours,
  name_trainer
}) {
  const url = `sec/registers_mype_tracking_training/${idRegistersMype}`;
  const data = {
    id_theme: id_theme,
    date: date,
    number_hours: number_hours,
    name_trainer: name_trainer
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}
function updateTraining({
  idTrainingRegisterMype,
  id_theme,
  date,
  number_hours,
  name_trainer
}){
  const url = `sec/registers_mype_tracking_training/detail/${idTrainingRegisterMype}`;
  const data = {
    id_theme: id_theme,
    date: date,
    number_hours: number_hours,
    name_trainer: name_trainer
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}
function deleteTraining({idTrainingRegisterMype}){
  const url = `sec/registers_mype_tracking_training/detail/${idTrainingRegisterMype}`;
  return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
  });
}

function getSimulacrum ({idRegistersMype}){
  const url = `sec/registers_mype_tracking_simulacrum/${idRegistersMype}`;
  return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "GET",
      url: url,
  });
}
function postSimulacrum({
  idRegistersMype,
  id_theme,
  date,
  number_hours,
  name_trainer
}){
  const url = `sec/registers_mype_tracking_simulacrum/${idRegistersMype}`;
  const data = {
    id_theme: id_theme,
    date: date,
    number_hours: number_hours,
    name_trainer: name_trainer
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}
function updateSimulacrum({
  idSimulacrumRegisterMype,
  id_theme,
  date,
  number_hours,
  name_trainer
}){
  const url = `sec/registers_mype_tracking_simulacrum/detail/${idSimulacrumRegisterMype}`;
  const data = {
    id_theme: id_theme,
    date: date,
    number_hours: number_hours,
    name_trainer: name_trainer
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}
function deleteSimulacrum({idSimulacrumRegisterMype}){
  const url = `sec/registers_mype_tracking_simulacrum/detail/${idSimulacrumRegisterMype}`;
  return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
  });
}

const MyMypeTracking = {
  getRegisters,
  createRegisters,
  editRegisters,
  updateRegisters,

  // // // monitoreo de agentes
  getMonitoringAgents,
  createMonitoringAgents,
  deleteMonitoringAgents,

  // equipos
  getEquipment,
  createEquipment,
  deleteEquipment,

  // asistente
  getWorkersInvolved,
  createWorkersInvolved,
  updateSignWorkerInvolved,
  deleteWorkerInvolved,


  // // responsable
  getResponsibleWorkers,
  createResponsibleWorkers,
  deleteResponsibleWorker,
  updateResponsibleWorkers,

  // // cierre de registro
  updateRegistersCloseStatus,

  //capacitaciones
  getInduction,
  postInduction,
  updateInduction,
  deleteInduction,
  getInstruction,
  postInstruction,
  updateInstruction,
  deleteInstruction,
  getTraining,
  postTraining,
  updateTraining,
  deleteTraining,
  getSimulacrum,
  postSimulacrum,
  updateSimulacrum,
  deleteSimulacrum
};
export default MyMypeTracking;
