import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  InputLabel
} from "@mui/material";
import { Add, ArrowBack } from "@mui/icons-material";
import Container from "@mui/material/Container";

import LoadingControla from "../../components/load/loadingControla";
import SubtitleText from "../../components/text/subtitleText";
import ButtonControla from "../../components/buttons/buttonController";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import { EditorControla } from "../../components/editor/editorControla";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import TitleText from "../../components/text/titleText";
import MyRisst from "../../api/MyRisst";
import SearchButtonControla from "../../components/search/searchButtonControla";
import MyUsers from "../../api/MyUsers";
import SearchUsersControla from "../../components/search/searchUsersControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import { useAppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import ButtonSignatureControla from "../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";

function Template() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const idRistTemplate = params.get("idRisst");
  const readOnly = (params.get("readOnly") == "true") ? true : false;


  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");
  
  const userData = MyConfigurationApi.userData();
  let idUserLogged= userData.id_users;


  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  const [usersActives, setUsersActives] = useState([]);
  const handleGetUsersActives = async () => {
    const response = await MyUsers.users();
    if (response.success === false) {
      alert("Error");
    } else {
      setUsersActives(response.users_actives);
    }
  };


  const [version, setVersion] = useState("");
  const [title, setTitle] = useState("");
  const [documentText, setDocumentText] = useState(null);
  const [code, setCode] = useState("");

  const [develop, setDevelop] = useState("");
  const [dateDevelop, setDateDevelop] = useState("");
  const [revised, setRevised] = useState("");
  const [dateRevised, setDateRevised] = useState("");
  const [approved, setApproved] = useState("");
  const [dateApproved, setDateApproved] = useState("");

  const [developUrlFirm, setDevelopUrlFirm] = useState("");
  const [revisedUrlFirm, setRevisedUrlFirm] = useState("");
  const [approvedUrlFirm, setApprovedUrlFirm]= useState("");

  const[developSign, setDevelopSign] = useState("");
  const[revisedSign, setRevisedSign] = useState("");
  const[approvedSign, setApprovedSign] =useState("");

  const templateText = (textdata) => {
    setDocumentText(textdata);
  };
  // jalar y mostrar datos del usuario preparado
  const [idUsersDevelop, setIdUsersDevelop] = useState("");
  const [openModalDevelop, setOpenModalDevelop] = useState(false);
  const handleOpenModalDevelop = () => {
    setOpenModalDevelop(true);
  };

  const handleGetUsersDevelop = async (idUser) => {
    handleOpenLoading();
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setDevelop(response.first_name + " " + response.last_name);
      setIdUsersDevelop(response.id_users === null || response.id_user==='' ? idUsersDevelop : response.id_users);
      setDevelopUrlFirm(response.firm_url);
      setOpenModalDevelop(false);
      handleOpenAlert("", "success");
    }
  };

  const handleSignatureDevelop = async () => {
    handleOpenLoading();
    if (idRistTemplate == null) {
      setDevelopSign(1);
      handleOpenAlert("Se firmó con éxito", "success");
    } else {
      try {
        const response = await MyRisst.signDevelop({ idRisstTemplate: idRistTemplate });
        await handleEditRisstTemplate();
        handleOpenAlert("Se firmó con éxito", "success");
      } catch (e) {
        handleOpenAlert("Ocurrió un error al firmar", "error");
      }
    }
  } 

  // jalar y mostrar datos del usuario revisado
  const [idUsersRevised, setIdUsersRevised] = useState("");
  const [openModalRevised, setOpenModalRevised] = useState(false);
  const handleOpenModalRevised = () => {
    setOpenModalRevised(true);
  };

  const handleGetUsersRevised = async (idUser) => {
    handleOpenLoading();

    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setRevised(response.first_name + " " + response.last_name);
      setIdUsersRevised(response.id_users);
      setRevisedUrlFirm(response.firm_url);

      setOpenModalRevised(false);
      handleOpenAlert("", "success");
    }
  };

  const handleSignatureRevised = async() => {
    handleOpenLoading();
    if(idRistTemplate == null){
      setRevisedSign(1);
      handleOpenAlert("Se firmó con éxito","success");
    }else{
      try{
        const response = await MyRisst.signRevised({idRisstTemplate: idRistTemplate})
        await handleEditRisstTemplate();
        handleOpenAlert("Se firmó con éxito", "success");
      }catch(e){
        handleOpenAlert("Ocurrió un error al firmar", "error");
      }
    }
  }

  // jalar y mostrar datos del usuario revisado
  const [idUsersApproved, setIdUsersApproved] = useState("");
  const [openModalApproved, setOpenModalApproved] = useState(false);
  const handleOpenModalApproved = () => {
    setOpenModalApproved(true);
  };

  const handleGetUsersApproved = async (idUser) => {
    handleOpenLoading();

    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setApproved(response.first_name + " " + response.last_name);
      setIdUsersApproved(response.id_users);
      setApprovedUrlFirm(response.firm_url);

      setOpenModalApproved(false);
      handleOpenAlert("", "success");
    }
  };

  const handleSignatureApproved = async () => {
    handleOpenLoading();
    if (idRistTemplate == null) {
      setApprovedSign(1);
      handleOpenAlert("Se firmó con éxito", "success");
    } else {
      try {
        await MyRisst.signApproved({ idRisstTemplate: idRistTemplate });

        await handleEditRisstTemplate();
        handleOpenAlert("Se firmó con éxito", "success");
      } catch (e) {
        handleOpenAlert("Ocurrió un error", "error");
      }
    }
  } 

  

  //   crear
  const handleCreateRissTemplate = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyRisst.createRisstTemplate({
      code: code,
      develop: develop,
      revised: revised,
      approved: approved,
      version: version,
      title: title,
      document_text: documentText,
      idUsersDevelop:idUsersDevelop,
      idUsersRevised:idUsersRevised,
      idUsersApproved:idUsersApproved,
      dateDevelop,
      dateRevised,
      dateApproved,

      developSign,
      revisedSign,
      approvedSign,

      developUrlFirm,
      revisedUrlFirm,
      approvedUrlFirm
    });
    handleOpenAlert("El documento se ha creado con éxito", "success");
    navigate( "/documentation/risst?idTabs=0");
    return response;
  };

  const defaultTemplate = `
    <h2>RISST - Reglamento interno de seguridad y salud en el trabajo</h2>
    <h3> I.RESUMEN EJECUTIVO DE LA ACTIVIDAD DE LA EMPRESA</h3>
    <h3> II.OBJETIVOS Y ALCANCES</h3>
    <h3> III. LIDERAZGO Y COMPROMISOS, Y POLÍTICA DE SEGURIDAD Y SALUDA</h3>
      <ul>
        <li>Liderazgo y compromisos</li>
        <li>Política de seguridad y salud</li>
    </ul>
    <h3> IV. ATRIBUCIONES Y OBLIGACIONES DEL EMPLEADOR, DE LOS
    SUPERVISORES, DEL COMITÉ DE SEGURIDAD Y SALUD, DE LOS
    TRABAJADORES Y DE LOS EMPLEADORES QUE LES BRINDAN SERVICIOS SI
    LOS HUBIERA</h3>
        <ul>
            <li>Funciones y responsabilidades</li>
            <li>Organización interna de seguridad y salud ocupaciona</li>
            <li>
              Implementación de registros y documentación del Sistema de
              Gestión de Seguridad y salud ocupacional
            </li>
            <li>
              Funciones y responsabilidades de las empresas, entidades
              públicas o privadas que brindan servicios
            </li>
        </ul>
    <h3> V.ESTÁNDARES DE SEGURIDAD Y SALUD EN LAS OPERACIONES</h3>
    <h3> VI. ESTÁNDARES DE SEGURIDAD Y SALUD EN LOS SERVICIOS Y
    ACTIVIDADES CONEXAS</h3>
  <h3> VII. PREPARACIÓN Y RESPUESTA A EMERGENCIAS</h3>`;        
  ;

  // Editar temlplate
  // editar rist document

  const handleEditRisstTemplate = async () => {
    handleOpenLoading();

    const response = await MyRisst.editRisstTemplate({
      idRisstTemplate: idRistTemplate,
    });
    setDevelopUrlFirm(response.develop_url_firm);
    setRevisedUrlFirm(response.revised_url_firm);
    setApprovedUrlFirm(response.approved_url_firm);

    setDevelop(response.develop);
    setCode(response.code);
    setApproved(response.approved);
    setDevelop(response.develop);
    setRevised(response.revised);
    setVersion(response.version);
    setTitle(response.title);
    setIdUsersApproved(response.id_users_approved);
    setIdUsersDevelop(response.id_users_develop);
    setIdUsersRevised(response.id_users_revised);
    setDocumentText(response.document_text);
    setDateApproved(response.date_approved);
    setDateRevised(response.date_revised);
    setDateDevelop(response.date_develop);
    
    setDevelopSign(response.develop_sign);
    setRevisedSign(response.revised_sign);
    setApprovedSign(response.approved_sign);

    setOpenLoading(false);
    // return response;
  };

  const handleUpdateRisstTemplate = async () => {
    handleOpenLoading();
    const response = await MyRisst.updateRisstTemplate({

      idRisstTemplate: idRistTemplate,
      code: code,
      develop: develop,
      revised: revised,
      approved: approved,
      version: version,
      title: title,
      document_text: documentText,
      idUsersDevelop,
      idUsersRevised,
      idUsersApproved,
      dateDevelop,
      dateRevised,
      dateApproved
    });
 
    handleOpenAlert("Los datos fueron editados de forma correcta", "success");
    return response;
  };

  // Dialogo de aviso de falta de fecha INICIO
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
  }; 

  useEffect(() => {
    if(idRistTemplate != null) handleEditRisstTemplate();
    handleGetUsersActives();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <Container>
        <form onSubmit={handleCreateRissTemplate}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText text={"RISST"} color={colorTitle}></SubtitleText>
               {idRistTemplate === null || idRistTemplate === undefined ? 
              <TitleText text={"Plantilla RISST"}></TitleText>
              :
              <TitleText text={"RISST"}></TitleText>
               }
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <InputControlaVertical
                roles={permissions.DOC_RISST_MODEL_EDITAR}
                text={"Código:"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <InputControlaVertical
                roles={permissions.DOC_RISST_MODEL_EDITAR}
                text={"Versión:"}
                inputType={"text"}
                inputValue={setVersion}
                value={version}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <InputControlaVertical
                roles={permissions.DOC_RISST_MODEL_EDITAR}
                text={"Título documento:"}
                inputType={"text"}
                inputValue={setTitle}
                value={title}
                required
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <SearchButtonControla
                roles={permissions.DOC_RISST_MODEL_ELABORADO}
                titleLabel="Elaborado por: "
                inputValue={develop}
                functionClick={handleOpenModalDevelop}
                required
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"Fecha de Elaboración:"}
                inputType={"date"}
                inputValue={setDateDevelop}
                value={dateDevelop}
                readOnly={ readOnly ? true : (idUsersDevelop == idUserLogged ? false: true) }
                required
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Stack>
                <Box>
                  <InputLabel
                    sx={{
                      padding: "8px",
                      fontSize: "18px",
                      color: "#305AD9",
                      fontWeight: "bold",
                      textAlign: "center"
                    }}
                  >
                    Firma
                  </InputLabel>
                </Box>

                <Box sx={{textAlign: "center"}}>
                  <ButtonSignatureControla
                    idUser={idUsersDevelop}
                    firmStatus={developSign}
                    firmUrl={developUrlFirm}
                    functionOnClick={async() => { await handleSignatureDevelop()}}
                  />
                </Box>
              </Stack>
            </Grid>


            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <SearchButtonControla
                roles={permissions.DOC_RISST_MODEL_REVISADO}
                titleLabel="Revisado por: "
                inputValue={revised}
                functionClick={handleOpenModalRevised}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"Fecha de Revisión:"}
                inputType={"date"}
                inputValue={setDateRevised}
                value={dateRevised}
                readOnly={readOnly? true:(idUsersRevised == idUserLogged ? false : true)}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Stack>
                <Box>
                  <InputLabel
                    sx={{
                      padding: "8px",
                      fontSize: "18px",
                      color: "#305AD9",
                      fontWeight: "bold",
                      textAlign: "center"
                    }}
                  >
                    Firma
                  </InputLabel>
                </Box>

                <Box sx={{textAlign: "center"}}>
                  <ButtonSignatureControla
                    idUser={idUsersRevised}
                    firmStatus={revisedSign}
                    firmUrl={revisedUrlFirm}
                    functionOnClick={
                      dateRevised == ""
                        ? () => handleOpenModalConfirm()
                        : async() => await handleSignatureRevised()
                    }
                  />
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <SearchButtonControla
                roles={permissions.DOC_RISST_MODEL_APROBADO}
                titleLabel="Aprobado por: "
                inputValue={approved}
                functionClick={handleOpenModalApproved}
              /> 
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"Fecha de Aprobación:"}
                inputType={"date"}
                inputValue={setDateApproved}
                value={dateApproved}
                readOnly={readOnly? true:(idUsersApproved == idUserLogged ? false :true)}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Stack>
                <Box>
                  <InputLabel
                   sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                  >
                    Firma
                  </InputLabel>
                </Box>

                <Box sx={{textAlign: "center"}}>
                  <ButtonSignatureControla
                      idUser={idUsersApproved}
                      firmStatus={approvedSign}
                      firmUrl={approvedUrlFirm}
                      functionOnClick={
                          dateApproved == ""
                            ? () => handleOpenModalConfirm()
                            : async () =>  await handleSignatureApproved()
                      }
                  />
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EditorControla
                roles={permissions.DOC_RISST_MODEL_EDITAR}
                defaultTemplate={defaultTemplate}
                dataEdit={documentText}
                handleDataEditor={templateText}
              />
            </Grid>
          </Grid>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"center"}
            mt={2}
            mb={2}
          >
            <Box>
              <ButtonControla
                iconButton={<ArrowBack sx={{ color: "white" }} />}
                backgroundColor={"#C61C39"}
                backgroundColorHover={"#D61F3E"}
                textButton={"Regresar"}
                typeButton={"button"}
                isNavegationOrigin={true}
                url={"/documentation/risst"}
              />
            </Box>
            {idRistTemplate === null || idRistTemplate === undefined ? (
              <Box>
                <ButtonControla
                  roles = {permissions.DOC_RISST_MODEL_GUARDAR}
                  iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Guardar"}
                  typeButton={"submit"}
                />
              </Box>
            ) : ( !readOnly && (
              <Box>
                <ButtonControla
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Actualizar"}
                  functionOnClick={() => handleUpdateRisstTemplate()}
                />
              </Box>
            ) 
            )}
          </Stack>
        </form>
      </Container>

      {/* Buscar responsables */}
      {/* Elaborado por */}
      <Dialog
        open={openModalDevelop}
        onClose={() => {
          setOpenModalDevelop(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de elaborar la plantilla RISST
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersDevelop}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalDevelop(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Revisado por */}
      <Dialog
        open={openModalRevised}
        onClose={() => {
          setOpenModalRevised(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Revisar la plantilla RISST
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersRevised}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalRevised(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Revisado por */}
      <Dialog
        open={openModalApproved}
        onClose={() => {
          setOpenModalApproved(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Aprobar la plantilla RISST
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersApproved}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalApproved(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* dialogo de falta de fecha */}
      <Dialog
        open={openModalConfirm}
        onClose={() => setOpenModalConfirm(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Para firmar es necesario consignar fecha.
          </Typography>
        </DialogTitle>

        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{
              textTransform: "none",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => setOpenModalConfirm(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Template;
