import { React, useState, useEffect } from "react";
import SubtitleText from "../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Typography,
  Stack,
  DialogActions,
  MenuItem,
} from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  Delete,
  Event,
  PictureAsPdf,
} from "@mui/icons-material";
import TitleText from "../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import MyPrograms from "../../../api/MyPrograms";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import CompanyLogoControla from "../../../components/images/companyLogoControla";
import ButtonControla from "../../../components/buttons/buttonController";
import LoadingControla from "../../../components/load/loadingControla";
import SelectControla from "../../../components/selects/selectControla";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";
import DialogConfirmControla from "../../../components/dialog/dialogConfirmControla";
import SearchButtonControla from "../../../components/search/searchButtonControla";
import SearchUsersControla from "../../../components/search/searchUsersControla";
import MyUsers from "../../../api/MyUsers";
import ReturnBack from "../../../components/buttons/returnBackControla";


const skyblue = "#2D9CDB";

const StyledTypography = styled(Typography)({
  color: "#305AD9",
  fontSize: 18,
  fontWeight: 600,
  gap: "2%",
  padding: "0.5em",
});

const StyledAddButton = styled(Button)({
  textTransform: "none",
  backgroundColor: skyblue,
  borderRadius: 16,
});

function ObjectiveGeneral() {
  const params = new URLSearchParams(window.location.search);
  const idobjectivegeneral = params.get("id_og");
  const nameobjectivegeneral = params.get("name_og");
  const idprograms = params.get("id_programs");
  const readOnly = params.get("readOnly") == "true" ? true : false;

  const weeks = [1, 2, 3, 4];
  const months = [
    {
      id: 1,
      month: "Enero",
    },
    {
      id: 2,
      month: "Febrero",
    },
    {
      id: 3,
      month: "Marzo",
    },
    {
      id: 4,
      month: "Abril",
    },
    {
      id: 5,
      month: "Mayo",
    },
    {
      id: 6,
      month: "Junio",
    },
    {
      id: 7,
      month: "Julio",
    },
    {
      id: 8,
      month: "Agosto",
    },
    {
      id: 9,
      month: "Septiembre",
    },
    {
      id: 10,
      month: "Octubre",
    },
    {
      id: 11,
      month: "Noviembre",
    },
    {
      id: 12,
      month: "Diciembre",
    },
  ];

  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // Pantallas de carga y alertas FIN

  // Dialogos de confirmacion INICIO
  const [idAction, setIdAction] = useState("");

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdAction(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);

    setIdObjetiveSpecific(id);
    setIdActivity(id);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "executeProgram":
        handleStoreProgrammingExecute();
        break;
      case "deleteColumn":
        handleDeleteColumn();
        break;
      case "deleteObjEspecific":
        handleDeleteObjectiveSpecifics();
        break;
      case "deleteActivity":
        handleDeleteObjetivesActivity();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  useEffect(() => {
    //handleSearchObjectiveGeneral();
    handleGetObjectiveSpecific();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    handleGetObjectiveGeneral();
    handleGetActivities();
    handleGetProgramming();
    handleGetProgrammingColumns();
    handleGetColumnsData();
    handleGetProgrammingProgramming();
  }, []);

  const [openModalLegend, setOpenModalLegend] =
    useState(false);

  const handleOpenModalLegend = () => {
    setOpenModalLegend(true);
  };

  const handleCloseModalLegend = () => {
    setOpenModalLegend(false);
  };

  const [openModalCreateActivities, setOpenModalCreateActivities] =
    useState(false);

  const handleOpenModalCreateActivities = () => {
    setOpenModalCreateActivities(true);
  };

  const handleCloseModalCreateActivities = () => {
    setOpenModalCreateActivities(false);
  };

  /* OBJETIVOS GENERALES */

  const [currentSymbol, setCurrentSymbol] = useState("");
  const handleGetObjectiveGeneral = async () => {
    handleOpenLoading();
    
    const response = await MyPrograms.editObjetiveGeneral({
      idobjectivegeneral,
    });

    setCurrentSymbol((response.currency_symbol === 'Soles' ? 'S/.' : '$'));

    setOpenLoading(false);

  };

  const handleGetObjectiveSpecific = async () => {
    const response = await MyPrograms.getObjetiveSpecific({
      idobjectivegeneral,
    });

    if (response.success !== false) {
      setRowsObjectiveSpecific(response);

    } else {
    }

    return response;
  };

  const handleGetColumnsData = async () => {
    const response = await MyPrograms.getProgrammingColumnsData({
      idobjectivegeneral,
    });

    if (response.success === false) {
      // setAlertMessage(response.message);
      // setOpenLoadView(false);
    } else {
      setRowsColumnsData(response);
    }

    return response;
  };

  const handleGetProgrammingProgramming = async () => {
    const response = await MyPrograms.getProgrammingProgramming({
      idobjectivegeneral,
    });

    if (response.success === false) {
      // setAlertMessage(response.message);
      // setOpenLoadView(false);
    } else {
      setRowsProgrammingProgramming(response);
    }

    return response;
  };
  const [activity, setActivity] = useState("");

  const [openModalSelectUser, setOpenModalSelectUser] = useState(false);

  const handleOpenModalSelectUser = () => {
    handleUsers();
    setOpenModalSelectUser(true);
  };

  const handleCloseModalSelectUser = () => setOpenModalSelectUser(false);

  const [rowsUsers, setRowsUsers] = useState([]);

  const handleUsers = async () => {
    const response = await MyUsers.users();

    if (response.success !== false) {
      setRowsUsers(response.users_actives);
    } else {
      alert("Error");
    }
    return response;
  };

  const handleSelectUser = async (idUsers) => {
    const response = await MyUsers.editUsers({ idUsers: idUsers });

    if (response.success !== false) {
      setIdResponsible(idUsers);
      setResponsible(
        response.document +
        " - " +
        response.first_name +
        " " +
        response.last_name
      );
      handleCloseModalSelectUser();
    } else {
      alert("ERROR");
    }
    return response;
  };

  const [rowsObjectiveSpecific, setRowsObjectiveSpecific] = useState([]);
  const [rowsActivities, setRowsActivities] = useState([]);
  const [rowsProgramming, setRowsProgramming] = useState([]);
  const [rowsColumns, setRowsColumns] = useState([]);
  const [rowsColumnsData, setRowsColumnsData] = useState([]);
  const [rowsProgrammingProgramming, setRowsProgrammingProgramming] = useState(
    []
  );
  /*  OBJETIVO ESPECIFICO */
  const [idObjetiveSpecific, setIdObjetiveSpecific] = useState();
  const [objetiveSpecific, setObjetiveSpecific] = useState("");
  const [goals, setGoals] = useState("");
  const [indicador, setIndicador] = useState("");
  const [orderNumber, setOrderNumber] = useState(0);

  const [openModalAddObjectiveSpecific, setOpenAddObjectiveSpecific] =
    useState(false);
  const openAgregarObjetivoEspecifico = () => {
    setOpenAddObjectiveSpecific(true);
  };

  const closeAgregarObjetivo = () => {
    setOpenAddObjectiveSpecific(false);
  };

  const openEditarObjetivoEspecifico = (
    id_programs_objectives_general,
    programs_objectives_general,
    currency_symbol,
    indicators,
    orderNumber
  ) => {
    setIdObjectiveGeneral(id_programs_objectives_general);
    setObjetiveSpecific(programs_objectives_general);
    setGoals(currency_symbol);
    setIndicador(indicators);
    setOrderNumber(orderNumber);
    setOpenEdit(true);
  };

  // crear objetivo especifico
  const handlePostCreateObjectiveSpecific = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyPrograms.createObjetiveSpecific({
      id_programs_objectives_general: idobjectivegeneral,
      programs_objectives_specific: objetiveSpecific,
      goals: goals,
      indicators: indicador,
      order_number: orderNumber,
    });
    if (response.success === true) {
      closeAgregarObjetivo();
      handleGetObjectiveSpecific();
      handleOpenAlert(response.msg, "success");
    }
    return response;
  };

  /* ACTUALIZAR OBJETIVOS espeficicos */
  const handleUpdateObjetivoEspecifico = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPrograms.updateObjetiveSpecific({
      id_programs_objectives_specific: idObjectiveGeneralEdit,
      programs_objectives_specific: objetiveSpecific,
      goals: goals,
      indicators: indicador,
      order_number: orderNumber,
    });
    if (response.success === true) {
      handleGetObjectiveSpecific();
      closeEditarObjetivo();
      handleOpenAlert(response.msg, "success");
    }
    return response;
  };
  /* EDITAR OBEJTIVO ESPEFICICO */
  const [openModalEdit, setOpenEdit] = useState(false);

  const closeEditarObjetivo = () => {
    setOpenEdit(false);
  };

  // eliminr objetivo específico
  const handleDeleteObjectiveSpecifics = async () => {
    handleOpenLoading();

    const response = await MyPrograms.deleteObjetiveSpecific({
      id_programs_objectives_specific: idObjetiveSpecific,
    });
    if (response.success !== false) {
      handleGetObjectiveSpecific();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");

    }
    return response;
  };


  // ***********************  ACTIVIDADES  **************************
  const [idActivity, setIdActivity] = useState(0);
  const [openModalEditActivity, setOpenModalEditActivity] = useState(false);
  const [idActivitiesEdit, setIdActivitiesEdit] = useState(null);
  const [idObjectiveGeneralEdit, setIdObjectiveGeneral] = useState(null);
  const [orderNumberActivity, setOrderNumberActivity] = useState(null);


  const handleCloseModalEditActivity = () => {
    setOpenModalEditActivity(false);
  };

  // listando actividades
  const handleGetActivities = async () => {
    const response = await MyPrograms.getActivities({ idobjectivegeneral });
    if (response.success !== false) {
      setRowsActivities(response);
    } else {
    }
    return response;
  };

  // creando actividades
  const handlePostCreateActivity = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyPrograms.createActivities({
      id_programs_objectives_general: idobjectivegeneral,
      programs_objectives_activities: activity,
      order_number: orderNumberActivity,
    });
    if (response.success !== false) {
      handleGetActivities();
      handleCloseModalCreateActivities();
      handleOpenAlert(response.msg, "success");

    }
    return response;
  };

  // actualizando actividades
  const handleOpenEditActivity = (
    id_programs_objectives_activities,
    programs_objectives_activities,
    order_number
  ) => {
    setActivity(programs_objectives_activities);
    setOrderNumberActivity(order_number);
    setIdActivitiesEdit(id_programs_objectives_activities);
    setOpenModalEditActivity(true);
  };

  const handleUpdateActivity = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPrograms.updateActivities({
      id_programs_objectives_activities: idActivitiesEdit,
      programs_objectives_activities: activity,
      order_number: orderNumberActivity,
    });
    if (response.success !== false) {
      handleGetActivities();
      handleCloseModalEditActivity();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  // eliminr actividades
  const handleDeleteObjetivesActivity = async () => {
    handleOpenLoading();
    const response = await MyPrograms.deleteObjetivesActivity({
      id_programs_objectives_activity: idActivity,
    });
    if (response.success !== false) {
      handleGetActivities();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");

    }
    return response;
  };

  const handleGetProgramming = async () => {
    const response = await MyPrograms.getProgramming({ idobjectivegeneral });

    if (response.success === false) {
      // setAlertMessage(response.message);
      // setOpenLoadView(false);
    } else {
      setRowsProgramming(response);
    }

    return response;
  };

  const handleGetProgrammingColumns = async () => {
    const response = await MyPrograms.getProgrammingColumns({
      idobjectivegeneral,
    });

    if (response.success === false) {
      // setAlertMessage(response.message);
      // setOpenLoadView(false);
    } else {
      setRowsColumns(response);
    }

    return response;
  };

  const handleSearchColumnData = (idColumns, idProgramming) => {
    const columnId = "c" + idColumns;
    const programmingId = "p" + idProgramming;

    let data = "";

    // let columnData = rowsColumnsData.columnId.programmingId.filter(x => column.test(x));
    if (rowsColumnsData != []) {
      let columnData = [];
      try {
        columnData = rowsColumnsData[`${columnId}`][`${programmingId}`];
        if (columnData) {
          data = columnData["programs_objectives_programming_columns_data"];
        }
      } catch (error) {
        columnData = [];
      }
    }

    return data;
    // return columnData.programs_objectives_programming_columns_data;
  };

  const handleSearchProgrammingProgramming = (idProgramming, month, week) => {
    const programmingId = "p" + idProgramming;
    const monthId = "m" + month;
    const weekId = "w" + week;

    let data = "";
    // let columnData = rowsColumnsData.columnId.programmingId.filter(x => column.test(x));
    if (rowsProgrammingProgramming != []) {
      let programmingProgramming = [];
      try {
        programmingProgramming =
          rowsProgrammingProgramming[`${programmingId}`][`${monthId}`][
          `${weekId}`
          ];
        if (
          programmingProgramming[
          "id_programs_objectives_programming_rels_programming"
          ] !== undefined
        ) {
          let backgroundColor = "green";
          let programmingType = "P";
          let color = "white";
          switch (
          programmingProgramming[
          "id_programs_objectives_programming_rels_programming_types"
          ]
          ) {
            case 1:
              backgroundColor = "#169073";
              programmingType = "P";
              color = "white";
              break;
            case 2:
              backgroundColor = "#F2994A";
              programmingType = "R";
              color = "white";
              break;
            case 3:
              backgroundColor = "#2D9CDB";
              programmingType = "E";
              color = "white";
              break;
          }
          data = (
            <Box>
              <Box sx={{ textAlign: "center", minWidth: "70px" }}>
                <Typography
                  sx={{
                    backgroundColor: backgroundColor,
                    color: color,
                    padding: "2px 5px",
                    borderRadius: "8px",
                    textAlign: "center",
                  }}
                >
                  {programmingProgramming[
                    "id_programs_objectives_programming_rels_programming"
                  ] +
                    " - " +
                    programmingType}
                </Typography>
              </Box>
              {programmingProgramming.reprogramming == 1 ? (
                !readOnly && (
                  <Box sx={{ marginTop: 1, textAlign: "center" }}>
                    <Stack direction="row" spacing={1}>
                      <Box sx={{ textAlign: "center" }}>
                        <IconButton
                          sx={{
                            borderRadius: "50%",
                            color: "white",
                            width: "30px",
                            height: "30px",
                            backgroundColor: "#F2994A",
                          }}
                          onClick={() => {
                            handleOpenModalProgrammingReprogram(
                              programmingProgramming.id_programs_objectives_programming_rels_programming
                            );
                          }}
                        >
                          <Typography>R</Typography>
                        </IconButton>
                      </Box>
                      <Box sx={{ textAlign: "center" }}>
                        <IconButton
                          sx={{
                            borderRadius: "50%",
                            color: "white",
                            width: "30px",
                            height: "30px",
                            backgroundColor: "#2D9CDB",
                          }}
                          onClick={() => {
                            handleOpenModalConfirm(
                              programmingProgramming.id_programs_objectives_programming_rels_programming_changelog,
                              "Executar programa",
                              "¿Estas seguro de ejecutar y cerrar este programa?",
                              "Si, ejecutar",
                              "executeProgram"
                            );
                          }}
                        >
                          <Typography>E</Typography>
                        </IconButton>
                      </Box>
                    </Stack>
                  </Box>
                )

              ) : (
                ""
              )}
            </Box>
          );
        }
      } catch (error) {
        programmingProgramming = [];
      }
    }

    return data;
  };

  function unique(arr) {
    let result = [];

    for (let str of arr) {
      if (!result.includes(str)) {
        result.push(str);
      }
    }

    return result;
  }

  const handleSearchProgrammingTotal = (idProgramming) => {
    let total = [];
    months.forEach((month) => {
      weeks.forEach((week) => {
        const programmingId = "p" + idProgramming;
        const monthId = "m" + month.id;
        const weekId = "w" + week;
        let programmingProgramming = [];
        try {
          programmingProgramming =
            rowsProgrammingProgramming[`${programmingId}`][`${monthId}`][
            `${weekId}`
            ];
          if (
            programmingProgramming[
            "id_programs_objectives_programming_rels_programming"
            ] !== undefined
          ) {
            total.push(
              programmingProgramming[
              "id_programs_objectives_programming_rels_programming"
              ]
            );
          }
        } catch (error) {
          programmingProgramming = [];
        }
      });
    });
    const totalCount = unique(total).length;

    return totalCount;
  };

  const handleSearchProgrammingPercentage = (idProgramming) => {
    let total = [];
    let complete = [];
    months.forEach((month) => {
      weeks.forEach((week) => {
        const programmingId = "p" + idProgramming;
        const monthId = "m" + month.id;
        const weekId = "w" + week;
        let programmingProgramming = [];
        try {
          programmingProgramming =
            rowsProgrammingProgramming[`${programmingId}`][`${monthId}`][
            `${weekId}`
            ];
          if (
            programmingProgramming[
            "id_programs_objectives_programming_rels_programming"
            ] !== undefined
          ) {
            total.push(
              programmingProgramming[
              "id_programs_objectives_programming_rels_programming"
              ]
            );
            if (
              programmingProgramming[
              "id_programs_objectives_programming_rels_programming_types"
              ] == 3
            ) {
              complete.push(
                programmingProgramming[
                "id_programs_objectives_programming_rels_programming"
                ]
              );
            }
          }
        } catch (error) {
          programmingProgramming = [];
        }
      });
    });

    const totalCount = unique(total).length;
    const completeCount = unique(complete).length;
    const percentage = (completeCount * 100) / totalCount;

    return percentage;
  };

  const [programmingId, setProgrammingId] = useState("");

  // PROGRAMAR
  const [monthId, setMonthId] = useState("");
  const [weekId, setWeekId] = useState("");

  const [openModalProgrammingProgram, setOpenModalProgrammingProgram] =
    useState(false);

  const handleOpenModalProgrammingProgram = (programmingId) => {
    setProgrammingId(programmingId);
    setOpenModalProgrammingProgram(true);
  };

  const handleCloseModalProgrammingProgram = () => {
    setOpenModalProgrammingProgram(false);
  };

  const handleStoreProgrammingProgram = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPrograms.createProgrammingProgram({
      idProgramsProgramming: programmingId,
      month: monthId,
      week: weekId,
    });

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else if (response.success === true) {
      handleGetProgrammingProgramming();
      handleOpenAlert(response.msg, "success");
      handleCloseModalProgrammingProgram();
    } else {
      alert("Hubo un error general");
    }

    return response;
  };

  // REPROGRAMAR
  const [programmingProgram, setProgrammingProgram] = useState("");

  const [openModalProgrammingReprogram, setOpenModalProgrammingReprogram] =
    useState(false);

  const handleOpenModalProgrammingReprogram = (programmingProgramId) => {
    setProgrammingProgram(programmingProgramId);
    setOpenModalProgrammingReprogram(true);
  };

  const handleCloseModalProgrammingReprogram = () => {
    setOpenModalProgrammingReprogram(false);
  };

  const handleStoreProgrammingReprogram = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPrograms.updateProgrammingReprogram({
      idProgramsProgram: programmingProgram,
      month: monthId,
      week: weekId,
    });

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else if (response.success === true) {
      handleGetProgrammingProgramming();
      handleOpenAlert(response.msg, "success");
      handleCloseModalProgrammingReprogram();
    } else {
      alert("Hubo un error general");
    }

    return response;
  };

  const handleStoreProgrammingExecute = async () => {
    handleOpenLoading();
    const response = await MyPrograms.updateProgrammingExecute({
      idProgramsProgramChangeLog: idAction,
    });

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else if (response.success === true) {
      handleGetProgrammingProgramming();
      handleOpenAlert(response.msg, "success");
      handleCloseModalProgrammingReprogram();
    } else {
      alert("Hubo un error general");
    }

    return response;
  };

  // REPROGRAMAR
  const [programmingColumn, setProgrammingColumn] = useState("");

  const [openModalCreateColumns, setOpenModalCreateColumns] = useState(false);

  const handleOpenModalCreateColumns = () => {
    setOpenModalCreateColumns(true);
  };

  const handleCloseModalCreateColumns = () => {
    setOpenModalCreateColumns(false);
  };

  const handlePostCreateColumn = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPrograms.createProgrammingColumns({
      idobjectivegeneral: idobjectivegeneral,
      programmingColumn: programmingColumn,
    });

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else if (response.success === true) {
      handleGetProgrammingColumns();
      handleGetColumnsData();
      handleOpenAlert(response.msg, "success");
      handleCloseModalCreateColumns();
    } else {
      alert("Hubo un error general");
    }

    return response;
  };

  // REPROGRAMAR

  const [idProgrammingColumn, setIdProgrammingColumn] = useState("");

  const [openModalEditColumns, setOpenModalEditColumns] = useState(false);

  const handleOpenModalEditColumns = (
    idProgrammingColumn,
    programmingColumn
  ) => {
    setProgrammingColumn(programmingColumn);
    setIdProgrammingColumn(idProgrammingColumn);
    setOpenModalEditColumns(true);
  };

  const handleCloseModalEditColumns = () => {
    setOpenModalEditColumns(false);
  };

  const handleUpdateColumn = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPrograms.updateProgrammingColumns({
      idProgrammingColumn: idProgrammingColumn,
      programmingColumn: programmingColumn,
    });

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else if (response.success === true) {
      handleGetProgrammingColumns();
      handleGetColumnsData();
      handleOpenAlert(response.msg, "success");
      handleCloseModalEditColumns();
    } else {
      alert("Hubo un error general");
    }

    return response;
  };

  const handleDeleteColumn = async () => {
    handleOpenLoading();
    const response = await MyPrograms.deleteProgrammingColumns({
      idProgrammingColumn: idAction,
    });

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else if (response.success === true) {
      handleGetProgrammingColumns();
      handleGetColumnsData();
      handleOpenAlert(response.msg, "success");
      handleCloseModalEditColumns();
    } else {
      alert("Hubo un error general");
    }

    return response;
  };

  // REPROGRAMAR
  const [idResponsible, setIdResponsible] = useState("");
  const [responsible, setResponsible] = useState("");

  const [resources, setResources] = useState("");
  const [programming, setProgramming] = useState("");
  const [budgets, setBudgets] = useState("");

  const [openModalCreateProgramming, setOpenModalCreateProgramming] =
    useState(false);

  const handleOpenModalCreateProgramming = () => {
    setResponsible("");
    setIdResponsible("");
    setOpenModalCreateProgramming(true);
  };

  const handleCloseModalCreateProgramming = () => {
    setOpenModalCreateProgramming(false);
  };

  const handleCreateProgramming = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    let columnsData = {};

    rowsColumns.map(
      (rowColumn) =>
      (columnsData[rowColumn.id_programs_objectives_programming_columns] =
        document.getElementById(
          "columnDataCreate" +
          rowColumn.id_programs_objectives_programming_columns
        ).value)
    );

    const response = await MyPrograms.createProgramming({
      idobjectivegeneral: idobjectivegeneral,
      idResponsible: idResponsible,
      resources: resources,
      programming: programming,
      budgets: budgets,
      columnsData: columnsData,
    });

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else if (response.success === true) {
      handleGetProgramming();
      handleGetColumnsData();
      handleOpenAlert(response.msg, "success");
      handleCloseModalCreateProgramming();
    } else {
      alert("Hubo un error general");
    }

    return response;
  };

  const [openModalEditProgramming, setOpenModalEditProgramming] =
    useState(false);

  const handleOpenModalEditProgramming = (
    programmingId,
    responsible,
    idResponsible,
    resources,
    programming,
    budgets
  ) => {
    setProgrammingId(programmingId);
    setResponsible(responsible);
    setIdResponsible(idResponsible);
    setResources(resources);
    setProgramming(programming);
    setBudgets(budgets);
    setOpenModalEditProgramming(true);
  };

  const handleCloseModalEditProgramming = () => {
    setOpenModalEditProgramming(false);
  };

  const handleUpdateProgramming = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    let columnsData = {};

    rowsColumns.map(
      (rowColumn) =>
      (columnsData[rowColumn.id_programs_objectives_programming_columns] =
        document.getElementById(
          "columnDataEdit" +
          rowColumn.id_programs_objectives_programming_columns
        ).value)
    );

    const response = await MyPrograms.updateProgramming({
      programmingId: programmingId,
      idResponsible: idResponsible,
      resources: resources,
      programming: programming,
      budgets: budgets,
      columnsData: columnsData,
    });

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else if (response.success === true) {
      handleGetProgramming();
      handleGetColumnsData();
      handleOpenAlert(response.msg, "success");
      handleCloseModalEditProgramming();
    } else {
      alert("Hubo un error general");
    }

    return response;
  };

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
            <Grid item md={12}>
              <SubtitleText
                text={"Programa anual de SSO y otros"}
                color={"#9191B0"}
              ></SubtitleText>
            </Grid>
            <Grid item md={10}>
              <Stack direction="row" justifyContent={"flex-first"}>
                <TitleText text={nameobjectivegeneral} />
              </Stack>
            </Grid>
            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <StyledTypography
              sx={{
                padding: "8px",
                fontSize: "20px",
                color: "#305AD9",
                fontWeight: "bold",
              }}
            >
              Objetivos especificos
            </StyledTypography>
          </Grid>
          {
            !readOnly && (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} textAlign="right">
                <ButtonControla
                  roles={[1, 3, 4, 7, 8]}
                  iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#2D9CDB"}
                  textButton={"Agregar objetivo específico"}
                  typeButton={"button"}
                  functionOnClick={openAgregarObjetivoEspecifico}
                />
              </Grid>
            )
          }

          

          <Grid itemxs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
            <TableContainer component={Paper}>
              <Table stickyHeader sx={{ width: "100%", minWidth: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        color: "#305ad9",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Número de objetivo
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "#305ad9",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Objetivo Especifico
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "#305ad9",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Meta
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "#305ad9",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Indicador
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsObjectiveSpecific.map((rowsObjectiveSpecific) => (
                    <TableRow
                      hover
                      key={
                        rowsObjectiveSpecific.id_programs_objectives_specific
                      }
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">
                        {rowsObjectiveSpecific.order_number}
                      </TableCell>
                      <TableCell align="center">
                        {rowsObjectiveSpecific.programs_objectives_specific}
                      </TableCell>
                      <TableCell align="center">
                        {rowsObjectiveSpecific.goals}%
                      </TableCell>
                      <TableCell align="center">
                        {rowsObjectiveSpecific.indicators}
                      </TableCell>
                      {
                        !readOnly && (
                          <TableCell align="right">
                        <Stack
                          direction="row"
                          justifyContent="end"
                          alignItems="center"
                        >
                          <ButtonIconControla
                            roles={[1, 3, 4, 7, 8]}
                            icon={ <BorderColorOutlined sx={{ color: "white" }} /> }
                            backgroundColor={"#2D9CDB"}
                            textTooltip={"Editar"}
                            functionOnClick={() =>
                              openEditarObjetivoEspecifico(
                                rowsObjectiveSpecific.id_programs_objectives_specific,
                                rowsObjectiveSpecific.programs_objectives_specific,
                                rowsObjectiveSpecific.goals,
                                rowsObjectiveSpecific.indicators,
                                rowsObjectiveSpecific.order_number
                              )
                            }
                          />
                          <ButtonIconControla
                            roles={[1, 3, 4, 7, 8]}
                            icon={<Delete sx={{ color: "white" }} />}
                            backgroundColor={"#EB5757"}
                            backgroundColorHover={"#FF4040"}
                            textTooltip={"Eliminar"}
                            functionOnClick={() => {
                              handleOpenModalConfirm(
                                rowsObjectiveSpecific.id_programs_objectives_specific,
                                "¿Esta seguro de eliminar el objetivo espefíco?",
                                'Una vez eliminado no se podra recuperar todo lo incluido en este objetivo específico:  "' +
                                rowsObjectiveSpecific.programs_objectives_specific +
                                '"',
                                "Si, Eliminar",
                                "deleteObjEspecific"
                              );
                            }}
                          />
                        </Stack>
                      </TableCell>
                        )
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Box sx={{ flexGrow: 1, mt: 3 }}>
          <Grid
            container
            spacing={2}
            sx={{ mt: 2 }}
            style={{ borderRadius: "15px" }}
          >
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <StyledTypography
                sx={{
                  padding: "8px",
                  fontSize: "20px",
                  color: "#305AD9",
                  fontWeight: "bold",
                }}
              >
                Actividades
              </StyledTypography>
            </Grid>
            {
              !readOnly && (
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                  <ButtonControla
                    roles={[1, 3, 4, 7, 8]}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#2D9CDB"}
                    textButton={"Agregar actividades"}
                    typeButton={"button"}
                    functionOnClick={handleOpenModalCreateActivities}
                  />
                </Grid>
              )
            }
          </Grid>
        </Box>

        <Grid item md={12} style={{ textAlign: "center" }}>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              sx={{ width: "100%", minWidth: "100%" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#305ad9",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    N°
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#305ad9",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Actividad
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsActivities.map((row) => (
                  <TableRow
                    hover
                    key={row.id_programs_objectives_activities}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{row.order_number}</TableCell>
                    <TableCell align="center">
                      {row.programs_objectives_activities}
                    </TableCell>
                    {
                      !readOnly && (
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <ButtonIconControla
                              roles={[1, 3, 4, 7, 8]}
                              icon={<BorderColorOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#2D9CDB"}
                              textTooltip={"Editar"}
                              functionOnClick={() =>
                                handleOpenEditActivity(
                                  row.id_programs_objectives_activities,
                                  row.programs_objectives_activities,
                                  row.order_number
                                )
                              }
                            />
                            <ButtonIconControla
                              roles={[1, 3, 4, 7, 8]}
                              icon={<Delete sx={{ color: "white" }} />}
                              backgroundColor={"#EB5757"}
                              backgroundColorHover={"#FF4040"}
                              textTooltip={"Eliminar"}
                              functionOnClick={() => {
                                handleOpenModalConfirm(
                                  row.id_programs_objectives_activities,
                                  "¿Esta seguro de eliminar esta actividad?",
                                  'Una vez eliminado no se podra recuperar todo lo incluido en esta actividad:  "' +
                                  row.programs_objectives_activities
                                  +
                                  '"',
                                  "Si, Eliminar",
                                  "deleteActivity"
                                );
                              }}
                            />
                          </Stack>
                        </TableCell>
                      )
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Box sx={{ flexGrow: 1, mt: 3 }}>
          <Grid
            container
            spacing={2}
            sx={{ mt: 3 }}
            style={{ borderRadius: "15px" }}
          >
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
              <StyledTypography
                sx={{
                  padding: "8px",
                  fontSize: "20px",
                  color: "#305AD9",
                  fontWeight: "bold",
                }}
              >
                Programación
              </StyledTypography>
            </Grid>

            <Grid item xs={12} sm={7} md={7} lg={7} xl={7} >
              <Stack direction={"row"} justifyContent={"end"} spacing={2}>
                {/* <StyledAddButton
                  variant="contained"
                  onClick={handleOpenModalLegend}
                  disableElevation
                >
                  Leyenda
                </StyledAddButton> */}

                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} textAlign="right">
                  <ButtonControla
                    roles={[1, 3, 4, 7, 8]}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#2D9CDB"}
                    textButton={"Leyenda"}
                    typeButton={"button"}
                    functionOnClick={handleOpenModalLegend}
                  />
                </Grid>
                {
                  !readOnly && (
                    <>
                      <Grid item xs={12} sm={12} md={7} lg={7} xl={7} textAlign="right">
                        <ButtonControla
                          roles={[1, 3, 4, 7, 8]}
                          iconButton={<Add sx={{ color: "white" }} />}
                          backgroundColor={"#2D9CDB"}
                          backgroundColorHover={"#2D9CDB"}
                          textButton={"Agregar programación"}
                          typeButton={"button"}
                          functionOnClick={handleOpenModalCreateProgramming}
                        />
                      </Grid>

                      {/* <StyledAddButton
                        variant="contained"
                        onClick={handleOpenModalCreateProgramming}
                        startIcon={<Add />}
                        disableElevation
                      >
                        Agregar programación
                      </StyledAddButton> */}

                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} textAlign="right">
                        <ButtonControla
                          roles={[1, 3, 4, 7, 8]}
                          iconButton={<Add sx={{ color: "white" }} />}
                          backgroundColor={"#2D9CDB"}
                          backgroundColorHover={"#2D9CDB"}
                          textButton={"Agregar columnas"}
                          typeButton={"button"}
                          functionOnClick={handleOpenModalCreateColumns}
                        />
                      </Grid>

                      {/* <StyledAddButton
                        variant="contained"
                        onClick={handleOpenModalCreateColumns}
                        startIcon={<Add />}
                        disableElevation
                      >
                        Agregar columnas
                      </StyledAddButton> */}
                    </>
                  )
                }
              </Stack>
            </Grid>

            <Grid item md={12} sx={{ mb: 3 }} style={{ textAlign: "center" }}>
              <TableContainer component={Paper}>
                <Table
                  stickyHeader
                  sx={{ width: "100%", minWidth: "100%" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" rowSpan={2}></TableCell>
                      <TableCell rowSpan={2}>Nombres</TableCell>
                      <TableCell rowSpan={2}>Responsables</TableCell>
                      <TableCell rowSpan={2}>Recursos</TableCell>
                      <TableCell rowSpan={2}>Presupuesto</TableCell>
                      {rowsColumns.map((row) => (
                        <TableCell
                          key={row.id_programs_objectives_programming_columns}
                          rowSpan={2}
                          onClick={() =>
                            handleOpenModalEditColumns(
                              row.id_programs_objectives_programming_columns,
                              row.programs_objectives_programming_columns
                            )
                          }
                        >
                          {row.programs_objectives_programming_columns}
                        </TableCell>
                      ))}
                      {months.map((month) => (
                        <TableCell colSpan={4} align="center" key={month.id}>
                          {month.month}
                        </TableCell>
                      ))}
                      <TableCell rowSpan={2}>Total</TableCell>
                      <TableCell rowSpan={2}>Avance (%)</TableCell>
                    </TableRow>
                    <TableRow>
                      {months.map((month) =>
                        weeks.map((week) => (
                          <TableCell key={week + "" + month.id} align="center">
                            {week}
                          </TableCell>
                        ))
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsProgramming.map((row) => (
                      <TableRow
                        hover
                        key={row.id_programs_objectives_programming}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {
                          !readOnly && (
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                                spacing={2}
                              >
                                <ButtonIconControla
                                  roles={[1, 3, 4, 7, 8]}
                                  icon={ <BorderColorOutlined sx={{ color: "white" }} /> }
                                  backgroundColor={"#2D9CDB"}
                                  textTooltip={"Editar"}
                                  backgroundColorHover={"#33AEF4"}
                                  functionOnClick={() =>
                                    handleOpenModalEditProgramming(
                                      row.id_programs_objectives_programming,
                                      row.first_name + " " + row.last_name,
                                      row.id_users_responsible,
                                      row.resources,
                                      row.programs_objectives_programming,
                                      row.budgets
                                    )
                                  }
                                />
                                <ButtonIconControla
                                  roles={[1, 3, 4, 7, 8]}
                                  icon={<Event sx={{ color: "white" }} />}
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1BAA88"}
                                  textTooltip={"Programar"}
                                  functionOnClick={() =>
                                    handleOpenModalProgrammingProgram(
                                      row.id_programs_objectives_programming
                                    )
                                  }
                                />
                              </Stack>
                            </TableCell>
                          )
                        }
                        <TableCell align="left">
                          {row.programs_objectives_programming}
                        </TableCell>
                        <TableCell align="left">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell align="left">{row.resources}</TableCell>
                        <TableCell align="left">{(currentSymbol === 'S/.' ? (currentSymbol + ' ') : '') + row.budgets + (currentSymbol === '$' ? (' ' + currentSymbol) : '')}</TableCell>

                        {rowsColumns.map((rowColumn, key) => (
                          <TableCell key={key}>
                            {handleSearchColumnData(
                              rowColumn.id_programs_objectives_programming_columns,
                              row.id_programs_objectives_programming
                            )}
                          </TableCell>
                        ))}
                        {months.map((month) =>
                          weeks.map((week) => (
                            <TableCell key={week + "" + month.id}>
                              {handleSearchProgrammingProgramming(
                                row.id_programs_objectives_programming,
                                month.id,
                                week
                              )}
                            </TableCell>
                          ))
                        )}
                        <TableCell align="left">
                          {handleSearchProgrammingTotal(
                            row.id_programs_objectives_programming
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {handleSearchProgrammingPercentage(
                            row.id_programs_objectives_programming
                          )}
                          %
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Stack direction={'row'} spacing={2} justifyContent={'right'} marginBottom={"15px"}>
            <Box>
              <ButtonControla
                iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                backgroundColor={"#F2994A"}
                backgroundColorHover={"#FF881E"}
                textButton={"Imprimir"}
                typeButton={'button'}
                functionOnClick={() => alert('En construcción')}
              />
            </Box>
            <Box>
              <ButtonControla 
                backgroundColor={'#CBCBFF'} 
                textButton={'Regresar'} 
                backgroundColorHover={'#CBCBFF'} 
                typeButton={'button'} 
                isNavegationOrigin={true} 
                url={'/documentation/programs/sso/editprograms?id_programs=' + idprograms +"&readOnly=" + readOnly} 
              />
            </Box>
          </Stack>
        </Box>
      </Container>

      <Dialog
        open={openModalAddObjectiveSpecific}
        onClose={closeAgregarObjetivo}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar nuevo objetivo especifico
          </Typography>
        </DialogTitle>
        <form onSubmit={handlePostCreateObjectiveSpecific}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Objetivo específico"}
                  inputType={"text"}
                  inputValue={setObjetiveSpecific}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Número de objetivo"}
                  inputType={"number"}
                  inputValue={setOrderNumber}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Meta"}
                  inputType={"number"}
                  inputValue={setGoals}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Indicador"}
                  inputType={"text"}
                  inputValue={setIndicador}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                closeAgregarObjetivo();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo para actualizar obj. especificos| */}

      <Dialog
        open={openModalEdit}
        onClose={closeEditarObjetivo}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Actualizar objetivo especifico
          </Typography>
        </DialogTitle>
        <form onSubmit={handleUpdateObjetivoEspecifico}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Objetivo específico"}
                  inputType={"text"}
                  inputValue={setObjetiveSpecific}
                  modalType={true}
                  value={objetiveSpecific}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Número de objetivo"}
                  inputType={"number"}
                  inputValue={setOrderNumber}
                  modalType={true}
                  value={orderNumber}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Meta"}
                  inputType={"number"}
                  inputValue={setGoals}
                  modalType={true}
                  value={goals}
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Indicador"}
                  inputType={"text"}
                  inputValue={setIndicador}
                  modalType={true}
                  value={indicador}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                closeEditarObjetivo();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            // onClick={() => {
            //     handleUpdateObjetivoEspecifico();
            // }}
            >
              Actualizar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog para editar actividades */}
      <Dialog
        open={openModalEditActivity}
        onClose={handleCloseModalEditActivity}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Actualizar Actividad
          </Typography>
        </DialogTitle>
        <form onSubmit={handleUpdateActivity}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Actividad:"}
                  inputType={"text"}
                  inputValue={setActivity}
                  modalType={true}
                  value={activity}
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Número de actividad:"}
                  inputType={"number"}
                  inputValue={setOrderNumberActivity}
                  modalType={true}
                  value={orderNumberActivity}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                handleCloseModalEditActivity();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            // onClick={() => {
            //   handleUpdateActivity();
            // }}
            >
              Actualizar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo para agregar actividades| */}
      <Dialog
        open={openModalCreateActivities}
        onClose={handleCloseModalCreateActivities}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar nuevas actividades
          </Typography>
        </DialogTitle>
        <form onSubmit={handlePostCreateActivity}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Actividad:"}
                  inputType={"text"}
                  inputValue={setActivity}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Número de actividad:"}
                  inputType={"number"}
                  inputValue={setOrderNumberActivity}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                handleCloseModalCreateActivities();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo para programar| */}
      <Dialog
        open={openModalProgrammingProgram}
        onClose={handleCloseModalProgrammingProgram}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Programar"
          functionOnClose={handleCloseModalProgrammingProgram}
        ></DialogTitleControla>
        <form onSubmit={handleStoreProgrammingProgram}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <SelectControla
                  text="Mes:"
                  inputValue={setMonthId}
                  modalType={true}
                  childrenRows={months.map((month) => (
                    <MenuItem key={month.id} value={month.id}>
                      {month.month}
                    </MenuItem>
                  ))}
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <SelectControla
                  text="Semana:"
                  inputValue={setWeekId}
                  modalType={true}
                  childrenRows={weeks.map((week) => (
                    <MenuItem key={week} value={week}>
                      {week}
                    </MenuItem>
                  ))}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                handleCloseModalProgrammingProgram();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Programar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo para reprogramar| */}
      <Dialog
        open={openModalProgrammingReprogram}
        onClose={handleCloseModalProgrammingReprogram}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Reprogramar"
          functionOnClose={handleCloseModalProgrammingReprogram}
        ></DialogTitleControla>
        <form onSubmit={handleStoreProgrammingReprogram}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <SelectControla
                  text="Mes:"
                  inputValue={setMonthId}
                  modalType={true}
                  childrenRows={months.map((month) => (
                    <MenuItem key={month.id} value={month.id}>
                      {month.month}
                    </MenuItem>
                  ))}
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <SelectControla
                  text="Semana:"
                  inputValue={setWeekId}
                  modalType={true}
                  childrenRows={weeks.map((week) => (
                    <MenuItem key={week} value={week}>
                      {week}
                    </MenuItem>
                  ))}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                handleCloseModalProgrammingReprogram();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#F2994A",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Reprogramar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo para agregar columnas| */}
      <Dialog
        open={openModalCreateColumns}
        onClose={handleCloseModalCreateColumns}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar nueva columna
          </Typography>
        </DialogTitle>
        <form onSubmit={handlePostCreateColumn}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Nombre de columna:"}
                  inputType={"text"}
                  inputValue={setProgrammingColumn}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                handleCloseModalCreateColumns();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo para agregar columnas| */}
      <Dialog
        open={openModalEditColumns}
        onClose={handleCloseModalEditColumns}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Editar columna
          </Typography>
        </DialogTitle>
        <form onSubmit={handleUpdateColumn}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Nombre de columna:"}
                  inputType={"text"}
                  inputValue={setProgrammingColumn}
                  value={programmingColumn}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                handleCloseModalEditColumns();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="button"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#EB5757",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                handleOpenModalConfirm(
                  idProgrammingColumn,
                  "Eliminar columna",
                  "¿Estas seguro de eliminar esta columna?, perderas todos los datos guardados en ella.",
                  "Si, eliminar",
                  "deleteColumn"
                );
              }}
            >
              Eliminar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo para agregar columnas| */}
      <Dialog
        open={openModalCreateProgramming}
        onClose={handleCloseModalCreateProgramming}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar actividad de programación
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateProgramming}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <SearchButtonControla
                  titleLabel={"Usuario:"}
                  inputValue={responsible}
                  functionClick={handleOpenModalSelectUser}
                  modalType={true}
                  required
                />

                {/* <InputControlaVertical
                                    text={"Responsable:"}
                                    inputType={"text"}
                                    inputValue={setIdResponsible}
                                    modalType={true}
                                    required
                                /> */}
              </Grid>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Nombre de actividad:"}
                  inputType={"text"}
                  inputValue={setProgramming}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <InputControlaVertical
                  text={"Recursos:"}
                  inputType={"text"}
                  inputValue={setResources}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <InputControlaVertical
                  text={"Presupuesto:"}
                  inputType={"number"}
                  inputValue={setBudgets}
                  modalType={true}
                  required
                />
              </Grid>
              {rowsColumns.map((rowColumn, key) => (
                <Grid item xs={6} md={6} key={key}>
                  <InputControlaVertical
                    id={
                      "columnDataCreate" +
                      rowColumn.id_programs_objectives_programming_columns
                    }
                    text={
                      rowColumn.programs_objectives_programming_columns + ":"
                    }
                    inputType={"text"}
                    inputValue={toString}
                    modalType={true}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                handleCloseModalCreateProgramming();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo para agregar columnas| */}
      <Dialog
        open={openModalEditProgramming}
        onClose={handleCloseModalEditProgramming}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Editar actividad de programación
          </Typography>
        </DialogTitle>
        <form onSubmit={handleUpdateProgramming}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <SearchButtonControla
                  titleLabel={"Usuario:"}
                  inputValue={responsible}
                  functionClick={handleOpenModalSelectUser}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Nombre de actividad:"}
                  inputType={"text"}
                  inputValue={setProgramming}
                  value={programming}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <InputControlaVertical
                  text={"Recursos:"}
                  inputType={"text"}
                  inputValue={setResources}
                  value={resources}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <InputControlaVertical
                  text={"Presupuesto:"}
                  inputType={"number"}
                  inputValue={setBudgets}
                  value={budgets}
                  modalType={true}
                  required
                />
              </Grid>
              {rowsColumns.map((rowColumn, key) => (
                <Grid item xs={6} md={6} key={key}>
                  <InputControlaVertical
                    id={
                      "columnDataEdit" +
                      rowColumn.id_programs_objectives_programming_columns
                    }
                    text={
                      rowColumn.programs_objectives_programming_columns + ":"
                    }
                    inputType={"text"}
                    inputValue={toString}
                    modalType={true}
                    defaultValue={handleSearchColumnData(
                      rowColumn.id_programs_objectives_programming_columns,
                      programmingId
                    ).toString()}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                handleCloseModalEditProgramming();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={openModalSelectUser}
        onClose={handleCloseModalSelectUser}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Buscar Usuario"
          functionOnClose={handleCloseModalSelectUser}
        />
        <DialogContent>
          <SearchUsersControla
            rowsUser={rowsUsers}
            handleUsersActionButton={handleSelectUser}
          />
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <Box>
              <ButtonControla
                backgroundColor={"#CBCBFF"}
                textButton={"Cancelar"}
                backgroundColorHover={"#CBCBFF"}
                typeButton={"button"}
                functionOnClick={handleCloseModalSelectUser}
              />
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModalLegend}
        onClose={handleCloseModalLegend}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Leyenda"
          functionOnClose={handleCloseModalLegend}
        />
        <DialogContent>
          <Table
            stickyHeader
            sx={{ width: "100%", minWidth: "100%" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    color: "#305ad9",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Nombre
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#305ad9",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Referencia 1
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#305ad9",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Referencia 2
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">Programado / Programar</TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      backgroundColor: "#169073",
                      color: "white",
                      padding: "2px 5px",
                      borderRadius: "8px",
                      textAlign: "center",
                      maxWidth: "90px"
                    }}
                  >
                    N° - P
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    sx={{
                      borderRadius: "50%",
                      color: "white",
                      width: "30px",
                      height: "30px",
                      backgroundColor: "#169073",
                    }}
                  ><Event sx={{ color: "white", width: "20px" }} /></IconButton>

                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Reprogramado / Reprogramar</TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      backgroundColor: "#F2994A",
                      color: "white",
                      padding: "2px 5px",
                      borderRadius: "8px",
                      textAlign: "center",
                      maxWidth: "90px"
                    }}
                  >
                    N° - R
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    sx={{
                      borderRadius: "50%",
                      color: "white",
                      width: "30px",
                      height: "30px",
                      backgroundColor: "#F2994A",
                    }}
                  >
                    <Typography>R</Typography>
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Ejectado / Ejecutar</TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      backgroundColor: "#2D9CDB",
                      color: "white",
                      padding: "2px 5px",
                      borderRadius: "8px",
                      textAlign: "center",
                      maxWidth: "90px"
                    }}
                  >
                    N° - E
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    sx={{
                      borderRadius: "50%",
                      color: "white",
                      width: "30px",
                      height: "30px",
                      backgroundColor: "#2D9CDB",
                    }}
                  >
                    <Typography>E</Typography>
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <Box>
              <ButtonControla
                backgroundColor={"#CBCBFF"}
                textButton={"Cancelar"}
                backgroundColorHover={"#CBCBFF"}
                typeButton={"button"}
                functionOnClick={handleCloseModalLegend}
              />
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

export default ObjectiveGeneral;
