// colocar lo de Workaccidents.jsx
import {useEffect, useState} from "react";

import Container from "@mui/material/Container";
import DialogConfirmControla from "../../../components/dialog/dialogConfirmControla";
import Box from "@mui/material/Box";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Stack,
  Typography,
  MenuItem,
} from "@mui/material";
import {Add} from "@mui/icons-material";

import ButtonControla from "../../../components/buttons/buttonController";
import TitleText from "../../../components/text/titleText";
import SelectControlaHorizontal  from "../../../components/selects/selectControlaHorizontal";
import SearchUsersControla from "../../../components/search/searchUsersControla";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import MyMembersCommitte from "../../../api/MyMembersCommittee";
import MyUsers from "../../../api/MyUsers";
import DialogInformativeControla from "../../../components/dialog/dialogInformativeControla";
import LoadingControla from "../../../components/load/loadingControla";

import TableControla from "../../../components/tables/table-controla";
import ListHeadlines from "./components/list-Headlines";
import ListAlternates from "./components/list-Alternates";
import ButtonMailboxControla from "../../../components/buttons/buttonMailboxControla";
import ReturnBack from "../../../components/buttons/returnBackControla";


const purple = "#8B8BD8";

function Index() {

// alerts
const [openAlert, setOpenAlert] = useState(false);
const [openLoading, setOpenLoading] = useState(false);
const [messageAlert, setMessageAlert] = useState("");
const [typeAlert, setTypeAlert] = useState("success");

const handleOpenLoading = () => {
  setOpenAlert(false);
  setOpenLoading(true);
  setMessageAlert("");
  setTypeAlert("success");
};

const handleOpenAlert = (alertMessage, alertType) => {
  setOpenAlert(true);
  setOpenLoading(false);
  setMessageAlert(alertMessage);
  setTypeAlert(alertType);
};

const handleCloseAlert = () => {
  setOpenAlert(false);
};

//fin de alerts

  const [openModalAddCommittee, setOpenModalAddCommittee] = useState(false);

  const handleOpenModalAddCommittee = () => {
    setOpenModalAddCommittee(true);

    //limpiar 
    setPosition("");
    setRepresentative("");
    setArrayUsers([]);
    setCommitteePosition("");
  }

  const handleCloseModalAddCommittee = () => {
    setOpenModalAddCommittee(false);
  }

 
  const typePosition = [{ id_position: 1,nombre:"Titular"},{ id_position:2, nombre:"Suplente"}];
  const [position, setPosition] = useState("");

  const listCommitteePosition = [{id_committee_position : 1 , nombre: "Presidente"},{id_committee_position :2 , nombre: "Secretaria"}, {id_committee_position :3 , nombre: "Miembro"}]
  const [committeePosition, setCommitteePosition]  = useState("");

  const typeRepresentative = [{ id_representative: 1,nombre:"Empleador"},{ id_representative:2, nombre:"Trabajadores"}];
  const [representative, setRepresentative] = useState("");

  const [members, setMembers] = useState([]);
  const [users, setUsers]= useState([]);
  const [arrayUsers, setArrayUsers]= useState([]);
  const [usersByEmployer, setUsersByEmployer] = useState([]);
  const [usersByWorkers, setUsersByWorkers]= useState([]);
 
  const [membersAlternates, setMembersAlternates] =  useState([]);
  const [membersHeadlines, setMembersHeadlines] = useState([]);

  const listUsers = async () => {
    handleOpenLoading()

    //traer todo los usuarios
    const responseUsers = await MyUsers.users();
    setUsers(responseUsers.users_actives);
    setUsersByEmployer(responseUsers.users_actives.filter((user) => user.id_job_types === 1 || user.id_job_types === 2));
    setUsersByWorkers(responseUsers.users_actives.filter((user) => user.id_job_types === 3));
    
    setOpenLoading(false);

  }

  const listMembers = async()=>{

    handleOpenLoading();
    const responseMembers = await MyMembersCommitte.getMembers();
    setMembers(responseMembers.data);
    setMembersAlternates(responseMembers.data.filter((member) => member.committee_position === 2));
    setMembersHeadlines(responseMembers.data.filter((member) => member.committee_position === 1));

    setOpenLoading(false);
  }

  
  const handleAddMemberCommittee = async (id_user) => {
    handleOpenLoading();

    if (position === "" || representative === "") {
      handleOpenAlert("Falta seleccionar datos", "error");

    } else {

      //buscar si existe este usuario registrado en comité
      let existUserCommitte = false;
      members.forEach(m => {
        if (m.id_users === id_user) {
          existUserCommitte = true;
        }
      });

      //verificar si usuario tiene el rol de comite 
      const userRoles = await MyUsers.usersRoles({ idUsers: id_user });
      const isCommittee = userRoles.some((rol) => rol.id_users_roles == 12);

      if (existUserCommitte) {
        handleCloseModalAddCommittee();
        handleOpenAlert("usuario existente en comité", "error");

      } else if (!isCommittee) {
        setOpenLoading(false);

        setMessage("Para otorgar funciones completas  al miembro de comité deberás agregar rol comité en sección configuración - usuarios");
        setActiveModalInformative(true);
        handleCloseModalAddCommittee();
      }
      else {
        //verificar cargo
        const existPosition = users.filter((user) => user.id_users === id_user)[0].id_positions;

        if (existPosition === null) {
          setOpenLoading(false);
          handleCloseModalAddCommittee();

          setActiveModalInformative(true);
          setMessage("El usuario no tiene un cargo registrado, verificar dato en el sistema.")

        } else {
          //verificar si existe presidente en comité
          if (committeePosition == 1) {
            //si selecciona presidente
            let existPresident = members.some((m) => m.position_committe_member == 1);
            if (existPresident) {
              handleCloseModalAddCommittee();
              setOpenLoading(false);

              setActiveModalInformative(true);
              setMessage("No se puede agregar miembro, ya existe un presidente en comité");
            } else {
              handlePostMember(id_user);
            }

          }
          else if (committeePosition == 2) {
            //si selecciona secretaria
            let existSecretary = members.some((m) => m.position_committe_member == 2);
            if (existSecretary) {
              handleCloseModalAddCommittee();
              setOpenLoading(false);

              setActiveModalInformative(true);
              setMessage("No se puede agregar miembro, ya existe una secretaria en comité");

            } else {
              handlePostMember(id_user);
            }
          }
          else {
            //si selecciona miembro
            handlePostMember(id_user);
          }

          handleCloseModalAddCommittee();
        }

      }

    }

  }

  const handlePostMember = async(id_user) => {
    const dataMember = {
      id_users: id_user,
      selection_type: representative.toString(),
      committee_position: position,
      status: 1,
      comment: "---",
      position_committe_member: committeePosition == "" ? 3 : committeePosition
    }

    const response = await MyMembersCommitte.addMember(dataMember);

    if (response.success === true) {
      listMembers();
      handleOpenAlert("Miembro agregado con éxito", "success");
    } else {
      handleOpenAlert("Ocurrió un error", "error");
    }

  }


  //array de usuarios del sistema
  const handleGetUserByTypeRepresentative = async (idTypeRepresentative)=> {

    //mostrar lista de usuarios de acuerdo al tipo de representate 1= empleador, 2 trabajador
    if(idTypeRepresentative === 1){
      setArrayUsers(usersByEmployer);
    } else if(idTypeRepresentative === 2){
      setArrayUsers(usersByWorkers);
    }
  }

  const [openModalComment, setOpenModalComment] = useState(false);
  const [idCommitteeMember, setIdCommitteeMember] = useState("");
  const [statusMember, setStatusMember] = useState(0);
  const [comment, setComment]= useState("");
  const [buttonActionTypeComment, setButtonActionTypeComment]= useState("");

  const handleOpenModalComment = (id_committee_members, status_member, buttonActionTypeComment)=>{
    setOpenModalComment(true);
    setIdCommitteeMember(id_committee_members);
    setStatusMember(status_member);
    setButtonActionTypeComment(buttonActionTypeComment);
    handleCloseModalConfirm();
  }
  const handleCloseModalComment = ()=>{
    setOpenModalComment(false);

    //limpiar 
    setComment("")
  }

  const handleActionModalComment = (event)=>{
    switch(buttonActionTypeComment){
      case "changeCommiteePosition":
        handleUpdateCommitteePosition(event);
        break;
      case "changeMember":
        handleChangeMember(event)
        break;
    }

  }


  const handleChangeMember = async (event) => {

    handleOpenLoading();
    event.preventDefault();

    if (statusMember === "") {

      const changePosition = {
        committee_position: 1,
        comment: comment
      }
      const resp = await MyMembersCommitte.updateMember(changePosition, idCommitteeMember);
      if (resp.success === true) {
        listMembers();
        handleOpenAlert("Se convirtió a titular con éxito","success");
        
      } else {
        handleOpenAlert("Ocurrio un error","error" );
      }

    } else {
      let newStatus = null;

      statusMember === 1 ? newStatus = 0 : newStatus = 1;

      const status = {
        status: newStatus,
        comment: comment
      }
      const r = await MyMembersCommitte.updateMember(status, idCommitteeMember);

      if (r.success === true) {
        listMembers();
        if (newStatus === 0) {
          handleOpenAlert("Se deshabilitó al miembro con éxito","success");
        } else {
          handleOpenAlert( "Se habilitó al miembro con éxito", "success");
        }
        
      } else {
        handleOpenAlert( "Ocurrió un error", "error");
      }

    }
     setComment("");

    handleCloseModalComment();

  }

  const handleChangeCommitteePosition = (idCommittee, committeePositionSelected)=>{
    setCommitteePosition(committeePositionSelected);

    handleOpenModalConfirm(
      idCommittee,
      "¿Estás seguro de actualizar el cargo que ocupa dentro del comité?",
      "",
      "Si, actualizar",
      "updateCommitteePosition"
      )
  }

  /** modal de confirmación*/

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (id_committee_member, titleText, contentText, buttonTextConfirm, buttonActionType) => {
    setOpenModalConfirm(true);
    setIdCommitteeMember(id_committee_member);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
  }
  const handleCloseModalConfirm = ()=>{
    setOpenModalConfirm(false);
  }

  const handleActionConfirm = ()=>{
    switch (buttonActionType){
      case "deleteMember":
        handleDeleteMember();
        break;
      case "updateCommitteePosition":
        handleOpenModalComment(
          idCommitteeMember,
          "",
          "changeCommiteePosition"
          );
        break;
    }

  }

  const handleDeleteMember = async()=>{
    handleOpenLoading();

    const re = await MyMembersCommitte.deleteMember(idCommitteeMember);

    if(re.success === true){
      handleCloseModalConfirm();
      listMembers();
      handleOpenAlert("Se eliminó al miembro con éxito","success");

    }else{
      handleCloseModalConfirm();
      handleOpenAlert("error", "Ocurrió un error");
    }

  }

  const handleUpdateCommitteePosition = async(event) => {
    event.preventDefault();
    handleOpenLoading()

    const data = {
      position_committe_member: committeePosition,
      comment : comment
    }
    const resp = await MyMembersCommitte.updateMember(data, idCommitteeMember);

    if(resp.success == true){
      listMembers();
      handleOpenAlert("Se actualizó el cargo del comité con éxito");
    }else{
      handleOpenAlert("Ocurrió un error", "error");
    }

    handleCloseModalConfirm();
    handleCloseModalComment();

  }

  const [activeModalInformative, setActiveModalInformative] = useState(false);
  const [message, setMessage] = useState("");
  const handleCloseModal = ()=>{
    setActiveModalInformative(false);
  }


  useEffect(()=>{
    listMembers();
    listUsers();
  },[]);


  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <Container>
        <Box sx={{ flexGrow: 1 }}>

          <Grid container>
            <Grid item xs={12} md={12}>
              <ButtonMailboxControla
                originUrl={"/committee/presentCommittee/currentCommittee"}
              />
            </Grid>
            <Grid item xs={7} md={7}>
              <TitleText text="Miembros del Comite" />
            </Grid>

          </Grid>

          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ marginTop: 3, marginBottom: 1 }}
          >
            <Box>
              <InputLabel
                sx={{
                  fontSize: "20px",
                  color: "#305AD9",
                  fontWeight: "bold",
                }}
              >
                Miembros Titulares
              </InputLabel>
            </Box>
            <Box>
              <ButtonControla
                roles={[1, 7, 8]}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar miembros al comité"}
                functionOnClick={handleOpenModalAddCommittee}
              />
            </Box>
          </Stack>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={12}>

              <TableControla
                loading={openLoading}
                tabs={[
                  {
                    columnNames: ["Nombres y Apellidos", "Documento de identidad", "Puesto de trabajo", "Tipo", "Estado", "Cargo", "Acciones", "Comentario"]
                  }
                ]}
              >
                <ListHeadlines
                  membersHeadlines={membersHeadlines}
                  listPosition={listCommitteePosition}
                  setCommitteePosition={setCommitteePosition}
                  colorCell={purple}
                  handleOpenModalComment={handleOpenModalComment}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                  handleChangeCommitteePosition={handleChangeCommitteePosition}
                />

              </TableControla>
            </Grid>
          </Grid>

          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ marginTop: 3, marginBottom: 1 }}
          >
            <Box>
              <InputLabel
                sx={{
                  fontSize: "20px",
                  color: "#305AD9",
                  fontWeight: "bold",
                }}
              >
                Miembros Suplentes
              </InputLabel>
            </Box>
          </Stack>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={12}>

              <TableControla
                loading={openLoading}
                tabs={[
                  {
                    columnNames: ["Nombres y Apellidos", "Documento de identidad", "Puesto de trabajo", "Tipo", "Estado", "Cargo", "Acciones", "Comentario"]
                  }
                ]}
              >

                <ListAlternates
                  membersAlternates={membersAlternates}
                  colorCell={purple}
                  handleOpenModalComment={handleOpenModalComment}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                />
              </TableControla>

            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>

          </Grid>

        </Box>
      </Container>

      {/* modal de agregar comité */}
      <Dialog
        open={openModalAddCommittee}
        onClose={handleCloseModalAddCommittee}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar nuevo miembro al comité
          </Typography>
        </DialogTitle>

        {/* <form onSubmit={handleAddMemberCommittee}> */}
        <DialogContent sx={{ paddingTop: "0" }}>

          <Grid xs={12} sm={12} md={12} lg={12} xl={12}
            container
            spacing={2}
            alignItems="center"
          >

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <SelectControlaHorizontal
                required
                text={"Tipo de miembro del comité:"}
                modalType={true}
                inputValue={setPosition}
                value={position || ''}
                childrenRows={typePosition.map((rows) => (
                  <MenuItem key={rows.id_position} value={rows.id_position}>
                    {rows.nombre}
                  </MenuItem>
                ))}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              {
                position == 1 ? (
                  <SelectControlaHorizontal
                    required
                    text={"Cargo dentro del Comité:"}
                    modalType={true}
                    inputValue={setCommitteePosition}
                    value={committeePosition || ''}
                    childrenRows={listCommitteePosition.map((rows) => (
                      <MenuItem key={rows.id_committee_position} value={rows.id_committee_position}>
                        {rows.nombre}
                      </MenuItem>
                    ))}
                  />
                ) : ""
              }
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <SelectControlaHorizontal
                text={"Tipo de representante:"}
                modalType={true}
                inputValue={(e) => {
                  setRepresentative(e);
                  handleGetUserByTypeRepresentative(e);
                }}
                value={representative || ''}
                childrenRows={typeRepresentative.map((rows) => (
                  <MenuItem key={rows.id_representative} value={rows.id_representative}>
                    {rows.nombre}
                  </MenuItem>
                ))}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SearchUsersControla
                rowsUser={arrayUsers}
                handleUsersActionButton={(id_users) => { handleAddMemberCommittee(id_users) }}
              />
            </Grid>

          </Grid>
        </DialogContent>


        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <Box>
              <ButtonControla
                backgroundColor={"#CBCBFF"}
                textButton={"Cancelar"}
                backgroundColorHover={"#CBCBFF"}
                typeButton={"button"}
                functionOnClick={() => handleCloseModalAddCommittee()}
              />
            </Box>
          </Stack>
        </DialogActions>
        {/* </form> */}
      </Dialog>

      {/* modal de cambiar estado del miembro / subir a titular / cambiar cargo de comité */}
      <Dialog
        open={openModalComment}
        onClose={handleCloseModalComment}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Agregar comentario"
          functionOnClose={handleCloseModalComment}
        />
        <form onSubmit={handleActionModalComment}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
              <InputControlaVertical
                text={"Comentario"}
                inputType={"textArea"}
                inputValue={setComment}
                value={comment}
                modalType={true}
                required
                readOnly={false}
              />
            </Grid>
          </DialogContent>

          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <Box>
                <ButtonControla
                  backgroundColor={"#CBCBFF"}
                  textButton={"Cancelar"}
                  backgroundColorHover={"#CBCBFF"}
                  typeButton={"button"}
                  functionOnClick={handleCloseModalComment}
                />
              </Box>
              <Box>
                <ButtonControla
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Actualizar"}
                  typeButton={"submit"}
                />
              </Box>
            </Stack>
          </DialogActions>

        </form>
      </Dialog>

      {/* modal de información */}

      <DialogInformativeControla
        open={activeModalInformative}
        onClose={handleCloseModal}
        message={message}
      />

    </Box>
  );
}

export default Index;