import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import {
    Archive,
    BorderColorOutlined,
    Delete,
    Email,
    Publish,
    Send,
    VisibilityOutlined,
  } from "@mui/icons-material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";

import { useAppContext } from "../../../context/AppContext";

function ListActives(props) {
    const {permissions} = useAppContext();

    const {
        riskMap,
        colorCell,
        handleOpenModalUploadFileEdit,
        handleOpenModalConfirm
    } = props
    return (
        <TableBody
            // sx={{ display: documentType !== 0 ? "none" : "" }}
        >
            {riskMap.map((row) => (
                <TableRow
                    hover
                    key={row.id}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell
                        sx={{ color: colorCell }}
                        component="th"
                        scope="row"
                    >
                        {row.name}
                    </TableCell>
                    <TableCell
                        sx={{ color: colorCell }}
                        component="th"
                        scope="row"
                    >
                        {row.register_status === 1 ? "Creado" : ""}
                        {row.register_status === 7 ? "Enviado a trabajadores" : ""}
                        {row.register_status === 3 ? "Enviado a comité" : ""}
                        {row.register_status === 4 ? "Publicado" : ""}
                        {row.register_status === 5 ? "Archivado" : ""}
                    </TableCell>
                    <TableCell>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                icon={
                                    <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={row.file}
                                directionTarget={"_blank"}
                            />
                            {row.register_status !== 3 &&
                                row.register_status !== 4 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_MAP_RIESGO_ACTIVOS_EDITAR}
                                    icon={
                                        <BorderColorOutlined
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#2D9CDB"}
                                    backgroundColorHover={"#33AEF4"}
                                    textTooltip={"Editar"}
                                    functionOnClick={() => {
                                        handleOpenModalUploadFileEdit(row.id);
                                    }}
                                />
                            ) : (
                                ""
                            )}

                            {row.register_status === 1 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_MAP_RIESGO_ACTIVOS_ENVIA_TRABAJ}
                                    icon={<Send sx={{ color: "white" }} />}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1AA483"}
                                    textTooltip={"Enviar a trabajadores"}
                                    functionOnClick={() => {
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de enviar a trabajadores?",
                                            'Una vez enviado no podrás revertir la operación del documento.',
                                            "Sí, enviar a trabajadores",
                                            "workers"
                                        );
                                    }}
                                />
                            ) : (
                                ""
                            )}
                            {row.register_status === 7 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_MAP_RIESGO_ACTIVOS_ENVIA_COMIT}
                                    icon={<Send sx={{ color: "white" }} />}
                                    backgroundColor={"#581845"}
                                    backgroundColorHover={"#6D1F56"}
                                    textTooltip={"Enviar a comité"}
                                    functionOnClick={() => {
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de enviar al comité?",
                                            'Recuerda que para enviar al comité debes tener en cuenta las sugerencias de los trabajadores.',
                                            "Sí, enviar a comité",
                                            "comite"
                                        );
                                    }}
                                />
                            ) : (
                                ""
                            )}
                            {row.register_status === 7 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_MAP_RIESGO_ACTIVOS_BUZÓN}
                                    icon={<Email sx={{ color: "white" }} />}
                                    backgroundColor={"#FFC300"}
                                    backgroundColorHover={"#FFC300"}
                                    textTooltip={"Buzón de participación"}
                                    directionUrl={"/mailbox"}
                                    directionTarget={"_blank"}
                                />
                            ) : (
                                ""
                            )}

                            {row.register_status === 3 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_MAP_RIESGO_ACTIVOS_PUBLICAR}
                                    icon={<Publish sx={{ color: "white" }} />}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1AA483"}
                                    textTooltip={"Publicar"}
                                    functionOnClick={() => {
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de publicar?",
                                            'Una vez publicado no podrás revertir la operación del documento.',
                                            "Sí, publicar",
                                            "public"
                                        );
                                    }}
                                />
                            ) : (
                                ""
                            )}

                            {row.register_status === 4 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_MAP_RIESGO_ACTIVOS_ARCHIVAR}
                                    icon={<Archive sx={{ color: "white" }} />}
                                    backgroundColor={"#F2994A"}
                                    backgroundColorHover={"#FF881E"}
                                    textTooltip={"Archivar"}
                                    functionOnClick={() =>
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de archivar el documento?",
                                            'Documento a archivar:  "' +
                                            row.name +
                                            '"',
                                            "Sí, archivar",
                                            "archived"
                                        )
                                    }
                                />
                            ) : (
                                ""
                            )}
                            {row.register_status !== 7 &&
                                row.register_status !== 3 &&
                                row.register_status !== 4 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_MAP_RIESGO_ACTIVOS_ELIMINAR}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() => {
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de eliminar los datos del mapa de riesgos?",
                                            "Una vez eliminado no podrás recuperar los datos de " +
                                            row.name,
                                            "Sí, eliminar",
                                            "delete"
                                        );
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListActives;