import MyConfigurationApi from "../../api/MyConfigurationApi";
import ButtonIconControla from "./buttonIconControla";
import { HistoryEduOutlined} from "@mui/icons-material";
import DialogInformativeControla from "../dialog/dialogInformativeControla";
import { useEffect, useState } from "react";
import {Grid, DialogActions, DialogContent, Dialog, Typography, Stack} from "@mui/material";

import DialogTitleControla from "../dialog/dialogTitleControla";
import ButtonControla from "./buttonController";
import MyUsers from "../../api/MyUsers";

function ButtonSignatureControla({ idUser, firmStatus, firmUrl, functionOnClick}) {
    const params = new URLSearchParams(window.location.search);
    const readOnly = params.get("readOnly") == "true" ? true : false;

    const idUsserLogged = MyConfigurationApi.userData().id_users;

    const [firmUrlUser, setFirmUrlUser] = useState(null);
    
    const handleGetFirmUrlUser = async() => {
        const resp =  await MyUsers.editUsers({idUsers:idUsserLogged});
        setFirmUrlUser(resp.firm_url);
    }

    const [openModalValidateFirm, setOpenModalValidateFirm] = useState(false);

    const handleOpenModalValidateFirm = async () => {
        if (firmUrlUser == null) {
            setOpenModalValidateFirm(true);
        } else {
            handleOpenModalSign();
        }
    }

    const handleCloseModalValidateFirm = () => {
        setOpenModalValidateFirm(false);
    }

    const [openModalSign, setOpenModalSign] = useState(false);
    const handleOpenModalSign = () => {
        setOpenModalSign(true);
    }
    const handleCloseModalSign = () => {
        setOpenModalSign(false);
    }

    const handleFunctionOnClick = async() => {
        await functionOnClick();
        handleCloseModalSign();
    }


    useEffect(() => {
        handleGetFirmUrlUser()
    }, [])

    return (

        <Grid container >
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {
                    (firmStatus == 0 || firmStatus == null) ? (
                        (idUsserLogged == idUser && !readOnly) ? (
                            <ButtonIconControla
                                icon={<HistoryEduOutlined sx={{ color: "white" }} />}
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() => handleOpenModalValidateFirm()}
                            />
                        ) : ("No firmado")

                    ) : (<img src={firmUrl} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />)
                }

                <DialogInformativeControla
                    open={openModalValidateFirm}
                    onClose={handleCloseModalValidateFirm}
                    message={"Tu firma no está registrada en el sistema. Agregue en configuraciones de usuarios"}
                />

                {/* dialogo para confirmación de firma */}
                <Dialog
                    open={openModalSign}
                    onClose={() => handleCloseModalSign}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                >
                    <DialogTitleControla
                        titleText={"Registrar Firma"}
                        functionOnClose={handleCloseModalSign}
                    />
                        <DialogContent>
                            <Typography
                                id="modal-modal-title"
                                sx={{
                                    color: "#1638F2",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                }}
                            >
                                Sus datos quedarán registrados de forma permanente ¿Desea firmar?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Stack direction={"row"} spacing={2} justifyContent={"flex-start"}>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    functionOnClick={handleCloseModalSign}
                                />
                                <ButtonControla
                                    textButton={"Firmar"}
                                    backgroundColor={"#389532"}
                                    functionOnClick={handleFunctionOnClick}
                                />
                            </Stack>
                        </DialogActions>
                </Dialog>

            </Grid>
        </Grid>
    )
}

export default ButtonSignatureControla;

