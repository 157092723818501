import { React, useEffect, useState } from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";

import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";

import BannerControla from "../../../../components/cards/bannerControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import MyStatisticalData from "../../../../api/MyRegisterStatisticalData";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";
import ReturnBack from "../../../../components/buttons/returnBackControla";
import LoadingControla from "../../../../components/load/loadingControla";
import TableControla from "../../../../components/tables/table-controla";
import ListActives from "./components/list-actives";

const colorTitle = "#9191B0";
const purple = "#8B8BD8";

function Index() {
  const navigate = useNavigate();
  const {permissions} = useAppContext();

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");
  
    const handleOpenLoading = () => {
      setOpenAlert(false);
      setOpenLoading(true);
      setMessageAlert("");
      setTypeAlert("success");
    };
  
    const handleOpenAlert = (alertMessage, alertType) => {
      setOpenAlert(true);
      setOpenLoading(false);
      setMessageAlert(alertMessage);
      setTypeAlert(alertType);
    };
  
    const handleCloseAlert = () => {
      setOpenAlert(false);
    };
    //fin de alerts

  const [code, setCode] = useState("");
  const [rowsActives, setRowsActives] = useState([]);
  const [openModalUpload, setOpenModalUpload] = useState(false);

  const handleOpenModalUpload = () => {
    setCode(null);
    setOpenModalUpload(true);
  };

  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
  };

  const hadleGetRegistersStatisticalData = async () => {
    handleOpenLoading();
    const response = await MyStatisticalData.getRegistersStatisticalData();
    if (response.success === false) {
    } else {
      setRowsActives(response);
    }

    setOpenLoading();
    return response;
  };

  const handleCreateRegistersStatisticalData = async (event) => {
    event.preventDefault();
    const response = await MyStatisticalData.createRegistersStatisticalData({ code });
    handleCloseModalUpload();
    navigate('/documentation/registers/generals/statisticaldata/dataStatisticalRecords/edit?idRegistersStatisticalData=' + response.id);

    return response;
  };

  useEffect(() => {
    hadleGetRegistersStatisticalData();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <ButtonMailboxControla
              originUrl={"/documentation/registers/generals/statisticaldata"}
            />

            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/bannerStatisticalData.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Datos de estadísticas"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                roles={permissions.DOC_REGIS_DATA_STATISTICAL_RECORDS_CREAR_REGIS}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Crear nuevo registro"}
                functionOnClick={handleOpenModalUpload}

              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableControla
                 loading = {openLoading}
                 tabs={[
                  {
                    columnNames: ["Código", "Estado", "Acciones"]
                  }
                 ]}
                >
                  <ListActives
                   rowsActives={rowsActives}
                   colorCell={purple}
                  />
                </TableControla>

              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalUpload}
        onClose={handleCloseModalUpload}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar código del registro del inducción y entrenamiento
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateRegistersStatisticalData}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <InputControlaVertical
              text={"Código"}
              inputType={"text"}
              inputValue={setCode}
              modalType={true}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUpload}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}

            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Index;
