import { Search } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { InputBase } from "@mui/material";
import MyConfigurationApi from "../../api/MyConfigurationApi";

const SearchTextField = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginRight: '1rem',
      width: "auto",
      // display: 'block'
    },
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "100%",
      },
    },
  }));

function InputSearchControla({
    placeholder,
    functionOnchange = alert,
    roles = []
}) {
    const userData = MyConfigurationApi.userData();
    let usersRoles = userData.roles;
    console.log(roles)

    let showInput = false;
    roles = roles.length === 0 ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16] : roles;

    roles.map((rol) => {
        if (usersRoles.includes(rol)) {
            showInput = true;
        }
    });
    if (showInput === true) {
        return (
            <SearchTextField
                onChange={({ target }) => functionOnchange(target.value)}
            >
                <SearchIconWrapper>
                    <Search />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder = {placeholder}
                    inputProps={{ "aria-label": "search" }}
                />
            </SearchTextField>
        );
    } else {
        return "";
    }

}
export default InputSearchControla;