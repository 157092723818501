import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getStandards (){
    const url = 'standards/get';
    return MyConfigurationApi.connectApi({ 
        dataJson: null, 
        method: 'GET', 
        url: url 
    });
}

function getStandardById(idStandard){
    const url = 'standards/' +idStandard;
    return MyConfigurationApi.connectApi({ 
        dataJson: null, 
        method: 'GET', 
        url: url 
    });
}

async function postStandard (data){
    const url = 'standards/create';

    //fecha actual
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();

    if(data.document_url !== null){
        data.document_url = await uploadFileCompanies(
            data.document_url,`documentation/documents/standards/${year}/${month}/${day}/`
        )
    }

    return MyConfigurationApi.connectApi({ 
        dataJson: data, 
        method: 'POST', 
        url: url 
    });
}

function postActiveStandard(idModel){
    const url = 'standards/active/create/' + idModel;
    return MyConfigurationApi.connectApi({ 
        dataJson: null, 
        method: 'POST', 
        url: url 
    });
}

async function updateStandardById(data, idStandard){
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();

    if(typeof(data.document_url) == "object"){
        data.document_url = await uploadFileCompanies(
            data.document_url,`documentation/documents/standards/${year}/${month}/${day}/`
        )
    }

    const url = 'standards/update/' + idStandard;
    return MyConfigurationApi.connectApi({ 
        dataJson: data, 
        method: 'PUT', 
        url: url 
    });
}

function updateStatusStandard (data,idStandard){
    const url = 'standards/status/' + idStandard ;
    return MyConfigurationApi.connectApi({ 
        dataJson: data, 
        method: 'PUT', 
        url: url 
    });

}

function deleteStandardById (idStandard){
    const url = 'standards/remove/' + idStandard;
    return MyConfigurationApi.connectApi({ 
        dataJson: null, 
        method: 'DELETE', 
        url: url 
    });
}
//standards/{id}/pdf

function generatePDF (idStandard){
    const url = `standards/${idStandard}/pdf`;
    return MyConfigurationApi.connectApiPDF({ 
        dataJson: null, 
        method: 'GET', 
        url: url 
    });
}

const MyStandards = {
    postStandard,
    postActiveStandard,
    getStandardById,
    getStandards,
    updateStandardById,
    updateStatusStandard,
    deleteStandardById,
    generatePDF
}

export default MyStandards;