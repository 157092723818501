import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getRegistersMonitoring() {
  const url = "monitoring";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function createRegisterMonitoring({ code }) {
  const url = "monitoring";
  const data = { code: code, status: "Creado" };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function closeRegisterMonitoring({ idRegister }) {
  const url = idRegister + "/status/end";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "PUT",
    url: url,
  });
}

async function updateRegisterMonitoring(
  { idRegister,
    code,
    area,
    date,
    riskType,
    monitoringProgram,
    monitoringFrecuency,
    exposedWorkers,
    organizationName,
    results,
    description,
    document_1,
    document_2,
    document_3,
    conclusionsRecomendations,
    riskDetail,
    registerStatus,
  }
) {

  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  
if (document_1 === '') {
  document_1 = document_1;
} else {
  if (typeof document_1 === 'string' &&  document_1.substring(0,38)==='https://firebasestorage.googleapis.com') {
    document_1 = document_1;
  } else {
    document_1 = await uploadFileCompanies(
      document_1,
      "documentation/registers/generals/accidents/" +
      year +
      "/" +
      month +
      "/" +
      day +
      "/"
    );
  }
}

  if (document_2 !== '' && document_2 !== undefined && document_2.length !== 0) {
    if (typeof document_2 === 'string' && document_2.substring(0,38)==='https://firebasestorage.googleapis.com') {
      document_2 = document_2;
    } else {
      document_2 = await uploadFileCompanies(
        document_2,
        "documentation/registers/generals/accidents/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
      );
    }
  }

if (document_3 !== '' && document_3 !== undefined && document_3.length !== 0) {
    if (typeof document_3 === 'string' && document_3.substring(0,38)==='https://firebasestorage.googleapis.com') {
      document_3 = document_3;
    } else {
      document_3 = await uploadFileCompanies(
        document_3,
        "documentation/registers/generals/accidents/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
      );
    }
  }
  const url = "monitoring/" + idRegister;

  const data = {
    code: code, //
    area: area, //
    date: date, //
    risk_type: riskType, //
    risk_detail: riskDetail,//
    monitoring_program: monitoringProgram,//
    monitoring_frecuency: monitoringFrecuency, //
    exposed_workers: exposedWorkers, //
    organization_name: organizationName,//
    results: results,//
    description: description,//
    conclusions_recomendations: conclusionsRecomendations,//
    document_1: document_1, //
    document_2: document_2, //
    document_3: document_3, //
    register_status: registerStatus
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function editRegisterMonitoring(idRegister) {
  const url = "monitoring/" + idRegister.idRegister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function deleteRegisterMonitoring() {
  const url = "monitoring";
  const data = {};
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "DELETE",
    url: url,
  });
}
// RESPONSABLES

//obtiene responsables
function getResponsible({ idRegister }) {
  const url = idRegister + "/responsible";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}
//agrega responsables
function addResponsible({ idUser, idRegister }) {
  const url = idRegister + "/responsible";
  const data = {
    id_users: idUser,
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

//elimina responsables
function deleteResponsible({ idUser }) {
  const url = "responsible/detail/" + idUser;
  const data = {
    id_users: idUser,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "DELETE",
    url: url,
  });
}

//firma responsables
function signResponsible({ idUserId, idUser }) {
  const url = "responsible/detail/" + idUser;
  const data = {
    id_users: idUserId,
    firm: 1,
    firm_date: null,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function getAreas() {
  const url = "configuration_areas";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}
// RESPONSABLES

const MyRegistersMonitoring = {
  getRegistersMonitoring,
  createRegisterMonitoring,
  editRegisterMonitoring,
  updateRegisterMonitoring,
  deleteRegisterMonitoring,
  closeRegisterMonitoring,

  getAreas,

  getResponsible,
  addResponsible,
  deleteResponsible,
  signResponsible,
};

export default MyRegistersMonitoring;
