
import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import {
    Unarchive
  } from "@mui/icons-material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";

import { useAppContext } from "../../../context/AppContext";

function ListArchived(props) {
    const {permissions} = useAppContext();

    const {
        riskMapArchived,
        colorCell,
        handleOpenModalConfirm
    } = props

    return (
        <TableBody
            // sx={{ display: documentType !== 1 ? "none" : "" }}
        >
            {riskMapArchived.map((row) => (
                <TableRow
                    hover
                    key={row.id_pets}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell sx={{ color: colorCell }}>
                        {row.name}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.register_status === 1 ? "Creado" : ""}
                        {row.register_status === 7 ? "Enviado a trabajadores" : ""}
                        {row.register_status === 3 ? "Enviado a comité" : ""}
                        {row.register_status === 4 ? "Publicado" : ""}
                        {row.register_status === 5 ? "Archivado" : ""}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                roles={permissions.DOC_MAP_RIESGO_ARCHIVADOS_DESARCHIVAR}
                                icon={<Unarchive sx={{ color: "white" }} />}
                                backgroundColor={"#FF5733"}
                                backgroundColorHover={"#FF5733"}
                                textTooltip={"Desarchivar"}
                                functionOnClick={() =>
                                    handleOpenModalConfirm(
                                        row.id,
                                        "¿Esta seguro de desarchivar el documento?",
                                        'Documento a desarchivar:  "' +
                                        row.name +
                                        '"',
                                        "Si, desarchivar",
                                        "unarchived"
                                    )
                                }
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListArchived;