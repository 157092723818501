import { React, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { Button, Card, CardContent, CardMedia, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, Stack,Typography  } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { Add, Delete, Save, Send, ThumbDown, ThumbUp } from '@mui/icons-material';
import SubtitleText from '../../components/text/subtitleText';
import TitleText from '../../components/text/titleText';
import BannerControla from '../../components/cards/bannerControla';
import ButtonControla from '../../components/buttons/buttonController';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import MyCommitteeElectionProcess from "../../api/MyCommitteeElectionProcess";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import SearchButtonControla from "../../components/search/searchButtonControla";
import MyUsers from "../../api/MyUsers";
import UsersPicturesControla from "../../components/images/usersPicturesControla";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import SearchUsersControla from "../../components/search/searchUsersControla";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";
import ReturnBack from "../../components/buttons/returnBackControla";

const grey = "#9191B0";

const StyledEnterButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: "#2D9CDB",
    color: "white",
    width: "70%"
});
const colorTitle = "#9191B0";


function Index() {

    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    // Dialogos de confirmacion INICIO

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");

    const handleOpenModalConfirm = (
        id,
        titleText,
        contentText,
        buttonTextConfirm,
        buttonActionType
    ) => {
        // setIdPrograms(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    };

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    };

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case "rejectElectoralCommissionStatus":
                handleUpdateElectoralCommissionStatus(3)
                break;
            case "acceptElectoralCommissionStatus":
                handleUpdateElectoralCommissionStatus(4)
                break;
            case "postulateCandidate":
                handleStoreCandidate();
                break;
        }
        setOpenModalConfirm(false);
    };

    const companyData = MyConfigurationApi.companyData();
    const companyName = companyData.name;

    const userData = MyConfigurationApi.userData();
    const userName = userData.first_name + ' ' + userData.last_name;

    const [idProcess, setIdProcess] = useState("");
    const handleGetProcessCompanies = async () => {
        handleOpenLoading();
        const response = await MyCommitteeElectionProcess.getProcessActives();

        if (response === null) {
            handleOpenAlert('No hay procesos en curso', 'success');
        } else {
            setIdProcess(response.id_committee_process);
            handleGetProcessSettings(response.id_committee_process);
            handleGetElectoralComission(response.id_committee_process);
            handleOpenAlert('Tienes un proceso en curso', 'success');
        }
    };

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [totalMembers, setTotalMembers] = useState("");
    const [totalMembersTitularWorkers, setTotalMembersTitularWorkers] = useState(0);
    const [totalMembersTitularCompanies, setTotalMembersTitularCompanies] = useState(0);
    const [totalOmbudsmanCandidate, setTotalOmbudsmanCandidate] = useState(0);
    const [totalMembersAlternateWorkers, setTotalMembersAlternateWorkers] = useState(0);
    const [totalMembersAlternateCompanies, setTotalMembersAlternateCompanies] = useState(0);
    const [statusPercentaje, setStatusPercentaje] = useState(0);
    const [mandateTerm, setMandateTerm] = useState({});
    const [idStatuses, setIdStatuses] = useState('');
    const [idElectionType, setIdElectionType] = useState('');
    const [electionType, setElectionType] = useState('');


    const handleGetProcessSettings = async (idProcess) => {
        const response = await MyCommitteeElectionProcess.getProcessSettings(idProcess);
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error xx', 'error');
        } else {
            handleGetTimelineStatuses(idProcess, response.id_committee_process_statuses);
            setStartDate(response.date_start || '');
            setEndDate(response.date_end || '');
            setIdElectionType(response.id_committee_process_election_type || '');
            setElectionType(response.committee_process_election_type || '');
            // setIdType(response.id_committee_process_types || '');
            // setIdMode(response.id_committee_process_mode || '');
            // setIdUserObserved(response.id_users_observed || '');
            // if (response.id_users_observed) {
            //     handleSelectUser(response.id_users_observed);
            // }
            setTotalMembers(response.total_members || '');
            setTotalMembersTitularCompanies(response.total_members_titular_companies || '');
            setTotalMembersAlternateCompanies(response.total_members_alternate_companies || '');
            setTotalMembersTitularWorkers(response.total_members_titular_workers || '');
            setTotalMembersAlternateWorkers(response.total_members_alternate_workers || '');
            setTotalOmbudsmanCandidate(response.total_ombudsman_candidate || '');
            setIdStatuses(response.id_committee_process_statuses || '');
            if (response.id_committee_process_statuses) {
                setStatusPercentaje(response.id_committee_process_statuses * 10);
            }

            const start = new Date(response.date_start);
            const end = new Date(response.date_end);

            const yearDiff = end.getFullYear() - start.getFullYear();
            const monthDiff = end.getMonth() - start.getMonth();
            const dayDiff = end.getDate() - start.getDate();

            if (dayDiff < 0) {
                const lastDayOfMonth = new Date(end.getFullYear(), end.getMonth(), 0).getDate();
                dayDiff = lastDayOfMonth + dayDiff;
                monthDiff--;
            }

            if (monthDiff < 0) {
                monthDiff += 12;
                yearDiff--;
            }

            setMandateTerm({ years: yearDiff, months: monthDiff, days: dayDiff });
        }
    };

    const [statusStartDate, setStatusStartDate] = useState("");
    const [statusEndtDate, setStatusEndtDate] = useState("");
    const [statusExpirationtDate, setStatusExpirationtDate] = useState("");
    const [statusName, setStatusName] = useState("");
    const [idStatusesTimeline, setIdStatusesTimeline] = useState("");

    const handleGetTimelineStatuses = async (idProcess, idProcessStatus) => {
        const response = await MyCommitteeElectionProcess.getTimelineStatuses({ id_committee_process: idProcess, id_committee_process_statuses: idProcessStatus });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error xx2', 'error');
        } else {
            setIdStatusesTimeline(response.id_committee_process_timeline);
            setStatusStartDate(response.timestamp_start);
            setStatusEndtDate(response.timestamp_end);
            setStatusExpirationtDate(response.timestamp_expiration);
            setStatusName(response.committee_process_status);
        }
    };

    const [idElectoralComissionPosition, setIdElectoralComissionPosition] = useState("");

    const [electoralComissionPosition, setElectoralComissionPosition] = useState("");
    const [electoralComissionStatus, setElectoralComissionStatus] = useState("");
    const [idElectoralComission, setIdElectoralComission] = useState("");

    const handleGetElectoralComission = async (idProcess) => {
        const response = await MyCommitteeElectionProcess.getElectoralComissionUser({ id_committee_process: idProcess });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error xx3', 'error');
        } else {
            setIdElectoralComissionPosition(response.id_committee_process_electoral_commission_position || '')
            setElectoralComissionPosition(response.committee_process_electoral_commission_position || '');
            setElectoralComissionStatus(response.committee_process_electoral_commission_status || '');
            setIdElectoralComission(response.id_committee_process_electoral_commission || '');
        }
    };

    useEffect(() => {
        handleGetProcessCompanies();
        handleGetUsers();
    }, []);

    const [checkedPropose, setCheckedPropose] = useState(false);
    const handleChangeCheckedPropose = (event) => {
        setCheckedPropose(event.target.checked);
    };

    const [settingObservation, setSettingObservation] = useState('');
    const handleStoreSettingsObservations = async () => {
        const response = await MyCommitteeElectionProcess.storeProcessSettingsObservations({ id_committee_process: idProcess, id_committee_process_timeline: idStatusesTimeline, setting_observation: settingObservation });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error xx4', 'error');
        } else {
            handleOpenAlert(response.msg, 'success');
            setCheckedPropose(false);
        }

        // setCheckedPropose(event.target.checked);
    };

    const handleUpdateElectoralCommissionStatus = async (idEditElectoralComission) => {
        handleOpenLoading();
        const response = await MyCommitteeElectionProcess.updateElectoralCommissionStatus({ id_committee_process: idProcess, id_users: userData.id_users, id_committee_process_electoral_commission_status: idEditElectoralComission });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error', 'error');
        } else {
            handleGetElectoralComission();
            handleOpenAlert(response.msg, 'success');
        }
    };

    const [openModalSelectUser, setOpenModalSelectUser] = useState(false);
    const [originSelectUser, setOriginSelectUser] = useState('postulate');
    const [idUserPostulate, setIdUserPostulate] = useState("");
    const [dataUserPostulate, setDataUserPostulate] = useState('');

    const handleOpenModalSelectUser = (origin) => {
        setOriginSelectUser(origin);
        setOpenModalSelectUser(true);
    };
    const handleCloseModalSelectUser = () => setOpenModalSelectUser(false);

    const [rowsUserObserved, setRowsUserObserved] = useState([]);
    const handleGetUsers = async () => {
        const response = await MyUsers.users();
        setRowsUserObserved(response.users_actives);
    };

    const handleSelectUser = async (idUsers) => {
        const response = await MyUsers.editUsers({ idUsers: idUsers });
        if (response.success !== false) {
            if (originSelectUser === 'postulate') {
                setIdUserPostulate(idUsers);
                setDataUserPostulate(response.document + ' - ' + response.first_name + ' ' + response.last_name)
            }
            handleCloseModalSelectUser();
        }
        return response;
    };

    const [document1, setDocument1] = useState(undefined);
    const [document2, setDocument2] = useState(undefined);
    const [document3, setDocument3] = useState(undefined);
    const handleStoreCandidate = async () => {
        handleOpenLoading();
        const response = await MyCommitteeElectionProcess.storeProcessCandidates({ id_committee_process: idProcess, id_users: idUserPostulate, document_1: document1, document_2: document2, document_3: document3 });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error', 'error');
        } else {
            setIdUserPostulate('');
            setDataUserPostulate('');
            setDocument1(undefined);
            setDocument2(undefined);
            setDocument3(undefined);
            handleOpenAlert(response.msg, 'success');
        }
    };

    const [rowsListCandidates, setRowsListCandidates] = useState([]);

    return (
        <>
            <Box sx={{ height: '100%' }}>
                <ReturnBack/>

                <LoadingControla
                    openLoading={openLoading}
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                    messageAlert={messageAlert}
                    typeAlert={typeAlert}
                />
                <DialogConfirmControla
                    openModalConfirm={openModalConfirm}
                    handleCloseModalConfirm={handleCloseModalConfirm}
                    titleText={titleText}
                    contentText={contentText}
                    buttonTextConfirm={buttonTextConfirm}
                    handleActionConfirm={handleActionConfirm}
                />
                <Container>

                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container sx={{ marginBottom: '15px' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ButtonMailboxControla
                                    originUrl={"/committee/process"}
                                />
                            </Grid>

                            <Grid item xs={6} sm={5} md={6} lg={6} xl={12}>
                                <SubtitleText
                                    text={"Comité"}
                                    color={grey}>
                                </SubtitleText>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <BannerControla
                                    text={'Proceso de elecciones ' + '(' + statusName + ')'}
                                    image={"/assets/images/committee/bannerVotaciones.png"}
                                />

                            </Grid>
                        </Grid>

                        {
                            (idStatuses === 1) ?

                                <Grid container sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Box sx={{ maxWidth: '500px', margin: 'auto', textAlign: 'center' }}>
                                            <TitleText text={"¡Bienvenido(a) " + userName + "!"} color={colorTitle}></TitleText>
                                            <Typography>
                                                El proceso esta en etapa de configuración, una vez sea actualizado te enteraras de todas las novedades por este medio.
                                            </Typography>
                                            <img src="/assets/images/committee/continueProcess.png" style={{ maxWidth: '400px', maxHeight: '400px', marginBottom: '15px' }} />
                                            <ButtonControla
                                                backgroundColor={'#CBCBFF'}
                                                backgroundColorHover={'#CBCBFF'}
                                                textButton={"Regresar"}
                                                isNavegationOrigin={true}
                                                url={"/committee"}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                : ''
                        }

                        {
                            (idStatuses === 2) ?

                                <Grid container sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Box sx={{ margin: 'auto', textAlign: 'justify' }}>
                                            <Box sx={{ textAlign: 'center' }}>
                                                <TitleText text={"Propuesta de conformación del comité"} color={colorTitle}></TitleText>
                                            </Box>
                                            <Typography>
                                                Fecha y hora de inicio: <b>{(statusStartDate) ? statusStartDate.slice(0, 19) : ''}</b>
                                            </Typography>
                                            <Typography>
                                                Asunto: Propuesta de número de miembros y comunicación para la elección de los representantes de los trabajadores ante el Comité de Seguridad y Salud Ocupacional de, para el período <b>{startDate + ' al ' + endDate}</b>.
                                            </Typography>
                                            <Typography>
                                                Estimados trabajadores,
                                            </Typography>
                                            <Typography>
                                                Tengo a bien dirigirme a ustedes a fin de poner en su conocimiento la necesidad de elegir a los representantes titulares y suplentes por parte de los trabajadores ante el Comité de Seguridad y Salud Ocupacional para el período <b>{startDate + ' al ' + endDate}</b>.
                                            </Typography>
                                            <Typography>
                                                En consecuencia, de acuerdo con lo establecido en el artículo 43 del Decreto Supremo N° 005-2012-TR, Reglamento de la Ley de Seguridad y Salud en el Trabajo, <b>{companyName}</b> les comunica la propuesta del número de personas que compondrán el Comité de Seguridad y Salud Ocupacional.
                                            </Typography>
                                            <Typography>
                                                Así, el número será de <b>{totalMembers}</b>, teniendo en cuenta que se ha considerado el nivel de riesgo de nuestras actividades, así como el número de trabajadores de la empresa.

                                            </Typography>
                                            <Typography>
                                                En caso de tener alguna observación, tienen hasta el <b>{(statusExpirationtDate) ? statusExpirationtDate.slice(0, 19) : ''}</b> para comunicárnoslo. De no realizarse observaciones se considerará por aceptada la presente propuesta.
                                            </Typography>
                                            <Typography>
                                                Agradecemos la participación de todos para el éxito del sistema de gestión de seguridad y salud ocupacional de nuestra empresa.
                                            </Typography>
                                            <Typography>
                                                Saludos.
                                            </Typography>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={checkedPropose}
                                                    onChange={handleChangeCheckedPropose}
                                                />
                                            } label="Estoy en desacuerdo respecto de la propuesta de conformación del Comité." />
                                            {
                                                checkedPropose === true ?
                                                    <InputControlaVertical text={'Observación'} inputType={'textArea'} inputValue={setSettingObservation} value={settingObservation} />
                                                    : ''
                                            }
                                            <Stack direction={'row'} spacing={2} justifyContent={'right'} sx={{ marginTop: '16px' }}>
                                                {
                                                    checkedPropose === true ?
                                                        <Box>
                                                            <ButtonControla
                                                                iconButton={<Save sx={{ color: "white" }} />}
                                                                backgroundColor={"#169073"}
                                                                backgroundColorHover={"#1BAA88"}
                                                                textButton={"Enviar comentario"}
                                                                functionOnClick={handleStoreSettingsObservations}
                                                            />
                                                        </Box>
                                                        : ''
                                                }
                                                <Box>
                                                    <ButtonControla
                                                        backgroundColor={'#CBCBFF'}
                                                        backgroundColorHover={'#CBCBFF'}
                                                        textButton={"Regresar"}
                                                        isNavegationOrigin={true}
                                                        url={"/committee"}
                                                    />
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                                : ''
                        }

                        {
                            (idStatuses === 3) ?

                                <Grid container sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Box sx={{ maxWidth: '600px', margin: 'auto', textAlign: 'center' }}>
                                            <TitleText text={"¡Comunicación de la configuración final!"} color={colorTitle}></TitleText>
                                            <Typography>
                                                <b>{companyName}</b> comunica a todos los trabajadores que de conformidad con el artículo 43 del Decreto Supremo N° 005-2012-TR, el número de miembros para conformar el Comité de Seguridad y Salud Ocupacional es de <b>{totalMembers}</b>
                                            </Typography>
                                            <img src="/assets/images/committee/continueProcess.png" style={{ maxWidth: '400px', maxHeight: '400px', marginBottom: '15px' }} />
                                            <ButtonControla
                                                backgroundColor={'#CBCBFF'}
                                                backgroundColorHover={'#CBCBFF'}
                                                textButton={"Regresar"}
                                                isNavegationOrigin={true}
                                                url={"/committee"}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                : ''
                        }

                        {(idStatuses === 4) ?
                            (idElectoralComission === '') ?
                                <Grid container sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Box sx={{ maxWidth: '600px', margin: 'auto', textAlign: 'center' }}>
                                            <TitleText text={"¡Eleccion de la junta electoral!"} color={colorTitle}></TitleText>
                                            <Typography>
                                                En esta etapa estan escogiendo los integrantes de la junta electoral, en caso seas escogido te enteraras por esta pagina.
                                            </Typography>
                                            <Typography>Si te llego la propuesta y aceptaste los cargos, puedes acceder por el modulo <b>Proceso de elecciones (configuracion)</b> que esta en la ventana anterior.
                                            </Typography>
                                            <img src="/assets/images/committee/continueProcess.png" style={{ maxWidth: '400px', maxHeight: '400px', marginBottom: '15px' }} />
                                            <ButtonControla
                                                backgroundColor={'#CBCBFF'}
                                                backgroundColorHover={'#CBCBFF'}
                                                textButton={"Regresar"}
                                                isNavegationOrigin={true}
                                                url={"/committee"}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Box sx={{ margin: 'auto', textAlign: 'left' }}>
                                            <Box sx={{ textAlign: 'center' }}>
                                                <TitleText text={"¡Fuiste elegido como miembro de la junta electoral!"} color={colorTitle}></TitleText>
                                            </Box>
                                            <Typography>
                                                Arequipa, <b>.{(statusStartDate) ? statusStartDate.slice(0, 19) : ''}</b>.
                                            </Typography>
                                            <Typography>
                                                Hola <b>{userName}</b>.,
                                            </Typography>
                                            <Typography>
                                                El propósito de la presente es para saludarte y, a la vez, invitarte a formar parte de la Junta Electoral a conformarse para el próximo proceso de elecciones del Comité de Seguridad y Salud Ocupacional, período <b>{startDate + ' al ' + endDate}</b>., en el cargo de <b>{electoralComissionPosition}</b>. Cabe señalar que la finalidad de la Junta Electoral, es convocar, organizar, dirigir, controlar y supervisar el proceso electoral con imparcialidad y objetividad. Asimismo, habiendo sido designado como <b>{electoralComissionPosition}</b>, tus funciones son:
                                            </Typography>
                                            {(idElectoralComissionPosition == 1) ?
                                                <ul>
                                                    <li><Typography>
                                                        Convocar, presidir y dirigir las sesiones y reuniones de trabajo de la Junta Electoral.
                                                    </Typography></li>
                                                    <li><Typography>
                                                        Firmar las actas, padrones del proceso electoral.
                                                    </Typography></li>
                                                    <li><Typography>
                                                        Impartir las directivas que sean necesarias para el desarrollo del proceso electoral.
                                                    </Typography></li>
                                                    <li><Typography>
                                                        Convocar, presidir y dirigir las sesiones y reuniones de trabajo de la Junta Electoral.
                                                    </Typography></li>
                                                </ul> : ''}
                                            {(idElectoralComissionPosition == 2) ?
                                                <ul>
                                                    <li><Typography>
                                                        Reemplazar al Presidente en caso de ausencia.
                                                    </Typography></li>
                                                    <li><Typography>
                                                        Redactar las Actas, hacer citaciones y mantener el archivo y Libro de Actas al día.
                                                    </Typography></li>
                                                    <li><Typography>
                                                        Preparar la documentación y agenda para las sesiones de la Junta Electoral.
                                                    </Typography></li>
                                                    <li><Typography>
                                                        Recibir las inscripciones de los candidatos.
                                                    </Typography></li>
                                                </ul>
                                                : ''}
                                            {(idElectoralComissionPosition == 3 || idElectoralComissionPosition == 4) ?
                                                <ul>
                                                    <li><Typography>
                                                        Coordinar con la Oficina de Administración y de Recursos Humanos, a fin de contar con los materiales necesarios para el cumplimiento de las actividades propias de la Junta.
                                                    </Typography></li>
                                                    <li><Typography>
                                                        Brindar apoyo en todo lo concerniente al desarrollo del proceso electoral.
                                                    </Typography></li>

                                                </ul>
                                                : ''}

                                            <Typography>
                                                Agradecemos tu participación en el proceso de elecciones y en el desarrollo y mejora del Sistema de Gestión en Seguridad y Salud Ocupacoinal.
                                            </Typography>
                                            <Typography>
                                                Saludos.
                                            </Typography>

                                            <Stack direction={'row'} spacing={2} justifyContent={'right'} sx={{ marginTop: '16px' }}>
                                                <Box>
                                                    <ButtonControla
                                                        iconButton={<ThumbUp sx={{ color: "white" }} />}
                                                        backgroundColor={"#169073"}
                                                        backgroundColorHover={"#1BAA88"}
                                                        textButton={"Aceptar cargo"}
                                                        functionOnClick={() => { handleOpenModalConfirm(1, 'Aceptar cargo', '¿Estás seguro de aceptar la propuesta para formar parte de la junta electoral?, se te habilitara el modulo para poder acceder a la configuracion del proceso', 'Sí, aceptar', 'rejectElectoralCommissionStatus'); }}
                                                    />
                                                </Box>
                                                <Box>
                                                    <ButtonControla
                                                        iconButton={<ThumbDown sx={{ color: "white" }} />}
                                                        backgroundColor={"#EB5757"}
                                                        backgroundColorHover={"#FF4040"}
                                                        textButton={"Rechazar cargo"}
                                                        functionOnClick={() => { handleOpenModalConfirm(1, 'Rechazar cargo', '¿Estás seguro de rechazar la propuesta para formar parte de la junta electoral?', 'Sí, rechazar', 'acceptElectoralCommissionStatus'); }}
                                                    />
                                                </Box>
                                                <Box>
                                                    <ButtonControla
                                                        backgroundColor={'#CBCBFF'}
                                                        backgroundColorHover={'#CBCBFF'}
                                                        textButton={"Regresar"}
                                                        isNavegationOrigin={true}
                                                        url={"/committee"}
                                                    />
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                            : ''}

                        {
                            (idStatuses === 5) ?

                                <Grid container sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Box sx={{ margin: 'auto', textAlign: 'left' }}>
                                            <Box sx={{ textAlign: 'center' }}>

                                                <TitleText text={"¡Etapa de postulación!"} color={colorTitle}></TitleText>
                                            </Box>
                                            <Typography>
                                                Empezamos la etapa de postulaciones y en esta oportunidad seran por {electionType}, {(idElectionType == 2) ? 'A continuación podras postular tú o postular a alguien mas para poder ser considerados en el proceso de votaciones, asegurate de cargar documentos que acrediten el porque el postulante deberia ser miembro.' : ''}
                                                {(idElectionType == 1) ? 'A continuación podras postular la lista con los que consideres puedan ser aptos para ser los nuevos miembros del comité, asegurate de cargar documentos que acrediten el porque los integrantes de la lista deberian ser miembros.' : ''}
                                            </Typography>

                                            {(idElectionType == 2) ?
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                                        <SearchButtonControla titleLabel={'Postulante:'} inputValue={dataUserPostulate} functionClick={() => { handleOpenModalSelectUser('postulate') }} modalType={false} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                                        <InputControlaVertical text={'Documento 1:'} inputType={'file'} inputValue={setDocument1} value={document1} modalType={false} required />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                                        <InputControlaVertical text={'Documento 2:'} inputType={'file'} inputValue={setDocument2} value={document2} modalType={false} required />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                                        <InputControlaVertical text={'Documento 3:'} inputType={'file'} inputValue={setDocument3} value={document3} modalType={false} required />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >

                                                        <Stack direction={'row'} spacing={2} justifyContent={'right'} sx={{ marginTop: '16px' }}>
                                                            <Box>
                                                                <ButtonControla
                                                                    iconButton={<Send sx={{ color: "white" }} />}
                                                                    backgroundColor={"#169073"}
                                                                    backgroundColorHover={"#1BAA88"}
                                                                    textButton={"Enviar postulación"}
                                                                    functionOnClick={() => { handleOpenModalConfirm(1, 'Postular', '¿Estás seguro de que la informacion enviada para la postulación es correcta?', 'Sí, postular', 'postulateCandidate'); }}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <ButtonControla
                                                                    backgroundColor={'#CBCBFF'}
                                                                    backgroundColorHover={'#CBCBFF'}
                                                                    textButton={"Regresar"}
                                                                    isNavegationOrigin={true}
                                                                    url={"/committee"}
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                                : ''
                                            }

                                            {(idElectionType == 1) ?
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                        <Typography>
                                                            Esta es tu lista con los candidatos inscritos, recuerda que esta lista solo la puedes modificar y ver tu, no se vera hasta que envies la postulacion
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                        <Stack direction={'row'} justifyContent={'right'}>
                                                            <Box>
                                                                <ButtonControla
                                                                    iconButton={<Add sx={{ color: "white" }} />}
                                                                    backgroundColor={"#169073"}
                                                                    backgroundColorHover={"#1BAA88"}
                                                                    textButton={"Agregar candidato a la lista"}
                                                                    functionOnClick={() => { }}
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                        <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                                                            {rowsListCandidates.map((row, key) => (
                                                                <Grid item key={key} xs={12} sm={6} md={3} lg={3} xl={3}>
                                                                    <Card sx={{ border: '1px solid black', borderRadius: '16px', }}>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '8px' }}>
                                                                            {(row.id_committee_process_electoral_commission_status == 3) ?
                                                                                <CardMedia sx={{
                                                                                    marginTop: 'auto',
                                                                                    marginBottom: 'auto',
                                                                                    maxWidth: '40px'
                                                                                }}
                                                                                >
                                                                                    <UsersPicturesControla textAvatar={row.first_name.charAt(0) + ' ' + row.last_name.charAt(0)} />
                                                                                </CardMedia>
                                                                                : ''}
                                                                            <CardContent sx={{
                                                                                paddingTop: '8px',
                                                                                paddingLeft: '8px',
                                                                                paddingRight: '8px',
                                                                                paddingBottom: '8px !important',
                                                                                width: '100%'
                                                                            }}>
                                                                                <Typography>
                                                                                    {row.first_name + ' ' + row.last_name}
                                                                                </Typography>
                                                                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                                                    <Box>
                                                                                        <Typography>
                                                                                            {row.document}
                                                                                        </Typography>
                                                                                        <Typography>
                                                                                            Cargo: {row.committee_process_electoral_commission_position}
                                                                                        </Typography>
                                                                                        <Typography>
                                                                                            Estado: {row.committee_process_electoral_commission_status}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    {(row.id_committee_process_electoral_commission_status == 1) ?
                                                                                        <Box sx={{ margin: 'auto' }}>
                                                                                            <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                                                                <Box>
                                                                                                    <ButtonIconControla
                                                                                                        // roles={[1, 7, 8]}
                                                                                                        icon={
                                                                                                            <Delete
                                                                                                                sx={{ color: "white" }}
                                                                                                            />
                                                                                                        }
                                                                                                        backgroundColor={"#EB5757"}
                                                                                                        backgroundColorHover={"#FF4040"}
                                                                                                        textTooltip={"Eliminar"}
                                                                                                        functionOnClick={() => { handleOpenModalConfirm(row.id_users, 'Eliminar miembro', '¿Estás seguro de eliminar el usuario como miembro de la junta electoral?', 'Sí, eliminar', 'deleteElectoralCommission'); }}
                                                                                                    />
                                                                                                </Box>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                        : ''}
                                                                                </Box>
                                                                            </CardContent>

                                                                        </Box>
                                                                    </Card>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >

                                                        <Stack direction={'row'} spacing={2} justifyContent={'right'} sx={{ marginTop: '16px' }}>
                                                            <Box>
                                                                <ButtonControla
                                                                    iconButton={<Send sx={{ color: "white" }} />}
                                                                    backgroundColor={"#169073"}
                                                                    backgroundColorHover={"#1BAA88"}
                                                                    textButton={"Postular lista"}
                                                                    functionOnClick={() => { handleOpenModalConfirm(1, 'Postular', '¿Estás seguro de que la informacion enviada en la lista para la postulacion es correcta?', 'Sí, postular', 'rejectElectoralCommissionStatus'); }}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <ButtonControla
                                                                    backgroundColor={'#CBCBFF'}
                                                                    backgroundColorHover={'#CBCBFF'}
                                                                    textButton={"Regresar"}
                                                                    isNavegationOrigin={true}
                                                                    url={"/committee"}
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </Grid>

                                                </Grid>
                                                : ''
                                            }

                                        </Box>
                                    </Grid>
                                </Grid>
                                : ''
                        }
                    </Box>
                </Container >
                <Dialog
                    open={openModalSelectUser}
                    onClose={handleCloseModalSelectUser}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText='Buscar Usuario' functionOnClose={handleCloseModalSelectUser} />
                    <DialogContent >
                        <SearchUsersControla rowsUser={rowsUserObserved} handleUsersActionButton={handleSelectUser} />
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={'row'} spacing={2}>
                            <Box>
                                <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalSelectUser} />
                            </Box>
                        </Stack>
                    </DialogActions>
                </Dialog>
            </Box >
        </>
    )
}

export default Index