import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  InputLabel,
  InputBase,
  Grid,
  IconButton,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tabs,
  Tab,
  TableBody,
  InputAdornment,
  TextField,
} from "@mui/material";
import Container from "@mui/material/Container";
import {
  Add,
  DriveFolderUpload,
  ArrowBackIosNew,
  ArrowForwardIos,
  Search,
  Clear
} from "@mui/icons-material";

import SubtitleText from "../../../../components/text/subtitleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import BannerControla from "../../../../components/cards/bannerControla";
import MyAts from "../../../../api/MyAts";
import ButtonControla from "../../../../components/buttons/buttonController";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import LoadingControla from "../../../../components/load/loadingControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import TableControla from "../../../../components/tables/table-controla";
import ListModels from "./components/list-models";
import ListActives from "./components/list-actives";
import ListArchived from "./components/list-archived";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import MyNotificacionNew from "../../../../api/MyNotificacionNew";
import { useAppContext } from "../../../../context/AppContext";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../../../components/buttons/returnBackControla";


const grey = "#9191B0";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 800,
  overflow: "visible",
  border: "1px solid #000",
  backgroundColor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};

function Index() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );

  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  if (!permissions) {
    handleOpenAlert('No se pudieron obtener los permisos', 'error')
  }
  // Pantallas de carga y alertas FIN

  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");
  const [dataNotification, setDataNotification] = useState({
    message: null,
    document_url: null,
    id_users_created: null,
    id_users_modified: null,
    id_users_sender: null,
    id_users_receiving: null
  });

  //traer dato del usuario
  const getUser = () => {
    return MyConfigurationApi.userData();
  }

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType,
    dataNotification
  ) => {
    setIdAts(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
    setDataNotification(dataNotification);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    const actions = {
      "delete": () => handleDeleteAts(),
      "sendWorkers": () => handleUpdateStatusesAts(2),
      "public": () => handleUpdateStatusesAts(3),
      "archived": () => handleUpdateArchiveAts(1),
      "unarchived": () => handleUpdateArchiveAts(0),
      "sendWorkersByTemplate": () => handleUpdateStatusAtsSendWorkers(2),
    }
    actions[buttonActionType] ? actions[buttonActionType]()
      : console.log(`Acción no controlada ${buttonActionType}`)
    setOpenModalConfirm(false);
  };
  // Dialogos de confirmacion FIN

  //* ----------- Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [archivado, setArchivado] = useState(2);

  const handleChangeTab = (event, newValue) => {
    setArchivado(newValue);
    setPage(0);
  }

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  //* ----------- Search
  const [search, setSearch] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const handleClearSearch = () => {
    setSearch('');
    setPage(0)
    handleGetAts(year, '', 0)
  }

  const handleSearch = (event) => {
    const input = event.target.value.trim();
    if (input === search) return;
    setSearch(input);
    setPage(0);

    if (debounceTimeout) clearTimeout(debounceTimeout);

    const newTimeout = setTimeout(() => {
      if (input || input === "") {
        handleGetAts(year, input, 0)
      }
    }, 500);
    setDebounceTimeout(newTimeout);
  }

  const [data, setData] = useState(null);
  // Main get method
  const handleGetAts = async (inputYear, search = '', pageN) => {
    handleOpenLoading();
    try {
      const response = await MyAts.ats({
        year: inputYear ?? year, page: pageN ?? page, search, perPage: rowsPerPage
      });
      console.log(response)
      if (!response || response.success === false) {
        return handleOpenAlert("Ocurrio un error al listar los ATS", "error");
      }
      if (response.data) setData(response.data);
      if (response.current_page) setPage(parseInt(response.current_page));
      if (response.perPage) setRowsPerPage(parseInt(response.per_page));
      if (response.total) setTotalRows(parseInt(response.total));
      if (response.last_page) setLastPage(parseInt(response.last_page));
      handleOpenAlert("Listando ATS activos del año ", "success");
      if (inputYear) {
        setDocumentType(1);
        handleOpenAlert("Listando ATS activos del año " + inputYear, "success");
      }
      return response;
    }
    catch (err) {
      console.log(`Error: ${err}`);
    }
    return null
  };

  const arrTHeadModels = ['#', 'Nombre del documento', 'Fecha de creación'];
  const arrTHead = ['#', 'Nombre del documento', 'Área', 'Tipo', 'Fecha', 'Estado'];

  const TableContent = {
    0: () => (<ListActives
      rowsActives={data}
      idUserLogged={idUserLogged}
      handleOpenModalConfirm={handleOpenModalConfirm}
      handleOpenModalEditUpload={handleOpenModalEditUpload}
    />),
    1: () => (<ListArchived
      rowsArchives={data}
      handleOpenModalConfirm={handleOpenModalConfirm}
    />),
    2: () => (<ListModels
      rowsModels={data}
      handleOpenModalConfirm={handleOpenModalConfirm}
    />),
  }

  const handlePostCreateAts = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyAts.createAts({
      name,
      code,
      model: 0,
      document_url: documentUrl,
    });
    if (response.success !== false) {
      await handleGetAts(year, search, page);
      handleCloseModalUpload();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Ocurrio un error al crear el ATS", "error");
    }
    return response;
  };

  const handlePostCreateModelAts = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyAts.createAts({ name, code, model: 1 });
    if (response.success !== false) {
      navigate("/ats/model/edit?id_ats=" + response.id_ats);
    } else {
      handleOpenAlert("Ocurrio un error al crear el modelo ATS", "error");
    }
    return response;
  };

  const handleDeleteAts = async () => {
    handleOpenLoading();
    const response = await MyAts.deleteAts({ id_ats: idAts });
    if (response.success !== false) {
      await handleGetAts(year, search, page);
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Ocurrio un error al crear el ATS", "error");
    }
    return response;
  };

  //datos del usuario logeado
  const user = MyConfigurationApi.userData();
  const idUserLogged = user.id_users;

  useEffect(() => {
    handleGetAts(year, search, page);
  }, [archivado, page, rowsPerPage]);

  const currentTime = new Date();
  const [year, setYear] = useState(currentTime.getFullYear());

  const [documentType, setDocumentType] = useState(
    idTabs !== undefined && idTabs !== null && idTabs !== ""
      ? parseInt(idTabs) : 0
  );
  const [idAts, setIdAts] = useState(null);

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  const handleIncreaseYear = (event, newValue) => {
    setYear(year + 1);
    handleGetAts(year + 1, search, page);
  };

  const handleDecreaseYear = (event, newValue) => {
    setYear(year - 1);
    handleGetAts(year - 1, search, page);
  };

  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [area, setArea] = useState(null);
  const [place, setPlace] = useState(null);
  const [hourStart, setHourStart] = useState(null);
  const [hourFinish, setHourFinish] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  const [openModalCreate, setOpenModalCreate] = useState(false);
  const handleOpenModalCreate = () => setOpenModalCreate(true);
  const handleCloseModalCreate = () => setOpenModalCreate(false);

  const [openModalUpload, setOpenModalUpload] = useState(false);
  const handleOpenModalUpload = () => {
    setName(null);
    setCode(null);
    setArea(null);
    setPlace(null);
    setHourStart(null);
    setHourFinish(null);
    setDocumentUrl(null);
    setOpenModalUpload(true);
  };

  const handleCloseModalUpload = () => setOpenModalUpload(false);

  const [openModalEditUpload, setOpenModalEditUpload] = useState(false);
  const handleOpenModalEditUpload = (idAts, name, code) => {
    setIdAts(idAts); setName(name); setCode(code);
    setOpenModalEditUpload(true);
  };

  const handleCloseModalEditUpload = () => setOpenModalEditUpload(false);

  const handleUpdateAts = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyAts.updateAts({
      id_ats: idAts, name, code,
      document_url: documentUrl,
    });
    if (response.success !== false) {
      handleGetAts(year, search, page);
      handleCloseModalEditUpload();
      handleOpenAlert(response.msg, "success");
      setDocumentType(1);
    } else {
      handleOpenAlert("Hubo un error al actualizar", "success");
    }
    return response;
  };

  const handleUpdateStatusesAts = async (idAtsStatuses) => {
    handleOpenLoading();
    const response = await MyAts.updateStatusesAts({
      id_ats: idAts,
      id_ats_statuses: idAtsStatuses,
    });
    if (response.success !== false) {
      handleGetAts(year, search, page);
      handleCloseModalEditUpload();
      // enviar notificación
      postNotification();
      //muestra alerta de exito
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  const postNotification = async () => {
    const response = await MyNotificacionNew.sendNotificacion(dataNotification);
  };

  const handleUpdateArchiveAts = async (archived) => {
    handleOpenLoading();
    const response = await MyAts.updateArchiveAts({
      id_ats: idAts,
      archived: archived,
    });

    if (response.success !== false) {
      handleGetAts(year, search, page);
      handleCloseModalEditUpload();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  const handleUpdateStatusAtsSendWorkers = async (idAtsStatus) => {
    handleOpenLoading();
    const resp = await MyAts.updateStatusesAts({
      id_ats: idAts, id_ats_statuses: idAtsStatus,
    });
    if (resp.success === true) {
      handleGetAts(year, search, page);
      handleOpenAlert("El ats se envío a trabajadores con éxito", "success");
    } else {
      handleOpenAlert("Ocurrió un error", "error");
    }
    handleCloseModalConfirm();
  }

  return (
    <>
      <Box sx={{ height: "100%" }}>

        <ReturnBack />

        <LoadingControla
          openLoading={openLoading}
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          messageAlert={messageAlert}
          typeAlert={typeAlert}
        />

        <DialogConfirmControla
          openModalConfirm={openModalConfirm}
          handleCloseModalConfirm={handleCloseModalConfirm}
          titleText={titleText}
          contentText={contentText}
          buttonTextConfirm={buttonTextConfirm}
          handleActionConfirm={handleActionConfirm}
        />

        <Container>
          <Box>
            {/* -------- Grid banner */}
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={5}>
                <ButtonMailboxControla
                  originUrl={"/ats"}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Procedimientos"}
                  color={grey}
                ></SubtitleText>
                <BannerControla
                  image="/assets/images/banners/documents/atsBanner.png"
                  color2="#2F67BC"
                  color1="#8FBCFF"
                  text="ATS"
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2, right: 0, justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
                <Stack direction={"row"} spacing={2}>
                  <Box>
                    <ButtonControla
                      roles={permissions.DOC_HG_PROC_ATS_MOD_AGREGAR_MODELO}
                      iconButton={<Add sx={{ color: "white" }} />}
                      backgroundColor={"#2D9CDB"}
                      backgroundColorHover={"#33AEF4"}
                      textButton={"Agregar modelo ATS"}
                      functionOnClick={handleOpenModalCreate}
                    />
                  </Box>
                  <Box>
                    <ButtonControla
                      roles={permissions.DOC_HG_PROC_ATS_MOD_SUBIR_ARCHIVO}
                      iconButton={<DriveFolderUpload sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Subir archivo ATS"}
                      functionOnClick={handleOpenModalUpload}
                    />
                  </Box>
                </Stack>
              </Grid>

              <Grid
                item xs={12} sm={12} md={2} lg={3} xl={3} sx={{ mt: 2 }}
              ></Grid>
              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={2} md={3} lg={3} xl={3}>
                    <InputLabel
                      sx={{
                        padding: "8px 0px 8px 0px",
                        fontSize: "14px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Periodo:
                    </InputLabel>
                  </Grid>
                  <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                    <InputBase
                      type="number"
                      value={year}
                      onChange={handleGetAts}
                      readOnly
                      sx={{
                        width: "100%",
                        padding: "4px 10px 4px 10px",
                        backgroundColor: "white",
                        color: "#305AD9",
                        fontSize: "14px",
                        borderRadius: "8px",
                      }}
                    ></InputBase>
                  </Grid>
                  <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        color: "#305AD9",
                      }}
                      onClick={handleDecreaseYear}
                    >
                      <ArrowBackIosNew />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        color: "#305AD9",
                      }}
                      onClick={handleIncreaseYear}
                    >
                      <ArrowForwardIos />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              {/* //? ------ Search input ------ */}
              <Grid item xs={12} sm={8} md={8} sx={{ mt: 2, mb: 0 }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder={"Buscar registros..."}
                  type={"text"}
                  value={search}
                  onChange={handleSearch}
                  style={{
                    width: '100%',
                    backgroundColor: "#FFF",
                    borderRadius: "16px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      search.length > 0 && (
                        <InputAdornment position="end">
                          <Clear sx={{ color: "#1638F2" }} onClick={handleClearSearch} />
                        </InputAdornment>
                      )
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 2, width: '100%', marginBottom: '1rem' }}>
              <Grid item xs={12}>
                <TableContainer sx={{ maxHeight: '60vh', marginBottom: '0', overflowY: 'auto', backgroundColor: 'white' }}>
                  <Table sx={{ minWidth: '100%', }} stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {(archivado === 2 ? arrTHeadModels : arrTHead).map((th, index) => (
                          <TableCell sx={{ color: "blue", lineHeight: '1.1rem' }} key={index}>{th}</TableCell>
                        ))}
                        <TableCell sx={{ position: 'sticky', right: 0, background: 'white' }}>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Tabs value={archivado} onChange={handleChangeTab} aria-label="status">
                              <Tab label="Modelos" value={2} />
                              <Tab label="Activos" value={0} />
                              <Tab label="Archivados" value={1} />
                            </Tabs>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {
                      (!data || data.length < 1)
                        ? <TableBody><TableRow>
                          <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                            No hay datos!
                          </TableCell>
                        </TableRow></TableBody>
                        : (<>{TableContent[archivado]()}</>)
                    }
                  </Table>
                </TableContainer>
                {/* //TODO: ------------- Paginación en español ------------- */}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Filas por página:"
                  labelDisplayedRows={() => `${page + 1}-${lastPage} de ${totalRows}`}
                  getItemAriaLabel={(type) => {
                    if (type === 'first') {
                      return 'Ir a la primera página';
                    }
                    if (type === 'last') {
                      return 'Ir a la última página';
                    }
                    if (type === 'next') {
                      return 'Ir a la página siguiente';
                    }
                    if (type === 'previous') {
                      return 'Ir a la página anterior';
                    }
                  }}
                  sx={{
                    width: '100%',
                    backgroundColor: "#FFF",
                    border: "1px solid #E0E0E0",
                    color: "#1638F2",
                    fontSize: "18px",
                    lineHeight: "1.2rem",
                  }}
                />
                {/* <TableControla
                  loading={openLoading}
                  tabs={[
                    {label: "Modelos", columnNames: ["Nombre del documento"]},
                    {label: "Activados",columnNames: ["Nombre del documento"]},
                    { label: "Archivados", columnNames: ["Nombre del documento"]}
                  ]}
                >
                  <ListModels
                    rowsModels={rowsModels}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                  />
                  <ListActives
                    rowsActives={rowsActives}
                    idUserLogged={idUserLogged}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                    handleOpenModalEditUpload={handleOpenModalEditUpload}
                  />
                  <ListArchived
                    rowsArchives={rowsArchives}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                  />
                </TableControla> */}

              </Grid>
            </Grid>

            <Dialog
              open={openModalCreate}
              onClose={handleCloseModalCreate}
              maxWidth="sm"
              fullWidth={true}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla
                titleText="Crear nuevo modelo ATS"
                functionOnClose={handleCloseModalCreate}
              />
              <form onSubmit={handlePostCreateModelAts}>
                <DialogContent>
                  <InputControlaVertical
                    text={"Código:"}
                    inputType={"text"}
                    inputValue={setCode}
                    modalType={true}
                    required
                  />
                  <InputControlaVertical
                    text={"Nombre del modelo ATS:"}
                    inputType={"text"}
                    inputValue={setName}
                    modalType={true}
                    required
                  />
                </DialogContent>
                <DialogActions>
                  <Stack direction={"row"} spacing={2}>
                    <Box>
                      <ButtonControla
                        backgroundColor={"#CBCBFF"}
                        textButton={"Cancelar"}
                        backgroundColorHover={"#CBCBFF"}
                        typeButton={"button"}
                        functionOnClick={handleCloseModalCreate}
                      />
                    </Box>
                    <Box>
                      <ButtonControla
                        iconButton={<Add />}
                        backgroundColor={"#169073"}
                        textButton={"Crear modelo de ATS"}
                        backgroundColorHover={"#1BAA88"}
                        typeButton={"submit"}
                      />
                    </Box>
                  </Stack>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog
              open={openModalUpload}
              onClose={handleCloseModalUpload}
              maxWidth="sm"
              fullWidth={true}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla
                titleText="Cargar documento ATS"
                functionOnClose={handleCloseModalUpload}
              />
              <form onSubmit={handlePostCreateAts}>
                <DialogContent>
                  <InputControlaVertical
                    text={"Código:"}
                    inputType={"text"}
                    inputValue={setCode}
                    modalType={true}
                    required
                  />
                  <InputControlaVertical
                    text={"Nombre del ATS:"}
                    inputType={"text"}
                    inputValue={setName}
                    modalType={true}
                    required
                  />
                  <InputControlaVertical
                    text={"Subir Archivo"}
                    inputType={"file"}
                    inputValue={setDocumentUrl}
                    modalType={true}
                    required
                  />
                </DialogContent>
                <DialogActions>
                  <Stack direction={"row"} spacing={2}>
                    <Box>
                      <ButtonControla
                        backgroundColor={"#CBCBFF"}
                        textButton={"Cancelar"}
                        backgroundColorHover={"#CBCBFF"}
                        typeButton={"button"}
                        functionOnClick={handleCloseModalUpload}
                      />
                    </Box>
                    <Box>
                      <ButtonControla
                        iconButton={<Add />}
                        backgroundColor={"#169073"}
                        textButton={"Cargar ATS"}
                        backgroundColorHover={"#1BAA88"}
                        typeButton={"submit"}
                      />
                    </Box>
                  </Stack>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog
              open={openModalEditUpload}
              onClose={handleCloseModalEditUpload}
              maxWidth="sm"
              fullWidth={true}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla
                titleText="Actuaizar documentol ATS"
                functionOnClose={handleCloseModalEditUpload}
              />
              <form onSubmit={handleUpdateAts}>
                <DialogContent>
                  <InputControlaVertical
                    text={"Código:"}
                    inputType={"text"}
                    value={code}
                    inputValue={setCode}
                    modalType={true}
                    required
                  />
                  <InputControlaVertical
                    text={"Nombre del ATS:"}
                    inputType={"text"}
                    value={name}
                    inputValue={setName}
                    modalType={true}
                    required
                  />
                  <InputControlaVertical
                    text={"Subir Archivo"}
                    inputType={"file"}
                    inputValue={setDocumentUrl}
                    modalType={true}
                  />
                </DialogContent>
                <DialogActions>
                  <Stack direction={"row"} spacing={2}>
                    <Box>
                      <ButtonControla
                        backgroundColor={"#CBCBFF"}
                        textButton={"Cancelar"}
                        backgroundColorHover={"#CBCBFF"}
                        typeButton={"button"}
                        functionOnClick={handleCloseModalEditUpload}
                      />
                    </Box>
                    <Box>
                      <ButtonControla
                        iconButton={<Add />}
                        backgroundColor={"#169073"}
                        textButton={"Actualizar ATS"}
                        backgroundColorHover={"#1BAA88"}
                        typeButton={"submit"}
                      />
                    </Box>
                  </Stack>
                </DialogActions>
              </form>
            </Dialog>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Index;
