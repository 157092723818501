import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import {
    BorderColorOutlined,
    Delete
} from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";

import { useAppContext } from "../../../context/AppContext";

function ListScheduled(props) {
    const {permissions} = useAppContext();

    const {
        currentMeetings,
        colorCell,
        handleOpenModalConfirm
    } = props
    return (
        <TableBody>
            {currentMeetings.map((meeting) => (
                <TableRow
                    hover
                    key={meeting.id_committee_meeting}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.id_committee_meeting}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.description}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.date}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.session === "1" ? "ordinario" : "extraordinario"}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.modality === "1" ? "presencial" : "remoto"}
                    </TableCell>

                    {/* botones de la tabla */}
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignContent="center"
                        >
                            <ButtonIconControla
                                roles={permissions.MYPE_REU_PROGRAM_VER_REU	}
                                icon={<VisibilityOutlined sx={{ color: "white" }}/> }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                isNavegationOrigin={true}
                                directionUrl={"/proceedingBook/scheduleMeetings/editScheduledMeeting?id_meeting=" + meeting.id_committee_meeting + "&readOnly=true"}
                            />
                            <ButtonIconControla
                                roles={permissions.MYPE_REU_PROGRAM_EDIT_REU}
                                icon={ <BorderColorOutlined sx={{ color: "white" }}/>}
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                isNavegationOrigin={true}
                                directionUrl={"/proceedingBook/scheduleMeetings/editScheduledMeeting?id_meeting=" + meeting.id_committee_meeting}
                            />
                            <ButtonIconControla
                                roles={permissions.MYPE_REU_PROGRAM_PUBLI_REU}
                                icon={<FileUploadOutlinedIcon  sx={{ color: "white" }}/>}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1AA483"}
                                textTooltip={"Notificar"}
                                functionOnClick={() => {
                                    handleOpenModalConfirm(
                                        meeting.id_committee_meeting,
                                        "¿Estás seguro de notificar la reunión?",
                                        "Una vez notificada no podrás editar la reunión",
                                        "Sí, notificar",
                                        "publish"
                                    )
                                }}
                            />
                            <ButtonIconControla
                                roles={permissions.MYPE_REU_PROGRAM_ELIM_REU}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => handleOpenModalConfirm(
                                    meeting.id_committee_meeting,
                                    "¿Estás seguro de eliminar esta reunión?",
                                    "Una vez eliminado no podrás recuperar la reunión",
                                    "Si, eliminar",
                                    "delete"
                                )}
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}

export default ListScheduled;