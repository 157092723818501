import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { Button, TextField, IconButton, Alert, Backdrop, CircularProgress } from '@mui/material';
import { Person, Lock, ArrowRightAlt, VisibilityOff, Visibility } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import MyLogin from "../api/MyLogin";
import './main.css'
import MyLocalStorage from '../api/MyLocalStorage';
import MyCrypto from '../api/MyCrypto';
import MyConfigurationApi from '../api/MyConfigurationApi';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function allyProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function Login() {
    const [openLoadView, setOpenLoadView] = useState(false);

    useEffect(() => {
        document.body.classList.add('bodyLogin');
        const userToken = MyConfigurationApi.tokenData().userToken;
        if (userToken) {
            setOpenLoadView(true);
            const handleValidateSession = async () => {
                try {
                    const response = await MyLogin.sessionValidate({ userToken });
                    if (!response.success) {
                        setAlertMessage(response.message);
                        setOpenLoadView(false);
                        return;
                    }
                    window.location.href = '/';
                } catch (error) {
                    console.error('Error al validar la sesión:', error);
                    setAlertMessage('Error al validar la sesión');
                    setOpenLoadView(false);
                }
            };
            handleValidateSession();
        }

    }, []);

    const [alertMessage, setAlertMessage] = useState('');

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const [passwordShow, setPasswordShow] = useState(false);
    const handleClickShowPassword = () => setPasswordShow(!passwordShow);
    const handleMouseDownPassword = () => setPasswordShow(!passwordShow);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmitLogin = async (event) => {
        event.preventDefault();
        setOpenLoadView(true);
        const response = await MyLogin.login({
            email, password
        })
        if (response.success === true) {
            let responseEncrypt = MyCrypto.crypt('controla', JSON.stringify(response));
            MyLocalStorage.setLocal('controla', responseEncrypt);
            window.location.href = '/';
        } else {
            setAlertMessage(response.message)
            setOpenLoadView(false);
        }

    }

    return (
        <div id='container'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoadView}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='navBar'>
                <div className='elements'>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label='Vertical tabs example'
                        orientation='vertical'
                        indicatorColor='#f5f5f5'
                        sx={{
                            "& button": {
                                borderTopLeftRadius: 22.5,
                                borderBottomLeftRadius: 22.5,
                                color: '#f5f5f5',
                                textTransform: 'none',
                                width: '110%',
                                height: '70px',
                                fontWeight: 'bold',
                                alignItems: 'flex-start',
                                '&.Mui-selected': {
                                    backgroundColor: '#f5f5f5'
                                }
                            },
                        }}
                    >
                        <Tab label='Iniciar sesión' {...allyProps(0)} />
                        <Tab label='Contáctanos' {...allyProps(1)} />
                    </Tabs>


                </div>
            </div>
            <div className='loginContainer'>
                <TabPanel value={value} index={0}>
                    <div className='divControlaLogo'>
                        <img className='controlaLogo' src='/assets/icons/controlaLogo.png' alt='LogoControla' />
                    </div>

                    <form onSubmit={handleSubmitLogin} >
                        <div className='loginForm'>
                            <Alert severity="error" sx={{
                                display: (alertMessage !== '' ? '' : 'none'),
                            }}>{alertMessage}</Alert>

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                background: 'white',
                                borderRadius: '8px',
                                padding: '0px 10px 10px 10px',
                            }}>
                                <Person style={{ color: '#8B8BD8', marginRight: '10px' }} />
                                <TextField id='emailTextField'
                                    label='Email'
                                    type='email'
                                    variant='standard'
                                    required
                                    value={email}
                                    InputLabelProps={{
                                        style: { color: '#8B8BD8' },
                                    }}
                                    sx={{
                                        width: '100%'
                                    }}
                                    onChange={({ target }) => setEmail(target.value)}
                                />
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                background: 'white',
                                borderRadius: '8px',
                                padding: '0px 10px 10px 10px',
                            }}>
                                <Lock style={{ color: '#8B8BD8', marginRight: '10px' }} />
                                <TextField id='passwordTextField'
                                    label='Contraseña'
                                    variant='standard'
                                    type={passwordShow ? 'text' : 'password'}
                                    value={password}
                                    required
                                    InputLabelProps={{
                                        style: { color: '#8B8BD8' },
                                    }}
                                    sx={{
                                        width: '100%'
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    style={{ color: '#8B8BD8', marginLeft: '10px' }}
                                                >
                                                    {passwordShow ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>

                                        )
                                    }}
                                    onChange={({ target }) => setPassword(target.value)}
                                />
                            </Box>

                            {/* <Link href="#" underline="hover">
                                <Typography sx={{ fontSize: 15, color: '#8B8BD8' }}>
                                    ¿Olvidaste la contraseña?
                                </Typography>
                            </Link> */}
                            <div className='loginButtons' style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                {/* <FormGroup> */}
                                {/* NO BORRAR */}
                                {/* <FormControlLabel
                                    control={<Checkbox style={{ color: '#8B8BD8' }} />}
                                    label={<Typography sx={{ color: '#8B8BD8', fontSize: 16 }}>
                                        Recordarme
                                    </Typography>} /> */}
                                {/* </FormGroup> */}
                                <Button type='submit' sx={{ textTransform: 'none', borderRadius: 3 }} variant="contained" endIcon={<ArrowRightAlt />} disableElevation>Acceder</Button>
                            </div>

                        </div>
                    </form>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div>
                        <p>
                            Vista de contactanos (Trabajando)
                        </p>
                    </div>
                </TabPanel>
            </div>


        </div>
    );
}

export default Login;