import { React, useState, useEffect } from "react";
import SubtitleText from '../components/text/subtitleText';
import Container from '@mui/material/Container';

import Box from '@mui/material/Box';
import { Grid, Stack, InputLabel, Dialog, DialogContent, DialogActions } from '@mui/material';
import {
    ArrowBackOutlined,
    PictureAsPdf,
} from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";

import CompanyLogoControla from "../components/images/companyLogoControla";
import TitleText from "../components/text/titleText";
import InputControlaVertical from "../components/textfields/inputControlaVertical";
import SearchButtonControla from "../components/search/searchButtonControla";
import ButtonControla from "../components/buttons/buttonController";
import DialogTitleControla from "../components/dialog/dialogTitleControla";
import SearchUsersControla from "../components/search/searchUsersControla";
import LoadingControla from "../components/load/loadingControla";
import MyUsers from "../api/MyUsers";
import MyStandards from "../api/MyStandards";
import { useAppContext } from "../context/AppContext";
import ButtonSignatureControla from "../components/buttons/buttonSignatureControla";
import ReturnBack from "../components/buttons/returnBackControla";

const colorTitle = "#9191B0";
const blue = "#034AFF";

function Edit() {
    const params = new URLSearchParams(window.location.search);
    const idStandard = params.get('id_standard');
    const readOnly = params.get('readOnly') == "true" ? true : false;

    const {permissions} = useAppContext();

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    //fin de alerts

    const [code, setCode] = useState(null);
    const [version, setVersion] = useState(null);
    const [title, setTitle] = useState(null);
    const [objective, setObjective] = useState(null);
    const [scope, setScope] = useState(null);
    const [legalReferences, setLegalReferences] = useState(null);
    const [specifications, setSpecifications] = useState(null);
    const [responsibles, setResponsibles] = useState(null);
    const [registers, setRegisters] = useState(null);
    const [review, setReview] = useState(null);

    const [preparedBy, setPreparedBy] = useState(null);
    const [idPreparedBy, setIdPreparedBy] = useState(null);
    const [datePreparedBy, setDatePreparedBy] = useState(null);
    const [stateFirmPrepare, setStateFirmPrepare] = useState(null);
    const [urlFirmPrepare, setUrlFirmPrepare] = useState(null);

    const [review1By, setReview1By] = useState(null);
    const [idReview1By, setIdReview1By] = useState(null);
    const [dateReview1By, setDateReview1By] = useState(null);
    const [stateFirmReview1, setStateFirmReview1] = useState(null);
    const [urlFirmReview1, setUrlFirmReview1] = useState(null);

    const [review2By, setReview2By] = useState(null);
    const [idReview2By, setIdReview2By] = useState(null);
    const [dateReview2By, setDateReview2By] = useState(null);
    const [stateFirmReview2, setStateFirmReview2] = useState(null);
    const [urlFirmReview2, setUrlFirmReview2] = useState(null);

    const [approveBy, setApproveBy] = useState(null);
    const [idApproveBy, setIdApproveBy] = useState(null);
    const [dateApproveBy, setDateApproveBy] = useState(null);
    const [stateFirmApprove, setStateFirmApprove] = useState(null);
    const [urlFirmApprove, setUrlFirmApprove] = useState(null);

    // modal de agregar responsable
    const [openModalCreateResponsible, setOpenModalCreateResponsible] = useState(false);
    const [typeResponsible, setTypeResponsible] = useState("");
    const [titleModalResponsible, setTitleModalResponsible] = useState("");

    const handleOpenModalCreateResponsible = (type) => {
        setOpenModalCreateResponsible(true)
        setTypeResponsible(type);
        switch (type) {
            case "preparedBy":
                setTitleModalResponsible("preparar");
                break;
            case "review1By":
                setTitleModalResponsible("revisar(1)");
                break;
            case "review2By":
                setTitleModalResponsible("revisar(2)");
                break;
            case "approveBy":
                setTitleModalResponsible("aprobar");
                break;
        }
    }
    const handleCloseModalCreateResponsible = () => {
        setOpenModalCreateResponsible(false)
    }

    const downloadPDF = async () => {
        try {
            const resp = await MyStandards.generatePDF(idStandard); // Llama a tu función que usa connectApiExcel
            const blob = await resp.blob(); // Convertir la respuesta en un blob

            // Crear un enlace temporal para descargar el archivo
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a'); // Aquí debe ser 'a' en lugar de 'r'
            link.href = url;
            link.setAttribute('download', `standar_${idStandard}.pdf`); // Establece el nombre del archivo
            document.body.appendChild(link);
            link.click();
            link.remove(); // Elimina el enlace después de la descarga

        } catch (error) {
            console.error('Hubo un error con la generacion del PDF', error);
        }
    }

    const handleSelectResponsible = async (idUser) => {
        handleOpenLoading();
        const resp = await MyUsers.editUsers({ idUsers: idUser });

        switch (typeResponsible) {
            case "preparedBy":
                setIdPreparedBy(idUser);
                setPreparedBy(resp.first_name + " " + resp.last_name);
                setUrlFirmPrepare(resp.firm_url);
                break;
            case "review1By":
                setIdReview1By(idUser);
                setReview1By(resp.first_name + " " + resp.last_name);
                setUrlFirmReview1(resp.firm_url);
                break;
            case "review2By":
                setIdReview2By(idUser);
                setReview2By(resp.first_name + " " + resp.last_name);
                setUrlFirmReview2(resp.firm_url);
                break;
            case "approveBy":
                setIdApproveBy(idUser);
                setApproveBy(resp.first_name + " " + resp.last_name);
                setUrlFirmApprove(resp.firm_url);
                break;
        }
        setOpenLoading(false);

        handleCloseModalCreateResponsible();
    }

    const [listUsers, setListUsers] = useState([]);

    const getUsers = async () => {
        const resp = await MyUsers.users();
        setListUsers(resp.users_actives);
    }

    const getActivedStandard = async () => {
        handleOpenLoading();

        if (Object.keys(permissions).length !== 0) {
            const resp = await MyStandards.getStandardById(idStandard);
            setCode(resp.data.code);
            setVersion(resp.data.version);
            setTitle(resp.data.document_title);
            setObjective(resp.data.objective);
            setScope(resp.data.scope);
            setLegalReferences(resp.data.legal_references_and_other_standards);
            setSpecifications(resp.data.standard_specifications);
            setResponsibles(resp.data.responsible_persons);
            setRegisters(resp.data.records_controls_and_documentation);
            setReview(resp.data.review_and_continuous_improvement);

            if (resp.data.id_users_prepare != null) {
                const respPreparedBY = await MyUsers.editUsers({ idUsers: resp.data.id_users_prepare });
                setPreparedBy(respPreparedBY.first_name + " " + respPreparedBY.last_name)
            }
            setIdPreparedBy(resp.data.id_users_prepare);
            setDatePreparedBy(resp.data.date_firm_prepare);
            setStateFirmPrepare(resp.data.firm_prepare);
            setUrlFirmPrepare(resp.data.prepare_firm_url);


            if (resp.data.id_users_review_1 != null) {
                const respReview1By = await MyUsers.editUsers({ idUsers: resp.data.id_users_review_1 });
                setReview1By(respReview1By.first_name + " " + respReview1By.last_name)
            }
            setIdReview1By(resp.data.id_users_review_1);
            setDateReview1By(resp.data.date_firm_review_1);
            setStateFirmReview1(resp.data.firm_review_1);
            setUrlFirmReview1(resp.data.review_1_firm_url);


            if (resp.data.id_users_review_2 != null) {
                const respReview2By = await MyUsers.editUsers({ idUsers: resp.data.id_users_review_2 });
                setReview2By(respReview2By.first_name + " " + respReview2By.last_name)
            }
            setIdReview2By(resp.data.id_users_review_2);
            setDateReview2By(resp.data.date_firm_review_2);
            setStateFirmReview2(resp.data.firm_review_2);
            setUrlFirmReview2(resp.data.review_2_firm_url);


            if (resp.data.id_users_approve != null) {
                const respApproveBy = await MyUsers.editUsers({ idUsers: resp.data.id_users_approve });
                setApproveBy(respApproveBy.first_name + " " + respApproveBy.last_name)
            }
            setIdApproveBy(resp.data.id_users_approve);
            setDateApproveBy(resp.data.date_firm_approve);
            setStateFirmApprove(resp.data.firm_approve);
            setUrlFirmApprove(resp.data.approve_firm_url);

            setOpenLoading(false);

        } 
    }

    const handleUpdateStandard = async () => {
        handleOpenLoading();

        const data = {
            code: code,
            version: version,
            document_title: title,
            objective: objective,
            scope: scope,
            legal_references_and_other_standards: legalReferences,
            standard_specifications: specifications,
            responsible_persons: responsibles,
            records_controls_and_documentation: registers,
            review_and_continuous_improvement: review,
            id_users_prepare: idPreparedBy,
            prepare_firm_url: urlFirmPrepare,
            id_users_review_1: idReview1By,
            review_1_firm_url: urlFirmReview1,
            id_users_review_2: idReview2By,
            review_2_firm_url: urlFirmReview2,
            id_users_approve: idApproveBy,
            approve_firm_url: urlFirmApprove,
        }

        const resp = await MyStandards.updateStandardById(data, idStandard);
        if (resp.success == true) {
            await getActivedStandard();
            handleOpenAlert("Estándar actualizada con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error al actualizar", "error");
        }
    }

    //fecha actual
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();

   
    const postSignByUser = async (data) => {
        handleOpenLoading();
        const resp = await MyStandards.updateStandardById(data, idStandard);

        if (resp.success == true) {
            await getActivedStandard();
            handleOpenAlert("Firmado con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error al firmar", "error");
        }
    }

    useEffect(() => {
        getUsers();
        getActivedStandard();
    }, [])

    useEffect(()=>{
        getActivedStandard();
    },[permissions])


    return (
        <Box sx={{ height: '100%' }}>
            <ReturnBack returnBack='/documentation/documents/standards?idTabs=1'/>

            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />
            <Container>
                <Box >
                    <Grid container columnSpacing={2} rowSpacing={2} alignItems="center">
                        <Grid item xs={11} sm={8} md={9} lg={10} xl={10}>
                            <SubtitleText text={"ESTÁNDARES"} color={colorTitle}></SubtitleText>
                            <TitleText text={ readOnly ? "Estándar" : "Editar estándar"} />

                        </Grid>
                        <Grid item xs={1} sm={4} md={3} lg={2} xl={2} sx={{ textAlign: 'right' }}>
                            <CompanyLogoControla />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputControlaVertical
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                text={"Código"}
                                inputType={"text"}
                                inputValue={setCode}
                                value={code}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputControlaVertical
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                text={"Versión"}
                                inputType={"text"}
                                inputValue={setVersion}
                                value={version}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputControlaVertical
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                text={"Título documento"}
                                inputType={"text"}
                                inputValue={setTitle}
                                value={title}
                                modalType={false}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                text={"Objetivo"}
                                inputType={"textArea"}
                                inputValue={setObjective}
                                value={objective}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                text={"Alcance"}
                                inputType={"textArea"}
                                inputValue={setScope}
                                value={scope}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                text={"Referencias legales y otras normas"}
                                inputType={"textArea"}
                                inputValue={setLegalReferences}
                                value={legalReferences}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                text={"Especificaciones del estándar"}
                                inputType={"textArea"}
                                inputValue={setSpecifications}
                                value={specifications}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                text={"Responsables"}
                                inputType={"textArea"}
                                inputValue={setResponsibles}
                                value={responsibles}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                text={"Registros, controles y documentación"}
                                inputType={"textArea"}
                                inputValue={setRegisters}
                                value={registers}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                text={"Revisión y mejora continua"}
                                inputType={"textArea"}
                                inputValue={setReview}
                                value={review}
                                modalType={false}
                                required
                            />
                        </Grid>

                        {/* preparado por */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container sx={{ mt: 2 }}>
                                <SubtitleText
                                    text={
                                        "Si ud. va a preparar y/o revisar y/o aprobar el documento registre su firma en el ícono firmar"
                                    }
                                    color={blue}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <SearchButtonControla
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                titleLabel={"Preparado por:"}
                                inputValue={preparedBy}
                                functionClick={() => handleOpenModalCreateResponsible("preparedBy")}
                                disabledButton={stateFirmPrepare == 1 ? true : false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <InputControlaVertical
                                text={"Fecha de Elaboración"}
                                inputType={"date"}
                                inputValue={setDatePreparedBy}
                                value={datePreparedBy}
                                modalType={false}
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Stack>
                                <Box>
                                    <InputLabel
                                        sx={{
                                            padding: "8px",
                                            fontSize: "18px",
                                            color: "#305AD9",
                                            fontWeight: "bold",
                                            textAlign: "center"
                                        }}
                                    >
                                        Firma
                                    </InputLabel>
                                </Box>
                                <Box sx={{textAlign: "center"}}>
                                    <ButtonSignatureControla
                                        idUser={idPreparedBy}
                                        firmStatus={stateFirmPrepare}
                                        firmUrl={urlFirmPrepare}
                                        functionOnClick={async() => {
                                            const data = {
                                                id_users_prepare: idPreparedBy,
                                                firm_prepare: true,
                                                date_firm_prepare: `${year}-${month}-${day}`
                                            }
                                            await postSignByUser(data);
                                        }}
                                    />
                                </Box>
                            </Stack>
                        </Grid>

                        {/* revisado 1 por  */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <SearchButtonControla
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                titleLabel={"Revisado por(1):"}
                                inputValue={review1By}
                                functionClick={() => handleOpenModalCreateResponsible("review1By")}
                                disabledButton={stateFirmReview1 == 1 ? true : false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <InputControlaVertical
                                text={"Fecha de revisión(1):"}
                                inputType={"date"}
                                inputValue={setDateReview1By}
                                value={dateReview1By}
                                modalType={false}
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Stack>
                                <Box>
                                    <InputLabel
                                        sx={{
                                            padding: "8px",
                                            fontSize: "18px",
                                            color: "#305AD9",
                                            fontWeight: "bold",
                                            textAlign: "center"
                                        }}
                                    >
                                        Firma
                                    </InputLabel>
                                </Box>
                                <Box sx={{textAlign: "center"}}>
                                    <ButtonSignatureControla
                                        idUser={idReview1By}
                                        firmStatus={stateFirmReview1}
                                        firmUrl={urlFirmReview1}
                                        functionOnClick={async()=>{
                                            const data = {
                                                id_users_review_1: idReview1By,
                                                firm_review_1: true,
                                                date_firm_review_1: `${year}-${month}-${day}`
                                            }
                                           await postSignByUser(data);
                                        }}
                                    />
                                </Box>
                            </Stack>
                        </Grid>

                        {/* revisado 2 por */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <SearchButtonControla
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                titleLabel={"Revisado por(2):"}
                                inputValue={review2By}
                                functionClick={() => handleOpenModalCreateResponsible("review2By")}
                                disabledButton={stateFirmReview2 == 1 ? true : false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <InputControlaVertical
                                text={"Fecha de revisión(2):"}
                                inputType={"date"}
                                inputValue={setDateReview2By}
                                value={dateReview2By}
                                modalType={false}
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Stack>
                                <Box>
                                    <InputLabel
                                        sx={{
                                            padding: "8px",
                                            fontSize: "18px",
                                            color: "#305AD9",
                                            fontWeight: "bold",
                                            textAlign: "center"
                                        }}
                                    >
                                        Firma
                                    </InputLabel>
                                </Box>
                                <Box sx={{textAlign: "center"}}>
                                    <ButtonSignatureControla
                                        idUser={idReview2By}
                                        firmStatus={stateFirmReview2}
                                        firmUrl={urlFirmReview2}
                                        functionOnClick={async() => {
                                            const data = {
                                                id_users_review_2: idReview2By,
                                                firm_review_2: true,
                                                date_firm_review_2: `${year}-${month}-${day}`
                                            }
                                            await postSignByUser(data);
                                        }}
                                    />
                                </Box>
                            </Stack>
                        </Grid>

                        {/* aprobado por */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <SearchButtonControla
                                roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                titleLabel={"Aprobado por:"}
                                inputValue={approveBy}
                                functionClick={() => handleOpenModalCreateResponsible("approveBy")}
                                disabledButton={stateFirmApprove == 1 ? true : false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <InputControlaVertical
                                text={"Fecha de aprobación:"}
                                inputType={"date"}
                                inputValue={setDateApproveBy}
                                value={dateApproveBy}
                                modalType={false}
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Stack>
                                <Box>
                                    <InputLabel
                                        sx={{
                                            padding: "8px",
                                            fontSize: "18px",
                                            color: "#305AD9",
                                            fontWeight: "bold",
                                            textAlign: "center"
                                        }}
                                    >
                                        Firma
                                    </InputLabel>
                                </Box>
                                <Box sx={{textAlign: "center"}}>
                                    <ButtonSignatureControla
                                        idUser={idApproveBy}
                                        firmStatus={stateFirmApprove}
                                        firmUrl={urlFirmApprove}
                                        functionOnClick = {async () => {
                                            const data = {
                                                id_users_approve: idApproveBy,
                                                firm_approve: true,
                                                date_firm_approve: `${year}-${month}-${day}`
                                            }
                                            await postSignByUser(data);
                                        }}
                                    />
                                </Box>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: 10, paddingBottom: 2 }}>
                            <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                                <Box>
                                    <ButtonControla
                                        iconButton={<ArrowBackOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#CBCBFF"}
                                        textButton={"Regresar"}
                                        backgroundColorHover={"#CBCBFF"}
                                        typeButton={"button"}
                                        isNavegationOrigin={true}
                                        url={"/documentation/documents/standards?idTabs=1"}
                                    />
                                </Box>
                                {
                                    !readOnly && (
                                        <>
                                            <Box>
                                                <ButtonControla
                                                    iconButton={<EditIcon sx={{ color: "white" }} />}
                                                    backgroundColor={"#169073"}
                                                    backgroundColorHover={"#1BAA88"}
                                                    textButton={"Actualizar Estándar"}
                                                    typeButton={"button"}
                                                    functionOnClick={handleUpdateStandard}
                                                />
                                            </Box>
                                        </>
                                    )
                                }
                                {
                                    readOnly && (
                                        <>
                                            <Box>
                                                {/* <ButtonControla
                                            iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                                            backgroundColor={"#F2994A"}
                                            backgroundColorHover={"#FF881E"}
                                            textButton={"Ver documento generado"}
                                            typeButton={"button"}
                                            url={""}
                                            target="_blank"
                                        /> */}

                                                <ButtonControla
                                                    iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                                                    backgroundColor={"#F2994A"}
                                                    backgroundColorHover={"#FF881E"}
                                                    textButton={"Generar PDF"}
                                                    typeButton={"button"}
                                                    functionOnClick={downloadPDF}
                                                />
                                            </Box>
                                        </>
                                    )
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            {/* dialogo de agregar responsables */}
            <Dialog
                open={openModalCreateResponsible}
                onClose={handleCloseModalCreateResponsible}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText={`Agregar responsable de ${titleModalResponsible} el Estándar`}
                    functionOnClose={handleCloseModalCreateResponsible}
                />
                <DialogContent>
                    <SearchUsersControla
                        rowsUser={listUsers}
                        handleUsersActionButton={handleSelectResponsible}
                    />
                </DialogContent>
                <DialogActions>
                    <Stack direction={"row"} spacing={2} justifyContent={"flex-start"}>
                        <ButtonControla
                            backgroundColor={"#CBCBFF"}
                            textButton={"Cancelar"}
                            functionOnClick={handleCloseModalCreateResponsible}
                        />
                    </Stack>
                </DialogActions>
            </Dialog>
        </Box>

    )
}
export default Edit