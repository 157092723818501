import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  InfoOutlined,
  PictureAsPdf,
  VisibilityOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import MyRegistersInspections from "../../../../api/MyRegistersInspections";
import MyAreas from "../../../../api/MyAreas";
import InputControlaVerticalIcon from "../../../../components/textfields/inputControlaVerticalIcon";
import { useAppContext } from "../../../../context/AppContext";
import { validationEditInputsByWorker } from "../../../../utils/validation_edit";
import ButtonSignatureControla from "../../../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";
const blue = "#034AFF";

function Edit() {
  const { permissions } = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const idRegisterInspection = params.get("idRegistersInspections");

  //validation view worker
  const isWorker = validationEditInputsByWorker()

  // BACKDROP
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };


  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  /////iduserid para responsables

  // id del responsable
  const [idRegisterResponsible, setIdRegisterResponsible] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
    setIdRegisterResponsible(id);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "deleteResponsible":
        handleDeleteResponsible({ idUsers: idRegisterResponsible });
        break;
      case "deleteResponsibleAreas":
        handleDeleteResponsibleAreas(idRegisterResponsible);
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN
  

  // agregar RESPONSABLES  DIALOG
  const [
    openModalUploadResponsibleRegistration,
    setOpenModalUploadCResponsibleRegistration,
  ] = useState(false);

  const handleOpenModalUploadResponsibleRegistration = async() => {

    await handleGetUsersForResponsibleRegistration();
    setOpenModalUploadCResponsibleRegistration(true);
    
  };

  const handleCloseModalUploadResponsibleRegistration = () => {
    setOpenModalUploadCResponsibleRegistration(false);
  };

  // variables inspecciones
  const [code, setCode] = useState("");
  const [area, setArea] = useState("");
  const [documentUrl1, setDocumentUrl1] = useState("");
  const [areaResponsible, setAreaResponsible] = useState("");
  const [conclusionsRecomendations, setConclusionsRecomendations] = useState("");
  const [date, setDate] = useState("");
  const [inspectionResponsible, setInspectionResponsible] = useState("");
  const [type, setType] = useState("");
  const [typeDetail, setTypeDetail] = useState("");
  const [objective, setObjective] = useState("");
  const [results, setResults] = useState("");
  const [description, setDescription] = useState("");

  const handleUpdateRegistersInspections = async (event) => {
    setOpenBackdrop(!openBackdrop);
    event.preventDefault();

    const response = await MyRegistersInspections.updateRegisterInspection({
      idRegister: idRegisterInspection,
      code: code,
      area: area,
      areaResponsible: areaResponsible,
      conclusionsRecomendations: conclusionsRecomendations,
      date: date,
      description: description,
      inspectionResponsible: inspectionResponsible,
      objective: objective,
      results: results,
      type: type,
      document_upload: true,
      document1: documentUrl1,
      typeDetail: typeDetail,
      register_status: 1,
    });

    if (response != null) {
      await handleEditRegisterInspections();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se actualizó de forma correcta los datos del registro"
      );
    }
  };

  const handleUpdateRegistersCloseInspections = async (event) => {

    setOpenBackdrop(!openBackdrop);
    event.preventDefault();

    if (responsibles.length >= 1) {
      const found = responsibles.find((item) => item.firm === 0);
      if (found === undefined) {
        const response = await MyRegistersInspections.updateRegisterInspection({
          idRegister: idRegisterInspection,
          code: code,
          area: area,
          areaResponsible: areaResponsible,
          conclusionsRecomendations: conclusionsRecomendations,
          date: date,
          description: description,
          inspectionResponsible: inspectionResponsible,
          objective: objective,
          results: results,
          type: type,
          document_upload: documentUrl1.type && documentUrl1.name ? true : false,
          document1: documentUrl1,
          typeDetail: typeDetail,
          register_status: 2,
        });
        if (response != null) {
          handleEditRegisterInspections();
          handleCloseBackdrop();
          handleClickAlert(
            "success",
            "Se guardaron de forma correcta los datos del registro"
          );
        }
      } else {
        handleCloseBackdrop();
        handleClickAlert(
          "error",
          "Complete las secciones obligatorias (Trabajador responsable)"
        );
      }
    } else {
      handleCloseBackdrop();
      handleClickAlert(
        "error",
        "Complete las secciones obligatorias (Trabajador responsable)"
      );
    }
  };


  const handleEditRegisterInspections = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersInspections.editRegisterInspection({
      idRegister: idRegisterInspection,
    });
    if (response != null) {
      setCode(response.code);
      setArea(response.id_areas);
      setAreaResponsible(response.area_responsible);
      setInspectionResponsible(response.inspection_responsible);
      setConclusionsRecomendations(response.conclusiones_recomendations);
      setDate(response.date);
      setDescription(response.description);
      setDocumentUrl1(response.document_1); //document_url_1
      setObjective(response.objective);
      setResults(response.results);
      setType(response.type);
      setTypeDetail(response.type_detail);
      setConclusionsRecomendations(response.conclusions_recomendations);
      if (response.register_status === 1) {
        setRegisterStatus(false);
      } else {
        setRegisterStatus(true);
      }
      handleCloseBackdrop();
    } else {
      handleClickAlert("error", "Error, no se pudo obtener los datos");
      handleCloseBackdrop();
    }
  };


  // Buscar responsables del area inspeccionada
  
  const [rowsSearchResponsiblesInspectedArea, setRowsSearchResponsiblesInspectedArea] = useState([]);

  const handleGetSearchResponsiblesInspectedArea = async() => {
    let users = usersActives;

    responsiblesAreas.forEach((user)=>{
      users = users.filter((u) => u.id_users !== user.id_users)
    })
    setRowsSearchResponsiblesInspectedArea(users);
  }

  // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(true);


  const [areasData, setAreasData] = useState([]);
  const [inspectionData, setInspectionData] = useState([
    "Planeada",
    "No planeada",
    "Otra",
  ]);

  
  //responsable del área inspeccionada
  const [openModalInspectionResponsible, setOpenModalInspectionResponsible] =
    useState(false);

  const handleOpenModalInspectionResponsible = () => {
    setOpenModalInspectionResponsible(true);
  };

 
  //Responsables del registro y de la investigación
  const [responsibles, setResponsibles] = useState([]);

  //obtiene responsables
  const handleGetResponsible = async () => {
    const response = await MyRegistersInspections.getResponsible({
      idRegister: idRegisterInspection,
    }) ?? [];

    setResponsibles(response);
  };

  //agregar responsables del registro
  const handleStoreResponsible = async (idUsers) => {
    setOpenBackdrop(true);

    try{
      const response = await MyRegistersInspections.addResponsible({
        idUser: idUsers,
        idRegister: idRegisterInspection,
      });
  
      if (response != null) {
        await handleGetResponsible();
        handleClickAlert("success", "Se agregó responsable con éxito");
        handleCloseModalUploadResponsibleRegistration();
      }
    }catch(e){
      handleClickAlert("error", "Ocurrió un error al agregar responsable");
    }

    handleCloseBackdrop();
  };
  //elimina responsables
  const handleDeleteResponsible = async ({ idUsers }) => {
    setOpenBackdrop(true);

    try{
      const response = await MyRegistersInspections.deleteResponsible({
        idUser: idUsers,
        idRegister: idRegisterInspection,
      });

      await handleGetResponsible();
      handleClickAlert("success", "Se eliminó el responsable del registro con éxito");
    }catch(e){
      handleClickAlert("error", "Ocurrió un error al eliminar responsable");
    }

    handleCloseBackdrop();
  };
  //firma responsables
  const handleSignResponsible = async (idAccidentsResponsible,idUserIdResponsible ) => {
    setOpenBackdrop(true);
    try{
      const response = await MyRegistersInspections.signResponsible({
        idUserId: idUserIdResponsible,
        idUser: idAccidentsResponsible,
      });

      await handleGetResponsible();
      handleClickAlert("success","Se registró la firma con éxito");

    }catch(e){
      handleClickAlert("error", "Ocurrió un error al firmar");
    }

    
    handleCloseBackdrop();
  };
  
  ///Handle get Areas
  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setAreasData(response);
    return response;
  };

  // handle get users para el inspection responsible

  const [usersActives, setUsersActives] = useState([]);
  const handleGetUsersActives = async () => {
    const response = await MyUsers.users();
    if (response.success === false) {
      alert("Error");
    } else {
      setUsersActives(response.users_actives);
    }
  };

  const [rowsSearchsResponsiblesRegistration, setRowsSearchsResponsiblesRegistration] = useState([]);

  const handleGetUsersForResponsibleRegistration = async() => {
    let users = usersActives;

    responsibles.forEach((user)=>{
      users = users.filter((u) => u.id_users !== user.id_users)
    });

    setRowsSearchsResponsiblesRegistration(users);
  }

  // jalar y mostrar datos del usuario responsable de la inspección
  const handleGetUsersInspectionResponsible = async (idUser) => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setInspectionResponsible(response.first_name + " " + response.last_name);
      setOpenModalInspectionResponsible(false);
    }
    handleCloseBackdrop();
  };

  
  //modal para seleccionar responsable del area inspeccionada
  const [openModalUploadResponsibleAreas, setOpenModalUploadResponsibleAreas] =
    useState(false);
  const handleOpenModalUploadResponsibleAreas = async() => {

    await handleGetSearchResponsiblesInspectedArea();
    setOpenModalUploadResponsibleAreas(true);
  };

  const handleCloseModalUploadResponsibleAreas = () => {
    setOpenModalUploadResponsibleAreas(false);
  };

  const [responsiblesAreas, setResponsiblesAreas] = useState([]);

  const handleGetResponsibleAreas = async () => {
    const response = await MyRegistersInspections.getResponsibleAreas({
      idRegister: idRegisterInspection,
    });

    setResponsiblesAreas(response);
  };

  const handleStoreResponsibleAreas = async (idUsers) => {
    setOpenBackdrop(true);

    const response = await MyRegistersInspections.addResponsibleAreas({
      idUser: idUsers,
      idRegister: idRegisterInspection,
    });

    if (response != null) {
      await handleGetResponsibleAreas();
      handleClickAlert("success","Se agregó responsable del área inspeccionada con éxito")
      handleCloseModalUploadResponsibleAreas();
    }

    handleCloseBackdrop();
  };

  const handleDeleteResponsibleAreas = async (idUsers) => {
    setOpenBackdrop(true);

    const response = await MyRegistersInspections.deleteResponsibleAreas({
      idUser: idUsers,
    });
    if (response.success) {
      await handleGetResponsibleAreas();
      handleClickAlert("success", "Se eliminó el responsable con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al eliminar responsable");
    }

    handleCloseBackdrop();
    return response;
  };

  useEffect(() => {
    handleEditRegisterInspections();

    handleGetResponsible();
    handleGetAreas();
    handleGetUsersActives();
    handleGetResponsibleAreas();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={
                  "Inspecciones internas de seguridad y salud en el trabajo"
                }
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                roles={permissions.DOC_REGIS_INSPEC_INT_EDITAR}
                text={"Nro registro"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
                required
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={4} xl={4} />
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUpdateRegistersCloseInspections}>

            <Grid>
              <InputLabel
                sx={{
                  padding: "8px",
                  fontSize: "18px",
                  color: "#305AD9",
                  fontWeight: "bold",
                }}
              >
                Datos de la inspección
              </InputLabel>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <SelectControla
                  roles={permissions.DOC_REGIS_INSPEC_INT_EDITAR}
                  text={"Área inspeccionada"}
                  inputType={"text"}
                  inputValue={setArea}
                  value={area}
                  modalType={false}
                  required
                  childrenRows={areasData.map((row) => (
                    <MenuItem key={row.id_areas} value={row.id_areas}>
                      {row.area}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_INSPEC_INT_EDITAR}
                  text={"Fecha de inspección"}
                  inputType={"date"}
                  inputValue={setDate}
                  value={date}
                  required
                />
              </Grid>

              {/* responsables del area inspeccionada*/}
              <Grid container item>
                <Stack
                  width="100%"
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: 3, marginBottom: 1 }}
                >
                  <Stack direction="row" sx={{ marginBottom: 1 }}>
                    <Box>
                      <InputLabel
                        sx={{
                          padding: "8px",
                          fontSize: "18px",
                          color: "#305AD9",
                          fontWeight: "bold",
                        }}
                      >
                        Responsables del área inspeccionada
                      </InputLabel>

                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        sx={{ color: "#F2994A", ml: 1 }}
                      >
                        Este campo es obligatorio
                      </Typography>
                    </Box>
                    <Box>
                      <ButtonIconControla
                        icon={<InfoOutlined sx={{ color: "white" }} />}
                        backgroundColor={"#FFC300"}
                        backgroundColorHover={"#FACB32"}
                        disabled={isWorker}
                        textTooltip={
                          "Completar los nombres de los responsables del área o las áreas que han sido inspeccionadas en materia de seguridad y salud en el trabajo"
                        }
                      />
                    </Box>
                  </Stack>
                  <Box sx={
                    {
                      display: "flex",
                      alignItems: "center"
                    }
                  }>
                    {registerStatus === false && (
                      <ButtonControla
                        roles={permissions.DOC_REGIS_INSPEC_INT_AGREGAR_RESPONS}
                        iconButton={<Add sx={{ color: "white" }} />}
                        backgroundColor={"#169073"}
                        backgroundColorHover={"#1BAA88"}
                        textButton={"Agregar responsables"}
                        functionOnClick={handleOpenModalUploadResponsibleAreas}
                      />
                    )}
                  </Box>
                </Stack>
              </Grid>
              {/* responsables del area inspeccionada*/}

              <Grid container item >
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Nombre y Apellido
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Documento de identidad
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {responsiblesAreas.map((row) => (
                        <TableRow
                          hover
                          key={row.id_users}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.first_name + " " + row.last_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.document}
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + row.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                              {registerStatus === false && (
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Estás seguro de eliminar los datos del trabajador responsable del área inspeccionada?",
                                      "Una vez eliminado no podrás recuperar los datos del trabajador responsable",
                                      "Si, eliminar",
                                      "deleteResponsibleAreas"
                                    );
                                  }}
                                />
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  roles={permissions.DOC_REGIS_INSPEC_INT_EDITAR}
                  titleLabel="Responsable de la inspección "
                  inputValue={inspectionResponsible}
                  functionClick={handleOpenModalInspectionResponsible}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <SelectControla
                  roles={permissions.DOC_REGIS_INSPEC_INT_EDITAR}
                  text={"Tipo de inspección"}
                  inputType={"text"}
                  inputValue={setType}
                  value={type}
                  required
                  childrenRows={inspectionData.map((row) => (
                    <MenuItem key={row} value={row}>
                      {row}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_INSPEC_INT_EDITAR}
                  text={"Detalle del tipo de inspección"}
                  inputType={"text"}
                  inputValue={setTypeDetail}
                  value={typeDetail}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_INSPEC_INT_EDITAR}
                  text={"Objetivo de la inspección interna"}
                  inputType={"textArea"}
                  inputValue={setObjective}
                  value={objective}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVerticalIcon
                  roles={permissions.DOC_REGIS_INSPEC_INT_EDITAR}
                  text={"Resultado de la inspección interna"}
                  inputType={"textArea"}
                  inputValue={setResults}
                  value={results}
                  textTooltip={
                    "Indicar nombre completo del personal que participó en la inspección interna."
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_INSPEC_INT_EDITAR}
                  text={
                    "Descripción de la causa ante resultados desfavorables de la inspección"
                  }
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_INSPEC_INT_EDITAR}
                  text={"Conclusiones  y recomendaciones"}
                  inputType={"textArea"}
                  inputValue={setConclusionsRecomendations}
                  value={conclusionsRecomendations}
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={11} md={11}>
                <InputControlaVerticalIcon
                  roles={permissions.DOC_REGIS_INSPEC_INT_EDITAR}
                  text={"Adjuntar"}
                  inputType={"file"}
                  inputValue={setDocumentUrl1}
                  textTooltip={"Lista de verificación de ser el caso"}
                />
              </Grid>
              <Grid
                item
                xs={1}
                md={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonIconControla
                  roles={permissions.DOC_REGIS_INSPEC_INT_VER_DOCUMENTO}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl1}
                  directionTarget={"_blank"}
                />
              </Grid>
            </Grid>

            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === false && (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={
                      handleOpenModalUploadResponsibleRegistration
                    }
                  />
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Documento de identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                           textAlign: "center"
                        }}
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibles.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.firm_date === null
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <ButtonSignatureControla
                              idUser={row.id_users}
                              firmStatus={row.firm}
                              firmUrl={row.firm_url}
                              functionOnClick={async() => {
                                await handleSignResponsible(row.id,row.id_users)
                              }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + row.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                            {registerStatus === false && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador responsable",
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {/* tabla para mostrar accidents workers */}

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              {/* cerrado temporalmente */}
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                />
              </Grid> */}
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={"/documentation/registers/generals/inspections"}
                />
              </Grid>
              {registerStatus === false && (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={permissions.DOC_REGIS_INSPEC_INT_ACTUALIZAR_REGIS}
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton="submit"
                    functionOnClick={handleUpdateRegistersInspections}
                  />
                </Grid>
              )}
              {registerStatus === false && (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={permissions.DOC_REGIS_INSPEC_INT_CERRAR_REGIS}
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    typeButton="submit"
                  />
                </Grid>

              )}
              {registerStatus === true && (
                <Grid item xs={12} md={3}>
                  <ButtonControla
                    roles={permissions.DOC_REGIS_INSPEC_INT_VER_PDF}
                    iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                    backgroundColor={"#F2994A"}
                    backgroundColorHover={"#FF881E"}
                    textButton={"Ver documento PDF"}
                    typeButton={"button"}
                    url={process.env.REACT_APP_API_DJANGO + 'report/' + idRegisterInspection}
                    target={"_blank"}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesRegistration}
              handleUsersActionButton={handleStoreResponsible}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/*responsable del area inspeccionada  */}
      <Dialog
        open={openModalUploadResponsibleAreas}
        onClose={handleCloseModalUploadResponsibleAreas}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Área Inspeccionada
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchResponsiblesInspectedArea}
              handleUsersActionButton={handleStoreResponsibleAreas}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleAreas}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* */}

      {/*Dialog de agregar responsables de la inspeccion*/}
      <Dialog
        open={openModalInspectionResponsible}
        onClose={() => {
          setOpenModalInspectionResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Inspección
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersInspectionResponsible}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalInspectionResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 99,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Edit;
