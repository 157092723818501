import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import { BorderColorOutlined, Delete} from "@mui/icons-material";

import { useAppContext } from "../../../../../context/AppContext";

const ListModels = (props)=> {
    const {permissions} = useAppContext();

    const {
        rowsModels,
        documentType,
        handleOpenModalConfirm
    } = props

    return (
        <TableBody>
            {rowsModels.map((row, index) => (
                <TableRow
                    hover key={row.id_ats} sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell>
                        {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.timestamp_created ? new Date(row.timestamp_created).toLocaleString('es-PE', { timeZone: 'America/Lima' }) : ''}
                    </TableCell>
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                icon={
                                    <BorderColorOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}                                                         
                                isNavegationOrigin={true}
                                directionUrl={'/ats/model/edit?id_ats=' + row.id_ats}
                            />

                            <ButtonIconControla
                                roles={permissions.DOC_HG_PROC_ATS_MOD_ELIMINAR}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                    handleOpenModalConfirm(
                                        row.id_ats,
                                        "¿Estás seguro de eliminar el modelo?",
                                        'Una vez eliminado no se podrá recuperar todo lo incluido en este modelo ATS:  "' +
                                        row.name +
                                        '"',
                                        "Si, Eliminar",
                                        "delete"
                                    );
                                }}
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );

}

export default ListModels;