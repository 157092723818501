import MyConfigurationApi from "./MyConfigurationApi";
// import MyConfigurationApiCrm from "./MyConfigurationApiCrm";

function getMemberships (){
    const url = "memberships/get";

    return MyConfigurationApi.connectApi({
        dataJson:null,
        method:"GET",
        url: url
    })
}

function postMemberships (dataMemberships) {
    const url = "memberships/create";
    return MyConfigurationApi.connectApi({
        dataJson: dataMemberships,
        method: "POST",
        url: url,
    });
}

function deleteMemberships(id_memberships){
    const url = "memberships/remove/" + id_memberships;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });

}

function updateMemberships(id_memberships, data){
    const url = "memberships/update/" + id_memberships;
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });

}

function getCompanyMemberships(){
    const url = "memberships/company/get";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });

}

function getCompanies(){
    const url = "companies/get";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });

}

function postCompanyMemberships (data){
    const url = "memberships/company/create";
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: "POST",
        url: url,
    });

}

function updateCompanyMemeberships(id_CompanyMemberships , data){
    const url = "memberships/company/update/" + id_CompanyMemberships;
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });
}
function deleteCompanyMemberships (id_CompanyMemberships){
    const url = "memberships/company/remove/" + id_CompanyMemberships;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });

}


function postConfigModuleMemberships (data){
    const url = "memberships/configuration/create";
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}
function deleteConfigModuleMemberships (id_configModuleMemberships) {
    const url = "memberships/configuration/remove/" + id_configModuleMemberships;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}

function updateStatusConfigModuleMemberships(id_configModuleMemberships, data){
    const url = "memberships/configuration/update/" + id_configModuleMemberships;
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });

}

function getModulesAll (){
    const url = "configurationModules/get";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });

}
function getModuleGalleryBySection(id_section){
    const url = "configurationModules/get/gallery/section/" + id_section;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });

}

function getSections (){
    const url = "configurationSections/sections/memberships/get";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });

}
function getAllSection (){
    const url = "configurationSections/all/get";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function postSectionByMembership(data){
    const url = "configurationSections/section/create";
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: "POST",
        url: url,
    });

}

function deleteSectionByMembership(id_SectionByMembership){
    const url = "configurationSections/section/remove/" + id_SectionByMembership;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });

}
//modulos
function postModuleBySection (data){
    const url = "configurationModules/module/create";
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: "POST",
        url: url,
    }); 
}
function getModulesBySection (id_section_membership){
    const url = "configurationModules/section/module/get/" +id_section_membership ;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    }); 

}
function deleteModuleBySection (id_config_module){
    const url = "configurationModules/module/remove/" + id_config_module;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    }); 

}

function getSubModulesByModule (id_config_section){
    const url = "getSubModulesByModule/" + id_config_section;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    }); 

}
function getModulesGalleryByParent (id_parent){
    const url = "configurationModules/get/parent?parent_id=" + id_parent;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    }); 
}


const MyMemberships = {
    getMemberships,
    postMemberships,
    deleteMemberships,
    updateMemberships,
    getCompanyMemberships,
    getCompanies,
    postCompanyMemberships,
    updateCompanyMemeberships,
    deleteCompanyMemberships,
    postConfigModuleMemberships,
    deleteConfigModuleMemberships,
    updateStatusConfigModuleMemberships,
    getModulesAll,
    getModuleGalleryBySection,
    getSections,
    getAllSection,
    postSectionByMembership,
    deleteSectionByMembership,
    postModuleBySection,
    getModulesBySection,
    deleteModuleBySection,
    getSubModulesByModule,
    getModulesGalleryByParent

    
};

export default MyMemberships;