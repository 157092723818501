import { useEffect, useState } from "react";
import MyConfigurationApi from "../api/MyConfigurationApi";
import MyLocalStorage from "../api/MyLocalStorage";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import HomeView from "../components/home/homeView";
import { useLocation } from "react-router-dom";

export const AuthGuard = () => {
  const location = useLocation();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false); //
  const { getSectionsModules, sectionsModules } = useAppContext();

  useEffect(() => {
    const tokenData = MyConfigurationApi.tokenData();
    const userToken = tokenData.userToken;
    const userTokenExpired = tokenData.userTokenExpired;

    if (userToken) {
      var currentTime = new Date();
      var numberOfMlSeconds = currentTime.getTime();
      var addMlSeconds = -5 * 60 * 60000;
      var sumHoursToken = numberOfMlSeconds + addMlSeconds;
      var currentDateTime = new Date(sumHoursToken).toISOString();
      var userTokenExpiredTime = new Date(
        userTokenExpired.replace(/-/g, "/").replace("T", " ") + " UTC"
      ).toISOString();

      setIsAuthenticated(true)
      getSectionsModules()
      setIsAuthChecked(true);


      if (userTokenExpiredTime < currentDateTime) {
        MyLocalStorage.clearLocal();
        setIsAuthenticated(false)
      }
    } else {
      setIsAuthenticated(false)
      setIsAuthChecked(true);
    }
  }, [location]);

  return isAuthChecked ? (
    isAuthenticated ? (
      <>
        <Outlet>
          <Routes>
            <Route  path="/testing" element={<HomeView />} />
          </Routes>
        </Outlet>
      </>
    ) : (
      <Navigate replace to="/login" />
    )
  ) : null;
}