import * as React from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";

import BannerControla from "../../../../components/cards/bannerControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import MyDangerousIncident from "../../../../api/MyDangerousIncident";

import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import { useAppContext } from "../../../../context/AppContext";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../../../components/buttons/returnBackControla";
import TableControla from "../../../../components/tables/table-controla";
import ListActives from "./components/list-actives";
import LoadingControla from "../../../../components/load/loadingControla";

const colorTitle = "#9191B0";
const purple = "#8B8BD8";

function Index() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();

  const [code, setCode] = React.useState("");
  
  const [rowsActives, setRowsActives] = React.useState([]);
  const [openModalNewRegister, setOpenModalNewRegister] = React.useState(false);

  // alerts
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts

  const handleOpenModalNewRegister = () => {
    setCode(null);
    setOpenModalNewRegister(true);
  };

  const hadleGetRegistersDangerousIncident = async () => {
    handleOpenLoading();
    const response = await MyDangerousIncident.getRegistersDangerousIncident();
    if (response.success === false) {
    } else {
      setRowsActives(response);
    }

    setOpenLoading(false);
    return response;
  };

  const handleCreateRegisterIndicentDangerous = async (event) => {
    event.preventDefault();
    const response = await MyDangerousIncident.createRegistersIncidentDangerous({ code});
    setOpenModalNewRegister(false);

    if (response.success === false) { 
     
    } else {
      setOpenModalNewRegister(false);
      navigate('/documentation/registers/generals/incidentsdangerousincidents/edit?idRegistersDangerousIncidents='+ response.id);
    }

    return response;
  };
  React.useEffect(() => {
    hadleGetRegistersDangerousIncident();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>

      <ReturnBack/>
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <ButtonMailboxControla
            originUrl={"/documentation/registers/generals/incidentsdangerousincidents"}
            />

            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/bannerIncidentsDangerousIncidents.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Registro de incidentes peligrosos e incidentes"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                roles={permissions.DOC_REGIS_INCID_PELIGRO_CREAR_REGIS}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Crear nuevo registro"}               
                functionOnClick={handleOpenModalNewRegister}
                
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableControla
                 loading={openLoading}
                 tabs={[
                  {
                  columnNames: ["Código","Estado","Acciones"]
                  }
                ]}
                >
                  <ListActives
                   rowsActives={rowsActives}
                   colorcell={purple}
                  />

                </TableControla>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalNewRegister}
        onClose={()=>setOpenModalNewRegister(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar código del registro de incidentes peligrosos
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateRegisterIndicentDangerous}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <InputControlaVertical
              text={"Código"}
              inputType={"text"}
              inputValue={setCode}
              modalType={true}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={()=>setOpenModalNewRegister(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

    </Box>
  );
}

export default Index;
