import MyConfigurationApi from "./MyConfigurationApi";

function getDataStadisticsTrainings() {
    const url = "stadistics/trainigs";
    return MyConfigurationApi.connectApiExcel({
        dataJson: null,
        method: "GET",
        url: url,
    });

}

function getDataStadisticsInspections(){
    const url = "stadistics/inspections";
    return MyConfigurationApi.connectApiExcel({
        dataJson: null,
        method: "GET",
        url: url,
    });
} 

function getDataStadisticsRegisterStadistics ({idRegister, start_date, end_date, hoursWorked}){
    let data = {
        start_date, end_date, hoursWorked: JSON.parse(hoursWorked)
    }

    const url = "stadistics/register-stadistic";
    return MyConfigurationApi.connectApiExcel({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

const MyStatisticalReport = {
    getDataStadisticsTrainings,
    getDataStadisticsInspections,
    getDataStadisticsRegisterStadistics
}

export default MyStatisticalReport;