import { NavigateBefore, NavigateNext } from "@mui/icons-material"
import { Box, Button } from "@mui/material"

function PaginationTable( {handleGetLogs, currentPage , totalPage}){
    return (
        <Box display='flex' justifyContent='center' gap='10px' marginTop='10px' marginBottom='10px'>
            <Button
                variant="contained"
                startIcon={<NavigateBefore />}
                onClick={() => handleGetLogs(currentPage - 1)}
                disabled={currentPage <= 1 }
            >
                Previous
            </Button>
            <Button
                variant="contained"
                endIcon={<NavigateNext />}
                onClick={() => handleGetLogs(currentPage + 1)}
                disabled={ currentPage === totalPage || currentPage === 0 || totalPage <= 20}
            >
                Next
            </Button>
        </Box>
    );

}
export default PaginationTable;