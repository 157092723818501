import { React, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import InputControlaVertical from "../textfields/inputControlaVertical";
import ButtonControla from "../buttons/buttonController";

function SearchDateRangeControla({handleRangeDate}) {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    return (
        <Grid container spacing={2}
            justifyContent="center"
            direction="row"
            alignItems="flex-end"
        >
            <Grid item>
                <InputControlaVertical
                    text={"Desde"}
                    inputType={"date"}
                    inputValue={setStartDate}
                    modalType={false}
                    value={startDate || ''}
                    required
                />
            </Grid>
            <Grid item >
                <InputControlaVertical
                    text={"Hasta"}
                    inputType={"date"}
                    inputValue={setEndDate}
                    modalType={false}
                    value={endDate || ''}
                    required
                />

            </Grid>
            <Grid item  alignItems="center">
                <ButtonControla
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Buscar"}
                    typeButton={"submit"}
                    functionOnClick={()=>{ handleRangeDate(startDate,endDate) }}
                />
            </Grid>


        </Grid>
    );

}

export default SearchDateRangeControla;