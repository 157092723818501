import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import {useNavigate } from "react-router-dom";
import MyConfigurationApi from "../../api/MyConfigurationApi";

function CardControla({ text, img, url, subText, onClick , roles = [] }) {

  const userData = MyConfigurationApi.userData();
  const usersRoles = userData.roles;
  let showCard = false;

  roles = roles.length === 0 ? [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16] : roles;
  roles.map((rol) => {
    if(usersRoles.includes(rol)){
      showCard= true;
    }
  });


  return showCard && (  <Box underline="none" onClick = {onClick}>
  <Card sx={{ maxWidth: 345, height: "235px" }}
  
  >
    <CardActionArea sx={{ height: "235px" }}>
      <CardMedia
        component="img"
        sx={{ width: "100%", height: "140px", display: "block" }}
        image={img}
        alt="card"
      />
      <CardContent sx={{ height: "95px", display: "block" }}>
        <Typography
          sx={{
            backgroundColor: "#FCFCFC",
            color: "#395AC8",
            fontSize: 20,
            textAlign: "center",
          }}
          gutterBottom
          variant="h5"
          component="div"
        >
          {text}
        </Typography>
        <Typography
          sx={{
            backgroundColor: "#FCFCFC",
            color: "#838282",
            fontSize: 16,
            textAlign: "center",
          }}
          gutterBottom
          variant="h2"
          component="div"
        >
          {subText}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
</Box>)


  
}

export default CardControla;

