import {
    IconButton,
    Tooltip,
    Grid
  } from "@mui/material";
  import {
    ArrowBackIosNew,
  } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


function ReturnBack({ returnBack = "" }) {
    const navigate = useNavigate();
 
    const handleGoBack = () => {
        if (returnBack) {
            navigate(returnBack)
        } else {
            navigate(-1)
        }
    }

    return (
        <Grid container >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Tooltip title={"Retroceder"} placement="top">
                    <IconButton
                        size="small"
                        edge="start"
                        aria-label="open drawer"
                        sx={{
                            mr: 2, backgroundColor: "#021358 ", color: "#fff",
                            position: "fixed",
                            "&:hover": {
                                backgroundColor: "#99A0BC",
                                color: "#fff"
                            },
                            // Media Queries
                            '@media (min-width: 1280px) and (max-width: 1366px)': {
                                height: "40px",
                                width: "40px",
                                left: "80px",
                            },
                            '@media (min-width: 1367px) and (max-width: 1400px)': {
                                height: "43px",
                                width: "43px",
                                left: "90px",
                            },
                            '@media (min-width: 1401px) and (max-width: 1600px)': {
                                height: "45px",
                                width: "45px",
                                left: "130px",
                            },
                            '@media (min-width: 1601px)': {
                                height: "48px",
                                width: "48px",
                                left: "160px",
                            },
                        }}
                        onClick={handleGoBack}
                    >
                        <ArrowBackIosNew />
                    </IconButton>
                </Tooltip>

            </Grid>
        </Grid>

    )
}

export default ReturnBack