import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {
    InputBase,
  } from "@mui/material";
import { Search } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const SearchTextField = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "100%",
      },
    },
  }));
  

function SearchGeneralControla({ placeholder , value, handleActionOnChange = alert }) {
    return (
        <SearchTextField
            onChange={handleActionOnChange}
        >
            <SearchIconWrapper>
                <Search />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder={placeholder}
                inputProps={{ "aria-label": "search" }}
                value = {value}
            />
        </SearchTextField>

        // <TextField fullWidth
        //     id="input-with-icon-textfield"
        //     label={textLabel}
        //     margin="normal"
        //     InputProps={{
        //         startAdornment: (
        //             <InputAdornment position="start">
        //                 <Search />
        //             </InputAdornment>
        //         ),
        //     }}
        //     variant="standard"
        //     onChange={
        //         handleActionOnChange
        //     }
        // />

    );

}
export default SearchGeneralControla;