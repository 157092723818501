import { React, useEffect, useState } from "react";

import { Chart as ChartJS,defaults, BarElement, Tooltip, Legend, CategoryScale, LinearScale} from "chart.js/auto";





import {Bar} from 'react-chartjs-2';

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TitleText from "../../components/text/titleText";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import { useNavigate } from "react-router-dom";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Card,
    CardContent,
    CardMedia,
    Grid,
    InputLabel,
    Typography,
    Stack,
    MenuItem,
    Backdrop,
    Snackbar,
    Alert
} from "@mui/material";
import MyTracking from "../../api/MyTracking";
import Company from "../../company";
import ReturnBack from "../../components/buttons/returnBackControla";
// ChartJS.register(BarElement,Tooltip, Legend, CategoryScale, LinearScale);
defaults.plugins.title.display= true;

const blue = "#034AFF";
const purple = "#8B8BD8";

function Tracking (){
    const navigate = useNavigate();

    const [companies, setCompanies] = useState([]);
    const [logsCountCompanies, setLogsCountCompanies]= useState([]);
    const [allCompany, setAllCompany] = useState([]);

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    }

    const getLogsAllCompany = async()=>{
        setOpenBackdrop(true);
        const res = await MyTracking.getLogsAllCompany();
        setAllCompany(res);
        const company = [];
        const logs = [];
        
        res?.forEach((c)=>{
            company.push(c.companies_name);
            logs.push(c.tracking_total_access);
        });
        setCompanies(company);
        setLogsCountCompanies(logs);

        handleCloseBackdrop();

    }

    const state = {
        labels: companies,
        datasets: [
          {
            label: 'Empresa',
            backgroundColor: ['rgba(43,63,229,0.8)','rgba(250,192,19,0.8)','rgba(253,135,135,0.8)'],
            borderColor: 'rgba(0,0,0,1)',
            borderRadius:5,
            borderWidth: 1,
            data: logsCountCompanies
          }
        ]
      }

      useEffect(()=>{
        getLogsAllCompany();

      }, []);

    return (
        <Box >
            <ReturnBack/>

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <ButtonMailboxControla
                                originUrl={"/crm/tracking"}
                            />
                        </Grid>

                        <Grid item xs={7} md={7} sx={{ mt: 2 }}>
                            <TitleText text="Seguimiento del uso en 'Controla' " />
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper sx={{ overflow: "hidden" }}>
                                <TableContainer>
                                    <Table
                                        stickyHeader
                                        sx={{ width: "100%", minWidth: "700px" }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Empresa
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Membresía
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Fecha de inicio
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Fecha fin
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Último acceso
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Acciones
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                allCompany?.map((c) => (
                                                    <TableRow
                                                        hover
                                                        key={c.id_companies}
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                        }}
                                                    >
                                                        <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                                                            {c.companies_name}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                                                            {c.memberships_name}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                                                            {c.start_date}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                                                            {c.end_date}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                                                            {c.tracking_last_access === null ? "No accedió al sistema" : c.tracking_last_access.substr(0, 10) + " / " + c.tracking_last_access.substr(10, 6)}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignContent="center"
                                                            >
                                                                <ButtonIconControla
                                                                    icon={
                                                                        <VisibilityOutlined
                                                                            sx={{ color: "white" }}
                                                                        />
                                                                    }
                                                                    backgroundColor={"#305AD9"}
                                                                    backgroundColorHover={"#0E4AFF"}
                                                                    textTooltip={"Ver navegación"}
                                                                    isNavegationOrigin={true}
                                                                    directionUrl={"/crm/logsByIdCompany"}
                                                                    paramsIntUrl={{
                                                                        id_companie: c.id_companies,
                                                                        name_companie: c.companies_name
                                                                    }}

                                                                />

                                                            </Stack>

                                                        </TableCell>


                                                    </TableRow>
                                                ))
                                            }

                                        </TableBody>
                                    </Table>

                                </TableContainer>

                            </Paper>
                        </Grid>
                    </Grid>


                    <Grid container justifyContent="center" >
                        <Grid item xs={12} md={7} sx={{ backgroundColor: '#FDFEFE', marginTop: '40px', borderRadius: 2, padding: '15px' }}>
                            <Bar
                                data={state}
                                options={{
                                    plugins: {
                                        title: {
                                            text: "Cantidad actual de registros en el sistema de Controla por empresa"
                                        },
                                        legend: {
                                            position: 'right'
                                        }

                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                    <div>

                    </div>
                </Box>

            </Container>

            {/* mostrar loading  */}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 9999,
                }}
                open={openBackdrop}
            >
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>
        </Box>
    );

}

export default Tracking;