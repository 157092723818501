import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import {
    BorderColorOutlined,
    Delete
} from "@mui/icons-material";
import { useAppContext } from "../../../../../context/AppContext";

function ListModels(props) {
    const {permissions} = useAppContext();

    const {
        rowsModels,
        colorCell,
        documentType,
        handleOpenModalConfirm
    } = props

    return (
        <TableBody
            sx={{ display: documentType !== 0 ? "none" : "" }}
        >
            {rowsModels.map((rowsModels) => (
                <TableRow
                    hover
                    key={rowsModels.id_pets}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell
                        sx={{ color: colorCell }}
                        component="th"
                        scope="row"
                    >
                        {rowsModels.name}
                    </TableCell>
                    <TableCell>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {/* <ButtonIconControla
                                roles={permissions.DOC_HG_PROC_PETS_MOD_VER_MODELO}
                                icon={
                                  <VisibilityOutlined
                                    sx={{ color: "white" }}
                                  />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                functionOnClick={() => {
                                  window.location.href =
                                    "/documentation/documents/procedures/pets/model?idPets=" +
                                    rowsModels.id_pets;
                                }}
                              /> */}
                            <ButtonIconControla
                                roles={permissions.DOC_HG_PROC_PETS_MOD_VER_MODELO}
                                icon={
                                    <BorderColorOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}               
                                isNavegationOrigin={true}
                                directionUrl={"/documentation/documents/procedures/pets/model?idPets=" + rowsModels.id_pets}
                            />

                            <ButtonIconControla
                                roles={permissions.DOC_HG_PROC_PETS_MOD_ELIMINAR_MODELO}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                    handleOpenModalConfirm(
                                        rowsModels.id_pets,
                                        "¿Esta seguro de eliminar el modelo?",
                                        'Una vez eliminado no se podra recuperar todo lo incluido en este modelo PETS:  "' +
                                        rowsModels.name +
                                        '"',
                                        "Si, Eliminar",
                                        "delete"
                                    );
                                }}
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListModels;