import { Box, CircularProgress, Container, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import Pagination from "../../all/index/components/pagination"
import { VisibilityOutlined } from "@mui/icons-material"
import ButtonIconControla from "../../../components/buttons/buttonIconControla"
import { useAppContext } from "../../../context/AppContext"

export const TableTrainings = (props) => {
    const {permissions} = useAppContext();
    const { handleGetTrainings, rowTrainings, currentPage, loading } = props
    const blue = "#034AFF";

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                { loading && ( <CircularProgress />)}
            </Box>
            {
                !loading && (
                    rowTrainings.data.length > 0 ? (
                    <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper sx={{ overflow: "hidden" }}>
                        <TableContainer>
                            <Table
                                stickyHeader
                                sx={{ width: "100%", minWidth: "700px" }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                        <TableCell align="center" sx={{ color: blue }}>
                                            Tema
                                        </TableCell>
                                        <TableCell align="center" sx={{ color: blue }}>
                                            Nombre
                                        </TableCell>
                                        <TableCell align="center" sx={{ color: blue }}>
                                            Estado
                                        </TableCell>
                                        <TableCell align="center" sx={{ color: blue }}>
                                            Fecha de inicio
                                        </TableCell>
                                        <TableCell align="center" sx={{ color: blue }}>
                                            Fecha de fin
                                        </TableCell>
                                        <TableCell align="center" sx={{ color: blue }}>
                                            Acciones
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rowTrainings.data.map((row, key) => (
                                        <TableRow
                                            hover
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                            key={key}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {row.subject}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {row.name}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {row.status}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {(row.timestamp_start != null) ? row.timestamp_start.substr(0, 19) : ''}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {(row.timestamp_end != null) ? row.timestamp_end.substr(0, 19) : ''}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Stack
                                                    direction="row"
                                                    justifyContent="end"
                                                    alignItems="center"
                                                >
                                                    <ButtonIconControla
                                                        roles={permissions.CAPACIT_MIS_CAPACIT_INGRESAR}
                                                        icon={
                                                            <VisibilityOutlined
                                                                sx={{ color: "white" }}
                                                            />
                                                        }
                                                        backgroundColor={"#2D9CDB"}
                                                        backgroundColorHover={"#33AEF4"}
                                                        textTooltip={"Ingresar"}
                                                        isNavegationOrigin={true}
                                                        directionUrl={"/trainings/personal/edit?id_trainings=" + row.id_trainings}
                                                    />
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
                    ) : (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            No se encontro capacitaciones
                        </Box>	
                    )
                )
            }

            <Pagination
                handlePageChange={handleGetTrainings}
                currentPage={currentPage}
                lastPage={rowTrainings.last_page}
            />
        </Box>
  )
}