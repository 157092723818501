import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import { Archive, VisibilityOutlined } from "@mui/icons-material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import { useAppContext } from "../../../../../context/AppContext";

function ListArchived(props) {
  const { permissions } = useAppContext();

  const { rowsArchives, documentType, colorCell, handleOpenModalConfirm } =
    props;

  return (
    <TableBody>
      {rowsArchives.map((rowsArchives, index) => (
        <TableRow
          hover
          key={rowsArchives.id_petar}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell sx={{ color: colorCell }}>
            {index + 1}
          </TableCell>
          <TableCell sx={{ color: colorCell }}>
            {rowsArchives.name ?? ""}
          </TableCell>
          <TableCell sx={{ color: colorCell }}>
            {rowsArchives.area ?? ""}
          </TableCell>
          <TableCell sx={{ color: colorCell }}>
            {rowsArchives.document_url === null ? "SISTEMA" : "ARCHIVO"}
          </TableCell>
          <TableCell sx={{ color: colorCell }}>
            {rowsArchives.timestamp_modified ? new Date(rowsArchives.timestamp_modified).toLocaleString("es-PE", {
              timeZone: "America/Lima",
            }) : ""}
          </TableCell>
          <TableCell sx={{ color: colorCell }}>
            {rowsArchives.status}
          </TableCell>
          <TableCell align="right">
            <Stack direction="row" justifyContent="end" alignItems="center">
              {" "}
              {rowsArchives.document_url !== null ? (
                <ButtonIconControla
                  roles={permissions.DOC_HG_PROC_PETAR_ARCHI_VER}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver"}
                  directionUrl={rowsArchives.document_url}
                  directionTarget={"_blank"}
                />
              ) : (
                <ButtonIconControla
                  roles={permissions.DOC_HG_PROC_PETAR_ARCHI_VER}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver"}
                  isNavegationOrigin={true}
                  directionUrl={
                    "/editPetar?id_petar=" +
                    rowsArchives.id_petar +
                    "&readOnly=" +
                    true
                  }
                />
              )}
              <ButtonIconControla
                roles={permissions.DOC_HG_PROC_PETAR_ARCHI_DESARCHIVAR}
                icon={<Archive sx={{ color: "white" }} />}
                backgroundColor={"#f4711a"}
                textTooltip={"Desarchivar"}
                functionOnClick={() =>
                  handleOpenModalConfirm(
                    rowsArchives.id_petar,
                    "¿Estás seguro de desarchivar el documento?",
                    "",
                    "Si, desarchivar",
                    "unarchived"
                  )
                }
              />
            </Stack>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
export default ListArchived;
