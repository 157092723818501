import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Sheet from '@mui/joy/Sheet';
import * as XLSX from 'xlsx';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  PictureAsPdf,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import MyUsers from "../../../../api/MyUsers";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import LoadingControla from "../../../../components/load/loadingControla";
import MyStatisticalData from "../../../../api/MyRegisterStatisticalData";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import { useAppContext } from "../../../../context/AppContext";
import ButtonSignatureControla from "../../../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../../../components/buttons/returnBackControla";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MyStatisticalReport from "../../../../api/MyStatisticalReports";


const colorTitle = "#9191B0";


function Edit() {
  const{permissions} = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const idRegistersStatisticalData = params.get("idRegistersStatisticalData");
  const readOnly = params.get("readOnly") == "true" ? true: false;



  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const exportToExcel = () => {
    const formattedData = rowsStatisticalData.map(row => ({
      'Mes': row.mes,
      'N° Accidente Mortal': row.nro_accidente_mortal,
      'Área/Sede Mortal': row.area_sede_mortal,
      'Accid. de Trabajo Leve': '',  // Ajusta según tu necesidad
      'Área/Sede Leve': row.area_sede_leve,
      'N° Accid. Trab. Incap.': row.nro_accidente_incapacitante,
      'Área/Sede Incapacitante': row.area_sede_incapacitante,
      'Total Horas hombres trabajadas': row.total_horas_hombres_trabajadas,
      'Índice de frecuencia': row.indice_frecuencia,
      'N° días perdidos': row.dias_perdidos,
      'Índice de gravedad': row.indice_gravedad,
      'Índice de accidentabilidad': row.indice_accidentabilidad,
      'N° Enf. Ocup.': row.nro_enfermedad_ocupacional,
      'Área/Sede Enf. Ocup.': row.area_sede_enfermedad_ocupacional,
      'N° Trabajadores expuestos': row.nro_trabajadores_expuestos,
      'Tasa de Incidencia': row.tasa_incidencia,
      'N° Trabajadores con Cáncer Profesional': row.nro_trabajadores_cancer,
      'N° Incidentes Peligrosos': row.nro_incidentes_peligrosos,
      'Área/Sede Incidente Peligroso': row.area_sede_incidente_peligroso,
      'N° Incidentes': row.nro_incidentes,
      'Área/Sede Incidente': row.area_sede_incidente,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Estadísticas');
    XLSX.writeFile(wb, 'reporte_estadisticas.xlsx');
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdResponsibleWorker(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "deleteResponsible":
        handleDeleteResponsibleWorker();
        break;
    }
    setOpenModalConfirm(false);
  };
  // Dialogos de confirmacion FIN

  
  // update register
  const handleUpdateRegistersStatisticalData = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const convertHoursWorked = JSON.stringify(hoursWorked);
  
    const response = await MyStatisticalData.updateRegistersStatisticalData(
      {
        idRegistersStatisticalData,
        code,
        startDate,
        endDate,
        hoursWorked: convertHoursWorked
      }
    );
    handleOpenAlert("Se actualizaron los datos con éxito", "success");

    return response;
  };

  // edit register
  const handleEditRegistersStatisticalData = async () => {
    const response = await MyStatisticalData.editRegistersStatisticalData({
      idRegistersStatisticalData,
    });

    setRegisterStatus(response.register_status === 1 ? true : false);
    setCode(response.code);
    setStartDate(response.startDate);
    setEndDate(response.endDate);
    setHoursWorked(response.hoursWorked);

    return response;
  };

  const [listUsers, setListUsers] = useState([]);

  const handleGetUsers = async() => {
    const response = await MyUsers.users();
    setListUsers(response.users_actives);
  }

  // USUARIOS ACTIVOS
  const [rowsUsersSearchs, setRowsUsersSearchs] = useState([]);
  const handleGetUsersSearchsAddsWorkers = async () => {
    let users = listUsers;

    //traer lista de responsables del registro
    const resp = await MyStatisticalData.getResponsibleWorkers({
      idRegistersStatisticalData: idRegistersStatisticalData,
    }) ?? [];

    // filtrar usuarios sin que se repita
    resp.forEach(user => {
      users = users.filter( u => u.id_users !== user.id_users);
    });

    setRowsUsersSearchs(users);
  };

  //*************************  RESPONSABLES  *******************************************
  const [openModalResponsibleWorker, setOpenModalResponsibleWorker] = useState(false);

  const handleOpenModalResponsibleWorker = async() => {
    handleOpenLoading();
    await  handleGetUsersSearchsAddsWorkers();
    setOpenModalResponsibleWorker(true);
    setOpenLoading(false);
  };

  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response = await MyStatisticalData.getResponsibleWorkers({
      idRegistersStatisticalData: idRegistersStatisticalData,
    });
    if (response.success === false) {
    } else {
      setResponsibleActions(response);
    }
  };

  //crear trabajadores responsables
  const handleStoreResponsibleWorkers = async (idUsers) => {
    handleOpenLoading();
    const response = await MyStatisticalData.createResponsibleWorkers({
      idRegistersStatisticalData: idRegistersStatisticalData,
      id_users: idUsers
    });

    if (response.success === false) {
    } else {
      handleGetResponsibleWorkers();
      setOpenModalResponsibleWorker(false);
      handleOpenAlert("Se agregó de forma correcta los datos", "success");
    }
    return response;
  };

  // eliminar trabajador responsable
  const [idResponsibleWorker, setIdResponsibleWorker] = useState(0);
  const handleDeleteResponsibleWorker = async () => {
    const response = await MyStatisticalData.deleteResponsibleWorker({
      idResponsible: idResponsibleWorker,
    });
    handleGetResponsibleWorkers();
    return response;
  };

  const handleSignResponsibleWorker = async (idResponsibleSign, idResponsibleSignUsers) => {
    handleOpenLoading();
    try{
      const response = await MyStatisticalData.updateResponsibleWorkers({
        idResponsible: idResponsibleSign,
        id_users: idResponsibleSignUsers
      });
    
      await handleGetResponsibleWorkers();
      handleOpenAlert("Se registró la firma con éxito", "success");
    }catch(e){
      handleOpenAlert("Ocurrió un error al firmar", "error");
    }
  };

  // // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(1);
  const handleGetRegisterStatus = async () => {

    if (startDate == "" || endDate == "") {
      handleOpenAlert("Completar el rango de fechas de consulta(mes inicial y final)", "error")
    } else if (responsibleWorkers.length == 0) {
      handleOpenAlert("Completar secciones obligatorias(responsable del registro)", "error");
    } else if (responsibleWorkers.some((user) => user.firm == 0)) {
      handleOpenAlert("Quedan firmas pendientes de responsables", "error");
    } else {

      handleOpenLoading();
      const response = await MyStatisticalData.updateRegistersStatusClose({
        idRegistersStatisticalData: idRegistersStatisticalData,
      });
      handleOpenAlert("El registro se cerró de forma correcta", "success");
      await handleEditRegistersStatisticalData();
      return response;
    }

  };

  //traer datos de registros estadisticos 12 meses
  // REGISTRO DE datos estadisticos
  const [code, setCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate ]= useState("");

//12 meses de inputs
  const [janHoursWorked, setJanHoursWorked] = useState(null);
  const [febHoursWorked, setFebHoursWorked] = useState(null);
  const [marHoursWorked, setMarHoursWorked] = useState(null);
  const [aprHoursWorked, setAprHoursWorked] = useState(null);
  const [mayHoursWorked, setMayHoursWorked] = useState(null);
  const [junHoursWorked, setJunHoursWorked] = useState(null);
  const [julHoursWorked, setJulHoursWorked] = useState(null);
  const [augHoursWorked, setAugHoursWorked] = useState(null);
  const [sepHoursWorked, setSepHoursWorked] = useState(null);
  const [octHoursWorked, setOctHoursWorked] = useState(null);
  const [novHoursWorked, setNovHoursWorked] = useState(null);
  const [decHoursWorked, setDecHoursWorked] = useState(null);


  const [rowsStatisticalData, setRowsStatisticalData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [hoursWorked, setHoursWorked] = useState({});

  const handleGetDataForStatisticsRecords = async () => {
    handleOpenLoading();

    if (code !== "") {
      if (startDate == "" && endDate == "") {
        const hoursWorkedFirst = {
          "01": 0,
          "02": 0,
          "03": 0,
          "04": 0,
          "05": 0,
          "06": 0,
          "07": 0,
          "08": 0,
          "09": 0,
          "10": 0,
          "11": 0,
          "12": 0
        }
        try {
          const resp = await MyStatisticalData.getDataForStatisticsRecords({ horas_trabajadas: hoursWorkedFirst });
          setRowsStatisticalData(resp);
          handleOpenAlert("", "success");
        } catch (e) {
          handleOpenAlert("Ocurrió un error al generar datos estadísticos");
        }


      } else {
        const hoursWorkedSave = JSON.parse(hoursWorked)

        try {
          const resp = await MyStatisticalData.getDataForStatisticsRecords({ horas_trabajadas: hoursWorkedSave, fecha_inicio: startDate, fecha_fin: endDate });
          setRowsStatisticalData(resp);
          handleOpenAlert("Se cargó datos estadísticos del rango de fecha indicada con éxito", "success");
        } catch (e) {
          handleOpenAlert("Ocurrió un error al generar datos estadísticos");
        }
      }

    }
  }

  const handlePostDataForStatisticsRecords = async (e) => {
    e.preventDefault();

    const hoursWorkedPost = {};

    for (let i = 1; i <= dateRange.length; i++) {
      let key = i < 10 ? `0${i}` : `${i}`;
      hoursWorkedPost[key] = dateRange[i - 1].value;
    }

    setHoursWorked(hoursWorkedPost);

    handleOpenLoading();

    try {
      const resp = await MyStatisticalData.getDataForStatisticsRecords({ horas_trabajadas: hoursWorkedPost, fecha_inicio: startDate, fecha_fin: endDate });
      setRowsStatisticalData(resp);
      handleOpenAlert("Datos estadísticos generados con éxito");

    } catch (e) {
      handleOpenAlert("Problemas al generar datos estadísticos", "error");
    }

    //limpiar
      setJanHoursWorked(null);
      setFebHoursWorked(null);
      setMarHoursWorked(null);
      setAprHoursWorked(null);
      setMayHoursWorked(null);
      setJunHoursWorked(null);
      setJulHoursWorked(null);
      setAugHoursWorked(null);
      setSepHoursWorked(null);
      setOctHoursWorked(null);
      setNovHoursWorked(null);
      setDecHoursWorked(null);

    setOpenLoading(false);
    handleCloseModalGenerateStaticalData();
  }

  const [openModalGenerateStaticalData, setOpenModalGenerateStaticalData] = useState(false);

  const handleOpenModalGenerateStaticalData = () => {
    handleShowInputsByNumberMonths();

    if (startDate !== "" && endDate !== "" && (endDate > startDate)) {
      setOpenModalGenerateStaticalData(true)
    } else if(endDate < startDate){
      handleOpenAlert("Fecha fin debe ser mayor a fecha inicial", "error");
    }
    else {
      handleOpenAlert("Ingrese el rango de fechas para generar datos estadísticos", "error");
    }

  }

  const handleCloseModalGenerateStaticalData = () => {
    setOpenModalGenerateStaticalData(false)
  }

  const months = [
    {
      id:1,
      inputValue:setJanHoursWorked,
      value: janHoursWorked,
      mes: "Enero",
    },
    {
      id:2,
      inputValue: setFebHoursWorked,
      value: febHoursWorked,
      mes: "Febrero",
    },
    {
      id:3,
      inputValue: setMarHoursWorked,
      value: marHoursWorked,
      mes: "Marzo",
    },
    {
      id:4,
      inputValue: setAprHoursWorked,
      value: aprHoursWorked,
      mes: "Abril",
    },
    {
      id:5,
      inputValue: setMayHoursWorked,
      value: mayHoursWorked,
      mes: "Mayo",
    },
    {
      id:6,
      inputValue: setJunHoursWorked,
      value: junHoursWorked,
      mes: "Junio",
    },
    {
      id:7,
      inputValue: setJulHoursWorked,
      value: julHoursWorked,
      mes: "Julio",
    },
    {
      id:8,
      inputValue: setAugHoursWorked,
      value: augHoursWorked,
      mes: "Agosto",
    },
    {
      id:9,
      inputValue: setSepHoursWorked,
      value: sepHoursWorked,
      mes: "Setiembre",
    },
    {
      id:10,
      inputValue: setOctHoursWorked,
      value: octHoursWorked,
      mes: "Octubre",
    },
    {
      id:11,
      inputValue: setNovHoursWorked,
      value: novHoursWorked,
      mes: "Noviembre",
    },
    {
      id:12,
      inputValue: setDecHoursWorked,
      value: decHoursWorked,
      mes: "Diciembre",
    }]

  const handleShowInputsByNumberMonths = () => {

    const yearStart = startDate.substring(0, 4);
    const yearEnd = endDate.substring(0, 4);
    const initialMonth = startDate.substring(5, 7);
    const finalMonth = endDate.substring(5, 7);

    //para un mismo año 
    if (yearStart == yearEnd) {

      const monthsFilter = months.filter((month) => month.id >= initialMonth && month.id <= finalMonth);
      setDateRange(monthsFilter);

    } else {
      const monthsFilter1 = months.filter((month) => month.id >= initialMonth && month.id <= 12)
      const monthsFilter2 = months.filter((month) => month.id >= 1 && month.id <= finalMonth)

      setDateRange(monthsFilter1.concat(monthsFilter2));
    }

  }

  //generar descarga de excel
  const handleGetDowloandReportRegisterStadistics = async ()=> {
    try {
      const res = await MyStatisticalReport.getDataStadisticsRegisterStadistics({
        idRegister: idRegistersStatisticalData,
        start_date: startDate,
        end_date: endDate,
        hoursWorked: hoursWorked
      });
      const blob = await res.blob(); // Convertir la respuesta en un blob
      
      // Crear un enlace temporal para descargar el archivo
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a'); // Aquí debe ser 'a' en lugar de 'r'
      link.href = url;
      link.setAttribute('download', `reporte-datos-estadísticos.xlsx`); // Establece el nombre del archivo
      document.body.appendChild(link);
      link.click();
      link.remove(); // Elimina el enlace después de la descarga
    } catch (error) {
      console.error('Hubo un error con la generacion del EXCEL', error);
    }

    setOpenLoading(false);
  }


  useEffect(() => {
    handleEditRegistersStatisticalData();

    // RESPONSABLES
    handleGetResponsibleWorkers();

    handleGetUsers();
  }, []);

  useEffect(()=>{
    handleGetDataForStatisticsRecords();
  },[code])

  useEffect(()=>{
    handleShowInputsByNumberMonths();
  },[janHoursWorked,
    febHoursWorked,
    marHoursWorked,
    aprHoursWorked,
    mayHoursWorked,
    junHoursWorked,
    julHoursWorked,
    augHoursWorked,
    sepHoursWorked,
    octHoursWorked,
    novHoursWorked,
    decHoursWorked
  ])

  return (
    <Box sx={{height: "100%" }}>
      <ReturnBack/>

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={
                  "Registros de estadísticas de seguridad y salud en el trabajo"
                }
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUpdateRegistersStatisticalData}>
            {/* tabla para mostrar accidents workers */}
            <Grid container spacing={2} sx={{ marginTop: "4px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_DATA_STATISTICAL_RECORDS_EDIT}
                  text={"Código:"}
                  inputType={"text"}
                  inputValue={setCode}
                  value={code}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_DATA_STATISTICAL_RECORDS_EDIT}
                  text={"Mes inicial:"}
                  inputType={"date"}
                  inputValue={setStartDate}
                  value={startDate}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_DATA_STATISTICAL_RECORDS_EDIT}
                  text={"Mes final:"}
                  inputType={"date"}
                  inputValue={setEndDate}
                  value={endDate}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} sx={{ marginTop: '40px' }}>
                {
                  registerStatus && (
                    <ButtonControla
                      iconButton={<Add sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Generar datos estadísticos"}
                      functionOnClick={handleOpenModalGenerateStaticalData}
                    />
                  )}
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Sheet  sx={{ height: 700, overflow: 'auto' }}>
                    <Table
                      stickyHeader
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow sx={{ border: 1 }}>
                          <TableCell rowSpan={3} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#40679E" }}>Mes</TableCell>
                          <TableCell rowSpan={3} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#40679E" }}>N° Accidente Mortal</TableCell>
                          <TableCell rowSpan={3} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#40679E" }} width="100px">Área/Sede</TableCell>
                          <TableCell rowSpan={3} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#40679E" }}>Accid. de Trabajo Leve</TableCell>
                          <TableCell rowSpan={3} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#40679E" }}>Área/Sede</TableCell>
                          <TableCell colSpan={7} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#1B3C73" }}>Solo Para Accidentes Incapacitantes</TableCell>
                          <TableCell colSpan={5} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#526D82" }}>Enfermedad Ocupacional</TableCell>
                          <TableCell rowSpan={3} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#3E497A" }}>N° Incidentes Peligrosos</TableCell>
                          <TableCell rowSpan={3} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#3E497A" }}>Área/Sede</TableCell>
                          <TableCell rowSpan={3} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#3E497A" }}>N° Incidentes</TableCell>
                          <TableCell rowSpan={3} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#3E497A" }}>Área/Sede</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#365486", top: '58px'  }}>
                            N° Accid. Trab. Incap.<br></br>
                            <Typography variant="caption" color="#EAEAEA">
                              Incluye accidentes mortales
                            </Typography>
                          </TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#365486", top: '58px' }}>
                            Área/Sede<br></br>
                          </TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#365486", top: '58px' }}>
                            Total Horas hombres trabajadas<br></br>
                            <Typography variant="caption" color="#EAEAEA">
                              Horas trabajadas en el mes (incluye accidentes mortales)
                            </Typography>
                          </TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#365486", top: '58px' }}>
                            Índice de frecuencia<br></br>
                            <Typography variant="caption" color="#EAEAEA">
                              N° acc. Incap x 1 millón /THHT
                            </Typography>
                          </TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#365486", top: '58px' }}>
                            N° días perdidos<br></br>
                            <Typography variant="caption" color="#EAEAEA">
                              N° días descanso médico
                            </Typography>
                          </TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#365486", top: '58px' }}>
                            Índice de gravedad<br></br>
                            <Typography variant="caption" color="#EAEAEA">
                              N° total DP x 1 millón /THHT
                            </Typography>
                          </TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#365486", top: '58px' }}>
                            Índice de accidentabilidad<br></br>
                            <Typography variant="caption" color="#EAEAEA">
                              IF x IG /1000
                            </Typography>
                          </TableCell>

                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#74959A", top: '58px' }}>
                            N° Enf. Ocup.<br></br>
                          </TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#74959A", top: '58px' }}>
                            Área/Sede<br></br>
                          </TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#74959A", top: '58px' }}>
                            N° Trabajadores expuestos al agente<br></br>
                          </TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#74959A", top: '58px' }}>
                            Tasa de Incidencia<br></br>
                          </TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white", fontWeight: 'bold', background: "#74959A", top: '58px' }}>
                            N° Trabaj. Con Cáncer Profesional<br></br>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          rowsStatisticalData.map((row, i) => (
                            <TableRow
                              hover
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <TableCell align="center" component="th" scope="row" sx={{ color: "blue" }}>{row.mes}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_accidente_mortal}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.area_sede_mortal}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_accidente_leve }</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.area_sede_leve }</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_accidente_incapacitante}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.area_sede_incapacitante}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.total_horas_hombres_trabajadas}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.indice_frecuencia}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.dias_perdidos}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.indice_gravedad}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.indice_accidentabilidad}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_enfermedad_ocupacional}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.area_sede_enfermedad_ocupacional}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_trabajadores_expuestos}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.tasa_incidencia}</TableCell>  
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_trabajadores_cancer}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_incidentes_peligrosos}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.area_sede_incidente_peligroso}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_incidentes}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.area_sede_incidente}</TableCell>
                            </TableRow>

                          ))
                        }
                      </TableBody>
                    </Table>
                  </Sheet>
              </Grid>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro y de la investigación
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus && (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={handleOpenModalResponsibleWorker}
                  />
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Cargo
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.position_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.firm_date === null
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <ButtonSignatureControla
                              idUser={row.id_users}
                              firmStatus={row.firm}
                              firmUrl={row.firm_url}
                              functionOnClick={async() => {
                                await handleSignResponsibleWorker(row.id, row.id_users)
                              }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador responsable " +
                                    row.first_name +
                                    " " +
                                    row.last_name,
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              {/* cerrado temporalmente */}
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                />
              </Grid> */}
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={"/documentation/registers/generals/statisticaldata/dataStatisticalRecords"}
                />
              </Grid>
              {registerStatus && (
                <>
                  <Grid item xs={12} md={2}>
                    <ButtonControla
                      iconButton={<EditOutlined sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Actualizar registro"}
                      typeButton="submit"
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <ButtonControla
                      iconButton={<CloseIcon fontSize="inherit" />}
                      backgroundColor={"black"}
                      backgroundColorHover={"#2C2B29"}
                      textButton={"Cerrar registro"}
                      functionOnClick={handleGetRegisterStatus}
                    />
                  </Grid>
                </>
              )}
              {
                !registerStatus && (
                  <>
                    <Grid item xs={12} md={3}>
                      <ButtonControla
                        iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                        backgroundColor={"#F2994A"}
                        backgroundColorHover={"#FF881E"}
                        textButton={"Ver documento PDF"}
                        typeButton={"button"}
                        // url={process.env.REACT_APP_API_DJANGO + 'report/' + idRegistersStatisticalData}
                        target={"_blank"}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <ButtonControla
                        iconButton={<CloudDownloadIcon sx={{ color: "white" }} />}
                        backgroundColor={"#169073"}
                        backgroundColorHover={"#1AA483"}
                        textButton={"Descargar reporte"}
                        typeButton={"button"}
                        functionOnClick={() => exportToExcel()}
                      />
                    </Grid>
                  </>
                )
              }
            </Grid>
          </form>
        </Box>
      </Container>


      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalResponsibleWorker}
        onClose={() => setOpenModalResponsibleWorker(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsUsersSearchs}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => setOpenModalResponsibleWorker(false)}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialogo de generar datos estadisticos */}
      <Dialog
        open={openModalGenerateStaticalData}
        onClose={handleCloseModalGenerateStaticalData}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText={"Agregar total de horas hombre trabajadas para los meses seleccionados"}
          functionOnClose={handleCloseModalGenerateStaticalData}
        />

        <form onSubmit={handlePostDataForStatisticsRecords}>
          <DialogContent>
            <Grid container spacing={2} sx={{ marginTop: "4px" }}>
              {
                dateRange.map((month) => (
                  <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                    <InputControlaVertical
                      text={month.mes}
                      inputType={"number"}
                      inputValue= {(event) => month.inputValue(event)}
                      value={month.value} 
                      modalType={true}
                    />
                  </Grid>
                ))
              }

            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <ButtonControla
                textButton={"Cancelar"}
                backgroundColor={"#CBCBFF"}
                functionOnClick={handleCloseModalGenerateStaticalData}
              />
              <ButtonControla
                textButton={"Generar"}
                backgroundColor={"#389532"}
                typeButton={"submit"}
              />
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Edit;
