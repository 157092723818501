import { React, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Card, CardContent, CardHeader, CardMedia, Dialog, DialogActions, DialogContent,Grid, LinearProgress, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { Add, BorderColorOutlined, Delete, Description, Edit, ExpandMore, Group, Public, Save, Send, ThumbDown, ThumbUp, Visibility, VisibilityOutlined } from '@mui/icons-material';
import TitleText from '../../components/text/titleText';
import BannerControla from '../../components/cards/bannerControla';
import ButtonControla from '../../components/buttons/buttonController';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import SelectControla from "../../components/selects/selectControla";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import SearchButtonControla from "../../components/search/searchButtonControla";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import SearchUsersControla from "../../components/search/searchUsersControla";
import MyUsers from "../../api/MyUsers";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import MyCommitteeElectionProcess from "../../api/MyCommitteeElectionProcess";
import UsersPicturesControla from "../../components/images/usersPicturesControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";
import ReturnBack from "../../components/buttons/returnBackControla";



const blue = "#034AFF";

const StyledEnterButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: "#2D9CDB",
    color: "white",
    width: "70%"
});
const colorTitle = "#9191B0";


function CreateProcess() {
    const params = new URLSearchParams(window.location.search);
    const idProcess = params.get("idProcess");


    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    // Dialogos de confirmacion INICIO

    const [idAction, setIdAction] = useState('');
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");

    const handleOpenModalConfirm = (
        id,
        titleText,
        contentText,
        buttonTextConfirm,
        buttonActionType
    ) => {
        setIdAction(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    };

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    };

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case "updateElectoralCommissionStatus":
                handleUpdateElectoralCommissionStatus();
                break;
            case "deleteElectoralCommission":
                handleDeleteElectoralCommission();
                break;
            case "cancelProcess":
                handleUpdateProcessStatus(-1);
                break;
            case "proposeProcess":
                handleUpdateProcessStatus(2);
                break;
            case "configurationProcess":
                handleUpdateProcessStatus(1);
                break;
            case "oficialDataProcess":
                handleUpdateProcessStatus(3);
                break;
            case "acceptCandidate":
                handleUpdateCandidateStatus(2);
                break;
            case "rejectCandidate":
                handleUpdateCandidateStatus(3);
                break;

            default:
                alert('Falto yo ' + buttonActionType);
        }
        setOpenModalConfirm(false);
    };


    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [idType, setIdType] = useState("");
    const [rowsTypes, setRowsTypes] = useState([]);
    const handleGetType = async () => {
        const response = await MyCommitteeElectionProcess.getType();
        if (response.length === 0) {
            handleOpenAlert('Ocurrio un error los tipos', 'error');
        } else {
            setRowsTypes(response);
        }
    };

    const [idMode, setIdMode] = useState("");
    const [rowsModes, setRowsModes] = useState([]);
    const handleGetMode = async () => {
        const response = await MyCommitteeElectionProcess.getMode();
        if (response.length === 0) {
            handleOpenAlert('Ocurrio un error los modos', 'error');
        } else {
            setRowsModes(response);
        }
    };

    const [idElectionType, setIdElectionType] = useState("");
    const [rowsElectionTypes, setRowsElectionTypes] = useState([]);
    const handleGetElectionType = async () => {
        const response = await MyCommitteeElectionProcess.getElectionType();
        if (response.length === 0) {
            handleOpenAlert('Ocurrio un error los tipos de eleccion', 'error');
        } else {
            setRowsElectionTypes(response);
        }
    };

    const [totalMembers, setTotalMembers] = useState("");
    const [rowsTotalMembers, setRowsTotalMembers] = useState([]);
    const handleGetTotalMembers = async () => {
        const jsonTotalMembers = [
            { 'key': 4, 'value': 4 },
            { 'key': 6, 'value': 6 },
            { 'key': 8, 'value': 8 },
            { 'key': 10, 'value': 10 },
            { 'key': 12, 'value': 12 }
        ];
        setRowsTotalMembers(jsonTotalMembers);
    };
    const [totalMembersTitularWorkers, setTotalMembersTitularWorkers] = useState(0);
    const [totalMembersTitularCompanies, setTotalMembersTitularCompanies] = useState(0);

    const handleSetRowsTotalMembers = async (total) => {
        setTotalMembers(total);
        setTotalMembersTitularWorkers(total / 2);
        setTotalMembersTitularCompanies(total / 2)
    };

    const [totalOmbudsmanCandidate, setTotalOmbudsmanCandidate] = useState("");

    const [totalMembersAlternateWorkers, setTotalMembersAlternateWorkers] = useState("");
    const [rowsTotalMembersAlternateWorkers, setRowsTotalMembersAlternateWorkers] = useState([]);
    const handleGetTotalMembersAlternateWorkers = async () => {
        const jsonTotalMembers = [
            { 'key': 1, 'value': 1 },
            { 'key': 2, 'value': 2 },
            { 'key': 3, 'value': 3 },
            { 'key': 4, 'value': 4 },
            { 'key': 5, 'value': 5 },
            { 'key': 6, 'value': 6 }
        ];
        setRowsTotalMembersAlternateWorkers(jsonTotalMembers);
    };

    const [totalMembersAlternateCompanies, setTotalMembersAlternateCompanies] = useState("");
    const [rowsTotalMembersAlternateCompanies, setRowsTotalMembersAlternateCompanies] = useState([]);
    const handleGetTotalMembersAlternateCompanies = async () => {
        const jsonTotalMembers = [
            { 'key': 1, 'value': 1 },
            { 'key': 2, 'value': 2 },
            { 'key': 3, 'value': 3 },
            { 'key': 4, 'value': 4 },
            { 'key': 5, 'value': 5 },
            { 'key': 6, 'value': 6 }
        ];
        setRowsTotalMembersAlternateCompanies(jsonTotalMembers);
    };


    const [openModalSelectUser, setOpenModalSelectUser] = useState(false);
    const [originSelectUser, setOriginSelectUser] = useState('observed');
    const [idUserObserved, setIdUserObserved] = useState("");
    const [dataUserObserved, setDataUserObserved] = useState('');

    const handleOpenModalSelectUser = (origin) => {
        setOriginSelectUser(origin);
        setOpenModalSelectUser(true);
    };
    const handleCloseModalSelectUser = () => setOpenModalSelectUser(false);

    const [rowsUserObserved, setRowsUserObserved] = useState([]);
    const handleGetUsers = async () => {
        const response = await MyUsers.users();
        setRowsUserObserved(response.users_actives);
    };

    const [idStatuses, setIdStatuses] = useState('');
    const [statusPercentaje, setStatusPercentaje] = useState(0);
    const [mandateTerm, setMandateTerm] = useState({});
    const handleGetProcessSettings = async () => {
        const response = await MyCommitteeElectionProcess.getProcessSettings(idProcess);
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error', 'error');
        } else {
            handleGetTimelineStatuses(idProcess, response.id_committee_process_statuses);
            setStartDate(response.date_start || '');
            setEndDate(response.date_end || '');
            setIdElectionType(response.id_committee_process_election_type || '');
            setIdType(response.id_committee_process_types || '');
            setIdMode(response.id_committee_process_mode || '');
            setIdUserObserved(response.id_users_observed || '');
            if (response.id_users_observed) {
                handleSelectUser(response.id_users_observed);
            }
            setTotalMembers(response.total_members || '');
            setTotalMembersTitularCompanies(response.total_members_titular_companies || '');
            setTotalMembersAlternateCompanies(response.total_members_alternate_companies || '');
            setTotalMembersTitularWorkers(response.total_members_titular_workers || '');
            setTotalMembersAlternateWorkers(response.total_members_alternate_workers || '');
            setTotalOmbudsmanCandidate(response.total_ombudsman_candidate || '');
            setIdStatuses(response.id_committee_process_statuses || '');
            if (response.id_committee_process_statuses) {
                setStatusPercentaje(response.id_committee_process_statuses * 10);
            }

            const start = new Date(response.date_start);
            const end = new Date(response.date_end);

            const yearDiff = end.getFullYear() - start.getFullYear();
            const monthDiff = end.getMonth() - start.getMonth();
            const dayDiff = end.getDate() - start.getDate();

            if (dayDiff < 0) {
                const lastDayOfMonth = new Date(end.getFullYear(), end.getMonth(), 0).getDate();
                dayDiff = lastDayOfMonth + dayDiff;
                monthDiff--;
            }

            if (monthDiff < 0) {
                monthDiff += 12;
                yearDiff--;
            }

            setMandateTerm({ years: yearDiff, months: monthDiff, days: dayDiff });

        }
    };

    const [rowsElectoralComissionPosition, setRowsElectoralComissionPosition] = useState([]);
    const handleGetElectoralComissionPosition = async () => {
        const response = await MyCommitteeElectionProcess.getElectoralComissionPosition();
        if (response.length === 0) {
            handleOpenAlert('Ocurrio un error con los cargos de la junta electoral', 'error');
        } else {
            setRowsElectoralComissionPosition(response);
        }
    };

    const [rowsElectoralComissionStatus, setRowsElectoralComissionStatus] = useState([]);
    const handleGetElectoralComissionStatus = async () => {
        const response = await MyCommitteeElectionProcess.getElectoralComissionStatus();
        if (response.length === 0) {
            handleOpenAlert('Ocurrio un error con los estados de la junta electoral', 'error');
        } else {
            setRowsElectoralComissionStatus(response);
        }
    };

    const [rowsElectoralComission, setRowsElectoralComission] = useState([]);
    const handleGetElectoralComission = async () => {
        const response = await MyCommitteeElectionProcess.getElectoralComission({ id_committee_process: idProcess });
        if (response.length === 0) {
            // handleOpenAlert('Ocurrio un error los tipos de eleccion', 'error');
        } else {
            setRowsElectoralComission(response);
        }
    };

    const [rowsTimeline, setRowsTimeline] = useState([]);
    const handleGetTimeline = async () => {
        const response = await MyCommitteeElectionProcess.getTimeline({ id_committee_process: idProcess });
        if (response.length === 0) {
            handleOpenAlert('Ocurrio un error al traer el cronograma', 'error');
        } else {
            setRowsTimeline(response);
        }
    };

    const companyName = MyConfigurationApi.companyData().name;

    // const [idTimelineStatuses, setIdTimelineStatuses] = useState("");
    // const [rowsTimelineStatuses, setRowsTimelineStatuses] = useState([]);
    // const handleGetProcessStatuses = async () => {
    //     const response = await MyCommitteeElectionProcess.getStatus();
    //     if (response.length === 0) {
    //         handleOpenAlert('Ocurrio un error con los estados', 'error');
    //     } else {
    //         setRowsTimelineStatuses(response);
    //     }
    // };

    const [statusStartDate, setStatusStartDate] = useState("");
    const [statusEndtDate, setStatusEndtDate] = useState("");
    const [statusExpirationtDate, setStatusExpirationtDate] = useState("");
    const [statusName, setStatusName] = useState("");
    const [idTimeline, setIdTimeline] = useState("");
    const handleGetTimelineStatuses = async (idProcess, idProcessStatus) => {
        const response = await MyCommitteeElectionProcess.getTimelineStatuses({ id_committee_process: idProcess, id_committee_process_statuses: idProcessStatus });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error', 'error');
        } else {
            handleGetProcessSettingsObservations(response.id_committee_process_timeline, idProcessStatus);
            setIdTimeline(response.id_committee_process_timeline);
            setStatusStartDate(response.timestamp_start);
            setStatusEndtDate(response.timestamp_end);
            setStatusExpirationtDate(response.timestamp_expiration);
            setStatusName(response.committee_process_status);
        }
    };

    const [rowsSettingsObservations, setRowsSettingsObservations] = useState([]);
    const handleGetProcessSettingsObservations = async (idTimeline, idProcessStatus) => {
        const response = await MyCommitteeElectionProcess.getProcessSettingsObservations({ id_committee_process: idProcess, id_committee_process_timeline: (idProcessStatus == 2 ? idTimeline : null) });

        if (response.length === 0) {
            // handleOpenAlert('Ocurrio un error con las observaciones', 'error');
        } else {
            setRowsSettingsObservations(response);
        }
    };

    const [rowsCandidates, setRowsCandidates] = useState([]);
    const handleGetCandidates = async () => {
        const response = await MyCommitteeElectionProcess.getProcessCandidates({ id_committee_process: idProcess });

        if (response.length === 0) {
            // handleOpenAlert('Ocurrio un error con las observaciones', 'error');
        } else {
            setRowsCandidates(response);
        }
    };

    const [accordionExpanded, setAccordionExpanded] = useState('panel1');

    const handleChangeAccordionExpanded = (panel) => (event, newExpanded) => {
        setAccordionExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        handleGetType();
        handleGetMode();
        handleGetElectionType();
        handleGetTotalMembers();
        handleGetTotalMembersAlternateWorkers();
        handleGetTotalMembersAlternateCompanies();
        handleGetUsers();
        handleGetProcessSettings();
        handleGetElectoralComissionPosition();
        handleGetElectoralComissionStatus();
        handleGetElectoralComission();
        handleGetTimeline();
        handleGetCandidates();
    }, []);

    const handleUpdateProcessSettings = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyCommitteeElectionProcess.updateProcessSettings({ id_committee_process: idProcess, date_start: startDate, date_end: endDate, id_committee_process_types: idType, id_committee_process_mode: idMode, id_committee_process_election_type: idElectionType, id_users_observed: idUserObserved, total_members: totalMembers, total_members_titular_workers: totalMembersTitularWorkers, total_members_alternate_workers: totalMembersAlternateWorkers, total_members_titular_companies: totalMembersTitularCompanies, total_members_alternate_companies: totalMembersAlternateCompanies, total_ombudsman_candidate: totalOmbudsmanCandidate });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error', 'error');
        } else {
            handleOpenAlert(response.msg, 'success');
        }
    };

    const [openModalPropose, setOpenModalPropose] = useState("");
    const handleOpenModalPropose = () => {
        setOpenModalPropose(true)
    };
    const handleCloseModalPropose = () => setOpenModalPropose(false);
    const [datePropose, setDatePropose] = useState("");

    const handleUpdateProcessStatusPropose = async (event) => {
        event.preventDefault();
        handleUpdateProcessStatus(2);
        handleCloseModalPropose();
    };

    const [openModalSettingsObservations, setOpenModalSettingsObservations] = useState("");

    const handleOpenModalSettingsObservations = () => {
        setOpenModalSettingsObservations(true)
    };
    const handleCloseModalSettingsObservations = () => setOpenModalSettingsObservations(false);


    const [openModalCreateElectoralComission, setOpenModalCreateElectoralComission] = useState("");

    const handleOpenModalCreateElectoralComission = () => {
        setOpenModalCreateElectoralComission(true)
    };
    const handleCloseModalCreateElectoralComission = () => setOpenModalCreateElectoralComission(false);

    const [idUserElectoralComission, setIdUserElectoralComission] = useState("");
    const [dataUserElectoralComission, setDataUserElectoralComission] = useState('');

    const [idPositionElectoralCommission, setIdPositionElectoralCommission] = useState("");

    const handleStoreElectoralComission = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyCommitteeElectionProcess.storeElectoralComission({ id_committee_process: idProcess, id_users: idUserElectoralComission, id_committee_process_electoral_commission_position: idPositionElectoralCommission });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error', 'error');
        } else {
            handleGetElectoralComission();
            handleOpenAlert(response.msg, 'success');
            handleCloseModalCreateElectoralComission();
        }
    };

    const handleSelectUser = async (idUsers) => {
        const response = await MyUsers.editUsers({ idUsers: idUsers });
        if (response.success !== false) {
            if (originSelectUser === 'observed') {
                setIdUserObserved(idUsers);
                setDataUserObserved(response.document + ' - ' + response.first_name + ' ' + response.last_name)
            } else if (originSelectUser === 'electoralComission') {
                setIdUserElectoralComission(idUsers);
                setDataUserElectoralComission(response.document + ' - ' + response.first_name + ' ' + response.last_name)
            }
            handleCloseModalSelectUser();
        }
        return response;
    };

    const handleUpdateElectoralCommissionStatus = async () => {
        handleOpenLoading();
        const response = await MyCommitteeElectionProcess.updateElectoralCommissionStatus({ id_committee_process: idProcess, id_users: idAction, id_committee_process_electoral_commission_status: 2 });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error', 'error');
        } else {
            handleGetElectoralComission();
            handleOpenAlert(response.msg, 'success');
            handleCloseModalCreateElectoralComission();
        }
    };

    const handleDeleteElectoralCommission = async () => {
        handleOpenLoading();
        const response = await MyCommitteeElectionProcess.deleteElectoralCommission({ id_committee_process: idProcess, id_users: idAction });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error', 'error');
        } else {
            handleGetElectoralComission();
            handleOpenAlert(response.msg, 'success');
            handleCloseModalCreateElectoralComission();
        }
    };

    const handleUpdateProcessStatus = async (idProcessStatus) => {
        handleOpenLoading();
        const response = await MyCommitteeElectionProcess.updateProcessStatus({ id_committee_process: idProcess, id_committee_process_statuses: idProcessStatus, data_extra_1: datePropose });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error', 'error');
        } else {
            handleGetTimeline();
            setIdStatuses(idProcessStatus);
            handleOpenAlert(response.msg, 'success');
            handleGetProcessSettingsObservations(null);
        }
    };

    const [openModalTimeline, setOpenModalTimeline] = useState("");

    const handleOpenModalTimeline = () => {
        setOpenModalTimeline(true)
    };
    const handleCloseModalTimeline = () => setOpenModalTimeline(false);

    const [timelineStart, setTimelineStart] = useState("");
    const [timelineEnd, setTimelineEnd] = useState("");
    const [timelineExpiration, setTimelineExpiration] = useState("");
    const [idEditTimeline, setIdEditTimeline] = useState("");

    const [openModalEditTimeline, setOpenModalEditTimeline] = useState("");

    const handleOpenModalEditTimeline = (id, start, end, expiration) => {
        if (start) setTimelineStart(start.slice(0, 19));
        if (end) setTimelineEnd(end.slice(0, 19));
        if (expiration) setTimelineExpiration(expiration.slice(0, 19));
        setIdEditTimeline(id);
        setOpenModalEditTimeline(true)
    };

    const handleCloseModalEditTimeline = () => {
        setTimelineStart('');
        setTimelineEnd('');
        setTimelineExpiration('');
        setOpenModalEditTimeline(false);
    }

    const handleUpdateTimeline = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyCommitteeElectionProcess.updateTimeline({ id_committee_process_timeline: idEditTimeline, timestamp_start: timelineStart, timestamp_end: timelineEnd, timestamp_expiration: timelineExpiration });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error', 'error');
        } else {
            handleGetTimeline();
            handleOpenAlert(response.msg, 'success');
            handleCloseModalEditTimeline();
        }
    };

    const handleUpdateCandidateStatus = async (idCandidateStatus) => {
        handleOpenLoading();
        const response = await MyCommitteeElectionProcess.updateProcessCandidatesStatus({ id_committee_process: idProcess, id_users: idAction, id_committee_process_candidates_statuses: idCandidateStatus });
        if (response.success !== true) {
            handleOpenAlert(response.msg || 'Hubo un error', 'error');
        } else {
            handleGetCandidates();
            handleOpenAlert(response.msg, 'success');
        }
    };


    const [rowSubjects, setRowSubjects] = useState([]);




    return (
        <>
            <Box sx={{ height: '100%' }}>
                <ReturnBack returnBack="/committee/electionProcess"/>

                <LoadingControla
                    openLoading={openLoading}
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                    messageAlert={messageAlert}
                    typeAlert={typeAlert}
                />
                <DialogConfirmControla
                    openModalConfirm={openModalConfirm}
                    handleCloseModalConfirm={handleCloseModalConfirm}
                    titleText={titleText}
                    contentText={contentText}
                    buttonTextConfirm={buttonTextConfirm}
                    handleActionConfirm={handleActionConfirm}
                />
                <Container>

                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container sx={{ marginBottom: '15px' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ButtonMailboxControla
                                    originUrl={"/committee/electionProcess/process"}
                                />
                            </Grid>

                            <Grid item xs={6} sm={5} md={6} lg={6} xl={12}>
                                <TitleText text={"Creacion del proceso"} color={colorTitle}></TitleText>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <BannerControla
                                    text={'Proceso de elecciones'}
                                    image={"/assets/images/committee/bannerVotaciones.png"}
                                />

                            </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="left" spacing={2.5}  >
                            <Grid item xs={10} sm={10} md={7} lg={7} xl={7}>
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress sx={{
                                        height: 10,
                                        borderRadius: 5,
                                    }} variant="determinate" color={'success'} value={statusPercentaje} />
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                                <Typography sx={{
                                    color: "9191B0",
                                    fontSize: 12
                                }}>{statusPercentaje + '%'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                                    <Box>
                                        <ButtonControla
                                            // iconButton={<Add sx={{ color: "white" }} />}
                                            backgroundColor={"#EB5757"}
                                            backgroundColorHover={"#FF4040"}
                                            textButton={"Anular proceso"}
                                            functionOnClick={() => { handleOpenModalConfirm(idProcess, 'Anular proceso de elecciones', '¿Estás seguro de anular el proceso de elecciones, esta es una accion irreversible?', 'Sí, anular', 'cancelProcess'); }}
                                        />
                                    </Box>
                                    {idStatuses == 1 ?
                                        <Box>
                                            <ButtonControla
                                                iconButton={<Send sx={{ color: "white" }} />}
                                                backgroundColor={"#169073"}
                                                backgroundColorHover={"#1BAA88"}
                                                textButton={"Proponer datos"}
                                                functionOnClick={handleOpenModalPropose}
                                            />
                                        </Box>
                                        : ''}
                                    {idStatuses == 2 ?
                                        <Box>
                                            <ButtonControla
                                                iconButton={<Public sx={{ color: "white" }} />}
                                                backgroundColor={"#169073"}
                                                backgroundColorHover={"#1BAA88"}
                                                textButton={"Publicar datos"}
                                                functionOnClick={() => { handleOpenModalConfirm(idProcess, 'Publicar configuración del proceso de elecciones', '¿Estás seguro de publicar la configuración para este proceso de elecciones a los trabajadores?, esta acción no se puede revertir', 'Sí, publicar', 'oficialDataProcess'); }}
                                            />
                                        </Box>
                                        : ''}

                                    {/* {idStatuses == 3 ?
                                        <Box>
                                            <ButtonControla
                                                iconButton={<Group sx={{ color: "white" }} />}
                                                backgroundColor={"#169073"}
                                                backgroundColorHover={"#1BAA88"}
                                                textButton={"Junta electoral"}
                                                functionOnClick={() => { handleOpenModalConfirm(idProcess, 'Ir al proceso de conformación de la junta', '¿Estás seguro de cambiar al proceso a la conformación de la junta electoral?, esta acción no se puede revertir', 'Sí, cambiar', 'oficialDataProcess'); }}
                                            />
                                        </Box>
                                        : ''} */}
                                </Stack>

                            </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="left"
                            sx={{
                                marginTop: '15px',
                                textAlign: "left",
                                overflowY: "scroll",
                                maxHeight: "550px",
                                paddingTop: "0 !important",

                                "&::-webkit-scrollbar": {
                                    width: "0.1em",
                                },
                                "&::-webkit-scrollbar-button": {
                                    height: "1px",
                                },
                                "&::-webkit-scrollbar-track": {
                                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#222ADA",
                                },
                            }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginBottom: '15px' }}>
                                <Accordion sx={{ borderRadius: '16px !important', position: 'unset' }} expanded={accordionExpanded == 'panel1'} onChange={handleChangeAccordionExpanded('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        sx={{ background: '#305AD9', color: 'white', padding: '0px 30px', borderRadius: '16px' }}
                                    >
                                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Configuracion del proceso</Typography>
                                    </AccordionSummary>
                                    <form onSubmit={handleUpdateProcessSettings}>
                                        <AccordionDetails>

                                            <Grid container spacing={2}>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                    <InputControlaVertical text={'Inicio del comité:'} inputType={'date'} inputValue={setStartDate} value={startDate} modalType={true} required />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                    <InputControlaVertical text={'Fin del comité:'} inputType={'date'} inputValue={setEndDate} value={endDate} modalType={true} required />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <SelectControla text={'Tipo de proceso:'} name="id_committee_process_types" modalType={true}
                                                        inputValue={setIdType} childrenRows={
                                                            rowsTypes.map((row) => (
                                                                <MenuItem key={row.id_committee_process_types}
                                                                    value={row.id_committee_process_types}
                                                                >
                                                                    {row.committee_process_type}
                                                                </MenuItem>
                                                            ))
                                                        } value={idType} required
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <SelectControla text={'Modalidad:'} name="id_committee_process_mode" modalType={true}
                                                        inputValue={setIdMode} childrenRows={
                                                            rowsModes.map((row) => (
                                                                <MenuItem key={row.id_committee_process_mode}
                                                                    value={row.id_committee_process_mode}
                                                                >
                                                                    {row.committee_process_mode}
                                                                </MenuItem>
                                                            ))
                                                        } value={idMode} required
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <SelectControla text={'Tipo de elecciones:'} name="id_trainings_subjects" modalType={true}
                                                        inputValue={setIdElectionType} childrenRows={
                                                            rowsElectionTypes.map((row) => (
                                                                <MenuItem key={row.id_committee_process_election_type}
                                                                    value={row.id_committee_process_election_type}
                                                                >
                                                                    {row.committee_process_election_type}
                                                                </MenuItem>
                                                            ))
                                                        } value={idElectionType} required
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                    <InputControlaVertical text={'Personeros por candidato:'} inputType={'number'} inputValue={setTotalOmbudsmanCandidate} value={totalOmbudsmanCandidate} modalType={true} required />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <SelectControla text={'Total de miembros:'} name="total_members" modalType={true}
                                                        inputValue={handleSetRowsTotalMembers} childrenRows={
                                                            rowsTotalMembers.map((row) => (
                                                                <MenuItem key={row.key}
                                                                    value={row.key}
                                                                >
                                                                    {row.value}
                                                                </MenuItem>
                                                            ))
                                                        } value={totalMembers} required
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                    <InputControlaVertical text={'Miembros titulares (empleador):'} inputType={'number'} inputValue={setTotalMembersTitularCompanies} value={totalMembersTitularCompanies} modalType={true} readOnly required />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <SelectControla text={'Miembros suplentes (empleador):'} name="id_trainings_subjects" modalType={true}
                                                        inputValue={setTotalMembersAlternateCompanies} childrenRows={
                                                            rowsTotalMembersAlternateCompanies.map((row) => (
                                                                <MenuItem key={row.key}
                                                                    value={row.key}
                                                                >
                                                                    {row.value}
                                                                </MenuItem>
                                                            ))
                                                        } value={totalMembersAlternateCompanies} required
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                    <InputControlaVertical text={'Miembros titulares (trabajadores):'} inputType={'number'} inputValue={setTotalMembersTitularWorkers} value={totalMembersTitularWorkers} modalType={true} readOnly required />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <SelectControla text={'Miembros suplentes (trabajadores):'} name="total_members_alternate_workers" modalType={true}
                                                        inputValue={setTotalMembersAlternateWorkers} childrenRows={
                                                            rowsTotalMembersAlternateWorkers.map((row) => (
                                                                <MenuItem key={row.key}
                                                                    value={row.key}
                                                                >
                                                                    {row.value}
                                                                </MenuItem>
                                                            ))
                                                        } value={totalMembersAlternateWorkers} required
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <SearchButtonControla titleLabel={'Observador:'} inputValue={dataUserObserved} functionClick={() => { handleOpenModalSelectUser('observed') }} modalType={true} />
                                                </Grid>

                                            </Grid>

                                        </AccordionDetails>
                                        <AccordionActions sx={{ textAlign: 'right' }}>
                                            <Stack direction={'row'} spacing={2} justifyContent={'end'}>

                                                <Box>
                                                    <ButtonControla
                                                        iconButton={<Visibility sx={{ color: "white" }} />}
                                                        backgroundColor={"#169073"}
                                                        backgroundColorHover={"#1BAA88"}
                                                        textButton={"Ver Observaciones"}
                                                        functionOnClick={handleOpenModalSettingsObservations}
                                                    />
                                                </Box>
                                                {idStatuses == 2 ?
                                                    <Box>
                                                        <ButtonControla
                                                            iconButton={<Edit sx={{ color: "white" }} />}
                                                            backgroundColor={"#169073"}
                                                            backgroundColorHover={"#1BAA88"}
                                                            textButton={"Editar"}
                                                            typeButton={"button"}
                                                            functionOnClick={() => { handleOpenModalConfirm(idProcess, 'Regresar a la configuracion de los datos', '¿Estás seguro de regresar a la configuracion, en este modo podras editar la configuracion?', 'Sí, regresar', 'configurationProcess'); }}
                                                        />
                                                    </Box>
                                                    : ''}
                                                {idStatuses == 1 ?
                                                    <Box>
                                                        <ButtonControla
                                                            iconButton={<Save sx={{ color: "white" }} />}
                                                            backgroundColor={"#169073"}
                                                            backgroundColorHover={"#1BAA88"}
                                                            textButton={"Guardar"}
                                                            typeButton={"submit"}
                                                        />
                                                    </Box>
                                                    : ''}
                                            </Stack>

                                        </AccordionActions>
                                    </form>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginBottom: '15px' }}>
                                <Accordion sx={{ borderRadius: '16px !important', position: 'unset' }} expanded={accordionExpanded == 'panel2'} onChange={handleChangeAccordionExpanded('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        sx={{ background: '#305AD9', color: 'white', padding: '0px 30px', borderRadius: '16px' }}
                                    >
                                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Junta Electoral</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={'right'}>
                                                <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                                                    <Box>
                                                        <ButtonControla
                                                            iconButton={<Add sx={{ color: "white" }} />}
                                                            backgroundColor={"#169073"}
                                                            backgroundColorHover={"#1BAA88"}
                                                            textButton={"Agregar Miembro"}
                                                            functionOnClick={handleOpenModalCreateElectoralComission}
                                                        />
                                                    </Box>
                                                </Stack>

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                                                    {rowsElectoralComission.map((row, key) => (
                                                        <Grid item key={key} xs={12} sm={6} md={3} lg={3} xl={3}>
                                                            <Card sx={{ border: '1px solid black', borderRadius: '16px', }}>
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '8px' }}>
                                                                    {(row.id_committee_process_electoral_commission_status == 3) ?
                                                                        <CardMedia sx={{
                                                                            marginTop: 'auto',
                                                                            marginBottom: 'auto',
                                                                            maxWidth: '40px'
                                                                        }}
                                                                        >
                                                                            <UsersPicturesControla textAvatar={row.first_name.charAt(0) + '' + row.last_name.charAt(0)} />
                                                                        </CardMedia>
                                                                        : ''}
                                                                    <CardContent sx={{
                                                                        paddingTop: '8px',
                                                                        paddingLeft: '8px',
                                                                        paddingRight: '8px',
                                                                        paddingBottom: '8px !important',
                                                                        width: '100%'
                                                                    }}>
                                                                        <Typography>
                                                                            {row.first_name + ' ' + row.last_name}
                                                                        </Typography>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                                            <Box>
                                                                                <Typography>
                                                                                    {row.document}
                                                                                </Typography>
                                                                                <Typography>
                                                                                    Cargo: {row.committee_process_electoral_commission_position}
                                                                                </Typography>
                                                                                <Typography>
                                                                                    Estado: {row.committee_process_electoral_commission_status}
                                                                                </Typography>
                                                                            </Box>
                                                                            {(row.id_committee_process_electoral_commission_status == 1) ?
                                                                                <Box sx={{ margin: 'auto' }}>
                                                                                    <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                                                        <Box>
                                                                                            <ButtonIconControla
                                                                                                // roles={[1, 7, 8]}
                                                                                                icon={
                                                                                                    <Delete
                                                                                                        sx={{ color: "white" }}
                                                                                                    />
                                                                                                }
                                                                                                backgroundColor={"#EB5757"}
                                                                                                backgroundColorHover={"#FF4040"}
                                                                                                textTooltip={"Eliminar"}
                                                                                                functionOnClick={() => { handleOpenModalConfirm(row.id_users, 'Eliminar miembro', '¿Estás seguro de eliminar el usuario como miembro de la junta electoral?', 'Sí, eliminar', 'deleteElectoralCommission'); }}
                                                                                            />
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <ButtonIconControla
                                                                                                icon={<Send sx={{ color: 'white' }} />}
                                                                                                backgroundColor={"#169073"}
                                                                                                backgroundColorHover={"#1BAA88"}
                                                                                                textTooltip={"Enviar propuesta"}
                                                                                                functionOnClick={() => { handleOpenModalConfirm(row.id_users, 'Enviar propuesta', '¿Estás seguro de enviar la propuesta al usuario para formar parte de la junta electoral?', 'Sí, enviar', 'updateElectoralCommissionStatus'); }}
                                                                                            />
                                                                                        </Box>
                                                                                    </Stack>
                                                                                </Box>
                                                                                : ''}
                                                                        </Box>
                                                                    </CardContent>

                                                                </Box>
                                                            </Card>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                <Paper sx={{ overflow: "hidden" }}>
                                                    <TableContainer>
                                                        <Table
                                                            stickyHeader
                                                            sx={{ width: "100%", minWidth: "700px" }}
                                                            aria-label="simple table"
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Acta
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Acciones
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rowSubjects.map((row, key) => (
                                                                    <TableRow
                                                                        hover
                                                                        sx={{
                                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                                        }}
                                                                        key={key}
                                                                    >
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            align="center"
                                                                        >
                                                                            {row.evidence}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <Stack
                                                                                direction="row"
                                                                                justifyContent="end"
                                                                                alignItems="center"
                                                                            >
                                                                                <ButtonIconControla
                                                                                    roles={[1, 7, 8]}
                                                                                    icon={
                                                                                        <VisibilityOutlined sx={{ color: "white" }} />
                                                                                    }
                                                                                    backgroundColor={"#305AD9"}
                                                                                    backgroundColorHover={"#0E4AFF"}
                                                                                    textTooltip={"Ver"}
                                                                                    directionUrl={row.evidence_url}
                                                                                    directionTarget={'blank'}
                                                                                />
                                                                                <ButtonIconControla
                                                                                    roles={[1, 7, 8]}
                                                                                    icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={"#2D9CDB"}
                                                                                    backgroundColorHover={"#33AEF4"}
                                                                                    textTooltip={"Editar"}
                                                                                />
                                                                                <ButtonIconControla
                                                                                    roles={[1, 7, 8]}
                                                                                    icon={
                                                                                        <Delete
                                                                                            sx={{ color: "white" }}
                                                                                        />
                                                                                    }
                                                                                    backgroundColor={"#EB5757"}
                                                                                    backgroundColorHover={"#FF4040"}
                                                                                    textTooltip={"Editar"}
                                                                                />
                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginBottom: '15px' }}>
                                <Accordion sx={{ borderRadius: '16px !important', position: 'unset' }} expanded={accordionExpanded == 'panel3'} onChange={handleChangeAccordionExpanded('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        sx={{ background: '#305AD9', color: 'white', padding: '0px 30px', borderRadius: '16px' }}
                                    >
                                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Cronograma</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={'right'}>
                                                <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                                                    <Box>
                                                        <ButtonControla
                                                            iconButton={<Description sx={{ color: "white" }} />}
                                                            backgroundColor={"#2D9CDB"}
                                                            backgroundColorHover={"#33AEF4"}
                                                            textButton={"Ver proceso"}
                                                            functionOnClick={handleOpenModalTimeline}
                                                        />
                                                    </Box>
                                                    {/* <Box>
                                                        <ButtonControla
                                                            iconButton={<Add sx={{ color: "white" }} />}
                                                            backgroundColor={"#169073"}
                                                            backgroundColorHover={"#1BAA88"}
                                                            textButton={"Agregar Proceso"}
                                                            functionOnClick={handleOpenModalCreateTimeline}
                                                        />
                                                    </Box> */}
                                                </Stack>

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Paper sx={{ overflow: "hidden" }}>
                                                    <TableContainer>
                                                        <Table
                                                            stickyHeader
                                                            sx={{ width: "100%", minWidth: "700px" }}
                                                            aria-label="simple table"
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Proceso
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Fecha de inicio
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Fecha de fin
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Fecha de expiracion
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Acciones
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rowsTimeline.map((row, key) => (
                                                                    <TableRow
                                                                        hover
                                                                        sx={{
                                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                                        }}
                                                                        key={key}
                                                                    >
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            align="center"
                                                                        >
                                                                            {row.committee_process_status}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            align="center"
                                                                        >
                                                                            {(row.timestamp_start) ? row.timestamp_start.slice(0, 19) : ''}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            align="center"
                                                                        >
                                                                            {(row.timestamp_end) ? row.timestamp_end.slice(0, 19) : ''}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            align="center"
                                                                        >
                                                                            {(row.timestamp_expiration) ? row.timestamp_expiration.slice(0, 19) : ''}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {(row.id_committee_process_statuses !== 1 && row.id_committee_process_statuses !== 2 && row.id_committee_process_statuses !== 3) ?
                                                                                <Stack
                                                                                    direction="row"
                                                                                    justifyContent="end"
                                                                                    alignItems="center"
                                                                                >
                                                                                    <ButtonIconControla
                                                                                        roles={[1, 7, 8]}
                                                                                        icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={"#2D9CDB"}
                                                                                        backgroundColorHover={"#33AEF4"}
                                                                                        textTooltip={"Editar"}
                                                                                        functionOnClick={() => { handleOpenModalEditTimeline(row.id_committee_process_timeline, row.timestamp_start, row.timestamp_end, row.timestamp_expiration) }}
                                                                                    />
                                                                                </Stack>
                                                                                : ''}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            {idElectionType == 1 ? (
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginBottom: '15px' }}>
                                    <Accordion sx={{ borderRadius: '16px !important', position: 'unset' }} expanded={accordionExpanded == 'panel5'} onChange={handleChangeAccordionExpanded('panel5')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            sx={{ background: '#305AD9', color: 'white', padding: '0px 30px', borderRadius: '16px' }}
                                        >
                                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Listas postulantes a miembros del comité</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={'right'}>
                                                    <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                                                        <Box>
                                                            <ButtonControla
                                                                iconButton={<Description sx={{ color: "white" }} />}
                                                                backgroundColor={"#2D9CDB"}
                                                                backgroundColorHover={"#33AEF4"}
                                                                textButton={"Ver proceso"}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <ButtonControla
                                                                iconButton={<Add sx={{ color: "white" }} />}
                                                                backgroundColor={"#169073"}
                                                                backgroundColorHover={"#1BAA88"}
                                                                textButton={"Agregar Proceso"}
                                                            />
                                                        </Box>
                                                    </Stack>

                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Paper sx={{ overflow: "hidden" }}>
                                                        <TableContainer>
                                                            <Table
                                                                stickyHeader
                                                                sx={{ width: "100%", minWidth: "700px" }}
                                                                aria-label="simple table"
                                                            >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                                                        <TableCell align="center" sx={{ color: blue }}>
                                                                            Nombres
                                                                        </TableCell>
                                                                        <TableCell align="center" sx={{ color: blue }}>
                                                                            Documento de identidad
                                                                        </TableCell>
                                                                        <TableCell align="center" sx={{ color: blue }}>
                                                                            Cargo de la junta
                                                                        </TableCell>
                                                                        <TableCell align="center" sx={{ color: blue }}>
                                                                            Estado
                                                                        </TableCell>
                                                                        <TableCell align="center" sx={{ color: blue }}>
                                                                            Acciones
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {rowSubjects.map((row, key) => (
                                                                        <TableRow
                                                                            hover
                                                                            sx={{
                                                                                "&:last-child td, &:last-child th": { border: 0 },
                                                                            }}
                                                                            key={key}
                                                                        >
                                                                            <TableCell
                                                                                component="th"
                                                                                scope="row"
                                                                                align="center"
                                                                            >
                                                                                {row.evidence}
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                <Stack
                                                                                    direction="row"
                                                                                    justifyContent="end"
                                                                                    alignItems="center"
                                                                                >
                                                                                    <ButtonIconControla
                                                                                        roles={[1, 7, 8]}
                                                                                        icon={
                                                                                            <VisibilityOutlined sx={{ color: "white" }} />
                                                                                        }
                                                                                        backgroundColor={"#305AD9"}
                                                                                        backgroundColorHover={"#0E4AFF"}
                                                                                        textTooltip={"Ver"}
                                                                                        directionUrl={row.evidence_url}
                                                                                        directionTarget={'blank'}
                                                                                    />
                                                                                    <ButtonIconControla
                                                                                        roles={[1, 7, 8]}
                                                                                        icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={"#2D9CDB"}
                                                                                        backgroundColorHover={"#33AEF4"}
                                                                                        textTooltip={"Editar"}
                                                                                    />
                                                                                    <ButtonIconControla
                                                                                        roles={[1, 7, 8]}
                                                                                        icon={
                                                                                            <Delete
                                                                                                sx={{ color: "white" }}
                                                                                            />
                                                                                        }
                                                                                        backgroundColor={"#EB5757"}
                                                                                        backgroundColorHover={"#FF4040"}
                                                                                        textTooltip={"Editar"}
                                                                                    />
                                                                                </Stack>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Paper>
                                                </Grid>

                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            ) : (
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginBottom: '15px' }}>
                                    <Accordion sx={{ borderRadius: '16px !important', position: 'unset' }} expanded={accordionExpanded == 'panel4'} onChange={handleChangeAccordionExpanded('panel4')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            sx={{ background: '#305AD9', color: 'white', padding: '0px 30px', borderRadius: '16px' }}
                                        >
                                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Postulantes a miembros del comité</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={'right'}>
                                                <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                                                    <Box>
                                                        <ButtonControla
                                                            iconButton={<Description sx={{ color: "white" }} />}
                                                            backgroundColor={"#2D9CDB"}
                                                            backgroundColorHover={"#33AEF4"}
                                                            textButton={"Ver proceso"}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <ButtonControla
                                                            iconButton={<Add sx={{ color: "white" }} />}
                                                            backgroundColor={"#169073"}
                                                            backgroundColorHover={"#1BAA88"}
                                                            textButton={"Agregar Proceso"}
                                                        />
                                                    </Box>
                                                </Stack>

                                            </Grid> */}

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
                                                        {rowsCandidates.map((row, key) => (
                                                            <Grid item key={key} xs={12} sm={6} md={3} lg={3} xl={3}>
                                                                <Card sx={{ border: '1px solid black', borderRadius: '16px', }}>
                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '8px' }}>
                                                                        {(row.id_committee_process_candidates_statuses == 2) ?
                                                                            <CardMedia sx={{
                                                                                marginTop: 'auto',
                                                                                marginBottom: 'auto',
                                                                                maxWidth: '40px'
                                                                            }}
                                                                            >
                                                                                <UsersPicturesControla textAvatar={row.first_name.charAt(0) + '' + row.last_name.charAt(0)} />
                                                                            </CardMedia>
                                                                            : ''}
                                                                        <CardContent sx={{
                                                                            paddingTop: '8px',
                                                                            paddingLeft: '8px',
                                                                            paddingRight: '8px',
                                                                            paddingBottom: '8px !important',
                                                                            width: '100%'
                                                                        }}>
                                                                            <Typography>
                                                                                {row.first_name + ' ' + row.last_name}
                                                                            </Typography>
                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                                                <Box>
                                                                                    <Typography>
                                                                                        {row.document}
                                                                                    </Typography>
                                                                                    {/* <Typography>
                                                                                    Cargo: {row.committee_process_electoral_commission_position}
                                                                                </Typography> */}
                                                                                    <Typography>
                                                                                        Estado: {row.committee_process_candidates_statuses}
                                                                                    </Typography>
                                                                                </Box>
                                                                                {(row.id_committee_process_candidates_statuses == 1) ?
                                                                                    <Box sx={{ margin: 'auto' }}>
                                                                                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                                                            <Box>
                                                                                                <ButtonIconControla
                                                                                                    // roles={[1, 7, 8]}
                                                                                                    icon={
                                                                                                        <ThumbDown
                                                                                                            sx={{ color: "white" }}
                                                                                                        />
                                                                                                    }
                                                                                                    backgroundColor={"#EB5757"}
                                                                                                    backgroundColorHover={"#FF4040"}
                                                                                                    textTooltip={"Rechazar"}
                                                                                                    functionOnClick={() => { handleOpenModalConfirm(row.id_users, 'Rechazar postulante', '¿Estás seguro de rechazar a este postulante?, la accion no se puede deshacer', 'Sí, rechazar', 'rejectCandidate'); }}
                                                                                                />
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <ButtonIconControla
                                                                                                    icon={<ThumbUp sx={{ color: 'white' }} />}
                                                                                                    backgroundColor={"#169073"}
                                                                                                    backgroundColorHover={"#1BAA88"}
                                                                                                    textTooltip={"Aceptar"}
                                                                                                    functionOnClick={() => { handleOpenModalConfirm(row.id_users, 'Aceptar postulante', '¿Estás seguro de aceptar a este postulante?, la accion no se puede deshacer', 'Sí, aceptar', 'acceptCandidate'); }}
                                                                                                />
                                                                                            </Box>
                                                                                        </Stack>
                                                                                    </Box>
                                                                                    : ''}
                                                                            </Box>
                                                                        </CardContent>

                                                                    </Box>
                                                                </Card>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )}


                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginBottom: '15px' }}>
                                <Accordion sx={{ borderRadius: '16px !important', position: 'unset' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        sx={{ background: '#305AD9', color: 'white', padding: '0px 30px', borderRadius: '16px' }}
                                    >
                                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Seleccion de personeros</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={'right'}>
                                                <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                                                    <Box>
                                                        <ButtonControla
                                                            iconButton={<Description sx={{ color: "white" }} />}
                                                            backgroundColor={"#2D9CDB"}
                                                            backgroundColorHover={"#33AEF4"}
                                                            textButton={"Ver proceso"}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <ButtonControla
                                                            iconButton={<Add sx={{ color: "white" }} />}
                                                            backgroundColor={"#169073"}
                                                            backgroundColorHover={"#1BAA88"}
                                                            textButton={"Agregar Proceso"}
                                                        />
                                                    </Box>
                                                </Stack>

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Paper sx={{ overflow: "hidden" }}>
                                                    <TableContainer>
                                                        <Table
                                                            stickyHeader
                                                            sx={{ width: "100%", minWidth: "700px" }}
                                                            aria-label="simple table"
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Nombres
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Documento de identidad
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Cargo de la junta
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Estado
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Acciones
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rowSubjects.map((row, key) => (
                                                                    <TableRow
                                                                        hover
                                                                        sx={{
                                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                                        }}
                                                                        key={key}
                                                                    >
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            align="center"
                                                                        >
                                                                            {row.evidence}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <Stack
                                                                                direction="row"
                                                                                justifyContent="end"
                                                                                alignItems="center"
                                                                            >
                                                                                <ButtonIconControla
                                                                                    roles={[1, 7, 8]}
                                                                                    icon={
                                                                                        <VisibilityOutlined sx={{ color: "white" }} />
                                                                                    }
                                                                                    backgroundColor={"#305AD9"}
                                                                                    backgroundColorHover={"#0E4AFF"}
                                                                                    textTooltip={"Ver"}
                                                                                    directionUrl={row.evidence_url}
                                                                                    directionTarget={'blank'}
                                                                                />
                                                                                <ButtonIconControla
                                                                                    roles={[1, 7, 8]}
                                                                                    icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={"#2D9CDB"}
                                                                                    backgroundColorHover={"#33AEF4"}
                                                                                    textTooltip={"Editar"}
                                                                                />
                                                                                <ButtonIconControla
                                                                                    roles={[1, 7, 8]}
                                                                                    icon={
                                                                                        <Delete
                                                                                            sx={{ color: "white" }}
                                                                                        />
                                                                                    }
                                                                                    backgroundColor={"#EB5757"}
                                                                                    backgroundColorHover={"#FF4040"}
                                                                                    textTooltip={"Editar"}
                                                                                />
                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginBottom: '15px' }}>
                                <Accordion sx={{ borderRadius: '16px !important', position: 'unset' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        sx={{ background: '#305AD9', color: 'white', padding: '0px 30px', borderRadius: '16px' }}
                                    >
                                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Resultados</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={'right'}>
                                                <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                                                    <Box>
                                                        <ButtonControla
                                                            iconButton={<Description sx={{ color: "white" }} />}
                                                            backgroundColor={"#2D9CDB"}
                                                            backgroundColorHover={"#33AEF4"}
                                                            textButton={"Ver proceso"}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <ButtonControla
                                                            iconButton={<Add sx={{ color: "white" }} />}
                                                            backgroundColor={"#169073"}
                                                            backgroundColorHover={"#1BAA88"}
                                                            textButton={"Agregar Proceso"}
                                                        />
                                                    </Box>
                                                </Stack>

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Paper sx={{ overflow: "hidden" }}>
                                                    <TableContainer>
                                                        <Table
                                                            stickyHeader
                                                            sx={{ width: "100%", minWidth: "700px" }}
                                                            aria-label="simple table"
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Nombres
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Documento de identidad
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Cargo de la junta
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Estado
                                                                    </TableCell>
                                                                    <TableCell align="center" sx={{ color: blue }}>
                                                                        Acciones
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rowSubjects.map((row, key) => (
                                                                    <TableRow
                                                                        hover
                                                                        sx={{
                                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                                        }}
                                                                        key={key}
                                                                    >
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            align="center"
                                                                        >
                                                                            {row.evidence}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <Stack
                                                                                direction="row"
                                                                                justifyContent="end"
                                                                                alignItems="center"
                                                                            >
                                                                                <ButtonIconControla
                                                                                    roles={[1, 7, 8]}
                                                                                    icon={
                                                                                        <VisibilityOutlined sx={{ color: "white" }} />
                                                                                    }
                                                                                    backgroundColor={"#305AD9"}
                                                                                    backgroundColorHover={"#0E4AFF"}
                                                                                    textTooltip={"Ver"}
                                                                                    directionUrl={row.evidence_url}
                                                                                    directionTarget={'blank'}
                                                                                />
                                                                                <ButtonIconControla
                                                                                    roles={[1, 7, 8]}
                                                                                    icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={"#2D9CDB"}
                                                                                    backgroundColorHover={"#33AEF4"}
                                                                                    textTooltip={"Editar"}
                                                                                />
                                                                                <ButtonIconControla
                                                                                    roles={[1, 7, 8]}
                                                                                    icon={
                                                                                        <Delete
                                                                                            sx={{ color: "white" }}
                                                                                        />
                                                                                    }
                                                                                    backgroundColor={"#EB5757"}
                                                                                    backgroundColorHover={"#FF4040"}
                                                                                    textTooltip={"Editar"}
                                                                                />
                                                                            </Stack>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                        </Grid>
                    </Box>
                </Container >

                <Dialog
                    open={openModalSelectUser}
                    onClose={handleCloseModalSelectUser}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText='Buscar Usuario' functionOnClose={handleCloseModalSelectUser} />
                    <DialogContent >
                        <SearchUsersControla rowsUser={rowsUserObserved} handleUsersActionButton={handleSelectUser} />
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={'row'} spacing={2}>
                            <Box>
                                <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalSelectUser} />
                            </Box>
                        </Stack>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openModalCreateElectoralComission}
                    onClose={handleCloseModalCreateElectoralComission}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText='Agregar miembro a la junta electoral' functionOnClose={handleCloseModalCreateElectoralComission} />
                    <form onSubmit={handleStoreElectoralComission}>
                        <DialogContent >
                            <SearchButtonControla titleLabel={'Usuario:'} inputValue={dataUserElectoralComission} functionClick={() => { handleOpenModalSelectUser('electoralComission') }} modalType={true} />

                            <SelectControla text={'Cargo:'} name="id_committee_process_electoral_commission_position" modalType={true}
                                inputValue={setIdPositionElectoralCommission} childrenRows={
                                    rowsElectoralComissionPosition.map((row) => (
                                        <MenuItem key={row.id_committee_process_electoral_commission_position}
                                            value={row.id_committee_process_electoral_commission_position}
                                        >
                                            {row.committee_process_electoral_commission_position}
                                        </MenuItem>
                                    ))
                                } required
                            />

                        </DialogContent>
                        <DialogActions>
                            <Stack direction={'row'} spacing={2}>
                                <Box>
                                    <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalCreateElectoralComission} />
                                </Box>
                                <Box>
                                    <ButtonControla iconButton={<Add />} backgroundColor={'#169073'} textButton={'Agregar'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                </Box>
                            </Stack>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={openModalTimeline}
                    onClose={handleCloseModalTimeline}
                    maxWidth="lg"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    {/* <DialogTitleControla titleText='Informacion del proceso para los trabajadores.' functionOnClose={handleCloseModalTimeline} /> */}
                    <DialogContent >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                    MODELO DE CONVOCATORIA AL PROCESO DE ELECCIÓN DE LOS/LAS REPRESENTANTES TITULARES Y SUPLENTES DE LOS/AS TRABAJADORES/AS ANTE EL COMITÉ DE SEGURIDAD Y SALUD EN EL TRABAJO DE LA EMPRESA/ENTIDAD {companyName} POR EL PERIODO {startDate + ' - ' + endDate}
                                </Typography>
                                <Typography >
                                    {companyName} , en virtud del artículo 31º de la LSST2 y el artículo 49º del RLSST3, convoca a las elecciones de los/as representantes de los/as trabajadores/as ante el Comité de Seguridad y Salud en el Trabajo, de acuerdo al siguiente cronograma:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={8} xl={8} >
                                <Typography >
                                    1 - Conformación de la Junta Electoral(Integrantes de la JE: designados/as por sindicato mayoritario, sindicato más representativo o el/la empleador/a, dependiendo de quién tuvo a su cargo la convocatoria a elecciones, 49º RLSST ）
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                                {rowsElectoralComission.map((row, key) => (
                                    (row.id_committee_process_electoral_commission_position === 3 ?
                                        <Typography>
                                            {row.committee_process_electoral_commission_position + ': ' + row.first_name + ' ' + row.last_name}
                                        </Typography>
                                        : '')
                                ))}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={8} xl={8} >
                                <Typography >
                                    2 - Número de representantes titulares y suplentes a ser elegidos/as （ 43º RLSST ）
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                                <Typography>
                                    Titulares: {totalMembersTitularWorkers}
                                </Typography>
                                <Typography>
                                    Suplentes: {totalMembersAlternateWorkers}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={8} xl={8} >
                                <Typography >
                                    3 - Plazo del mandato （ 62º RLSST ）
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                                <Typography>
                                    {mandateTerm.years + ' años ' + mandateTerm.months + ' meses ' + mandateTerm.days + ' dias'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={8} xl={8} >
                                <Typography >
                                    4 - Cumplir con los requisitos para postular y ser elegidos/as como representantes de los/as trabajadores/as ante el Comité de Seguridad y Salud en el Trabajo:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                                <Typography>
                                    Ser trabajador/a del empleador.
                                </Typography>
                                <Typography>
                                    Tener dieciocho años (18 años) de edad como mínimo.
                                </Typography>
                                <Typography>
                                    De preferencia, tener capacitación en temas de seguridad y salud en el trabajo o laborar en puestos que le permitan tener conocimiento o información sobre riesgos laborales.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={8} xl={8} >
                                <Typography >
                                    5 - Modalidad en la que se llevará a cabo el proceso de elecciones
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                                <Typography>
                                    No presencial.
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={'row'} spacing={2}>
                            <Box>
                                <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalTimeline} />
                            </Box>
                        </Stack>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openModalEditTimeline}
                    onClose={handleCloseModalEditTimeline}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText='Editar fechas de un proceso del cronograma' functionOnClose={handleCloseModalEditTimeline} />
                    <form onSubmit={handleUpdateTimeline}>
                        <DialogContent >

                            <InputControlaVertical text={'Fecha de inicio:'} inputType={'datetime-local'} inputValue={setTimelineStart} value={timelineStart} modalType={true} required />

                            <InputControlaVertical text={'Fecha de fin:'} inputType={'datetime-local'} inputValue={setTimelineEnd} value={timelineEnd} modalType={true} min={timelineStart} required />

                            <InputControlaVertical text={'Fecha de expiración:'} inputType={'datetime-local'} inputValue={setTimelineExpiration} value={timelineExpiration} min={timelineEnd} modalType={true} required />

                        </DialogContent>
                        <DialogActions>
                            <Stack direction={'row'} spacing={2}>
                                <Box>
                                    <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalEditTimeline} />
                                </Box>
                                <Box>
                                    <ButtonControla iconButton={<Edit />} backgroundColor={'#169073'} textButton={'Actualizar'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                </Box>
                            </Stack>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={openModalPropose}
                    onClose={handleCloseModalPropose}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText='Proponer configuración' functionOnClose={handleCloseModalPropose} />
                    <form onSubmit={handleUpdateProcessStatusPropose}>
                        <DialogContent >

                            <Typography>
                                ¿Estás seguro de enviar la propuesta de la configuracion para este proceso de elecciones a los trabajadores?
                            </Typography>

                            <InputControlaVertical text={'Fecha Limite para recibir comentarios:'} inputType={'datetime-local'} inputValue={setDatePropose} value={datePropose} modalType={true} required />

                        </DialogContent>
                        <DialogActions>
                            <Stack direction={'row'} spacing={2}>
                                <Box>
                                    <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalPropose} />
                                </Box>
                                <Box>
                                    <ButtonControla iconButton={<Edit />} backgroundColor={'#169073'} textButton={'Actualizar'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                </Box>
                            </Stack>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={openModalSettingsObservations}
                    onClose={handleCloseModalSettingsObservations}
                    maxWidth="md"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText='Observaciones a la propuesta' functionOnClose={handleCloseModalSettingsObservations} />
                    <DialogContent >
                        <Paper sx={{ overflow: "hidden" }}>
                            <TableContainer>
                                <Table
                                    stickyHeader
                                    sx={{ width: "100%", minWidth: "700px" }}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                            <TableCell align="center" sx={{ color: blue }}>
                                                Usuario
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: blue }}>
                                                Observacion
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowsSettingsObservations.map((row, key) => (
                                            <TableRow
                                                hover
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                                key={key}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                >
                                                    {row.first_name + ' ' + row.last_name}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.setting_observation}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={'row'} spacing={2}>
                            <Box>
                                <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalSettingsObservations} />
                            </Box>
                        </Stack>
                    </DialogActions>
                </Dialog>
            </Box >
        </>
    )
}

export default CreateProcess