import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import {
    BorderColorOutlined,
    Delete,
    Archive,
    FileUploadOutlined,
    Send
} from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import ButtonIconControla from "../../components/buttons/buttonIconControla";
import { useAppContext } from "../../context/AppContext";

function ListActives(props) {
    const { permissions } = useAppContext();
    const {
        rowsStandards,
        colorCell,
        getUser,
        handleOpenModalConfirm,
        handleOpenModalUpload
    } = props;

    return (
        <TableBody>
            {rowsStandards.map((row) => (
                <TableRow
                    hover
                    key={row.id_standards}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell sx={{ color: colorCell }}>
                        {row.document_title}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.archive == 1 ? "Archivo" : "Sistema"}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.id_standards_statuses == 1 ? "Creado" : (row.id_standards_statuses == 2 ? (row.firm_approve == null ? "En revisión" :"Aprobado"): "Publicado")}
                    </TableCell>
                    <TableCell>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {
                                row.id_standards_statuses == 1 && row.archive != 1 ? (
                                    <>
                                        <ButtonIconControla
                                            roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                            icon={<BorderColorOutlined  sx={{ color: "white" }} />}
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar"}
                                            isNavegationOrigin={true}
                                            directionUrl={`/documentation/documents/standards/edit?id_standard=${row.id_standards}`}
                                        />
                                        <ButtonIconControla
                                            roles={permissions.DOC_HG_STANDAR_ACTI_ENVI_REVI}
                                            icon={<Send sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1AA483"}
                                            textTooltip={"Enviar a revisión"}
                                            functionOnClick={() => handleOpenModalConfirm(
                                                "Enviar a revisión",
                                                "¿Estás seguro de enviar a revisión este documento?",
                                                "Si, enviar",
                                                "sendReview",
                                                row.id_standards,
                                                row.firm_prepare
                                            )}
                                        />
                                    </>
                                ) : " "
                            }
                            {
                                row.id_standards_statuses == 1 && row.archive == 1 ? (
                                    <>
                                        <ButtonIconControla
                                            roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                            icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar"}
                                            functionOnClick={() => handleOpenModalUpload("update", row)}
                                        />

                                        <ButtonIconControla
                                            roles={permissions.DOC_HG_STANDAR_ACTI_PUBLI}
                                            icon={ <FileUploadOutlined sx={{ color: "white" }}/>}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1AA483"}
                                            textTooltip={"Publicar"}
                                            functionOnClick={() => handleOpenModalConfirm(
                                                "Publicar documento",
                                                "¿Estás seguro de publicar este documento?",
                                                "Si, publicar",
                                                "publishFile",
                                                row.id_standards,
                                                row.firm_prepare
                                            )}
                                        />
                                        <ButtonIconControla
                                            roles={permissions.DOC_HG_STANDAR_ACTI_VER}
                                            icon={ <VisibilityOutlined sx={{ color: "white" }}/> }
                                            backgroundColor={"#305AD9"}
                                            backgroundColorHover={"#0E4AFF"}
                                            textTooltip={"Ver"}
                                            directionUrl={row.document_url}
                                            directionTarget={"_blank"}
                                        />
                                    </>
                                ) : ""
                            }
                            {
                                row.id_standards_statuses == 1 ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_STANDAR_ACTI_ELIMINAR}
                                        icon={<Delete sx={{ color: "white" }} />}
                                        backgroundColor={"#EB5757"}
                                        backgroundColorHover={"#FF4040"}
                                        textTooltip={"Eliminar"}
                                        functionOnClick={() => handleOpenModalConfirm(
                                            "¿Estás seguro de eliminar el estándard?",
                                            `Una vez eliminado no se podrá recuperar todo lo incluido en este estándar: "${row.document_title}"`,
                                            "Si, Eliminar",
                                            "deleteActiveStandard",
                                            row.id_standards
                                        )}
                                    />
                                ) : ""
                            }
                            {
                                row.id_standards_statuses == 2  && row.firm_approve == 1? (
                                    <>
                                        {/* <ButtonIconControla
                                            roles={permissions.DOC_HG_STANDAR_ACTI_EDIT}
                                            icon={
                                                <BorderColorOutlined
                                                    sx={{ color: "white" }}
                                                />
                                            }
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar activo"}
                                            isNavegationOrigin={true}
                                            directionUrl={`/documentation/documents/standards/edit?id_standard=${row.id_standards}`}
                                        /> */}
                                        <ButtonIconControla
                                            roles={permissions.DOC_HG_STANDAR_ACTI_PUBLI}
                                            icon={ <FileUploadOutlined sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1AA483"}
                                            textTooltip={"Publicar"}
                                            functionOnClick={() => handleOpenModalConfirm(
                                                "Publicar documento",
                                                "¿Estás seguro de publicar este documento?",
                                                "Si, publicar",
                                                "publish",
                                                row.id_standards,
                                                row.firm_prepare
                                            )}
                                        />
                                    </>
                                ) : ""
                            }

                            {/* para 1 revisador */}
                            {
                                
                                row.id_standards_statuses == 2 && row.id_users_review_1 == getUser().id_users ? (
                                    (row.id_users_review_1 != null && row.firm_review_1 == null ) && row.id_users_review_2 == null && row.firm_approve == null ? (
                                        <ButtonIconControla
                                            icon={ <BorderColorOutlined sx={{ color: "white" }} />}
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar 1 revisador"}
                                            isNavegationOrigin={true}
                                            directionUrl={`/documentation/documents/standards/edit?id_standard=${row.id_standards}`}
                                        />
                                    ) : ""
                                ) : ""
                            } 
                            {/* para 2 revisadores */}
                            {
                                row.id_standards_statuses == 2 && (row.id_users_review_1 == getUser().id_users || row.id_users_review_2 == getUser().id_users) &&
                                    (row.id_users_review_1 != null && row.id_users_review_2 != null) ? (
                                    row.firm_review_1 == null && row.id_users_review_1 == getUser().id_users ? (
                                        <ButtonIconControla
                                            icon={ <BorderColorOutlined sx={{ color: "white" }}/>}
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar revisador1"}
                                            isNavegationOrigin={true}
                                            directionUrl={`/documentation/documents/standards/edit?id_standard=${row.id_standards}`}
                                        />

                                    ) : (row.firm_review_2 == null && row.id_users_review_2 == getUser().id_users ? (
                                        <ButtonIconControla
                                            icon={<BorderColorOutlined sx={{ color: "white" }}/>}
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar revisador2"}
                                            isNavegationOrigin={true}
                                            directionUrl={`/documentation/documents/standards/edit?id_standard=${row.id_standards}`}
                                        />

                                    ) : ""
                                    )
                                ) : ""
                            }

                            {/* para el aprobador */}
                            {
                                row.id_standards_statuses == 2 && row.id_users_approve == getUser().id_users ? (
                                    (row.id_users_review_1 != null && row.id_users_review_2 == null) &&
                                        (row.firm_review_1 == 1 && row.firm_approve == null) ? (
                                        <ButtonIconControla
                                            icon={ <BorderColorOutlined sx={{ color: "white" }}/>}
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar aprobador 1 revisador"}
                                            isNavegationOrigin={true}
                                            directionUrl={`/documentation/documents/standards/edit?id_standard=${row.id_standards}`}
                                        />
                                    ) : (
                                        (row.id_users_review_1 != null && row.id_users_review_2 != null) &&
                                            (row.firm_review_1 == 1 && row.firm_review_2 == 1) && row.firm_approve == null ? (
                                            <ButtonIconControla
                                                icon={ <BorderColorOutlined  sx={{ color: "white" }} /> }
                                                backgroundColor={"#2D9CDB"}
                                                backgroundColorHover={"#33AEF4"}
                                                textTooltip={"Editar aprobador 2 revisadores"}
                                                isNavegationOrigin={true}
                                                directionUrl={`/documentation/documents/standards/edit?id_standard=${row.id_standards}`}
                                            />
                                        ) : ""
                                    )
                                ) : ""
                            }



                            {
                                row.id_standards_statuses == 2 && row.active == 1 ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_STANDAR_ACTI_VER}
                                        icon={ <VisibilityOutlined sx={{ color: "white" }}/> }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        isNavegationOrigin={"true"}
                                        directionUrl={`/documentation/documents/standards/edit?id_standard=${row.id_standards}&readOnly=true`}
                                    />

                                ) : ""
                            }
                            {
                                row.id_standards_statuses == 3 ? (
                                    <>
                                        <ButtonIconControla
                                            roles={permissions.DOC_HG_STANDAR_ACTI_ARCHI}
                                            icon={<Archive sx={{ color: "white" }} />}
                                            backgroundColor={"#F2994A"}
                                            backgroundColorHover={"#FF881E"}
                                            textTooltip={"Archivar"}
                                            functionOnClick={() => handleOpenModalConfirm(
                                                "Archivar documento",
                                                "¿Estás seguro de archivar el documento?",
                                                "Si, archivar",
                                                "archived",
                                                row.id_standards,
                                                "1"
                                            )}
                                        />
                                    </>
                                ) : ""
                            }
                            {
                                row.id_standards_statuses == 3 && row.active == 1 && row.model == 1 ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_STANDAR_ACTI_VER}
                                        icon={ <VisibilityOutlined sx={{ color: "white" }}/>}
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        isNavegationOrigin={"true"}
                                        directionUrl={`/documentation/documents/standards/edit?id_standard=${row.id_standards}&readOnly=true`}
                                    />
                                ) : ""
                            }
                            {
                                row.id_standards_statuses == 3 && row.archive == 1 ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_STANDAR_ACTI_VER}
                                        icon={<VisibilityOutlined sx={{ color: "white" }}/> }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        directionUrl={row.document_url}
                                        directionTarget={"_blank"}
                                    />
                                ) : ""
                            }
                        </Stack>
                    </TableCell>
                </TableRow>
            ))
            }
        </TableBody>
    );

}
export default ListActives;