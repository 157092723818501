import React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import { Add, Send } from "@mui/icons-material";
import SubtitleText from "../components/text/subtitleText";
import DocBanner from "../components/cards/docBanner";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import DownloadIcon from "@mui/icons-material/Download";
import AddMemberDialog from "./addMemberDialog";
import AddDateDialog from "./addDateDialog";
import InputControlaVertical from "../components/textfields/inputControlaVertical";
import SelectControla from "../components/selects/selectControla";
import ButtonControla from "../components/buttons/buttonController";

const grey = "#9191B0";
const blue = "#034AFF";
//const drawerBlue = "#0F1255";
//const uDrawerText = "#65A3FF";
const green = "#2C8C75";
const lilac = "#D1D1E9";
const teal = "#2D9CDB";
const purple = "#5D5D93";
//const simpleBlue = "#599CFF";
const accordionBlue = "#305AD9";
const backgroundGrey = "#F5F5F5";

const StyledDataButton = styled(Button)({
  textTransform: "none",
  borderRadius: 14,
  backgroundColor: green,
  color: "white",
  width: "100%",
});

const StyledCancelButton = styled(Button)({
  textTransform: "none",
  borderRadius: 14,
  backgroundColor: lilac,
  color: purple,
  width: "100%",
});

const StyledBlueButton = styled(Button)({
  textTransform: "none",
  borderRadius: 14,
  backgroundColor: blue,
  color: "white",
  width: "100%",
});

const StyledTealButton = styled(Button)({
  textTransform: "none",
  borderRadius: 14,
  backgroundColor: teal,
  color: "white",
  width: "100%",
});

const StyledAccordion = styled(Accordion)({
  backgroundColor: backgroundGrey,
  borderRadius: "0.9rem",
  boxShadow: "none",
  color: purple,
});

const StyledGreyButton = styled(Button)({
  textTransform: "none",
  borderRadius: 14,
  backgroundColor: backgroundGrey,
  color: accordionBlue,
  width: "100%",
});

const StyledTextField = styled(TextField)({
  //label
  "& label.Mui-focused": {
    color: "#305AD9",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#305AD9",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      background: "white",
      borderRadius: " 16px",
    },
    "&:hover fieldset": {
      borderColor: "white",
      background: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      background: "white",
    },
    "& .MuiFilledInput-root": {
      background: "white",
    },
  },
  width: "100%",
});

const StyledTypography = styled(Typography)({
  color: "#305AD9",
  fontSize: 16,
  fontWeight: 600,
  gap: "2%",
  padding: "0.5em",
});

const BorderLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
});

const StyledPercentageText = styled(Typography)({
  color: "9191B0",
  fontSize: 12,
});

function ProcessStatus() {
  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>

        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container rowSpacing={3} columnSpacing={6}>
              <Grid item md={12}>
                <SubtitleText
                  text={"Proceso de las elecciones"}
                  color={grey}
                ></SubtitleText>
              </Grid>
              <Grid item md={12}>
                <DocBanner
                  color1={"#8B8BD8"}
                  color2={"#2B2BA5"}
                  text={"Estado del proceso"}
                ></DocBanner>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="left"
                gap={2}
                style={{ paddingLeft: "48px", paddingTop: "24px" }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                  columnSpacing={2.5}
                >
                  <Grid item md={7}>
                    <Box sx={{ width: "100%" }}>
                      <BorderLinearProgress
                        variant="determinate"
                        color={"success"}
                        value={9}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={1}>
                    <StyledPercentageText>9%</StyledPercentageText>
                  </Grid>
                  <Grid item md={2}>
                    <ButtonControla
                      roles={[1, 7, 8]}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Proponer datos"}
                      // functionOnClick={()=>
                      //     window.location.href = '/committee/electionprocessstart'          }
                    />
                  </Grid>
                  <Grid item md={2}>
                    <StyledCancelButton>Anular proceso</StyledCancelButton>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        background: lilac, // use summary background color
                        '&:hover': {
                          background: 'success.dark', // use summary hover background
                         color: 'success.light', // use summary hover color
                        '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': 
                        { color: 'success.light' }                        

                      }
                    }}
                    >
                      <Typography>
                        Datos del proceso para elección del Comité
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <Grid
                          container
                          direction={"row"}
                          alignItems="center"
                          alignContent="center"
                          rowSpacing={1}
                          columnSpacing={2}
                          paddingTop="1em"
                        >
                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <InputControlaVertical
                              text={"Inicio del comité:"}
                              inputType={"date"}
                              inputValue={""}
                              value={""}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <InputControlaVertical
                              text={"Fin del comité:"}
                              inputType={"date"}
                              inputValue={""}
                              value={""}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <SelectControla
                              text="Tipo:"
                              inputValue={"setAreaWorkerInvolved"}
                              required
                              value={"areaWorkerInvolved"}
                              //   childrenRows={rowsAreaWorkerInvolved.map(
                              //     (rows) => (
                              //       <MenuItem
                              //         key={rows.id_areas}
                              //         value={rows.id_areas}
                              //       >
                              //         {rows.area}
                              //       </MenuItem>
                              //     )
                              //   )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <InputControlaVertical
                              text={"Observador del proceso:"}
                              inputType={"date"}
                              inputValue={""}
                              value={""}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <InputControlaVertical
                              text={"Total de miembros para el comite:"}
                              inputType={"number"}
                              inputValue={""}
                              value={""}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <InputControlaVertical
                              text={"Limite de fecha para propuestas:"}
                              inputType={"date"}
                              inputValue={""}
                              value={""}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <InputControlaVertical
                              text={
                                "Miembros titulares por parte del empleador:"
                              }
                              inputType={"number"}
                              inputValue={""}
                              value={""}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <InputControlaVertical
                              text={
                                "Miembros titulares por parte de los trabajadores:"
                              }
                              inputType={"number"}
                              inputValue={""}
                              value={""}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <InputControlaVertical
                              text={
                                "Miembros suplentes por parte del empleador:"
                              }
                              inputType={"number"}
                              inputValue={""}
                              value={""}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <InputControlaVertical
                              text={
                                "Miembros suplentes por parte de los trabajadores:"
                              }
                              inputType={"number"}
                              inputValue={""}
                              value={""}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <InputControlaVertical
                              text={"Cantidad de personeros por Candidato:"}
                              inputType={"number"}
                              inputValue={""}
                              value={""}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SelectControla
                              text="Modalidad:"
                              inputValue={"setAreaWorkerInvolved"}
                              required
                              value={"areaWorkerInvolved"}
                              //   childrenRows={rowsAreaWorkerInvolved.map(
                              //     (rows) => (
                              //       <MenuItem
                              //         key={rows.id_areas}
                              //         value={rows.id_areas}
                              //       >
                              //         {rows.area}
                              //       </MenuItem>
                              //     )
                              //   )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SelectControla
                              text="Tipo de Elección:"
                              inputValue={"setAreaWorkerInvolved"}
                              required
                              value={"areaWorkerInvolved"}
                              //   childrenRows={rowsAreaWorkerInvolved.map(
                              //     (rows) => (
                              //       <MenuItem
                              //         key={rows.id_areas}
                              //         value={rows.id_areas}
                              //       >
                              //         {rows.area}
                              //       </MenuItem>
                              //     )
                              //   )}
                            />
                          </Grid>

                          <Grid
                            container
                            direction={"row"}
                            alignContent="center"
                            justifyContent="center"
                            gap={2}
                            paddingTop={"2em"}
                          >
                            <Grid item md={2}>
                              <ButtonControla
                                roles={[1, 7, 8]}
                                backgroundColor={"#007bff"}
                                backgroundColorHover={"#007bff"}
                                textButton={"Avisar a trabajadores "}
                                // functionOnClick={()=>
                                //     window.location.href = '/committee/electionprocessstart'          }
                              />
                            </Grid>
                            <Grid item md={2}>
                              <ButtonControla
                                roles={[1, 7, 8]}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Guardar datos"}
                                // functionOnClick={()=>
                                //     window.location.href = '/committee/electionprocessstart'          }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </StyledAccordion>
                </Grid>
                <Grid item md={12}>
                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        backgroundColor: lilac,
                      }}
                    >
                      <Typography>Junta Electoral</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <Grid container direction={"row"} paddingTop="1em">
                          <Grid item md={12} paddingBottom="2em">
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                      <AddDateDialog />
                                    </TableCell>
                                    <TableCell>
                                      <StyledBlueButton
                                        variant="contained"
                                        startIcon={<Send />}
                                        disableElevation
                                      >
                                        Enviar propuestas
                                      </StyledBlueButton>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      sx={{ color: blue }}
                                      align="center"
                                    >
                                      Nombres
                                    </TableCell>
                                    <TableCell
                                      sx={{ color: blue }}
                                      align="center"
                                    >
                                      Documento de Identidad
                                    </TableCell>
                                    <TableCell
                                      sx={{ color: blue }}
                                      align="center"
                                    >
                                      Cargo de la junta
                                    </TableCell>
                                    <TableCell
                                      sx={{ color: blue }}
                                      align="center"
                                    >
                                      Estado
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      sx={{ color: purple }}
                                      align="center"
                                    >
                                      Nelson Heredia
                                    </TableCell>
                                    <TableCell
                                      sx={{ color: purple }}
                                      align="center"
                                    >
                                      0028463
                                    </TableCell>
                                    <TableCell
                                      sx={{ color: purple }}
                                      align="center"
                                    >
                                      Firma
                                    </TableCell>
                                    <TableCell
                                      sx={{ color: purple }}
                                      align="center"
                                    >
                                      Firma
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                          <Grid item md={12}>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead></TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left">
                                      <StyledTypography>
                                        Libro de elecciones
                                      </StyledTypography>
                                    </TableCell>

                                    <TableCell>
                                      <AddMemberDialog />
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell
                                      sx={{ color: blue }}
                                      align="center"
                                    >
                                      Título
                                    </TableCell>
                                    <TableCell
                                      sx={{ color: blue }}
                                      align="center"
                                    >
                                      Acciones
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      sx={{ color: purple }}
                                      align="center"
                                    >
                                      Nelson Heredia
                                    </TableCell>
                                    <TableCell
                                      sx={{ color: purple }}
                                      align="center"
                                    >
                                      0028463
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <Grid
                              container
                              direction={"row"}
                              alignContent="center"
                              justifyContent="center"
                              gap={2}
                              paddingTop={"2em"}
                            >
                              <Grid item md={"2"}>
                                <StyledBlueButton>
                                  Completar datos
                                </StyledBlueButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Typography>
                    </AccordionDetails>
                  </StyledAccordion>
                </Grid>
                <Grid item md={12}>
                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        backgroundColor: lilac,
                      }}
                    >
                      Cronograma
                      <Typography></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item md={12}>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead></TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <StyledTypography>
                                      Responsables del trabajo
                                    </StyledTypography>
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell>
                                    <StyledBlueButton
                                      variant="contained"
                                      startIcon={<Add />}
                                      disableElevation
                                    >
                                      Agregar datos
                                    </StyledBlueButton>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{ color: blue }}
                                    align="center"
                                  >
                                    Nombre del estado
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: blue }}
                                    align="center"
                                  >
                                    Fecha de Inicio
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: blue }}
                                    align="center"
                                  >
                                    Fecha de Fin
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: blue }}
                                    align="center"
                                  >
                                    Fecha de tolerancia
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: blue }}
                                    align="center"
                                  >
                                    Estado
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    sx={{ color: purple }}
                                    align="center"
                                  >
                                    Nelson Heredia
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: purple }}
                                    align="center"
                                  >
                                    0028463
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell>
                                    <StyledTealButton>Entrar</StyledTealButton>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>
                </Grid>
                <Grid item md={12}>
                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        backgroundColor: lilac,
                      }}
                    >
                      Selección de personeros
                      <Typography></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item md={12}>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead></TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <StyledTypography>
                                      Responsables del trabajo
                                    </StyledTypography>
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell>
                                    <StyledBlueButton
                                      variant="contained"
                                      startIcon={<Add />}
                                      disableElevation
                                    >
                                      Agregar datos
                                    </StyledBlueButton>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell
                                    sx={{ color: blue }}
                                    align="center"
                                  >
                                    Nombre completo
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: blue }}
                                    align="center"
                                  >
                                    Documento de Identidad
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: blue }}
                                    align="center"
                                  >
                                    Candidato
                                  </TableCell>
                                  <TableCell
                                    sx={{ color: blue }}
                                    align="center"
                                  >
                                    Estado
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    sx={{ color: purple }}
                                    align="center"
                                  ></TableCell>
                                  <TableCell
                                    sx={{ color: purple }}
                                    align="center"
                                  ></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>
                </Grid>
                <Grid item md={12}>
                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        backgroundColor: lilac,
                      }}
                    >
                      Resultados
                      <Typography></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item md={6}></Grid>
                        <Grid item md={6}>
                          <Grid container>
                            <Grid item>
                              <StyledTypography>
                                Necesitamos miembros titulares y miembros
                                suplentes, ambos por parte del trabajador para
                                conformar el comité.
                              </StyledTypography>
                            </Grid>
                            <Grid item>
                              <StyledTypography>
                                Por lo tanto los ganadores a miembros titulares
                                son:
                              </StyledTypography>
                            </Grid>
                            <Grid item md={12}>
                              <StyledTypography>
                                - Miembro titulares 1
                              </StyledTypography>
                            </Grid>
                            <Grid item md={12}>
                              <StyledTypography>
                                - Miembro titulares 2
                              </StyledTypography>
                            </Grid>
                            <Grid item md={12}>
                              <StyledTypography>
                                - Miembro titulares 3
                              </StyledTypography>
                            </Grid>
                            <Grid item md={12}>
                              <StyledTypography>
                                - Miembro titulares 4
                              </StyledTypography>
                            </Grid>
                            <Grid item>
                              <StyledTypography>
                                y como miembros suplentes son:
                              </StyledTypography>
                            </Grid>
                            <Grid item md={12}>
                              <StyledTypography>
                                - Miembro suplente 1
                              </StyledTypography>
                            </Grid>
                            <Grid item md={12}>
                              <StyledTypography>
                                - Miembro suplente 2
                              </StyledTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  paddingBottom="2em"
                  paddingTop="2em"
                >
                  <Grid item md={4}>
                    <StyledGreyButton startIcon={<DownloadIcon />}>
                      Exportar todo
                    </StyledGreyButton>
                  </Grid>
                  <Grid item md={4}>
                    <Stack spacing={2}>
                      <Pagination count={2} />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default ProcessStatus;
