import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import {
    Delete,
    Unarchive,
} from "@mui/icons-material";


import { useAppContext } from "../../../../../context/AppContext";

function ListArchived(props) {
    const {permissions} = useAppContext();
    const {
        rowsIpercSearchArchived,
        documentType,
        colorCell,
        handleOpenModalConfirm,
        handleClickOpenDelete
    } = props

    return (
        <TableBody
            sx={{ display: documentType !== 1 ? "none" : "" }}
        >
            {rowsIpercSearchArchived.map((rowsArchives) => (
                <TableRow
                    hover
                    key={rowsArchives.id_iperc}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >

                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {rowsArchives.name}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }} align="center">
                        {rowsArchives.area === null
                            ? "Sin Área"
                            : rowsArchives.area}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {rowsArchives.status}
                    </TableCell>
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                roles={permissions.DOC_HG_PROC_IPERC_ARCHI_VER}
                                icon={
                                    <VisibilityOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={rowsArchives.document_url}
                                directionTarget={"_blank"}
                            />
                            <ButtonIconControla
                                roles={permissions.DOC_HG_PROC_IPERC_ARCHI_DESARCHIVAR}
                                icon={<Unarchive sx={{ color: "white" }} />}
                                backgroundColor={"#F2994A"}
                                backgroundColorHover={"#FF881E"}
                                textTooltip={"Desarchivar"}
                                functionOnClick={() =>
                                    handleOpenModalConfirm(
                                        rowsArchives.id_iperc,
                                        "¿Estás seguro de desarchivar el documento?",
                                        '',
                                        "Si, desarchivar",
                                        "unarchive"
                                    )
                                }
                            />
                            <ButtonIconControla
                                roles={permissions.DOC_HG_PROC_IPERC_ARCHI_ELIMINAR}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={
                                    () =>
                                        handleClickOpenDelete(
                                            rowsArchives.id_iperc
                                        )
                                }
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}

export default ListArchived;