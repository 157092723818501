import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import {
    BorderColorOutlined,
    Delete,
    Archive,
    Send,
    Publish,
    Email,
  } from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useAppContext } from "../../../context/AppContext";
function ListModels(props) {
    const {permissions} = useAppContext();

    const {
        rowsRisstTemplate,
        colorCell,
        idUserLogged,
        handleOpenModalConfirm
    } = props

    return (
        <TableBody >
            {rowsRisstTemplate.map((row) => (
                <TableRow
                    hover
                    key={row.id}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell
                        sx={{ color: colorCell }}
                        component="th"
                        scope="row"
                    >
                        {row.title}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.document_text !== null ? "Sistema" : "Archivo"}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.register_status === 1 ? "Creado" : ""}
                        {row.register_status === 7
                            ? "Enviado a trabajadores"
                            : ""}
                        {row.register_status === 3
                            ? "Enviado a comité"
                            : ""}
                        {row.register_status === 4 ? "Publicado" : ""}
                        {row.register_status === 5 ? "Archivado" : ""}{" "}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {(row.register_status === 1 ||
                                row.register_status === 7 ||
                                row.register_status === 3) && (

                                <ButtonIconControla
                                    roles={row.id_users_approved === idUserLogged || row.id_users_develop === idUserLogged || row.id_users_revised === idUserLogged ? [] : permissions.DOC_RISST_MODEL_EDITAR}
                                    icon={ <BorderColorOutlined sx={{ color: "white" }}/>}
                                    backgroundColor={"#2D9CDB"}
                                    backgroundColorHover={"#33AEF4"}
                                    textTooltip={"Editar"}
                                    isNavegationOrigin={true}
                                    directionUrl={"/documentation/risst/template?idRisst=" + row.id}
                                />
                            )}

                            {row.register_status === 1 && (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_RISST_MODEL_ENVIA_TRABAJ}
                                        icon={<Send sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Enviar a trabajadores"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                row.id,
                                                "¿Estás seguro de enviar a trabajadores?",
                                                'Una vez enviado no podra revertir la operación del documento:  "' +
                                                row.title +
                                                '"',
                                                "Si, Enviar a trabajadores",
                                                "workersTemplate"
                                            );
                                        }}
                                    />
                                    <ButtonIconControla
                                        roles={permissions.DOC_RISST_MODEL_VER}
                                        icon={ <VisibilityOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        isNavegationOrigin={true}
                                        directionUrl={"/documentation/risst/template?idRisst=" + row.id +"&readOnly=true"}
                                    />
                                </>
                            )}

                            {row.register_status === 7 && (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_RISST_MODEL_ENVIA_COMIT}
                                        icon={<Send sx={{ color: "white" }} />}
                                        backgroundColor={"#581845"}
                                        backgroundColorHover={"#6D1F56"}
                                        textTooltip={"Enviar a comité"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                row.id,
                                                "¿Estás seguro de enviar al comité?",
                                                "Recuerda que para enviar al comité tienes que tener en cuenta las sugerencias de los trabajadores" +
                                                "",
                                                "Si, enviar a comité",
                                                "comiteTemplate"
                                            );
                                        }}
                                    />
                                    <ButtonIconControla
                                        roles={permissions.DOC_RISST_MODEL_VER_TRABAJ}
                                        icon={ <VisibilityOutlined sx={{ color: "white" }} /> }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        isNavegationOrigin={true}
                                        directionUrl={"/documentation/risst/template?idRisst=" + row.id +"&readOnly=true"}
                                    />
                                </>
                            )}

                            {row.register_status === 7 && (
                                <ButtonIconControla
                                    roles={permissions.DOC_RISST_MODEL_BUZON}
                                    icon={<Email sx={{ color: "white" }} />}
                                    backgroundColor={"#FFC300"}
                                    backgroundColorHover={"#FFC300"}
                                    textTooltip={"Buzón de participación"}
                                    directionUrl={"/mailbox"}
                                    directionTarget={"_blank"}
                                />
                            )}

                            {row.register_status === 3 && (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_RISST_MODEL_PUBLI}
                                        icon={<Publish sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Publicar"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                row.id,
                                                "¿Estás seguro de publicar?",
                                                "Ingresa en el documento la fecha del acta de comité en el cuál se aprueba el RISST"
                                                ,
                                                "Si, publicar",
                                                "publicTemplate"
                                            );
                                        }}
                                    />
                                    <ButtonIconControla
                                        roles={permissions.DOC_RISST_MODEL_VER_COMIT}
                                        icon={ <VisibilityOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        isNavegationOrigin={true}
                                        directionUrl={"/documentation/risst/template?idRisst=" + row.id +"&readOnly=true"}
                                    />
                                </>
                            )}

                            {row.register_status === 4 && (
                                <ButtonIconControla
                                    roles={[1, 7, 8]}
                                    icon={<Archive sx={{ color: "white" }} />}
                                    backgroundColor={"#F2994A"}
                                    backgroundColorHover={"#FF881E"}
                                    textTooltip={"Archivar"}
                                    functionOnClick={() =>
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de archivar el documento?",
                                            'Documento a archivar:  "' +
                                            row.title +
                                            '"',
                                            "Si, archivar",
                                            "archivedTemplate"
                                        )
                                    }
                                />
                            )}

                            {(row.register_status !== 7 &&
                                row.register_status !== 3 &&
                                row.register_status !== 4) && (
                                <ButtonIconControla
                                    roles={permissions.DOC_RISST_MODEL_ELIMINAR}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() => {
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de eliminar los datos del RISST?",
                                            "Una vez eliminado no se podra recuperar los datos de " +
                                            row.title,
                                            "Si, Eliminar",
                                            "deleteTemplate"
                                        );
                                    }}
                                />
                            )}
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )
}
export default ListModels;