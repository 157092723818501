import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import {
  Add,
  DeleteForever,
  Edit,
  PictureAsPdf
} from "@mui/icons-material";
import SubtitleText from "../../../../components/text/subtitleText";
import TitleText from "../../../../components/text/titleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import MyPetar from "../../../../api/MyPetar";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import LoadingControla from "../../../../components/load/loadingControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import SearchEppsControla from "../../../../components/search/searchEppsControla";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import MyAreas from "../../../../api/MyAreas";
import SelectControla from "../../../../components/selects/selectControla";
import { useAppContext } from "../../../../context/AppContext";

import ButtonSignatureControla from "../../../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const grey = "#9191B0";
const blue = "#034AFF";
const skyblue = "#2D9CDB";


const StyledTypography = styled(Typography)({
  color: "#305AD9",
  fontSize: 16,
  fontWeight: 600,
  gap: "2%",
  padding: "0.5em",
});

function EditPetar() {
  const { permissions } = useAppContext();

  const params = new URLSearchParams(window.location.search);
  const id_petar = params.get("id_petar");
  const readOnly  = params.get("readOnly");

  const [statusPetar, setStatusPetar] = useState(0);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [area, setArea] = useState("");
  const [place, setPlace] = useState("");
  const [hourStart, setHourStart] = useState("");
  const [hourFinish, setHourFinish] = useState("");
  const [description, setDescription] = useState("");
  const [tools, setTools] = useState("");
  const [procedure, setProcedure] = useState("");
  const [pdfGenerateUrl, setPdfGenerateUrl] = useState("");


  const [openModalCreateResponsible, setOpenModalCreateResponsible] = useState(false);
  const [openModalCreateAuthorization, setOpenModalCreateAuthorization] = useState(false);

  const [rowsResponsible, setRowsResponsible] = useState([]);
  const [observation, setObservation] = useState("");

  //////readonly control
  const [readOnlyStatus, setReadOnlyStatus] = useState(false);
  const [idStatuses, setIdStatuses] = useState(false);

  const [rowsAuthorizations, setRowsAuthorizations] = useState([]);
  const [rowsUsersNoResponsible, setRowsUsersNoResponsible] = useState([]);
  const [rowsUsersNoAuthorization, setRowsUsersNoAuthorization] = useState([]);

  const handleOpenModalCreateResponsible = () => {
    handleUsersNoResponsible();
    setOpenModalCreateResponsible(true);
  };
  const handleCloseModalCreateResponsible = () =>
    setOpenModalCreateResponsible(false);


  const handleOpenModalCreateAuthorization = () => {
    handleUsersNoAuthorizations();
    setOpenModalCreateAuthorization(true);
  };
  const handleCloseModalCreateAuthorization = () =>
    setOpenModalCreateAuthorization(false);

  const handleEditPetar = async () => {
    handleOpenLoading();

    if (Object.keys(permissions).length !== 0) {
      const response = await MyPetar.editPetar({ id_petar });

      if (response.success === false) {
        alert("Error");
      } else {
        setName(response.name);
        setCode(response.code);
        setArea(response.area);
        setPlace(response.place);
        setHourStart(response.start_time === null ? "" : response.start_time);
        setHourFinish(response.end_time === null ? "" : response.end_time);
        setDescription(response.description);
        setTools(response.tools);
        setProcedure(response.procedure);
        setObservation(response.observation);
        setIdStatuses(response.id_ats_statuses);
        setPdfGenerateUrl(response.pdf_generate_url);
        setStatusPetar(response.id_petar_statuses);
        if (response.id_petar_statuses === 3 || response.archived === 1 || response.id_petar_statuses === 2) {
          setReadOnlyStatus(true);
        }
        handleOpenAlert("", "success")
      }
      return response;
    }

  };

  const handleGeneratePDF = async () => {
    try {

      const response = await MyPetar.generatePDF({id_petar});
      const blob = await response.blob();

      // Crear un enlace temporal para descargar el archivo
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `petar-${id_petar}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch {
      handleOpenAlert('Hubo un error con la generacion del pdf', 'error');
    }
  };

  const handleUsersNoResponsible = async () => {
    const response = await MyPetar.petarNoResponsibleGet({
      id_petar: id_petar,
    });

    if (response.success !== false) {
      setRowsUsersNoResponsible(response);
    } else {
      alert("Error");
    }
    return response;
  };

  const handleUsersNoAuthorizations = async () => {
    const response = await MyPetar.getNoAuthorizationUsers({
      idPetar: id_petar,
    });
    if (response.success !== false) {
      setRowsUsersNoAuthorization(response);
    } else {
      alert("Error");
    }
    return response;
  };

  const handleDeletePetarResponsible = async (id_petar_responsible) => {
    handleOpenLoading();

    const response = await MyPetar.deletePetarResponsible({
      id_petar_responsible,
    });
    if (response.success === false) {
      handleOpenAlert("Ocurrió un error al eliminar responsable");
    } else {
      await handlePetarResponsible();
      handleOpenAlert("Se eliminó responsable con éxito");
    }
    return response;
  };

  const handleDeletePetarAuthorization = async (id_petar_authorizations) => {
    handleOpenLoading();
    const response = await MyPetar.deleteAuthorizations({
      idPetarAuthorizations: id_petar_authorizations,
    });
    if (response.success === false) {
      handleOpenAlert("Ocurrió un error al eliminar supervisor", "error");
    } else {
      await handlePetarAuthorizations();
      handleOpenAlert("Se eliminó supervisor con éxito", "success");
    }
  };

  const handlePetarResponsible = async () => {
    const response = await MyPetar.petarResponsibleGet({ id_petar });

    if (response.success === false) {
      alert("Error");
    } else {
      setRowsResponsible(response);
    }
    return response;
  };

  const handlePetarAuthorizations = async () => {
    const response = await MyPetar.getAuthorizations({ idPetar: id_petar });

    if (response.success === false) {
      alert("Error");
    } else {
      setRowsAuthorizations(response);
    }
    return response;
  };

  const handlePostCreatePetarResponsible = async (id_users) => {
    handleOpenLoading();

    const response = await MyPetar.createPetarResponsible({
      id_petar,
      id_users,
    });

    if (response.success === false) {
      alert("Error");
      handleOpenAlert("Ocurrió un error al agregar responsable", "error");
    } else {
      await handlePetarResponsible();
      handleOpenAlert("Se agregó responsable con éxito");
    }

    handleCloseModalCreateResponsible();
    return response;
  };

  const handlePostCreatePetarAuthorization = async (id_users) => {
    handleOpenLoading();

    const response = await MyPetar.storeAuthorizations({
      idPetar: id_petar,
      idUsers: id_users,
    });

    if (response.success === false) {
      alert("Error");
      handleOpenAlert("Ocurrió un error al agregar supervisor", "error");
    } else {
      await handlePetarAuthorizations();
      handleOpenAlert("Se agregó supervisor con éxito", "success");
    }
    handleCloseModalCreateAuthorization();
  };


  ////////////////EPP

  const [openModalEpps, setOpenModalEpps] = useState(false);
  const [rowsEpps, setRowsEpps] = useState([]);

  const [rowsEppsPetar, setRowsEppsPetar] = useState([]);

  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [handleActionConfirm, setHandleActionConfirm] = useState();
  const [rowsAreaWorkerInvolved, setRowsAreaWorkerInvolved] = useState([]);

  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setRowsAreaWorkerInvolved(response);
    return response;
  };

  const handleOpenModalEpps = async() => {
    handleOpenLoading();
    await handlePetarNoEpps();
    setOpenModalEpps(true);
    setOpenLoading(false);
  };

  const handleCloseModalEpps = () => {
    setOpenModalEpps(false);
  };

  const handleSelectEpps = async (idEpps) => {
    handleOpenLoading();
    const response = await MyPetar.eppsStore({ id_petar, id_epps: idEpps });

    if (response.success !== false) {
      await handlePetarNoEpps();
      await handlePetarEpps();
      handleOpenAlert(response.msg, "success");
      setRowsEpps([]);
      setOpenModalEpps(false);
    } else {
      handleOpenAlert("Hubo un error", "error");
      setOpenModalEpps(false);
    }
  };

  const handleSelectDeleteEpps = async (idPetarEpps) => {
    handleOpenLoading();
    const response = await MyPetar.eppsDelete({ id_petar_epps: idPetarEpps });

    if (response.success !== false) {
      setRowsEpps([]);
      setOpenModalEpps(false);
      handlePetarNoEpps();
      handlePetarEpps();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Hubo un error", "error");
    }
    return response;
  };

  const handlePetarNoEpps = async () => {
    const response = await MyPetar.eppsNoPetarGet({ id_petar });

    if (response.success === false) {
      alert("Error");
    } else {
      setRowsEpps(response);
    }
    return response;
  };

  const handlePetarEpps = async () => {
    const response = await MyPetar.eppPetarGet({ id_petar });

    if (response.success === false) {
      alert("Error listando los epps");
    } else {
      setRowsEppsPetar(response);
    }
    return response;
  };

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  ///////////////EPP

  //////////////FIRMA

  const signAuthorization = async (idPetarAuthorizations) => {
    handleOpenLoading();

    const response = await MyPetar.signAuthorization({
      idPetarAuthorizations: idPetarAuthorizations,
    });

    if(response.success){
      await handlePetarAuthorizations();
      handleOpenAlert("Petar firmado con éxito", "success");
    }else{
      handleOpenAlert("Ocurrió un error al firmar", "error");
    }
  };

  const signResponsible = async (idPetarResponsible) => {
    handleOpenLoading();

    const response = await MyPetar.signResponsibleEnd({
      idPetarResponsible: idPetarResponsible,
    });
    if(response.success){
      await handlePetarResponsible();
      handleOpenAlert("Responsable ha firmado con éxito", "success");
    }else{
      handleOpenAlert("Ocurrió un error al firmar el responsable", "error");
    }

  };

  const signResponsibleStart = async (idPetarResponsible) => {
    handleOpenLoading();

    const response = await MyPetar.signResponsibleStart({
      idPetarResponsible: idPetarResponsible,
    });

    if(response.success){
      await handlePetarResponsible();
      handleOpenAlert("Responsable ha firmado con éxito", "success");
    }else{
      handleOpenAlert("Ocurrió un error al firmar el responsable","error");
    }
  };

  //////////////FIRMA
  useEffect(() => {
    handlePetarResponsible();
    handlePetarAuthorizations();
    handlePetarEpps();
    handleGetAreas();
  }, []);

  useEffect(()=>{
    handleEditPetar();
  },[permissions])

  const handlePutUpdatePetar = async () => {
    handleOpenLoading()
    const response = await MyPetar.updatePetar({
      id_petar,
      name,
      code,
      area,
      place,
      start_time: hourStart,
      end_time: hourFinish,
      description,
      observation,
      tools,
      procedure,
    });
    if (response.success !== false) {
      await handleEditPetar();
      handleOpenAlert("Se actualizó Petar con éxito", "success");
    } else {
      handleOpenAlert("Hubo un error al actualizar", "success");
    }
  };

  return (
    <>
      <Box sx={{ height: "100%" }}>

        <ReturnBack returnBack= "/documentation/documents/procedures/petar?idTabs=1"/>

        <LoadingControla
          openLoading={openLoading}
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          messageAlert={messageAlert}
          typeAlert={typeAlert}
        />

        <DialogConfirmControla
          openModalConfirm={openModalConfirm}
          handleCloseModalConfirm={handleCloseModalConfirm}
          titleText={titleText}
          contentText={contentText}
          buttonTextConfirm={buttonTextConfirm}
          handleActionConfirm={handleActionConfirm}
        />

        <SearchEppsControla
          openModalEpps={openModalEpps}
          handleCloseModalEpps={handleCloseModalEpps}
          handleClickSelectEpps={handleSelectEpps}
          rowsEpps={rowsEpps}
        />

        <Container sx={{ paddingBottom: 4 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              alignItems="center"
            >
              <Grid item md={12}>
                <SubtitleText
                  text={"Procedimientos"}
                  color={grey}
                ></SubtitleText>
              </Grid>
              <Grid item md={10}>
                <TitleText
                  text={"Permiso Escrito de trabajo de alto riesgo (PETAR) "}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                <CompanyLogoControla />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={9} xl={9}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETAR_ACTI_EDITAR}
                  text={"Nombre del PETAR"}
                  inputType={"text"}
                  inputValue={setName}
                  modalType={false}
                  value={name}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETAR_ACTI_EDITAR}
                  text={"Código"}
                  inputType={"text"}
                  inputValue={setCode}
                  modalType={false}
                  value={code}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <SelectControla
                  roles={permissions.DOC_HG_PROC_PETAR_ACTI_EDITAR}
                  text="Área:"
                  inputValue={setArea}
                  modalType={false}
                  required
                  value={area}
                  childrenRows={rowsAreaWorkerInvolved.map((rows) => (
                    <MenuItem key={rows.id_areas} value={rows.id_areas}>
                      {rows.area}
                    </MenuItem>
                  ))}
                />

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETAR_ACTI_EDITAR}
                  text={"Lugar"}
                  inputType={"text"}
                  inputValue={setPlace}
                  modalType={false}
                  value={place}
                />

              </Grid>
              {/* SOLO ENVIADOS*/}

              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETAR_ACTI_HORA_DE_INICIO}
                  text={"Hora de Inicio"}
                  inputType={"time"}
                  inputValue={setHourStart}
                  modalType={false}
                  value={hourStart}
                />

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETAR_ACTI_HORA_DE_FIN}
                  text={"Hora de Fin"}
                  inputType={"time"}
                  inputValue={setHourFinish}
                  modalType={false}
                  value={hourFinish}
                />
              </Grid>
              {/* SOLO ENVIADOS*/}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETAR_ACTI_EDITAR}
                  text={"Descripción del Trabajo"}
                  inputType={"textArea"}
                  inputValue={setDescription}
                  modalType={false}
                  value={description}
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <StyledTypography
                  sx={{
                    padding: "8px",
                    fontSize: "20px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del trabajo
                </StyledTypography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                {readOnlyStatus == false && (
                  <ButtonControla
                    roles={permissions.DOC_HG_PROC_PETAR_ACTI_AGREGAR_RESPONSABLE}
                    textButton={"Agregar Responsable"}
                    iconButton={<Add />}
                    backgroundColor={skyblue}
                    backgroundColorHover={"#58C0FB"}
                    functionOnClick={() => handleOpenModalCreateResponsible()}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: blue }} align="center">
                          Cargo
                        </TableCell>
                        <TableCell sx={{ color: blue }} align="center">
                          Nombres
                        </TableCell>
                        <TableCell sx={{ color: blue }} align="center">
                          Firma Inicio
                        </TableCell>
                        <TableCell sx={{ color: blue }} align="center">
                          Firma Final
                        </TableCell>
                        <TableCell sx={{ color: blue }} align="center">
                          Acciones
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsResponsible.map((rowsResponsible) => (
                        <TableRow
                          hover
                          key={rowsResponsible.id_petar_responsible}
                        >
                          <TableCell align="center">
                            {rowsResponsible.position_name}
                          </TableCell>
                          <TableCell align="center">
                            {rowsResponsible.first_name +
                              " " +
                              rowsResponsible.last_name}
                          </TableCell>
                          <TableCell align="center">
                            <ButtonSignatureControla
                               idUser={rowsResponsible.id_users}
                               firmStatus={rowsResponsible.firm_start}
                               firmUrl={rowsResponsible.firm_start_url}
                               functionOnClick={ async() =>
                                await signResponsibleStart(rowsResponsible.id_petar_responsible)
                               }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <ButtonSignatureControla
                               idUser={rowsResponsible.id_users}
                               firmStatus={rowsResponsible.firm_end}
                               firmUrl={rowsResponsible.firm_end_url}
                               functionOnClick={ async() =>
                                await signResponsible(rowsResponsible.id_petar_responsible)
                               }
                            />
                          </TableCell>
                          <TableCell align="center">
                            {readOnlyStatus == false && (
                              <ButtonControla
                              roles={[1, 7, 8]}
                                textButton={"Eliminar"}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                iconButton={<DeleteForever />}
                                functionOnClick={() =>
                                  handleDeletePetarResponsible(
                                    rowsResponsible.id_petar_responsible
                                  )
                                }
                              ></ButtonControla>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <StyledTypography
                  sx={{
                    padding: "8px",
                    fontSize: "20px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  EPPS
                </StyledTypography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                {readOnlyStatus == false && (
                  <ButtonControla
                    roles={permissions.DOC_HG_PROC_PETAR_ACTI_AGREGAR_EPPS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar EPP"}
                    functionOnClick={handleOpenModalEpps}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper sx={{ overflow: "hidden" }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ width: "100%", margin: "auto" }}
                  >
                    {rowsEppsPetar.map((rowsEpps) => (
                      <Grid
                        item
                        key={rowsEpps.id_epps}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                        sx={{ p: 2 }}
                      >
                        <Card
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: 80,
                            "&:hover": {
                              cursor: "pointer",
                              border: "1px solid #0F1255",
                              boxShadow: "0px 4px #0F1255",
                            },
                          }}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <Typography sx={{ fontSize: "14px" }}>
                                {rowsEpps.epp}
                              </Typography>
                            </CardContent>
                          </Box>
                          <CardMedia
                            component="img"
                            sx={{
                              maxWidth: 80,
                              marginLeft: 0,
                              maxHeight: 80,
                              objectFit: "contain",
                            }}
                            image={rowsEpps.photo_url}
                            alt="Live from space album cover"
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETAR_ACTI_EDITAR}
                  text={"Herramientas, equipo y material:"}
                  inputType={"textArea"}
                  inputValue={setTools}
                  modalType={false}
                  value={tools}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETAR_ACTI_EDITAR}
                  text={"Procedimiento:"}
                  inputType={"textArea"}
                  inputValue={setProcedure}
                  modalType={false}
                  value={procedure}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETAR_ACTI_HORA_DE_INICIO}
                  text={"Observaciones:"}
                  inputType={"textArea"}
                  inputValue={setObservation}
                  modalType={false}
                  value={observation}
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <StyledTypography
                  sx={{
                    padding: "8px",
                    fontSize: "20px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Autorización y supervisión
                </StyledTypography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                {readOnlyStatus == false && (
                  <ButtonControla
                    roles={ permissions.DOC_HG_PROC_PETAR_ACTI_AGREGAR_SUPERVISOR}
                    iconButton={<Add />}
                    backgroundColor={skyblue}
                    textButton="Agregar Supervisor"
                    backgroundColorHover={"#58C0FB"}
                    functionOnClick={() => handleOpenModalCreateAuthorization()}
                  />
                )}
              </Grid>
              <Grid item md={12}>
                <Paper sx={{ overflow: "hidden" }}>
                  <TableContainer sx={{ minWidth: 650 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ color: blue }} align="center">
                            Cargo
                          </TableCell>
                          <TableCell sx={{ color: blue }} align="center">
                            Nombre
                          </TableCell>
                          <TableCell sx={{ color: blue }} align="center">
                            Firma
                          </TableCell>
                          <TableCell sx={{ color: blue }} align="center">
                            Fecha de firma
                          </TableCell>
                          <TableCell sx={{ color: blue }} align="center">
                            Acciones
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowsAuthorizations.map((rowsAuthorizations) => (
                          <TableRow
                            hover
                            key={rowsAuthorizations.id_petar_authorizations}
                          >
                            <TableCell align="center">
                              {rowsAuthorizations.position_name}
                            </TableCell>
                            <TableCell align="center">
                              {rowsAuthorizations.first_name +
                                " " +
                                rowsAuthorizations.last_name}
                            </TableCell>
                            <TableCell align="center">                             
                              <ButtonSignatureControla
                                 idUser={rowsAuthorizations.id_users}
                                 firmStatus={rowsAuthorizations.firm}
                                 firmUrl={rowsAuthorizations.firm_url}
                                 functionOnClick = { () =>
                                  signAuthorization(
                                    rowsAuthorizations.id_petar_authorizations
                                  )
                                 }
                              />

                            </TableCell>
                            <TableCell align="center">
                              {rowsAuthorizations.firm_date !== null
                                ? rowsAuthorizations.firm_date.substring(0, 10)
                                : "Sin fecha"}
                            </TableCell>
                            <TableCell align="center">
                              {readOnlyStatus == false && (
                                <ButtonControla
                                  roles={permissions.DOC_HG_PROC_PETAR_ACTI_ELIMINAR_SUPERVISOR}
                                  textButton={"Eliminar"}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  iconButton={<DeleteForever />}
                                  functionOnClick={() =>
                                    handleDeletePetarAuthorization(
                                      rowsAuthorizations.id_petar_authorizations
                                    )
                                  }
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                  <Box>
                    <ButtonControla
                      backgroundColor={"#CBCBFF"}
                      textButton={"Regresar"}
                      backgroundColorHover={"#CBCBFF"}
                      typeButton={"button"}
                      isNavegationOrigin={true}
                      url={"/documentation/documents/procedures/petar?idTabs=1"}
                    />
                  </Box>
                  <Box>

                    { statusPetar == 3 && (
                      <ButtonControla
                        iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                        backgroundColor={"#F2994A"}
                        backgroundColorHover={"#FF881E"}
                        textButton={"Ver documento generado"}
                        typeButton={"button"}
                        functionOnClick={handleGeneratePDF}
                        target="_blank"
                      />
                    )}
                    {/* <ButtonControla
                                                iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                                                backgroundColor={"#F2994A"}
                                                backgroundColorHover={"#FF881E"}
                                                textButton={"Generar PDF"}
                                                typeButton={'button'}
                                                functionOnClick={() => handleGeneratePDF}
                                            />) : '' */}
                    {/* } */}
                  </Box>
                  {readOnly !== "true" && (
                    <Box>
                      <ButtonControla
                        roles={permissions.DOC_HG_PROC_PETAR_ACTI_ACTUALIZAR_PETAR}
                        iconButton={<Edit sx={{ color: "white" }} />}
                        backgroundColor={"#169073"}
                        backgroundColorHover={"#1BAA88"}
                        textButton={"Actualizar PETAR"}
                        typeButton={"button"}
                        functionOnClick={handlePutUpdatePetar}
                      />
                    </Box>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <Dialog
              open={openModalCreateResponsible}
              onClose={handleCloseModalCreateResponsible}
              maxWidth="sm"
              fullWidth={true}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla
                titleText="Agregar Responsable"
                functionOnClose={handleCloseModalCreateResponsible}
              />
              <DialogContent>
                <SearchUsersControla
                  rowsUser={rowsUsersNoResponsible}
                  handleUsersActionButton={handlePostCreatePetarResponsible}
                />
              </DialogContent>
              <DialogActions>
                <Stack direction={"row"} spacing={2}>
                  <Box>
                    <ButtonControla
                      backgroundColor={"#CBCBFF"}
                      textButton={"Cancelar"}
                      backgroundColorHover={"#CBCBFF"}
                      typeButton={"button"}
                      functionOnClick={handleCloseModalCreateResponsible}
                    />
                  </Box>
                </Stack>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openModalCreateAuthorization}
              onClose={handleCloseModalCreateAuthorization}
              maxWidth="sm"
              fullWidth={true}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla
                titleText="Agregar Supervisor"
                functionOnClose={handleCloseModalCreateAuthorization}
              />
              <DialogContent>
                <SearchUsersControla
                  rowsUser={rowsUsersNoAuthorization}
                  handleUsersActionButton={handlePostCreatePetarAuthorization}
                />
              </DialogContent>
              <DialogActions>
                <Stack direction={"row"} spacing={2}>
                  <Box>
                    <ButtonControla
                      backgroundColor={"#CBCBFF"}
                      textButton={"Cancelar"}
                      backgroundColorHover={"#CBCBFF"}
                      typeButton={"button"}
                      functionOnClick={handleCloseModalCreateAuthorization}
                    />
                  </Box>
                </Stack>
              </DialogActions>
            </Dialog>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default EditPetar;
