import {
    Card,
    CardActionArea,
    CardContent,
    Typography,
  } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";


function BannerHomeButtonControla({
  color1,
  color2,
  image,
  title = null,
  subtitle,
  url,
  functionClick
}) {
    return (
        <Card
            sx={{
                width: "100%",
                height: "154px",
                borderRadius: "16px",
                background: `linear-gradient(90deg, ${color2}, ${color1})`,
                backgroundSize: "100%",
                opacity: "0.85",
                color: "white",
                position: "relative",
                fontSize: "90%",
            }}
        >
            <CardActionArea
                href={url}
                sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: title !== null ? "flex-end" : "center",
                    justifyContent: "center",
                }}
                onClick={functionClick}
            >
                <CardContent
                    sx={{
                        width: "90%",
                        display: "flex",
                        flexDirection: "column",
                        padding: '0',
                    }}
                >
                    {
                        (title !== null) && (
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="span"
                                sx={{
                                    fontWeight: "bold",
                                    width: "100%",
                                    padding: '10px 10px 0 20px'
                                }}
                            >
                                {title}
                            </Typography>
                        )
                    }

                    <Typography
                        gutterBottom
                        variant="h6"
                        component="span"
                        sx={{
                            padding: '0 10px 10px 20px'
                        }}
                    >
                        {subtitle}
                    </Typography>

                </CardContent>

                <Box component="img" src={image} alt={"Imagen no encontrada"} sx={{ height: "90%", width: "25%" }} />
            </CardActionArea>
        </Card>
    );
}

export default BannerHomeButtonControla;