import MyConfigurationApi from "./MyConfigurationApi";

function getLogsAllCompany() {
    const url = "tracking/memberships";
    return MyConfigurationApi.connectFormApiGeneral({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function getLogsByIdCompany (idCompany, startDate, endDate, page) {
    const url = `log/${idCompany}/${startDate}/${endDate}?page=${page}`;
    return MyConfigurationApi.connectFormApiGeneral({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

const MyTracking ={
    getLogsAllCompany,
    getLogsByIdCompany

}

export default MyTracking;