import { React, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Grid, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@mui/material';
import Container from '@mui/material/Container';

import { VisibilityOutlined } from '@mui/icons-material';

import SubtitleText from '../../components/text/subtitleText';
import TitleText from '../../components/text/titleText';
import CompanyLogoControla from '../../components/images/companyLogoControla';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import MyTrainings from '../../api/MyTrainings';
import ReturnBack from '../../components/buttons/returnBackControla';
import { Link } from 'react-router-dom';
import LoadingControla from '../../components/load/loadingControla';


const grey = "#9191B0";
const blue = "#034AFF";

function Materials() {

    const params = new URLSearchParams(window.location.search);
    const id_trainings = params.get('id_trainings');

    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert('');
        setTypeAlert('success');
    }

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    // Pantallas de carga y alertas FIN

    const [rowMaterials, setRowMaterials] = useState([]);
    const handleGetMaterials = async () => {
        handleOpenLoading();

        const response = await MyTrainings.getMaterials({ id_trainings: id_trainings });
        setRowMaterials(response);

        setOpenLoading(false);
    }

    useEffect(() => {
        handleGetMaterials();
    }, []);


    return (
        <>
            <Box sx={{ height: "100%" }}>
                <ReturnBack returnBack='/trainings/personal'  />

                <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

                <Container sx={{ paddingBottom: 4 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                            <Grid item md={10}>
                                <SubtitleText text={"Capacitaciones"} color={grey}></SubtitleText>
                                <TitleText text={"Materiales"} />
                            </Grid>
  
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                                <CompanyLogoControla />
                            </Grid>
                        </Grid>
                        <Stack
                            direction="row"
                            justifyContent={"space-between"}
                            alignItems="center"
                            sx={{ mt: .5, mb: 2 }}
                        >
                            <Tabs value={1} aria-label="Tipos" >
                                <Link to={"/trainings/personal/edit?id_trainings=" + id_trainings}><Tab label="Detalles"/></Link>
                                <Link to={"/trainings/personal/materials?id_trainings=" + id_trainings}><Tab label="Materiales"/></Link>
                                <Link to={"/trainings/personal/exams?id_trainings=" + id_trainings}><Tab label="Examenes"/></Link>
                            </Tabs>
                        </Stack>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper sx={{ overflow: "hidden" }}>
                                    <TableContainer>
                                        <Table
                                            stickyHeader
                                            sx={{ width: "100%", minWidth: "700px" }}
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Nombre
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Acciones
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowMaterials.map((row, key) => (
                                                    <TableRow
                                                        hover
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                        }}
                                                        key={key}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {row.material}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                            >
                                                                <ButtonIconControla
                                                                    roles={[1, 7, 8, 9]}
                                                                    icon={
                                                                        <VisibilityOutlined sx={{ color: "white" }} />
                                                                    }
                                                                    backgroundColor={"#305AD9"}
                                                                    backgroundColorHover={"#0E4AFF"}
                                                                    textTooltip={"Ver"}
                                                                    directionUrl={row.material_url}
                                                                    directionTarget={'blank'}
                                                                />
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

            </Box >
        </>
    )
}

export default Materials