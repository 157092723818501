import { Close } from "@mui/icons-material";
import { DialogTitle, IconButton, Typography, Box, Stack } from "@mui/material";
import { } from "@mui/system";
import { React } from "react";




function DialogTitleControla({ titleText = '', functionOnClose = '' , image = null }) {

    return (
        <DialogTitle sx={{ paddingBottom: '10px' }}>
            <Stack direction="row" spacing={2} alignContent={"center"}>
                {
                    (image !== null) && (
                     <Box component="img" src={image} alt={"Imagen no encontrada"} sx={{ height: "50px", width: "auto" }} />
                    )
                }
                
                <Box sx={{ alignContent: "center" }}>
                    <Typography
                        sx={{
                            fontSize: '24px',
                            color: "#1638F2",
                            fontWeight: "bold",
                        }}
                    >
                        {titleText}
                    </Typography>

                </Box>

                <IconButton
                    aria-label="close"
                    onClick={functionOnClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>

            </Stack>
        </DialogTitle>
    );
}

export default DialogTitleControla;