import { React, useState, useEffect } from 'react';

import SubtitleText from '../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { Grid, Button, FormControl, Typography, Divider, InputLabel, MenuItem, DialogActions, InputBase, Autocomplete, TextField } from '@mui/material';
import { Add, ArrowBackIosNew, ArrowForwardIos, ArrowRight, Delete, DriveFileRenameOutlineOutlined, Search, } from '@mui/icons-material';
import TitleText from '../../components/text/titleText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import CompanyLogoControla from '../../components/images/companyLogoControla';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import ButtonControla from '../../components/buttons/buttonController';
import SelectYesNotControla from '../../components/selects/selectYesNotControla';
import MyCompanies from '../../api/MyCompanies';
import SelectControla from '../../components/selects/selectControla';
import SearchUsersControla from '../../components/search/searchUsersControla';
import MyUsers from '../../api/MyUsers';

const colorTitle = "#9191B0";
const blue = "#034AFF";
const green = "#2C8C75";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";

function Certifications() {
    const [openModalCreateCertifications, setOpenModalCreateCertifications] = useState(false);
    const [certifications, setCertifications] = useState('0');

    const handleOpenModalCreateCertifications = () => {
        handleGetCompaniesNotCertifications();
        setCertifications('');
        setOpenModalCreateCertifications(true);
    }

    const [rowsCertifications, setRowsCertifications] = useState([]);

    const [rowsNotCertifications, setRowsNotCertifications] = useState([]);


    const handleGetCompaniesCertifications = async () => {
        const response = await MyCompanies.certificationsCompanies();
        if (response.success !== false) {
            setRowsCertifications(response);
        } else {
            alert("Error al listar la los certificados!");
        }
    }

    const handleGetCompaniesNotCertifications = async () => {
        const response = await MyCompanies.certificationsNotCompanies();
        if (response.success !== false) {
            setRowsNotCertifications(response);
        } else {
            alert("Error al listar la los certificados!");
        }
    }

    useEffect(() => {
        handleGetCompaniesCertifications();
    }, []);

    const handleStoreCompaniesCertifications = async (event) => {
        event.preventDefault();
        const response = await MyCompanies.storeCertificationsCompanies({ id_companies_certifications: certifications });
        if (response.success !== false) {
            handleGetCompaniesCertifications();
            setOpenModalCreateCertifications(false);
        } else {
            alert("Error al listar la Los regimen!");
        }
    }

    const handleDeleteCompaniesCertifications = async (id_companies_certifications_rels_companies) => {
        const response = await MyCompanies.deleteCertificationsCompanies({ id_companies_certifications_rels_companies: id_companies_certifications_rels_companies });
        if (response.success !== false) {
            handleGetCompaniesCertifications();
            alert(response.msg);
        } else {
            alert("Error al listar la Los regimen!");
        }
    }

    return (

        <Box sx={{ display: "flex", height: '100%' }}>

            <Container>

                <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
                            <SubtitleText text={"Configuración"} color={colorTitle} />
                            <TitleText text={"Mis certificaciones"} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                            <CompanyLogoControla />
                        </Grid>
                    </Grid>
                    <Box sx={{ marginTop: 3 }}>
                        <Stack
                            direction="row"
                            justifyContent={"space-between"}
                            sx={{ marginTop: 3, marginBottom: 1 }}
                        >
                            <Box>
                                {/* <SubtitleText text={"Datos del trabajador"} color={blue} /> */}
                                <InputLabel
                                    sx={{
                                        padding: "8px",
                                        fontSize: "18px",
                                        color: "#305AD9",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Certificaciones
                                </InputLabel>
                            </Box>
                            <Box>
                                <ButtonControla
                                    iconButton={<Add sx={{ color: "white" }} />}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={"Agregar certificado"}
                                    functionOnClick={handleOpenModalCreateCertifications}
                                />
                            </Box>
                        </Stack>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                color: "#305ad9",
                                                fontSize: "16px",
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Certificados
                                        </TableCell>
                                        <TableCell >
                                        </TableCell>

                                    </TableRow>
                                </TableHead>

                                <TableBody
                                >
                                    {rowsCertifications
                                        .map((row) => (
                                            <TableRow
                                                hover
                                                key={row.id_companies_certifications_rels_companies}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >

                                                <TableCell component="th" scope="row">
                                                    {row.certification}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="end"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <ButtonIconControla
                                                            icon={<Delete sx={{ color: "white" }} />}
                                                            backgroundColor={"#EB5757"}
                                                            backgroundColorHover={"#FF4040"}
                                                            textTooltip={"Eliminar"}
                                                            functionOnClick={
                                                                () =>
                                                                    handleDeleteCompaniesCertifications(
                                                                        row.id_companies_certifications_rels_companies
                                                                    )
                                                            }
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    <Stack direction={'row'} spacing={2} justifyContent="start" sx={{ marginTop: 3 }}>
                        <Box >
                            <ButtonControla textButton={'Cancelar'} backgroundColor={'#CBCBFF'} backgroundColorHover={'#d6d6ff'} />
                        </Box>
                        <Box >
                            <ButtonControla iconButton={<ArrowRight />} textButton={'Continuar'} backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"} typeButton={'submit'} />
                        </Box>
                    </Stack>
                </Box>

                <Dialog
                    open={openModalCreateCertifications}
                    onClose={() => { setOpenModalCreateCertifications(false); }}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitle>
                        <Typography
                            id="modal-modal-title"
                            sx={{
                                color: "#1638F2",
                                fontWeight: "bold",
                                fontSize: "22px",
                            }}
                        >
                            Agregar certificaciones
                        </Typography>
                    </DialogTitle>
                    <form onSubmit={handleStoreCompaniesCertifications}>
                        <DialogContent sx={{ paddingTop: "0" }}>
                            <SelectControla
                                text={"Certificados:"}
                                inputValue={setCertifications}
                                modalType={true}
                                required
                                value={certifications}
                                childrenRows={rowsNotCertifications.map((row) => (
                                    <MenuItem key={row.id_companies_certifications}
                                        value={row.id_companies_certifications}
                                    >
                                        {row.certification}
                                    </MenuItem>
                                ))}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => { setOpenModalCreateCertifications(false); }}
                            >
                                Cancelar
                            </Button>

                            <Button
                                type='submit'
                            >
                                Agregar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

            </Container>

        </Box>

    );

}

export default Certifications;

