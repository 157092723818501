import { InfoOutlined } from "@mui/icons-material";
import { Grid, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { React, useState } from "react";
import ValidationEditByRoles from "../../utils/validation_edit_roles";

function SelectControla(props) {

    const { disabled = false ,text, inputValue, modalType = false, value = undefined, textTooltip = '', childrenRows,roles=[],readOnly=false, ...other } = props;

    const isEdit = roles.length == 0 ? true : ValidationEditByRoles(roles);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} disabled={disabled}>
            { text !== undefined ? (
                <InputLabel
                sx={{
                    padding: '8px',
                    fontSize: '18px',
                    color: '#305AD9',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center'
                }}> 
                    {text}

                    {textTooltip && (
                        <Tooltip title={textTooltip} placement="top">
                            <InfoOutlined sx={{
                                display: (textTooltip == '' ? 'none' : 'block'),
                                color: "white",
                                backgroundColor: '#FFC300',
                                marginLeft: '10px',
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                "&:hover": {
                                    backgroundColor: '#FACB32',
                                    boxShadow:
                                        "rgba(50, 50, 93, 0.25) 0px 6px 12px 1px, rgba(0, 0, 0, 0.3) 0px 3px 7px 1px",
                                },
                            }} />
                        </Tooltip>
                    )}
                </InputLabel>

            ):""
            }
                
                <Select
                    size="small"
                    value={(value === undefined || value === null ? undefined : value)}
                    displayEmpty
                    onChange={(event) => { inputValue(event.target.value) }}
                    sx={{
                        fontFamily: 'Roboto',
                        width: "100%",
                        backgroundColor: (modalType === true ? '#F5F5F5' : '#FFFFFF'),
                        height: "42px",
                        color: '#305AD9',
                    }}
                    {...other}
                    readOnly={isEdit?(readOnly? true : false):true}
                >
                    {childrenRows}
                </Select>

            </Grid>
        </Grid >
    );
}

export default SelectControla;
