import { React, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  Check,
  Delete,
  InfoOutlined,
  PlayArrow,
  Upload,
} from "@mui/icons-material";

import SubtitleText from "../../../components/text/subtitleText";
import BannerControla from "../../../components/cards/bannerControla";
import ButtonControla from "../../../components/buttons/buttonController";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import SearchButtonControla from "../../../components/search/searchButtonControla";
import MyTasks from "../../../api/MyTasks";
import MyUsers from "../../../api/MyUsers";
import SearchUsersControla from "../../../components/search/searchUsersControla";
import SelectControla from "../../../components/selects/selectControla";
import MyConfigurationApi from "../../../api/MyConfigurationApi";
import SelectControlaRoles from "../../../components/selects/selectControlaRoles";

const colorTitle = "#9191B0";
const blue = "#034AFF";
const purple = "#8B8BD8";
// Recuerda agendar la reunión mensual de tu Comité

function Index() {
  //variables
  const [tasks, setTasks] = useState([]);
  const [idTask, setIdTask] = useState();
  const [idUser, setidUser] = useState();
  const [initialDate, setInitialDate] = useState();
  const [endDate, setEndDate] = useState();
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [status, setStatus] = useState();

  const taskStatus = [
    { id_status: 1, status: "En curso" },
    { id_status: 2, status: "Completado" },
    { id_status: 3, status: "Anulado" },
  ];

  //responsibles
  const [nameResponsibleInput, setNameResponsibleInput] = useState();
  const [idResponsible, setIdResponsible] = useState();

  //funciones
  const handleGetTasks = async () => {
    setOpenBackdrop(true);
    const response = await MyTasks.getTasks();
    console.log(response);
    setTasks(response);
    handleCloseBackdrop();
  };

  //NewTask
  const handleNewTask = async (event) => {
    event.preventDefault();
    setOpenBackdrop(true);
    const response = await MyTasks.newTask({
      initialDate: initialDate,
      endDate: endDate,
      description: description,
      idResponsible: idResponsible,
      idTaskType: 1,
      title: title,
    });
    setOpenDialogAddTask(false);
    handleCloseBackdrop();
    handleGetTasks();
  };

  //EditTask

  const handleEditTask = async (event) => {
    event.preventDefault();
    setOpenBackdrop(true);
    const response = await MyTasks.editTask({
      idTask: idTask,
      initialDate: initialDate,
      description: description,
      idResponsible: idResponsible,
      endDate: endDate,
      title: title,
    });
    setOpenDialogEditTask(false);
    handleCloseBackdrop();
    handleGetTasks();
  };

  const handleDeleteTask = async (idTask) => {
    setOpenBackdrop(true);
    const response = await MyTasks.deleteTask({
      idTask: idTask,
    });
    setOpenDialogDeleteTask(false);
    handleCloseBackdrop();
    handleGetTasks();
  };

  const handleFinishedTask = async () => {
    const response = await MyTasks.taskStatusUpdate({
      idTask: idTask,
      status: 2,
      endDate: endDate,
    });
  };

  const handleGetTaskByUser = async () => {
    const response = await MyTasks.taskByUser({ idUser: idUser });
    console.log(response);
  };
  // Obtener usuarios Activos
  const [rowsUsersSearchs, setRowsUsersSearchs] = useState([]);
  const handleGetUsersSearchsAddsWorkers = async () => {
    const response = await MyUsers.users();

    setRowsUsersSearchs(response.users_actives);
  };

  // buscar usuario por ID y setear sus datos

  const handleSelectUsersResponsible = async (idUsers) => {
    setIdResponsible(idUsers);
    // handleOpenLoading();
    setOpenBackdrop(true);

    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setNameResponsibleInput(response.first_name + " " + response.last_name);
      setIdResponsible(response.id_users);
      setOpenDialogResponsible(false);
      // handleOpenAlert(response.msg, "success");
      handleCloseBackdrop();
    } else {
    }
  };

  // updateStatus

  const handleUpdateTaskStatus = async ({ idTask, status }) => {
    console.log(status);
    const response = await MyTasks.taskStatusUpdate({
      idTask: idTask,
      status: status,
    });
    handleGetTasks();
  };
  //modales

  //añadir una tarea
  const [openDialogAddTask, setOpenDialogAddTask] = useState(false);

  const handleOpenDialogAddTask = () => {
    setInitialDate("");
    setEndDate("");
    setDescription("");
    setIdResponsible("");
    setTitle("");
    setNameResponsibleInput(null);
    setOpenDialogAddTask(true);
  };
  //agregar responsable
  const [openDialogResponsible, setOpenDialogResponsible] = useState(false);

  const handleOpenDialogResponsible = () => {
    setOpenDialogResponsible(true);
  };
  //editarTareas
  const [openDialogEditTask, setOpenDialogEditTask] = useState(false);

  const handleOpenDialogEditTask = ({
    idTask,
    initialDate,
    endDate,
    description,
    idResponsible,
    title,
  }) => {
    setIdTask(idTask);
    setInitialDate(initialDate);
    setEndDate(endDate);
    setDescription(description);
    setIdResponsible(idResponsible);
    setTitle(title);
    setOpenDialogEditTask(true);
  };

  ///Eliminar tarea
  const [openDialogDeleteTask, setOpenDialogDeleteTask] = useState(false);

  const handleOpenDialogDeleteTask = ({ deleteTask }) => {
    setIdTask(deleteTask);
    setOpenDialogDeleteTask(true);
  };
  ///Backdrop

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  // admin
  const [isAdmin, setIsAdmin] = useState(false);

  const handleIsAdmin = () => {
    const roles = [1, 7, 8];
    const userData = MyConfigurationApi.userData();
    console.log(userData);
    let userRoles = userData.roles;
    roles.map((rol) => {
      console.log(rol);
      if (userRoles.includes(rol)) {
        setIsAdmin(true);
      }
    });
  };

  useEffect(() => {
    handleGetTasks();
    handleGetUsersSearchsAddsWorkers();
    handleIsAdmin();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SubtitleText
                text={"Actividades"}
                color={colorTitle}
              ></SubtitleText>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <BannerControla
                image={
                  "/assets/images/banners/configuration/improvactionsbaselinebanner.png"
                }
                text={"Acciones de mejora"}
              />
            </Grid>
            <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
              <Stack direction={"row"} spacing={2}>
                <Box>
                  <ButtonControla
                    roles={[1, 7, 8]}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#1cb591"}
                    backgroundColorHover={"#169073"}
                    textButton={"Agregar tarea"}
                    functionOnClick={handleOpenDialogAddTask}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper sx={{ overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 400 }}>
                  <Table
                    stickyHeader
                    sx={{ width: "100%", minWidth: "700px" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        {isAdmin ? (
                          ""
                        ) : (
                          <TableCell sx={{ color: blue }} align="center">
                            Título
                          </TableCell>
                        )}
                        <TableCell sx={{ color: blue }} align="center">
                          Descripción
                        </TableCell>
                        <TableCell
                          sx={{
                            color: blue,
                          }}
                          align="center"
                        >
                          Fecha de inicio
                        </TableCell>
                        <TableCell
                          sx={{
                            color: blue,
                          }}
                          align="center"
                        >
                          Fecha de vencimiento
                        </TableCell>
                        {isAdmin ? (
                          <TableCell sx={{ color: blue }} align="center">
                            Responsable
                          </TableCell>
                        ) : (
                          ""
                        )}
                        <TableCell
                          sx={{
                            color: blue,
                          }}
                          align="center"
                        >
                          Tipo
                        </TableCell>

                        <TableCell
                          sx={{
                            color: blue,
                          }}
                          align="center"
                        >
                          Estado
                        </TableCell>
                        <TableCell colSpan={2}>
                          {/* <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Tabs
                              // value={documentType}
                              // onChange={handleChangeDocumentType}
                              aria-label="Tipos de documento"
                            >
                              <Tab label="Pendientes" />
                              <Tab label="Vencidas" />
                            </Tabs>
                          </Stack> */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                    // sx={{ display: documentType !== 0 ? "none" : "" }}
                    >
                      {tasks.map((row) =>
                        row.id_tasks_types === 1 ? (
                          <TableRow
                            hover
                            // key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {isAdmin ? (
                              ""
                            ) : (
                              <TableCell
                                sx={{ color: purple }}
                                component="th"
                                scope="row"
                                align="center"
                              >
                                {row.title}
                              </TableCell>
                            )}
                            <TableCell
                              sx={{ color: purple }}
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {row.description}
                            </TableCell>
                            <TableCell
                              sx={{ color: purple }}
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {row.date_start === null
                                ? "Sin fecha de inicio"
                                : row.date_start}
                            </TableCell>
                            <TableCell
                              sx={{ color: purple }}
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {row.date_end === null
                                ? "Sin fecha de fin"
                                : row.date_end}
                            </TableCell>
                            {isAdmin ? (
                              <TableCell
                                sx={{ color: purple }}
                                component="th"
                                scope="row"
                                align="center"
                              >
                                {row.first_name + " " + row.last_name}
                              </TableCell>
                            ) : (
                              ""
                            )}
                            <TableCell
                              sx={{ color: purple }}
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {row.id_tasks_types}
                            </TableCell>

                            
                            <TableCell
                              sx={{ color: purple }}
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <SelectControlaRoles
                                roles={[1, 7, 8]}
                                inputValue={setStatus}
                                value={row.id_status}
                                childrenRows={taskStatus.map((rows) => (
                                  <MenuItem
                                    key={rows.id_status}
                                    value={rows.id_status}
                                  >
                                    {rows.status}
                                  </MenuItem>
                                ))}
                                onChange={
                                  (event) => {
                                    handleUpdateTaskStatus({
                                      idTask: row.id_tasks,
                                      status: event.target.value,
                                    });
                                  }
                                  // handleUpdateTaskStatus({
                                  //   idTask: row.id_tasks,
                                  //   status: status,
                                  // })
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={
                                    <BorderColorOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#169073"}
                                  textTooltip={"Editar"}
                                  functionOnClick={() =>
                                    handleOpenDialogEditTask({
                                      idTask: row.id_tasks,
                                      initialDate: row.date_start,
                                      endDate: row.date_end,
                                      description: row.description,
                                      idResponsible: row.id_users,
                                      title: row.title,
                                    })
                                  }
                                />
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#EB5757"}
                                  textTooltip={"Delete"}
                                  functionOnClick={() =>
                                    handleOpenDialogDeleteTask({
                                      deleteTask: row.id_tasks,
                                    })
                                  }
                                />
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Dialog agregar tareas */}
      <Dialog
        open={openDialogAddTask}
        onClose={() => setOpenDialogAddTask(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar tarea
          </Typography>
        </DialogTitle>
        <form onSubmit={handleNewTask}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título"}
                  modalType={true}
                  inputType={"textArea"}
                  inputValue={setTitle}
                  value={title}
                  required
                ></InputControlaVertical>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inicio"}
                  inputType={"date"}
                  inputValue={setInitialDate}
                  value={initialDate}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setEndDate}
                  value={endDate}
                  modalType={true}
                  // required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable: "
                  inputValue={nameResponsibleInput}
                  functionClick={handleOpenDialogResponsible}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenDialogAddTask(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* Dialog Editar tareas */}
      <Dialog
        open={openDialogEditTask}
        onClose={() => setOpenDialogEditTask(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Editar tarea
          </Typography>
        </DialogTitle>
        <form onSubmit={handleEditTask}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título"}
                  modalType={true}
                  inputType={"textArea"}
                  inputValue={setTitle}
                  value={title}
                  required
                ></InputControlaVertical>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inicio"}
                  inputType={"date"}
                  inputValue={setInitialDate}
                  value={initialDate}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setEndDate}
                  value={endDate}
                  modalType={true}
                  // required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable: "
                  inputValue={nameResponsibleInput}
                  functionClick={handleOpenDialogResponsible}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenDialogEditTask(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* Añadir Responsable */}
      <Dialog
        open={openDialogResponsible}
        onClose={() => {
          setOpenDialogResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsUsersSearchs}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialogResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Eliminar */}
      <Dialog
        open={openDialogDeleteTask}
        onClose={() => {
          setOpenDialogDeleteTask(false);
        }}
        maxWidth="xs"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Eliminar tarea
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          ¿Está seguro de eliminar esta tarea?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialogDeleteTask(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleDeleteTask(idTask);
            }}
          >
            Sí, eliminar.
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        {/* <CircularProgress color="inherit" /> */}
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Index;
