import React, { Component, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const EditorSsoPoliticsControlaThree = ({
  dataEdit,
  handleDataEditor = alert,
  disabled = false
}) => {
  const template = `
  <p>  INGRESAR NOMBRE, empresa dedicada a INGRESAR OBJETO SOCIAL,
  está comprometida con el desarrollo, implementación y
  mantenimiento del Sistema Integrado de Gestión en Medio
  Ambiente y Seguridad y Salud Ocupacional. Nuestros objetivos
  la protección del medio ambiente y la protección de la
  seguridad y la salud de los trabajadores, los contratistas y
  las partes interesadas que interactúan en todos los niveles de
  nuestra organización. Para alcanzar estos objetivos la empresa
  se compromete a:
  </p>
  <ul>
    <li>
    Desarrollar acciones para el manejo eficiente de los recursos
    naturales que se utilizan, como agua, suelo, energía,
    biodiversidad entre otros y reducir el impacto ambiental de las
    actividades, aplicando la prevención y la vigilancia de los
    aspectos ambientales en los procesos de la organización.
    Asimismo, la empresa implementa acciones para otorgar las
    condiciones adecuadas de trabajo, salud y seguridad en la
    organización.
    </li>
    <li>
    Identificar los aspectos ambientales relacionados a las
    actividades de la empresa y establecer medidas de control para
    la reducción, mitigación y prevención de impactos ambientales
    negativos, así como identificar los peligros, evaluar los
    riesgos relacionados a las actividades de la empresa y
    establecer medidas de control en materia de seguridad y salud de
    los trabajadores.
    </li>
    <li>
    Cumplir con la normativa legal vigente, recomendaciones y
    mandatos de la autoridad ambiental, regulaciones internas,
    compromisos voluntarios suscritos por la organización,
    incluyendo los señalados en los instrumentos de gestión
    ambiental, así como estándares internacionales en materia de
    medio ambiente y seguridad y salud en el trabajo.
    </li>
    <li>
    Asegurar la consulta y participación activa de nuestro
    personal y de sus representantes en todos los aspectos del
    Sistema de Gestión de Seguridad y Salud en el Trabajo de nuestra
    empresa.
    <li>
    Informar, comunicar y poner a disposición de manera
    responsable y oportuna a nuestro personal, sus representantes y
    grupos de interés los aspectos del Sistema Integrado de Gestión
    de Medio Ambiente y Seguridad y Salud en el Trabajo de nuestra
    empresa.
    </li>
    <li>
    Capacitar, entrenar y comprometer a nuestro personal con la
    cultura de ecoeficiencia y la cultura de la prevención en todos
    los aspectos del Sistema Integrado de Gestión de Medio Ambiente
    y Seguridad y Salud de nuestra empresa.
    </li>
    <li>
    Mejorar continuamente el desempeño del Sistema Integrado de
    Gestión de Medio Ambiente y de Seguridad y Salud en el Trabajo.
    </li>
  </ul>
`;
 
  return (
    <div className="App">
      <CKEditor
        disabled={disabled}
        editor={ClassicEditor}
        data={dataEdit === null ? template : dataEdit}
        onReady={(editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // You can store the "editor" and use when it is needed.
          // console.log( 'Editor is ready to use!', editor );
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // console.log({ data });
        }}
        // onChange={(event, editor) => dataEditor(editor.getData())}

        onBlur={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
        onFocus={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
      />
    </div>
  );
};
