import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import {
    BorderColorOutlined,
    Delete,
    Archive,
    Send
} from "@mui/icons-material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";

function ListActives(props) {
    const { permissions } = useAppContext();
    const navigate = useNavigate();

    const {
        rowsActives,
        colorCell,
        idUserLogged,
        handleOpenModalConfirm,
        handleOpenModalEditFile
    } = props

    return (
        <TableBody>
            {
                rowsActives.map((r) => (
                    <TableRow
                        hover
                        key={r.id_committee_regulations}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <TableCell sx={{ color: colorCell }}>{r.title}</TableCell>
                        <TableCell sx={{ color: colorCell }}>{r.model == 1 ? "Sistema" : "Archivo"}</TableCell>
                        <TableCell sx={{ color: colorCell }}>{r.id_status == 1 ? "Creado" : (r.id_status == 2 ? "Enviado a comité" : r.id_status == 3 ? "Publicado" : "Archivado")}</TableCell>
                        <TableCell align="right">
                            <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                            >
                                {
                                   (r.id_status == 2 || (r.id_status == 3 && r.model == 1)) && (
                                        <ButtonIconControla
                                            roles={permissions.COMITE_COMITE_RIC_ACTIVOS_VER}
                                            icon={ <VisibilityOutlined sx={{ color: "white" }}/>}
                                            backgroundColor={"#305AD9"}
                                            backgroundColorHover={"#0E4AFF"}
                                            textTooltip={"Ver"}
                                            isNavegationOrigin={true}
                                            directionUrl={"/committee/presentCommittee/internalRegulationsCommittee/templateMenu/template" + `?idCommitteeRegulations=${r.id_committee_regulations}&readOnly=true`}
                                        />
                                    ) 
                                }
                                {
                                    (r.id_status == 3 && r.model == 0) && (

                                        <ButtonIconControla
                                            roles={permissions.COMITE_COMITE_RIC_ACTIVOS_VER}
                                            icon={<VisibilityOutlined  sx={{ color: "white" }}/>}
                                            backgroundColor={"#305AD9"}
                                            backgroundColorHover={"#0E4AFF"}
                                            textTooltip={"Ver"}
                                            directionUrl={r.document}
                                            directionTarget={"_blank"}
                                        />
                                    )
                                }

                                {
                                    r.id_status == 1 && (
                                        <ButtonIconControla
                                            roles={idUserLogged == r.produced ? [] : permissions.COMITE_COMITE_RIC_ACTIVOS_EDIT}
                                            icon={<BorderColorOutlined sx={{ color: "white" }}/> }
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar"}
                                            functionOnClick={() => {
                                                if (r.model == 0) {
                                                    handleOpenModalEditFile(r);
                                                } else {
                                                    navigate("/committee/presentCommittee/internalRegulationsCommittee/templateMenu/template"+ `?idCommitteeRegulations=${r.id_committee_regulations}`);
                                                }
                                            }}
                                        />
                                    )
                                }

                                {
                                    r.id_status == 2 && (r.reviewed == 0 ? !(r.firm_elaboration == 1 && r.firm_approval == 1) : !(r.firm_elaboration == 1 && r.firm_approval == 1 && r.firm_review == 1)) ? (
                                        <ButtonIconControla
                                            roles={idUserLogged == r.produced || idUserLogged == r.reviewed || idUserLogged == r.approved ? [] : permissions.COMITE_COMITE_RIC_ACTIVOS_EDIT}
                                            icon={ <BorderColorOutlined sx={{ color: "white" }}/>}
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar"}
                                            functionOnClick={() => {
                                                if (r.model == 0) {
                                                    handleOpenModalEditFile(r);
                                                } else {
                                                    navigate("/committee/presentCommittee/internalRegulationsCommittee/templateMenu/template"+ `?idCommitteeRegulations=${r.id_committee_regulations}`);
                                                }
                                            }}
                                        />
                                    ) : ""
                                }

                                {
                                    (r.id_status == 1 && r.model == 1 ) && (
                                        <ButtonIconControla
                                            roles={r.produced == idUserLogged ? [] : permissions.COMITE_COMITE_RIC_ACTIVOS_ENVIA_A_COMIT}
                                            icon={<Send sx={{ color: "white" }} />}
                                            backgroundColor={"#581845"}
                                            backgroundColorHover={"#6D1F56"}
                                            textTooltip={"Enviar a comité"}
                                            functionOnClick={() => {
                                                handleOpenModalConfirm(
                                                    r.id_committee_regulations,
                                                    "¿Estás seguro de enviar a comité este reglamento?",
                                                    "",
                                                    "Si, enviar",
                                                    "sendToCommittee"
                                                )
                                            }}
                                        />
                                    )
                                }

                                {
                                    (r.id_status == 2 || (r.id_status == 1 && r.model == 0)) && (
                                        <ButtonIconControla
                                            roles={r.produced == idUserLogged ? [] : permissions.COMITE_COMITE_RIC_ACTIVOS_PUBLICAR}
                                            icon={<FileUploadOutlinedIcon  sx={{ color: "white" }}/>}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1AA483"}
                                            textTooltip={"Publicar"}
                                            functionOnClick={() => {
                                                handleOpenModalConfirm(
                                                    r.id_committee_regulations,
                                                    "¿Estás seguro de publicar este reglamento?",
                                                    "",
                                                    "Si, publicar",
                                                    "publish"
                                                )
                                            }}
                                        />
                                    )
                                }
                                {
                                    r.id_status == 3 && (
                                        <ButtonIconControla
                                            roles={r.produced == idUserLogged ? [] : permissions.COMITE_COMITE_RIC_ACTIVOS_ARCHIVAR}
                                            icon={<Archive sx={{ color: "white" }} />}
                                            backgroundColor={"#F2994A"}
                                            backgroundColorHover={"#FF881E"}
                                            textTooltip={"Archivar"}
                                            functionOnClick={() => {
                                                handleOpenModalConfirm(
                                                    r.id_committee_regulations,
                                                    "¿Estás seguro de archivar este reglamento?",
                                                    "",
                                                    "Si, archivar",
                                                    "archived"
                                                )
                                            }}
                                        />

                                    )
                                }
                                {
                                    r.id_status == 1 && (
                                        <ButtonIconControla
                                            roles={r.produced == idUserLogged ? [] : permissions.COMITE_COMITE_RIC_ACTIVOS_ELIM}
                                            icon={<Delete sx={{ color: "white" }} />}
                                            backgroundColor={"#EB5757"}
                                            backgroundColorHover={"#FF4040"}
                                            textTooltip={"Eliminar"}
                                            functionOnClick={() => {
                                                handleOpenModalConfirm(
                                                    r.id_committee_regulations,
                                                    "¿Estás seguro de eliminar el reglamento?",
                                                    "Una vez eliminado no se podrá recuperar todo lo incluido en el reglamento",
                                                    "Sí, eliminar",
                                                    "delete"
                                                )
                                            }}
                                        />
                                    )
                                }
                            </Stack>
                        </TableCell>
                    </TableRow>
                ))
            }
        </TableBody>
    )
}
export default ListActives;