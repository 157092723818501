import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import {BorderColorOutlined} from "@mui/icons-material";
function ListPassword(props) {
    const {
        user,
        colorCell,
        setOpenModalChangePassword
    } = props
    return (
        <TableBody
        // sx={{ display: rowsType !== 0 ? "none" : "" }}
        >
            <TableRow
                hover
                key={user.id_users}
                sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                }}
            >
                <TableCell component="th" scope="row" sx={{ color: colorCell }}>
                    {user.first_name + " " + user.last_name}
                </TableCell>
                <TableCell sx={{ color: colorCell }}>{user.email}</TableCell>
                <TableCell align="right">
                    <Stack
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                    >
                        <ButtonIconControla
                            icon={<BorderColorOutlined sx={{ color: "white" }} />}
                            backgroundColor={"#2D9CDB"}
                            backgroundColorHover={"#33AEF4"}
                            textTooltip={"Editar"}
                            functionOnClick={() =>
                                setOpenModalChangePassword(true)
                            }
                        />
                    </Stack>
                </TableCell>
            </TableRow>
        </TableBody>
    )

}
export default ListPassword;