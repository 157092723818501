import MyConfigurationApi from "./MyConfigurationApi";

function getMembers (){
    const url = "committe/members/get";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url
    })
}

function addMember(data_member){
    const url = "committe/members/store";
    return MyConfigurationApi.connectApi({
        dataJson: data_member,
        method: "POST",
        url: url
    })

}
function updateMember(data_member,id_committee_member){
    const url = "committe/members/update/" + id_committee_member;
    return MyConfigurationApi.connectApi({
        dataJson: data_member,
        method: "PUT",
        url: url
    })
}

function deleteMember(id_committee_member){
    const url = "committe/members/remove/" + id_committee_member;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url
    })
}

const MyMembersCommitte = {
    getMembers,
    addMember,
    updateMember,
    deleteMember
}

export default MyMembersCommitte;