import MyConfigurationApi from "./MyConfigurationApi";
import MyLocalStorage from "./MyLocalStorage";

// function notificationsUsers({ view }) {
//   const url = "notifications/users/get?view=" + view;
//   return MyConfigurationApi.connectApi({
//     dataJson: null,
//     method: "GET",
//     url: url,
//   });
// }

function notifications() {
  const url = "notifications";
  return MyConfigurationApi.connectFormApiGeneral({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// function notificationsPersonal({idUser}) {
//   const url = "notifications/user/"+idUser+"/bell";
//   return MyConfigurationApi.connectFormApiGeneral({
//     dataJson: null,
//     method: "GET",
//     url: url,
//   });
// }

function notificationsPersonal({idUser}) {
  const url = "notifications/user/"+idUser+"/bell";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function notificationsStatus({idNotification}) {
  const url = "notifications/status/"+idNotification;
  const data = {  
    "status": 2  
  };


  return MyConfigurationApi.connectFormApiGeneral({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

const MyNotifications = {
  // notificationsUsers,
  notifications,
  notificationsPersonal,
  notificationsStatus
};

export default MyNotifications;
