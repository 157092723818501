import { Button, DialogActions, DialogContent, Grid, MenuItem } from "@mui/material"
import { useNavigate } from "react-router-dom";
import SelectControla from "../../../components/selects/selectControla";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";

const FiltersPersonal = ({ 
  handleCloseModalFilters,
  subjectFilter,
  typeFilter,
  modalityFilter,
  statusFilter,
  yearFilter,
  responsibleFilter,
  setSubjectFilter,
  setTypeFilter,
  setModalityFilter,
  setStatusFilter,
  setYearFilter,
  setResponsibleFilter,
  rowsTypes,
  rowSubjects,      
  rowStatuses,
  rowModalities,
  rowResponsible,
  rowYears,
  setStartFilter,
  startFilter,
  setEndFilter,
  endFilter,
  onSubmit
}) => {
  const navigate = useNavigate();

  const onSubmitForm = (e) => {
    onSubmit(e)
    const filters = [];
    if (subjectFilter) filters.push(`id_trainings_subjects=${subjectFilter}`);
    if (typeFilter) filters.push(`id_trainings_types=${typeFilter}`);
    if (modalityFilter) filters.push(`id_trainings_modalities=${modalityFilter}`);
    if (statusFilter) filters.push(`id_trainings_statuses=${statusFilter}`);
    if (yearFilter) filters.push(`year=${yearFilter}`);
    if (responsibleFilter) filters.push(`id_users_responsible=${responsibleFilter}`);
    if (startFilter) filters.push(`timestamp_start=${startFilter}`);
    if (endFilter) filters.push(`timestamp_end=${endFilter}`);

    // Agrega otros filtros aquí

    // Combina los filtros en una cadena de consulta
    const queryParams = filters.join('&');

    // Genera la nueva ubicación con los parámetros de filtro
    const newLocation = `/trainings/personal?${queryParams}`;

    // Cambia la URL sin recargar la página
    navigate(newLocation);
  }

  const handleResetFilters = () => {
    setSubjectFilter('')
    setTypeFilter('')
    setModalityFilter('')
    setStatusFilter('')
    setYearFilter('')
    setResponsibleFilter('')
    setStartFilter('')
    setEndFilter('')
  }

  return (
    <form  onSubmit={onSubmitForm}>
      <DialogContent sx={{ paddingTop: "0" }}>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SelectControla text={'Tema de capacitación:'} name="id_trainings_subjects"
                      inputValue={setSubjectFilter} modalType={true} childrenRows={
                          rowSubjects.map((row) => (
                              <MenuItem key={row.id_trainings_subjects}
                                  value={row.id_trainings_subjects}
                              >
                                  {row.subject}
                              </MenuItem>
                          ))
                      } value={subjectFilter}
                      optionNull={1}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SelectControla text={'Tipo de capacitación:'} name="id_trainings_types"
                      inputValue={setTypeFilter} modalType={true} childrenRows={
                          rowsTypes.map((row) => (
                              <MenuItem key={row.id_trainings_types}
                                  value={row.id_trainings_types}
                              >
                                  {row.type}
                              </MenuItem>
                          ))
                      } value={typeFilter}
                      optionNull={1}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SelectControla text={'Estado de capacitación:'} name="id_trainings_statuses"
                      inputValue={setStatusFilter} modalType={true} childrenRows={
                          rowStatuses.map((row) => (
                              <MenuItem key={row.id_trainings_statuses}
                                  value={row.id_trainings_statuses}
                              >
                                  {row.status}
                              </MenuItem>
                          ))
                      } value={statusFilter}
                      optionNull={1}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SelectControla text={'Modalidad de capacitación:'} name="id_trainings_modalities"
                      inputValue={setModalityFilter} modalType={true} childrenRows={
                          rowModalities.map((row) => (
                              <MenuItem key={row.id_trainings_modalities}
                                  value={row.id_trainings_modalities}
                              >
                                  {row.modality}
                              </MenuItem>
                          ))
                      } value={modalityFilter}
                      optionNull={1}
                  />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SelectControla text={'Responsable de capacitación:'} name="id_users_responsible"
                      inputValue={setResponsibleFilter} modalType={true} childrenRows={
                          rowResponsible.map((row) => (
                              <MenuItem key={row.id_users}
                                  value={row.id_users}
                              >
                                  {row.first_name + ' ' + row.last_name}
                              </MenuItem>
                          ))
                      } value={responsibleFilter}
                      optionNull={1}
                  />
              </Grid>

              {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SelectControla text={'Sede de capacitación:'} name="id_campus"
                      inputValue={setCampusFilter} modalType={true} childrenRows={
                          rowCampus.map((row) => (
                              <MenuItem key={row.id_campus}
                                  value={row.id_campus}
                              >
                                  {row.name}
                              </MenuItem>
                          ))
                      } value={campusFilter}
                  />
              </Grid> */}

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputControlaVertical text={'Fecha y hora inicio de capacitación:'} inputType={'datetime-local'} inputValue={setStartFilter} value={startFilter} modalType={true} name="timestamp_start" />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputControlaVertical text={'Fecha y hora fin de capacitación:'} inputType={'datetime-local'} inputValue={setEndFilter} value={endFilter} modalType={true} name="timestamp_end" />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <SelectControla text={'Año de capacitación:'} name="year"
                      inputValue={setYearFilter} modalType={true} childrenRows={
                          rowYears.map((row) => (
                              <MenuItem key={row}
                                  value={row}
                              >
                                  {row}
                              </MenuItem>
                          ))
                      } value={yearFilter}
                      optionNull={1}
                  />
              </Grid>

          </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
              textTransform: "none",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
          }}
          onClick={() => handleResetFilters()}
        >
          Limpiar Filtros
        </Button>
          <Button
              variant="contained"
              // color="success"
              sx={{
                  textTransform: "none",
                  color: "#6969D6",
                  backgroundColor: "#CBCBFF",
                  borderRadius: "16px",
                  marginRight: "10px",
              }}
              onClick={() => handleCloseModalFilters()}
          >
              Cancelar
          </Button>
          <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                  textTransform: "none",
                  // backgroundColor: '#169073',
                  borderRadius: "16px",
                  marginRight: "10px",
              }}
          >
              Buscar
          </Button>
      </DialogActions>
    </form>
  )
}

export default FiltersPersonal