import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getRit() {
  const url = "rit";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createRit({ name, file }) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (file !== null && file !== undefined) {
    file = await uploadFileCompanies(
      file,
      "documentation/rit" + year + "/" + month + "/" + day + "/"
    );
  }

  const url = "rit";
  const data = {
    name: name,
    file: file,
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

async function deleteRit({ idRit }) {
  const url = "rit/" + idRit;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

function changeRitStatus({ idRit, registerStatus }) {
  const url = "rit/" + idRit;
  const data = {
    register_status: registerStatus,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

//fulfillments
function editRit({ idRit }) {
  const url = "rit/" + idRit;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function updateRit({ idRit, workerEntrance, workingHours, attendanceControl, permanenceRules, weeklyLeave, employeeRightsResponsabilities, employerRightsResponsabilities, employerEmployeeHarmonyRules, disciplinaryMeasures, laboralMattersEntity, fundamentalRulesActivities, accidentAvoidanceRules, authorityEntityApproved, sucamec, tuberculosisAntidiscrimnationRules, smokingProhibition, sexualHarassmentPolicies, investigationSanction, hivPrevention, lactancyTime, maternityLeave, bikeUsage, uniformFlexibility, diabetesAntidiscrimination, ritTopics }) {
  const url = "rit/" + idRit;
  const camposRequeridos = [
    workerEntrance, workingHours, attendanceControl, permanenceRules, weeklyLeave,
    employeeRightsResponsabilities, employerRightsResponsabilities, employerEmployeeHarmonyRules,
    disciplinaryMeasures, laboralMattersEntity, fundamentalRulesActivities, accidentAvoidanceRules,
    authorityEntityApproved, sucamec, tuberculosisAntidiscrimnationRules, smokingProhibition,
    sexualHarassmentPolicies, investigationSanction, hivPrevention, lactancyTime, maternityLeave,
    bikeUsage, uniformFlexibility, diabetesAntidiscrimination, ritTopics
  ];
  // Validación de campos obligatorios
  if (camposRequeridos.some(campo => campo === undefined || campo === null || campo === "")) {
    return {
      error: true,
      message: "Todos los campos son obligatorios"
    }
  }

  const data = {
    workers_entrance: workerEntrance,
    working_hours: workingHours,
    attendance_control: attendanceControl,
    permanence_rules: permanenceRules,
    weekly_leave: weeklyLeave,
    employee_rights_responsabilities: employeeRightsResponsabilities,
    employer_rights_responsabilities: employerRightsResponsabilities,
    employer_employee_harmony_rules: employerEmployeeHarmonyRules,
    disciplinary_measures: disciplinaryMeasures,
    laboral_matters_entity: laboralMattersEntity,
    fundamental_rules_activities: fundamentalRulesActivities,
    accident_avoidance_rules: accidentAvoidanceRules,
    authority_entity_approved: authorityEntityApproved,
    sucamec: sucamec,
    tuberculosis_antidiscrimination_rules: tuberculosisAntidiscrimnationRules,
    smoking_prohibition: smokingProhibition,
    sexual_harassment_policies: sexualHarassmentPolicies,
    investigation_sanction: investigationSanction,
    hiv_prevention: hivPrevention,
    lactancy_time: lactancyTime,
    maternity_leave: maternityLeave,
    bike_usage: bikeUsage,
    uniform_flexibility: uniformFlexibility,
    diabetes_antidiscrimination: diabetesAntidiscrimination,
    rit_topics: ritTopics
  }
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

const MyRit = {
  getRit,
  createRit,
  deleteRit,
  changeRitStatus,

  //fulfillments
  editRit,
  updateRit
};

export default MyRit;
