// juntas los new work accidents onemptied, two, thre, frour
import { React, useEffect, useState } from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  FormControlLabel,
  Switch,
  FormControl,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  InfoOutlined,
  BorderColorOutlined,
  VisibilityOutlined,
  PictureAsPdf,
  CheckBox,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import MyAccidentsOccupationalsIncidents from "../../../../api/MyAccidentsOccupationalsIncidents";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import SelectControla from "../../../../components/selects/selectControla";
import MyUsers from "../../../../api/MyUsers";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import MyAreas from "../../../../api/MyAreas";
import MyTasksNew from "../../../../api/MyTasksNew";
import { useAppContext } from "../../../../context/AppContext";
import ButtonSignatureControla from "../../../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../../../components/buttons/returnBackControla";
import MyRegistersAccidents from "../../../../api/MyRegistersAccidents";
import MyContractors from "../../../../api/MyContractors";
import MyContractorsEmployees from "../../../../api/contractors/MyContractorsEmployees";
import { ModalAddWorkerInvolved } from "./components/modalAddWorkerInvolved";
import MyContractorsRelationEmployees from "../../../../api/contractors/MyContractorsRelationEmployees";

const colorTitle = "#9191B0";
const blue = "#034AFF";

function Edit() {
  const { permissions } = useAppContext();

  const params = new URLSearchParams(window.location.search);
  const idRegistersAccOccInc = params.get("idRegistersAccidentsOccupationalsIncidents");

  const readOnly = params.get("readOnly") == "true" ? true : false;

  // Backdrop
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;
  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdAgentType(id);
    setIdRegistersWorkers(id);
    setIdMeasures(id);
    setIdResponsibleWorker(id);

    setIdDisablingAccident(id);
    setIdMortalAccident(id);
    setIdMinorAccident(id);

    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    const actions = {
      "deleteWorkers": () => handleDeleteAccOccIncsWorkers(),
      "deleteOccupationalDiseases": () => handleDeleteAgentsTypes(),
      "deleteMeasures": () => handleDeleteCorrectivesActions(),
      "deleteResponsible": () => handleDeleteResponsibleWorkers(),
      "deleteMinorAccident": () => handleDeleteMinorAccident(),
      "deleteDisabblingAccident": () => handleDeleteDisablingAccident(),
      "deleteMortalAccident": () => handleDeleteMortalAccident(),
    }
    if (actions[buttonActionType]) {
      actions[buttonActionType]();
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN


  // DIALOGOS
  // agregar datos del trabajador DIALOG
  const [openModalUploadAddDates, setOpenModalUploadAddDates] = useState(false);

  const handleOpenModalAddWorkerAccidented = () => {
    setWorkExperience("");
    setAreaSelected("");
    setShift("");
    setWorkHoursBefore("");
    setResponsibleCorrectiveActions("");
    if (!checked) {
      handleGetSearchsAccOccIncWorkersUsers();
    } else {
      handleGetContractorsEmployees();
    }
    setOpenModalUploadAddDates(true);
  };

  const handleCloseModalUploadAddDates = () => {
    setOpenModalUploadAddDates(false);
  };

  // agregar enfermedades ocupacionales
  const [openModalOccupationalDiseases, setOpenModalOccupationalDiseases] =
    useState(false);

  const handleOpenModaOccupationalDiseases = () => {
    setFirstSemesterCount("");
    setSecondSemesterCount("");
    setDiseaseName("");
    setArea("");
    setAreaSelected("");
    setCauses("");
    setMeasures("");
    setResponsibleCorrectiveActions("");
    setTimestampExecution("");
    setTimeStampEnd("")
    setStatusSelectAgentsTypes("");
    handleGetSearchsResponsiblesWorkersAgentsTypes();
    setOpenModalOccupationalDiseases(true);
  };

  const handleCloseModalOccupationalDiseases = () => {
    setOpenModalOccupationalDiseases(false);
  };

  // agregar medidas correctivas DIALOG
  const [
    openModalUploadCorrectiveActions,
    setOpenModalUploadCorrectiveActions,
  ] = useState(false);

  const handleOpenModalUploadCorrectiveActions = () => {
    setTimestampStartMeasures(null);
    setTimestampEndMeasures(null);
    setDescriptionMeasures(null);
    setDescriptionMeasures("");
    setResponsibleCorrectiveActions("");
    setOpenModalUploadCorrectiveActions(true);
  };

  const handleCloseModalUploadCorrectiveActions = () => {
    setOpenModalUploadCorrectiveActions(false);
  };

  // agregar RESPONSABLES  DIALOG
  const [
    openModalUploadResponsibleRegistration,
    setOpenModalUploadCResponsibleRegistration,
  ] = useState(false);

  const handleOpenModalUploadResponsibleRegistration = () => {
    handleGetSearchsResponsiblesWorkers();
    setOpenModalUploadCResponsibleRegistration(true);
  };

  const handleCloseModalUploadResponsibleRegistration = () => {
    setOpenModalUploadCResponsibleRegistration(false);
  };
  /**************************************************************************************+ */

  // listar tipo de rgistro
  // severidad del accidente
  const [typesRegistersAccOcc, setTypesRegistersAccOcc] = useState(0);
  const [rowsTypesRegisters, setRowsTypesRegisters] = useState([]);

  const handleGetTypeRegisters = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.typeRegistersAccOccInc();
    if (response.success === false) {
    } else {
      setRowsTypesRegisters(response);
    }
    return response;
  };

  // LISTAR  SEVERIDAD
  // Gravedad del accidente de trabajo
  const [severityAccidents, setSeverityAccidents] = useState(0);

  // severidad del accidente
  const [severityIncidents, setSeverityIncidents] = useState(0);
  const [rowsSeverityIncidents, setRowsSeverityIncidents] = useState([]);

  const handleGetSeverityInccidents = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.severityIncidents();
    if (response.success === false) {
    } else {
      setRowsSeverityIncidents(response);
    }
    return response;
  };

  // edit accidentes ocupaccionales mypes
  const [code, setCode] = useState("");
  const [affectedWorkers, setAffectedWorkers] = useState(0);
  const [causesDescription, setcausesDescription] = useState("");
  const [injuredBodyPartDescription, setInjuredBodyPartDescription] =
    useState("");
  const [location, setLocation] = useState("");
  const [medicalLeaveDays, setMedicalLeaveDays] = useState(0);
  const [timestampAccident, setTimestampAccident] = useState("");
  const [timestampInvestigation, setTimestampInvestigation] = useState("");
  const [descriptionWork, setDescriptionWork] = useState("");

  const handleEditAccidentsOccupational = async () => {
    setOpenBackdrop(true);

    if (Object.keys(permissions).length !== 0) {

      let response = await MyAccidentsOccupationalsIncidents.editAccidentsOccupational({ idRegistersAccOccInc });
      if (response.success === false) {
      } else {
        response = response.data;
        setRegisterStatus(
          response.id_registers_accidents_occupationals_incidents_statuses === 1
            ? true
            : false
        );
        setAffectedWorkers(response.affected_workers);
        setcausesDescription(response.causes_description);
        setCode(response.code);
        setDescriptionWork(response.description);
        setInjuredBodyPartDescription(response.injured_body_part_desciption);
        setLocation(response.location);
        setMedicalLeaveDays(response.medical_leave_days);
        setTimestampAccident(
          response.timestamp_accident === null || response.timestamp_accident === undefined
            ? ""
            : response.timestamp_accident.substring(0, 19)
        );
        setTimestampInvestigation(
          response.timestamp_investigation === null || response.timestamp_investigation === undefined
            ? ""
            : response.timestamp_investigation.substring(0, 10)
        );
        setTypesRegistersAccOcc(
          response.id_registers_accidents_occupationals_incidents_types
        );
        setSeverityAccidents(
          response.id_registers_accidents_occupationals_incidents_severities
        );
        setSeverityIncidents(
          response.id_registers_accidents_occupationals_incidents_incident
        );
        const contractorIdsString = response.contractor_ids;
        const contractorIdsArray = contractorIdsString != null ? contractorIdsString.split(',').map(id => parseInt(id, 10)) : [];
        setSelectedContractorsIds(contractorIdsArray)
      }

      handleCloseBackdrop();
      return response;
    }
  };

  //actualizar accidentes ocupacionales mypes
  const handleUpdateOccupationalsDiseases = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyAccidentsOccupationalsIncidents.updateAccidentsOccupational({
        idRegistersAccOccInc,
        code: code,
        id_registers_accidents_occupationals_incidents_types: typesRegistersAccOcc,
        timestamp_accident: timestampAccident,
        timestamp_investigation: timestampInvestigation,
        location: location,
        id_registers_accidents_occupationals_incidents_severities: severityAccidents,
        id_registers_accidents_occupationals_incidents_incident: severityIncidents,
        medical_leave_days: medicalLeaveDays,
        affected_workers: affectedWorkers,
        injured_body_part_desciption: injuredBodyPartDescription,
        description: descriptionWork,
        causes_description: causesDescription,
        contractor_ids: selectedContractorsIds
      });

    if (!response || response.success === false) {
      handleClickAlert('error','Error al actualizar');
    } else {
      handleCloseBackdrop();
      handleClickAlert("success", "Se actualizaron los datos");
    }
    return response;
  };

  // cerrar el estado de accidentes ocupacionales mypes!!!!!!
  const [registerStatus, setRegisterStatus] = useState(true);

  const handleAccidentsOcupationalEnd = async () => {
    if (
      responsibleWorkers.length >= 1 &&
      correctivesActions.length >= 1 &&
      accOccIncWorkers.length >= 1
    ) {
      const finded = responsibleWorkers.find((item) => item.firm === 0);

      if (finded === undefined) {
        const response =
          await MyAccidentsOccupationalsIncidents.accidentsOcupationalEnd({
            idRegistersAccOccInc,
          });
        if (response.success === false) {
          alert("Error");
        } else {
          handleEditAccidentsOccupational();
        }
        return response;
      } else {
        handleClickAlert("error", "Registre firmas pendientes");
      }
    } else {
      handleClickAlert("error", "Registre datos en las secciones: Trabajador involucrado, medidas correctivas y agregar responsable");
    }
  };

  //! ---------------------------------- TRABAJADOR INVOLUCRADO ----------------------------------
  const [
    openModalUploadSearchInvolvedWorkers,
    setOpenModalUploadSearchInvolvedWorkers,
  ] = useState(false);
  const handleOpenModalResponsibleInvolvedWorkers = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUploadSearchInvolvedWorkers(true);
  };
  // listar trabajador involucrado para la búsqueda
  const [rowsSearchsAccOccIncWorkersUsers, setSearchsAccOccIncWorkersUsers] = useState([]);

  const handleGetSearchsAccOccIncWorkersUsers = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getSearchsAccOccIncWorkersUsers({
        idRegistersAccOccInc,
      });
    setSearchsAccOccIncWorkersUsers(response);
  };

  // * get trabajador accidentados
  const [accOccIncWorkers, setAccOccIncWorkers] = useState([]);
  const handleGetsetAccOccIncWorkers = async () => {
    const response = await MyAccidentsOccupationalsIncidents.getAccOccIncWorkers({ idRegistersAccOccInc, });
    if (response.success) {
      handleClickAlert("error", "No se pudo obtener los datos");
    }
    return setAccOccIncWorkers(response);
  };

  const [contractorsEmployees, setContractorsEmployees] = useState([]);
  //! Obtener para filtrar los trabajadores de los contratistas seleccionados
  const handleGetContractorsEmployees = async () => {
    try {
      if (selectedContractorsIds.length < 1) {
        return handleClickAlert('info', 'Debe seleccionar al menos un contratista primero.');
      }
      const ids = selectedContractorsIds.join(',');
      const response = await MyContractorsEmployees.getContractorsEmployeesByIdContractor(ids);
      if (!response || response.success === false) {
        handleClickAlert('error', 'No se pudo obtener datos de los trabajadores de los contratistas.')
      }
      setContractorsEmployees(response.data ?? [])
    }
    catch (err) {
      handleClickAlert('error', `Error al obtener los trabajadores de contratista(s). ${err}`.trimEnd())
    }
  }

  const [rowContractorsEmployees, setRowContractorsEmployees] = useState([]);
  //! Obtener los trabajadores de los contratistas accidentados
  const handleGetContractorsEmployeesByIdRegister = async () => {
    try {
      if (!idRegistersAccOccInc) {
        return handleClickAlert('error', 'No se encontró el id del registro.');
      }
      const resp = await MyContractorsEmployees.getContractorsEmployeesByIdRegister({
        id_register: idRegistersAccOccInc, register: 'mypes'
      });
      if (resp.success === true) {
        setRowContractorsEmployees(resp.data);
      }
      setContractorsEmployees(resp.data ?? [])
    }
    catch (err) {
      handleClickAlert('error', `Error al obtener los trabajadores de contratista(s). ${err}`.trimEnd())
    }
  }
  // crear trabajador involucrado

  const [shift, setShift] = useState("");
  const [workExperience, setWorkExperience] = useState("");
  const [workHoursBefore, setWorkHoursBefore] = useState("");

  const handleChangeShift = (event) => {
    setShift(event.target.value);
  };

  // * guardar trabajadores accidentados
  const handleStoreAccidentsWorkers = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyAccidentsOccupationalsIncidents.creatAccOccIncsWorkers({
        idRegistersAccOccInc: idRegistersAccOccInc,
        id_users: idResponsible,
        area: areaSelected,
        shift: shift,
        work_experience: workExperience,
        work_hours_before: workHoursBefore,
      });

    if (response.success === false) {
      console.log("error", response);
    } else {
      handleCloseModalUploadAddDates();
      handleGetsetAccOccIncWorkers();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente los datos del trabajador"
      );
    }
    return response;
  };

  // eliminar
  const [idRegistersWorkers, setIdRegistersWorkers] = useState();
  const handleDeleteAccOccIncsWorkers = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = checked
      ? await MyContractorsRelationEmployees.deleteRelationEmployee(idRegistersWorkers)
      : await MyAccidentsOccupationalsIncidents.deleteAccOccIncsWorkers({
        id_registers_workers: idRegistersWorkers,
      });
    if (!response || response.success === false) {
      handleCloseBackdrop();
      return handleClickAlert("error", "Ha ocurrido un error");
    }
    (checked)
      ? await handleGetContractorsEmployeesByIdRegister()
      : await handleGetsetAccOccIncWorkers();
    handleCloseBackdrop();
    handleClickAlert("success", "Se eliminó correctamente el trabajador accidentado");
    return response;
  };

  //MEDIDAS CORRECTIVAS
  // buscar usuario por ID
  const [responsibleCorrectiveActions, setResponsibleCorrectiveActions] = useState(null);
  const [idResponsible, setIdResponsible] = useState("");

  const handleSelectUsersResponsible = async (idUsers) => {
    try {
      if (rowContractorsEmployees.some(employee => employee.id_users === idUsers)) {
        handleClickAlert("error", "El usuario seleccionado ya se encuentra en la lista de trabajadores.");
        return;
      }
      setIdResponsible(idUsers);
      let response = checked ? await MyContractorsEmployees.getContractorEmployeeById(idUsers) :
        await MyUsers.editUsers({ idUsers: idUsers });
      if (response.success !== false) {
        if (checked) response = response.data[0];
        setResponsibleCorrectiveActions(response.first_name + " " + response.last_name);
        setOpenModalUploadSearchUsersCorrectiveActions(false);
        setOpenModalUploadSearchInvolvedWorkers(false);
        setOpenModalUploadAgentsResponsible(false);
      } else {
        handleClickAlert("error", "Error al obtener el usuario!");
      }
    }
    catch (err) {
      handleClickAlert('error', `Error al seleccionar los datos del trabajador. ${err}`.trimEnd());
    }
  };

  // abrir modal de busqueda de usuarios de medidas correctivas
  const [
    openModalUploadSearchUsersCorrectiveActions,
    setOpenModalUploadSearchUsersCorrectiveActions,
  ] = useState(false);
  const handleOpenModalResponsibleSearchUsersCorrectiveActions = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUploadSearchUsersCorrectiveActions(true);
  };

  //get de usuarios para buscar en medidas correctivas
  const [
    rowsSearchsCorrectivesActionWorkers,
    setSearchsCorrectivesActionWorkers,
  ] = useState([]);

  const handleGetSearchsMeasuresUsers = async () => {
    const resp = await MyUsers.users() ?? [];
    setSearchsCorrectivesActionWorkers(resp.users_actives);
  };

  //get de medidas correctivas, tabla
  const [correctivesActions, setCorrectivesActions] = useState([]);

  const handleGetCorrectivesActions = async () => {
    const response = await MyAccidentsOccupationalsIncidents.getMeasures({
      idRegistersAccOccInc,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setCorrectivesActions(response);
    }
  };

  // crear medidas correctivas
  const [timestampStartMeasures, setTimestampStartMeasures] = useState("");
  const [timestampEndMeasures, setTimestampEndMeasures] = useState("");
  const [descriptionMeasures, setDescriptionMeasures] = useState("");

  const handleStoreCorrectivesActions = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyAccidentsOccupationalsIncidents.createCorrectivesActions({
        idRegistersAccOccInc: idRegistersAccOccInc,
        id_registers_accidents_occupationals_incidents_measures_statuses: 0,
        id_users: idResponsible,
        timestamp_start: timestampStartMeasures,
        timestamp_end: timestampEndMeasures,
        description: descriptionMeasures,
      });

    // agregar a tarea
    const dataTask = {
      date_start: timestampStartMeasures,
      date_end: timestampEndMeasures,
      description: descriptionMeasures,
      id_status: 1,
      id_users: idResponsible,
      id_tasks_types: 2,
      title: "Tarea asignada en Registro de pequeñas empresas generales",
      origin_url: `/documentation/registers/mypes/accidentsoccupationalsincidents/edit?idRegistersAccidentsOccupationalsIncidents=${idRegistersAccOccInc}`
    }
    const resp = MyTasksNew.postTasks(dataTask);

    if (response.success === false && resp.success == false) {
    } else {
      await handleGetCorrectivesActions();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente la medida correctiva"
      );
    }
    return response;
  };

  // eliminar medidas correctives
  const [idMeasures, setIdMeasures] = useState();
  const handleDeleteCorrectivesActions = async () => {
    setOpenBackdrop(!openBackdrop);

    const response =
      await MyAccidentsOccupationalsIncidents.deleteCorrectivesActions({
        id_measures: idMeasures,
      });
    if (response.success === true) {
      await handleGetCorrectivesActions();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se eliminó correctamente la medida correctiva"
      );
    } else {
      alert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // RESPONSABLES
  // Buscar responsables del registro
  const [rowsSearchsResponsiblesWorkers, setSearchsResponsiblesWorkers] = useState([]);

  const handleGetSearchsResponsiblesWorkers = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getSearchsResponsiblesWorkers({
        idRegistersAccOccInc,
      });
    setSearchsResponsiblesWorkers(response);
  };

  // //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getResponsibleWorkers({
        idRegistersAccOccInc,
      });
    if (response.success === false) {
      alert("Error");
    } else {
      setResponsibleActions(response);
    }
  };

  // firmar responsable
  const handleSignResponsible = async (idRegister) => {

    setOpenBackdrop(!openBackdrop);
    const response = await MyAccidentsOccupationalsIncidents.signResponsible({
      idResponsibleSign: idRegister
    });

    if (response.success !== false) {
      await handleGetResponsibleWorkers();
      handleClickAlert("success", "Responsable firmó con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al firmar");
    }

    handleCloseBackdrop();
  };
  // //crear trabajadores responsables
  const handleStoreResponsibleWorkers = async (idUsers) => {
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyAccidentsOccupationalsIncidents.createResponsibleWorkers({
        idRegistersAccOccInc: idRegistersAccOccInc,
        id_users: idUsers,
      });

    if (response.success === false) {
      console.log("error", response);
    } else {
      handleGetResponsibleWorkers();
      handleCloseModalUploadResponsibleRegistration();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente la medida correctiva"
      );
    }
    return response;
  };

  // // eliminar trabajador responsable
  const [idResponsibleWorker, setIdResponsibleWorker] = useState();
  const handleDeleteResponsibleWorkers = async () => {
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyAccidentsOccupationalsIncidents.deleteResponsibleWorkers({
        id_registers_responsible: idResponsibleWorker,
      });
    if (response.success === true) {
      await handleGetResponsibleWorkers();
      handleCloseBackdrop();
      handleClickAlert("success", "Se eliminó correctamente");
    } else {
      alert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // // AGENTES
  const [
    openModalUploadAgentsResponsible,
    setOpenModalUploadAgentsResponsible,
  ] = useState(false);
  const handleOpenModalAgentsResponsible = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUploadAgentsResponsible(true);
  };
  // // seleccionar sustancias select
  const [statusSelectAgentsTypes, setStatusSelectAgentsTypes] = useState(null);

  // // listar tipos de agentes
  const [agentsTypes, setAgentsTypes] = useState([]);
  const handleGetAgentsTypess = async () => {
    const response = await MyAccidentsOccupationalsIncidents.getAgentsTypes();
    setAgentsTypes(response);
  };


  const [relsDAgentsTypes, setRelsAgentsTypes] = useState([]);
  const handleGetRelsDAgentsTypes = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getRelsDAgentsTypes({
        idRegistersAccOccInc,
      });

    if (response.success === false) {
      alert("Error");
    } else {
      setRelsAgentsTypes(response);
    }
  };

  // Buscar trabajadores en medidas correctivas
  const [
    rowsSearchsResponsiblesWorkersAgentesType,
    setSearchsResponsiblesWorkersAgentesType,
  ] = useState([]);

  const handleGetSearchsResponsiblesWorkersAgentsTypes = async () => {
    const resp = await MyUsers.users() ?? []
    setSearchsResponsiblesWorkersAgentesType(resp.users_actives);
  };

  // // crear enfermedades ocupacionales
  const [firstSemesterCount, setFirstSemesterCount] = useState("");
  const [secondSemesterCount, setSecondSemesterCount] = useState("");
  const [diseaseName, setDiseaseName] = useState("");
  const [area, setArea] = useState("");

  //nueva creación
  const [areas, setAreas] = useState([]);
  const [areaSelected, setAreaSelected] = useState("");

  const [causes, setCauses] = useState("");
  const [measures, setMeasures] = useState("");

  const [timestampExecution, setTimestampExecution] = useState("");
  const [timeStampEnd, setTimeStampEnd] = useState("");

  //listar areas guardadas
  const getAreas = async () => {
    const resp = await MyAreas.getAreas();
    setAreas(resp);
  }

  const handleStoreAccOccIncAgents = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);

    const response = await MyAccidentsOccupationalsIncidents.createAgentsTypes({
      idRegistersAccOccInc: idRegistersAccOccInc,
      id_registers_accidents_occupationals_incidents_agents: statusSelectAgentsTypes,
      first_semester_count: firstSemesterCount,
      second_semester_count: secondSemesterCount,
      disease_name: diseaseName,
      area: areaSelected,
      causes: causes,
      measures: measures,
      id_users: idResponsible,
      start_date: timestampExecution,
      end_date: timeStampEnd
    });

    //agregar a tareas
    const dataTask = {
      date_start: timestampExecution,
      date_end: timeStampEnd,
      description: measures,
      id_status: 1,
      id_users: idResponsible,
      id_tasks_types: 2,
      title: "Tarea asignada en Registro de pequeñas empresas generales",
      origin_url: `/documentation/registers/mypes/accidentsoccupationalsincidents/edit?idRegistersAccidentsOccupationalsIncidents=${idRegistersAccOccInc}`
    }
    const resp = MyTasksNew.postTasks(dataTask);

    if (response.success === false || resp.success === false) {
      console.log("error", response);
    } else {
      await handleGetRelsDAgentsTypes();
      handleCloseModalOccupationalDiseases();
      handleCloseBackdrop();
      handleClickAlert("success", "Se creó correctamente");
    }

    return response;
  };
  // // eliminar agentes de enfermedades ocupacionales
  const [idAgentType, setIdAgentType] = useState();
  const handleDeleteAgentsTypes = async () => {
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyAccidentsOccupationalsIncidents.deleteAccOccIncAgentsTypes({
        id_agent_type: idAgentType,
      });
    if (response.success === true) {
      await handleGetRelsDAgentsTypes();
      handleCloseBackdrop();
      handleClickAlert("success", "Se eliminó correctamente");
    } else {
      alert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // listar tipos de agentes tabla completa
  const [openModalTypeAgentsTable, setOpenModalTypeAgentsTable] =
    useState(false);

  const handleOpenModalTypeAgentsTable = () => {
    setOpenModalTypeAgentsTable(true);
  };

  const [agentsTable, setAgentsTable] = useState([]);

  const handleGetAgentsTypesTable = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getAgentsTypesTable();

    if (response.success === false) {
      handleClickAlert("error", "Error al guardar el documento!");
    } else {
      setAgentsTable(response);

    }
    return response;
  };

  //------------- dialogo por tipo de gravedad del accidente-----------
  const [openModalTypeMild, setOpenModalTypeMild] = useState(false);
  const [openModalTypeDisabling, setOpenModalTypeDisabling] = useState(false);
  const [openModalTypeMortal, setOpenModalTypeMortal] = useState(false);

  const [modalButtonAction, setModalButtonAction] = useState("");

  const handleGetRowsParts = (data) => {
    let rowsParts = [];
    data.forEach((p) => {
      rowsParts.push(p.body_part);
    });
    return rowsParts;
  }

  const handleGetRowsIdPartsSelected = (data) => {
    let rowsIdParts = [];
    data.forEach((p) => {
      rowsIdParts.push(p.id_body_parts);
    });
    return rowsIdParts;
  }

  const handleOpenModalTypeMild = (typeModal, data) => {
    setOpenModalTypeMild(true);
    setModalButtonAction(typeModal);

    if (typeModal == "createMinorAccident") {
      setNumberAffectedTypeMild(1);
      setMedicalRestDaysTypeMild("");
      setBodyPartSelected({});
      setBodyPartsLists([]);
    } else {
      setBodyPartsLists(handleGetRowsParts(data.body_parts));
      // setBodyPartsLists(rowsParts);
      setBodyPartSelected({});
      setIdMinorAccident(data.id_accident_type_minor);
      setNumberAffectedTypeMild(data.affected_workers);
      setMedicalRestDaysTypeMild(data.medical_rest_days);
    }
  }

  const handleCloseModalTypeMild = () => setOpenModalTypeMild(false);

  const handleOpenModalTypeDisabling = (typeModal, data) => {
    setOpenModalTypeDisabling(true);
    setModalButtonAction(typeModal);
    if (typeModal == "createDisablingAccident") {
      setSeverityIncidents("");
      setNumberAffectedTypeDisabling(1);
      setMedicalRestDaysTypeDisabling("");
      setBodyPartSelected({})
      setBodyPartsLists([]);
    } else {
      setBodyPartsLists(handleGetRowsParts(data.body_parts));
      setBodyPartSelected({});
      setIdDisablingAccident(data.id_accident_type_disabling);
      setSeverityIncidents(data.id_registers_accidents_severities_incident);
      setNumberAffectedTypeDisabling(data.affected_workers);
      setMedicalRestDaysTypeDisabling(data.medical_rest_days)
    }
  }
  const handleCloseModalTypeDisabling = () => setOpenModalTypeDisabling(false);

  const handleOpenModalTypeMortal = (typeModal, data) => {
    setOpenModalTypeMortal(true);
    setModalButtonAction(typeModal);
    if (typeModal === "createMortalAccident") {
      setNumberAffectedTypeMortal(1);
      setMedicalRestDaysTypeMortal("");
      setBodyPartSelected({});
      setBodyPartsLists([]);
    } else {
      setBodyPartsLists(handleGetRowsParts(data.body_parts));
      setBodyPartSelected({});
      setIdMortalAccident(data.id_accident_type_mortal)
      setNumberAffectedTypeMortal(data.affected_workers);
      setMedicalRestDaysTypeMortal(data.medical_rest_days)
    }
  }
  const handleCloseModalTypeMortal = () => {
    setOpenModalTypeMortal(false);
  }

  const [numberAffectedTypeMild, setNumberAffectedTypeMild] = useState(null);
  const [medicalRestDaysTypeMild, setMedicalRestDaysTypeMild] = useState(null);

  const [numberAffectedTypeDisabling, setNumberAffectedTypeDisabling] = useState(null);
  const [medicalRestDaysTypeDisabling, setMedicalRestDaysTypeDisabling] = useState(null);

  const [numberAffectedTypeMortal, setNumberAffectedTypeMortal] = useState(null);
  const [medicalRestDaysTypeMortal, setMedicalRestDaysTypeMortal] = useState(null);

  const handleActionModal = (e) => {
    e.preventDefault();
    const actions = {
      "createDisablingAccident": () => handlePostDisablingAccident(),
      "updateDisablingAccident": () => handleUpdateDisablingAccident(),
      "createMinorAccident": () => handlePostMinorAccident(),
      "updateMinorAccident": () => handleUpdateMinorAccident(),
      "createMortalAccident": () => handlePostMortalAccident(),
      "updateMortalAccident": () => handleUpdateMortalAccident(),
    }
    if (actions[modalButtonAction]) {
      actions[modalButtonAction]();
    }
  }

  // ver detalle de la enfermedad ocupacional
  const [openModalViewOcupationalDisease, setOpenModalViewOcupationalDisease] = useState(null);
  const handleOpenModalViewOcupationalDisease = (data) => {
    setOpenModalViewOcupationalDisease(true);
    // traer info
    setDiseaseName(data.disease_name);
    setStatusSelectAgentsTypes(data.id_registers_accidents_occupationals_incidents_agents);
    setFirstSemesterCount(data.first_semester_count);
    setSecondSemesterCount(data.second_semester_count);
    setAreaSelected(data.area);
    setCauses(data.causes);
    setMeasures(data.measures);
    setTimestampExecution(data.start_date_corrective_measures);
    setTimeStampEnd(data.end_date_corrective_measures)
    setResponsibleCorrectiveActions(data.first_name + " " + data.last_name);
  }

  const handleCloseModalViewOcupationalDisease = () => setOpenModalViewOcupationalDisease(false);

  // ! ------------------------------------- Generar PDF -------------------------------------
  const handleGeneratePDF = async () => {
    try {
      setOpenBackdrop(true);
      const response = await MyAccidentsOccupationalsIncidents.mypeGeneratePDF({ idRegistersAccOccInc });
      const blob = await response.blob();
      // Crear un enlace temporal para descargar el archivo
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `register-${idRegistersAccOccInc}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setOpenBackdrop(false);
    } catch {
      setOpenBackdrop(false);
      console.error('Hubo un error con la generacion del pdf', 'error');
    }
  };

  //tipos de accidentes
  const [rowsDisablingAccident, setRowsDisablingAccident] = useState([]);
  const [idDisablingAccident, setIdDisablingAccident] = useState(null)

  const handleGetDisablingAccident = async () => {
    const resp = await MyAccidentsOccupationalsIncidents.getDisablingAccident({ id_registers_accidents_occupationals_incidents: idRegistersAccOccInc }) ?? [];
    if (resp.success == true) {
      setRowsDisablingAccident(resp.data);
    } else {
      handleClickAlert("error", "Ocurrió un error al traer los accidentes tipo incapacitante");
    }

  }
  const handlePostDisablingAccident = async () => {
    setOpenBackdrop(true);

    const resp = await MyAccidentsOccupationalsIncidents.postDisablingAccident({
      id_registers_accidents_occupationals_incidents: idRegistersAccOccInc,
      id_registers_accidents_severities_incident: severityIncidents,
      medical_rest_days: medicalRestDaysTypeDisabling,
      affected_workers: numberAffectedTypeDisabling,
      body_part_ids: handleGetRowsIdPartsSelected(bodyPartsLists)
    });

    if (resp.success) {
      await handleGetDisablingAccident();
      handleClickAlert("success", "Se agregó el accidente tipo incapacitante con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al agregar accidente tipo incapacitante");
    }

    handleCloseBackdrop();
    handleCloseModalTypeDisabling();
  }

  const handleUpdateDisablingAccident = async () => {
    setOpenBackdrop(true);

    const resp = await MyAccidentsOccupationalsIncidents.updateDisablingAccident({
      id_registers_accidents_occupationals_incidents: idRegistersAccOccInc,
      id_registers_accidents_severities_incident: severityIncidents,
      medical_rest_days: medicalRestDaysTypeDisabling,
      affected_workers: numberAffectedTypeDisabling,
      id_disabling_accident: idDisablingAccident,
      body_part_ids: handleGetRowsIdPartsSelected(bodyPartsLists),
    });

    if (resp.success) {
      await handleGetDisablingAccident();
      handleClickAlert("success", "Se actualizó accidente tipo incapacitante con éxito");

    } else {
      handleClickAlert("error", "Ocurrió un error al actualizar accidente tipo incapacitante");
    }

    handleCloseBackdrop();
    handleCloseModalTypeDisabling();
  }

  const handleDeleteDisablingAccident = async () => {
    setOpenBackdrop(true);

    try {
      const resp = await MyAccidentsOccupationalsIncidents.deleteDisablingAccident({ id_disabling_accident: idDisablingAccident });

      await handleGetDisablingAccident();
      handleClickAlert("success", "Se eliminó el accidente incapacitante con éxito");
    } catch (e) {
      handleClickAlert("error", "Ocurrió un error al eliminar accidente");
    }

    handleCloseModalConfirm();
    handleCloseBackdrop();
  }

  const [rowsMinorAccident, setRowsMinorAccident] = useState([]);
  const [idMinorAccident, setIdMinorAccident] = useState(null);

  const handleGetMinorAccident = async () => {

    const resp = await MyAccidentsOccupationalsIncidents.getMinorAccident({ id_registers_accidents_occupationals_incidents: idRegistersAccOccInc }) ?? [];

    if (resp.success == true) {
      setRowsMinorAccident(resp.data);
    } else {
      handleClickAlert("error", "Ocurrió un error al traer lista de accidentes de tipo leve")
    }
  }

  const handlePostMinorAccident = async () => {
    setOpenBackdrop(true);
    const resp = await MyAccidentsOccupationalsIncidents.postMinorAccident({
      id_registers_accidents_occupationals_incidents: idRegistersAccOccInc,
      medical_rest_days: medicalRestDaysTypeMild,
      affected_workers: numberAffectedTypeMild,
      body_part_ids: handleGetRowsIdPartsSelected(bodyPartsLists)
    });
    if (resp.success) {
      await handleGetMinorAccident();
      handleClickAlert("success", "Se agregó accidente leve con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al agregar accidente tipo leve");
    }
    handleCloseBackdrop();
    handleCloseModalTypeMild();
  }

  const handleUpdateMinorAccident = async () => {
    setOpenBackdrop(true);
    const resp = await MyAccidentsOccupationalsIncidents.updateMinorAccident({
      id_minor_accident: idMinorAccident,
      id_registers_accidents_occupationals_incidents: idRegistersAccOccInc,
      medical_rest_days: medicalRestDaysTypeMild,
      affected_workers: numberAffectedTypeMild,
      body_part_ids: handleGetRowsIdPartsSelected(bodyPartsLists)
    });
    if (resp.success) {
      await handleGetMinorAccident();
      handleClickAlert("success", "Se actualizó el accidente leve con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al actualizar accidente tipo leve");
    }
    handleCloseBackdrop();
    handleCloseModalTypeMild();
  }

  const handleDeleteMinorAccident = async () => {
    setOpenBackdrop(true);
    try {
      const resp = await MyAccidentsOccupationalsIncidents.deleteMinorAccident({ id_minor_accident: idMinorAccident });
      await handleGetMinorAccident();
      handleClickAlert("success", "Se eliminó el accidente tipo leve con éxito");
    } catch (e) {
      handleClickAlert("error", "Ocurrió un error al eliminar accidente tipo leve");
    }
    handleCloseBackdrop();
    handleCloseModalConfirm();
  }

  const [rowsMortalAccident, setRowsMortalAccident] = useState([]);
  const [idMortalAccident, setIdMortalAccident] = useState(null);
  const handleGetMortalAccident = async () => {
    const resp = await MyAccidentsOccupationalsIncidents.getMortalAccident({ id_registers_accidents_occupationals_incidents: idRegistersAccOccInc }) ?? [];
    if (resp.success == true) {
      setRowsMortalAccident(resp.data);
    } else {
      handleClickAlert("error", "Ocurrió un error al traer lista de accidentes de tipo mortal");
    }
  }
  const handlePostMortalAccident = async () => {
    setOpenBackdrop(true);
    const resp = await MyAccidentsOccupationalsIncidents.postMortalAccident({
      id_registers_accidents_occupationals_incidents: idRegistersAccOccInc,
      medical_rest_days: medicalRestDaysTypeMortal,
      affected_workers: numberAffectedTypeMortal,
      body_part_ids: handleGetRowsIdPartsSelected(bodyPartsLists)
    });
    if (resp.success) {
      await handleGetMortalAccident();
      handleClickAlert("success", "Se agregó accidente mortal con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al agregar un accidente mortal");
    }
    handleCloseBackdrop();
    handleCloseModalTypeMortal();
  }

  const handleUpdateMortalAccident = async () => {
    setOpenBackdrop(true);
    const resp = await MyAccidentsOccupationalsIncidents.updateMortalAccident({
      id_mortal_accident: idMortalAccident,
      id_registers_accidents_occupationals_incidents: idRegistersAccOccInc,
      medical_rest_days: medicalRestDaysTypeMortal,
      affected_workers: numberAffectedTypeMortal,
      body_part_ids: handleGetRowsIdPartsSelected(bodyPartsLists)
    });
    if (resp.success) {
      await handleGetMortalAccident();
      handleClickAlert("success", "Se actualizó el accidente mortal con éxito");
    } else {
      handleClickAlert("error", "Ocurrió un error al actualizar el accidente mortal");
    }
    handleCloseBackdrop();
    handleCloseModalTypeMortal();
  }

  const handleDeleteMortalAccident = async () => {
    setOpenBackdrop(true);
    try {
      const resp = await MyAccidentsOccupationalsIncidents.deleteMortalAccident({ id_mortal_accident: idMortalAccident });
      await handleGetMortalAccident();
      handleClickAlert("success", "Se eliminó el accidente tipo mortal con éxito");

    } catch (e) {
      handleClickAlert("error", "Ocurrió un error al eliminar accidente mortal");
    }
    handleCloseBackdrop();
    handleCloseModalConfirm();
  }

  //partes del cuerpo lesionado
  const [bodyParts, setBodyParts] = useState([]);

  const handleGetBodyParts = async () => {
    const resp = await MyRegistersAccidents.getBodyParts() ?? [];
    setBodyParts(resp);
  }

  const [bodyPartSelected, setBodyPartSelected] = useState({});
  const [bodyPartsLists, setBodyPartsLists] = useState([]);

  const handleOnChangeBodyPart = (e) => {
    setBodyPartSelected(e);

    const isExist = bodyPartsLists.some((part) => part.id_body_parts == e.id_body_parts);

    if (!isExist) {
      setBodyPartsLists((part) => [...part, e])
    }
  }

  const handleDeleteBodyParts = (e) => {
    const newArray = bodyPartsLists.filter((p) => p !== e);
    setBodyPartsLists(newArray);
    setBodyPartSelected({});
  }

  //! ------------------- contratistas 
  const [selectedContractorsIds, setSelectedContractorsIds] = useState([]);
  const [rowsContractors, setRowsContractors] = useState([]);
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (selectedContractorsIds.length > 0) {
      setChecked(true)
    }
  }, [selectedContractorsIds])

  useEffect(() => {
    if (checked === false) {
      //setSelectedContractorsIds([])
    }
    else {
      handleGetContractorsEmployeesByIdRegister();
    }
  }, [checked])

  // Manejar la selección de contratistas
  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    // Guardar solo los IDs de los contratistas seleccionados
    setSelectedContractorsIds(value.map(option => option.id_contractors));
  };

  const handleChipDelete = (id) => {
    setSelectedContractorsIds((prevSelected) =>
      prevSelected.filter(contractorId => contractorId !== id)
    );
  };

  const getContractors = async () => {
    const resp = await MyContractors.getContractors() ?? [];
    setRowsContractors(resp.data);
  }

  useEffect(() => {
    handleEditAccidentsOccupational();
  }, [permissions]);

  useEffect(() => {
    getAreas();
    handleGetBodyParts();
    handleGetTypeRegisters();
    handleGetSeverityInccidents();
    // trabajadores relacionados
    if (!checked) {
      handleGetsetAccOccIncWorkers();
    }
    else {
      // ! obtener los trabajadores de los contratistas accidentados
      if (selectedContractorsIds.length < 1) return;
      handleGetContractorsEmployeesByIdRegister();
    }
    // medidas correctivas
    handleGetSearchsMeasuresUsers();
    handleGetCorrectivesActions();
    //* agentes
    handleGetAgentsTypess();
    handleGetRelsDAgentsTypes();
    // responsables
    handleGetResponsibleWorkers();
    // tipos de agentes tabla completa
    handleGetAgentsTypesTable();
    //tipos de accidentes
    handleGetDisablingAccident();
    handleGetMinorAccident();
    handleGetMortalAccident();
    getContractors();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack />

      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={
                  "Registro de accidentes de trabajo, enfermedades ocupacionales, incidentes peligrosos y otros indicentes"
                }
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={handleCheckboxChange}
                    disabled={!registerStatus}
                  />
                }
                label="Habilitar contratistas"
              />
              { /* //! ------------------------------ Contratistas ------------------------------ */}
              {checked && (
                <>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="multi-select-label">Contratistas</InputLabel>
                      <Select
                        labelId="multi-select-label"
                        multiple
                        disabled={!registerStatus}
                        value={rowsContractors.filter(option => selectedContractorsIds.includes(option.id_contractors))}
                        onChange={handleSelectChange}
                        input={<OutlinedInput label="Opciones" />}
                        renderValue={() => null} // Evitar que los chips se muestren dentro del select
                      >
                        {rowsContractors.map((option) => (
                          <MenuItem key={option.id_contractors} value={option}>
                            <ListItemText primary={option.company_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 8 }}>
                    {selectedContractorsIds.map((id) => {
                      const contractor = rowsContractors.find((contractor) => contractor.id_contractors === id);
                      return (
                        <Chip
                          key={id}
                          label={contractor?.company_name}
                          onDelete={() => handleChipDelete(id)}
                          style={{ margin: 2 }}
                        />
                      );
                    })}
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                roles={permissions.DOC_REGISMYPES_REGISMYPES_EDIT}
                text={"Nro registro"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
              />
            </Grid>
          </Grid>
        </Box>
        <form onSubmit={handleUpdateOccupationalsDiseases}>
          <Box sx={{ flexGrow: 1 }}>
            {/* DATOS DEL TRABAJADOR  */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Datos del trabajador
                </InputLabel>
                <Typography variant="caption" display="block" gutterBottom sx={{ color: '#F2994A', marginLeft: 1 }}>
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true && (
                  <ButtonControla
                    roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#35B4FB"}
                    textButton={"Agregar trabajadores accidentados"}
                    functionOnClick={handleOpenModalAddWorkerAccidented}
                  />
                )}
              </Box>
            </Stack>

            { /* //! ------------------------------ Empleados de los Contratistas ------------------------------ */}
            {/* tabla para mostrar accidents workers */}
            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {['Contratista', 'Nombre y Apellido', 'Documento de Identidad', 'Área', 'Turno', 'N.º de horas trabajadas antes del accidente', 'Tiempo de experiencia en el puesto de trabajo', ''].map(col => (
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >{col}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {checked ? (
                      (!rowContractorsEmployees || rowContractorsEmployees.length < 1)
                        ? (<>
                          <TableRow>
                            <TableCell colSpan={16} style={{
                              textAlign: 'center'
                            }}>No hay datos</TableCell>
                          </TableRow>
                        </>)
                        : (rowContractorsEmployees.map(registerEmployee => (
                          <TableRow>
                            <TableCell>{registerEmployee.company_name}</TableCell>
                            <TableCell>{registerEmployee.first_name} {registerEmployee.last_name}</TableCell>
                            <TableCell>{registerEmployee.document}</TableCell>
                            <TableCell>{registerEmployee.birthday ? new Date().getFullYear() - new Date(registerEmployee.birthday).getFullYear() : ''}</TableCell>
                            <TableCell>{registerEmployee.area}</TableCell>
                            <TableCell>{registerEmployee.shift}</TableCell>
                            <TableCell>{registerEmployee.work_experience}</TableCell>
                            <TableCell>{registerEmployee.work_hours_before}</TableCell>
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                                spacing={1}
                              >
                                {registerStatus === true && (
                                  <ButtonIconControla
                                    roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() => {
                                      handleOpenModalConfirm(
                                        registerEmployee.id,
                                        "¿Esta seguro de eliminar los datos del trabajador?",
                                        "Una vez eliminado no se podra recuperar los datos del trabajador",
                                        "Si, Eliminar",
                                        "deleteWorkers"
                                      );
                                    }}
                                  />
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        )))
                    ) : (
                      accOccIncWorkers.map((row) => (
                        <TableRow
                          hover
                          key={row.id_users}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.first_name + " " + row.last_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.document}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {areas.filter((a) => a.id_areas === row.area)[0]?.area}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.shift}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.work_hours_before}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.work_experience}
                          </TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                              spacing={1}
                            >
                              {registerStatus === true && (
                                <ButtonIconControla
                                  roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id_registers_accidents_occupationals_incidents_workers,
                                      "¿Esta seguro de eliminar los datos del trabajador?",
                                      "Una vez eliminado no se podra recuperar los datos del trabajador",
                                      "Si, Eliminar",
                                      "deleteWorkers"
                                    );
                                  }}
                                />
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      )))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Accidente de trabajo indicente peligro incidente */}
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Accidente de trabajo/incidente peligroso/incidente"}
                  color={blue}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  roles={permissions.DOC_REGISMYPES_REGISMYPES_EDIT}
                  text="Seleccione si es accidente de trabajo/incidente peligroso/incidente:"
                  inputValue={setTypesRegistersAccOcc}
                  modalType={false}
                  required
                  value={typesRegistersAccOcc}
                  childrenRows={rowsTypesRegisters.map((row) => (
                    <MenuItem
                      key={row.id_registers_accidents_occupationals_incidents_types}
                      value={row.id_registers_accidents_occupationals_incidents_types}
                    >{row.type}</MenuItem>
                  ))}
                />
              </Grid>
              <Typography variant="caption" display="block" gutterBottom sx={{ color: '#F2994A', ml: 2 }}>
                Este campo es obligatorio
              </Typography>
            </Grid>

            {/* INVESTIGACIÓN DEL ACCIDENTE DE TRABAJO */}
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText text={"Investigación"} color={blue} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  roles={permissions.DOC_REGISMYPES_REGISMYPES_EDIT}
                  text={`Fecha y hora de ocurrencia ${(typesRegistersAccOcc == null || typesRegistersAccOcc === 0)
                    ? "---" : (typesRegistersAccOcc == 1
                      ? "del accidente de trabajo" : (
                        typesRegistersAccOcc == 2 ? "del incidente peligroso" : "del incidente"))}`}
                  inputType={"datetime-local"}
                  inputValue={setTimestampAccident}
                  value={timestampAccident}
                  required
                />
                <Typography variant="caption" display="block" gutterBottom sx={{ color: '#F2994A' }}>
                  Este campo es obligatorio
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  roles={permissions.DOC_REGISMYPES_REGISMYPES_EDIT}
                  text={"Fecha de inicio de la investigación"}
                  inputType={"date"}
                  inputValue={setTimestampInvestigation}
                  value={timestampInvestigation}
                  required
                />
                <Typography variant="caption" display="block" gutterBottom sx={{ color: '#F2994A' }}>
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  roles={permissions.DOC_REGISMYPES_REGISMYPES_EDIT}
                  text={`Lugar exacto donde ocurrió ${typesRegistersAccOcc == null || typesRegistersAccOcc == 0 ? "---" : (typesRegistersAccOcc == 1 ? "el accidente de trabajo" : (typesRegistersAccOcc == 2 ? "el incidente peligroso" : "el incidente"))} `}
                  inputType={"text"}
                  inputValue={setLocation}
                  value={location}
                  required
                />
                <Typography variant="caption" display="block" gutterBottom sx={{ color: '#F2994A' }}>
                  Este campo es obligatorio
                </Typography>
              </Grid>
            </Grid>

            {/* Investigación del accidente de trabajo */}
            <Grid container spacing={2}>
              {
                typesRegistersAccOcc === 1 ? (
                  <>
                    {/* tipo leve */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{ marginTop: 3, marginBottom: 1 }}
                      >
                        <Box>
                          <InputLabel
                            sx={{
                              padding: "8px",
                              fontSize: "18px",
                              color: "#305AD9",
                              fontWeight: "bold",
                            }}
                          >
                            Gravedad del accidente del trabajo: Leve
                          </InputLabel>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            sx={{ color: "#F2994A", ml: 1 }}
                          >
                            Este campo es opcional de acuerdo al accidente
                          </Typography>
                        </Box>
                        <Box>
                          {
                            registerStatus === true && (
                              <ButtonControla
                                roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Agregar datos"}
                                functionOnClick={() => handleOpenModalTypeMild("createMinorAccident", null)}
                              />
                            )}
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 600 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {['N° trabajador afectado', 'N° días de descanso médico', 'Partes del cuerpo lesionado', ''].map(col => (
                                <TableCell
                                  sx={{
                                    color: "#305ad9",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    textAlign: "center"
                                  }}
                                >{col}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              rowsMinorAccident.map((row) => (
                                <TableRow
                                  hover
                                  key={row.id_accident_type_minor}
                                  sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                  }}
                                >
                                  <TableCell component="th" scope="row" align="center">
                                    {row.affected_workers}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center">
                                    {row.medical_rest_days}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <ul>
                                      {row.body_parts.map((p) => (
                                        <li key={p.body_part.id_body_parts}>{p.body_part.name}</li>
                                      ))}
                                    </ul>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Stack
                                      direction="row"
                                      justifyContent="end"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      {
                                        registerStatus === true && (
                                          <>
                                            <ButtonIconControla
                                              roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                                              icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                              backgroundColor={"#2D9CDB"}
                                              backgroundColorHover={"#33AEF4"}
                                              textTooltip={"Editar"}
                                              functionOnClick={() => handleOpenModalTypeMild("updateMinorAccident", row)}
                                            />

                                            <ButtonIconControla
                                              roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                                              icon={<Delete sx={{ color: "white" }} />}
                                              backgroundColor={"#EB5757"}
                                              backgroundColorHover={"#FF4040"}
                                              textTooltip={"Eliminar"}
                                              functionOnClick={() => handleOpenModalConfirm(
                                                row.id_accident_type_minor,
                                                "¿Estás seguro de eliminar los datos del accidente de tipo leve?",
                                                "Una vez eliminado no se podrá recuperar la información del accidente",
                                                "Si, eliminar",
                                                "deleteMinorAccident"
                                              )}
                                            />
                                          </>
                                        )}
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    {/* tipo incapacitante */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{ marginTop: 3, marginBottom: 1 }}
                      >
                        <Box>
                          <InputLabel
                            sx={{
                              padding: "8px",
                              fontSize: "18px",
                              color: "#305AD9",
                              fontWeight: "bold",
                            }}
                          >
                            Gravedad del accidente del trabajo: Incapacitante
                          </InputLabel>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            sx={{ color: "#F2994A", ml: 1 }}
                          >
                            Este campo es opcional de acuerdo al accidente
                          </Typography>
                        </Box>
                        <Box>
                          {
                            registerStatus === true && (
                              <ButtonControla
                                roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Agregar datos"}
                                functionOnClick={() => handleOpenModalTypeDisabling("createDisablingAccident", null)}
                              />
                            )}
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 600 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {['N° trabajador afectado', 'Gravedad del accidente incapacitante', 'N° días de descanso médico', 'Partes del cuerpo lesionado', ''].map(col => (
                                <TableCell
                                  sx={{
                                    color: "#305ad9",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    textAlign: "center"
                                  }}
                                >{col}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              rowsDisablingAccident.map((row) => (
                                <TableRow
                                  hover
                                  key={row.id_accident_type_disabling}
                                  sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                  }}
                                >
                                  <TableCell component="th" scope="row" align="center">
                                    {row.affected_workers}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center">
                                    {rowsSeverityIncidents?.filter((r) => (r.id_registers_accidents_severities_incident == row.id_registers_accidents_severities_incident))[0]?.register_accident_severity_incident}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center">
                                    {row.medical_rest_days}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <ul>
                                      {row.body_parts.map((p) => (
                                        <li key={p.body_part.id_body_parts}>{p.body_part.name}</li>
                                      ))}
                                    </ul>
                                  </TableCell>

                                  <TableCell align="right">
                                    <Stack
                                      direction="row"
                                      justifyContent="end"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      {
                                        registerStatus === true && (
                                          <>
                                            <ButtonIconControla
                                              roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                                              icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                              backgroundColor={"#2D9CDB"}
                                              backgroundColorHover={"#33AEF4"}
                                              textTooltip={"Editar"}
                                              functionOnClick={() => handleOpenModalTypeDisabling("updateDisablingAccident", row)}
                                            />

                                            <ButtonIconControla
                                              roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                                              icon={<Delete sx={{ color: "white" }} />}
                                              backgroundColor={"#EB5757"}
                                              backgroundColorHover={"#FF4040"}
                                              textTooltip={"Eliminar"}
                                              functionOnClick={() => handleOpenModalConfirm(
                                                row.id_accident_type_disabling,
                                                "¿Estás seguro de eliminar los datos del accidente de tipo incapacitante?",
                                                "Una vez eliminado no se podrá recuperar la información del accidente",
                                                "Si, eliminar",
                                                "deleteDisabblingAccident"
                                              )}
                                            />
                                          </>
                                        )}
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    {/* tipo mortal */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{ marginTop: 3, marginBottom: 1 }}
                      >
                        <Box>
                          <InputLabel
                            sx={{
                              padding: "8px",
                              fontSize: "18px",
                              color: "#305AD9",
                              fontWeight: "bold",
                            }}
                          >
                            Gravedad del accidente del trabajo: Mortal
                          </InputLabel>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            sx={{ color: "#F2994A", ml: 1 }}
                          >
                            Este campo es opcional de acuerdo al accidente
                          </Typography>
                        </Box>
                        <Box>
                          {
                            registerStatus === true && (
                              <ButtonControla
                                roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Agregar datos"}
                                functionOnClick={() => handleOpenModalTypeMortal("createMortalAccident", null)}
                              />
                            )}
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 600 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {['N° trabajador afectado', 'N° días de descanso médico', 'Partes del cuerpo lesionado', ''].map(col => (
                                <TableCell
                                  sx={{
                                    color: "#305ad9",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    textAlign: "center"
                                  }}
                                >{col}</TableCell>
                              ))}
                            </TableRow>

                          </TableHead>
                          <TableBody>
                            {
                              rowsMortalAccident.map((row) => (
                                <TableRow
                                  hover
                                  key={row.id_accident_type_mortal}
                                  sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                  }}
                                >
                                  <TableCell component="th" scope="row" align="center">
                                    {row.affected_workers}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center">
                                    {row.medical_rest_days}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <ul>
                                      {row.body_parts.map((p) => (
                                        <li key={p.body_part.id_body_parts}>{p.body_part.name}</li>
                                      ))}
                                    </ul>
                                  </TableCell>

                                  <TableCell align="right">
                                    <Stack
                                      direction="row"
                                      justifyContent="end"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      {
                                        registerStatus === true && (
                                          <>
                                            <ButtonIconControla
                                              roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                                              icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                              backgroundColor={"#2D9CDB"}
                                              backgroundColorHover={"#33AEF4"}
                                              textTooltip={"Editar"}
                                              functionOnClick={() => handleOpenModalTypeMortal("updateMortalAccident", row)}
                                            />

                                            <ButtonIconControla
                                              roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                                              icon={<Delete sx={{ color: "white" }} />}
                                              backgroundColor={"#EB5757"}
                                              backgroundColorHover={"#FF4040"}
                                              textTooltip={"Eliminar"}
                                              functionOnClick={() => handleOpenModalConfirm(
                                                row.id_accident_type_mortal,
                                                "¿Estás seguro de eliminar los datos del accidente de tipo mortal?",
                                                "Una vez eliminado no se podrá recuperar la información del accidente",
                                                "Si, eliminar",
                                                "deleteMortalAccident"
                                              )}
                                            />
                                          </>
                                        )}
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </>
                ) : ""
              }

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_REGISMYPES_REGISMYPES_EDIT}
                  text={"Descripción del suceso"}
                  inputType={"textArea"}
                  inputValue={setDescriptionWork}
                  value={descriptionWork}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_REGISMYPES_REGISMYPES_EDIT}
                  text={
                    `Descripción de las causas que originaron el ${typesRegistersAccOcc == null || typesRegistersAccOcc == 0 ? "---" : (typesRegistersAccOcc == 1 ? "accidente de trabajo" : (typesRegistersAccOcc == 2 ? "incidente peligroso" : "incidente"))}`
                  }
                  inputType={"textArea"}
                  inputValue={setcausesDescription}
                  value={causesDescription}
                />
              </Grid>
            </Grid>

            {/* medidas correctivas */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Medidas correctivas
                </InputLabel>
                <Typography variant="caption" display="block" gutterBottom sx={{ color: '#F2994A', marginLeft: 1 }}>
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true && (
                  <ButtonControla
                    roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_MEDIDAS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#35B4FB"}
                    textButton={"Agregar medidas correctivas"}
                    functionOnClick={handleOpenModalUploadCorrectiveActions}
                  />
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {['Descripción de la medida correctiva', 'Responsable de la implementación', 'Fecha de investigación', ''].map(col => (
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >{col}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {correctivesActions.map((rows) => (
                      <TableRow
                        hover
                        key={rows.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rows.description}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.first_name + " " + rows.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.timestamp_start.substring(0, 10)}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >

                            {registerStatus === true && (
                              <ButtonIconControla
                                roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_MEDIDAS}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    rows.id_registers_accidents_occupationals_incidents_measures,
                                    "¿Esta seguro de eliminar los datos de la medida correctiva?",
                                    "Una vez eliminado no se podra recuperar los datos de la medida correctiva",
                                    "Si, Eliminar",
                                    "deleteMeasures"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Datos refrentes a la enfermedad ocupacional */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Enfermedad ocupacional
                </InputLabel>
                <Typography variant="caption" display="block" gutterBottom sx={{ color: '#F2994A', marginLeft: 1 }}>
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box sx={{ marginRight: 55 }}>
                <ButtonIconControla
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#FFC300"}
                  backgroundColorHover={"#FACB32"}
                  textTooltip={"Ver enfermedades ocupacionales"}
                  functionOnClick={handleOpenModalTypeAgentsTable}
                />
              </Box>
              <Box>
                {registerStatus === true && (
                  <ButtonControla
                    roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#35B4FB"}
                    textButton={"Agregar datos"}
                    functionOnClick={handleOpenModaOccupationalDiseases}
                  />
                )}
              </Box>
            </Stack>
            {/* tabla para mostrar accidents workers */}
            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table
                  stickyHeader
                  sx={{ width: "100%", minWidth: "100%" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      {['Tipo de agente que originó la enfermedad ocupacional', 'Nombre de la enfermedad ocupacional', 'Medidas correctivas a implementar', 'Responsable', 'Fecha de ejecución'].map(col => (
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >{col}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {relsDAgentsTypes.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.agent_type}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.disease_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.measures}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.start_date_corrective_measures == null ? "" : row.start_date_corrective_measures.substring(0, 10)}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            <ButtonIconControla
                              icon={<VisibilityOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver mas información"}
                              functionOnClick={() => handleOpenModalViewOcupationalDisease(row)}
                            />
                            {registerStatus === true && (
                              <ButtonIconControla
                                roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_DATOS}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id_registers_accidents_occupationals_incidents_rels_agents_types,
                                    "¿Esta seguro de eliminar los datos de la enfermedad ocupacional?",
                                    "Una vez eliminado no se podra recuperar los datos de  la enfermedad ocupacional",
                                    "Si, Eliminar",
                                    "deleteOccupationalDiseases"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro y de la investigación
                </InputLabel>
                <Typography variant="caption" display="block" gutterBottom sx={{ color: '#F2994A', marginLeft: 1 }}>
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true && (
                  <ButtonControla
                    roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_RESP}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#35B4FB"}
                    textButton={"Agregar responsables"}
                    functionOnClick={handleOpenModalUploadResponsibleRegistration}
                  />
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {['Nombre y Apellido', 'Documento de identidad', 'Fecha de firma', 'Firma'].map(col => (
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                          align="center"
                        >{col}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.firm_date === null ? '' : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <ButtonSignatureControla
                            idUser={row.id_users}
                            firmStatus={row.firm}
                            firmUrl={row.firm_url}
                            functionOnClick={async () => {
                              await handleSignResponsible(row.id_registers_accidents_occupationals_incidents_responsible)
                            }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus === true && (
                              <ButtonIconControla
                                roles={permissions.DOC_REGISMYPES_REGISMYPES_AGREG_RESP}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id_registers_accidents_occupationals_incidents_responsible,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos  del trabajador responsable",
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                  // functionOnClick={""}
                />
              </Grid> */}
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={"/documentation/registers/mypes/accidentsoccupationalsincidents"}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                {registerStatus === true && (
                  <ButtonControla
                    roles={permissions.DOC_REGISMYPES_REGISMYPES_UPDATE}
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton="submit"
                  />
                )}
              </Grid>

              <Grid item xs={12} md={2}>
                {registerStatus === true && (
                  <ButtonControla
                    roles={permissions.DOC_REGISMYPES_REGISMYPES_CERRAR_REGIS}
                    iconButton={<CloseIcon sx={{ color: "white" }} />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#C4C4C4"}
                    textButton={"Cerrar registro"}
                    functionOnClick={handleAccidentsOcupationalEnd}
                  />
                )}
              </Grid>

              <Grid item xs={12} md={2}>
                {registerStatus === false && (
                  <ButtonControla
                    iconButton={<PictureAsPdf fontSize="inherit" />}
                    backgroundColor={"#F2994A"}
                    backgroundColorHover={"#FF881E"}
                    textButton={"Ver Documento PDF"}
                    functionOnClick={handleGeneratePDF}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>

      {/* //! ------------------------ Dialog agregar trabajador involucrado ------------------------ */}

      <ModalAddWorkerInvolved
        checked={checked}
        open={openModalUploadAddDates}
        handleClose={handleCloseModalUploadAddDates}
        handleOpenModalResponsibleInvolvedWorkers={handleOpenModalResponsibleInvolvedWorkers}
        responsibleCorrectiveActions={responsibleCorrectiveActions}
        areas={areas}
        areaSelected={areaSelected}
        setAreaSelected={setAreaSelected}
        handleStoreAccidentsWorkers={handleStoreAccidentsWorkers}
        shift={shift}
        handleChangeShift={handleChangeShift}
        workHoursBefore={workHoursBefore}
        setWorkHoursBefore={setWorkHoursBefore}
        workExperience={workExperience}
        setWorkExperience={setWorkExperience}
        callbackAlert={handleClickAlert}
        selectedIds={selectedContractorsIds}
        id_employee={idResponsible}
        handleCloseBackdrop={handleCloseBackdrop}
        handleCloseModalUploadAddDates={handleCloseModalUploadAddDates}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
        id_register_accident={idRegistersAccOccInc}
        fetchAccidentedWorkers={checked ? handleGetContractorsEmployeesByIdRegister : handleGetsetAccOccIncWorkers}
      />

      {/* Dialog agregar medidas correctivas */}
      <Dialog
        open={openModalUploadCorrectiveActions}
        onClose={handleCloseModalUploadCorrectiveActions}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar medidas correctivas
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreCorrectivesActions}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de ejecución"}
                  inputType={"date"}
                  inputValue={setTimestampStartMeasures}
                  value={timestampStartMeasures}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescriptionMeasures}
                  value={descriptionMeasures}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable de la implementación: "
                  modalType={true}
                  inputValue={responsibleCorrectiveActions}
                  functionClick={
                    handleOpenModalResponsibleSearchUsersCorrectiveActions
                  }
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadCorrectiveActions}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog de agentes enfermedades ocupacionales */}
      <Dialog
        open={openModalOccupationalDiseases}
        onClose={handleCloseModalOccupationalDiseases}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar datos referentes a las enfermedades ocupacionales
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreAccOccIncAgents}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Tipo de agente:"
                  inputValue={setStatusSelectAgentsTypes}
                  modalType={true}
                  required
                  value={statusSelectAgentsTypes}
                  childrenRows={agentsTypes.map((row) => (
                    <MenuItem
                      key={
                        row.id_registers_accidents_occupationals_incidents_agents
                      }
                      value={
                        row.id_registers_accidents_occupationals_incidents_agents
                      }
                    >
                      {row.agent_type}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "16px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  N° de trabajadores que adquirieron enfermedades ocupacionales
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Primer Semestre"}
                  inputType={"number"}
                  inputValue={setFirstSemesterCount}
                  value={firstSemesterCount}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Segundo Semestre"}
                  inputType={"number"}
                  inputValue={setSecondSemesterCount}
                  value={secondSemesterCount}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre de la Enfermedad Ocupacional"}
                  inputType={"text"}
                  inputValue={setDiseaseName}
                  value={diseaseName}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text={"Áreas donde se presentó la enfermedad ocupacional"}
                  inputValue={setAreaSelected}
                  value={areaSelected}
                  modalType={true}
                  childrenRows={areas.map((a) => (
                    <MenuItem
                      key={a.id_areas}
                      value={a.id_areas}
                    >
                      {a.area}
                    </MenuItem>
                  ))}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Causas que generaron la enfermedad ocupacional"}
                  inputType={"textArea"}
                  inputValue={setCauses}
                  value={causes}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Medidas Correctivas a implementar"}
                  inputType={"text"}
                  inputValue={setMeasures}
                  value={measures}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                <InputControlaVertical
                  text={"Fecha de Ejecución"}
                  inputType={"date"}
                  inputValue={setTimestampExecution}
                  value={timestampExecution}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setTimeStampEnd}
                  value={timeStampEnd}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable: "
                  modalType={true}
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalAgentsResponsible}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalOccupationalDiseases}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

      {/* //! ************************************ Modal buscar trabajadores involucrados ************************************************** */}
      {/* Dialogo de buscar trabajadores involucrados */}
      <Dialog
        open={openModalUploadSearchInvolvedWorkers}
        onClose={() => {
          setOpenModalUploadSearchInvolvedWorkers(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={checked ? contractorsEmployees : rowsSearchsAccOccIncWorkersUsers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalUploadSearchInvolvedWorkers(false)}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar usuarios de medidas correctivas */}
      <Dialog
        open={openModalUploadSearchUsersCorrectiveActions}
        onClose={() => {
          setOpenModalUploadSearchUsersCorrectiveActions(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsSearchsCorrectivesActionWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUploadSearchUsersCorrectiveActions(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar trabajadores Agregar datos referentes a las enfermedades ocupacionales */}
      <Dialog
        open={openModalUploadAgentsResponsible}
        onClose={() => {
          setOpenModalUploadAgentsResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsSearchsResponsiblesWorkersAgentesType}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUploadAgentsResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* ver mas datos de enfermedades ocupacionales */}
      <Dialog
        open={openModalViewOcupationalDisease}
        onClose={handleCloseModalViewOcupationalDisease}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitleControla
          titleText="Detalle de información de la enfermedad ocupacional"
          functionOnClose={handleCloseModalViewOcupationalDisease}
        />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                text={"Nombre de la Enfermedad Ocupacional"}
                inputType={"text"}
                inputValue={setDiseaseName}
                value={diseaseName}
                modalType={true}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SelectControla
                text="Tipo de agente que originó:"
                inputValue={setStatusSelectAgentsTypes}
                modalType={true}
                required
                value={statusSelectAgentsTypes}
                childrenRows={agentsTypes.map((row) => (
                  <MenuItem
                    key={
                      row.id_registers_accidents_occupationals_incidents_agents
                    }
                    value={
                      row.id_registers_accidents_occupationals_incidents_agents
                    }
                  >
                    {row.agent_type}
                  </MenuItem>
                ))}
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputLabel
                sx={{
                  padding: "8px",
                  fontSize: "16px",
                  color: "#305AD9",
                  fontWeight: "bold",
                }}
              >
                N° de trabajadores que adquirieron enfermedades ocupacionales
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputControlaVertical
                text={"Primer Semestre"}
                inputType={"number"}
                inputValue={setFirstSemesterCount}
                value={firstSemesterCount}
                modalType={true}
                readOnly
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputControlaVertical
                text={"Segundo Semestre"}
                inputType={"number"}
                inputValue={setSecondSemesterCount}
                value={secondSemesterCount}
                modalType={true}
                readOnly
                required
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SelectControla
                text={"Áreas donde se presentó la enfermedad ocupacional"}
                inputValue={setAreaSelected}
                value={areaSelected}
                modalType={true}
                childrenRows={areas.map((a) => (
                  <MenuItem
                    key={a.id_areas}
                    value={a.id_areas}
                  >
                    {a.area}
                  </MenuItem>
                ))}
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                text={"Causas que generaron la enfermedad ocupacional"}
                inputType={"textArea"}
                inputValue={setCauses}
                value={causes}
                modalType={true}
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                text={"Medidas Correctivas a implementar"}
                inputType={"text"}
                inputValue={setMeasures}
                value={measures}
                modalType={true}
                required
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={5} md={6} lg={6} xl={5}>
              <InputControlaVertical
                text={"Fecha de Ejecución"}
                inputType={"date"}
                inputValue={setTimestampExecution}
                value={timestampExecution}
                modalType={true}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={5} md={6} lg={6} xl={5}>
              <InputControlaVertical
                text={"Fecha de fin"}
                inputType={"date"}
                inputValue={setTimeStampEnd}
                value={timeStampEnd}
                modalType={true}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SearchButtonControla
                titleLabel="Responsable: "
                modalType={true}
                inputValue={responsibleCorrectiveActions}
                functionClick={handleOpenModalAgentsResponsible}
                disabledButton
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <ButtonControla
              textButton={"Cancelar"}
              backgroundColor={"#CBCBFF"}
              functionOnClick={handleCloseModalViewOcupationalDisease}
            />
          </Stack>
        </DialogActions>
      </Dialog>

      {/* ver typos de agentes y enfermedades tabla completa */}
      <Dialog
        open={openModalTypeAgentsTable}
        onClose={() => {
          setOpenModalTypeAgentsTable(false);
        }}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Tipos de Agentes
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid container sx={{ marginTop: "4px" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} >
                <TableHead>
                  <TableRow>
                    {agentsTable.map((column) => (
                      <TableCell
                        key={column.id_categories}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.category}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {agentsTable.map((row) => {
                    return (
                      <TableCell
                        key={row.id_categories}
                      >
                        {row.agents.map((column) => {
                          return (
                            <TableRow key={column.id_registers_occupational_diseases_agents_types}>
                              {column.agent_type}
                            </TableRow>
                          );
                        })}
                      </TableCell>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalTypeAgentsTable(false);
            }}
          >
            Cerrar
          </Button>
        </DialogActions>

      </Dialog>

      {/* dialogo de por  gravedad leve */}
      <Dialog
        open={openModalTypeMild}
        onClose={handleCloseModalTypeMild}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitleControla
          titleText={modalButtonAction == "createMinorAccident" ? "Agregar datos del accidente leve" : "Actualizar datos del accidente"}
          functionOnClose={handleCloseModalTypeMild}
        />
        <form onSubmit={handleActionModal}>
          <DialogContent>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de trabajador afectado"}
                  inputType={"text"}
                  inputValue={setNumberAffectedTypeMild}
                  value={numberAffectedTypeMild}
                  modalType={true}
                  readOnly
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de días de descanso médico"}
                  inputType={"text"}
                  inputValue={setMedicalRestDaysTypeMild}
                  value={medicalRestDaysTypeMild}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SelectControla
                    text="Seleccione partes del cuerpo lesionado:"
                    inputValue={(e) => handleOnChangeBodyPart(e)}
                    modalType={true}
                    value={bodyPartSelected}
                    childrenRows={bodyParts.map((row) => (
                      <MenuItem
                        key={row}
                        value={row}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    required
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      listStyle: "none",
                      gap: "5px",
                      paddingTop: "10px"
                    }}
                  >
                    {bodyPartsLists.map((e) => (
                      <li>
                        <Chip sx={{ color: "#305AD9" }}
                          label={e.name}
                          onDelete={() => { handleDeleteBodyParts(e) }}
                        />
                      </li>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <ButtonControla
                textButton={"Cancelar"}
                backgroundColor={"#CBCBFF"}
                functionOnClick={handleCloseModalTypeMild}
              />
              <ButtonControla
                textButton={modalButtonAction == "createMinorAccident" ? "Crear" : "Actualizar"}
                backgroundColor={"#389532"}
                typeButton={"submit"}
              />
            </Stack>
          </DialogActions>
        </form>
      </Dialog>

      {/* dialogo de agregar gravedad incapacitante */}
      <Dialog
        open={openModalTypeDisabling}
        onClose={handleCloseModalTypeDisabling}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitleControla
          titleText={modalButtonAction == "createDisablingAccident" ? "Agregar datos del accidente incapacitante" : "Actualizar datos del accidente incapacitante"}
          functionOnClose={handleCloseModalTypeDisabling}
        />
        <form onSubmit={handleActionModal}>
          <DialogContent>
            <Grid container spacing={2} justifyContent={"center"}>

              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° trabajador afectado"}
                  inputType={"text"}
                  inputValue={setNumberAffectedTypeDisabling}
                  value={numberAffectedTypeDisabling}
                  modalType={true}
                  readOnly
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <SelectControla
                  text="Gravedad del accidente incapacitante:"
                  inputValue={setSeverityIncidents}
                  modalType={true}
                  value={severityIncidents}
                  childrenRows={rowsSeverityIncidents.map((row) => (
                    <MenuItem
                      key={row.id_registers_accidents_severities_incident}
                      value={row.id_registers_accidents_severities_incident}
                    >
                      {row.register_accident_severity_incident}
                    </MenuItem>
                  ))}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de días de descanso médico"}
                  inputType={"text"}
                  inputValue={setMedicalRestDaysTypeDisabling}
                  value={medicalRestDaysTypeDisabling}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SelectControla
                    text="Seleccione partes del cuerpo lesionado:"
                    inputValue={(e) => handleOnChangeBodyPart(e)}
                    modalType={true}
                    value={bodyPartSelected}
                    childrenRows={bodyParts.map((row) => (
                      <MenuItem
                        key={row}
                        value={row}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    required
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      listStyle: "none",
                      gap: "5px",
                      paddingTop: "10px"
                    }}
                  >
                    {bodyPartsLists.map((e) => (
                      <li>
                        <Chip sx={{ color: "#305AD9" }}
                          label={e.name}
                          onDelete={() => { handleDeleteBodyParts(e) }}
                        />
                      </li>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <ButtonControla
                textButton={"Cancelar"}
                backgroundColor={"#CBCBFF"}
                functionOnClick={handleCloseModalTypeDisabling}
              />
              <ButtonControla
                textButton={modalButtonAction == "createDisablingAccident" ? "Crear" : "Actualizar"}
                backgroundColor={"#389532"}
                typeButton={"submit"}
              />
            </Stack>
          </DialogActions>
        </form>
      </Dialog>

      {/* dialogo de por  gravedad mortal */}
      <Dialog
        open={openModalTypeMortal}
        onClose={handleCloseModalTypeMortal}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitleControla
          titleText={modalButtonAction == "createMortalAccident" ? "Agregar datos del accidente mortal" : "Actualizar datos del accidente mortal"}
          functionOnClose={handleCloseModalTypeMortal}
        />
        <form onSubmit={handleActionModal}>
          <DialogContent>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° trabajador afectado"}
                  inputType={"text"}
                  inputValue={setNumberAffectedTypeMortal}
                  value={numberAffectedTypeMortal}
                  modalType={true}
                  readOnly
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de días de descanso médico"}
                  inputType={"text"}
                  inputValue={setMedicalRestDaysTypeMortal}
                  value={medicalRestDaysTypeMortal}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SelectControla
                    text="Seleccione partes del cuerpo lesionado:"
                    inputValue={(e) => handleOnChangeBodyPart(e)}
                    modalType={true}
                    value={bodyPartSelected}
                    childrenRows={bodyParts.map((row) => (
                      <MenuItem
                        key={row}
                        value={row}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    required
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      listStyle: "none",
                      gap: "5px",
                      paddingTop: "10px"
                    }}
                  >
                    {bodyPartsLists.map((e) => (
                      <li>
                        <Chip sx={{ color: "#305AD9" }}
                          label={e.name}
                          onDelete={() => { handleDeleteBodyParts(e) }}
                        />
                      </li>
                    ))}
                  </Box>
                </Grid>
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <ButtonControla
                textButton={"Cancelar"}
                backgroundColor={"#CBCBFF"}
                functionOnClick={handleCloseModalTypeMortal}
              />
              <ButtonControla
                textButton={modalButtonAction == "createMortalAccident" ? "Crear" : "Actualizar"}
                backgroundColor={"#389532"}
                typeButton={"submit"}
              />
            </Stack>
          </DialogActions>
        </form>
      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 99,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Edit;
