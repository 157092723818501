// colocar lo de Workaccidents.jsx
import * as React from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import BannerControla from "../../../../components/cards/bannerControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import LoadingControla from "../../../../components/load/loadingControla";
import MyRegistersAudits from "../../../../api/MyRegistersAudits";
import { useAppContext } from "../../../../context/AppContext";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";

function Index() {
  const {permissions} = useAppContext();
  const navigate = useNavigate();
  // alerts
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };
  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  const [code, setCode] = React.useState("");
  const [rowsActives, setRowsActives] = React.useState([]);
  const [openModalUpload, setOpenModalUpload] = React.useState(false);

  const handleOpenModalUpload = () => {
    setCode(null);
    setOpenModalUpload(true);
  };

  // BACKDROP
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };



  const handleGetRegistersAudits = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAudits.getRegistersAudit();
    if (response != null) {
      handleCloseBackdrop();
      setRowsActives(response);
    } else {
      console.log("error al obtener registros");
    }
    return response;
  };


  const handlePostCreateRegistersAudits = async (event) => {
    event.preventDefault();
    const response = await MyRegistersAudits.createRegisterAudit({
      code: code,
    });
    if (response != null) {
      navigate("/documentation/registers/generals/audits/edit?idRegisterAudit=" + response.id);
    } else {
      alert("error");
    }
    return response;
  };

  //datos del usuario logeado
  const userData = MyConfigurationApi.userData();
  const idUserLogged = userData.id_users;

  React.useEffect(() => {
    handleGetRegistersAudits();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>
      
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <ButtonMailboxControla
             originUrl={"/documentation/registers/generals/audits"}
            />
            
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/banneraudits.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Auditorias"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                roles ={permissions.DOC_REGIS_AUDITORIAS_CREAR_REGIS}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Crear nuevo registro"}
                functionOnClick={handleOpenModalUpload}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid item md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "50%",
                          }}
                        >
                          Código
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "30%",
                          }}
                        >
                          Estado
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {rowsActives.map((row) => (
                        <TableRow
                          key={row.id_registers_accidents}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "4px",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.code}
                          </TableCell>
                          <TableCell component="th" scope="row"  >
                            {row.register_status == 1? "Creado" : row.register_status == 2? "Finalizado" : "?"}
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {row.register_status === 1 && (
                                <ButtonIconControla
                                  roles ={ row.responsibles.filter((u) => u.id_users == idUserLogged).length !== 0 ? []:permissions.DOC_REGIS_AUDITORIAS_EDITAR}
                                  icon={ <BorderColorOutlined sx={{ color: "white" }} />}
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  isNavegationOrigin={true}
                                  directionUrl={ "/documentation/registers/generals/audits/edit?idRegisterAudit=" + row.id}
                                />
                              )}
                              {row.register_status === 2 && (
                                <ButtonIconControla
                                  roles ={permissions.DOC_REGIS_AUDITORIAS_VER}
                                  icon={ <VisibilityOutlined sx={{ color: "white" }} />}
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  isNavegationOrigin={true}
                                  directionUrl={ "/documentation/registers/generals/audits/edit?idRegisterAudit=" + row.id + "&readOnly=true"}
                                />
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalUpload}
        onClose={() => setOpenModalUpload(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar código del registro de Auditoría
          </Typography>
        </DialogTitle>
        <form onSubmit={handlePostCreateRegistersAudits}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <InputControlaVertical
              text={"Código"}
              inputType={"text"}
              inputValue={setCode}
              modalType={true}
              required
            />
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2} justifyContent={"center"}>
              <Box>
                <ButtonControla
                  backgroundColor={"#D1394E"}
                  backgroundColorHover={"#E24F63"}
                  textButton={"Cancelar"}
                  functionOnClick={() => setOpenModalUpload(false)}
                />
              </Box>
              <Box>
                <ButtonControla
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Crear registro"}
                  typeButton="submit"
                />
              </Box>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Index;
