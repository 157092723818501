import { React, useState, useEffect } from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  InputLabel,
  Stack,
  MenuItem,
  Paper,
  CardContent,
  Typography,
  CardMedia,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import {
  Add,
  ArrowBackOutlined,
  PictureAsPdf,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import { styled } from "@mui/system";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import MyPets from "../../../../api/MyPets";
import ButtonControla from "../../../../components/buttons/buttonController";
import LoadingControla from "../../../../components/load/loadingControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import SearchEppsControla from "../../../../components/search/searchEppsControla";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import { Edit as EditIcon } from "@mui/icons-material";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import MyUsers from "../../../../api/MyUsers";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import { useAppContext } from "../../../../context/AppContext";
import MyAreas from "../../../../api/MyAreas";
import SelectControla from "../../../../components/selects/selectControla";
import ButtonSignatureControla from "../../../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const grey = "#9191B0";
const blue = "#034AFF";
const StyledTypography = styled(Typography)({
  color: "#305AD9",
  fontSize: 16,
  fontWeight: 600,
  gap: "2%",
  padding: "0.5em",
});

function Edit() {
  const { permissions } = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const id_pets = params.get("idPets");
  const readOnlyStatus = params.get("readOnly") == "true" ? true :false;

  const [title, setTitle] = useState("");

  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // Pantallas de carga y alertas FIN

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [handleActionConfirm, setHandleActionConfirm] = useState();

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonAction
  ) => {
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setOpenModalConfirm(true);
    setHandleActionConfirm(buttonAction);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN
  const [codigo, setCodigo] = useState("");
  const [version, setVersion] = useState("");
  const [titulo, setTitulo] = useState("");
  const [staff, setStaff] = useState("");
  const [tools, setTools] = useState("");
  const [procedures, setProcedures] = useState("");
  const [restrictions, setRestrictions] = useState("");
  const [observations, setObservations] = useState("");

  const [idUsersPrepare, setIdUsersPrepar] = useState(0);
  const [idUsersReview1, setIdUsersReview1] = useState(0);
  const [idUsersReview2, setIdUsersReview2] = useState(0);
  const [idUsersApprove, setIdUsersApprove] = useState(0);

  const [publishedStatus, setPublishedStatus] = useState(0);
  const [pdfGenerateUrl, setPdfGenerateUrl] = useState("");

  const handleSearchPets = async () => {
    handleOpenLoading();

    if (Object.keys(permissions).length !== 0) {
      readOnlyStatus ? setTitle("Procedimiento Escrito de Trabajo Seguro") : setTitle("Editar Procedimiento Escrito de Trabajo Seguro")
      const response = await MyPets.searchPets({ id_pets });
      if (response.success === false) {

      } else {
        setAreaSelected(response.area);
        setCodigo(response.code);
        setVersion(response.version);
        setTitulo(response.name);
        setStaff(response.staff);
        setTools(response.tools);
        setProcedures(response.procedure);
        setRestrictions(response.restriction);
        setObservations(response.observation);
        setPdfGenerateUrl(response.pdf_generate_url);
        setIdUsersPrepar(response.id_users_prepare);
        setIdUsersReview1(response.id_users_review_1);
        setIdUsersReview2(response.id_users_review_2);
        setIdUsersApprove(response.id_users_approve);

        setUserPreparedUrlFirm(response.users_prepare_firm_url);
        setUserReview1UrlFirm(response.users_review_1_firm_url);
        setUserReview2UrlFirm(response.users_review_2_firm_url);
        setUserApproveUrlFirm(response.users_approve_firm_url);

        setPreparedBy(
          (response.first_name_prepare === null
            ? ""
            : response.first_name_prepare + " ") +
          (response.last_name_prepare === null
            ? ""
            : response.last_name_prepare) +
          (response.document_prepare === null
            ? ""
            : " - Documento: " + response.document_prepare)
        );

        setDatePreparedBy(
          response.date_prepare === null
            ? ""
            : response.date_prepare.substring(0, 19)
        );

        setDatePreparedBy(
          response.date_prepare === null
            ? ""
            : response.date_prepare.substring(0, 19)
        );

        setUserPreparedFirm(response.users_prepare_firm);
        setReview1By(
          (response.first_name_review_1 === null
            ? ""
            : response.first_name_review_1 + " ") +
          (response.last_name_review_1 === null
            ? ""
            : response.last_name_review_1) +
          (response.document_review_1 === null
            ? ""
            : " - Documento: " + response.document_review_1)
        );

        setDateReview1By(
          response.date_review_1 === null
            ? ""
            : response.date_review_1.substring(0, 19)
        );

        setDateReview1By(
          response.date_review_1 === null
            ? ""
            : response.date_review_1.substring(0, 19)
        );

        setUserReview1Firm(response.users_review_1_firm);

        setReview2By(
          (response.first_name_review_2 === null
            ? ""
            : response.first_name_review_2) +
          " " +
          (response.last_name_review_2 === null
            ? ""
            : response.last_name_review_2) +
          " " +
          (response.document_review_2 === null
            ? ""
            : " - Documento: " + response.document_review_2)
        );

        setDateReview2By(
          response.date_review_2 === null
            ? ""
            : response.date_review_2.substring(0, 19)
        );

        setDateReview2By(
          response.date_review_2 === null
            ? ""
            : response.date_review_2.substring(0, 19)
        );

        setUserReview2Firm(response.users_review_2_firm);

        setApproveBy(
          (response.first_name_approve === null
            ? ""
            : response.first_name_approve + " ") +
          (response.last_name_approve === null
            ? ""
            : response.last_name_approve + " ") +
          (response.document_approve === null
            ? ""
            : " - Documento: " + response.document_approve)
        );

        setDateApproveBy(
          response.date_approve === null
            ? ""
            : response.date_approve.substring(0, 19)
        );

        setDateApproveBy(
          response.date_approve === null
            ? ""
            : response.date_approve.substring(0, 19)
        );

        setUserApproveFirm(response.users_approve_firm);

        if (response.id_pets_statuses === 3) {
          setPublishedStatus(3);
        }
        handleOpenAlert("", "success");
      }
      return response;

    }
  };


  const handlePostEditPets = async (event) => {
    if(event !== undefined){
      event.preventDefault();
    }
    
    handleOpenLoading();
    const response = await MyPets.editPets({
      area: areaSelected,
      code: codigo,
      version,
      name: titulo,
      id_pets: id_pets,
      staff: staff,
      tools: tools,
      procedure: procedures,
      restriction: restrictions,
      observation: observations,
      id_users_prepare: idUsersPrepare,
      id_users_review_1: idUsersReview1,
      id_users_review_2: idUsersReview2,
      id_users_approve: idUsersApprove,
    });

 
    if(event !== undefined){

      if (response.success !== false) {
        handleOpenAlert(response.msg, "success");
      } else {
        handleOpenAlert("Hubo un error al actualizar", "success");
      }
    }
    
    return response;
  };

  // agregar EPPS
  const [openModalEpps, setOpenModalEpps] = useState(false);
  const [rowsEpps, setRowsEpps] = useState([]);

  const handleOpenModalEpps = () => {
    handlePetsNoEpps();
    setOpenModalEpps(true);
  };

  const handleCloseModalEpps = () => {
    setOpenModalEpps(false);
  };

  const handleSelectEpps = async (idEpps) => {
    handleOpenLoading();
    const response = await MyPets.eppsStore({
      id_pets: id_pets,
      id_epps: idEpps,
    });

    if (response.success !== false) {
      setRowsEpps([]);
      setOpenModalEpps(false);
      handlePetsNoEpps();
      handlePetsEpps();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Hubo un error", "error");
    }
    return response;
  };

  // const handleSelectDeleteEpps = async (idPetsEpps) => {
  //   handleOpenLoading();
  //   const response = await MyPets.eppsDelete({ id_pets_epps: idPetsEpps });

  //   if (response.success !== false) {
  //     setRowsEpps([]);
  //     setOpenModalEpps(false);
  //     handlePetsNoEpps();
  //     handlePetsEpps();
  //     handleOpenAlert(response.msg, "success");
  //   } else {
  //     handleOpenAlert("Hubo un error", "error");
  //   }
  //   return response;
  // };

  const handlePetsNoEpps = async () => {

    const response = await MyPets.eppsNoPetsGet({ id_pets: id_pets });
    if (response.success === false) {
      alert("Error");
    } else {
      setRowsEpps(response);
    }
    return response;
  };

  // listar epps de cada modelo PET

  const [rowsEppsPets, setRowsEppsPets] = useState([]);

  const handlePetsEpps = async () => {
    const response = await MyPets.eppsPetsGet({ id_pets: id_pets });
    if (response.success === false) {
      alert("Error listando los epps");
    } else {
      setRowsEppsPets(response);
    }
    return response;
  };

  // usuario logeado
  const [idUserLogged, setIdUserLogged] = useState(0);

  const handleUserActive = async () => {
    const response = await MyConfigurationApi.userData();
    setIdUserLogged(response.id_users);
  };

  // // agregar usuario que firma a preparado por
  const [datePreparedBy, setDatePreparedBy] = useState("");
  const [preparedBy, setPreparedBy] = useState("");
  const [userPreparedFirm, setUserPreparedFirm] = useState("");
  const [userPreparedUrlFirm, setUserPreparedUrlFirm]= useState("");


  const handleSignPreparedBy = async () => {
    handleOpenLoading();

    const response = await MyPets.signPreparedBy({
      id_pets: id_pets,
      id_users_prepare: idUserLogged,
      date_prepare: datePreparedBy,
    });
    if (response.success !== false) {
      await handleSearchPets();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
  };

  // // agregar usuario que firma a revisado 1 por
  const [dateReview1By, setDateReview1By] = useState("");
  const [review1By, setReview1By] = useState("");
  const [userReview1Firm, setUserReview1Firm] = useState("");
  const [userReview1UrlFirm, setUserReview1UrlFirm] = useState("");

  const handleSignReview1By = async () => {
    handleOpenLoading();

    const response = await MyPets.signReview1By({
      id_pets: id_pets,
      id_users_review_1: idUserLogged,
      date_review_1: dateReview1By,
    });
    if (response.success !== false) {
      await handleSearchPets();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
  };

  // // agregar usuario que firma a revisado 2 por
  const [dateReview2By, setDateReview2By] = useState("");
  const [review2By, setReview2By] = useState("");
  const [userReview2Firm, setUserReview2Firm] = useState("");
  const [userReview2UrlFirm, setUserReview2UrlFirm] = useState("");

  const handleSignReview2By = async () => {
    handleOpenLoading();
    const response = await MyPets.signReview2By({
      id_pets: id_pets,
      id_users_review_2: idUserLogged,
      date_review_2: dateReview2By,
    });
    if (response.success !== false) {
      await handleSearchPets();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
  };

  // // agregar usuario que firma a aprobado por
  const [dateApproveBy, setDateApproveBy] = useState("");
  const [approveBy, setApproveBy] = useState("");
  const [userApproveFirm, setUserApproveFirm] = useState("");
  const [userApproveUrlFirm, setUserApproveUrlFirm] = useState("");

 
  const handleSignApproveBy = async () => {
    handleOpenLoading();

    const response = await MyPets.signApprovedBy({
      id_pets: id_pets,
      id_users_approve: idUserLogged,
      date_approve: dateApproveBy,
    });
    if (response.success !== false) {
      await handleSearchPets();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
  };

  const handleGeneratePDF = async (event) => {
    try {
      const response = await MyPets.generatePDF({ id_pets: id_pets });
      const blob = await response.blob();

      // Crear un enlace temporal para descargar el archivo
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `pet-${id_pets}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch {
      handleOpenAlert('Hubo un error con la generacion del pdf', 'error');
    }
  };

  // agregar RESPONSABLES  DIALOG

  //listas trabajadores responsables
  const [usersActives, setUsersActives] = useState([]);
  const handleGetUsersActives = async () => {
    const response = await MyUsers.users();
    if (response.success === false) {
      alert("Error");
    } else {
      setUsersActives(response.users_actives);
    }
  };

  // jalar y mostrar datos del usuario preparado por
  const [openModalResponsiblePreparedBy, setOpenModalResponsiblePreparedBy] =
    useState(false);
  const handleOpenModalResponsiblePreparedBy = () => {
    setOpenModalResponsiblePreparedBy(true);
  };

  const handleGetUsersPreparedBy = async (idUser) => {
    const response = await MyUsers.editUsers({ idUsers: idUser });

    if (response.success === false) {
      alert("Error");
    } else {
      setPreparedBy(response.first_name + " " + response.last_name);
      setIdUsersPrepar(response.id_users);
      setUserPreparedUrlFirm(response.firm_url);
      setOpenModalResponsiblePreparedBy(false);
    }
  };

  // jalar y mostrar datos del usuario preprevisado 1arado por
  const [openModalResponsibleReview1By, setOpenModalResponsibleReview1By] =
    useState(false);
  const handleOpenModalResponsibleReview1By = () => {
    setOpenModalResponsibleReview1By(true);
  };
  const handleGetUsersReview1By = async (idUser) => {
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setReview1By(response.first_name + " " + response.last_name);
      setIdUsersReview1(response.id_users);
      setUserReview1UrlFirm(response.firm_url);

      setOpenModalResponsibleReview1By(false);
    }
  };

  // jalar y mostrar datos del usuario preprevisado 1arado por
  const [openModalResponsibleReview2By, setOpenModalResponsibleReview2By] =
    useState(false);
  const handleOpenModalResponsibleReview2By = () => {
    setOpenModalResponsibleReview2By(true);
  };
  const handleGetUsersReview2By = async (idUser) => {
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setReview2By(response.first_name + " " + response.last_name);
      setIdUsersReview2(response.id_users);
      setUserReview2UrlFirm(response.firm_url);

      setOpenModalResponsibleReview2By(false);
    }
  };

  // jalar y mostrar datos del usuario preparado por
  const [openModalResponsibleAproveBy, setOpenModalResponsibleAproveBy] =
    useState(false);
  const handleOpenModalResponsibleAproveBy = () => {
    setOpenModalResponsibleAproveBy(true);
  };

  const handleGetUsersAproveBy = async (idUser) => {
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setApproveBy(response.first_name + " " + response.last_name);
      setIdUsersApprove(response.id_users);
      setUserApproveUrlFirm(response.firm_url);
 
      setOpenModalResponsibleAproveBy(false);
    }
  };

  //cargar areas de la empresa
  const [areas, setAreas] = useState([]);
  const [areaSelected, setAreaSelected] = useState("");

  const getArea = async() => {
    const response = await MyAreas.getAreas();
    setAreas(response);
  }

  useEffect(() => {
    handleUserActive();
    handleGetUsersActives();
    handlePetsEpps();
    getArea();
  }, []);

  useEffect(()=>{
    handleSearchPets();
  },[permissions])


  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack returnBack='/documentation/documents/procedures/pets?idTabs=1'/>

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <SearchEppsControla
        openModalEpps={openModalEpps}
        handleCloseModalEpps={handleCloseModalEpps}
        handleClickSelectEpps={handleSelectEpps}
        rowsEpps={rowsEpps}
      />
      <Container sx={{ paddingBottom: 4 }}>
        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handlePostEditPets}>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              alignItems="center"
            >
              <Grid item md={10}>
                <SubtitleText
                  text={"Procedimientos"}
                  color={grey}
                ></SubtitleText>
                <TitleText
                  text={title}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                <CompanyLogoControla />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectControla
                 roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                 text={"Área"}
                 inputValue={setAreaSelected}
                 value={areaSelected}
                 childrenRows={ areas.map((a)=>(
                  <MenuItem key={a.id_areas} value={a.id_areas}>
                    {a.area}
                  </MenuItem>
                 ))}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                  text={"Código"}
                  inputType={"text"}
                  inputValue={setCodigo}
                  value={codigo}
                  modalType={false}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                  text={"Versión"}
                  inputType={"text"}
                  inputValue={setVersion}
                  value={version}
                  modalType={false}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitulo}
                  value={titulo}
                  modalType={false}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                  text={"Personal"}
                  inputType={"textArea"}
                  inputValue={setStaff}
                  value={staff}
                  modalType={false}
                  required
                />
              </Grid>

              {/* Equipos de proteccion personal */}
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <StyledTypography
                  sx={{
                    padding: "8px",
                    fontSize: "20px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  EPPS
                </StyledTypography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                {!readOnlyStatus && (
                  <ButtonControla
                    roles={permissions.DOC_HG_PROC_PETS_ACTI_AGREGAR_EPP}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar EPP"}
                    functionOnClick={handleOpenModalEpps}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper sx={{ overflow: "hidden" }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ width: "100%", margin: "auto" }}
                  >
                    {rowsEppsPets.map((rowsEpps) => (
                      <Grid
                        item
                        key={rowsEpps.id_epps}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                        sx={{ p: 2 }}
                      >
                        <Card
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: 80,
                            "&:hover": {
                              cursor: "pointer",
                              border: "1px solid #0F1255",
                              boxShadow: "0px 4px #0F1255",
                            },
                          }}
                          // onClick={() => {
                          //   handleSelectDeleteEpps(rowsEpps.id_ats_epps);
                          // }}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <Typography sx={{ fontSize: "14px" }}>
                                {rowsEpps.epp}
                              </Typography>
                            </CardContent>
                          </Box>
                          <CardMedia
                            component="img"
                            sx={{
                              maxWidth: 80,
                              marginLeft: 0,
                              maxHeight: 80,
                              objectFit: "contain",
                            }}
                            image={rowsEpps.photo_url}
                            alt="Live from space album cover"
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>

              {/* Equipos/ herramientas/materiales */}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                  text={"Equipos/herramientas/materiales"}
                  inputType={"textArea"}
                  inputValue={setTools}
                  value={tools}
                  modalType={false}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                  text={"Procedimiento"}
                  inputType={"textArea"}
                  inputValue={setProcedures}
                  value={procedures}
                  modalType={false}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                  text={"Restricciones"}
                  inputType={"textArea"}
                  inputValue={setRestrictions}
                  value={restrictions}
                  modalType={false}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                  text={"Observaciones:"}
                  inputType={"textArea"}
                  inputValue={setObservations}
                  modalType={false}
                  value={observations}
                />
              </Grid>

              {/* preparado por */}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                  <SubtitleText
                    text={
                      "Si ud. va a preparar y/o revisar y/o aprobar el documento registre su firma en el ícono firmar"
                    }
                    color={blue}
                  />{" "}
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {
                      <SearchButtonControla
                        roles={permissions.DOC_HG_PROC_PETS_ACTI_PREPARADO_POR}
                        titleLabel="Preparado por: "
                        inputValue={preparedBy}
                        functionClick={handleOpenModalResponsiblePreparedBy}
                        disabledButton={userPreparedFirm === 1}
                      />
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <InputControlaVertical
                      text={"Fecha de Elaboración"}
                      inputType={"text"}
                      inputValue={setDatePreparedBy}
                      value={datePreparedBy}
                      modalType={false}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <Stack>
                      <Box>
                        <InputLabel
                          sx={{
                            padding: "8px",
                            fontSize: "18px",
                            color: "#305AD9",
                            fontWeight: "bold",
                            textAlign: "center"
                          }}
                        >
                          Firma
                        </InputLabel>
                      </Box>
                      <Box sx={{textAlign: "center"}}>
                        <ButtonSignatureControla
                           idUser={idUsersPrepare}
                           firmStatus={userPreparedFirm}
                           firmUrl={userPreparedUrlFirm}
                          functionOnClick={async()=>await handleSignPreparedBy()}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              {/* revisado por (1) por */}

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {
                  <SearchButtonControla
                    roles={permissions.DOC_HG_PROC_PETS_ACTI_REVISADO_POR}
                    titleLabel="Revisado por(1): "
                    inputValue={review1By}
                    functionClick={handleOpenModalResponsibleReview1By}
                    disabledButton={userReview1Firm === 1}
                  />
                }
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  readOnly
                  text={"Fecha de revisión(1):"}
                  inputType={"text"}
                  inputValue={setDateReview1By}
                  value={dateReview1By}
                  modalType={false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Stack>
                  <Box>
                    <InputLabel
                      sx={{
                        padding: "8px",
                        fontSize: "18px",
                        color: "#305AD9",
                        fontWeight: "bold",
                        textAlign: "center"
                      }}
                    >
                      Firma
                    </InputLabel>
                  </Box>
                  <Box sx={{textAlign: "center"}}>
                    <ButtonSignatureControla
                       idUser={idUsersReview1}
                       firmStatus={userReview1Firm}
                       firmUrl={userReview1UrlFirm}
                       functionOnClick={async()=> await handleSignReview1By()}
                    />
                  </Box>
                </Stack>
              </Grid>

              <Divider />

              {/* revisado por (2) */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {
                      <SearchButtonControla
                        roles={permissions.DOC_HG_PROC_PETS_ACTI_REVISADO_POR}
                        titleLabel="Revisado por(2): "
                        inputValue={review2By}
                        functionClick={handleOpenModalResponsibleReview2By}
                        disabledButton={userReview2Firm === 1}
                      />
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <InputControlaVertical
                      text={"Fecha de revisión(2):"}
                      inputType={"text"}
                      readOnly
                      inputValue={setDateReview2By}
                      value={dateReview2By}
                      modalType={false}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <Stack>
                      <Box>
                        <InputLabel
                          sx={{
                            padding: "8px",
                            fontSize: "18px",
                            color: "#305AD9",
                            fontWeight: "bold",
                            textAlign: "center"
                          }}
                        >
                          Firma
                        </InputLabel>
                      </Box>
                      
                      <Box sx={{textAlign: "center"}}>
                        <ButtonSignatureControla
                           idUser={idUsersReview2}
                           firmStatus={userReview2Firm}
                           firmUrl={userReview2UrlFirm}
                           functionOnClick={async() => await handleSignReview2By()}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>

              <Divider />

              {/* aprobado  por */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {
                      <SearchButtonControla
                        roles={permissions.DOC_HG_PROC_PETS_ACTI_APROBADO_POR}
                        titleLabel="Aprobado por: "
                        inputValue={approveBy}
                        functionClick={handleOpenModalResponsibleAproveBy}
                        disabledButton={userApproveFirm === 1}
                      />
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <InputControlaVertical
                      readOnly
                      text={"Fecha de aprobación:"}
                      inputType={"text"}
                      inputValue={setDateApproveBy}
                      value={dateApproveBy}
                      modalType={false}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <Stack>
                      <Box>
                        <InputLabel
                          sx={{
                            padding: "8px",
                            fontSize: "18px",
                            color: "#305AD9",
                            fontWeight: "bold",
                            textAlign: "center"
                          }}
                        >
                          Firma
                        </InputLabel>
                      </Box>
                      <Box>
                        <ButtonSignatureControla
                            idUser={idUsersApprove}
                            firmStatus={userApproveFirm}
                            firmUrl={userApproveUrlFirm}
                            functionOnClick={async() => await handleSignApproveBy()}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>

              {/* BOTONES DE CERRAR */}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                sx={{ paddingTop: 8 }}
              >
                <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                  <Box>
                    <ButtonControla
                      roles = {permissions.DOC_HG_PROC_PETS_ACTI_REGRESAR}
                      iconButton={<ArrowBackOutlined sx={{ color: "white" }} />}
                      backgroundColor={"#CBCBFF"}
                      textButton={"Regresar"}
                      backgroundColorHover={"#CBCBFF"}
                      typeButton={"button"}                   
                      isNavegationOrigin={true}
                      url={"/documentation/documents/procedures/pets?idTabs=1"}
                    />
                  </Box>
                  <Box>
                    {publishedStatus === 3 && (
                        <ButtonControla
                          iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                          backgroundColor={"#F2994A"}
                          backgroundColorHover={"#FF881E"}
                          textButton={"Ver documento generado"}
                          typeButton={"button"}
                          functionOnClick={handleGeneratePDF}
                        />
                      )
                    }
                  </Box>

                  <Box>
                    {!readOnlyStatus && (
                      <ButtonControla
                        roles={permissions.DOC_HG_PROC_PETS_ACTI_ACTUALIZAR}
                        iconButton={<EditIcon sx={{ color: "white" }} />}
                        backgroundColor={"#169073"}
                        backgroundColorHover={"#1BAA88"}
                        textButton={"Actualizar PETS"}
                        typeButton={"submit"}
                      />
                    )}
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Buscar responsables */}
      {/* preparado por */}
      <Dialog
        open={openModalResponsiblePreparedBy}
        onClose={() => {
          setOpenModalResponsiblePreparedBy(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de aprobar el PETS
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersPreparedBy}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalResponsiblePreparedBy(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Buscar responsables */}
      {/* revisado 1 por */}
      <Dialog
        open={openModalResponsibleReview1By}
        onClose={() => {
          setOpenModalResponsibleReview1By(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de revisar (1) el PETS
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersReview1By}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalResponsibleReview1By(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Buscar responsables */}
      {/* revisado 2 por */}
      <Dialog
        open={openModalResponsibleReview2By}
        onClose={() => {
          setOpenModalResponsibleReview2By(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de revisar (2) el PETS
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersReview2By}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalResponsibleReview2By(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Buscar responsables */}
      {/* aprovado  por */}
      <Dialog
        open={openModalResponsibleAproveBy}
        onClose={() => {
          setOpenModalResponsibleAproveBy(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Aprobar el PETS
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersAproveBy}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalResponsibleAproveBy(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Edit;
