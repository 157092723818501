import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import SubtitleText from "../../components/text/subtitleText";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import MyUsers from "../../api/MyUsers";
import BannerButtonControla from "../../components/cards/bannerButtonControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import LoadingControla from "../../components/load/loadingControla";
import { useAppContext } from "../../context/AppContext";
import TableControla from "../../components/tables/table-controla";
import ListPassword from "./list-pasword";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";
import ReturnBack from "../../components/buttons/returnBackControla";

const grey = "#9191B0";
const purple = "#8B8BD8";

function Index() {
  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  const [user, setUser] = useState([]);

  const [newPassword, setNewPassword] = useState("");
  const [visibilityPassword, setVisibilityPassword] = useState(false);

  const [userId, setUserId] = useState(0);

 
  const handleGetUser = async () => {
    handleOpenLoading();

    const response = await MyConfigurationApi.userData();

    if (response.success === false) {

    } else {
      setUser(response);
      setUserId(response.id_users);
    }

    setOpenLoading(false);
    return response;
  };

  const { logoCompany } = useAppContext();

  // cargar usuarios con archivos masivos
  const [openModalLoadUserFile, setOpenModalLoadUserFile] = useState(false);
  const handleOpenModalUpload = () => {
    setOpenModalLoadUserFile(true);
  };

  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);
  const handleOpenModalPassword = () => {
    setOpenModalChangePassword(true);
  };

  const [loadUserFile, setLoadUserFile] = useState();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setLoadUserFile(file);
  };

  const handleCreatUserFile = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    let formData = new FormData();
    formData.append("users_file", loadUserFile);

    const response = await fetch(
      process.env.REACT_APP_API_DJANGO+"api/users/import",
      {
        method: "POST",
        body: formData,
      }
    );
    if (response.status === 200) {
      handleGetUser();
      setOpenModalLoadUserFile(false);
      handleOpenAlert(
        "Los datos fueron registrados de forma correcta",
        "success"
      );
    } else {
      alert("Error al importar usuarios");
      handleOpenAlert("Hubo un error al cargar los datos", "error");
    }
    return response;
  };
  const changePassword = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyUsers.changeUserPassword(userId, newPassword);

    handleOpenAlert(
      "Los datos fueron registrados de forma correcta",
      "success"
    );
    setOpenModalChangePassword(false);
    handleGetUser();
  };

  const togglePassword = () => {
    setVisibilityPassword(prev => !prev)
  }

  useEffect(() => {
    handleGetUser();
  }, []);

  return (
    <>
      <Box >
        <ReturnBack/>

        <LoadingControla
          openLoading={openLoading}
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          messageAlert={messageAlert}
          typeAlert={typeAlert}
        />
        <Container>
          <Box>
            <Grid container>
              <Grid item md={12}>
                <ButtonMailboxControla
                  originUrl={"/settings/password"}
                />
              </Grid>

              <Grid item md={7}>
                <SubtitleText text={"Contraseñas"} color={grey}></SubtitleText>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <BannerButtonControla
                  color1={"#8FBCFF"}
                  color2={"#2F67BC"}
                  title={"Mi Contraseña"}
                  image={logoCompany}
                />
              </Grid>
            </Grid>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="start"
              sx={{ marginTop: 3, marginBottom: 3 }}
            ></Stack>

            <TableControla
              loading={openLoading}
              tabs={[
                {
                  columnNames: ["Nombre y Apellidos", "Email / Usuario"]
                }
              ]}
            >
              <ListPassword
                user={user}
                colorCell={purple}
                setOpenModalChangePassword={setOpenModalChangePassword}
              />

            </TableControla>
          </Box>
        </Container>

        {/* Dialog para Editar contraseña */}
        <Dialog
          open={openModalChangePassword}
          onClose={() => setOpenModalChangePassword(false)}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Cambiar Contraseña
            </Typography>
          </DialogTitle>
          <form onSubmit={changePassword}>
            <DialogContent sx={{ paddingTop: "0" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack display="flex" gap={4} flexDirection="row" justifyContent="center" alignItems="end">
                  <InputControlaVertical
                    text={"Nueva Contraseña"}
                    modalType={true}
                    inputValue={setNewPassword}
                    value={newPassword}
                    type={visibilityPassword ? "text" : "password"}
                  />

                  <Box sx={{ padding: '5px 0' }}>
                    <IconButton
                      onClick={togglePassword}
                    >
                      {!visibilityPassword ? (
                        <Visibility/>
                        ) : (
                          <VisibilityOff/>
                        )
                      }
                      
                    </IconButton>
                  </Box>
                </Stack>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#6969D6",
                  backgroundColor: "#CBCBFF",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
                onClick={() => setOpenModalChangePassword(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#169073",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
              >
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </>
  );
}

export default Index;
