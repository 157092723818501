import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import TitleText from "../text/titleText";

function ErrorViews({ urlImg, textError, msgError }) {
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Container>
        <Stack justifyContent="end" alignItems="center">
          <img
            style={{
              width: "50%",
              marginBottom:"2rem",
            }}
            src={urlImg}
            alt="Error 403"
          />
          <TitleText text={textError} />
          <Typography>{msgError}</Typography>
        </Stack>
      </Container>
    </Box>
  );
}

export default ErrorViews;
