import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import {
  Add,
  BorderColorOutlined,
  Clear,
  LockResetRounded,
  Search,
  UploadFile,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import SubtitleText from "../../components/text/subtitleText";
import ButtonControla from "../../components/buttons/buttonController";
import MyUsers from "../../api/MyUsers";
import BannerButtonControla from "../../components/cards/bannerButtonControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import LoadingControla from "../../components/load/loadingControla";
import { useAppContext } from "../../context/AppContext";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";
import ReturnBack from "../../components/buttons/returnBackControla";
import GenerateExcel from "./generateExcel";
import ButtonIconControla from "../../components/buttons/buttonIconControla";


const grey = "#9191B0";
const purple = "#8B8BD8";

function Index() {
  const { logoCompany, permissions } = useAppContext();

  const rolesButtonCreateUser = permissions ? permissions.CONF_USER_CREAR : null;
  const rolesButtonImportUser = permissions ? permissions.CONF_USER_IMPORT : null;

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [userId, setUserId] = useState("");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  if (!permissions) {
    handleOpenAlert('No se pudieron obtener los permisos', 'error')
  }
  //fin de alerts

  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);

  const handleChangeTab = (event, newValue) => {
    setActive(newValue);
    setPage(0);
  }

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  // Search
  const [search, setSearch] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const handleClearSearch = () => {
    setSearch('');
    setPage(0)
    handleGetUsers();
  }

  const handleSearch = (event) => {
    const input = event.target.value.trim();
    if (input === search) return;
    setSearch(input);
    setPage(0);

    // Limpiar 
    if (debounceTimeout) clearTimeout(debounceTimeout);

    const newTimeout = setTimeout(() => {
      if (input || input === "") {
        handleGetUsers(input, 0)
      }
    }, 500);
    setDebounceTimeout(newTimeout);
  }


  const [users, setUsers] = useState([]);
  const [visibilityPassword, setVisibilityPassword] = useState(false);

  const togglePassword = () => {
    setVisibilityPassword(prev => !prev)
  }

  const handleGetUsers = async (search = '', pageN) => {
    handleOpenLoading();
    const response = await MyUsers.usersPagination({ page: pageN ?? page, rowsPerPage, search, active });
    if (!response || response.success === false) {
      handleOpenAlert('error', response?.message ?? 'No se pudo obtener los datos')
    }
    else {
      if (response.current_page) setPage(parseInt(response.current_page));
      if (response.per_page) setRowsPerPage(parseInt(response.per_page));
      if (response.total) setTotalRows(parseInt(response.total));
      if (response.last_page) setLastPage(parseInt(response.last_page));
      setUsers(response.data);
    }

    setOpenLoading(false);
    return response;
  };

  // cargar usuarios con archivos masivos
  const [openModalLoadUserFile, setOpenModalLoadUserFile] = useState(false);
  const handleOpenModalUpload = () => {
    setOpenModalLoadUserFile(true);
  };

  const handleCleanUploadFileData = () => {
    setRowsInserted(0);
    setRowsWithDuplicatedEmails(null);
    setRowsWithErrors(null);
    setLoadUserFile(null)
  }

  const handleCloseModalUploadFile = () => {
    setOpenModalLoadUserFile(false);
    handleCleanUploadFileData();
  }

  const [loadUserFile, setLoadUserFile] = useState();
  const [uploadFileError, setUploadFileError] = useState('');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // Validar si el archivo es un Excel o CSV
    const validTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.ms-excel', // .xls
      'text/csv', // .csv
      'application/csv',
      'text/comma-separated-values',
      'application/vnd.ms-excel'
    ];
    if (selectedFile && validTypes.includes(selectedFile.type)) {
      setLoadUserFile(selectedFile);
      setRowsInserted(0);
      setRowsWithDuplicatedEmails(null);
      setRowsWithErrors(null);
      setUploadFileError('');
    } else {
      handleCleanUploadFileData();
      setUploadFileError('Por favor, sube un archivo Excel (.xlsx, .xls, .csv)');
    }
  };

  const [rowsInserted, setRowsInserted] = useState(0);
  const [rowsWithErrors, setRowsWithErrors] = useState(null);
  const [rowsWithDuplicatedEmails, setRowsWithDuplicatedEmails] = useState(null);

  const [checkedItems, setCheckedItems] = useState({});
  const [allChecked, setAllChecked] = useState(false);

  const handleAllCheckboxChange = () => {
    setRowsWithDuplicatedEmails(prevData =>
      prevData.map(item => ({
        ...item,
        checked: !allChecked
      }))
    );
    setAllChecked(prevState => !prevState);
  };

  const handleCheckboxChange = (email) => {
    setRowsWithDuplicatedEmails(prevData => (
      prevData.map(item => {
        return item['Correo Electrónico'] === email
          ? { ...item, checked: !item.checked } : item
      })
    ));
  };

  const handleCreatUserFile = async (event, duplicates = false) => {
    event.preventDefault();
    if (!duplicates) {
      setRowsInserted(0);
      setRowsWithErrors(null);
      setRowsWithDuplicatedEmails(null);
    }
    if (!loadUserFile && !duplicates) {
      handleCleanUploadFileData();
      handleOpenAlert('Por favor, sube un archivo válido (.xlsx, .xls, .csv)', 'error');
      return setUploadFileError('Por favor, sube un archivo válido (.xlsx, .xls, .csv)');
    }

    //handleOpenLoading();
    //andleOpenAlert('Procesando sus datos', 'info');
    setOpenLoading(true);
    setMessageAlert('Creando usuarios...');
    setTypeAlert('info');
    setOpenAlert(true)
    setUploadFileError('');

    let formData = new FormData();
    if (!duplicates) {
      formData.append("users_file", loadUserFile)
    }
    else {
      if (!rowsWithDuplicatedEmails.some(row => row.checked))
        return handleOpenAlert('Debe seleccionar al menos uno o cierre el modal si no desea reemplazar los correos.', 'error');
      formData.append("rows", JSON.stringify(rowsWithDuplicatedEmails.filter(row => row.checked)));
      setOpenLoading(false);
    }
    const userToken = MyConfigurationApi.tokenData().userToken;
    try {
      const response = await fetch(
        process.env.REACT_APP_API_DJANGO + "api/users/import",
        {
          method: "POST",
          headers: { Authorization: "Bearer " + userToken + "", },
          body: formData,
        }
      );
      // Datos
      const data = await response.json();
      if (!response || response.ok === false) {
        handleOpenAlert(data?.error || 'No se pudo importar los usuarios!', 'error');  
      }
      if (response.ok) {
        handleGetUsers();
        handleOpenAlert("Los datos fueron registrados de forma correcta", "success");
      }
      if (data?.rows_inserted) {
        setRowsInserted(data.rows_inserted);
      }

      if (data?.rows_with_errors) {
        setRowsWithErrors(data.rows_with_errors);
      }
      if (data?.rows_with_emails_duplicated) {
        setRowsWithDuplicatedEmails(data.rows_with_emails_duplicated)
        // Inicializa el estado de los checkboxes
        const dataWithChecked = data.rows_with_emails_duplicated.map(item => ({
          ...item,
          checked: false // Inicializa la propiedad checked como false
        }));
        setRowsWithDuplicatedEmails(dataWithChecked);
      }

      if (!response.ok && data?.message) {
        return handleOpenAlert(`No se pudo cargar los datos: ${data?.message}`, "error");
      }
      setTimeout(() => handleCloseAlert(), 5000);
      return response;
    }
    catch (error) {
      console.error(error);
      handleOpenAlert('No se pudo guardar los registros, error de servidor!', 'error');
    }
  };

  const changePassword = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyUsers.changeUserPassword(userId, newPassword);

    handleOpenAlert(
      "Los datos fueron registrados de forma correcta",
      "success"
    );
    setOpenModalChangePassword(false);
    handleGetUsers();
  };

  useEffect(() => {
    handleGetUsers(search, page);
  }, [active, page, rowsPerPage, rowsInserted]);

  return (
    <>
      <Box >
        <ReturnBack />

        <LoadingControla
          openLoading={openLoading}
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          messageAlert={messageAlert}
          typeAlert={typeAlert}
        />

        <Container>
          <Box>
            <Grid container>
              <Grid item md={12}>
                <ButtonMailboxControla originUrl={"/settings/users"} />
              </Grid>

              <Grid item md={7}>
                <SubtitleText text={"Usuarios"} color={grey}></SubtitleText>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <BannerButtonControla
                  color1={"#8FBCFF"}
                  color2={"#2F67BC"}
                  title={"Mis Usuarios"}
                  image={logoCompany}
                />
              </Grid>
            </Grid>

            {
              !permissions ? (
                <span>No hay permisos</span>
              ) : (
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  justifyContent="start"
                  sx={{ marginTop: 3, marginBottom: 3 }}
                >
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <ButtonControla
                      roles={rolesButtonCreateUser}
                      iconButton={<Add />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Crear usuario"}
                      isNavegationOrigin={true}
                      url={"/settings/users/create"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <ButtonControla
                      roles={rolesButtonImportUser}
                      iconButton={<UploadFile />}
                      backgroundColor={"#2D9CDB"}
                      backgroundColorHover={"#33AEF4"}
                      textButton={"Importar usuarios"}
                      functionOnClick={handleOpenModalUpload}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={8}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      placeholder={"Buscar registros..."}
                      type={"text"}
                      value={search}
                      onChange={handleSearch}
                      style={{
                        width: '100%'
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          search.length > 0 && (
                            <InputAdornment position="end">
                              <Clear sx={{ color: "#1638F2" }} onClick={handleClearSearch} />
                            </InputAdornment>
                          )
                        ),
                      }}
                      sx={{
                        backgroundColor: "#FFF",
                        borderRadius: "16px",
                      }}
                    />
                  </Grid>
                </Grid>
              )
            }
            <Grid container>
              <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                <TableContainer sx={{ maxHeight: '60vh', marginBottom: '0', overflowY: 'auto' }}>
                  <Table sx={{ minWidth: '100%', }} stickyHeader aria-label="simple table">

                    <TableHead style={{ width: '100%' }}>
                      <TableRow>
                        {["#", "Nombre completo", "Documento de identidad", "Email / Usuario"].map((th, index) => (
                          <TableCell sx={{ color: "blue", lineHeight: '1.1rem' }} key={index}>{th}</TableCell>
                        ))}
                        <TableCell sx={{ position: 'sticky', right: 0, background: 'white' }}>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Tabs value={active} onChange={handleChangeTab} aria-label="status">
                              <Tab label="Habilitados" value={1} />
                              <Tab label="Deshabilitados" value={0} />
                            </Tabs>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody style={{ background: 'rgba(250,250,250, 1)' }}>
                      {
                        users && users.map((user, index) => (
                          <TableRow key={user.id} sx={{ padding: "4px", }}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{user.first_name} {user.last_name}</TableCell>
                            <TableCell>{user.document}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <ButtonIconControla
                                  roles={permissions.CONF_USER_EDITAR}
                                  icon={
                                    <BorderColorOutlined sx={{ color: "white" }} />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  isNavegationOrigin={true}
                                  directionUrl={"/settings/users/edit?idUsers=" + user.id_users}
                                />
                                <ButtonIconControla
                                  roles={permissions.CONF_USER_EDITAR}
                                  icon={
                                    <LockResetRounded sx={{ color: "white" }} />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Cambiar contraseña"}
                                  functionOnClick={() => {
                                    setOpenModalChangePassword(true)
                                    setUserId(user.id_users)
                                  }}
                                />
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* //TODO: ------------- Paginación en español ------------- */}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Filas por página:"
                  labelDisplayedRows={() => `${page + 1}-${lastPage} de ${totalRows}`}
                  getItemAriaLabel={(type) => {
                    if (type === 'first') {
                      return 'Ir a la primera página';
                    }
                    if (type === 'last') {
                      return 'Ir a la última página';
                    }
                    if (type === 'next') {
                      return 'Ir a la página siguiente';
                    }
                    if (type === 'previous') {
                      return 'Ir a la página anterior';
                    }
                  }}
                  sx={{
                    width: '100%',
                    backgroundColor: "#FFF",
                    border: "1px solid #E0E0E0",
                    color: "#1638F2",
                    fontSize: "18px",
                    lineHeight: "1.2rem",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Dialog
          open={openModalChangePassword}
          onClose={() => setOpenModalChangePassword(false)}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Cambiar Contraseña
            </Typography>
          </DialogTitle>
          <form onSubmit={changePassword}>
            <DialogContent sx={{ paddingTop: "0" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack display="flex" gap={4} flexDirection="row" justifyContent="center" alignItems="end">
                  <InputControlaVertical
                    text={"Nueva Contraseña"}
                    modalType={true}
                    inputValue={setNewPassword}
                    value={newPassword}
                    type={visibilityPassword ? "text" : "password"}
                  />

                  <Box sx={{ padding: '5px 0' }}>
                    <IconButton
                      onClick={togglePassword}
                    >
                      {!visibilityPassword ? (<Visibility />) : (<VisibilityOff />)}
                    </IconButton>
                  </Box>
                </Stack>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#6969D6",
                  backgroundColor: "#CBCBFF",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
                onClick={() => setOpenModalChangePassword(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#169073",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
              >
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        {/* Dialog para agregar archivo de usuarios */}
        <Dialog
          open={openModalLoadUserFile}
          onClose={handleCloseModalUploadFile}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Agregar usuarios
            </Typography>
          </DialogTitle>
          <form onSubmit={handleCreatUserFile}>
            <DialogContent sx={{ paddingTop: "0", marginBottom: "0.2rem" }}>
              <Grid item xs={12}>
                <span style={{ fontSize: "0.9rem" }}>Genera tu plantilla y súbela para el registro masivo de usuarios.</span>
              </Grid>
              <GenerateExcel callbackAlert={handleOpenAlert} callbackLoading={handleOpenLoading}
              />
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{
                marginTop: '1.2rem'
              }}>
                <label htmlFor="file" style={{ fontSize: "0.9rem" }}>Subir archivo excel:</label>
                <input type="file" onChange={handleFileChange} name="file" accept=".xlsx, .xls, .csv" style={{
                  background: 'rgba(230,230,230,0.7)', width: '100%', textAlign: 'center',
                  borderRadius: '10px', padding: '0.5rem',
                }} />
                {uploadFileError && <p style={{ color: 'red' }}>{uploadFileError}</p>}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#6969D6",
                  backgroundColor: "#CBCBFF",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
                onClick={handleCloseModalUploadFile}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#169073",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
              >
                Guardar
              </Button>
            </DialogActions>
          </form>
          {
            ((rowsInserted || rowsWithErrors || rowsWithDuplicatedEmails)
              && (rowsInserted.length > 0 || rowsWithErrors?.length > 0 || rowsWithDuplicatedEmails?.length > 0)) ? (
              <DialogContent sx={{ paddingTop: "0.5rem", background: 'rgba(210,210,210, 0.2)', width: "100%" }}>
                <Grid container sx={{ marginBottom: '0rem' }}>
                  <Grid item xs={12} sx={{ textAlign: "center", fontSize: "1.5rem", paddingTop: "0.5rem" }}>
                    <strong>Detalles de la importación</strong>
                  </Grid>
                </Grid>
                <Grid container spacing={0} sx={{
                  marginTop: '0.5rem', width: "100%", margin: 0, display: "flex", justifyContent: "center"
                }}>
                  {rowsWithErrors && rowsWithErrors.length > 0 ? (
                    <TableContainer>
                      <h5 style={{ textAlign: "center", fontSize: "1.2rem", marginBottom: "0.6rem" }}>Filas con errores</h5>
                      <Table
                        sx={{ width: '100%' }}
                        aria-labelledby="tableTitle"
                        size="small"
                      >
                        <TableHead sx={{ background: 'rgba(0,0,0,0.1)' }}>
                          <TableRow>
                            {['Fila', 'Errores', 'Email', 'Nombre'].map((th => (
                              <TableCell style={{ maxWidth: 'min-content', fontSize: '0.7rem' }}>{th}</TableCell>
                            )))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rowsWithErrors?.map((row) => (
                            <TableRow>
                              <TableCell style={{ maxWidth: 'min-content', fontSize: '0.7rem' }}>{row.row}</TableCell>
                              <TableCell style={{ width: '100%', fontSize: '0.7rem' }}>{row.errors.join(',\n')}</TableCell>
                              <TableCell style={{ maxWidth: 'min-content', fontSize: '0.7rem', padding: '0.2rem', lineBreak: "normal" }}>{row.email}</TableCell>
                              <TableCell style={{ maxWidth: 'min-content', fontSize: '0.7rem' }}>{row.user}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : ('')}
                  {rowsWithDuplicatedEmails && rowsWithDuplicatedEmails.length > 0 ? (
                    <TableContainer>
                      <div className="row" style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: "0.2rem"
                      }}>
                        <h5 style={{ textAlign: "center", fontSize: "1.4rem", marginBottom: '0.4rem' }}>Correos duplicados: {rowsWithDuplicatedEmails.length ?? 0}</h5>
                        <div style={{ fontSize: '0.8rem', display: 'flex', flexDirection: 'column' }}>
                          <span>Los siguientes correos <strong>ya están</strong> registrados en la base de datos.</span>
                          <span>El sistema <strong>generará</strong> nuevos usuarios con los mismos correos y <strong>deshabilitará</strong> a los usuarios anteriores.</span>
                          <span>Seleccione marcando las casillas al final de la fila.</span>
                          <strong>¿Deseas proceder?</strong>
                        </div>
                        <Button variant="contained" onClick={(event) => handleCreatUserFile(event, true)} sx={{ marginLeft: '0.4rem' }}>Confirmar</Button>
                      </div>
                      <Table
                        size="small"
                        sx={{ width: '100%' }}
                      >
                        <TableHead sx={{ background: 'rgba(0,0,0,0.1)' }}>
                          <TableRow>
                            {['Fila', 'Email duplicado', 'Usuario nuevo', 'Usuario registrado', 'Fecha de registro previo', <>Seleccionar todos <input
                              type="checkbox"
                              checked={allChecked}
                              onChange={handleAllCheckboxChange}
                            /></>].map(row => (
                              <TableCell style={{ lineHeight: '0.91rem', fontSize: '0.9rem' }}>{row}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            rowsWithDuplicatedEmails.map((row) => (
                              <TableRow>
                                <TableCell style={{ width: 'auto', fontSize: '0.7rem' }}>{row['row']}</TableCell>
                                <TableCell style={{ maxWidth: 'min-content', fontSize: '0.7rem', lineBreak: 'auto' }}>
                                  {row['Correo Electrónico']}</TableCell>
                                <TableCell style={{ width: 'auto', maxWidth: 'min-content', fontSize: '0.7rem', lineBreak: 'auto' }}>
                                  {`${row['Nombres']} ${row['Apellido paterno']} ${row['Apellido materno']}`}
                                </TableCell>
                                <TableCell style={{ width: 'auto', maxWidth: 'min-content', fontSize: '0.7rem' }}>
                                  {row['prev_name']}
                                </TableCell>
                                <TableCell style={{ maxWidth: 'min-content', fontSize: '0.7rem' }}>
                                  {row['date_created'] ? new Date(row['date_created']).toLocaleDateString() : ''}
                                </TableCell>
                                <TableCell style={{ maxWidth: 'min-content', fontSize: '0.7rem' }}>
                                  <input
                                    type="checkbox"
                                    checked={row.checked}
                                    onChange={() => handleCheckboxChange(row['Correo Electrónico'])}
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : ('')}
                  {rowsInserted && rowsInserted.length > 0 ? (
                    <TableContainer>
                      <h5 style={{ textAlign: "center", fontSize: "1.2rem", marginBottom: "0.6rem" }}>
                        Filas insertadas: {rowsInserted?.length ?? 0}</h5>
                      <Table
                        sx={{ width: '100%' }}
                        aria-labelledby="tableTitle"
                        size="small"
                      >
                        <TableHead sx={{ background: 'rgba(0,0,0,0.1)' }}>
                          <TableRow>
                            {['Fila', 'Email', 'Número de documento','Nombre'].map((th => (
                              <TableCell style={{ maxWidth: 'min-content', fontSize: '0.7rem', lineHeight: '0.9rem' }}>{th}</TableCell>
                            )))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rowsInserted?.map((row) => (
                            <TableRow>
                              <TableCell style={{ maxWidth: 'min-content', fontSize: '0.7rem' }}>{row.row}</TableCell>
                              <TableCell style={{ maxWidth: 'min-content', fontSize: '0.7rem', padding: '0.2rem', lineBreak: "normal" }}>{row['Correo Electrónico']}</TableCell>
                              <TableCell style={{ maxWidth: 'min-content', fontSize: '0.7rem' }}>{row['Numero de Identificacion']}</TableCell>
                              <TableCell style={{ width: 'auto', maxWidth: 'min-content', fontSize: '0.7rem', lineBreak: 'auto' }}>
                                {`${row['Nombres']} ${row['Apellido paterno']} ${row['Apellido materno']}`}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : ('')}
                </Grid>
              </DialogContent>
            ) : ('')
          }
        </Dialog>
      </Box>
    </>
  );
}

export default Index;
