import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import {
    BorderColorOutlined,
    Delete,
    Archive,
    Publish,
  } from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import ButtonIconControla from "../../../components/buttons/buttonIconControla"

import { useAppContext } from "../../../context/AppContext";

function ListActives(props) {
    const {permissions} = useAppContext();

    const {
        totalRisstActivos,
        colorCell,
        handleOpenModalUploadFileEdit,
        handleOpenModalConfirm
    } = props

    return (
        <TableBody>
            {totalRisstActivos.map((row) => (
                <TableRow
                    hover
                    key={row.id}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell sx={{ color: colorCell }}>
                        {row.title}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.document_text === null ||
                            row.document_text === undefined
                            ? "Archivo"
                            : row.document_url !== null
                                ? "Sistema"
                                : ""}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.register_status === 1 ? "Creado" : ""}
                        {row.register_status === 7
                            ? "Enviado a trabajadores"
                            : ""}
                        {row.register_status === 3
                            ? "Enviado a comité"
                            : ""}
                        {row.register_status === 4 ? "Publicado" : ""}
                        {row.register_status === 5 ? "Archivado" : ""}{" "}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {row.register_status === 1 && (
                                <ButtonIconControla
                                    roles={permissions.DOC_RISST_ACTIVO_EDITAR}
                                    icon={ <BorderColorOutlined sx={{ color: "white" }}/>}
                                    backgroundColor={"#2D9CDB"}
                                    backgroundColorHover={"#33AEF4"}
                                    textTooltip={"Editar"}
                                    functionOnClick={() => {
                                        handleOpenModalUploadFileEdit(row.id);
                                    }}
                                />
                            )}

                            {(row.register_status === 1 && row.document_url !== undefined) && (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_RISST_ACTIVO_PUBLICAR}
                                        icon={<Publish sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Publicar"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                row.id,
                                                "¿Estás seguro de publicar?",
                                                " ",
                                                "Si, publicar",
                                                "public"
                                            );
                                        }}
                                    />
                                    <ButtonIconControla
                                        icon={<VisibilityOutlined sx={{ color: "white" }} /> }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        directionUrl={row.document_url}
                                        directionTarget={"_blank"}
                                    />
                                </>
                            )}

                            {(row.register_status === 4 &&
                                row.document_text !== undefined) && (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_RISST_ACTIVO_ARCHIVAR}
                                        icon={<Archive sx={{ color: "white" }} />}
                                        backgroundColor={"#F2994A"}
                                        backgroundColorHover={"#FF881E"}
                                        textTooltip={"Archivar"}
                                        functionOnClick={() =>
                                            handleOpenModalConfirm(
                                                row.id,
                                                "¿Estás seguro de archivar el documento?",
                                                'Documento a archivar:  "' +
                                                row.title +
                                                '"',
                                                "Si, archivar",
                                                "archived"
                                            )
                                        }
                                    />
                                    <ButtonIconControla
                                        roles={permissions.DOC_RISST_ACTIVO_VER}
                                        icon={ <VisibilityOutlined sx={{ color: "white" }} /> }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        isNavegationOrigin={true}
                                        directionUrl={"/documentation/risst/template?idRisst=" + row.id +"&readOnly=true"}
                                    />
                                </>
                            )}

                            {(row.document_url !== undefined && row.register_status === 4) && (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_RISST_ACTIVO_ARCHIVAR}
                                        icon={<Archive sx={{ color: "white" }} />}
                                        backgroundColor={"#F2994A"}
                                        backgroundColorHover={"#FF881E"}
                                        textTooltip={"Archivar"}
                                        functionOnClick={() =>
                                            handleOpenModalConfirm(
                                                row.id,
                                                "¿Estás seguro de archivar el documento?",
                                                'Documento a archivar:  "' +
                                                row.title +
                                                '"',
                                                "Si, archivar",
                                                "archivedTemplate"
                                            )
                                        }
                                    />

                                    <ButtonIconControla
                                        roles={permissions.DOC_RISST_ACTIVO_VER}
                                        icon={ <VisibilityOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        directionUrl={row.document_url}
                                        directionTarget={"_blank"}
                                    />
                                </>
                            )}

                            {(row.register_status !== 7 &&
                                row.register_status !== 3 &&
                                row.register_status !== 4 ) && (
                                <ButtonIconControla
                                    roles={permissions.DOC_RISST_ACTIVO_ELIMINAR}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() => {
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de eliminar los datos del RISST?",
                                            "Una vez eliminado no se podra recuperar los datos de " +
                                            row.title,
                                            "Si, Eliminar",
                                            "delete"
                                        );
                                    }}
                                />
                            )}
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )
}
export default ListActives;