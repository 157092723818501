import { Button, Grid } from '@mui/material';
import React from 'react'

// Excel
import { saveAs } from 'file-saver';

import MyUsers from '../../api/MyUsers';
import MyCompanies from '../../api/MyCompanies';

export default function GenerateExcel({ callbackAlert, callbackLoading }) {

    const [positions, setPositions] = React.useState([]);
    const [jobTypes, setJobTypes] = React.useState([]);
    const [healthEntity, setHealthEntity] = React.useState([]);

    // Función para leer el archivo Excel existente y agregar columnas con menús desplegables
    const readAndModifyExcel = () => {
        try {
            // Uso workers porque el generar el archivo colgaba la aplicacion
            const worker = new Worker(new URL('./workers/excelWorker.js', import.meta.url));

            callbackLoading();
            worker.postMessage({ positions, jobTypes, healthEntity });

            worker.onmessage = (event) => {
                const { success, blob, error, type } = event.data;
                if (type) {
                    return callbackAlert(error, type);
                }
                if (success) {
                    saveAs(blob, `PlantillaGenerada-${Date('Y-m-d h:m:i')}.xlsx`);
                    callbackAlert('Plantilla generada!', 'success');
                } else {
                    callbackAlert('Error al generar plantilla: ' + error, 'error');
                }
            };
            worker.onerror = (error) => {
                callbackAlert('Error en el worker: ' + error.message, 'error');
            };
        }
        catch (err) {
            callbackAlert('Error en el proceso de generar plantilla: ' + err, 'error');
        }
    };

    React.useEffect(() => {
        const handleGetDynamicData = async () => {
            try {
                const [positionsRes, jobTypesRes, healthEntity] = await Promise.all([
                    MyUsers.positions(),
                    MyUsers.jobTypes(),
                    MyCompanies.healthEntity(),
                ]);

                // Si la empresa no tiene cargos generados debe enviar un mensaje de información pidiendo que cree su cargos primero
                if (!positionsRes || positionsRes.length === 0 || positionsRes.status === 'error') {
                    return callbackAlert('No se encontraron cargos para su empresa. Debe de crear primero al menos un cargo.', 'info');
                }
                // Guardamos cargos
                setPositions(positionsRes)

                if (!jobTypesRes || jobTypesRes.length === 0 || jobTypesRes.status === 'error') {
                    return callbackAlert('No se encontraron tipos de puestos para su empresa. Debe de  crear primero al menos un tipo de puesto.', 'error');
                }
                // Guardamos puestos
                setJobTypes(jobTypesRes)

                if (!healthEntity || healthEntity.length === 0 || healthEntity.status === 'error') {
                    return callbackAlert("No se encontraron EPS's para su empresa. Debe de  crear primero al menos una.", 'error');
                }
                setHealthEntity(healthEntity)

            } catch (err) {
                console.error(err);
                callbackAlert('Error!: ' + err.message, 'Error');
            }
        }

        handleGetDynamicData();
    }, []);

    return (
        <Grid container spacing={2} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: '0.2rem',
        }}>
            <Grid item xs={12} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}>
                <Button variant='contained' color='success' onClick={readAndModifyExcel}>Generar plantilla en excel</Button>
            </Grid>
        </Grid>
    )
}
