import { Stack, TableBody, TableCell, TableRow, MenuItem } from "@mui/material";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import SelectControlaRoles from "../../../../components/selects/selectControlaRoles";
import Link from '@mui/material/Link';
import {
    BorderColorOutlined,
    Delete
} from "@mui/icons-material";
import CommentIcon from '@mui/icons-material/Comment';

import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";

function ListWorkersTasks(props) {
    const {permissions} = useAppContext();
    const navigate = useNavigate();
    
    const {
        tasks,
        colorCell,
        handleUpdateTaskStatus,
        handleOpenDialogEditTask,
        handleOpenDialogDeleteTask,
        setStatus,
        taskStatus,

    } = props

    return (
        <TableBody>
            {tasks.map((row) =>
                <TableRow
                    hover
                    // key={row.id}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >

                    <TableCell
                        sx={{ color: colorCell, width: "20%" }}
                        component="th"
                        scope="row"
                        align="center"
                    >
                        {row.title}
                    </TableCell>
                    <TableCell
                        sx={{ color: colorCell, width: "20%" }}
                        component="th"
                        scope="row"
                        align="center"
                    >
                        {row.description}
                    </TableCell>
                    <TableCell
                        sx={{ color: colorCell, width: "18%" }}
                        component="th"
                        scope="row"
                        align="center"
                    >
                        {row.date_start === null
                            ? "Sin fecha de inicio"
                            : row.date_start}
                    </TableCell>
                    <TableCell
                        sx={{ color: colorCell, width: "8%" }}
                        component="th"
                        scope="row"
                        align="center"
                    >
                        {row.date_end === null
                            ? "Sin fecha de fin"
                            : row.date_end}
                    </TableCell>

                    <TableCell
                        sx={{ color: colorCell, width: "10%" }}
                        component="th"
                        scope="row"
                        align="center"
                    >
                        {row.first_name + " " + row.last_name}
                    </TableCell>

                    <TableCell
                        sx={{ color: colorCell, width: "10%" }}
                        component="th"
                        scope="row"
                        align="center"
                    >
                        {row.origin_url !== null ? (<Link href={"/" + row.origin_url}>{row.task_type}</Link>) : row.task_type}
                    </TableCell>
                    <TableCell
                        sx={{ color: colorCell, width: "10%" }}
                        component="th"
                        scope="row"
                        align="center"
                    >
                        <SelectControlaRoles
                            roles={permissions.ACTIVIDADES_TAREAS_DE_TRABAJADORES_EDIT_ESTADOS}
                            inputValue={setStatus}
                            value={row.id_status}
                            childrenRows={taskStatus.map((rows) => (
                                <MenuItem
                                    key={rows.id_tasks_status}
                                    value={rows.id_tasks_status}
                                >
                                    {rows.status}
                                </MenuItem>
                            ))}
                            onChange={
                                (event) => {
                                    handleUpdateTaskStatus({
                                        idTask: row.id_tasks,
                                        status: event.target.value,
                                    });
                                }

                            }
                        />
                    </TableCell>
                    <TableCell sx={{ width: "20%" }}>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                roles={permissions.ACTIVIDADES_TAREAS_DE_TRABAJADORES_EDITAR}
                                icon={
                                    <BorderColorOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#169073"}
                                textTooltip={"Editar"}
                                functionOnClick={() =>
                                    handleOpenDialogEditTask({
                                        idTask: row.id_tasks,
                                        initialDate: row.date_start,
                                        endDate: row.date_end,
                                        description: row.description,
                                        idResponsible: row.id_users,
                                        title: row.title,
                                        nameUser: row.first_name + " " + row.last_name
                                    })
                                }
                            />
                            <ButtonIconControla
                                roles={permissions.ACTIVIDADES_TAREAS_DE_TRABAJADORES_OCURRENCIA}
                                icon={
                                    <CommentIcon
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#FFC300"}
                                backgroundColorHover={"#FFC300"}
                                textTooltip={"Ocurrencias"}
                                isNavegationOrigin={true}
                                directionUrl={"/activities/task/taksWorkers/taskOccurrences"}
                                paramsIntUrl={{id_tasks: row.id_tasks}}
                            />

                            <ButtonIconControla
                                roles={permissions.ACTIVIDADES_TAREAS_DE_TRABAJADORES_ELIMINAR}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#EB5757"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() =>
                                    handleOpenDialogDeleteTask({
                                        deleteTask: row.id_tasks,
                                    })
                                }
                            />
                        </Stack>
                    </TableCell>
                </TableRow>

            )}
        </TableBody>

    )

}
export default ListWorkersTasks;