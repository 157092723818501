// juntas los new work accidents onemptied, two, thre, frour
import { React, useEffect, useState } from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  InfoOutlined,
  PictureAsPdf,
  Print,
  VisibilityOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";

import MyOccupationalDiseases from "../../../../api/MyOccupationalDiseases";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SelectControla from "../../../../components/selects/selectControla";
import SelectYesNotControla from "../../../../components/selects/selectYesNotControla";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import MyAccidentsOccupationalsIncidents from "../../../../api/MyAccidentsOccupationalsIncidents";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import { useAppContext } from "../../../../context/AppContext";
import MyTasksNew from "../../../../api/MyTasksNew";
import MyAreas from "../../../../api/MyAreas";
import ButtonSignatureControla from "../../../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../../../components/buttons/returnBackControla";
import MyContractors from "../../../../api/MyContractors";

const colorTitle = "#9191B0";
const blue = "#034AFF";


function Edit() {
  const { permissions } = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const idRegistersOcupationalDiseases = params.get(
    "idRegistersOcupationalDiseases"
  );

  // Backdrop
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;
  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
  
    setIdAgentType(id);
    setIdSubstancesCarcinogens(id);
    setIdAccidentsMeasures(id);
    setIdRegistersAccidentsResponsible(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "deleteOccupationalDiseases":
        handleDeleteAgentsTypes();
        break;
      case "deleteSubstancesCarcinogens":
        handleDeleteDiseasesCarcinogens();
        break;
      case "deleteMeasures":
        handleDeleteCorrectivesActions();
        break;
      case "deleteResponsible":
        handleDeleteResponsibleWorkers();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  // DIALOGOS
  // agregar enfermedades ocupacionales
  const [openModalOccupationalDiseases, setOpenModalOccupationalDiseases] =
    useState(false);

  const handleOpenModaOccupationalDiseases = () => {
    setDiseaseName("");

    setAreaSelected("")
    setAffectedBodyeAgents("");
    setAffectedWorkersCount("");
    setNumberOfWorkersExposed("")
    setJobChangesCoun("");
    setJanuaryCount(0);
    setFebruaryyCount(0);
    setFebruaryyCount(0);
    setAprilCount(0);
    setMayCount(0);
    setJuneCount(0);
    setJulyCount(0);
    setAugustCount(0);
    setSeptemberCount(0);
    setOctoberrCount(0);
    setNovemberCount(0);
    setDecemberCount(0);
    setStatusSelectAgentsTypes(null);
    setOpenModalOccupationalDiseases(true);
  };

  const handleCloseModalOccupationalDiseases = () => {
    setOpenModalOccupationalDiseases(false);
  };

  // agregar sustancias
  const [openModalCarcinogens, setOpenModalCarcinogens] = useState(false);

  const handleOpenModalCarcinogens = () => {
    setCarcinogen("");
    setStatusSelectMonitoring(null);
    setOpenModalCarcinogens(true);
  };
  const handleCloseModalCarcinogens = () => {
    setOpenModalCarcinogens(!openModalCarcinogens);
  };

  // agregar medidas correctivas DIALOG
  const [
    openModalUploadCorrectiveActions,
    setOpenModalUploadCorrectiveActions,
  ] = useState(false);

  const handleOpenModalUploadCorrectiveActions = () => {
    setTimestampStart(null);
    setTimestampEnd(null);
    setDescription(null);
    setResponsibleCorrectiveActions("");
    setStatusCorrectivesActions(null);

    setOpenModalUploadCorrectiveActions(true);
  };

  const handleCloseModalUploadCorrectiveActions = () => {
    setOpenModalUploadCorrectiveActions(false);
  };

  // agregar RESPONSABLES  DIALOG
  const [
    openModalUploadResponsibleRegistration,
    setOpenModalUploadCResponsibleRegistration,
  ] = useState(false);

  const handleOpenModalUploadResponsibleRegistration = () => {
    handleGetSearchsResponsiblesWorkers();
    setOpenModalUploadCResponsibleRegistration(true);
  };

  const handleCloseModalUploadResponsibleRegistration = () => {
    setOpenModalUploadCResponsibleRegistration(false);
  };

  /**************************************************************************************+ */

  // edit renfermedades ocupacionales

  const [code, setCode] = useState("");
  const [documentUrl1, setDocumentUrl1] = useState("");

  const handleEditOccupationalsDiseases = async () => {
    setOpenBackdrop(true);

    let response = await MyOccupationalDiseases.editOccupationalsDiseases({
      idRegistersOcupationalDiseases,
    });
    if (!response || response.success === false) {
      handleClickAlert('error', 'No se pudo obtener los datos');
    } else {
      response = response.data;
      console.log(response);
      setRegisterStatus(
        response.id_registers_occupational_diseases_statuses === 1
          ? true
          : false
      );
      setCode(response.code);
      setDocumentUrl1(response.document_url_1);
      setYear(response.year);
      const contractorIdsString = response.contractor_ids;
      const contractorIdsArray = contractorIdsString != null ? contractorIdsString.split(',').map(id => parseInt(id, 10)) : [];
      setSelectedContractorsIds(contractorIdsArray)
    }
    handleCloseBackdrop();

    return response;
  };

  //actualizar enfermedades ocupacionales
  const handleUpdateOccupationalsDiseases = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);

    const response = await MyOccupationalDiseases.updateOccupationalsDiseases({
      idRegistersOcupationalDiseases,
      code: code,
      document_url_1: documentUrl1,
      year: year,
      contractor_ids: selectedContractorsIds
    });

    if (response.success === false) {
      handleClickAlert("error", "No se pudo actualizar los datos!");
    } else {
      handleCloseBackdrop();
      handleClickAlert("success", "Se actualizaron los datos");
      handleEditOccupationalsDiseases()
    }
    return response;
  };

  // cerrar el estado de enfermedades ocupacionales
  const [registerStatus, setRegisterStatus] = useState(true);
  const handleOcupationalDiseasesEnd = async () => {

    if (relsDAgentsTypes.length == 0 || correctivesActions == 0) {
      handleClickAlert("error", "Complete las secciones obligatorias(enfermedad ocupacional y medidas correctivas)");
    } else if (responsibleWorkers.length == 0) {
      handleClickAlert("error", "Complete la sección obligatoria(responsable del registro)");
    } else if (responsibleWorkers.some((user) => user.firm == 0)) {
      handleClickAlert("error", "Quedan firmas pendientes de responsables del registro");
    } else {
      const response = await MyOccupationalDiseases.ocupationalDiseasesEnd({
        idRegistersOcupationalDiseases,
      });
      if (response.success === false) {
        alert("Error");
      } else {
        handleEditOccupationalsDiseases();
      }
      return response;
    }
  };

  //MEDIDAS CORRECTIVAS
  // buscar usuario por ID
  const [responsibleCorrectiveActions, setResponsibleCorrectiveActions] =
    useState(null);
  const [idResponsible, setIdResponsible] = useState(" ");

  const handleSelectUsersResponsible = async (idUsers) => {
    setIdResponsible(idUsers);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success != false) {
      setResponsibleCorrectiveActions(
        response.first_name + " " + response.last_name
      );
      setOpenModalUpload(false);
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  // abrir modal de busqueda de usuarios de medidas correctivas
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const handleOpenModalResponsible = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUpload(true);
  };

  // listar estado de la medida correctiva
  const [statusCorrectivesActions, setStatusCorrectivesActions] =
    useState(null);

  const [rowsStatusCorrectivesActions, setRowsStatusCorrectivesActions] =
    useState([]);
  const handleGetMeasuresStatuses = async () => {
    const response = await MyOccupationalDiseases.getMeasuresStatuses();
    if (response.success === false) {
    } else {
      setRowsStatusCorrectivesActions(response);
    }
    return response;
  };

  //get de usuarios para buscar en medidas correctivas
  const [
    rowsSearchsCorrectivesActionWorkers,
    setSearchsCorrectivesActionWorkers,
  ] = useState([]);

  const handleGetSearchsMeasuresUsers = async () => {
    const resp = await MyUsers.users();
    setSearchsCorrectivesActionWorkers(resp.users_actives);
  };

  //get de medidas correctivas, tabla
  const [correctivesActions, setCorrectivesActions] = useState([]);
  const handleGetCorrectivesActions = async () => {
    const response = await MyOccupationalDiseases.getMeasures({
      idRegistersOcupationalDiseases,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setCorrectivesActions(response);
    }
  };

  // crear medidas correctivas
  const [timestampStart, setTimestampStart] = useState("");
  const [timestampEnd, setTimestampEnd] = useState("");
  const [description, setDescription] = useState("");

  const handleStoreCorrectivesActions = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);

    const response = await MyOccupationalDiseases.createCorrectivesActions({
      idRegistersOcupationalDiseases: idRegistersOcupationalDiseases,
      id_registers_occupational_diseases_measures_statuses:
        statusCorrectivesActions,
      id_users: idResponsible,
      timestamp_start: timestampStart,
      timestamp_end: timestampEnd,
      description: description,
    });

    // agregar tarea
    const data = {
      date_start: timestampStart,
      date_end: timestampEnd,
      description: description,
      id_status: 1,
      id_users: idResponsible,
      id_tasks_types: 2,
      title: "Tarea asignada en enfermedades ocupacionales",
      origin_url: `documentation/registers/generals/occupationaldiseases/edit?idRegistersOcupationalDiseases=${idRegistersOcupationalDiseases}`
    }

    const resp = await MyTasksNew.postTasks(data);

    if (response.success === false) {
      console.log("error", response);
    } else {
      handleCloseModalUploadCorrectiveActions();
      await handleGetCorrectivesActions();
      handleCloseBackdrop();
      handleClickAlert("success", "Se ha creado la medida correctiva");
    }
    return response;
  };

  // eliminar medidas correctives
  const [idAccidentsMeasures, setIdAccidentsMeasures] = useState();
  const handleDeleteCorrectivesActions = async () => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyOccupationalDiseases.deleteCorrectivesActions({
      id_measures: idAccidentsMeasures,
    });
    if (response.success === true) {
      await handleGetCorrectivesActions();
      handleCloseBackdrop();
      handleClickAlert("success", "Se ha eliminado la medida correctiva");
    } else {
      alert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // RESPONSABLES
  // Buscar trabajadores en medidas correctivas
  const [rowsSearchsResponsiblesWorkers, setSearchsResponsiblesWorkers] =
    useState([]);
  const handleGetSearchsResponsiblesWorkers = async () => {
    const response = await MyOccupationalDiseases.getSearchsResponsiblesWorkers(
      { idRegistersOcupationalDiseases }
    );
    setSearchsResponsiblesWorkers(response);
  };

  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response = await MyOccupationalDiseases.getResponsibleWorkers({
      idRegistersOcupationalDiseases,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setResponsibleActions(response);
    }
  };

  const handleSignResponsible = async (idResponsibleSign) => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyOccupationalDiseases.signResponsible({
      idResponsibleSign,
    });
    if (response.success !== false) {
      await handleGetResponsibleWorkers();
      handleClickAlert("success", "Se registró la firma");
    } else {
      alert("Error al obtener el usuario!");
    }
    handleCloseBackdrop();
  };

  //crear trabajadores responsables
  const [firm, setFirm] = useState();
  const handleStoreResponsibleWorkers = async (idUsers) => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyOccupationalDiseases.createResponsibleWorkers({
      idRegistersOcupationalDiseases: idRegistersOcupationalDiseases,
      id_users: idUsers,
      firm: firm,
    });

    if (response.success === false) {
      console.log("error", response);
    } else {
      await handleGetResponsibleWorkers();
      handleCloseModalUploadResponsibleRegistration();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert("success", "Se agrearon los datos");
    }
    return response;
  };

  // eliminar trabajador responsable
  const[idRegistersAccidentsResponsible, setIdRegistersAccidentsResponsible] = useState();
  const handleDeleteResponsibleWorkers = async () => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyOccupationalDiseases.deleteResponsibleWorkers({
      idRegistersOcupationalDiseases: idRegistersAccidentsResponsible,
    });
    if (response.success === true) {
      await handleGetResponsibleWorkers();
      handleCloseBackdrop();
      handleClickAlert("success", "Se eliminaron los datos");
    } else {
      alert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // SUSTANCIAS
  //listas sustancias
  const [diseasesCarcinogens, setDiseasesCarcinogen] = useState([]);

  const handleGetDiseasesCarcinogens = async () => {
    const response = await MyOccupationalDiseases.getDiseasesCarcinogens({
      idRegistersOcupationalDiseases,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setDiseasesCarcinogen(response);
    }
  };

  // select de sustancias
  const [statusSelectMonitoring, setStatusSelectMonitoring] = useState("");

  //crear sustancias monitoring carcinogen
  const [carcinogen, setCarcinogen] = useState();

  const handleStoreDiseasesCarcinogen = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyOccupationalDiseases.createDiseasesCarcinogens({
      idRegistersOcupationalDiseases: idRegistersOcupationalDiseases,
      carcinogen: carcinogen,
      monitoring: statusSelectMonitoring,
    });
    if (response.success === false) {
      console.log("error", response);
    } else {
      await handleGetDiseasesCarcinogens();
      handleCloseModalCarcinogens();
      handleCloseBackdrop();
      handleClickAlert("success", "Se agregó correctamente los datos");
    }
    return response;
  };
  // eliminar sustancias
  const [idSubstancesCarcinogens, setIdSubstancesCarcinogens] = useState();

  const handleDeleteDiseasesCarcinogens = async () => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyOccupationalDiseases.deleteDiseasesCarcinogens({
      idRegistersOcupationalDiseases: idSubstancesCarcinogens,
    });
    if (response.success === true) {
      await handleGetDiseasesCarcinogens();
      handleCloseBackdrop();
      handleClickAlert("success", "Se eliminó correctamente los datos");
    } else {
      alert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // AGENTES
  // seleccionar sustancias select
  const [statusSelectAgentsTypes, setStatusSelectAgentsTypes] = useState(null);
  // listar tipos de agentes
  const [agentsTypes, setAgentsTypes] = useState([]);
  const handleGetAgentsTypess = async () => {
    const response = await MyOccupationalDiseases.getAgentsTypes();
    setAgentsTypes(response);
  };

  //listas agentes
  const [relsDAgentsTypes, setRelsAgentsTypes] = useState([]);
  const handleGetRelsDAgentsTypes = async () => {
    getAreas();
    const response = await MyOccupationalDiseases.getRelsDAgentsTypes({
      idRegistersOcupationalDiseases,
    });

    if (response.success === false) {
      alert("Error");
    } else {
      setRelsAgentsTypes(response === undefined ? "" : response);
      setFirm(response.firm);
    }
  };
  // crear enfermedades ocupacionales
  const [diseaseName, setDiseaseName] = useState("");
  const [area , setArea]= useState([]);
  const [areaSelected , setAreaSelected] = useState("");
  const [affectedBodyAgents, setAffectedBodyeAgents] = useState("");
  const [affectedWorkersCount, setAffectedWorkersCount] = useState("");
  const [numberOfWorkersExposed,setNumberOfWorkersExposed] = useState("");
  const [jobChangesCoun, setJobChangesCoun] = useState(0);

  const [januaryCount, setJanuaryCount] = useState(0);
  const [februaryCount, setFebruaryyCount] = useState(0);
  const [marchCount, setMarchCount] = useState(0);
  const [aprilCount, setAprilCount] = useState(0);
  const [mayCount, setMayCount] = useState(0);
  const [juneCount, setJuneCount] = useState(0);
  const [julyCount, setJulyCount] = useState(0);
  const [augustCount, setAugustCount] = useState(0);
  const [septemberCount, setSeptemberCount] = useState(0);
  const [octoberCount, setOctoberrCount] = useState(0);
  const [novemberCount, setNovemberCount] = useState(0);
  const [decemberCount, setDecemberCount] = useState(0);

  const [year, setYear] = useState(new Date().getFullYear());

  const handleStoreAgentsTypes = async (event) => {
    event.preventDefault();

    setOpenBackdrop(!openBackdrop);
    
    const response = await MyOccupationalDiseases.createAgentsTypes({
      idRegistersOcupationalDiseases: idRegistersOcupationalDiseases,
      id_registers_occupational_diseases_agents_types: statusSelectAgentsTypes,
      disease_name: diseaseName,
      area: areaSelected,
      affected_body: affectedBodyAgents,
      affected_workers_count: affectedWorkersCount,
      number_of_workers_exposed: numberOfWorkersExposed,
      job_changes_count: jobChangesCoun,
      january_count: januaryCount,
      february_count: februaryCount,
      march_count: marchCount,
      april_count: aprilCount,
      may_count: mayCount,
      june_count: juneCount,
      july_count: julyCount,
      august_count: augustCount,
      september_count: septemberCount,
      october_count: octoberCount,
      november_count: novemberCount,
      december_count: decemberCount,
    });

    if (response.success === false) {
      console.log("error", response);
    } else {
      await handleGetRelsDAgentsTypes();
      handleCloseModalOccupationalDiseases();
      handleCloseBackdrop();
      handleClickAlert("success", "Se crearon los datos");
    }
    return response;
  };

  // eliminar agentes de enfermedades ocupacionales
  const [idAgentType, setIdAgentType] = useState();
  const handleDeleteAgentsTypes = async () => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyOccupationalDiseases.deleteAgentsTypes({
      id_agent_type: idAgentType,
    });
    if (response.success === true) {
      await handleGetRelsDAgentsTypes();
      handleCloseBackdrop();
      handleClickAlert("success", "Se eliminó correctamente los datos");
    } else {
      alert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // listar tipos de agentes tabla completa
  const [openModalTypeAgentsTable, setOpenModalTypeAgentsTable] =
    useState(false);

  const handleOpenModalTypeAgentsTable = () => {
    setOpenModalTypeAgentsTable(true);
  };

  const [agentsTable, setAgentsTable] = useState([]);

  const handleGetAgentsTypesTable = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getAgentsTypesTable();

    if (response.success === false) {
      handleClickAlert("error", "Error al guardar el documento!");
    } else {
      setAgentsTable(response);
    }
    return response;
  };

  // Mostrar tabla de enfermedad ocupacional
  const [openModalTableOcupational, setOpenModalTableOcupational] =
    useState(false);

  const handleOpenModalTableOcupational = () => {
    setOpenModalTableOcupational(true);
  };
  //cargar areas
  const getAreas = async()=>{
    const resp = await MyAreas.getAreas();
    setArea(resp);
  }

  const handleGeneratePDF = async () => {
    try {
      setOpenBackdrop(true);
      const response = await MyAccidentsOccupationalsIncidents.generatePDF({idRegistersOcupationalDiseases});
      const blob = await response.blob();

      // Crear un enlace temporal para descargar el archivo
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `register-${idRegistersOcupationalDiseases}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setOpenBackdrop(false);
    } catch {
      setOpenBackdrop(false);
      console.error('Hubo un error con la generacion del pdf', 'error');
    }
  };

  // contratistas 

  const [selectedContractorsIds, setSelectedContractorsIds] = useState([]);
  const [rowsContractors, setRowsContractors] = useState([]);
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(()=> {
    if(selectedContractorsIds.length > 0) {      
      setChecked(true)
    }    
  },[selectedContractorsIds])

  useEffect(()=> {
    if(checked == false) {
      setSelectedContractorsIds([])
    }
  },[checked])

  // Manejar la selección de contratistas
  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;

    // Guardar solo los IDs de los contratistas seleccionados
    setSelectedContractorsIds(value.map(option => option.id_contractors));
  };

  const getContractors = async () => {
    const resp = await MyContractors.getContractors() ?? [];
    setRowsContractors(resp.data);
  }

  useEffect(() => {
    //listar areas
    getAreas();

    handleEditOccupationalsDiseases();

    // medidas correctivas
    handleGetMeasuresStatuses();
    handleGetSearchsMeasuresUsers();
    handleGetCorrectivesActions();

    // responsables
    handleGetSearchsResponsiblesWorkers();
    handleGetResponsibleWorkers();

    //sustancias
    handleGetDiseasesCarcinogens();

    // agentes
    handleGetAgentsTypess();
    handleGetRelsDAgentsTypes();

    // listar tabla de enfermedades coupacionales
    handleGetAgentsTypesTable();

    getContractors()
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={"Registro de enfermedad ocupacional"}
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
             <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!registerStatus}
                    checked={checked}
                    onChange={handleCheckboxChange}
                    label="Habilitar contratistas"
                  />
                }
                label="Habilitar contratistas"
              />

              {checked && (
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="multi-select-label">Contratistas</InputLabel>
                    <Select
                      labelId="multi-select-label"
                      multiple
                      disabled={!registerStatus}
                      value={rowsContractors.filter(option => selectedContractorsIds.includes(option.id_contractors))} // Selecciona solo los contratistas por ID
                      onChange={handleSelectChange}
                      input={<OutlinedInput label="Opciones" />}
                      renderValue={(selected) => (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {selected.map((contractor) => (
                            <Chip key={contractor.id_contractors} label={contractor.company_name} style={{ margin: 2 }} />
                          ))}
                        </div>
                      )}
                    >
                      {rowsContractors.map((option) => (
                        <MenuItem key={option.id_contractors} value={option}>
                          {option.company_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputControlaVertical
                roles={permissions.DOC_REGIS_ENFERMEDADES_OCUPAC_EDITAR}
                text={"Nro registro"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputControlaVertical
                roles={permissions.DOC_REGIS_ENFERMEDADES_OCUPAC_EDITAR}
                text={"Año"}
                inputType={"text"}
                inputValue={setYear}
                value={year}
                modalType={false}
                required
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUpdateOccupationalsDiseases}>
            {/* Datos refrentes a la enfermedad ocupacional */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Datos referentes a la enfermedad ocupacional
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", marginLeft: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box sx={{ marginRight: 60 }}>
                <ButtonIconControla
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#FFC300"}
                  backgroundColorHover={"#FACB32"}
                  textTooltip={"Ver enfermedades ocupacionales"}
                  functionOnClick={handleOpenModalTypeAgentsTable}
                />
              </Box>
              <Box>
                {registerStatus === true && (
                  <ButtonControla
                    roles ={permissions.DOC_REGIS_ENFERMEDADES_OCUPAC_AGREGAR_DATOS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={handleOpenModaOccupationalDiseases}
                  />
                )}
              </Box>
            </Stack>
            {/* tabla para mostrar accidents workers */}
            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tipo de agente que originó la enfermedad ocupacional
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre de la enfermedad ocupacional
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Parte del cuerpo o sistema del trabajador afectado
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nro Trabajadores afectados
                      </TableCell>
                       <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nro Trabajadores expuestos al agente
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Áreas
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        N° de cambios de puestos generados(De ser el caso)
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {relsDAgentsTypes.map((row) => (
                      <TableRow
                        hover
                        key={
                          row.id_registers_occupational_diseases_rels_agents_types
                        }
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.agent_type}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.disease_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.affected_body}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.affected_workers_count}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.number_of_workers_exposed}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {area.filter((a) => a.id_areas == row.area)[0]?.area}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.job_changes_count}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            <ButtonIconControla
                              icon={ <VisibilityOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver tabla de enfermedades ocupacionales por mes"}
                              functionOnClick={handleOpenModalTableOcupational}
                            />
                            {registerStatus && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id_registers_occupational_diseases_rels_agents_types,
                                    "¿Esta seguro de eliminar los datos de la enfermedad ocupacional?",
                                    "Una vez eliminado no se podra recuperar los datos de  la enfermedad ocupacional",
                                    "Si, Eliminar",
                                    "deleteOccupationalDiseases"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* descripcion de las causas del accidente de trabajo */}

            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={
                    "Detalle de las causas que generan enfermedades ocupacionales en el tipo de agente"
                  }
                  color={blue}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h6" sx={{ color: "#305ad9" }}>
                  Adjuntar documento en el que consten las causas que generan
                  las enfermedades ocupacionales y adicionalmente indicar una
                  breve descripción de las labores adquirir la enfermedad.
                </Typography>
              </Grid>
              <Grid item xs={11} md={11}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_ENFERMEDADES_OCUPAC_EDITAR}
                  text={""}
                  inputType={"file"}
                  inputValue={setDocumentUrl1}
                />
              </Grid>
              <Grid item xs={1} md={1}>
                <ButtonIconControla
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl1}
                  directionTarget={"_blank"}
                />
              </Grid>
            </Grid>

            {/* Completar en caso de uso de sustancias cancerígernas */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Completar en caso de uso de sustancias cancerígenas
                </InputLabel>
              </Box>
              <Box sx={{ marginRight: 55 }}>
                <ButtonIconControla
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#18A26B"}
                  backgroundColorHover={"#1DC280"}
                  textTooltip={`Ref. D.S. 039-93-PCM /  D.S. 015-2005-SA`}
                />
              </Box>
              <Box>
                {registerStatus && (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={handleOpenModalCarcinogens}
                  />
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Relación de sustancias cancerígenas
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Monitoreo de los agentes presentes en el ambiente
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {diseasesCarcinogens.map((rows) => (
                      <TableRow
                        hover
                        key={
                          rows.id_registers_occupational_diseases_carcinogens
                        }
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rows.carcinogen}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.monitoring === 1 ? "si" : "No"}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    rows.id_registers_occupational_diseases_carcinogens,
                                    "¿Esta seguro de eliminar los datos del uso de sustancias cancerígenas?",
                                    "Una vez eliminado no se podra recuperar los datos del uso de sustancias cancerígenas",
                                    "Si, Eliminar",
                                    "deleteSubstancesCarcinogens"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* medidas correctivas */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Medidas correctivas
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", marginLeft: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus && (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_ENFERMEDADES_OCUPAC_AGREGAR_MEDIDAS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar medidas correctivas"}
                    functionOnClick={handleOpenModalUploadCorrectiveActions}
                  />
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Descripción de la medida correctiva
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Responsable
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                       Estado
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de inicio
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de fin
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {correctivesActions.map((rows) => (
                      <TableRow
                        hover
                        key={rows.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rows.description}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.first_name + " " + rows.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.id_registers_occupational_diseases_measures_statuses === 1 ? 'En proceso' 
                          :
                          rows.id_registers_occupational_diseases_measures_statuses === 2 ? 'Finalizada'
                          :
                          rows.id_registers_occupational_diseases_measures_statuses === 3 ? 'Vencida'
                          : 'Cancelada' }
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.timestamp_start.substring(0, 10)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.timestamp_end == null ? "No definido": rows.timestamp_end.substring(0, 10)}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + rows.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                            {registerStatus && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    rows.id_registers_occupational_diseases_measures,
                                    "¿Esta seguro de eliminar los datos de la medida correctiva?",
                                    "Una vez eliminado no se podra recuperar los datos de la medida correctiva",
                                    "Si, Eliminar",
                                    "deleteMeasures"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro y de la investigación
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", marginLeft: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus && (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_ENFERMEDADES_OCUPAC_AGREGAR_RESPON}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={
                      handleOpenModalUploadResponsibleRegistration
                    }
                  />
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Documento de identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Cargo 
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.position_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.firm_date === null ? '' : row.firm_date.substring(0,19)}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <ButtonSignatureControla
                              idUser={ row.id_users}
                              firmStatus={row.firm}
                              firmUrl={row.firm_url}
                              functionOnClick={async() => {
                                await handleSignResponsible(row.id_registers_occupational_diseases_responsible)
                              }}
                          />
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + row.id_users
                              }
                              directionTarget={"_blank"}
                            /> */}
                            {registerStatus && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id_registers_occupational_diseases_responsible,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos  del trabajador responsable",
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                  // functionOnClick={""}
                />
              </Grid> */}
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={"/documentation/registers/generals/occupationaldiseases"}
                />
              </Grid>
              {
                registerStatus && (
                  <>
                    <Grid item xs={12} md={2}>
                      <ButtonControla
                        roles={permissions.DOC_REGIS_ENFERMEDADES_OCUPAC_ACTUALIZAR_REGIS}
                        iconButton={<EditOutlined sx={{ color: "white" }} />}
                        backgroundColor={"#169073"}
                        backgroundColorHover={"#1BAA88"}
                        textButton={"Actualizar registro"}
                        typeButton="submit"
                      />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <ButtonControla
                        roles={permissions.DOC_REGIS_ENFERMEDADES_OCUPAC_CERRAR_REGIS}
                        iconButton={<CloseIcon sx={{ color: "white" }} />}
                        backgroundColor={"black"}
                        backgroundColorHover={"#2C2B29"}
                        textButton={"Cerrar registro"}
                        functionOnClick={handleOcupationalDiseasesEnd}
                      />
                    </Grid>
                  </>
                )
              }

              <Grid item xs={12} md={2}>
                {registerStatus === false && (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_ENFERMEDADES_OCUPAC_CERRAR_REGIS}
                    iconButton={<PictureAsPdf fontSize="inherit" />}
                    backgroundColor={"#F2994A"}
                    backgroundColorHover={"#FF881E"}
                    textButton={"Ver Documento PDF"}
                    functionOnClick={handleGeneratePDF}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog de  sustancias cancerígenas handleOpenModalCarcinogens */}
      <Dialog
        open={openModalCarcinogens}
        onClose={handleCloseModalCarcinogens}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Datos de uso de sustancias cancerígenas
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreDiseasesCarcinogen}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                text={"Relación de sustancias cancerígenas"}
                inputType={"text"}
                inputValue={setCarcinogen}
                value={carcinogen}
                modalType={true}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <SelectYesNotControla
                text={"Monitoreo de los agentes presentes en el ambiente"}
                inputValue={setStatusSelectMonitoring}
                modalType={true}
                value={statusSelectMonitoring}
                required
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalCarcinogens}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog de  agentes enfermedades ocupacionales */}
      <Dialog
       PaperProps={{
        sx: {
          width: "50%",
          maxHeight: 500
        }
      }}
        open={openModalOccupationalDiseases}
        onClose={handleCloseModalOccupationalDiseases}
        maxWidth="sm"
        
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 ,  
       }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar datos referentes a las enfermedades ocupacionales
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreAgentsTypes}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Tipo de agente:"
                  inputValue={setStatusSelectAgentsTypes}
                  modalType={true}
                  required
                  value={statusSelectAgentsTypes}
                  childrenRows={agentsTypes.map((row) => (
                    <MenuItem
                      key={row.id_registers_occupational_diseases_agents_types}
                      value={
                        row.id_registers_occupational_diseases_agents_types
                      }
                    >
                      {row.agent_type}
                    </MenuItem>
                  ))}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Enero"}
                  inputType={"number"}
                  inputValue={setJanuaryCount}
                  value={januaryCount}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Febrero"}
                  inputType={"number"}
                  inputValue={setFebruaryyCount}
                  value={februaryCount}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Marzo"}
                  inputType={"number"}
                  inputValue={setMarchCount}
                  value={marchCount}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Abril"}
                  inputType={"number"}
                  inputValue={setAprilCount}
                  value={aprilCount}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Mayo"}
                  inputType={"number"}
                  inputValue={setMayCount}
                  value={mayCount}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Junio"}
                  inputType={"number"}
                  inputValue={setJuneCount}
                  value={juneCount}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Julio"}
                  inputType={"number"}
                  inputValue={setJulyCount}
                  value={julyCount}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Agosto"}
                  inputType={"number"}
                  inputValue={setAugustCount}
                  value={augustCount}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Septiembre"}
                  inputType={"number"}
                  inputValue={setSeptemberCount}
                  value={septemberCount}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Octubre"}
                  inputType={"number"}
                  inputValue={setOctoberrCount}
                  value={octoberCount}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Noviembre"}
                  inputType={"number"}
                  inputValue={setNovemberCount}
                  value={novemberCount}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Diciembre"}
                  inputType={"number"}
                  inputValue={setDecemberCount}
                  value={decemberCount}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre de la Enfermedad Ocupacional"}
                  inputType={"text"}
                  inputValue={setDiseaseName}
                  value={diseaseName}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Parte del Cuerpo o Sistema Afectado"}
                  inputType={"text"}
                  inputValue={setAffectedBodyeAgents}
                  value={affectedBodyAgents}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nº Trabajadores Afectados"}
                  inputType={"number"}
                  inputValue={setAffectedWorkersCount}
                  value={affectedWorkersCount}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nº Trabajadores expuestos al agente"}
                  inputType={"number"}
                  inputValue={setNumberOfWorkersExposed}
                  value={numberOfWorkersExposed}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                text={"Area"}
                inputValue ={setAreaSelected}
                value={areaSelected}
                childrenRows={area.map((a)=>( 
                  <MenuItem key={a.id_areas} value={a.id_areas}>
                    {a.area}
                  </MenuItem>
                ))}
                required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nº de Cambios de Puestos Generados"}
                  inputType={"number"}
                  inputValue={setJobChangesCoun}
                  value={jobChangesCoun}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalOccupationalDiseases}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog agregar medidas correctivas */}
      <Dialog
        open={openModalUploadCorrectiveActions}
        onClose={handleCloseModalUploadCorrectiveActions}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar medidas correctivas
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreCorrectivesActions}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inicio"}
                  inputType={"date"}
                  inputValue={setTimestampStart}
                  value={timestampStart}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setTimestampEnd}
                  value={timestampEnd}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Estado de medida correctiva"
                  inputValue={setStatusCorrectivesActions}
                  modalType={true}
                  value={statusCorrectivesActions}
                  required
                  childrenRows={rowsStatusCorrectivesActions.map((rows) => (
                    <MenuItem
                      key={
                        rows.id_registers_occupational_diseases_measures_statuses
                      }
                      value={
                        rows.id_registers_occupational_diseases_measures_statuses
                      }
                    >
                      {rows.statuses}
                    </MenuItem>
                  ))}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable: "
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalResponsible}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadCorrectiveActions}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo de buscar usuarios de medidas correctivas */}
      <Dialog
        open={openModalUpload}
        onClose={() => {
          setOpenModalUpload(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsSearchsCorrectivesActionWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUpload(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

      {/* Dialogo para firmar responsable */}
      {/* <Dialog
        open={openModalSignResponsible}
        onClose={() => {
          setOpenModalSignResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Sus datos quedarán registrados de forma permanente ¿Desea firmar?
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignResponsible}>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalSignResponsible(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Firmar
            </Button>
          </DialogActions>
        </form>
      </Dialog> */}

      {/* ver typos de agentes y enfermedades tabla completa */}
      <Dialog
        open={openModalTypeAgentsTable}
        onClose={() => {
          setOpenModalTypeAgentsTable(false);
        }}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Tipos de Agentes
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid container sx={{ marginTop: "4px" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    {agentsTable.map((column) => (
                      <TableCell
                        key={column.id_categories}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.category}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {agentsTable.map((row) => {
                    return (
                      <TableCell key={row.id_categories}>
                        {row.agents.map((column) => {
                          return (
                            <TableRow
                              key={
                                column.id_registers_occupational_diseases_agents_types
                              }
                            >
                              {/* <TableCell > */}
                              {column.agent_type}
                              {/* </TableCell> */}
                            </TableRow>
                          );
                        })}
                      </TableCell>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            // color="success"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalTypeAgentsTable(false);
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

      {/*TABLA DE ENFERMEDADES OCUPACIONALES POR MES */}
      <Dialog
        open={openModalTableOcupational}
        onClose={() => {
          setOpenModalTableOcupational(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            ENFERMEDADES OCUPACIONALES POR MES
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignResponsible}>
          <DialogContent>
            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 200 }} aria-label="simple table">
                  <TableHead>
                    <TableRow
                      sx={{
                        color: "#305ad9",
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      style={{ textAlign: "center" }}
                    >
                      N° de Enfermedades ocupacionales presentadas en cada mes
                      por tipo de agente
                    </TableRow>

                    <TableRow>
                      <InputControlaVertical
                        text={"Año"}
                        inputType={"text"}
                        inputValue={setYear}
                        value={year}
                        modalType={true}
                      />
                    </TableRow>
                    <TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          E
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          F
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          M
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          A
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          M
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          J
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          J
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          A
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          S
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          O
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          N
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          D
                        </TableCell>
                      </TableRow>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {relsDAgentsTypes.map((row) => (
                      <TableRow
                        hover
                        key={
                          row.id_registers_occupational_diseases_rels_agents_types
                        }
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.january_count}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.february_count}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.march_count}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.april_count}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.may_count}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.june_count}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.july_count}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.august_count}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.september_count}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.october_count}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.november_count}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.december_count}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalTableOcupational(false);
              }}
            >
              Cerrar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 99,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Edit;
