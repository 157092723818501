import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useAppContext } from "../../../context/AppContext";

function ListArchived(props) {
    const {permissions} = useAppContext();

    const {
        archivedMeetings,
        colorCell
    } = props
    return (
        <TableBody>
            {archivedMeetings.map((meeting) => (
                <TableRow
                    hover
                    key={meeting.id_committee_meeting}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.id_committee_meeting}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.description}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.date}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.session === "1" ? "ordinario" : "extraordinario"}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.modality === "1" ? "presencial" : "remoto"}
                    </TableCell>

                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignContent="center"
                        >
                            <ButtonIconControla
                                roles={permissions.MYPE_REU_ARCHIV_VER_REU}
                                icon={<VisibilityOutlined sx={{ color: "white" }}/>}
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                isNavegationOrigin={true}
                                directionUrl={"/proceedingBook/scheduleMeetings/editScheduledMeeting?id_meeting=" + meeting.id_committee_meeting + "&readOnly=true"}
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>

    )
}
export default ListArchived;