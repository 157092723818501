import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import InputControlaVertical from '../../../../../components/textfields/inputControlaVertical'
import SelectControla from '../../../../../components/selects/selectControla'
import SearchButtonControla from '../../../../../components/search/searchButtonControla'
import MyContractorsRelationEmployees from '../../../../../api/contractors/MyContractorsRelationEmployees'

export default function ModalAddWorker({
  openModalUploadAddDates,
  handleCloseModalUploadAddDates,
  checked,
  handleStoreAccidentsWorkers,
  setWorkExperience,
  workExperience,
  rowsAreaWorkerInvolved,
  setArea,
  area,
  shift,
  handleChangeShift,
  setWorkHoursBefore,
  workHoursBefore,
  responsibleCorrectiveActions,
  handleOpenModalResponsibleInvolvedWorkers,
  selectedIds = [],
  callbackAlert,
  fetchAccidentedWorkers,
  setOpenBackdrop,
  openBackdrop,
  handleCloseBackdrop,
  id_register_accident,
  id_employee
}) {

  const handleStoreAccidentsWorkersContractors = async (event) => {
    try {
      if (selectedIds.length < 1) return false;
      event.preventDefault();
      setOpenBackdrop(!openBackdrop);
      
      const response = await MyContractorsRelationEmployees.storeContractorsRelationsEmployees({
        registro: 'accidentes',
        id_register: id_register_accident,
        id_employee: id_employee,
        id_area: area,
        shift: shift,
        work_experience: workExperience,
        work_hours_before: workHoursBefore,
      });
      if (!response || response.success === false) {
        console.log("error", response);
        handleCloseBackdrop();
        callbackAlert("error", "No se pudo agregar trabajador.");
      } else {
        await fetchAccidentedWorkers();
        handleCloseModalUploadAddDates();
        handleCloseBackdrop();
        callbackAlert("success", "Se agregó correctamente los datos del trabajador");
      }
      return response;
    }
    catch (err) {
      callbackAlert('error', `Error al modificar el registro. ${err}`.trimEnd());
    }
  }

  return (
    <Dialog
      open={openModalUploadAddDates}
      onClose={handleCloseModalUploadAddDates}
      maxWidth="sm"
      fullWidth={true}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
    >
      <DialogTitle>
        <Typography
          id="modal-modal-title"
          sx={{
            color: "#1638F2",
            fontWeight: "bold",
            fontSize: "22px",
          }}
        >
          {checked ? 'Agregar trabajador de contratista(s)' : 'Agregar trabajador involucrado'}
        </Typography>
      </DialogTitle>
      <form onSubmit={checked ? handleStoreAccidentsWorkersContractors : handleStoreAccidentsWorkers}>
        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                text={"Tiempo de experiencia en el puesto de trabajo"}
                inputType={"text"}
                inputValue={setWorkExperience}
                value={workExperience}
                modalType={true}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <SelectControla
                text="Área:"
                inputValue={setArea}
                required
                value={area}
                childrenRows={rowsAreaWorkerInvolved.map((rows) => (
                  <MenuItem key={rows.id_areas} value={rows.id_areas}>
                    {rows.area}
                  </MenuItem>
                ))}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputLabel
                sx={{
                  padding: "8px",
                  fontSize: "18px",
                  color: "#305AD9",
                  fontWeight: "bold",
                }}
              >
                Turno
              </InputLabel>
              <Select
                required
                sx={{
                  fontFamily: "Roboto",
                  width: "100%",
                  backgroundColor: "#F5F5F5",
                  height: "42px",
                  color: "#305AD9",
                }}
                value={shift}
                onChange={handleChangeShift}
              >
                <MenuItem value={"Dia"}>Dia</MenuItem>
                <MenuItem value={"Tarde"}>Tarde</MenuItem>
                <MenuItem value={"Noche"}>Noche</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                text={"N.º de horas trabajadas antes del accidente"}
                inputType={"Number"}
                inputValue={setWorkHoursBefore}
                value={workHoursBefore}
                modalType={true}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SearchButtonControla
                titleLabel="Trabajador involucrado: "
                inputValue={responsibleCorrectiveActions}
                functionClick={handleOpenModalResponsibleInvolvedWorkers}
                modalType={true}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadAddDates}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{
              textTransform: "none",
              color: "white",
              backgroundColor: "#169073",
              borderRadius: "16px",
              marginRight: "10px",
            }}
          >
            Agregar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
