import { React, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { 
    Button,
    Backdrop,
    Grid,
    Tab,
    Tabs,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    Typography,
    Snackbar,
    CircularProgress,
    Alert,
 } from '@mui/material';
 import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import BannerControla from '../../components/cards/bannerControla';
import SearchDateRangeControla from "../../components/search/searchDateRangeControla";
import { useLocation } from "react-router-dom";
import MyTracking from "../../api/MyTracking";
import PaginationTable from "./components/paginationTable";


const blue = "#034AFF";
const purple = "#8B8BD8";

function LogsByIdCompany() {
    const location = useLocation();
    const idCompany = location.state.id_companie;
    const nameCompany = location.state.name_companie;

    const [logsCompany, setLogsCompany] = useState([]);
    const [currentPage, setCurrentPage] =  useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate ] = useState("");
    const [loading, setLoading] = useState(false);
    

    const searchRangeDateLogs = async (startDate, endDate) => {


        if (startDate !== "" && endDate !== "") {
            setStartDate(startDate);
            setEndDate(endDate);
            setCurrentPage(1);
            const resp = await MyTracking.getLogsByIdCompany(idCompany, startDate, endDate, 1);
            console.log(resp);
            setLogsCompany(resp.content);
            setTotalPages(resp.total_pages);
        }
    }

    const handleGetLogsByPage = async(page)=>{
          setCurrentPage(page);
          console.log(page);
          setLoading(true);

          const resp = await MyTracking.getLogsByIdCompany(idCompany,startDate,endDate,currentPage);
          console.log(resp);
          setLogsCompany(resp.content);
          setLoading(false);
    }

    useEffect(()=>{
        
    },[]);

    return (
        <Box sx={{ display: "flex" }}>
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <BannerControla
                                image="/assets/images/banners/configuration/tareasbanner.png"
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text={nameCompany}
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        spacing={2}
                        sx={{ mt: 2 }}
                        justifyContent="center"
                        direction="row"
                        alignItems="center">
                        <Grid item>
                            <SearchDateRangeControla
                                handleRangeDate={searchRangeDateLogs}
                            />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper sx={{ overflow: "hidden" }}>
                                <TableContainer>
                                    <Table
                                        stickyHeader
                                        sx={{ width: "100%", minWidth: "700px" }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Usuario
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Fecha de registro
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Nombre del módulo
                                                </TableCell>

                                                {/* <TableCell>
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="end"
                                                        alignItems="center"
                                                    >
                                                        <Tabs
                                                            value={typeStatusMeeting}
                                                            onChange={handleChangeStatusMeeting}
                                                            aria-label="Tipos de estados de la reunión"
                                                        >
                                                            <Tab label="Programadas" />
                                                            <Tab label="Notificadas" />
                                                            <Tab label="Archivadas" />
                                                        </Tabs>
                                                    </Stack>
                                                </TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        {
                                            loading ? "" : (
                                                <TableBody>
                                                    {logsCompany.map((l) => (
                                                        <TableRow
                                                            hover
                                                            key={l.id_logs}
                                                            sx={{
                                                                "&:last-child td, &:last-child th": { border: 0 },
                                                            }}
                                                        >
                                                            <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                                                                {l.id_users_first_name + " " + l.id_users_last_name}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                                                                {l.timestamp_created}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                                                                {l.module}
                                                            </TableCell>
                                                        </TableRow>

                                                    ))}
                                                </TableBody>
                                            )
                                        }

                                    </Table>
                                </TableContainer>
                                {
                                    startDate !== "" && endDate !== "" && loading ? (
                                        <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
                                            {/* {loading ? <CircularProgress /> : ""} */}
                                            <CircularProgress />
                                        </Box>
                                    ) : ""
                                }
                                
                                

                            </Paper>

                        </Grid>
                    </Grid>
                    <PaginationTable
                        handleGetLogs={handleGetLogsByPage}
                        currentPage={currentPage}
                        totalPage={totalPages}
                    />
                    {console.log(currentPage)}


                </Box>
            </Container>
        </Box>

    );

}
export default LogsByIdCompany;