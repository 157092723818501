import MyConfigurationApi from "./MyConfigurationApi";

function postUserByCompany (data){
    const url = 'users/create-custom';
    return MyConfigurationApi.connectApi(
        { dataJson: data, 
          method: 'POST', 
          url: url 
        });

}

function getUserByCompany(idCompany){
  const url = 'users/get-by-company/' + idCompany;
    return MyConfigurationApi.connectApi(
        { dataJson: null, 
          method: 'GET', 
          url: url 
        });

}

const MyUserAdmin = {
    postUserByCompany,
    getUserByCompany
}

export default MyUserAdmin;