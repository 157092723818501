import MyConfigurationApi from "./MyConfigurationApi";

function getRecommendationsAll(){
    const url = "minutes/recommendations/minute/get";

    return MyConfigurationApi.connectApi({
        dataJson:null,
        method:"GET",
        url: url
    })
}
function getRecommendationsByIdProceeding(idProceeding){
    const url = "minutes/recommendations/minute/get?id_minute=" + idProceeding;

    return MyConfigurationApi.connectApi({
        dataJson:null,
        method:"GET",
        url: url
    })

}

const MyMinuteBookRecommendations ={
    getRecommendationsAll,
    getRecommendationsByIdProceeding

}
export default MyMinuteBookRecommendations;