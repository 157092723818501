import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { React, useState } from "react";
import ValidationEditByRoles from "../../utils/validation_edit_roles";

function SelectYesNotControla(props) {

    const { text, inputValue, modalType = false, value = undefined,roles=[], ...other } = props;

    const isEdit = roles.length == 0 ? true : ValidationEditByRoles(roles);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputLabel
                    sx={{
                        padding: '8px',
                        fontSize: '18px',
                        color: '#305AD9',
                        fontWeight: 'bold'
                    }}> {text} </InputLabel>
                <Select
                    size="small"
                    value={(value === undefined || value === null ? undefined : value)}
                    displayEmpty
                    onChange={(event) => { inputValue(event.target.value) }}
                    sx={{
                        fontFamily: 'Roboto',
                        width: "100%",
                        backgroundColor: (modalType === true ? '#F5F5F5' : '#FFFFFF'),
                        height: "42px",
                        color: '#305AD9',

                    }}
                    readOnly={!isEdit}
                    {...other}
                >
                    <MenuItem value={0}>No</MenuItem>
                    <MenuItem value={1}>Si</MenuItem>
                </Select>

            </Grid>
        </Grid >
    );
}

export default SelectYesNotControla;
