import { React, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import {
  Grid,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  MenuItem,
} from "@mui/material";

import CommentIcon from '@mui/icons-material/Comment';

import SubtitleText from "../../../components/text/subtitleText";
import BannerControla from "../../../components/cards/bannerControla";
import SearchGeneralControla from "../../../components/search/searchGeneralControla";
import SelectControlaRoles from "../../../components/selects/selectControlaRoles";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import MyTasks from "../../../api/MyTasks";
import {useNavigate} from "react-router-dom";

import MyConfigurationApi from "../../../api/MyConfigurationApi";
import MyTasksNew from "../../../api/MyTasksNew";
import ButtonMailboxControla from "../../../components/buttons/buttonMailboxControla";
import LoadingControla from "../../../components/load/loadingControla";
import ReturnBack from "../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";
const blue = "#034AFF";
const purple = "#8B8BD8";

function MyTask() {
    const navigate = useNavigate();
    // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

    const [tasks, setTasks] = useState([]);

    const [currentTask, setCurrentTask] = useState([]);
    const [approvedTask, setApprovedTask] = useState([]);
    const [canceledTask, setCanceledTask] = useState([]);
    const [pendingTask, setPendingTask] = useState([]);
    const [taskToValidate, setTaskToValidate] = useState([]);

    const handleGetTasksByUser = async () => {
        handleOpenLoading()

        const userData = MyConfigurationApi.userData();
        const response = await MyTasks.taskByUser({ idUser: userData.id_users });
        setTasks(response);

        //traer tareas por status
        setPendingTask(response.filter((t) => t.id_status === 1))
        setCurrentTask(response.filter((t) => t.id_status === 2));
        setTaskToValidate(response.filter((t) => t.id_status === 3));
        setApprovedTask(response.filter((t) => t.id_status === 4));
        setCanceledTask(response.filter((t) => t.id_status === 5));

        setOpenLoading(false);

    };

    const [documentType, setDocumentType] = useState(0);
    const [textSearch, setTextSearch] = useState("");

    const handleChangeDocumentType = (event, newValue) => {
        setDocumentType(newValue);
        setTextSearch("");
    }

    const handleSearchTask = async (e) => {
        setTextSearch(e);
        const userData = MyConfigurationApi.userData();
        const response = await MyTasks.taskByUser({ idUser: userData.id_users });
        const pendingTasks = response.filter((t) => t.id_status === 1);
        const currentTasks = response.filter((t) => t.id_status === 2);
        const tasksToValidate = response.filter((t) => t.id_status === 3);
        const approvedTaks = response.filter((t) => t.id_status === 4);

        if (e !== "") {
            let expresion = new RegExp(`${e}.*`, "i");

            let pendingTaskSelected = pendingTasks.filter((task) => expresion.test(task.title));
            let currentTaskSelected = currentTasks.filter((task) => expresion.test(task.title));
            let toValidateTaskSelected = tasksToValidate.filter((task) => expresion.test(task.title));
            let approvedTaskSelected = approvedTaks.filter((task) => expresion.test(task.title));

            if (documentType === 0) {
                setPendingTask(pendingTaskSelected);
            } else if (documentType === 1) {
                setCurrentTask(currentTaskSelected);
            } else if (documentType === 2) {
                setTaskToValidate(toValidateTaskSelected);
            } else if (documentType === 3) {
                setApprovedTask(approvedTaskSelected)
            }

        } else {
            handleGetTasksByUser();

        }

    }

    // const taskStatus = [
    //     { id_status: 1, status: "Pendiente" },
    //     { id_status: 2, status: "En curso" },
    //     { id_status: 3, status: "Por validar" },
    // ];
    const [taskStatus, setTaskStatus] = useState([]);
    const getTaksStatus = async () => {
        const resp = await MyTasksNew.getTasksStatus();
        setTaskStatus(resp);
    }



    const [status, setStatus] = useState("1");

    const handleUpdateTaskStatus = async ({ idTask, status }) => {
        const response = await MyTasks.taskStatusUpdate({
            idTask: idTask,
            status: status,
        });
        handleGetTasksByUser();
    };

    useEffect(() => {
        handleGetTasksByUser();
        getTaksStatus();

    }, []);

    return (
        <Box>
            <ReturnBack/>

            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ButtonMailboxControla
                                originUrl={"/activities/task/mytasks"}
                            />
                        </Grid>

                        <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                            <SubtitleText
                                text={"Actividades"}
                                color={colorTitle}
                            ></SubtitleText>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <BannerControla
                                image={
                                    "/assets/images/banners/configuration/improvactionsbaselinebanner.png"
                                }
                                text={"Mis tareas"}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        sx={{ mt: 2 }}
                        justifyContent="center"
                        direction="row"
                        alignItems="center"
                    >
                        <Grid item>
                            <SearchGeneralControla
                                placeholder={"Buscar tarea ...."}
                                handleActionOnChange={(event) => { handleSearchTask(event.target.value) }}
                                value={textSearch}
                            />
                        </Grid>

                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                sx={{ backgroundColor: "#FFFFFF", width: "50%", borderRadius: '8px 8px 0px 0px' }}
                            >
                                <Tabs
                                    value={documentType}
                                    onChange={handleChangeDocumentType}
                                    aria-label="Tipos de documento"
                                >
                                    <Tab label="Pendiente" />
                                    <Tab label="En curso" />
                                    <Tab label="Por validar" />
                                    <Tab label="Aprobado" />
                                    <Tab label="Anulado" />
                                </Tabs>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper sx={{ overflow: "hidden" }}>
                                <TableContainer sx={{ maxHeight: 400 }}>
                                    <Table
                                        stickyHeader
                                        sx={{ width: "100%", minWidth: "700px" }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow>

                                                <TableCell sx={{ color: blue }} align="center">
                                                    Título
                                                </TableCell>

                                                <TableCell sx={{ color: blue }} align="center">
                                                    Descripción
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: blue,
                                                        width: "10%",
                                                    }}
                                                    align="center"
                                                >
                                                    Fecha de inicio
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: blue,
                                                        width: "10%",
                                                    }}
                                                    align="center"
                                                >
                                                    Fecha de vencimiento
                                                </TableCell>
                                                {
                                                    documentType === 4 || documentType === 3 ? "" : (
                                                        <TableCell
                                                            sx={{
                                                                color: blue,
                                                                width: "10%",
                                                            }}
                                                            align="center"
                                                        >
                                                            Estado
                                                        </TableCell>
                                                    )
                                                }
                                                {
                                                    documentType != 3 ? (
                                                        <TableCell
                                                            sx={{
                                                                color: blue,
                                                                width: "10%",
                                                            }}
                                                            align="center"
                                                        >
                                                            Acciones
                                                        </TableCell>

                                                    ) : ""
                                                }


                                            </TableRow>
                                        </TableHead>

                                        <TableBody
                                            sx={{ display: documentType === 0 ? "" : "none" }}
                                        >
                                            {pendingTask.map((row) =>

                                                <TableRow
                                                    hover
                                                    key={row.id}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >

                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.title}
                                                    </TableCell>

                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.description}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.date_start === null
                                                            ? "Sin fecha de inicio"
                                                            : row.date_start}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.date_end === null
                                                            ? "Sin fecha de fin"
                                                            : row.date_end}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        <SelectControlaRoles
                                                            inputValue={setStatus}
                                                            value={row.id_status}
                                                            childrenRows={taskStatus.map((rows) => (
                                                                <MenuItem
                                                                    key={rows.id_tasks_status}
                                                                    value={rows.id_tasks_status}
                                                                >
                                                                    {rows.status}
                                                                </MenuItem>
                                                            ))}
                                                            onChange={
                                                                (event) => {
                                                                    handleUpdateTaskStatus({
                                                                        idTask: row.id_tasks,
                                                                        status: event.target.value,
                                                                    });
                                                                }

                                                            }
                                                        />

                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            color: blue,
                                                            width: "10%",
                                                        }}
                                                        align="center"
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignItems="center"
                                                        >
                                                            <ButtonIconControla
                                                                icon={
                                                                    <CommentIcon
                                                                        sx={{ color: "white" }}
                                                                    />
                                                                }
                                                                backgroundColor={"#FFC300"}
                                                                backgroundColorHover={"#FFC300"}
                                                                textTooltip={"Ocurrencias"}
                                                                isNavegationOrigin={true}
                                                                directionUrl={"/activities/task/taksWorkers/taskOccurrences"}
                                                                paramsIntUrl={{ id_tasks: row.id_tasks }}
                                                            />
                                                        </Stack>

                                                    </TableCell>
                                                </TableRow>

                                            )}
                                        </TableBody>

                                        <TableBody
                                            sx={{ display: documentType === 1 ? "" : "none" }}
                                        >
                                            {currentTask.map((row) =>
                                                <TableRow
                                                    hover
                                                    key={row.id}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >

                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.title}
                                                    </TableCell>

                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.description}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.date_start === null
                                                            ? "Sin fecha de inicio"
                                                            : row.date_start}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.date_end === null
                                                            ? "Sin fecha de fin"
                                                            : row.date_end}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        <SelectControlaRoles
                                                            roles={[1, 7, 8, 9]}
                                                            inputValue={setStatus}
                                                            value={row.id_status}
                                                            childrenRows={taskStatus.map((rows) => (
                                                                <MenuItem
                                                                    key={rows.id_tasks_status}
                                                                    value={rows.id_tasks_status}
                                                                >
                                                                    {rows.status}
                                                                </MenuItem>
                                                            ))}
                                                            onChange={
                                                                (event) => {
                                                                    handleUpdateTaskStatus({
                                                                        idTask: row.id_tasks,
                                                                        status: event.target.value,
                                                                    });
                                                                }

                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            color: blue,
                                                            width: "10%",
                                                        }}
                                                        align="center"
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignItems="center"
                                                        >
                                                            <ButtonIconControla
                                                                roles={[1, 3, 4, 7, 8, 9]}
                                                                icon={
                                                                    <CommentIcon
                                                                        sx={{ color: "white" }}
                                                                    />
                                                                }
                                                                backgroundColor={"#FFC300"}
                                                                backgroundColorHover={"#FFC300"}
                                                                textTooltip={"Ocurrencias"}
                                                                isNavegationOrigin={true}
                                                                directionUrl={"/activities/task/taksWorkers/taskOccurrences"}
                                                                paramsIntUrl={{ id_tasks: row.id_tasks }}
                                                            />
                                                        </Stack>

                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>

                                        <TableBody
                                            sx={{ display: documentType === 2 ? "" : "none" }}
                                        >
                                            {taskToValidate.map((row) =>
                                                <TableRow
                                                    hover
                                                    key={row.id}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >

                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.title}
                                                    </TableCell>

                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.description}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.date_start === null
                                                            ? "Sin fecha de inicio"
                                                            : row.date_start}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.date_end === null
                                                            ? "Sin fecha de fin"
                                                            : row.date_end}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        Por validar
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            color: blue,
                                                            width: "10%",
                                                        }}
                                                        align="center"
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignItems="center"
                                                        >
                                                            <ButtonIconControla
                                                                roles={[1, 7, 8, 9]}
                                                                icon={
                                                                    <CommentIcon
                                                                        sx={{ color: "white" }}
                                                                    />
                                                                }
                                                                backgroundColor={"#FFC300"}
                                                                backgroundColorHover={"#FFC300"}
                                                                textTooltip={"Ocurrencias"}
                                                                isNavegationOrigin={true}
                                                                directionUrl={"/activities/task/taksWorkers/taskOccurrences"}
                                                                paramsIntUrl={{ id_tasks: row.id_tasks }}
                                                            />
                                                        </Stack>

                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>

                                        <TableBody
                                            sx={{ display: documentType === 3 ? "" : "none" }}
                                        >
                                            {approvedTask.map((row) =>
                                                <TableRow
                                                    hover
                                                    key={row.id}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >

                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.title}
                                                    </TableCell>

                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.description}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.date_start === null
                                                            ? "Sin fecha de inicio"
                                                            : row.date_start}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ color: purple }}
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {row.date_end === null
                                                            ? "Sin fecha de fin"
                                                            : row.date_end}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>

                                        <TableBody
                                            sx={{ display: documentType === 4 ? "" : "none" }}
                                        >
                                            {
                                                canceledTask.map((row) =>
                                                    <TableRow
                                                        hover
                                                        key={row.id}
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                        }}
                                                    >
                                                        <TableCell
                                                            sx={{ color: purple }}
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {row.title}
                                                        </TableCell>

                                                        <TableCell
                                                            sx={{ color: purple }}
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {row.description}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ color: purple }}
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {row.date_start === null
                                                                ? "Sin fecha de inicio"
                                                                : row.date_start}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ color: purple }}
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {row.date_end === null
                                                                ? "Sin fecha de fin"
                                                                : row.date_end}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                color: blue,
                                                                width: "10%",
                                                            }}
                                                            align="center"
                                                        >
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                            >
                                                                <ButtonIconControla
                                                                    icon={
                                                                        <CommentIcon
                                                                            sx={{ color: "white" }}
                                                                        />
                                                                    }
                                                                    backgroundColor={"#FFC300"}
                                                                    backgroundColorHover={"#FFC300"}
                                                                    textTooltip={"Ocurrencias"}
                                                                    isNavegationOrigin={true}
                                                                    directionUrl={"/activities/task/taksWorkers/taskOccurrences"}
                                                                    paramsIntUrl={{
                                                                        id_tasks: row.id_tasks,
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            </Stack>

                                                        </TableCell>

                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>


    );

}
export default MyTask;