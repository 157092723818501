// src/components/ContractorUserModal.js

import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    TextField,
    Button,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@mui/material';

// Estilo para el modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: '80%',
    width: '50%',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
};

const ContractorEmployeeModal = ({ open, id_contractor, handleClose, handleSubmit, initialData }) => {

    const [formData, setFormData] = useState({
        id_contractor: '',
        nombres: '',
        apellido_paterno: '',
        apellido_materno: '',
        n_documento: '',
        fecha_nacimiento: '',
        sexo: '',
        celular: '',
        puesto_trabajo: '',
        fecha_inicio_actividades: '',
        tipo_contrato: '',
    });

    useEffect(() => {
        if (initialData) {
            setFormData(initialData);
        } else {
            resetForm();
        }
    }, [initialData, open]);

    const resetForm = () => {
        setFormData({
            id_contractor: '',
            nombres: '',
            apellido_paterno: '',
            apellido_materno: '',
            n_documento: '',
            fecha_nacimiento: '',
            sexo: '',
            celular: '',
            puesto_trabajo: '',
            fecha_inicio_actividades: '',
            tipo_contrato: '',
        });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit(formData);
    };

    return (
        <Modal open={open} onClose={handleClose} style={{
            height: '100%',
            width: '100%',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Box sx={{
                ...style,
                width: { xs: '90%', sm: '80%', md: '50%', lg: '50%', xl: '50%' },
                height: { xs: '90%', sm: '80%', md: '80%', lg: '80%', xl: '80%' },
            }}>
                <Typography variant="h6" component="h2">
                    {initialData ? 'Editar Trabajador' : 'Registrar Trabajador'}
                </Typography>
                <form onSubmit={(e) => e.preventDefault()} style={{
                    overflowX: 'auto',
                    height: '100%',
                    padding: '0.2rem 0.8rem'
                }}>
                    <TextField
                        label="Nombres"
                        name="nombres"
                        value={formData.nombres}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Apellido Paterno"
                        name="apellido_paterno"
                        value={formData.apellido_paterno}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Apellido Materno"
                        name="apellido_materno"
                        value={formData.apellido_materno}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="N° Documento"
                        name="n_documento"
                        value={formData.n_documento}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Fecha Nacimiento"
                        type="date"
                        name="fecha_nacimiento"
                        value={formData.fecha_nacimiento ? formData.fecha_nacimiento.split(' ')[0] : ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />
                    <FormControl fullWidth>
                        <InputLabel>Sexo</InputLabel>
                        <Select
                            label="Sexo"
                            name="sexo"
                            value={formData.sexo ?? ''}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                        >
                            <MenuItem value="M">Masculino</MenuItem>
                            <MenuItem value="F">Femenino</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Celular"
                        type="text"
                        name="celular"
                        value={formData.celular ?? ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />
                    <TextField
                        label="Puesto de trabajo"
                        type="text"
                        name="puesto_trabajo"
                        value={formData.puesto_trabajo ?? ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />
                    <TextField
                        label="Fecha de inicio de actividades"
                        type="date"
                        name="fecha_inicio_actividades"
                        value={formData.fecha_inicio_actividades ? formData.fecha_inicio_actividades.split(' ')[0] : ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />
                    <TextField
                        label="Tipo de contrato"
                        type="text"
                        name="tipo_contrato"
                        value={formData.tipo_contrato ?? ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />
                </form>
                <Box style={{
                    margin: '1rem 0', display: 'flex', alignItems: 'center', justifyContent: 'right',
                }}>
                    <Button type="button" variant="contained" color="primary" onClick={onSubmit}>
                        {initialData ? 'Actualizar' : 'Agregar'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ContractorEmployeeModal;