import { Button, Grid, Tooltip } from "@mui/material";
import { React } from "react";
import { validationRoles } from "../../utils/validation_rol";
import { useNavigate } from "react-router-dom";

function ButtonControla({
  iconButton,
  backgroundColor,
  textButton,
  functionOnClick,
  backgroundColorHover = undefined,
  isNavegationOrigin=false,
  url,
  target = "",
  typeButton = "button",
  roles = [],
  tooltipText,
  display,
  ...rest
}) {
  const navigate = useNavigate();
  const showButton = validationRoles(roles)

  return showButton && (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Tooltip title={tooltipText} placement="top">
          <Button
            {...rest}
            variant="contained"
            type={typeButton}
            sx={{
              backgroundColor: { backgroundColor },
              textTransform: "none",
              boxShadow: "none",
              borderRadius: "16px",
              "&:hover": {
                backgroundColor:
                  backgroundColorHover === undefined
                    ? backgroundColor
                    : backgroundColorHover,
              },
              minWidth: 'max-content',
              width: '100%',
              padding: '0.5rem',

              display: { display },
            }}
            onClick={isNavegationOrigin ? ()=>{navigate(url)}: functionOnClick}
            startIcon={iconButton}
            href={isNavegationOrigin ? null : url}
            target={target}
          >
            {textButton}
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default ButtonControla;
