import MyConfigurationApi from "./MyConfigurationApi";

//Estado 1: Creado
//Estado 2: Completado

//obtiene todas las tareas
function getTasks() {
  const url = "tasks";
  return MyConfigurationApi.connectFormApiGeneral({
    dataJson: null,
    method: "GET",
    url: url,
  });
}
//agrega una nueva tarea
function newTask({
  initialDate,
  description,
  idResponsible,
  endDate,
  idTaskType,
  title,
}) {
  const url = "tasks";
  const data = {
    date_start: initialDate,
    date_end: endDate,
    description: description,
    id_users: idResponsible,
    id_tasks_types: idTaskType,
    title: title,
    id_status: 1
    
  };
  console.log(data);
  return MyConfigurationApi.connectFormApiGeneral({
    dataJson: data,
    method: "POST",
    url: url,
  });
}
//actualiza el estado de la tarea
function taskStatusUpdate({ idTask, status }) {
  const url = "task/update/statuses/" + idTask;
  const data = {
    id_status: status,
  };

  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

//actualiza una tarea

function editTask({
  idTask,
  initialDate,
  description,
  idResponsible,
  endDate,
  title,
}) {
  const url = "tasks/" + idTask;
  const data = {
    timestamp_start: initialDate,
    timestamp_end: endDate,
    description: description, 
    id_users: idResponsible,
    title,
  };
  console.log(idTask);
  return MyConfigurationApi.connectFormApiGeneral({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

//elimina una tarea
function deleteTask({ idTask }) {
  const url = "tasks/" + idTask;
  return MyConfigurationApi.connectFormApiGeneral({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}
//obtiene las tareas de un usuario
function taskByUser({ idUser }) {
  const url = "tasks/users/" + idUser;
  return MyConfigurationApi.connectFormApiGeneral({
    dataJson: null,
    method: "GET",
    url: url,
  });
}
///------------------------comentarios de tarea
function postCommentByIdTask(data) {
  const url = "task/comment/create";
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function getCommentByIdTask(id_tasks) {
  const url = "task/comments/get/task/" +id_tasks ;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function UpdateCommentByIdTask(id_comment, data) {
  const url = "task/comment/update/" + id_comment ;
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function deleteCommentByIdTask(id_comment){
  const url = "task/comment/remove/" + id_comment ;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}


const MyTasks = {
  getTasks,
  newTask,
  taskStatusUpdate,
  taskByUser,
  editTask,
  deleteTask,
  postCommentByIdTask,
  getCommentByIdTask,
  UpdateCommentByIdTask,
  deleteCommentByIdTask
};

export default MyTasks;
