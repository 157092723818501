import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import {
    BorderColorOutlined,
    Delete
  } from "@mui/icons-material";

import { useAppContext } from "../../context/AppContext";

function ListInProgress (props){
    const {permissions } = useAppContext();

    const {
        baselineInProgressRows,
        colorCell,
        handleOpenEditDiagnosticModal,
        handleOpenAlertModal
    } = props
    return (
        <TableBody>
            {baselineInProgressRows.map((rowProgress) => (
                <TableRow
                    hover
                    key={rowProgress.id_baseline}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell component="th" scope="row" sx={{ color: colorCell }}>
                        {rowProgress.name === null ||
                            rowProgress.name === undefined
                            ? ""
                            : rowProgress.name}
                    </TableCell>
                    <TableCell align="center" sx={{ color: colorCell }}>
                        {rowProgress.document_url !== null
                            ? "ARCHIVO"
                            : "SISTEMA"}
                    </TableCell>
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {rowProgress.document_url === null ? (
                                <ButtonIconControla
                                    roles={permissions.LINE_DIAG_PROC_EDITAR}
                                    icon={
                                        <BorderColorOutlined
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#2D9CDB"}
                                    textTooltip={"Editar"}
                                    isNavegationOrigin={true}
                                    directionUrl={"/baseline/answers?id_baseline=" + rowProgress.id_baseline}
                                />
                            ) : (
                                <ButtonIconControla
                                    roles={permissions.LINE_DIAG_PROC_EDITAR}
                                    icon={
                                        <BorderColorOutlined
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#2D9CDB"}
                                    textTooltip={"Editar"}
                                    functionOnClick={
                                        handleOpenEditDiagnosticModal
                                    }
                                />
                            )}

                            <ButtonIconControla
                                roles={permissions.LINE_DIAG_PROC_ELIMINAR}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                textTooltip={"Eliminar"}
                                functionOnClick={
                                    () =>
                                        handleOpenAlertModal(
                                            rowProgress.id_baseline
                                        )
                                }
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListInProgress;