import MyConfigurationApi from "./MyConfigurationApi";

function getTasks (){
    const url = "task/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });

}
function postTasks (data){
    const url = "task/create";
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}
function getTasksStatus (){
  const url = "tasks/gallery/get";
return MyConfigurationApi.connectApi({
  dataJson: null,
  method: "GET",
  url: url,
});
}

const MyTasksNew ={
    getTasks,
    postTasks,
    getTasksStatus,

}

export default MyTasksNew;