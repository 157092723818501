// colocar lo de Workaccidents.jsx
import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {
  Add,
  InfoOutlined,
} from "@mui/icons-material";

import SubtitleText from "../../components/text/subtitleText";
import BannerControla from "../../components/cards/bannerControla";
import ButtonControla from "../../components/buttons/buttonController";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import styled from "styled-components";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import MyRiskMap from "../../api/MyRiskMap";
import { useAppContext } from "../../context/AppContext";
import ListActives from "./components/list-actives";
import ListArchived from "./components/list-archived";
import TableControla from "../../components/tables/table-controla";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";
import ReturnBack from "../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";
const skyblue = "#2D9CDB";
const purple = "#8B8BD8";

function Index() {
  const { permissions } = useAppContext();
  const StyledAddButton = styled(Button)({
    textTransform: "none",
    backgroundColor: skyblue,
    borderRadius: 16,
  });
  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );

  // alerts
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
  const [titleText, setTitleText] = React.useState("");
  const [contentText, setContentText] = React.useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = React.useState("");
  const [buttonActionType, setButtonActionType] = React.useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdRiskMap(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "delete":
        handleDeleteRiskMap();
        break;
      case "public":
        updateStatus(4);
        break;
      case "archived":
        updateStatus(5);
        break;
      case "unarchived":
        updateStatus(4);
        break;
      case "comite":
        updateStatus(3);
        break;
      case "workers":
        updateStatus(7);
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  const [openModalRiskMap, setOpenModalRiskMap] = React.useState(false);
  const handleOpenModalAddMatrix = () => {
    setName("");
    setOpenModalRiskMap(true);
  };

  const [riskMap, setRiskMap] = React.useState([]);
  const [name, setName] = React.useState("");
  const [file, setFile] = React.useState("");

  const handleAddRiskMap = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyRiskMap.createRiskMapx({ name, file });
    handleGetRiskMap();
    setOpenModalRiskMap(false);
    handleOpenAlert("Se crearon los datos de forma correcta", "success");
    return response;
  };

  const handleGetRiskMap = async () => {
    handleOpenLoading();

    const response = await MyRiskMap.getRiskMap();
    let letriskmaparchived = response.filter(
      (status) => status.register_status === 5
    );
    let letriskmap = response.filter((status) => status.register_status !== 5);

    setRiskMapArchived(letriskmaparchived);
    setRiskMap(letriskmap);

    setOpenLoading(false);
    return response;
  };

  //   // eliminar
  const [idRiskMap, setIdRiskMap] = React.useState();

  const handleDeleteRiskMap = async () => {
    handleOpenLoading();

    const response = await MyRiskMap.deleteRiskMap({
      idRiskMap: idRiskMap,
    });
    if (response.success === true) {
      handleGetRiskMap();
      handleOpenAlert(
        "Los datos fueron eliminados de forma correcta",
        "success"
      );
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  //   infromacion del mapa de riesgos
  const StyledModalTitle = styled("h2")({
    color: purple,
  });
  const StyledModalSubtitle = styled("h3")({
    fontWeight: "bold",
  });

  const [openModalInfo, setOpenModalInfo] = React.useState(false);

  const handleOpenModalInfo = () => {
    setOpenModalInfo(true);
  };

  // document type
  const [documentType, setDocumentType] = React.useState(
    idTabs !== undefined && idTabs !== null && idTabs !== "" ? idTabs : 0
  );

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  // actualizar estado
  const updateStatus = async (idStatus) => {
    handleOpenLoading();
    const response = await MyRiskMap.updatestatus({
      idRiskMap: idRiskMap,
      register_status: idStatus,
    });
    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      handleGetRiskMap();
      handleOpenAlert("Operación realizada con éxito", "success");
    }
    return response;
  };

  const [riskMapArchived, setRiskMapArchived] = React.useState([]);
 
  // EDITAR 
  // const [idRist, setIdRist] = useState();
  const [openModalUploaFileEdit, setOpenModalUploadFileEdit] = React.useState(false);
  const handleOpenModalUploadFileEdit = (idRiskMap) => {
    setIdRiskMap(idRiskMap);
    handleEdit(idRiskMap);
    setOpenModalUploadFileEdit(true);
  };

  const handleEdit = async (idRiskMap) => {
    const response = await MyRiskMap.editRiskMap({ idRiskMap: idRiskMap });
      setName(response.name);
    // setTitle(response.title);
    // setDate(response.date);
    return response;
  };
  const handleUpdate= async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyRiskMap.updateRiskMap({
      idRiskMap: idRiskMap,
      name: name,
      file: file
    });
    handleGetRiskMap();
    setOpenModalUploadFileEdit(false);
    handleOpenAlert("Los datos fueron editados de forma correcta", "success");
    return response;
  };

  React.useEffect(() => {
    handleGetRiskMap();
    // handleriskMapArchived();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction="row" justifyContent="flex-start" spacing={0}>
            <Grid item xs={5} md={5}>
              <ButtonMailboxControla
                originUrl={"/documentation/riskmap"}
              />
            </Grid>
            <Grid item xd={7} md={7} style={{ textAlign: "right" }}>
              <StyledAddButton
                variant="text"
                startIcon={<InfoOutlined />}
                disableElevation
                size="large"
                style={{
                  backgroundColor: "#305AD9",
                  borderRadius: "15px",
                  color: "white",
                  padding: "8px 25px",
                }}
                onClick={() => handleOpenModalInfo()}
              >
                ¿Qué debo saber sobre los mapas de riesgos?
              </StyledAddButton>
            </Grid>

          </Grid>

          <Grid container>

            <Grid item xs={2} md={2}>
              <SubtitleText
                text={"Documentación"}
                color={colorTitle}
              ></SubtitleText>
            </Grid>

            <Grid item xs={12} md={12}>
              <BannerControla
                image="/assets/images/banners/documents/bannerriskmap.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Mapa de riesgos"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                roles={permissions.DOC_MAP_RIESGO_ACTIVOS_AGREGAR}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar nuevo mapa"}
                functionOnClick={handleOpenModalAddMatrix}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid item md={12}>
                <TableControla
                  loading={openLoading}
                  tabs={[
                    {
                      label: "Activos",
                      columnNames: ["Nombre del documento", "Estado"]
                    },
                    {
                      label: "Archivados",
                      columnNames: ["Nombre del documento", "Estado"]
                    }
                  ]}
                >
                  <ListActives
                    riskMap={riskMap}
                    colorCell={purple}
                    handleOpenModalUploadFileEdit={handleOpenModalUploadFileEdit}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                  />
                  <ListArchived
                    riskMapArchived={riskMapArchived}
                    colorCell={purple}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                  />

                </TableControla>


              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalRiskMap}
        onClose={() => setOpenModalRiskMap(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Crear nuevo mapa de riesgo
          </Typography>
        </DialogTitle>
        <form onSubmit={handleAddRiskMap}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre:"}
                  inputType={"text"}
                  inputValue={setName}
                  value={name}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Archivo"}
                  inputType={"file"}
                  inputValue={setFile}
                  value={file}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalRiskMap(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog apra editar mapa de riesgos */}
      <Dialog
        open={openModalUploaFileEdit}
        onClose={() => setOpenModalUploadFileEdit(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Editar nuevo mapa de riesgo
          </Typography>
        </DialogTitle>
        <form onSubmit={handleUpdate}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre:"}
                  inputType={"text"}
                  inputValue={setName}
                  value={name}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Archivo"}
                  inputType={"file"}
                  inputValue={setFile}
                  value={file}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalUploadFileEdit(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Actualizar
            </Button>
          </DialogActions>
        </form>
      </Dialog>


      {/* dialogo de información */}
      <Dialog
        open={openModalInfo}
        onClose={() => setOpenModalInfo(false)}
        maxWidth="lg"
        fullWidth={true}
        sx={{
          padding: "20px",
        }}
      >
        <DialogTitleControla titleText="MAPA DE RIESGOS" />
        <DialogContent>
          <Grid container>
            <Grid item md={12}>
              <StyledModalTitle>1. Introducción:</StyledModalTitle>
            </Grid>
            <Grid item>
              El mapa de riesgos es una herramienta informativa y dinámica que
              forma parte del Sistema de Gestión de Seguridad y Salud
              Ocupacional, por lo que su elaboración y mejora deben estar
              siempre documentadas. A continuación, te presentamos sus
              principales características:
            </Grid>
            <Grid item md={12}>
              <StyledModalTitle>2. Consideraciones generales:</StyledModalTitle>
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Qué es un mapa de riesgos?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Es un plano que localiza los riesgos de una instalación o área
              para determinar las acciones de promoción y protección de la salud
              de los trabajadores en la organización del empleador y los
              servicios que presta.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>¿Cuál es su origen?</StyledModalSubtitle>
            </Grid>
            <Grid item>
              El mapa de riesgos tiene su origen en el IPERC que analiza los
              procesos productivos, las sustancias utilizadas, la organización
              del trabajo, es decir, las condiciones de trabajo y determina la
              exposición al riesgo de los trabajadores.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>¿Para qué sirve?</StyledModalSubtitle>
            </Grid>
            <Grid item>
              El mapa de riesgos sirve para:
              <ol type="a">
                <li>
                  Localizar los riesgos de manera práctica así como las medidas
                  de control.
                </li>
                <li>
                  Analizar de manera sistemática y actualizable los riesgos con
                  el objetivo de definir las áreas críticas.{" "}
                </li>
                <li>
                  Al ser dinámico, permite facilitar el análisis colectivo de
                  las condiciones de trabajo, así como dar un seguimiento a la
                  evolución de los riesgos en coherencia con la implementación
                  de las nuevas tecnologías.{" "}
                </li>
              </ol>
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Cómo elaboramos el mapa de riesgos?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>De acuerdo a la normativa vigente se recomienda:</Grid>
            <Grid item>
              <ol type="a">
                <li>
                  Elaborar un plano sencillo de las instalaciones de la empresa
                  ubicando los puestos de trabajo, maquinarias o equipos
                  existentes que generan riesgo alto.{" "}
                </li>
                <li>Asignarle un símbolo que represente el tipo de riesgo. </li>
                <li>
                  Asignar un símbolo para adoptar las medidas de protección a
                  utilizarse
                </li>
              </ol>
            </Grid>
            <Grid item md={12}>
              <StyledModalTitle>3. Simbología establecida</StyledModalTitle>
            </Grid>
            <Grid item>
              NTP 399.010-1: 2016, SEÑALES DE SEGURIDAD. Símbolos gráficos y
              colores de seguridad. Parte 1: Reglas para el diseño de las
              señales de seguridad y franjas de seguridad. 3ra. Edición.
            </Grid>
            <Grid>
              <a
                href="/assets/images/info/NTP Símbolos de seguridad.pdf"
                target="_blank"
              >
                <ButtonControla
                  textButton={"Ver gráficos"}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                />
              </a>
            </Grid>
            <Grid item md={12}>
              <StyledModalTitle>
                4. Fiscalización del mapa de riesgos
              </StyledModalTitle>
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Qué aspectos principales considerará la autoridad ante una
                actuación inspectiva?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              <ul type="1. ">
                <li>
                  Que la participación de los trabajadores en la elaboración y/o
                  actualización del mapa de riesgos debidamente documentada y
                  registrada.
                </li>
                <li>
                  Que la participación (revisión) del Comité de Seguridad y
                  Salud Ocupacional en la elaboración y/o actualización del mapa
                  de riesgos esté debidamente documentada y registrada.
                </li>
                <li>
                  Que se pueda elaborar tantos mapas de riesgo como hagan falta,
                  de acuerdo al tamaño de la empresa, número de pisos, o zonas
                  de riesgo diferenciadas.
                </li>
                <li>
                  Que el mapa de riesgos se exhiba en el centro de labores.
                </li>
                <li>
                  Que el mapa de riesgos contenga una leyenda a fin de que los
                  trabajadores puedan saber el significado de cada señal.
                </li>
                <li>
                  Que el mapa de riesgos contenga las señales de peligros,
                  prohibiciones, y uso de EPP para cada zona. La actualización
                  del mapa de riesgos se realiza de manera periódica, teniendo
                  en cuenta las actualizaciones o modificaciones de la matriz
                  IPERC.
                </li>
                <li>
                  Que los trabajadores hayan sido capacitados en el contenido y
                  uso del mapa de riesgos.
                </li>
              </ul>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalInfo(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Index;
