import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  FileOpen,
  InfoOutlined,
  PictureAsPdf,
  BorderColorOutlined
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import LoadingControla from "../../../../components/load/loadingControla";

import MyAreas from "../../../../api/MyAreas";
import MyOccupationalPositions from "../../../../api/MyOccupationalPositions";
import MyMypeTracking from "../../../../api/MyMypeTracking";
import MyAccidentsOccupationalsIncidents from "../../../../api/MyAccidentsOccupationalsIncidents";

import DialogInformativeControla from "../../../../components/dialog/dialogInformativeControla";
import MyTasksNew from "../../../../api/MyTasksNew";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import MyTrainings from "../../../../api/MyTrainings";
import SearchUsersAndOtherUserControla from "../../../../components/search/searchUsersAndAddOtherUserControla";
import { useAppContext } from "../../../../context/AppContext";
import ButtonSignatureControla from "../../../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";

function Edit() {
  const {permissions} = useAppContext();

  const params = new URLSearchParams(window.location.search);
  const idRegistersMypeTracking = params.get("idRegistersMypeTracking");

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdMonitoringAgents(id);
    setIdEquipment(id);
    setIdWorkerInvolved(id);
    setIdResponsibleWorker(id);
    setIdTraining(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = async() => {
    switch (buttonActionType) {
      case "deleteAgents":
        await handleDeleteMonitoringAgents();
        break;
      case "deleteEquipment":
        await handleDeleteEquipment();
        break;
      case "deleteWorkers":
        await handleDeleteWorkerInvolved();
        break;
      case "deleteResponsible":
        await handleDeleteResponsibleWorker();
        break;
      case "deleteInduction":
        await handleDeleteInduction();
        break;
      case "deleteInstruction":
        await handleDeleteInstruction();
        break;
      case "deleteTraining":
        await handleDeleteTraining();
        break;
      case "deleteSimulacrum":
        await handleDeleteSimulacrum();
        break;
    }
    setOpenModalConfirm(false);
  };
  // Dialogos de confirmacion FIN

  //*************************  formulario general  *******************************************
  const [rowsArea, setRowsAreaWorkerInvolved] = useState([]);
  const [rowsPositionsByArea, setRowsPositionsByArea] = useState([]);
  const [rowsPositions, setRowsPositions] = useState([]);

  //mostrar las áreas desde configuración
  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setRowsAreaWorkerInvolved(response);
    return response;
  };

  const handleGetPositionsByIdArea = async () => {
    //area seleccionada
    handleOpenLoading();

    const resp = await MyAreas.getPositionsByIdArea(idArea);
    setRowsPositionsByArea(resp);
    setOpenLoading(false);
  }

  const handleGetPositions = async () => {
    const resp = await MyOccupationalPositions.getPositions();
    setRowsPositions(resp);
  }

  const [code, setCode] = useState("");
  const [idArea, setIdArea] = useState("");
  const [nameArea, setNameArea] = useState(0);
  const [numberWorkersArea, setNumberWorkersArea] = useState("");
  const [TypeTranning, setTypeTranning] = useState("");
  const [date, setDate] = useState("");
  const [theme, setTheme] = useState("");
  const [numberHour, setNumberHour] = useState("");
  const [nameTrainer, setNameTrainerr] = useState("");

  // update register
  const handleUpdateRegisters = async () => {
    handleOpenLoading();
    const response = await MyMypeTracking.updateRegisters({
      idRegistersMype: idRegistersMypeTracking,
      code: code,
      area: nameArea,
      number_workers_area: numberWorkersArea,
      id_type_severity_work_accident: TypeTranning,
      theme: theme,
      date: date,
      number_hours: numberHour,
      name_coach: nameTrainer,
      register_status: 1,
    });
    if (response.success === false) {
      handleOpenAlert("Ocurrió un error al actualizar los datos", "error");
    } else {
      handleOpenAlert("Se actualizaron los datos", "success");
    }
    return response;
  };

  // edit register
  const handleEditRegisters = async () => {
    handleOpenLoading();
    const response = await MyMypeTracking.editRegisters({
      idRegistersMype: idRegistersMypeTracking,
    });
    if (response.success === false) {
    } else {
      setRegisterStatus(response.register_status === 1 ? true : false);
      setCode(response.code);
      setNameArea(response.area);
      setIdArea(response.id_areas);
      setNumberWorkersArea(response.number_workers_area);
      setTypeTranning(response.id_type_severity_work_accident);
      setTheme(response.theme);
      setDate(response.date);
      setNumberHour(response.number_hours);
      setNameTrainerr(response.name_coach);
    }
    setOpenLoading(false);
    return response;
  };


  // USUARIOS ACTIVOS
  const [rowsUsersActives, setRowsUsersActives] = useState([]);
  const handleGetUsersActives = async() => {
    const resp = await MyUsers.users();
    setRowsUsersActives(resp.users_actives);
  }

  const [rowsResponsiblesSearchs, setRowsResponsiblesSearchs] = useState([]);
  const handleGetResponsibleSearch = () => {
    let users = rowsUsersActives;

    responsibleWorkers.forEach((user) => {
      users = users.filter((u) => u.id_users !== user.id_users);
    });

    setRowsResponsiblesSearchs(users);
  }

  const [rowsAssistantsSearch, setRowsAssistantsSearch] = useState([]);
  const handleGetAssistantsSearch = () => {
    let users = rowsUsersActives;

    asistents.forEach((user) => {
      users = users.filter((u) => u.id_users !== user.id_users);
    });

    setRowsAssistantsSearch(users);
  }

  // buscar usuario por ID y setear sus datos
  const [nameResponsibleInput, setResponsibleInput] = useState(null);

  const handleSelectUsersResponsible = async (idUsers) => {
    setIdResponsible(idUsers);
    handleOpenLoading();

    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setResponsibleInput(response.first_name + " " + response.last_name);
      setOpenModalInvolvedWorkersResponsible(false);
      setOpenModalOccupationalDiseasesResponsible(false);
      handleOpenAlert("", "success");
    } else {
    }
  };

  //*************************  Monitoreo de agentes  *******************************************
  const [monitoringAgents, setMonitoringAgents] = useState([]);

  const [dateEjecution, setDateEjecution] = useState("");
  const [endDateMeasure, setEndDateMeasure] = useState("");
  const [file, setFile] = useState("");

  const [correctiveActions, setCorrectiveActions] = useState("");
  const [
    agentGenerateOccupationalDisease,
    setAgentGenerateOccupationalDisease,
  ] = useState("");
  const handleChangeAgentGenerateOccupationalDisease = (event) => {
    setAgentGenerateOccupationalDisease(event.target.value);
  };
  const [poorOutcome, setPoorOutcome] = useState("");
  const handleChangePoorOutcome = (event) => {
    setPoorOutcome(event.target.value);
  };

  const [dateMonitoring, setDateMonitoring] = useState("");
  const [workstation, setWorkstation] = useState("");
  const [idTypeAgent, setIdTypeAgent] = useState(0);
  const [nameTypeDisseasse, setNameTypeDisseasse] = useState("");

  const [idResponsible, setIdResponsible] = useState(" ");

  //mostrar las áreas desde configuración
  // // listar tipos de agentes
  const [agentsTypes, setAgentsTypes] = useState([]);
  const handleGetAgentsTypess = async () => {
    const response = await MyAccidentsOccupationalsIncidents.getAgentsTypes();

    // convertir agregando tipo de categoria de agente
    const res = await MyAccidentsOccupationalsIncidents.getAgentsTypesTable();
    const array = response.map((agent) => {
      if (res.some((t) => t.id_categories == agent.id_registers_accidents_occupationals_incidents_agents_categories)) {
        agent.agent_type = agent.agent_type + ' (' + res.filter((type) => type.id_categories == agent.id_registers_accidents_occupationals_incidents_agents_categories)[0].category + ')'
      }
      return agent
    });
    setAgentsTypes(array);
  };

  // agregar datos de enfermedades ocupacionales
  const [openModalOccupationalDiseases, setOpenModalOccupationalDiseases] =
    useState(false);
  const handleOpenModaOccupationalDisease = async () => {

    if (idArea == undefined) {
      handleOpenModalInformative();
      setMessageModalInformative("Seleccione un área para agregar datos")

    } else {
      // traer puesto de trabajo
      await handleGetPositionsByIdArea();

      setWorkstation("");
      setDateEjecution("");
      setIdTypeAgent("");
      setDateMonitoring("");
      setPoorOutcome("");
      setAgentGenerateOccupationalDisease("");
      setCorrectiveActions("");
      setResponsibleInput("");
      setNameTypeDisseasse('');
      setOpenModalOccupationalDiseases(true);
    }

  };

  // dialog para buscar el responsable de enfermedades ocupacionales
  const [
    openModalOccupationalDiseasesResponsible,
    setOpenModalOccupationalDiseasesResponsible,
  ] = useState(false);
  const handleOpenModalResponsibleOccupationalDiseases = () => {
    setResponsibleInput("");
    setOpenModalOccupationalDiseasesResponsible(true);
  };

  // // mostrar agentes
  const handleGetMonitoringAgents = async () => {
    handleOpenLoading();
    const positions = await MyOccupationalPositions.getPositions();

    const response = await MyMypeTracking.getMonitoringAgents({
      idRegistersMype: idRegistersMypeTracking,
    });
    if (response.success === false) {
    }else {
      const array = response.map((a)=>{
        if(positions.some((p)=> p.id_positions == a.workstation)){
          a.workstation = positions.filter((p)=> p.id_positions == a.workstation)[0].name
        }
        return a;
      });
      setMonitoringAgents(array);
    }
    setOpenLoading(false);
  };

  // crear agentes
  const handleCreateAgentesMonitoring = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyMypeTracking.createMonitoringAgents({
      idRegistersMype: idRegistersMypeTracking,
      workstation: workstation,
      id_type_agent: idTypeAgent,
      date_monitoring: dateMonitoring,
      poor_outcome: poorOutcome,
      agent_generate_occupational_disease: agentGenerateOccupationalDisease,
      corrective_actions: correctiveActions,
      id_users: idResponsible,
      date_ejecution: dateEjecution,
      end_date: endDateMeasure,
      file: file,
      name_type_severity: nameTypeDisseasse
    });

    // agregar a tareas
    const data = {
      date_start: dateEjecution,
      date_end: endDateMeasure,
      description: correctiveActions,
      id_status: 1,
      id_users: idResponsible,
      id_tasks_types: 2,
      title: 'Tarea asignada en registro de seguimiento para mypes',
      origin_url: `documentation/registers/generals/accidents/edit?idRegistersAccidents=${idRegistersMypeTracking}`
    }

    await MyTasksNew.postTasks(data);

    setOpenModalOccupationalDiseases(false);
    handleGetMonitoringAgents();
    handleOpenAlert("Creado de forma correcta", "success");

    return response;
  };

  // // eliminar
  const [idMonitoringAgents, setIdMonitoringAgents] = useState();

  const handleDeleteMonitoringAgents = async () => {
    handleOpenLoading();
    const response = await MyMypeTracking.deleteMonitoringAgents({
      idRegistersMype: idMonitoringAgents,
    });

    handleGetMonitoringAgents();
    handleOpenAlert("Eliminado de forma correcta", "success");

    return response;
  };

  //*************************  EQUIPO  *******************************************
  const [openModalEquipment, setOpenModalEquipment] = useState(false);

  const handleOpenModalEquipment = () => {
    setBodyPartProtected("");
    setEquipmentDetail("");
    setDateDelivery("");
    setDateRevision("");
    setDateRenewalApplicable("");
    setTranningImportance("");
    setNumberWorkersTranning("");
    setOpenModalEquipment(true);
  };
  const [equipment, setEquipment] = useState([]);
  const handleGetEquipment = async () => {
    const response = await MyMypeTracking.getEquipment({
      idRegistersMype: idRegistersMypeTracking,
    });
    setEquipment(response);
  };
  const [bodyPartProtected, setBodyPartProtected] = useState("");
  const [equipmentDetail, setEquipmentDetail] = useState("");
  const [dateDelivery, setDateDelivery] = useState("");
  const [dateRevision, setDateRevision] = useState("");
  const [dateRenewalApplicable, setDateRenewalApplicable] = useState("");
  const [tranningImportance, setTranningImportance] = useState("");
  const [numberWorkersTranning, setNumberWorkersTranning] = useState("");
  const [fileEquipment, setFileEquipment] = useState("");

  const handleChangeTranningImportance = (event) => {
    setTranningImportance(event.target.value);
  };

  const handleCreatetEquipment = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyMypeTracking.createEquipment({
      idRegistersMype: idRegistersMypeTracking,
      body_part_protected: bodyPartProtected,
      equipment_detail: equipmentDetail,
      date_delivery: dateDelivery,
      date_revision: dateRevision,
      date_renewal_applicable: dateRenewalApplicable,
      tranning_importance: tranningImportance,
      number_workers_tranning: numberWorkersTranning,
      file: fileEquipment,
    });
    handleGetEquipment();
    setOpenModalEquipment(false);
    handleOpenAlert("La operación se realizó con éxito", "success");
    return response;
  };

  // // eliminar
  const [idEquipment, setIdEquipment] = useState();

  const handleDeleteEquipment = async () => {
    handleOpenLoading();
    const response = await MyMypeTracking.deleteEquipment({
      idRegistersMype: idEquipment,
    });
    handleGetEquipment();
    handleOpenAlert("Eliminado de forma correcta", "success");
    return response;
  };

  //*************************  ASISTENTE  *******************************************

  const [areaAsistant, setAreaAsistant] = useState(0);
  const [observation, setObservation] = useState("");

  //mostrar las áreas desde configuración
  const [rowsAreaAsistant, setRowsAreaAsistant] = useState([]);
  const handleGetAreasAsistant = async () => {
    const response = await MyOccupationalPositions.getPositions();
    setRowsAreaAsistant(response);
    return response;
  };

  const [openModalAsistant, setOpenModalAsistant] = useState(false);
  const handleOpenModalAsistent = async() => {
    handleGetAssistantsSearch();

    setAreaAsistant(null);
    setResponsibleInput("");
    setObservation("");
    setOpenModalAsistant(true);
  };
  // dialog para buscar el responsable de trabajador involucrado
  const [
    openModalInvolvedWorkersResponsible,
    setOpenModalInvolvedWorkersResponsible,
  ] = useState(false);
  const handleOpenModalResponsibleInvolvedWorkers = () => {
    setResponsibleInput("");
    setOpenModalInvolvedWorkersResponsible(true);
  };

  const [asistents, setAsistents] = useState([]);
  const handleGetAsistents = async () => {
    const response = await MyMypeTracking.getWorkersInvolved({
      idRegistersMype: idRegistersMypeTracking,
    });
    setAsistents(response);
  };
  // crear trabajador involucrado
  const handleCreateWorkersInvolved = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyMypeTracking.createWorkersInvolved({
      idRegistersMype: idRegistersMypeTracking,
      area: areaAsistant,
      observation: observation,
      id_users: idResponsible,
    });

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      setOpenModalAsistant(false);
      handleGetAsistents();
      handleOpenAlert("Creado de forma correcta", "success");
    }
    return response;
  };

  // firmar trabajador involucrado
  const handleSignWorkerInvolved = async (idRegister, idUser) => {
    handleOpenLoading();
    try{
      const response = await MyMypeTracking.updateSignWorkerInvolved({
        idResponsible: idRegister,
        id_users: idUser,
      });

      await handleGetAsistents();
      handleOpenAlert("Trabajador firmó con éxito", "success");
    }catch(e){
      handleOpenAlert("Ocurrió un error al firmar", "error");
    }
    
  };

  // // eliminar trabajadores involucrado
  const [idWorkerInvolved, setIdWorkerInvolved] = useState();

  const handleDeleteWorkerInvolved = async () => {
    handleOpenLoading();
    const response = await MyMypeTracking.deleteWorkerInvolved({
      idWorkerInvolved: idWorkerInvolved,
    });
    if (response.success === true) {
      handleGetAsistents();
      handleOpenAlert("Eliminado de forma correcta", "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };
  //*************************  RESPONSABLES  *******************************************
  const [openModalResponsibleWorker, setOpenModalResponsibleWorker] =
    useState(false);

  const handleOpenModalResponsibleWorker = async() => {
    handleGetResponsibleSearch();

    setOpenModalResponsibleWorker(true);
  };

  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response = await MyMypeTracking.getResponsibleWorkers({
      idRegistersMype: idRegistersMypeTracking,
    });
    if (response.success === false) {
    } else {
      setResponsibleActions(response);
    }
  };

  //crear trabajadores responsables
  const handleStoreResponsibleWorkers = async (idUsers) => {
    handleOpenLoading();
    const response = await MyMypeTracking.createResponsibleWorkers({
      idRegistersMype: idRegistersMypeTracking,
      id_users: idUsers,
    });

    if (response.success === false) {
    } else {
      handleGetResponsibleWorkers();
      setOpenModalResponsibleWorker(false);
      handleOpenAlert("Se agregó de forma correcta los datos", "success");
    }
    return response;
  };

  // eliminar trabajador responsable
  const [idResponsibleWorker, setIdResponsibleWorker] = useState(0);
  const handleDeleteResponsibleWorker = async () => {
    handleOpenLoading();
    try{
      const response = await MyMypeTracking.deleteResponsibleWorker({
        idResponsible: idResponsibleWorker,
      });

      await handleGetResponsibleWorkers();
      handleOpenAlert("Se eliminó responsable con éxito", "success")
    }catch(e){
      handleOpenAlert("Ocurrió un error al firmar", "error");
    }
  };

  // firmar responsable
  const handleSignResponsibleWorker = async (idRegister, idUser) => {
    handleOpenLoading();
    try{
      const response = await MyMypeTracking.updateResponsibleWorkers({
        idResponsible: idRegister,
        id_users: idUser,
      });
  
      await handleGetResponsibleWorkers();
      handleOpenAlert("Responsable firmó con éxito", "success");
    }catch(e){
      handleOpenAlert("Ocurrió un error al firmar", "error");
    }
  };

  // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(1);
  const handleGetRegisterStatus = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    if (
      responsibleWorkers.length >= 1 &&
      monitoringAgents.length >= 1 &&
      equipment.length >= 1 &&
      asistents.length >= 1
    ) {
      const finded = responsibleWorkers.find((item) => item.firm === 0);
      const findedWorkers = monitoringAgents.find((item) => item.firm === 0);

      if (finded === undefined && findedWorkers === undefined) {
        handleOpenLoading();
        const response = await MyMypeTracking.updateRegistersCloseStatus({
          idRegistersMype: idRegistersMypeTracking,
          code: code,
          area: nameArea,
          number_workers_area: numberWorkersArea,
          id_type_severity_work_accident: TypeTranning,
          theme: theme,
          date: date,
          number_hours: numberHour,
          name_coach: nameTrainer,
          register_status: 2,
        });
        handleOpenAlert("El registro se cerró de forma correcta", "success");
        handleEditRegisters();
        return response;
      } else {
        handleOpenAlert("Firmas pendientes", "error");
      }
    } else {
      handleOpenAlert(
        "Complete las secciones obligatorias(Monitoreo de agentes, agregar equipos, agregar asistente y trabajador responsable)",
        "error"
      );
    }
  };

  // tipos de agentes
  // listar tipos de agentes tabla completa
  const [openModalTypeAgentsTable, setOpenModalTypeAgentsTable] =
    useState(false);
  const handleOpenModalTypeAgentsTable = () => {
    setOpenModalTypeAgentsTable(true);
  };
  const [agentsTable, setAgentsTable] = useState([]);
  const handleGetAgentsTypesTable = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getAgentsTypesTable();
    setAgentsTable(response);
    return response;
  };

  //modal de información
  const [openModalInformative, setOpenModalInformative] = useState(false);
  const [messageModalInformative, setMessageModalInformative] = useState("");

  const handleOpenModalInformative = () => {
    setOpenModalInformative(true)
  }
  const handleCloseModalInformative = () => {
    setOpenModalInformative(false)
  }
  //modal inducción
  const [openModalTraining, setOpenModalTraining] = useState(false);
  const [typeModalTraining,setTypeModalTraining] = useState(null);
  const [buttonActionModalTraining, setButtonActionModalTraining] = useState(null);
  const [idTraining, setIdTraining] = useState(null);

  const handleOpenModalTraining = (typeModal, buttonActionModalTraining,data) => {
    setOpenModalTraining(true);
    setTypeModalTraining(typeModal);
    setButtonActionModalTraining(buttonActionModalTraining)

    if(typeModal == "update"){
      setIdTraining(data.id);
      setIdThemeSelected(data.id_theme);
      setdateTraining(data.date);
      setNumberHoursTraining(data.number_hours);
      setNameCoach(data.name_trainer);
    }else{
      setIdTraining("");
      setIdThemeSelected("");
      setdateTraining("");
      setNumberHoursTraining("");
      setNameCoach("");
    }
  }
  const handleCloseModalTraining = () => {
    setOpenModalTraining(false);
  }

  const [rowsInduction, setRowsInduction] = useState([]);
  const [rowsInstruction, setRowsInstruction]= useState([]);
  const [rowsTraining, setRowsTraining]= useState([]);
  const [rowsSimulacrum, setRowsSimulacrum] = useState([]);

  const handleActionModalTraining = (e) => {
    e.preventDefault();

    switch(buttonActionModalTraining){
      case "postInduction":
        handlePostInduction();
        break;
      case "updateInduction":
        handleUpdateInduction();
        break;
      case "postInstruction":
        handlePostInstruction();
        break;
      case "updateInstruction":
        handleUpdateInstruction();
        break;
      case "postTraining":
        handlePostTraining();
        break;
      case "updateTraining":
        handleUpdateTraining();
        break;
      case "postSimulacrum":
        handlePostSimulacrum();
        break;
      case "updateSimulacrum":
        handleUpdateSimulacrum();
        break;
    }
  }


  const handleGetInduction = async() => {
    try{
      const resp = await MyMypeTracking.getInduction({idRegistersMype: idRegistersMypeTracking}) ?? [];
    setRowsInduction(resp);
    }catch(e){
      handleOpenAlert("Problemas al traer el listado del programa de inducción");
    }
  }
  const handleDeleteInduction = async() => {
    handleOpenLoading();
    try{
      const resp = await MyMypeTracking.deleteInduction({idInductionRegisterMype: idTraining});
      await handleGetInduction();
      handleOpenAlert("Se eliminó el registro con éxito", "success");
    }catch(e){
      handleOpenAlert("Ocurrió un error al eliminar", "error");
    }
    handleCloseModalConfirm();
  }
  const handlePostInduction = async() => {
    handleOpenLoading();
    try{
      const resp = await MyMypeTracking.postInduction({
        idRegistersMype: idRegistersMypeTracking,
        id_theme: idThemeSelected,
        date: dateTraining,
        number_hours: numberHoursTraining,
        name_trainer: nameCoach
      });

      await handleGetInduction();
      handleOpenAlert( "Se registró con éxito el tema de inducción", "success");

    }catch(e){
      handleOpenAlert("Ocurrió un error al registrar", "error");
    }

    handleCloseModalTraining();
  }
  const handleUpdateInduction = async () => {
    handleOpenLoading()
    try{
      const resp = await MyMypeTracking.updateInduction({
        idInductionRegisterMype: idTraining,
        id_theme: idThemeSelected,
        date: dateTraining,
        number_hours: numberHoursTraining,
        name_trainer : nameCoach
      });

      await handleGetInduction();
      handleOpenAlert("Se actualizó eñ registro con éxito", "success");
    }catch(e){
      handleOpenAlert("Ocurrió un error al actualizar", "error");
    }

    handleCloseModalTraining();
  }

  const handleGetInstruction = async() =>{
    try{
      const resp = await MyMypeTracking.getInstruction({idRegistersMype: idRegistersMypeTracking }) ?? [];
      setRowsInstruction(resp);
    }catch(e){
      handleOpenAlert("Problemas al traer listado de capacitaciones", "success");
    }
  }
  const handlePostInstruction = async() => {
    handleOpenLoading();
    try{
      const resp = await MyMypeTracking.postInstruction({
        idRegistersMype: idRegistersMypeTracking,
        id_theme: idThemeSelected,
        date: dateTraining,
        number_hours: numberHoursTraining ,
        name_trainer: nameCoach
      });

      await handleGetInstruction();
      handleOpenAlert("Se registró la capacitación con éxito", "success");
    }catch(e){
      handleOpenAlert("Ocurrió un error al registrar", "error")
    }
    handleCloseModalTraining();

  }
  const handleUpdateInstruction = async() => {
    handleOpenLoading();
    try{
      const resp = await MyMypeTracking.updateInstruction({
        idInstructionRegisterMype: idTraining,
        id_theme: idThemeSelected,
        date: dateTraining,
        number_hours: numberHoursTraining,
        name_trainer : nameCoach,
      });

      await handleGetInstruction();
      handleOpenAlert("Se actualizó la capacitación con éxito", "success");
    }catch(e){
      handleOpenAlert("Ocurrió un error al actualizar", "error");

    }
    handleCloseModalTraining();
  }
  const handleDeleteInstruction = async() => {
    handleOpenLoading();
    try{
      const resp = await MyMypeTracking.deleteInstruction({idInstructionRegisterMype: idTraining});
      await handleGetInstruction();
      handleOpenAlert("Se eliminó la capacitación con éxito");
    }catch(e){
      handleOpenAlert("Ocurrió un error al eliminar", "error");
    }

    handleCloseModalTraining();
  }

  const handleGetTraining = async() => {
    try{
      const resp = await MyMypeTracking.getTraining({idRegistersMype: idRegistersMypeTracking}) ?? [];
      setRowsTraining(resp);
    }catch(e){
      handleOpenAlert("Problemas al traer listado de entrenamiento", "error");
    }
  }
  const handlePostTraining = async() => {
    handleOpenLoading();
    try{
      const resp = await MyMypeTracking.postTraining({
        idRegistersMype: idRegistersMypeTracking,
        id_theme: idThemeSelected,
        date: dateTraining,
        number_hours: numberHoursTraining ,
        name_trainer: nameCoach
      });
      await handleGetTraining();
      handleOpenAlert("El entrenamiento se registró con éxito", "success");
    }catch(e){
      handleOpenAlert("Ocurrió un error al registrar el entrenamiento", "error");
    }

    handleCloseModalTraining();
  }
  const handleUpdateTraining = async() => {
    handleOpenLoading();
    try{
      const resp = await MyMypeTracking.updateTraining({
        idTrainingRegisterMype: idTraining,
        id_theme: idThemeSelected,
        date: dateTraining,
        number_hours: numberHoursTraining,
        name_trainer : nameCoach,
      });

      await handleGetTraining();
      handleOpenAlert("Se actualizó con éxito el entrenamiento", "success");

    }catch(e){
      handleOpenAlert("Ocurrió un error al actualizar", "error");
    }

    handleCloseModalTraining();
  }
  const handleDeleteTraining = async() => {
    handleOpenLoading();
    try{
      const resp = await MyMypeTracking.deleteTraining({idTrainingRegisterMype: idTraining});
      await handleGetTraining();
      handleOpenAlert("Se eliminó con éxito el entramiento", "success");
    }catch(e){
      handleOpenAlert("Ocurrió un error al eliminar el entrenamiento", "error");
    }

    handleCloseModalConfirm();
  }

  const handleGetSimulacrum = async() => {
    try{
      const resp = await MyMypeTracking.getSimulacrum({idRegistersMype : idRegistersMypeTracking}) ?? [];
      setRowsSimulacrum(resp);
    }catch(e){
      handleOpenAlert("Problemas al traer listado de simulacros de emergencias", "error");
    }
  }
  const handlePostSimulacrum = async() => {
    handleOpenLoading();
    try{
      const resp = await MyMypeTracking.postSimulacrum({
        idRegistersMype: idRegistersMypeTracking,
        id_theme: idThemeSelected,
        date: dateTraining,
        number_hours: numberHoursTraining ,
        name_trainer: nameCoach
      });
      await handleGetSimulacrum();
      handleOpenAlert("Se registró con éxito el simulacro de emergencia", "success");

    }catch(e){
      handleOpenAlert("Ocurrió un error al registrar", "error");
    }

    handleCloseModalTraining();
  }
  const handleUpdateSimulacrum = async()=> {
    handleOpenLoading();
    try{
      const resp = await MyMypeTracking.updateSimulacrum({
        idSimulacrumRegisterMype: idTraining,
        id_theme: idThemeSelected,
        date: dateTraining,
        number_hours: numberHoursTraining,
        name_trainer : nameCoach,
      });
      await handleGetSimulacrum();
      handleOpenAlert("Se actualizó con éxito el registro", "success");
    }catch(e){
      handleOpenAlert("Ocurrió un error al actualizar", "error");
    }

    handleCloseModalTraining();
  }
  const handleDeleteSimulacrum = async() => {
    handleOpenLoading();
    try{
      const resp = await MyMypeTracking.deleteSimulacrum({idSimulacrumRegisterMype : idTraining});
      await handleGetSimulacrum();
      handleOpenAlert("Se eliminó con éxito el simulacro de emergencia", "success");
    }catch(e){
      handleOpenAlert("Ocurrió un error al eliminar", "error");
    }

    handleCloseModalConfirm();
  }

  const [rowsThemes, setRowsThemes] = useState([]);
  const [idThemeSelected, setIdThemeSelected] = useState("");
  const [dateTraining, setdateTraining] = useState("");
  const [numberHoursTraining, setNumberHoursTraining]= useState("");
  const [nameCoach, setNameCoach]= useState("");

  const handleGetThemes = async() => {
    const resp = await MyTrainings.getSubjects();
    setRowsThemes(resp);

  }
  //modal de agregar capacitador
  const [openModalCoach, setOpenModalCoach] = useState(false);
  const handleOpenModalCoach = () => {
    setOpenModalCoach(true);
  }
  const handleCloseModalCoach = () => {
    setOpenModalCoach(false);
  }

  const handleSelectTrainer = async(idUser) => {
    handleOpenLoading();
    if(idUser !== null){
      const resp= await MyUsers.editUsers({ idUsers: idUser });

      if(resp.success){
        setNameCoach(`${resp.first_name} ${resp.last_name}`)
      }
    }
    setOpenLoading(false);
    handleCloseModalCoach();
  }

  const onSearchValueChangeName = (event) => {
    setNameCoach(event.target.value);
  }



  useEffect(() => {

    handleEditRegisters();
    handleGetMonitoringAgents();
    handleGetAreas();
    handleGetAgentsTypesTable();

    // equipos
    handleGetEquipment();

    // asistentes
    handleGetAreasAsistant();
    handleGetAsistents();
    // RESPONSABLES
    handleGetResponsibleWorkers();

    handleGetAgentsTypess();

    handleGetUsersActives();

    handleGetPositions();
    handleGetThemes();
    handleGetInduction();
    handleGetInstruction();
    handleGetTraining();
    handleGetSimulacrum();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText text={"Registro de seguimiento"}></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleGetRegisterStatus}>
            <Grid container spacing={2} sx={{ marginTop: "4px" }}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  roles={permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_EDIT}
                  text={"Nro registro"}
                  inputType={"text"}
                  inputValue={setCode}
                  value={code}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <SelectControla
                  roles={permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_EDIT}
                  text="Área:"
                  inputValue={setIdArea}
                  required
                  value={idArea}
                  childrenRows={rowsArea.map((rows) => (
                    <MenuItem key={rows.id_areas} value={rows.id_areas}>
                      {rows.area}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  roles={permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_EDIT}
                  text={"N° de trabajadores en el área:"}
                  inputType={"number"}
                  inputValue={setNumberWorkersArea}
                  value={numberWorkersArea}
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Monitoreo de agentes
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                {registerStatus === true && (
                  <ButtonControla
                    roles={permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_AGREG_DATOS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={handleOpenModaOccupationalDisease}
                  />
                )}
              </Grid>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre del puesto de trabajo
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tipo de agente a ser monitoreado
                        <Box>
                          <ButtonIconControla
                            icon={<InfoOutlined sx={{ color: "white" }} />}
                            backgroundColor={"#FFC300"}
                            backgroundColorHover={"#FACB32"}
                            textTooltip={
                              "VER TABLA REFERENCIAL 1: TIPOS DE AGENTES"
                            }
                            functionOnClick={handleOpenModalTypeAgentsTable}
                          />
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de monitoreo
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Resultados desfavorables
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        El agente generó enfermedad ocupacional{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Medidas correctivas a implementar{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Responsable{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de ejecución{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de fin
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Archivo adjunto
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {monitoringAgents.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.workstation}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {agentsTypes?.filter((a) => a.id_registers_accidents_occupationals_incidents_agents == row.id_type_agent)[0]?.agent_type.substring(0, 5) == "Otros" ? row.name_type_severity : agentsTypes?.filter((a) => a.id_registers_accidents_occupationals_incidents_agents == row.id_type_agent)[0]?.agent_type}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.date_monitoring}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.poor_outcome}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.agent_generate_occupational_disease}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.corrective_actions}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.date_ejecution}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.end_date == null ? "No definido" : row.end_date}
                        </TableCell>

                        {row.file !== "" ? (
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                              spacing={1}
                            >
                              <ButtonIconControla
                                icon={<FileOpen sx={{ color: "white" }} />}
                                backgroundColor={"#4446A2"}
                                backgroundColorHover={"#3436A2"}
                                textTooltip={"Ver documento"}
                                directionUrl={row.file}
                                directionTarget={"_blank"}
                              />
                            </Stack>
                          </TableCell>
                        ) : (
                          <TableCell component="th" scope="row"></TableCell>
                        )}

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {registerStatus === true && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Estás seguro de eliminar los datos del documento?",
                                    "Una vez eliminado no se podrá recuperar los datos del documento.",
                                    "Sí, eliminar",
                                    "deleteAgents"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Equipos de seguridad o emergencia
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                {registerStatus === true && (
                  <ButtonControla
                    roles={permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_AGREG_EQUIP}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar Equipo"}
                    functionOnClick={handleOpenModalEquipment}
                  />
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Parte del cuerpo a proteger
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Detalle del equipo
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de entrega
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de revisión
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de renovación
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Capacitación en el uso correcto/importancia
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nº de trabajadores capacitados
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {equipment.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.body_part_protected}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.equipment_detail}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.date_delivery}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {row.date_revision}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.date_renewal_applicable}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {row.tranning_importance}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.number_workers_tranning}
                        </TableCell>
                        {row.file !== "" ? (
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                              spacing={1}
                            >
                              <ButtonIconControla
                                icon={<FileOpen sx={{ color: "white" }} />}
                                backgroundColor={"#4446A2"}
                                backgroundColorHover={"#3436A2"}
                                textTooltip={"Ver documento"}
                                directionUrl={row.file}
                                directionTarget={"_blank"}
                              />
                            </Stack>
                          </TableCell>
                        ) : (
                          <TableCell component="th" scope="row"></TableCell>
                        )}

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {registerStatus === true && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Estás seguro de eliminar los datos del documento?",
                                    "Una vez eliminado no se podrá recuperar los datos del documento ",
                                    "Sí, eliminar",
                                    "deleteEquipment"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>


              {/* tabla de inducción */}
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Registro de inducción
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                {registerStatus === true && (
                  <ButtonControla
                    roles={permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_AGREG_INDUCC}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={() => handleOpenModalTraining("create","postInduction",null)}
                  />
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tema a tratar
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        N° de horas
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre del capacitador o entrenador
                      </TableCell>
                      <TableCell></TableCell>

                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rowsInduction.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rowsThemes.filter((t)=> t.id_trainings_subjects == row.id_theme)[0]?.subject}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.date}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.number_hours}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.name_trainer}
                        </TableCell>
                        {
                          registerStatus == true && (
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                                spacing={1}
                              >
                                <ButtonIconControla
                                  icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  functionOnClick={() => handleOpenModalTraining("update", "updateInduction", row)}
                                />
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => handleOpenModalConfirm(
                                    row.id,
                                    "¿Estás seguro de eliminar los datos del programa de inducción?",
                                    "Una vez eliminado no se podrá recuperar los datos del registro.",
                                    "Si, eliminar",
                                    "deleteInduction"
                                  )}
                                />
                              </Stack>
                            </TableCell>

                          ) }
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* tabla de capacitación */}
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Registro de capacitación
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                {registerStatus === true && (
                  <ButtonControla
                    roles ={ permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_AGREG_CAPAC }
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={()=>handleOpenModalTraining("create","postInstruction",null)}
                  />
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tema a tratar
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        N° de horas
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre del capacitador o entrenador
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rowsInstruction.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rowsThemes.filter((t) => t.id_trainings_subjects == row.id_theme)[0]?.subject}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.date}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.number_hours}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.name_trainer}
                        </TableCell>
                        {
                          registerStatus === true && (
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                                spacing={1}
                              >
                                <ButtonIconControla
                                  icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  functionOnClick={() => handleOpenModalTraining("update", "updateInstruction", row)}
                                />
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => handleOpenModalConfirm(
                                    row.id,
                                    "¿Estás seguro de eliminar los datos del programa de capacitación?",
                                    "Una vez eliminado no se podrá recuperar los datos del registro.",
                                    "Si, eliminar",
                                    "deleteInstruction"
                                  )}
                                />
                              </Stack>
                            </TableCell>
                          )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* tabla de entrenamiento */}
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Registro de entrenamiento
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                {registerStatus === true && (
                  <ButtonControla
                    roles ={ permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_AGREG_ENTREN}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={()=> handleOpenModalTraining("create","postTraining", null)}
                  />
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tema a tratar
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        N° de horas
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre del capacitador o entrenador
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rowsTraining.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rowsThemes.filter((t) => t.id_trainings_subjects == row.id_theme)[0]?.subject}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.date}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.number_hours}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.name_trainer}
                        </TableCell>
                        {
                          registerStatus === true && (
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                                spacing={1}
                              >
                                <ButtonIconControla
                                  icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  functionOnClick={() => handleOpenModalTraining("update", "updateTraining", row)}
                                />
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => handleOpenModalConfirm(
                                    row.id,
                                    "¿Estás seguro de eliminar los datos del programa de entrenamiento?",
                                    "Una vez eliminado no se podrá recuperar los datos del registro.",
                                    "Si, eliminar",
                                    "deleteTraining"
                                  )}
                                />
                              </Stack>
                            </TableCell>

                          )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* tabla de simulacro de emergencia */}
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Registro de simulacro de emergencia
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                {registerStatus === true && (
                  <ButtonControla
                    roles={permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_AGREG_SIMUL}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={()=>handleOpenModalTraining("create","postSimulacrum",null)}
                  />
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tema a tratar
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        N° de horas
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre del capacitador o entrenador
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rowsSimulacrum.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rowsThemes.filter((t) => t.id_trainings_subjects == row.id_theme)[0]?.subject}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.date}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.number_hours}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.name_trainer}
                        </TableCell>
                        {
                          registerStatus === true && (
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                                spacing={1}
                              >
                                <ButtonIconControla
                                  icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  functionOnClick={() => handleOpenModalTraining("update", "updateSimulacrum", row)}
                                />
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => handleOpenModalConfirm(
                                    row.id,
                                    "¿Estás seguro de eliminar los datos del programa de simulacro de emergencia?",
                                    "Una vez eliminado no se podrá recuperar los datos del registro.",
                                    "Si, eliminar",
                                    "deleteSimulacrum"
                                  )}
                                />
                              </Stack>
                            </TableCell>
                          ) }
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>


              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Datos de los asistentes
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                {registerStatus === true && (
                  <ButtonControla
                    roles ={ permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_AGREG_ASIST}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar Asistente"}
                    functionOnClick={handleOpenModalAsistent}
                  />
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Documento de Identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Cargo
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Firma
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Fecha de Firma
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Observación
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {asistents.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {rowsPositions.filter((p) => p.id_positions == row.area)[0]?.name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <ButtonSignatureControla
                              idUser={row.id_users}
                              firmStatus={row.firm }
                              firmUrl={row.firm_url}
                              functionOnClick={async() => {
                                await handleSignWorkerInvolved(row.id,row.id_users)
                              }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.firm_date === null || row.firm_date === undefined
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.observation}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {registerStatus === true && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Estás seguro de eliminar los datos del trabajador?",
                                    "Una vez eliminado no se podrá recuperar los datos del trabajador " +
                                    row.firm_date +
                                    " " +
                                    row.last_name,
                                    "Sí, eliminar",
                                    "deleteWorkers"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true && (
                  <ButtonControla
                    roles={permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_AGREG_RESPON}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={handleOpenModalResponsibleWorker}
                  />
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Cargo
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.position_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.firm_date === null
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <ButtonSignatureControla
                              idUser={row.id_users}
                              firmStatus={row.firm }
                              firmUrl={row.firm_url}
                              functionOnClick={async() => {
                                await handleSignResponsibleWorker(row.id, row.id_users)
                              }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus === true && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "Estás seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podrá recuperar los datos del trabajador responsable " +
                                    row.first_name +
                                    " " +
                                    row.last_name,
                                    "Sí, eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={"/documentation/registers/mypes/tracking"}

                />
              </Grid>
              {registerStatus === true && (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_UPDATE}
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    functionOnClick={handleUpdateRegisters}
                  />
                </Grid>
              )}
              {registerStatus === true && (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles = {permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_CLOSE}
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    typeButton="submit"
                  />
                </Grid>
              )}
              {registerStatus === false && (
                <Grid item xs={12} md={3}>
                  <ButtonControla
                    iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                    backgroundColor={"#F2994A"}
                    backgroundColorHover={"#FF881E"}
                    textButton={"Ver documento PDF"}
                    typeButton={"button"}
                    url={
                      process.env.REACT_APP_API_DJANGO + "report/" +
                      idRegistersMypeTracking
                    }
                    target="_blank"
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog agregar datos referentes a las enfermedades ocupacionales */}
      <Dialog
        open={openModalOccupationalDiseases}
        onClose={() => setOpenModalOccupationalDiseases(false)}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar datos referentes a las enfermedades ocupacionales
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateAgentesMonitoring}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <SelectControla
                  text={"Puesto de trabajo: "}
                  inputValue={setWorkstation}
                  modalType={true}
                  value={workstation}
                  childrenRows={rowsPositionsByArea.map((row) => (
                    <MenuItem
                      key={row.id_positions} value={row.id_positions}
                    >
                      {row.name}
                    </MenuItem>
                  ))}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <SelectControla
                  text="Tipo de agente:"
                  inputValue={setIdTypeAgent}
                  modalType={true}
                  required
                  value={idTypeAgent}
                  childrenRows={agentsTypes.map((row) => (
                    <MenuItem
                      key={
                        row.id_registers_accidents_occupationals_incidents_agents
                      }
                      value={
                        row.id_registers_accidents_occupationals_incidents_agents
                      }
                    >
                      {row.agent_type}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: idTypeAgent === 9 || idTypeAgent === 17 || idTypeAgent === 25 || idTypeAgent === 30 || idTypeAgent === 36 ? "" : "none",
                }}
              >
                <InputControlaVertical
                  text={"Detallar tipo de agente: "}
                  inputType={"text"}
                  inputValue={setNameTypeDisseasse}
                  value={nameTypeDisseasse}
                  modalType={true}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de monitoreo: "}
                  inputType={"date"}
                  inputValue={setDateMonitoring}
                  value={dateMonitoring}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  ¿Resultado Desfavorable?
                </InputLabel>
                <Select
                  required
                  sx={{
                    fontFamily: "Roboto",
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                    height: "42px",
                    color: "#305AD9",
                  }}
                  value={poorOutcome}
                  onChange={handleChangePoorOutcome}
                >
                  <MenuItem value={"Sí"}>Sí</MenuItem>
                  <MenuItem value={"No"}>No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={7} md={6} lg={6} xl={7}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  ¿El agente generó enfermedad ocupacional?
                </InputLabel>
                <Select
                  required
                  sx={{
                    fontFamily: "Roboto",
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                    height: "42px",
                    color: "#305AD9",
                  }}
                  value={agentGenerateOccupationalDisease}
                  onChange={handleChangeAgentGenerateOccupationalDisease}
                >
                  <MenuItem value={"Sí"}>Sí</MenuItem>
                  <MenuItem value={"No"}>No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={5}>
                <InputControlaVertical
                  text={"Fecha de ejecución: "}
                  inputType={"date"}
                  inputValue={setDateEjecution}
                  value={dateEjecution}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={5}>
                <InputControlaVertical
                  text={"Fecha de fin: "}
                  inputType={"date"}
                  inputValue={setEndDateMeasure}
                  value={endDateMeasure}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Medidas Correctivas a implementar: "}
                  inputType={"textArea"}
                  inputValue={setCorrectiveActions}
                  value={correctiveActions}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={
                    "Adjuntar informe de resultado de monitoreo de agentes(de ser el caso): "
                  }
                  inputType={"file"}
                  inputValue={setFile}
                  value={file}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable de la implementación: "
                  inputValue={nameResponsibleInput}
                  functionClick={handleOpenModalResponsibleOccupationalDiseases}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalOccupationalDiseases(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables de enfermedad ocupacional */}
      <Dialog
        open={openModalOccupationalDiseasesResponsible}
        onClose={() => {
          setOpenModalOccupationalDiseasesResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsUsersActives}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalOccupationalDiseasesResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog agregar de equipos */}
      <Dialog
        open={openModalEquipment}
        onClose={() => setOpenModalEquipment(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Equipo
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreatetEquipment}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={12}>
                <InputControlaVertical
                  text={"Parte del cuerpo a proteger: "}
                  inputType={"text"}
                  inputValue={setBodyPartProtected}
                  value={bodyPartProtected}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <ButtonIconControla
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#FFC300"}
                  backgroundColorHover={"#FACB32"}
                  textTooltip={
                    "Usted puede ingresar las siguientes partes del cuerpo(por ejemplo): Cabeza, ojos, oídos, vías respiratorias, manos, pies, otros(detallar)"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Detalle del equipo: "}
                  inputType={"textArea"}
                  inputValue={setEquipmentDetail}
                  value={equipmentDetail}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de entrega: "}
                  inputType={"date"}
                  inputValue={setDateDelivery}
                  value={dateDelivery}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de revisión: "}
                  inputType={"date"}
                  inputValue={setDateRevision}
                  value={dateRevision}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Fecha de renovación de ser el caso: "}
                  inputType={"date"}
                  inputValue={setDateRenewalApplicable}
                  value={dateRenewalApplicable}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Capacitacion en el uso correcto / importancia
                </InputLabel>
                <Select
                  required
                  sx={{
                    fontFamily: "Roboto",
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                    height: "42px",
                    color: "#305AD9",
                  }}
                  value={tranningImportance}
                  onChange={handleChangeTranningImportance}
                >
                  <MenuItem value={"Sí"}>Sí</MenuItem>
                  <MenuItem value={"No"}>No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nº de trabajadores capacitados: "}
                  inputType={"text"}
                  inputValue={setNumberWorkersTranning}
                  value={numberWorkersTranning}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Adjuntar archivo: "}
                  inputType={"file"}
                  inputValue={setFileEquipment}
                  value={fileEquipment}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <ButtonIconControla
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#FFC300"}
                  backgroundColorHover={"#FACB32"}
                  textTooltip={
                    "Adjuntar al registro información con el nombre completo de los trabajadores que recibieron capacitación y el equipo de protección o emergencia"
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalEquipment(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog agregar asistentes */}
      <Dialog
        open={openModalAsistant}
        onClose={() => setOpenModalAsistant(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar trabajador involucrado
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateWorkersInvolved}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Cargo:"
                  inputValue={setAreaAsistant}
                  modalType={true}
                  required
                  value={areaAsistant}
                  childrenRows={rowsAreaAsistant.map((rows) => (
                    <MenuItem key={rows.id_positions} value={rows.id_positions}>
                      {rows.name}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Observación: "}
                  inputType={"textArea"}
                  inputValue={setObservation}
                  value={observation}
                  modalType={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Trabajador involucrado: "
                  inputValue={nameResponsibleInput}
                  functionClick={handleOpenModalResponsibleInvolvedWorkers}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalAsistant(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables de asistentes */}
      <Dialog
        open={openModalInvolvedWorkersResponsible}
        onClose={() => {
          setOpenModalInvolvedWorkersResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsAssistantsSearch}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalInvolvedWorkersResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalResponsibleWorker}
        onClose={() => setOpenModalResponsibleWorker(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsResponsiblesSearchs}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => setOpenModalResponsibleWorker(false)}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* ver typos de agentes y enfermedades tabla completa */}
      <Dialog
        open={openModalTypeAgentsTable}
        onClose={() => {
          setOpenModalTypeAgentsTable(false);
        }}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Tipos de Agentes
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid container sx={{ marginTop: "4px" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    {agentsTable.map((column) => (
                      <TableCell
                        key={column.id_categories}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.category}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {agentsTable.map((row) => {
                    return (
                      <TableCell key={row.id_categories}>
                        {row.agents.map((column) => {
                          return (
                            <TableRow
                              key={
                                column.id_registers_occupational_diseases_agents_types
                              }
                            >
                              {/* <TableCell > */}
                              {column.agent_type}
                              {/* </TableCell> */}
                            </TableRow>
                          );
                        })}
                      </TableCell>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalTypeAgentsTable(false);
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

      {/* dialogo de información */}
      <DialogInformativeControla
        open={openModalInformative}
        onClose={handleCloseModalInformative}
        message={messageModalInformative}
      />

      {/* dialogo de agregar inducción */}
      <Dialog
        open={openModalTraining}
        onClose={handleCloseModalTraining}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText={typeModalTraining == "create" ? "Agregar datos del programa": "Actualizar datos del programa"}
          functionOnClose={handleCloseModalTraining}
        />
        <form onSubmit={handleActionModalTraining}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SelectControla
                text="Tema:"
                inputValue={setIdThemeSelected}
                modalType={true}
                value={idThemeSelected}
                childrenRows={rowsThemes.map((row) => (
                  <MenuItem key={row.id_trainings_subjects} value={row.id_trainings_subjects}>
                    {row.subject}
                  </MenuItem>
                ))}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputControlaVertical
                text={"Fecha: "}
                inputType={"date"}
                inputValue={setdateTraining}
                value={dateTraining}
                modalType={true}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputControlaVertical
                text={"N° de horas: "}
                inputType={"number"}
                inputValue={setNumberHoursTraining}
                value={numberHoursTraining}
                modalType={true}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SearchButtonControla
                titleLabel="Nombre del capacitador: "
                inputValue={nameCoach}
                functionClick={handleOpenModalCoach}
                modalType={true}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <Box>
              <ButtonControla
                backgroundColor={"#CBCBFF"}
                textButton={"Cancelar"}
                backgroundColorHover={"#CBCBFF"}
                typeButton={"button"}
                functionOnClick={handleCloseModalTraining}
              />
            </Box>
            <Box>
              <ButtonControla
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={typeModalTraining == "create" ? "Agregar" : "Actualizar"}
                typeButton={"submit"}
              />
            </Box>
          </Stack>
        </DialogActions>
        </form>
      </Dialog>

      {/* dialogo agregar capacitador  */}
      <Dialog
        open={openModalCoach}
        onClose={handleCloseModalCoach}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Agregar Capacitador"
          functionOnClose={handleCloseModalCoach}
        />
        <DialogContent>
          <SearchUsersAndOtherUserControla
            rowsUser={rowsUsersActives}
            onSearchValueChangeName={onSearchValueChangeName}
            handleUsersActionButton={(idUser)=>handleSelectTrainer(idUser)}
          />
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <Box>
              <ButtonControla
                backgroundColor={"#CBCBFF"}
                textButton={"Cancelar"}
                backgroundColorHover={"#CBCBFF"}
                typeButton={"button"}
                functionOnClick={handleCloseModalCoach}
              />
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

export default Edit;
