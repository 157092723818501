import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import { Archive, BorderColorOutlined, Delete, Publish } from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useAppContext } from "../../../context/AppContext";

const ListActives = (props) => {
  const { permissions } = useAppContext();

  const {
    rowsSso,
    colorCell,
    handleOpenModalUploadFileEdit,
    handleOpenModalConfirm } = props

  return (
    <TableBody>
      {rowsSso.map((row) =>
        (row.register_status != 5 && row.document_url != "") ||
          (row.register_status == 4 && row.document_url == "") ? (
          <TableRow
            hover
            key={row.id}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell sx={{ color: colorCell }}>
              {row.title}
            </TableCell>
            <TableCell sx={{ color: colorCell }}>
              {row.register_status === 4
                ? "Publicado"
                : "Creado"}
            </TableCell>
            <TableCell sx={{ color: colorCell }}>
              <Stack
                direction="row"
                justifyContent="end"
                alignItems="center"
              >
                {row.register_status === 1 && (
                  <>
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_ACTIVOS_PUBLICAR}
                      icon={<Publish sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1AA483"}
                      textTooltip={"Publicar"}
                      functionOnClick={() => {
                        handleOpenModalConfirm(
                          row.id,
                          "¿Estás seguro de publicar esta Política?",
                          'Una vez publicado no podrá editar nuevamente la Política de SSO:  "' +
                          row.title +
                          '"',
                          "Si, enviar",
                          "publish"
                        );
                      }}
                    />
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_ACTIVOS_EDITAR}
                      icon={ <BorderColorOutlined sx={{ color: "white" }}/>}
                      backgroundColor={"#2D9CDB"}
                      backgroundColorHover={"#33AEF4"}
                      textTooltip={"Editar"}
                      functionOnClick={() => {
                        handleOpenModalUploadFileEdit(row.id);
                      }}
                    />
                  </>
                )}

                {row.document_url !== "" ? (
                  <ButtonIconControla
                    roles={permissions.DOC_SSO_ACTIVOS_VER_PDF}
                    icon={<VisibilityOutlined sx={{ color: "white" }}/> }
                    backgroundColor={"#305AD9"}
                    backgroundColorHover={"#0E4AFF"}
                    textTooltip={"Ver"}
                    directionUrl={row.document_url}
                    directionTarget={"_blank"}
                  />
                ) : row.register_status !== 1 && (
                  <ButtonIconControla
                    roles={permissions.DOC_SSO_ACTIVOS_VER_SISTEMA}
                    icon={ <VisibilityOutlined sx={{ color: "white" }} /> }
                    backgroundColor={"#305AD9"}
                    backgroundColorHover={"#0E4AFF"}
                    textTooltip={"Ver"}
                    isNavegationOrigin={true}
                    directionUrl={
                      "/documentation/ssopolitics/template?idSsoPolitics=" +
                      row.id + " &readOnly=" + true
                    }
                  />
                )}

                {(row.register_status === 3 || row.register_status == 4 ) && (
                  <ButtonIconControla
                    roles={permissions.DOC_SSO_ACTIVOS_ARCHIVAR}
                    icon={<Archive sx={{ color: "white" }} />}
                    backgroundColor={"#F2994A"}
                    backgroundColorHover={"#FF881E"}
                    textTooltip={"Archivar"}
                    functionOnClick={() =>
                      handleOpenModalConfirm(
                        row.id,
                        "¿Estás seguro de archivar la Política de SSO?",
                        'Documento a archivar:  "' +
                        row.title +
                        '"',
                        "Si, archivar",
                        "archived"
                      )
                    }
                  />
                )}

                {row.register_status == 1 && (
                  <ButtonIconControla
                    roles={permissions.DOC_SSO_ACTIVOS_ELIMINAR}
                    icon={<Delete sx={{ color: "white" }} />}
                    backgroundColor={"#EB5757"}
                    backgroundColorHover={"#FF4040"}
                    textTooltip={"Eliminar"}
                    functionOnClick={() => {
                      handleOpenModalConfirm(
                        row.id,
                        "¿Estás seguro de eliminar la Política?",
                        'Una vez eliminado no se podrá recuperar todo lo incluido en la Política de SSO:  "' +
                        row.title +
                        '"',
                        "Si, Eliminar",
                        "delete_template"
                      );
                    }}
                  />
                )}
              </Stack>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )
      )}
    </TableBody>
  )
}

export default ListActives