import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

async function postSuggestion(data) {

    //obtener fecha
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();

    if (data.document_url !== "") {
        data.document_url = await uploadFileCompanies(
            data.document_url,
            "suggestionsMailbox/" + year + "/" + month + "/" + day + "/"
        );
    }

    const url = "suggestions/create";
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

function getSuggestions(){
    const url = "suggestions/get";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function getSuggestionById (idSuggestion){
    const url = "suggestions/" + idSuggestion;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function deleteSuggestionById (idSuggestion){
    const url = "suggestions/remove/" + idSuggestion;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}

const MySuggestionsMailbox = {
    postSuggestion,
    getSuggestions,
    getSuggestionById,
    deleteSuggestionById
}

export default MySuggestionsMailbox;