import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Stack,
  DialogActions,
  Typography,
} from "@mui/material";
import {
  Add,
  Upload,
  InfoOutlined,
} from "@mui/icons-material";
import Container from "@mui/material/Container";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import SubtitleText from "../../components/text/subtitleText";
import BannerControla from "../../components/cards/bannerControla";
import ButtonControla from "../../components/buttons/buttonController";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import styled from "styled-components";
import MyRisst from "../../api/MyRisst";
import { useAppContext } from "../../context/AppContext";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import TableControla from "../../components/tables/table-controla";
import ListModels from "./components/list-models";
import ListActives from "./components/list-actives";
import ListArchived from "./components/list-archived";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../components/buttons/returnBackControla";

const purple = "#8B8BD8";
const skyblue = "#2D9CDB";
function Index() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );

  //datos del usuario logeado
  const userData = MyConfigurationApi.userData();
  const idUserLogged = userData.id_users;

  const StyledAddButton = styled(Button)({
    textTransform: "none",
    backgroundColor: skyblue,
    borderRadius: 16,
  });

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts


  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdUpdateRisst(id);
    setIdUpdateRisstTemplate(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        handleDeleteRisst();
        break;
      case "public":
        updateStatus(4);
        break;
      case "archived":
        updateStatus(5);
        break;
      case "unarchivedRisst":
        updateStatusUnarchived(1);
        break;
      case "comite":
        updateStatus(3);
        break;
      case "workers":
        updateStatus(7);
        break;
      case "deleteTemplate":
        handleDeleteRisstTemplate();
        break;
      case "publicTemplate":
        updateStatusTemplatePublic(4);
        break;
      case "archivedTemplate":
        updateStatusTemplate(5);
        break;
      case "unarchivedTemplate":
        updateStatusTemplateUnarchived(1);
        break;
      case "comiteTemplate":
        updateStatusTemplate(3);
        break;
      case "workersTemplate":
        updateStatusTemplate(7);
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  // Abrir diálogo de cargar archivo

  const [develop, setDevelop] = useState();
  const [date, setDate] = useState(null);
  const [title, setTitle] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  const [rowsRisstTemplate, setRowsRisstTemplate] = useState([]);
  const [rowsRisst, setRowsRisst] = useState([]);
  const [rowsRisstArchived, setRowsRisstArchived] = useState([]);
  const [rowsRisstArchivedTemplate, setRowsRisstArchivedTemplate] = useState(
    []
  );

  const [rowsRisstPublishTemplate, setRowsRisstPublishTemplate] = useState([]);
  // mostrar risst docuemntos
  const handleGetRisst = async (inputYear = undefined) => {
   
    if (inputYear != undefined) {
      handleOpenLoading();
    }

    handleOpenLoading();

    const response = await MyRisst.getRisst();


    let letrisstarchived = response.filter(
      (status) => status.register_status === 5
    );

    let letrisst = response.filter((status) => status.register_status !== 5);

    setRowsRisst(letrisst);
    setRowsRisstArchived(letrisstarchived);

    setOpenLoading(false);
    return response;
  };

  // Abrir modal subir documento
  const [openModalUploaFile, setOpenModalUploadFile] = useState(false);
  const handleOpenModalUploadFile = () => {
    setDevelop(null);
    setDate(null);
    setTitle(null);
    setDocumentUrl(null);
    setOpenModalUploadFile(true);
  };

  // editar rist document
  const [idRist, setIdRist] = useState();
  const [openModalUploaFileEdit, setOpenModalUploadFileEdit] = useState(false);
  const handleOpenModalUploadFileEdit = (idRist) => {
    setIdRist(idRist);
    handleEditRisst(idRist);
    setOpenModalUploadFileEdit(true);
  };

  const handleEditRisst = async (idRist) => {
    const response = await MyRisst.editRisst({ idRisst: idRist });
    setDevelop(response.develop);
    setTitle(response.title);
    setDate(response.date);
    return response;
  };
  const handleUpdateRisst = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyRisst.UpdateRisst({
      idRisst: idRist,
      develop: develop,
      title: title,
      date: date,
      document_url: documentUrl,
    });
    handleGetRisst();
    setOpenModalUploadFileEdit(false);
    handleOpenAlert("Los datos fueron editados de forma correcta", "success");
    return response;
  };

  // Mostrar plantillas
  const handleGetRisstTemplate = async (inputYear = undefined) => {
    if (inputYear != undefined) {
      handleOpenLoading();
    }

    // handleOpenLoading();

    const response = await MyRisst.getRisstTemplate();


    let letrisstTemplatearchived = response.filter(
      (status) => status.register_status === 5
    );
    let letrisstTemplate = response.filter(
      (status) => status.register_status !== 5 && status.register_status !== 4
    );
    let letrisstTemplatePublish = response.filter(
      (status) => status.register_status === 4
    );

    setRowsRisstPublishTemplate(letrisstTemplatePublish);
    setRowsRisstTemplate(letrisstTemplate);
    setRowsRisstArchivedTemplate(letrisstTemplatearchived);

    // setOpenLoading(false);
    return response;
  };

  //   crear
  const handleCreateRisst = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyRisst.createRisst({
      develop: develop,
      title: title,
      date: date,
      document_url: documentUrl,
    });
    if (response.success !== false) {
      setOpenModalUploadFile(false);
      handleOpenAlert("El documento se ha creado de forma correcta", "success");
      handleGetRisst();
      navigate("/documentation/risst?idTabs=1");
    } else {
      handleOpenAlert("Ocurrió un error al crear el archivo", "error");
    }
    return response;
  };

  // Diálogo para template
  const [openModalTemplate, setOpenModalTemplate] = useState(false);
  const handleOpenModalTemplate = () => {
    setOpenModalTemplate(true);
  };

  // Dialog para ver plantilla
  const [openModalTemplateView, setOpenModalTemplateView] = useState(false);
  const handleOpenModalTemplateView = (event) => {
    event.preventDefault();
    setOpenModalTemplateView(true);
  };

  //usar plantilla

  const handleUseTemplate = async (event) => {
    event.preventDefault();
    navigate("/documentation/risst/template");
  };

  const currentTime = new Date();
  const [year, setYear] = useState(currentTime.getFullYear());

  const [documentType, setDocumentType] = useState(
    idTabs !== undefined && idTabs !== null && idTabs !== "" ? idTabs : 0
  );

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  const handleIncreaseYear = (event, newValue) => {
    setYear(year + 1);
    // handleGetPets(year + 1);
  };

  const handleDecreaseYear = (event, newValue) => {
    setYear(year - 1);
    // handleGetPets(year - 1);
  };

  // actualizar estado
  const [idUpdateRisst, setIdUpdateRisst] = useState();
  const updateStatus = async (idStatus) => {

    handleOpenLoading();
    const response = await MyRisst.updatestatusRisst({
      idRisst: idUpdateRisst,
      register_status: idStatus,
    });
     //

    handleGetRisstTemplate();
    handleGetRisst();
   
    handleOpenAlert("La operación se realizó con éxito", "success");
    return response;
  };

  const updateStatusUnarchived = async (idStatus) => {
    handleOpenLoading();
    const response = await MyRisst.updatestatusRisst({
      idRisst: idUpdateRisst,
      register_status: idStatus,
    });
    handleGetRisst();
    handleGetRisstTemplate();
    handleOpenAlert("La operación se realizó con éxito", "success");
    navigate("/documentation/risst?idTabs=1");
    return response;
  };

  // actualizar estado 
  const [idUpdateRisstTemplate, setIdUpdateRisstTemplate] = useState();
  const updateStatusTemplate = async (idStatus) => {
    handleOpenLoading();
    const response = await MyRisst.updatestatusRisstTemplate({
      idRisstTemplate: idUpdateRisstTemplate,
      register_status: idStatus,
    });

    handleGetRisstTemplate();
    handleGetRisst();
    handleOpenAlert("La operación se realizó con éxito", "success");

    return response;
  };

  const updateStatusTemplatePublic = async (idStatus) => {
    handleOpenLoading();
    const response = await MyRisst.updatestatusRisstTemplate({
      idRisstTemplate: idUpdateRisstTemplate,
      register_status: idStatus,
    });

    handleGetRisstTemplate();
    handleGetRisst();
    handleOpenAlert("La operación se realizó con éxito", "success");
    navigate("/documentation/risst?idTabs=1")
    return response;
  };


  const updateStatusTemplateUnarchived = async (idStatus) => {
    handleOpenLoading();
    const response = await MyRisst.updatestatusRisstTemplate({
      idRisstTemplate: idUpdateRisstTemplate,
      register_status: idStatus,
    });

    handleGetRisstTemplate();
    handleGetRisst();
    handleOpenAlert("La operación se realizó con éxito", "success");
    navigate("/documentation/risst?idTabs=0");
    return response;
  };

  // eliminar
  //  const [idRisst, setIdRisst] = React.useState();

  const handleDeleteRisst = async () => {
    handleOpenLoading();

    const response = await MyRisst.deleteRisst({
      idRisst: idUpdateRisst,
    });
    if (response.success === true) {
      handleGetRisst();
      handleGetRisstTemplate();
      handleOpenAlert(
        "Los datos fueron eliminados de forma correcta",
        "success"
      );
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  //  eliminar plantilla
  const handleDeleteRisstTemplate = async () => {
    handleOpenLoading();
    const response = await MyRisst.deleteRisstTemplate({
      idRisstTemplate: idUpdateRisstTemplate,
    });
    if (response.success === true) {
      handleGetRisstTemplate();
      handleOpenAlert(
        "Los datos fueron eliminados de forma correcta",
        "success"
      );
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  //   infromacion
  const StyledModalTitle = styled("h2")({
    color: purple,
  });
  const StyledModalSubtitle = styled("h3")({
    fontWeight: "bold",
  });
  const [openModalInfo, setOpenModalInfo] = React.useState(false);

  const handleOpenModalInfo = () => {
    setOpenModalInfo(true);
  };

  const [totalRisst, setTotalRisst] = useState([]);
  const [totalRisstActivos, setTotalRisstActivos] = useState([]);

  useEffect(() => {
    setTotalRisstActivos([...rowsRisst, ...rowsRisstPublishTemplate]);
  }, [rowsRisstPublishTemplate, rowsRisst]);

  useEffect(() => {
    setTotalRisst([...rowsRisstArchived, ...rowsRisstArchivedTemplate]);
  }, [rowsRisstArchived, rowsRisstArchivedTemplate]);

  useEffect(() => {
    handleGetRisst();
    handleGetRisstTemplate();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <Box>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item xs={5} md={5}>
              <ButtonMailboxControla
                originUrl={"/documentation/risst"}
              />
            </Grid>

            <Grid
              item
              xd={7}
              md={7}
              style={{ textAlign: "right", marginBottom: "3px" }}
            >
              <StyledAddButton
                variant="text"
                startIcon={<InfoOutlined />}
                disableElevation
                size="large"
                style={{
                  backgroundColor: "#305AD9",
                  borderRadius: "15px",
                  color: "white",
                  padding: "8px 25px",
                }}
                onClick={() => handleOpenModalInfo()}
              >
                ¿Qué debo saber del RISST?{" "}
              </StyledAddButton>
            </Grid>

            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Procedimientos"}
                color={"#9191B0"}
              ></SubtitleText>
            </Grid>

            <Grid item xs={12} md={12}>
              <BannerControla
                image="/assets/images/banners/documents/bannerrisst.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="RISST"
              />
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
              <Stack direction={"row"} spacing={2}>
                <Box>
                  <ButtonControla
                    roles={permissions.DOC_RISST_MODEL_AGREGAR}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#1cb591"}
                    backgroundColorHover={"#169073"}
                    textButton={"Agregar usando plantilla"}
                    functionOnClick={handleOpenModalTemplate}
                  />
                </Box>
                <Box>
                  <ButtonControla
                    roles={permissions.DOC_RISST_MODEL_SUBIR_DOC}
                    iconButton={<Upload sx={{ color: "white" }} />}
                    textButton={"Subir mi documento"}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#1976d2"}
                    functionOnClick={handleOpenModalUploadFile}
                  />
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={3} xl={3} sx={{ mt: 2 }}>
              {" "}
            </Grid>

            {/* filtrar por año */}
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <Grid container spacing={2}>
                {/* <Grid item xs={3} sm={2} md={3} lg={3} xl={3}>
                  <InputLabel
                    sx={{
                      padding: "8px 0px 8px 0px",
                      fontSize: "14px",
                      color: "#305AD9",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Periodo:
                  </InputLabel>
                </Grid>
                <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                  <InputBase
                    type="number"
                    value={year}
                    onChange={"handleGetPets"}
                    readOnly
                    sx={{
                      width: "100%",
                      padding: "4px 10px 4px 10px",
                      backgroundColor: "white",
                      color: "#305AD9",
                      fontSize: "14px",
                      borderRadius: "8px",
                    }}
                  ></InputBase>
                </Grid> */}
                {/* <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      color: "#305AD9",
                    }}
                    onClick={handleDecreaseYear}
                  >
                    <ArrowBackIosNew />
                  </IconButton>
                </Grid> */}
                {/* <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      color: "#305AD9",
                    }}
                    onClick={handleIncreaseYear}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

              <TableControla
                loading={openLoading}
                tabs={[
                  {
                    label: "Modelos",
                    columnNames: ["Nombre del documento", "Tipo", "Estado"]
                  },
                  {
                    label: "Activos",
                    columnNames: ["Nombre del documento", "Tipo", "Estado"]
                  },
                  {
                    label: "Archivados",
                    columnNames: ["Nombre del documento", "Tipo", "Estado"]
                  }
                ]}
              >

                <ListModels
                  rowsRisstTemplate={rowsRisstTemplate}
                  colorCell={purple}
                  idUserLogged={idUserLogged}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                />
                <ListActives
                  totalRisstActivos={totalRisstActivos}
                  colorCell={purple}
                  handleOpenModalUploadFileEdit={handleOpenModalUploadFileEdit}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                />

                <ListArchived
                  totalRisst={totalRisst}
                  colorCell={purple}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                />

              </TableControla>

            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Dialog subir archivo */}
      <Dialog
        open={openModalUploaFile}
        onClose={() => setOpenModalUploadFile(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            RISST - Reglamento Interno de Seguridad y Salud Ocupacional{" "}
          </Typography>
        </DialogTitle>

        <form onSubmit={handleCreateRisst}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Elaborado por:"}
                  inputType={"text"}
                  inputValue={setDevelop}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de elaboración:"}
                  inputType={"date"}
                  inputValue={setDate}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitle}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalUploadFile(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog editar  archivo */}
      <Dialog
        open={openModalUploaFileEdit}
        onClose={() => setOpenModalUploadFileEdit(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Edita - RISST - Reglamento Interno de Seguridad y Salud Ocupacional{" "}
          </Typography>
        </DialogTitle>

        <form onSubmit={handleUpdateRisst}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Elaborado por:"}
                  inputType={"text"}
                  inputValue={setDevelop}
                  value={develop}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de elaboración:"}
                  inputType={"date"}
                  inputValue={setDate}
                  modalType={true}
                  value={date}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitle}
                  modalType={true}
                  value={title}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalUploadFileEdit(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog plantilla */}
      <Dialog
        open={openModalTemplate}
        onClose={() => setOpenModalTemplate(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Te presentamos un modelo para facilitar la elaboración de tu
            documento
          </Typography>
        </DialogTitle>

        <form onSubmit={handleOpenModalTemplateView}>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalTemplate(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Ver
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Modelo de plantilla */}
      <Dialog
        open={openModalTemplateView}
        onClose={() => setOpenModalTemplateView(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0, marginTop: "15px" }}
      >
        <DialogTitleControla titleText="RISST" functionOnClose={()=>setOpenModalTemplateView(false)} />

        <form onSubmit={handleUseTemplate}>
          <DialogContent>
            <Grid container>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  I.RESUMEN EJECUTIVO DE LA ACTIVIDAD DE LA EMPRESA
                </StyledModalSubtitle>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  II.OBJETIVOS Y ALCANCES
                </StyledModalSubtitle>
              </Grid>
              <Grid item>
                <ul type="1. ">
                  <li>Objetivos</li>
                  <li>Alcances</li>
                </ul>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  III. LIDERAZGO Y COMPROMISOS, Y POLÍTICA DE SEGURIDAD Y SALUD{" "}
                </StyledModalSubtitle>
              </Grid>
              <Grid item>
                <ul type="1. ">
                  <li>Liderazgo y compromisos</li>
                  <li>Política de seguridad y salud</li>
                </ul>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  IV. ATRIBUCIONES Y OBLIGACIONES DEL EMPLEADOR, DE LOS
                  SUPERVISORES, DEL COMITÉ DE SEGURIDAD Y SALUD, DE LOS
                  TRABAJADORES Y DE LOS EMPLEADORES QUE LES BRINDAN SERVICIOS SI
                  LOS HUBIERA{" "}
                </StyledModalSubtitle>
              </Grid>
              <Grid item>
                <ul type="1. ">
                  <li>Funciones y responsabilidades</li>
                  <li>Organización interna de seguridad y salud ocupaciona</li>
                  <li>
                    Implementación de registros y documentación del Sistema de
                    Gestión de Seguridad y salud ocupacional
                  </li>
                  <li>
                    Funciones y responsabilidades de las empresas, entidades
                    públicas o privadas que brindan servicios
                  </li>
                </ul>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  V.ESTÁNDARES DE SEGURIDAD Y SALUD EN LAS OPERACIONES{" "}
                </StyledModalSubtitle>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  VI. ESTÁNDARES DE SEGURIDAD Y SALUD EN LOS SERVICIOS Y
                  ACTIVIDADES CONEXAS{" "}
                </StyledModalSubtitle>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  VII. PREPARACIÓN Y RESPUESTA A EMERGENCIAS
                </StyledModalSubtitle>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalTemplateView(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Usar plantilla
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* dialogo de información */}
      <Dialog
        open={openModalInfo}
        onClose={() => setOpenModalInfo(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{
          padding: "20px",
        }}
      >
        <DialogTitleControla
          titleText="REGLAMENTO INTERNO DE SEGURIDAD Y SALUD OCUPACIONAL
(RISST)"
        />
        <DialogContent>
          <Grid container>
            <Grid item md={12}>
              <StyledModalTitle>1. Introducción</StyledModalTitle>
            </Grid>

            <Grid item>
              El Reglamento Interno de Seguridad y Salud Ocupacional (RISST) es
              un documento que forma parte del Sistema de Gestión de Seguridad y
              Salud Ocupacional, y que tiene por finalidad promover la
              instauración de una cultura de prevención de riesgos laborales. Su
              elaboración y mejora deben estar siempre documentadas. A
              continuación, te presentamos sus principales características.
            </Grid>
            <Grid item md={12}>
              <StyledModalTitle>
                2. Consideraciones generales:{" "}
              </StyledModalTitle>
            </Grid>
            <StyledModalSubtitle>¿Quién elabora el RISST?</StyledModalSubtitle>
            <Grid item>
              El presente documento es elaborado por el empleador, y debe
              contener la estructura mínima señalada en el artículo 74° del
              Decreto Supremo N° 005-2012-TR, Reglamento de la Ley N° 29783, Ley
              de Seguridad y Salud en el Trabajo que es:
              <ol type="a">
                <li>Objetivos y alcances.</li>
                <li>
                  Liderazgo, compromisos y la política de seguridad y salud.
                </li>
                <li>
                  Atribuciones y obligaciones del empleador, de los
                  supervisores, del comité de seguridad y salud, de los
                  trabajadores y de los empleadores que les brindan servicios si
                  las hubiera.{" "}
                </li>
                <li>Estándares de seguridad y salud en las operaciones.</li>
                <li>
                  Estándares de seguridad y salud en los servicios y actividades
                  conexas.{" "}
                </li>
                <li>Preparación y respuesta a emergencias.</li>
              </ol>
            </Grid>
            <StyledModalSubtitle>
              ¿Qué empresas están obligadas a elaborar un RISST?
            </StyledModalSubtitle>
            <Grid item>
              Para empresas con menos de 20 trabajadores la elaboración del
              RISST es opcional. Sin embargo, para las empresas que cuenten con
              20 o más trabajadores es obligatoria la elaboración del RISST.
            </Grid>
            <StyledModalSubtitle>
              ¿Cada cuánto se debe actualizar el RISST?{" "}
            </StyledModalSubtitle>
            <Grid item>
              Como documento del Sistema de Gestión de Seguridad y Salud
              Ocupacional, el RISST debe ser revisado y actualizado de manera
              periódica, de acuerdo a los cambios que puedan surgir en los
              procesos y operaciones, o en los documentos que contiene el RISST.
              Asimismo, puede ser revisado periódicamente de acuerdo a lo que
              determine el comité.
            </Grid>
            <StyledModalSubtitle>
              ¿Quién vigila el cumplimiento del RISST?
            </StyledModalSubtitle>
            <Grid item>
              Los miembros del Comité de Seguridad y Salud Ocupacional de la
              empresa son los encargados de vigilar su cumplimiento.
            </Grid>
            <StyledModalSubtitle>
              ¿A quiénes se entrega el RISST?
            </StyledModalSubtitle>
            <Grid item>
              El RISST y sus modificaciones y/o actualizaciones se deben
              entregar a:
              <ol>
                <li>
                  Todos los trabajadores, considerando no sólo a los de planilla
                  sino aquellos que en modalidades formativas (practicantes),
                  recibo por honorarios, trabajadores de las empresas
                  contratistas o subcontratistas.
                </li>
              </ol>
            </Grid>
            <StyledModalSubtitle>
              ¿Cómo se entrega el RISST?{" "}
            </StyledModalSubtitle>
            <Grid item>
              El RISST puede ser entregado en formato digital o físico, siempre
              y cuando su entrega sea debidamente documentada o evidenciada.
            </Grid>

            <Grid item md={12}>
              <StyledModalTitle>3. Fiscalización del RISST</StyledModalTitle>
            </Grid>

            <StyledModalSubtitle>
              ¿Qué aspectos principales considerará la autoridad ante una
              actuación inspectiva?{" "}
            </StyledModalSubtitle>
            <Grid item>
              <ol>
                <li>
                  Que se cumpla con todos los requisitos para su elaboración
                  señalados anteriormente.
                </li>
                <li>
                  Que sea revisado y aprobado por el Comité de Seguridad y Salud
                  Ocupacional.
                </li>
                <li>
                  Que sea entregado a todos los trabajadores de la empresa,
                  incluidos personas en formación laboral, ya sea de forma
                  digital o física, pero que se cuente con un cargo de entrega.
                </li>
                <li>
                  Que además, sea entregado a los trabajadores de las empresas
                  contratistas y/o subcontratistas, personsa independientes y
                  visitantes en forma digital o física bajo cargo.
                </li>
                <li>
                  Que esté actualizado y/o modificado de acuerdo a la
                  actualización o modificación de los procesos operativos por la
                  introducción de nuevas tecnologías, en coherencia con el
                  IPERC.
                </li>
                <li>
                  Que contemple todas las actividades de la empresa, ya sea
                  operativas, administrativas, propias y contratadas.
                </li>
              </ol>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalInfo(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

export default Index;
