
import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import BannerControla from "../../../../components/cards/bannerControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import TableControla from "../../../../components/tables/table-controla";
import ListHealthAndSafetyStatistics from "./components/list-healthAndSafetyStatistics";
import LoadingControla from "../../../../components/load/loadingControla";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import Box from "@mui/material/Box";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from "@mui/material";
import {
    Add
} from "@mui/icons-material";
import MyHealthAndSafetyStatistics from "../../../../api/MyHealthAndSafetyStatistics";
import { useAppContext } from "../../../../context/AppContext";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";
const purple = "#8B8BD8";

function Index() {
    const {permissions} = useAppContext();

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    //fin de alerts

    //modal agregar
    const [openModalCreateRegister, setOpenModalCreateRegister] = useState(false);
    const handelOpenModalCreateRegister = () => {
        setOpenModalCreateRegister(true);
    }

    const handleCloseModalCreateRegister = () => {
        setOpenModalCreateRegister(false);
    }

    const [code, setCode] = useState("");

    const [rows, setRows] = useState([{}]);

    const getHealthAndSafetyStatistics = async () => {
        handleOpenLoading();
        const resp = await MyHealthAndSafetyStatistics.getHealthAndSafetyStatistics() ?? [];
        setRows(resp);

        setOpenLoading(false);

    }

    const handlePostHealthAndSafetyStatistics = async (e) => {
        e.preventDefault();
        handleOpenLoading();

        const data = {
            nro_register: code
        }

        try {
            const resp = await MyHealthAndSafetyStatistics.postHealthAndSafetyStatistics(data);
            await getHealthAndSafetyStatistics();
            handleOpenAlert("El registro se creó con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error al crear registro", "error");
        }

        handleCloseModalCreateRegister();
    }

    useEffect(() => {
        getHealthAndSafetyStatistics();

    }, [])

    return (
        <Box sx={{ height: "100%" }}>
            <ReturnBack/>

            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <ButtonMailboxControla
                            originUrl={"/documentation/registers/generals/statisticaldata/occupationalHealthAndSafetyStatistics"}
                        />

                        <Grid item xs={12} md={12}>
                            <SubtitleText
                                text={"Registros"}
                                color={colorTitle}
                            ></SubtitleText>
                            <BannerControla
                                image="/assets/images/banners/documents/bannerStatisticalData.png"
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Estadísticas de Seguridad y Salud Ocupacional"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={3}>
                            <ButtonControla
                                roles={permissions.DOC_REGIS_STATISTICS_RECORD_CREAR_REGIS}
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Crear nuevo registro"}
                                functionOnClick={handelOpenModalCreateRegister}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Grid item md={12}>
                                <TableControla
                                    loading={openLoading}
                                    tabs={[
                                        {
                                            columnNames: ["Código", "Estado", "Acciones"]
                                        }
                                    ]}
                                >
                                    <ListHealthAndSafetyStatistics
                                        rows={rows}
                                        colorCell={purple}
                                    />

                                </TableControla>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Dialog
                open={openModalCreateRegister}
                onClose={handleCloseModalCreateRegister}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText={"Agregar código del registro de estadísticas de seguridad y salud ocupacional"}
                    functionOnClose={handleCloseModalCreateRegister}
                />
                <form onSubmit={handlePostHealthAndSafetyStatistics}>
                    <DialogContent>
                        <InputControlaVertical
                            text={"Código"}
                            inputType={"text"}
                            inputValue={setCode}
                            value={code}
                            modalType={true}
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                backgroundColorHover={"#CBCBFF"}
                                typeButton={"button"}
                                functionOnClick={handleCloseModalCreateRegister}
                            />
                            <ButtonControla
                                textButton={"Crear"}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>

        </Box>
    )

}
export default Index;
