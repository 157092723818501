import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import {
    BorderColorOutlined,
    Delete,
    Archive,
    FileUploadOutlined,
    Send
} from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useAppContext } from "../../../../../context/AppContext";

function ListActives(props) {
    const { permissions } = useAppContext();

    const {
        rowsActives,
        colorCell,
        documentType,
        getUser,
        handleOpenModalEditFile,
        handleOpenModalConfirm
    } = props

    return (
        <TableBody
            sx={{ display: documentType !== 1 ? "none" : "" }}
        >
            {rowsActives.map((rowsActives) => (
                <TableRow
                    hover
                    key={rowsActives.id_pets}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell sx={{ color: colorCell }}>
                        {rowsActives.name}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {rowsActives.document_url != null &&
                            rowsActives.document_url != undefined
                            ? "Archivo"
                            : "Sistema"}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {rowsActives.users_approve_firm == 1 && rowsActives.id_pets_statuses == 2 ? "Aprobado" : rowsActives.status}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >

                            {rowsActives.id_pets_statuses === 1 &&
                                rowsActives.document_url === null ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                                    icon={
                                        <BorderColorOutlined
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#2D9CDB"}
                                    backgroundColorHover={"#33AEF4"}
                                    textTooltip={"Editar"}
                                    isNavegationOrigin={true}
                                    directionUrl={"/documentation/documents/procedures/pets/edit?idPets=" + rowsActives.id_pets}
                                />
                            ) : ''}

                            {rowsActives.id_pets_statuses === 1 &&
                                rowsActives.document_url !== null ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                                    icon={
                                        <BorderColorOutlined
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#2D9CDB"}
                                    backgroundColorHover={"#33AEF4"}
                                    textTooltip={"Editar"}
                                    // el editar de activos debe enviar a un modal
                                    functionOnClick={() => {
                                        handleOpenModalEditFile(
                                            rowsActives.id_pets,
                                            rowsActives.name,
                                            rowsActives.code,
                                            rowsActives.document_url
                                        );
                                    }}
                                />
                            ) : (
                                ""
                            )}

                            {/* probando para 1 revisador */}
                            {
                                rowsActives.id_pets_statuses == 2 &&
                                    (rowsActives.id_users_review_1 == getUser().id_users || rowsActives.id_users_prepare == getUser().id_users) &&
                                    (rowsActives.id_users_review_1 != null && rowsActives.id_users_review_2 == null) ? (
                                    rowsActives.users_review_1_firm == null
                                        ? (
                                            <ButtonIconControla
                                                icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                                backgroundColor={"#2D9CDB"}
                                                backgroundColorHover={"#33AEF4"}
                                                textTooltip={"Editar 1 revisador"}
                                                isNavegationOrigin={true}
                                                directionUrl={"/documentation/documents/procedures/pets/edit?idPets=" + rowsActives.id_pets}
                                            />
                                        ) : ""
                                ) : ""
                            }

                            {/* probando para 2 revisador */}
                            {
                                rowsActives.id_pets_statuses == 2 &&
                                    (rowsActives.id_users_review_1 == getUser().id_users || rowsActives.id_users_review_2 == getUser().id_users) &&
                                    (rowsActives.id_users_review_1 != null && rowsActives.id_users_review_2 != null) ? (
                                    rowsActives.users_review_1_firm == null && rowsActives.id_users_review_1 == getUser().id_users ? (
                                        <ButtonIconControla
                                            icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar revisador 1"}
                                            isNavegationOrigin={true}
                                            directionUrl={"/documentation/documents/procedures/pets/edit?idPets=" + rowsActives.id_pets}
                                        />

                                    ) : (rowsActives.users_review_2_firm == null && rowsActives.id_users_review_2 == getUser().id_users ? (
                                        <ButtonIconControla
                                            icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar revisador 2"}
                                            isNavegationOrigin={true}
                                            directionUrl={"/documentation/documents/procedures/pets/edit?idPets=" + rowsActives.id_pets}
                                        />

                                    ) : ""
                                    )
                                ) : ""
                            }

                            {/* para el aprobador */}
                            {
                                rowsActives.id_pets_statuses == 2 && rowsActives.id_users_approve == getUser().id_users ? (
                                    (rowsActives.id_users_review_1 != null && rowsActives.id_users_review_2 != null) &&
                                        (rowsActives.users_review_1_firm == 1 && rowsActives.users_review_2_firm == 1) &&
                                        rowsActives.users_approve_firm == null ? (
                                        <ButtonIconControla
                                            icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar 2 revisadores aprueba"}
                                            isNavegationOrigin={true}
                                            directionUrl={"/documentation/documents/procedures/pets/edit?idPets=" + rowsActives.id_pets}
                                        />

                                    ) :
                                        (rowsActives.id_users_review_1 != null && rowsActives.id_users_review_2 == null) && (rowsActives.users_review_1_firm == 1 && rowsActives.users_approve_firm == null) ? (
                                            <ButtonIconControla
                                                icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                                backgroundColor={"#2D9CDB"}
                                                backgroundColorHover={"#33AEF4"}
                                                textTooltip={"Editar 1 revisador aprueba"}
                                                isNavegationOrigin={true}
                                                directionUrl={"/documentation/documents/procedures/pets/edit?idPets=" + rowsActives.id_pets}
                                            />

                                        ) : ""
                                ) : ""
                            }

                            {
                                rowsActives.id_pets_statuses === 2 &&
                                    rowsActives.document_url !== null ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                                        icon={
                                            <BorderColorOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#2D9CDB"}
                                        backgroundColorHover={"#33AEF4"}
                                        textTooltip={"Editar"}
                                        // el editar de activos debe enviar a un modal
                                        functionOnClick={() => {
                                            handleOpenModalEditFile(
                                                rowsActives.id_pets,
                                                rowsActives.name,
                                                rowsActives.code,
                                                rowsActives.document_url
                                            );
                                        }}
                                    />
                                ) : (
                                    ""
                                )

                            }


                            {rowsActives.id_pets_statuses === 1 && rowsActives.document_url !== null ?
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETS_ACTI_PUBLICAR}
                                        icon={
                                            <FileUploadOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Publicar"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                rowsActives.id_pets,
                                                "¿Estás seguro de publicar el documento?",
                                                "Recuerda que el documento debe estar aprobado por la persona responsable.",
                                                "Sí, publicar",
                                                "public"
                                            );
                                        }}
                                    />
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                                        icon={
                                            <VisibilityOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        directionUrl={rowsActives.document_url}
                                        directionTarget={"_blank"}
                                    />
                                </>
                                : ''}

                            {rowsActives.id_pets_statuses === 1 && rowsActives.document_url === null ? (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETS_ACTI_ENVIAR_A_REVISION}
                                        icon={<Send sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Enviar a revisión"}
                                        functionOnClick={() => {
                                            const data = {
                                                message: "Tienes un nuevo documento por revisar",
                                                document_url: "/documentation/documents/procedures/pets/edit?idPets=" + rowsActives.id_pets,
                                                id_users_created: rowsActives.id_users_created,
                                                id_users_modified: rowsActives.id_users_modified,
                                                id_users_sender: getUser().id_users,
                                                id_users_receiving: [rowsActives.id_users_approve, rowsActives.id_users_review_1, rowsActives.id_users_review_2]
                                            }

                                            handleOpenModalConfirm(
                                                rowsActives.id_pets,
                                                "¿Estás seguro de enviar a revisión?",
                                                "",
                                                "Si, enviar",
                                                "revision",
                                                data,
                                                rowsActives.users_prepare_firm
                                            );
                                        }}
                                    />
                                </>
                            ) : ""
                            }
                            {
                                rowsActives.id_pets_statuses === 2 && rowsActives.document_url === null && rowsActives.users_approve_firm == 1 ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETS_ACTI_PUBLICAR}
                                        icon={<FileUploadOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Publicar"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                rowsActives.id_pets,
                                                "¿Estás seguro de publicar el documento?",
                                                "",
                                                "Sí, publicar",
                                                "public"
                                            );
                                        }}
                                    />

                                ) : ""
                            }
                            {
                                rowsActives.id_pets_statuses === 2 && rowsActives.document_url === null ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETS_ACTI_EDITAR}
                                        icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        isNavegationOrigin={true}
                                        directionUrl={"/documentation/documents/procedures/pets/edit?idPets=" + rowsActives.id_pets + "&readOnly=" + true}

                                    />
                                ) : ""
                            }

                            {rowsActives.id_pets_statuses === 3 && rowsActives.document_url !== null ? (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETS_ACTI_ARCHIVAR}
                                        icon={<Archive sx={{ color: "white" }} />}
                                        backgroundColor={"#F2994A"}
                                        backgroundColorHover={"#FF881E"}
                                        textTooltip={"Archivar"}
                                        functionOnClick={() =>
                                            handleOpenModalConfirm(
                                                rowsActives.id_pets,
                                                "¿Esta seguro de archivar el PETS?",
                                                'PETS a archivar:  "' +
                                                rowsActives.name +
                                                '"',
                                                "Si, archivar",
                                                "archived"
                                            )
                                        }
                                    />
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETS_ACTI_VER}
                                        icon={
                                            <VisibilityOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        directionUrl={rowsActives.document_url}
                                        directionTarget={"_blank"}
                                    />
                                </>
                            ) : ''}
                            {rowsActives.id_pets_statuses === 2 && rowsActives.document_url !== null ? (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETS_ACTI_ARCHIVAR}
                                        icon={<Archive sx={{ color: "white" }} />}
                                        backgroundColor={"#F2994A"}
                                        backgroundColorHover={"#FF881E"}
                                        textTooltip={"Archivar"}
                                        functionOnClick={() =>
                                            handleOpenModalConfirm(
                                                rowsActives.id_pets,
                                                "¿Esta seguro de archivar el PETS?",
                                                'PETS a archivar:  "' +
                                                rowsActives.name +
                                                '"',
                                                "Si, archivar",
                                                "archived"
                                            )
                                        }
                                    />
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_PETS_ACTI_VER}
                                        icon={
                                            <VisibilityOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        directionUrl={rowsActives.document_url}
                                        directionTarget={"_blank"}
                                    />
                                </>
                            ) : ''}

                            {rowsActives.id_pets_statuses === 3 && rowsActives.document_url === null ?
                                (
                                    <>
                                        <ButtonIconControla
                                            roles={permissions.DOC_HG_PROC_PETS_ACTI_ARCHIVAR}
                                            icon={<Archive sx={{ color: "white" }} />}
                                            backgroundColor={"#F2994A"}
                                            backgroundColorHover={"#FF881E"}
                                            textTooltip={"Archivar"}
                                            functionOnClick={() =>
                                                handleOpenModalConfirm(
                                                    rowsActives.id_pets,
                                                    "¿Esta seguro de archivar el PETS?",
                                                    'PETS a archivar:  "' +
                                                    rowsActives.name +
                                                    '"',
                                                    "Si, archivar",
                                                    "archived"
                                                )
                                            }
                                        />
                                        <ButtonIconControla
                                            roles={permissions.DOC_HG_PROC_PETS_ACTI_VER}
                                            icon={
                                                <VisibilityOutlined
                                                    sx={{ color: "white" }}
                                                />
                                            }
                                            backgroundColor={"#305AD9"}
                                            backgroundColorHover={"#0E4AFF"}
                                            textTooltip={"Ver"}
                                            isNavegationOrigin={true}
                                            directionUrl={"/documentation/documents/procedures/pets/edit?idPets=" + rowsActives.id_pets + "&readOnly=" + true}
                                        />
                                    </>
                                )
                                : ''
                            }

                            {rowsActives.id_pets_statuses === 1 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_HG_PROC_PETS_ACTI_ELIMINAR}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() => {
                                        handleOpenModalConfirm(
                                            rowsActives.id_pets,
                                            "¿Esta seguro de eliminar el modelo?",
                                            'Una vez eliminado no se podra recuperar todo lo incluido en este modelo PETS:  "' +
                                            rowsActives.name +
                                            '"',
                                            "Si, Eliminar",
                                            "delete"
                                        );
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListActives;