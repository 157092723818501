import {
    Backdrop,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
} from "@mui/material";
import { React, useState, useEffect } from "react";

import BannerControla from "../../../components/cards/bannerControla";
import { Add, FilterAlt } from "@mui/icons-material";
import ButtonControla from "../../../components/buttons/buttonController";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import DialogConfirmControla from "../../../components/dialog/dialogConfirmControla";
import MyTrainings from "../../../api/MyTrainings";
import SelectControla from "../../../components/selects/selectControla";
import MyUsers from "../../../api/MyUsers";
import MyCampus from "../../../api/MyCampus";
import { TableTrainings } from "./components/table";
import Filters from "./components/filters";
import { useAppContext } from "../../../context/AppContext";
import ButtonMailboxControla from "../../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import ReturnBack from "../../../components/buttons/returnBackControla";


function Index() {
    const navigate = useNavigate();
    const {permissions} = useAppContext();
    const params = new URLSearchParams(window.location.search);

    //BackDrop
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };

    const [idSubject, setIdSubject] = useState("");
    const [name, setName] = useState("");


    //Modals
    const [openModalCreate, setOpenModalCreate] = useState(false);

    const handleOpenModalCreate = () => {
        setOpenModalCreate(true);
    };
    const handleCloseModalCreate = () => {
        setOpenModalCreate(false);
    };

    //Filters
    const [openModalFilters, setOpenModalFilters] = useState(false);

    const handleOpenModalFilters = () => {
        setOpenModalFilters(true);
    };
    const handleCloseModalFilters = () => {
        setOpenModalFilters(false);
    };

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    // Dialogos de confirmacion INICIO

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");

    const handleOpenModalConfirm = (
        id,
        titleText,
        contentText,
        buttonTextConfirm,
        buttonActionType
    ) => {
        setIdSubject(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    };

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    };

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            default:
                break;
        }
        setOpenModalConfirm(false);
    };

    const [subjectFilter, setSubjectFilter] = useState(params.get("id_trainings_subjects"));
    const [typeFilter, setTypeFilter] = useState(params.get("id_trainings_types"));
    const [modalityFilter, setModalityFilter] = useState(params.get("id_trainings_modalities"));
    const [statusFilter, setStatusFilter] = useState(params.get("id_trainings_statuses"));
    const [yearFilter, setYearFilter] = useState(params.get("year"));
    // const [campusFilter, setCampusFilter] = useState(params.get("id_campus"));
    const [responsibleFilter, setResponsibleFilter] = useState(params.get("id_users_responsible"));
    // const [startFilter, setStartFilter] = useState(params.get("timestamp_start"));
    // const [endFilter, setEndFilter] = useState(params.get("timestamp_end"));
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false)


    const [rowsTypes, setRowsTypes] = useState([]);
    const handleGetTypes = async () => {
        const response = await MyTrainings.getTypes();
        setRowsTypes(response);
    };

    const [rowSubjects, setRowSubjects] = useState([]);
    const handleGetSubjects = async () => {
        const response = await MyTrainings.getSubjects();
        setRowSubjects(response);
    };

    const [rowStatuses, setRowStatuses] = useState([]);
    const handleGetStatuses = async () => {
        const response = await MyTrainings.getStatuses();
        setRowStatuses(response);
    };

    const [rowModalities, setRowModalities] = useState([]);
    const handleGetModalities = async () => {
        const response = await MyTrainings.getModalities();
        setRowModalities(response);
    };

    const [rowResponsible, setRowResponsible] = useState([]);
    const handleGetResponsible = async () => {
        const response = await MyUsers.usersTrust();
        setRowResponsible(response);
    };

    const [rowCampus, setRowCampus] = useState([]);
    const handleGetCampus = async () => {
        const response = await MyCampus.campus();
        setRowCampus(response);
    };

    const [rowYears, setRowYears] = useState([]);
    const handleGetYears = async () => {
        const today = new Date();
        const year = today.getFullYear();
        const margin = year - 2021;
        let years = [];
        for (let i = 0; i < margin; i++) {
            years.push(year - i);
        }
        years.push(year + 1);
        const response = years.sort();
        setRowYears(response);
    };

    //CRUD functions
    const [rowTrainings, setRowTrainings] = useState({
        data: [],
        last_page: 1
    });

    const handleGetTrainings = async (page = 1) => {
        setLoading(true)
        const response = await MyTrainings.getTrainings({
            id_trainings_subjects: subjectFilter, 
            id_trainings_types: typeFilter, 
            id_trainings_statuses: statusFilter, 
            id_trainings_modalities: modalityFilter, 
            id_users_responsible: responsibleFilter, 
            id_campus: '',
            timestamp_start: '',
            timestamp_end: '', 
            year: yearFilter,
            page: page,
            size: 20
        });

        setRowTrainings({
            data: response.data.data ?? [],
            last_page: response.data.last_page
        });

        setCurrentPage(page);

        setLoading(false)
    };

    const handleCreateTrainings = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyTrainings.createTrainings({ id_trainings_subjects: idSubject, name: name });
        if (response.success === true) {
            handleCloseModalCreate();
            handleGetTrainings();
            handleOpenAlert(response.msg, 'success');
            navigate('/trainings/all/edit?id_trainings=' + response.id_trainings);
        } else {
            alert('Ocurrio un error');
            handleOpenAlert(response.msg, 'error');
        }

    };

    useEffect(() => {
        handleGetSubjects();
        handleGetTypes();
        handleGetStatuses();
        handleGetModalities();
        handleGetResponsible();
        handleGetCampus();
        handleGetYears();
        handleGetTrainings();
    }, []);

    const handleFilterSubmit = (e) => {
        e.preventDefault()
        handleGetTrainings();
        setOpenModalFilters(false)
    }

    return (
        <Box sx={{height: "100%" }}>
            <ReturnBack/>

            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>               
                        <Grid item xd={12} md={12}>
                            <ButtonMailboxControla
                              originUrl ={"/trainings/all"}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <BannerControla
                                image="/assets/images/Trainings.png"
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Todas las capacitaciones"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        sx={{ mt: .5 }}
                        direction="row"
                        alignItems="center"
                    >
                        <Grid item>
                            <ButtonControla
                                roles={permissions.CAPACIT_TODAS_CAPACIT_CREAR}
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Crear capacitación"}
                                functionOnClick={() => handleOpenModalCreate()}
                            />
                        </Grid>

                        <Grid item>
                            <ButtonControla
                                roles={permissions.CAPACIT_TODAS_CAPACIT_BUSCAR}
                                iconButton={<FilterAlt sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Buscar capacitación"}
                                functionOnClick={() => handleOpenModalFilters()}
                            />
                        </Grid>

                    </Grid>
                    <TableTrainings 
                        handleGetTrainings={handleGetTrainings}
                        rowTrainings={rowTrainings}
                        currentPage={currentPage}
                        loading={loading}
                    />
                </Box>
            </Container>

            <Dialog
                open={openModalCreate}
                onClose={() => handleCloseModalCreate()}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Agregar nueva Capacitación"
                    functionOnClose={() => handleCloseModalCreate()}
                />
                <form onSubmit={handleCreateTrainings}>
                    <DialogContent sx={{ paddingTop: "0" }}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectControla text={'Tema de capacitación:'}
                                    inputValue={setIdSubject} modalType={true} childrenRows={
                                        rowSubjects.map((row) => (
                                            <MenuItem key={row.id_trainings_subjects}
                                                value={row.id_trainings_subjects}
                                            >
                                                {row.subject}
                                            </MenuItem>
                                        ))
                                    } required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Nombre"}
                                    inputType={"text"}
                                    inputValue={setName}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            // color="success"
                            sx={{
                                textTransform: "none",
                                color: "#6969D6",
                                backgroundColor: "#CBCBFF",
                                borderRadius: "16px",
                                marginRight: "10px",
                            }}
                            onClick={() => handleCloseModalCreate()}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            sx={{
                                textTransform: "none",
                                // backgroundColor: '#169073',
                                borderRadius: "16px",
                                marginRight: "10px",
                            }}
                        >
                            Crear
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                open={openModalFilters}
                onClose={() => handleCloseModalFilters()}
                maxWidth="md"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Filtros de búsqueda"
                    functionOnClose={() => handleCloseModalFilters()}
                />

                <Filters 
                    onSubmit={handleFilterSubmit}
                    handleCloseModalFilters={handleCloseModalFilters}
                    subjectFilter={subjectFilter}
                    typeFilter={typeFilter}
                    modalityFilter={modalityFilter}
                    statusFilter={statusFilter}
                    yearFilter={yearFilter}
                    responsibleFilter={responsibleFilter}
                    setSubjectFilter={setSubjectFilter}
                    setTypeFilter={setTypeFilter}
                    setModalityFilter={setModalityFilter}
                    setStatusFilter={setStatusFilter}
                    setYearFilter={setYearFilter}
                    setResponsibleFilter={setResponsibleFilter}
                    rowsTypes={rowsTypes}
                    rowSubjects={rowSubjects}   
                    rowStatuses={rowStatuses}
                    rowModalities={rowModalities}
                    rowResponsible={rowResponsible}
                    rowYears={rowYears}
                />
            </Dialog>

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackdrop}
            >
                {/* <CircularProgress color="inherit" /> */}
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>
        </Box>
    );
}

export default Index;
