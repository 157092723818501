import {
    Avatar,
    Box,
    Card,
    CardContent, 
    Divider,
    Grid,
    Stack,
    Typography,
  } from "@mui/material";
  import React from "react";
  
  function CardUserControlaAccident({
    users_json = {},
    document = false,
    email = false,
    phone = false,
    sex = false,
    age = false,
    current_time = false,
    campus = false,
    job_type = false,
  }) {
    
    const yearActually = new Date().getFullYear();
    const monthActually = new Date().getMonth();
    const dayActually = new Date().getDate();
  
    const yearExperience = yearActually - users_json.start_date.substring(0,4);
    const monthExperience = monthActually - users_json.start_date.substring(6,7);
    const dayExperience = dayActually - users_json.start_date.substring(8,10);
    const birthdayYear = users_json.birthday.substring(0,4);
    const birthday = yearActually - birthdayYear;
  
    return (
      <Card
        sx={{
          width: "100%",
          height: "430px",
          padding: "0 important!",
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px 1px, rgba(0, 0, 0, 0.3) 0px 3px 7px 1px'
        }}
      >
        <Stack>
          <Box
            sx={{
              width: "100%",
              height:'90px',
              backgroundColor: "#233DD1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 2,
              position:'relative'
            }}
          >
            <Avatar
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                textAlign: "center",
                padding: 5,
                position:'absolute',
                top:24
              }}
            >
              {users_json.first_name !== undefined &&
              users_json.first_name != null &&
              users_json.last_name !== undefined &&
              users_json.last_name != null
                ? users_json.first_name.charAt(0) + "" + users_json.last_name.charAt(0)
                : ""}
            </Avatar>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 3,
            }}
          >
            <Typography
              sx={{
                display: document === false ? "none" : "",
                color: "#233DD1",
                fontSize: 18,
                fontWeight: "700",
              }}
            >
              {users_json.first_name + " " + users_json.last_name}
            </Typography>
            <Typography
              sx={{
                display: document === false ? "none" : "",
                color: "#233DD1",
                fontSize: 15,
                paddingBottom: 1,
              }}
            >
              {"Puesto de trabajo: " + users_json.position ?? '-'}
            </Typography>
          </Box>
        </Stack>
  
       
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} display={"flex"}>
              <Typography
                sx={{
                  display: document === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 15,
                  fontWeight: "700",
                }}
              >
                Documento:
              </Typography>
              <Typography
                sx={{
                  display: document === false ? "none" : "",
                  fontSize: 15,
                  color: "#1B34D5",           
                }}
              >
                {users_json.document ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"}>
              <Typography
                sx={{
                  display: phone === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 15,
                  fontWeight: "700",
                }}
              >
                N° celular:
              </Typography>
              <Typography
                sx={{
                  display: phone === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 15,
                }}
              >
                {users_json.phone ?? '-'}
              </Typography>
            </Grid>
  
            <Grid item xs={12} md={6} display={"flex"}>
              <Typography
                sx={{
                  display: sex === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 15,
                  fontWeight: "700",
                }}
              >
                Sexo:
              </Typography>
              <Typography
                sx={{
                  display: sex === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 15,             
                }}
              >
                {users_json.gender ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"}>
              <Typography
                sx={{
                  display: age === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 15,
                  fontWeight: "700",
                }}
              >
                Edad:
              </Typography>
              <Typography
                sx={{
                  display: age === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 15,               
                }}
              >
                {birthday ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"}>
              <Typography
                sx={{
                  display: email === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 15,
                  fontWeight: "700",
                }}
              >
                Email:
              </Typography>
              <Typography
                sx={{
                  display: email === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 15,             
                }}
              >
                {users_json.email ?? '-'}
              </Typography>
            </Grid>
            <Divider />    
            {/* <Grid item xs={12} md={12} display={"flex"}>
              <Typography
                sx={{
                  display: job_type === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 15,
                  fontWeight: "700",
                }}
              >
                Tipo de contrato:
              </Typography>
              <Typography
                sx={{
                  display: job_type === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 15,             
                }}
              >
                {users_json.users_contracts_types}
              </Typography>
            </Grid> */}
            <Grid item xs={12} md={12} display={"flex"}>
              <Typography
                sx={{
                  display: current_time === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 15,
                  fontWeight: "700",
                }}
              >
                Antigüedad en el empleo:
              </Typography>
              <Typography
                sx={{
                  display: current_time === false ? "none" : "",
                  color: "#1B34D5",
                  fontSize: 13.5,
              }}
              >
                {yearExperience + ' año(s) ' + monthExperience + ' mes(es) '+ dayExperience + ' dia(s)'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
  
  export default CardUserControlaAccident;
  