import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import { BorderColorOutlined, Delete } from "@mui/icons-material";

import { useAppContext } from "../../context/AppContext";

function ListAreas(props) {
    const { permissions } = useAppContext();
    const {
        rowsAreaReferenced,
        colorCell,
        handleOpenModalEdit,
        handleOpenModalConfirm

    } = props
    return (
        <TableBody>
            {rowsAreaReferenced.map((areas, key) => (
                <TableRow
                    hover
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    key={key}
                >
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {areas.area}
                    </TableCell>

                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                roles={permissions.CONF_AREA_EDITAR}
                                icon={
                                    <BorderColorOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                functionOnClick={() =>
                                    handleOpenModalEdit(
                                        areas.id_areas,
                                        areas.area
                                    )
                                }
                            />
                            {areas.referenced === true ? ' ' :
                                <ButtonIconControla
                                    roles={permissions.CONF_AREA_ELIMINAR}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() => {
                                        handleOpenModalConfirm(
                                            areas.id_areas,
                                            "¿Esta seguro de eliminar esta área?",
                                            'Se borrarán TODOS los cargos asignados a esta área. Una vez eliminada no se podrá recuperar. Se eliminará:  "' +
                                            areas.area +
                                            '"',
                                            "Si, Eliminar",
                                            "delete"
                                        );
                                    }}
                                />
                            }
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListAreas;