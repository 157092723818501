import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import MyConfigurationApi from "./MyConfigurationApi";

const firebaseConfigGeneral = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: "numeric-guide-311319.firebaseapp.com",
    projectId: "numeric-guide-311319",
    storageBucket: "controla-general",
    messagingSenderId: "583933811780",
    appId: process.env.FIREBASE_APP_ID,
    measurementId: "G-XJVVV9L6YP"
};

const firebaseConfigCompanies = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: "numeric-guide-311319.firebaseapp.com",
    projectId: "numeric-guide-311319",
    storageBucket: "controla-companies",
    messagingSenderId: "583933811780",
    appId: process.env.FIREBASE_APP_ID,
    measurementId: "G-XJVVV9L6YP"
};

const appGeneral = initializeApp(firebaseConfigGeneral, 'appGeneral');
const appCompanies = initializeApp(firebaseConfigCompanies);

export const storageGeneral = getStorage(appGeneral);
export const storageCompanies = getStorage(appCompanies);

export async function uploadFileGeneral(file, direction = undefined) {
    const timestamp = Date.now();
    const extension = file.name.split('.').pop();
    const storageRef = ref(storageGeneral, direction + timestamp.toString() + '.' + extension);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    console.log(url);
    return url;
}

export async function uploadFileCompanies(file, direction = null) {
    try {
        const timestamp = Date.now();
        const extension = file.name.split('.').pop();
        const storageRef = ref(storageCompanies, MyConfigurationApi.companyData().id_companies + '/' + direction + timestamp.toString() + '.' + extension);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        return error;
        
    }
   
}