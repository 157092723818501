import { React, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";

import SubtitleText from "../../../components/text/subtitleText";
import BannerControla from "../../../components/cards/bannerControla";
import ButtonControla from "../../../components/buttons/buttonController";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import SearchButtonControla from "../../../components/search/searchButtonControla";
import MyTasks from "../../../api/MyTasks";
import MyUsers from "../../../api/MyUsers";
import SearchUsersControla from "../../../components/search/searchUsersControla";
import SelectControlaRoles from "../../../components/selects/selectControlaRoles";
import SearchGeneralControla from "../../../components/search/searchGeneralControla";
import MyTasksNew from "../../../api/MyTasksNew";
import { useAppContext } from "../../../context/AppContext";
import TableControla from "../../../components/tables/table-controla";
import LoadingControla from "../../../components/load/loadingControla";
import ListWorkersTasks from "./components/list-workersTasks";
import ButtonMailboxControla from "../../../components/buttons/buttonMailboxControla";
import ReturnBack from "../../../components/buttons/returnBackControla";


const colorTitle = "#9191B0";
const purple = "#8B8BD8";


function WorkersTaks() {
    const { permissions } = useAppContext();

    const params = new URLSearchParams(window.location.search);

    const idStatusTask = params.get('filterStatus') == null ? 1 : params.get('filterStatus') ;

    //variables
    const [tasks, setTasks] = useState([]);
    const [idTask, setIdTask] = useState();
    const [idUser, setidUser] = useState();
    const [initialDate, setInitialDate] = useState();
    const [endDate, setEndDate] = useState();
    const [description, setDescription] = useState();
    const [title, setTitle] = useState();
    const [status, setStatus] = useState("1");

    const [taskStatus, setTaskStatus] = useState([]);

    // const taskStatus = [
    //     { id_status: 1, status: "Pendiente" },
    //     { id_status: 2, status: "En curso" },
    //     { id_status: 3, status: "Por validar" },
    //     { id_status: 3, status: "Aprobado" },
    //     { id_status: 3, status: "Anulado" },
    // ];

    const getTaksStatus = async () => {
        const resp = await MyTasksNew.getTasksStatus();
        setTaskStatus(resp);
    }

    //responsibles
    const [nameResponsibleInput, setNameResponsibleInput] = useState();
    const [idResponsible, setIdResponsible] = useState();

    //funciones
    const handleGetTasks = async () => {
        handleOpenLoading();

        const res = await MyTasksNew.getTasks();

        //mostrar tareas de acuerdo al estado
        const tasks = res.data.filter((t)=> t.id_status == idStatusTask)

        setStatus(idStatusTask);
        setTasks(tasks);

        setOpenLoading(false);
    };

    //NewTask
    const handleNewTask = async (event) => {
        event.preventDefault();
        handleOpenLoading();

        const data = {
            date_start: initialDate,
            date_end: endDate,
            description: description,
            id_status: 1,
            id_users: idResponsible,
            id_tasks_types: 6,
            title: title,
            origin_url: null
        }
        const resp = await MyTasksNew.postTasks(data);
        if (resp.success == true) {
            handleGetTasks();
            handleOpenAlert("Se creó la tarea con éxito", "success");

        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }

        setOpenDialogAddTask(false);

    };


    //EditTask

    const handleEditTask = async (event) => {
        event.preventDefault();
        handleOpenLoading();

        const response = await MyTasks.editTask({
            idTask: idTask,
            initialDate: initialDate,
            description: description,
            idResponsible: idResponsible,
            endDate: endDate,
            title: title,
        });

        setOpenDialogEditTask(false);

        handleGetTasks();
        handleOpenAlert("Se actualizó la tarea con éxito", "success");
    };

    const handleDeleteTask = async (idTask) => {
        handleOpenLoading();

        const response = await MyTasks.deleteTask({
            idTask: idTask,
        });

        setOpenDialogDeleteTask(false);
        handleGetTasks();
        handleOpenAlert("Se eliminó la tarea con éxito", "success");

    };

    const handleFinishedTask = async () => {
        const response = await MyTasks.taskStatusUpdate({
            idTask: idTask,
            status: 2,
            endDate: endDate,
        });
    };

    const handleGetTaskByUser = async () => {
        const response = await MyTasks.taskByUser({ idUser: idUser });
    };
    // Obtener usuarios Activos
    const [rowsUsersSearchs, setRowsUsersSearchs] = useState([]);
    const handleGetUsersSearchsAddsWorkers = async () => {
        const response = await MyUsers.users();

        setRowsUsersSearchs(response.users_actives);
    };

    // buscar usuario por ID y setear sus datos

    const handleSelectUsersResponsible = async (idUsers) => {
        handleOpenLoading();

        setIdResponsible(idUsers);

        const response = await MyUsers.editUsers({ idUsers: idUsers });
        if (response.success !== false) {
            setNameResponsibleInput(response.first_name + " " + response.last_name);
            setIdResponsible(response.id_users);
            setOpenDialogResponsible(false);
            handleOpenAlert("Responsable seleccionado con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    };

    // updateStatus

    const handleUpdateTaskStatus = async ({ idTask, status }) => {
        handleOpenLoading();

        const response = await MyTasks.taskStatusUpdate({
            idTask: idTask,
            status: status,
        });

        handleGetTasks();
        handleOpenAlert("Se actualizó estado de la tarea con éxito", "success");
    };
    //modales

    //añadir una tarea
    const [openDialogAddTask, setOpenDialogAddTask] = useState(false);

    const handleOpenDialogAddTask = () => {
        setInitialDate("");
        setEndDate("");
        setDescription("");
        setIdResponsible("");
        setTitle("");
        setNameResponsibleInput(null);
        setOpenDialogAddTask(true);
    };
    //agregar responsable
    const [openDialogResponsible, setOpenDialogResponsible] = useState(false);

    const handleOpenDialogResponsible = () => {
        setOpenDialogResponsible(true);
    };
    //editarTareas
    const [openDialogEditTask, setOpenDialogEditTask] = useState(false);

    const handleOpenDialogEditTask = ({
        idTask,
        initialDate,
        endDate,
        description,
        idResponsible,
        title,
        nameUser
    }) => {
        setIdTask(idTask);
        setInitialDate(initialDate);
        setEndDate(endDate);
        setDescription(description);
        setIdResponsible(idResponsible);
        setTitle(title);
        setOpenDialogEditTask(true);

        setNameResponsibleInput(nameUser);
    };

    ///Eliminar tarea
    const [openDialogDeleteTask, setOpenDialogDeleteTask] = useState(false);

    const handleOpenDialogDeleteTask = ({ deleteTask }) => {
        setIdTask(deleteTask);
        setOpenDialogDeleteTask(true);
    };


    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    //fin de alerts


    const [searchedText, setSearchedText] = useState("");

    const handleSearchTask = async (text) => {
        handleOpenLoading();

        setSearchedText(text);
        const respTask = await MyTasksNew.getTasks();
        const tasksBySelectedStatus = respTask.data.filter((t) => t.id_status == status);

        let expresion = new RegExp(`${text}.*`, "i");

        let taskSearch = tasksBySelectedStatus.filter((task) => expresion.test(task.description));
        setTasks(taskSearch);
        if (taskSearch.length !== 0) {
            handleOpenAlert("Se listó las tareas buscadas con éxito", "success");
        } else {
            handleOpenAlert("No se encontró tareas para la búsqueda", "success");
        }

    }

    const handleSearchStatusTasks = async (event) => {
        handleOpenLoading();

        setStatus(event.target.value);
        const respTask = await MyTasksNew.getTasks();

        let expresion = new RegExp(`${searchedText}.*`, "i");

        let taskSearch = respTask.data.filter((task) => expresion.test(task.description));

        setTasks(taskSearch.filter((t) => t.id_status === event.target.value));

        handleOpenAlert("Se listó tareas buscadas con éxito", "success");
    }

    useEffect(() => {
        handleGetTasks();
        handleGetUsersSearchsAddsWorkers();
        getTaksStatus();

    }, []);


    return (
        <Box >
            <ReturnBack/>

            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ButtonMailboxControla
                                originUrl={"/activities/task/taksWorkers"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={7} xl={12}>
                            <SubtitleText
                                text={"Actividades"}
                                color={colorTitle}
                            ></SubtitleText>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <BannerControla
                                image={
                                    "/assets/images/banners/configuration/improvactionsbaselinebanner.png"
                                }
                                text={"Tareas de los trabajadores"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={12}>
                            <Stack direction={"row"} justifyContent="space-between" >
                                <Box>
                                    <ButtonControla
                                        roles={permissions.ACTIVIDADES_TAREAS_DE_TRABAJADORES_AGREGAR}
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#1cb591"}
                                        backgroundColorHover={"#169073"}
                                        textButton={"Agregar tarea"}
                                        functionOnClick={handleOpenDialogAddTask}
                                    />
                                </Box>
                                <Box>
                                    <SearchGeneralControla
                                        placeholder={"Buscar por descripción...."}
                                        handleActionOnChange={(event) => { handleSearchTask(event.target.value) }}
                                    />
                                </Box>
                                <Box>
                                    <SelectControlaRoles
                                        inputValue={setStatus}
                                        value={status}
                                        childrenRows={taskStatus.map((rows) => (
                                            <MenuItem
                                                key={rows.id_tasks_status}
                                                value={rows.id_tasks_status}
                                            >
                                                {rows.status}
                                            </MenuItem>
                                        ))}
                                        onChange={
                                            (event) => {
                                                handleSearchStatusTasks(event)
                                            }

                                        }
                                    />
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <TableControla
                                loading={openLoading}
                                tabs={[
                                    {
                                        columnNames: ["Título", "Descripción", "Fecha de inicio", "Fecha de vencimiento", "Responsable", "Tipo", "Estado", "Acciones"]
                                    }]
                                }
                            >
                                <ListWorkersTasks
                                    tasks={tasks}
                                    colorCell={purple}
                                    handleUpdateTaskStatus={handleUpdateTaskStatus}
                                    handleOpenDialogEditTask={handleOpenDialogEditTask}
                                    handleOpenDialogDeleteTask={handleOpenDialogDeleteTask}
                                    setStatus={setStatus}
                                    taskStatus={taskStatus}
                                />

                            </TableControla>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            {/* Dialog agregar tareas */}
            <Dialog
                open={openDialogAddTask}
                onClose={() => setOpenDialogAddTask(false)}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitle>
                    <Typography
                        id="modal-modal-title"
                        sx={{
                            color: "#1638F2",
                            fontWeight: "bold",
                            fontSize: "22px",
                        }}
                    >
                        Agregar tarea
                    </Typography>
                </DialogTitle>
                <form onSubmit={handleNewTask}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Título"}
                                    modalType={true}
                                    inputType={"textArea"}
                                    inputValue={setTitle}
                                    value={title}
                                    required
                                ></InputControlaVertical>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Fecha de inicio"}
                                    inputType={"date"}
                                    inputValue={setInitialDate}
                                    value={initialDate}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Fecha de fin"}
                                    inputType={"date"}
                                    inputValue={setEndDate}
                                    value={endDate}
                                    modalType={true}
                                // required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Descripción"}
                                    inputType={"textArea"}
                                    inputValue={setDescription}
                                    value={description}
                                    modalType={true}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SearchButtonControla
                                    titleLabel="Responsable: "
                                    inputValue={nameResponsibleInput}
                                    functionClick={handleOpenDialogResponsible}
                                    modalType={true}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            sx={{
                                textTransform: "none",
                                color: "#6969D6",
                                backgroundColor: "#CBCBFF",
                                borderRadius: "16px",
                                marginRight: "10px",
                            }}
                            onClick={() => setOpenDialogAddTask(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{
                                textTransform: "none",
                                color: "white",
                                backgroundColor: "#169073",
                                borderRadius: "16px",
                                marginRight: "10px",
                            }}
                        >
                            Agregar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            {/* Dialog Editar tareas */}
            <Dialog
                open={openDialogEditTask}
                onClose={() => setOpenDialogEditTask(false)}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitle>
                    <Typography
                        id="modal-modal-title"
                        sx={{
                            color: "#1638F2",
                            fontWeight: "bold",
                            fontSize: "22px",
                        }}
                    >
                        Editar tarea
                    </Typography>
                </DialogTitle>
                <form onSubmit={handleEditTask}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Título"}
                                    modalType={true}
                                    inputType={"textArea"}
                                    inputValue={setTitle}
                                    value={title}
                                    required
                                ></InputControlaVertical>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Fecha de inicio"}
                                    inputType={"date"}
                                    inputValue={setInitialDate}
                                    value={initialDate}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Fecha de fin"}
                                    inputType={"date"}
                                    inputValue={setEndDate}
                                    value={endDate}
                                    modalType={true}
                                // required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Descripción"}
                                    inputType={"textArea"}
                                    inputValue={setDescription}
                                    value={description}
                                    modalType={true}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SearchButtonControla
                                    titleLabel="Responsable: "
                                    inputValue={nameResponsibleInput}
                                    functionClick={handleOpenDialogResponsible}
                                    modalType={true}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            sx={{
                                textTransform: "none",
                                color: "#6969D6",
                                backgroundColor: "#CBCBFF",
                                borderRadius: "16px",
                                marginRight: "10px",
                            }}
                            onClick={() => setOpenDialogEditTask(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{
                                textTransform: "none",
                                color: "white",
                                backgroundColor: "#169073",
                                borderRadius: "16px",
                                marginRight: "10px",
                            }}
                        >
                            Guardar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            {/* Añadir Responsable */}
            <Dialog
                open={openDialogResponsible}
                onClose={() => {
                    setOpenDialogResponsible(false);
                }}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitle>
                    <Typography
                        id="modal-modal-title"
                        sx={{
                            color: "#1638F2",
                            fontWeight: "bold",
                            fontSize: "22px",
                        }}
                    >
                        Seleccionar Responsable
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ paddingTop: "0" }}>
                    <SearchUsersControla
                        rowsUser={rowsUsersSearchs}
                        handleUsersActionButton={handleSelectUsersResponsible}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenDialogResponsible(false);
                        }}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Eliminar */}
            <Dialog
                open={openDialogDeleteTask}
                onClose={() => {
                    setOpenDialogDeleteTask(false);
                }}
                maxWidth="xs"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitle>
                    <Typography
                        id="modal-modal-title"
                        sx={{
                            color: "#1638F2",
                            fontWeight: "bold",
                            fontSize: "22px",
                        }}
                    >
                        Eliminar tarea
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ paddingTop: "0" }}>
                    ¿Está seguro de eliminar esta tarea?
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenDialogDeleteTask(false);
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            handleDeleteTask(idTask);
                        }}
                    >
                        Sí, eliminar.
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );

}

export default WorkersTaks;
