import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import {
    Delete,
    Publish
  } from "@mui/icons-material";
  import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useAppContext } from "../../../context/AppContext";

function ListActives(props) {
    const { permissions } = useAppContext();

    const {
        securitySheet,
        isworker,
        handleOpenModalConfirm
    } = props

    return (
    <TableBody>
        {securitySheet.map((row) => (
            <TableRow
                key={row.id}
                sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    padding: "4px",
                }}
            >
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.register_status === 1
                        ? "Creado"
                        : row.register_status === 4 ?
                            "Publicado" : ''
                    }
                </TableCell>

                <TableCell align="right">
                    <Stack
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                    >
                        <ButtonIconControla
                            roles={isworker && row.register_status == 4 ? permissions.DOC_HOJAS_SEGURIDAD_VER : permissions.DOC_HOJAS_SEGURIDAD_AGREGAR_MSDS}
                            icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                            }
                            backgroundColor={"#305AD9"}
                            backgroundColorHover={"#0E4AFF"}
                            textTooltip={"Ver"}
                            directionUrl={row.file}
                            directionTarget={"_blank"}
                        />
                        {row.register_status === 1 ? (
                            <ButtonIconControla
                                roles={permissions.DOC_HOJAS_SEGURIDAD_PUBLICAR}
                                icon={<Publish sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1AA483"}
                                textTooltip={"Publicar"}
                                functionOnClick={() => {
                                    handleOpenModalConfirm(
                                        row.id,
                                        "¿Esta seguro de publicar?",
                                        'Una vez publicado no podrá revertir la acción del documento:  "' +
                                        row.name +
                                        '"',
                                        "Si, publicar",
                                        "public"
                                    );
                                }}
                            />
                        ) : (
                            <></>
                        )}
                        {/* {row.register_status === 4 ? (
                  <ButtonIconControla
                    roles={[1, 7, 8]}
                    icon={<Archive sx={{ color: "white" }} />}
                    backgroundColor={"#F2994A"}
                    backgroundColorHover={"#FF881E"}
                    textTooltip={"Archivar"}
                    functionOnClick={() =>
                      handleOpenModalConfirm(
                        row.id,
                        "¿Esta seguro de archivar el documento?",
                        'Documento a archivar:  "' +
                          row.name +
                          '"',
                        "Si, archivar",
                        "archived"
                      )
                    }
                  />
                ) : (
                  ""
                )} */}
                        {/* {row.register_status === 5 ? (
                  <ButtonIconControla
                    roles={[1, 7, 8]}
                    icon={<Unarchive sx={{ color: "white" }} />}
                    backgroundColor={"#FF5733"}
                    backgroundColorHover={"#FF5733"}
                    textTooltip={"Desarchivar"}
                    functionOnClick={() =>
                      handleOpenModalConfirm(
                        row.id,
                        "¿Esta seguro de desarchivar el documento?",
                        'Documento a desarchivar:  "' +
                          row.name +
                          '"',
                        "Si, desarchivar",
                        "unarchived"
                      )
                    }
                  />
                ) : (
                  ""
                )} */}
                        {row.register_status !== 5 ? (
                            <ButtonIconControla
                                roles={permissions.DOC_HOJAS_SEGURIDAD_ELIMINAR}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                    handleOpenModalConfirm(
                                        row.id,
                                        "¿Esta seguro de eliminar los datos del documento?",
                                        "Una vez eliminado no se podra recuperar los datos de " +
                                        row.name,
                                        "Si, Eliminar",
                                        "delete"
                                    );
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </Stack>
                </TableCell>
            </TableRow>
        ))}
    </TableBody>
)

}
export default ListActives