import { React, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ButtonControla from "../components/buttons/buttonController.jsx";
import ButtonIconControla from "../components/buttons/buttonIconControla.jsx";
import DialogTitleControla from "../components/dialog/dialogTitleControla.jsx";
import DialogConfirmControla from "../components/dialog/dialogConfirmControla.jsx";
import SelectControla from "../components/selects/selectControla.jsx";
import DialogInformativeControla from "../components/dialog/dialogInformativeControla.jsx";
import Paper from "@mui/material/Paper";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Card,
    CardContent,
    CardMedia,
    Grid,
    InputLabel,
    Typography,
    Stack,
    MenuItem,
    Backdrop,
    Snackbar,
    Alert,
} from "@mui/material";
import {
    Add,
    Delete
} from "@mui/icons-material";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from 'react-router-dom';
import MyMemberships from "../api/MyMemberships.jsx";
const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em",
});

function SubModuleByModule() {
    const navigate = useNavigate();
    const location = useLocation();
    let paramsInter = location.state;

    const title = paramsInter.name;
    const idConfigMemberships =  paramsInter.id_config_membership;
    const idConfigSection= paramsInter.id_config_section;
    const idConfigModule = paramsInter.id_config_module;

    //backdrop

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    }
    //
    //Message Alert
    const [stateAlert, setStateAlert] = useState({
        openAlert: false,
        vertical: "top",
        horizontal: "center",
        severityAlert: "success",
        messageAlert: "",
    });

    const { severityAlert, messageAlert, vertical, horizontal, openAlert } = stateAlert;

    const handleOpenAlert = (severityAlert, messageAlert) => {
        setStateAlert({
            openAlert: true,
            vertical: "top",
            horizontal: "right",
            severityAlert: severityAlert,
            messageAlert: messageAlert,
        });
    }

    const handleCloseAlert = () => {
        setStateAlert({ ...stateAlert, openAlert: false });
    }
    //modal de confirmación

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText]= useState("");
    const [contentText, setContentText]= useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [idConfigurationSection, setIdConfigurationSection] = useState("");
    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm, idConfigurationSection) => {
        setOpenModalConfirm(true);

        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setIdConfigurationSection(idConfigurationSection);

    }
    const handleCloseModalConfirm = ()=>{
        setOpenModalConfirm(false);
    }

    //modal de información
    const [openModalInformative, setOpenModalInformative] = useState(false);
    const [messageModal , setMessageModal]= useState("");

    const handleCloseModalInformative = ()=>{
        setOpenModalInformative(false);
    }
    //


    const [subModulesByModule, setSubModulesByModule] = useState([]);
    const [subModules, setSubModules] = useState([]);
    const [subModuleSelected, setSubModuleSelected] = useState("");
    const [statusSelected, setStatusSelected] = useState("");
    const status = [{ "id_status": 1, "name": "desactivado" }, { "id_status": 2, "name": "activado" }];


    const getModules = async () => {
        const res = await MyMemberships.getModulesGalleryByParent(idConfigModule);
        setSubModules(res.data);
    }

    const [openModalModules, setOpenModalModules] = useState(false);
    const handleOpenModalModules = () => {
        setOpenModalModules(true);

        // limpiar
        setSubModuleSelected("");
        setStatusSelected("");

    }
    const handleCloseModalModules = () => {
        setOpenModalModules(false);
    }

    const getSubModulesByModule = async () => {
        setOpenBackdrop(true);
        const r = await MyMemberships.getSubModulesByModule(idConfigSection);

        handleCloseBackdrop();
        setSubModulesByModule(r.subModules)

    }

    const handlePostSubmodule = async (event) => {
        event.preventDefault();

        setOpenBackdrop(true);
        //verificar si el submodulo existe en modulo
        const r = await MyMemberships.getSubModulesByModule(idConfigSection);
        const submoduleExists = r.subModules.filter(submod => submod.id_configuration_modules === subModuleSelected);
 
        if (submoduleExists.length === 0) {
            const data = {
                "id_config_memberships": idConfigMemberships,
                "id_configuration_modules": subModuleSelected,
                "active": statusSelected,
                "parent_id": idConfigSection,
            }

            const res = await MyMemberships.postModuleBySection(data);

            if (res.success === true) {
                getSubModulesByModule();
                handleOpenAlert("success", "Se agregó el submódulo con éxito");
            } else {
                handleOpenAlert("error", "Ocurrió un error");
            }

        } else {
            setOpenModalInformative(true);
            setMessageModal("El submódulo ya existe en el módulo del sistema");
        }

        handleCloseModalModules()
        handleCloseBackdrop();
    }

    const handleSearchSubmodule = async (subModule) => {
        setOpenBackdrop(true);

        const res = await MyMemberships.getModulesGalleryByParent(subModule.id_configuration_modules)

        if (res.data.length === 0) {
            setOpenModalInformative(true);
            setMessageModal("No existe galeria de submódulos en el sistema")
        } else {
            const params = {
                id_config_membership:idConfigMemberships,
                id_config_section: subModule.id_config_section,
                id_config_module: subModule.id_configuration_modules,
                name: subModule.title
            }

            navigate("/submodulesByModule",{state:params});
        }
        handleCloseBackdrop();
    }

    const handleDeleteSubmodule = async()=>{
        setOpenBackdrop(true);
        const res  = await MyMemberships.deleteModuleBySection(idConfigurationSection);
        if(res.success === true){
            getSubModulesByModule();
            handleOpenAlert("success", "Se eliminó el módulo con éxito");
        } else{
            handleOpenAlert("error", "Ocurrió un error");
        }
         handleCloseBackdrop();
         handleCloseModalConfirm();
    }


    useEffect(() => {
        getModules();
        getSubModulesByModule();

    }, [location.state]);

    return (
        <Box sx={{ display: "flex", height: "100%" }}>

            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleDeleteSubmodule}
            />
            {/* componente Alert */}
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={3000}
            >
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleCloseAlert();
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity={severityAlert}
                    size="small"
                >
                    {messageAlert}
                </Alert>
            </Snackbar>

            <Container>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>

                    <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{ marginTop: 3, marginBottom: 1 }}
                    >
                        <Box>
                            <InputLabel
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Módulo - {title}
                            </InputLabel>
                        </Box>

                        <Box>
                            <ButtonControla
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Agregar submódulo"}
                                functionOnClick={handleOpenModalModules}
                            />
                        </Box>
                    </Stack>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Paper sx={{ overflow: "hidden" }}>
                            {
                                subModulesByModule.length === 0 ? (
                                    <StyledTypography
                                        sx={{
                                            padding: "8px",
                                            fontSize: "18px",
                                            color: "#305AD9",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        No contiene módulos.
                                    </StyledTypography>

                                ) : ""
                            }
                            <Grid
                                container
                                spacing={2}
                                sx={{ width: "100%", margin: "auto", display: "flex", flexDirection: 'row', flexWrap: 'wrap', columnGap: '80px', rowGap: "2px" }}
                            >
                                {subModulesByModule.map((subModule, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}
                                        key={index} sx={{ p: 2 }}>
                                        <Card
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                height: 100,
                                                width: 350,

                                                "&:hover": {
                                                    cursor: "pointer",
                                                    border: "1px solid #0F1255",
                                                    boxShadow: "0px 4px #0F1255",
                                                },
                                            }}
                                            onClick={() => { handleSearchSubmodule(subModule) }}
                                        > 
                                            <Box
                                                sx={{ display: "flex", flexDirection: "column" }}
                                            >
                                                <CardContent sx={{ flex: "1 0 auto", display: "flex", alignItems: 'center', textAlign: "center" }}>
                                                    <Typography sx={{ fontSize: "14px" }}>
                                                        {subModule.title}
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                            <CardMedia
                                                component="img"
                                                sx={{
                                                    maxWidth: 100,
                                                    marginLeft: 0,
                                                    maxHeight: 100,
                                                    objectFit: "contain",
                                                }}
                                                image={subModule.image}
                                                alt="Live from space album cover"
                                            />
                                            <Box
                                                sx={{ display: "flex", flexDirection: "row", gap: "3px", width: "auto" }}
                                            >
                                                <CardContent sx={{ flex: "1 0 auto", display: "flex", flexDirection: "row", gap: "3px", alignItems: 'center' }}>
                                                    <ButtonIconControla
                                                        icon={<Delete sx={{ color: "white" }} />}
                                                        backgroundColor={"#EB5757"}
                                                        backgroundColorHover={"#FF4040"}
                                                        textTooltip={"Eliminar"}
                                                        functionOnClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpenModalConfirm(
                                                                "¿Estás seguro de eliminar este módulo?",
                                                                "",
                                                                "Sí, eliminar",
                                                                subModule.id_config_section
                                                            )
                                                        }}
                                                    />
                                                </CardContent>
                                            </Box>

                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            </Container>

            {/* modal agregar modulos */}
            <Dialog
                open={openModalModules}
                onClose={handleCloseModalModules}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitleControla
                    titleText="Agregar módulos a sección"
                    functionOnClose={handleCloseModalModules}
                />

                <form onSubmit={handlePostSubmodule}>
                    <DialogContent>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SelectControla
                                text="Módulos:"
                                inputValue={setSubModuleSelected}
                                modalType={true}
                                required
                                value={subModuleSelected || ''}
                                childrenRows={subModules.map((rows) => (
                                    <MenuItem key={rows.id_configuration_modules} value={rows.id_configuration_modules}>
                                        {rows.title}
                                    </MenuItem>
                                ))}
                            />

                            <SelectControla
                                text="Estado:"
                                inputValue={setStatusSelected}
                                modalType={true}
                                required
                                value={statusSelected || ''}
                                childrenRows={status.map((rows) => (
                                    <MenuItem key={rows.id_status} value={rows.id_status}>
                                        {rows.name}
                                    </MenuItem>
                                ))}
                            />
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={handleCloseModalModules}
                                />

                            </Box>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={"Guardar"}
                                    typeButton={"submit"}
                                />
                            </Box>
                        </Stack>

                    </DialogActions>

                </form>


            </Dialog>

            {/* modal de informaciòn */}
            <DialogInformativeControla
            open={openModalInformative}
            onClose={handleCloseModalInformative}
            message={messageModal}
            />

            {/* mostrar loading  */}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 9999,
                }}
                open={openBackdrop}
            >
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>

        </Box>
    );

}

export default SubModuleByModule;