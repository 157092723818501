import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  EditOutlined,
  InfoOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import MyRegistersCompetencyCertificate from "../../../../api/MyRegistersCompetencyCertificate";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import { useAppContext } from "../../../../context/AppContext";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";
const skyblue = "#2D9CDB";

function Edit() {
  const {permissions} = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const idRegistersCertificate = params.get("idRegistersCertificate");

  //Styled
  const StyledAddButton = styled(Button)({
    textTransform: "none",
    backgroundColor: skyblue,
    borderRadius: 16,
  });
  
  const StyledModalSubtitle = styled("h3")({
    fontWeight: "bold",
  });

  // BACKDROP
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  //variables
  const [code, setCode] = useState("");
 
  // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(true);


  // users
  const [usersActives, setUsersActives] = useState([]);
  const handleGetUsersActives = async () => {
    const response = await MyUsers.users();
    if (response.success === false) {
      alert("Error");
    } else {
      setUsersActives(response.users_actives);
    }
  };
  

  ////Certificados
  ////Variables
  const [workerName, setWorkerName] = useState(0);
  const [examType, setExamType] = useState(["Pre-ocupacional", "Periódico"]);
  const [examDate, setExamDate] = useState("");
  const [result, setResult] = useState("");
  const [certificate, setCertificate] = useState("");
  const [observations, setObservations] = useState("");
  const [examNextDate, setExamNextDate] = useState("");
  const [workerGender, setWorkerGender] = useState("");
  const [workerDocument, setWorkerDocument] = useState("");
  const [idUser, setIdUser] = useState("");

  const [examTypeList, setExamTypeList] = useState([
    "Pre-ocupacional",
    "Periódico",
    "de Retiro",
    "Por cambios de ocupación o puesto de trabajo",
    "Por reincorporación laboral",
    "Por contratos temporales de corta duracion",
    "Otros(complementarios, generales, específicos, auxiliares)",
  ]);

  const [resultTypeList, setResultTypeList] = useState([
    "Apto",
    "Apto con restricciones",
    "No apto",
    "Observado",
  ]);

  ////Funciones

  const handleEditRegisterCertificate = async () => {
    setOpenBackdrop(!openBackdrop);

    const response =
      await MyRegistersCompetencyCertificate.editRegisterCertificate({
        idRegister: idRegistersCertificate,
      });

    await handleGetUsersInspectionResponsible(response.id_users);

    setCode(response.code);
    setWorkerName(response.worker);
    setExamType(response.exam_type);
    setExamDate(response.exam_date);
    setResult(response.result);
    setCertificate(response.certificate);
    setObservations(response.observations);
    setExamNextDate(response.next_exam_date);
    setIdUser(response.id_users);
    setDocument(response.document);
    setDocumentUrl(response.document_url);
    if (response.register_status === 1) {
      setRegisterStatus(false);
    } else {
      setRegisterStatus(true);
    }

    handleCloseBackdrop();
  };

  const handleUpdateRegisterCertificate = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyRegistersCompetencyCertificate.updateRegisterCertificate({
        idRegister: idRegistersCertificate,
        code: code,
        workerName: workerName,
        examType: examType,
        examDate: examDate,
        result: result,
        certificate: certificate,
        observations: observations,
        nextExamDate: examNextDate,
        workerDocument: workerDocument,
        workerGender: workerGender,
        idUser: idUser,
        documentName: documentName,
        documentUrl: documentUrl,
      });
    handleEditRegisterCertificate();
    handleClickAlert("success", "Se actualizó el documento correctamente");
    return response;
  };

  
  ///
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const handleOpenModalInfo = () => {
    setOpenModalInfo(true);
  };

  const handleCloseModalInfo = () => {
    setOpenModalInfo(false);
  };
  ///

  const [openModalAddFile, setOpenModalAddFile] = useState(false);
  const handleOpenModalAddFile = () => {
    setOpenModalAddFile(true);
  };
  const handleCloseModalAddFile = () => {
    setOpenModalAddFile(false);
  };

  const [documentName, setDocumentName] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [document, setDocument] = useState("");

  const handleUploadCertificateFile = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);

    const response =
      await MyRegistersCompetencyCertificate.UploadCertificateFile({
        idRegister: idRegistersCertificate,
        document: document,
        documentName: documentName,
      });
    handleCloseBackdrop();
    handleCloseModalAddFile();
    handleClickAlert("success", "Se subió el documento correctamente");
    handleEditRegisterCertificate();
  };

  ////

  const [openModalCertificateWorker, setOpenModalCertificateWorker] =
    useState(false);

  const handleOpenModalCertificateWorker = () => {
    setOpenModalCertificateWorker(true);
  };

  // jalar y mostrar datos del usuario preparado

  const [selectedWorker, setSelectedWorker] = useState("");

  const handleGetUsersInspectionResponsible = async (idUser) => {
    setOpenBackdrop(!openBackdrop);

    if(idUser !== ""){
      const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setSelectedWorker(response.first_name + " " + response.last_name);
      setIdUser(response.id_users);
      setOpenModalCertificateWorker(false);
    }

    }
    handleCloseBackdrop();
  };


  useEffect(() => {
    handleEditRegisterCertificate();
    handleGetUsersActives();
  }, []);
  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      {/* <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      /> */}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={8} sm={8} md={7} lg={7} xl={7}>
              <SubtitleText text={"Registro"} color={colorTitle}></SubtitleText>
              <TitleText
                text={"Registro de certificados de aptitud"}
              ></TitleText>
            </Grid>
            <Grid xs={8} sm={8} md={3} lg={3} xl={3}>
              <StyledAddButton
                variant="text"
                startIcon={<InfoOutlined />}
                disableElevation
                size="large"
                style={{
                  backgroundColor: "#305AD9",
                  borderRadius: "15px",
                  color: "white",
                  padding: "8px 25px",
                }}
                onClick={() => handleOpenModalInfo()}
              >
                Tipo de examen
              </StyledAddButton>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <InputControlaVertical
                text={"Nro registro"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
                readOnly={registerStatus}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={4} xl={4} />
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUpdateRegisterCertificate}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <SearchButtonControla
                  titleLabel="Trabajador "
                  inputValue={selectedWorker}
                  functionClick={handleOpenModalCertificateWorker}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <SelectControla
                  text={"Tipo de exámen"}
                  value={examType}
                  inputValue={setExamType}
                  modalType={false}
                  readOnly={registerStatus}
                  childrenRows={examTypeList.map((type, key) => (
                    <MenuItem key={key} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  inputType={"date"}
                  text={"Fecha de último examen"}
                  value={examDate}
                  inputValue={setExamDate}
                  modalType={false}
                  readOnly={registerStatus}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <SelectControla
                  text={"Resultado"}
                  value={result}
                  inputValue={setResult}
                  modalType={false}
                  readOnly={registerStatus}
                  childrenRows={resultTypeList.map((type, key) => (
                    <MenuItem key={key} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {"Certificado"}{" "}
                </InputLabel>
              </Grid>
              <Grid item>
                <ButtonControla
                  textButton={"Adjuntar archivo"}
                  backgroundColor={"#838282"}
                  functionOnClick={() => handleOpenModalAddFile()}
                />
              </Grid>
              <Grid item>
                <ButtonIconControla
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl}
                  directionTarget={"_blank"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Observaciones"}
                  inputType={"textArea"}
                  inputValue={setObservations}
                  value={observations}
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Fecha del próximo examen"}
                  inputType={"date"}
                  inputValue={setExamNextDate}
                  value={examNextDate}
                  readOnly={registerStatus}
                />
              </Grid>
            </Grid>

            {/* tabla para mostrar accidents workers */}

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                paddingY: 4,
              }}
            >
              {/* cerrado temporalmente */}
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                />
              </Grid> */}
              <Grid item xs={6} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={"/documentation/registers/generals/competencycertificate"}
                />
              </Grid>
              {registerStatus === false && (
                <Grid item xs={6} md={2}>
                  <ButtonControla
                    roles = {permissions.DOC_REGIS_CERTIF_APTITUD_EXAM_MEDICO_ACTUALIZAR}
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton="submit"
                  />
                </Grid>
              )}
              {/* {registerStatus === false ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    functionOnClick={handleCloseRegisterCertificate}
                  />
                </Grid>
              ) : (
                ""
              )} */}
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Info */}
      <Dialog
        open={openModalInfo}
        onClose={handleCloseModalInfo}
        maxWidth="lg"
        fullWidth={true}
        sx={{
          padding: "20px",
        }}
      >
        <DialogTitleControla titleText="Tipo de Examen" functionOnClose ={handleCloseModalInfo} />
        <DialogContent>
          <Grid container>
            <Grid item md={12}>
              <StyledModalSubtitle>Pre-ocupacional:</StyledModalSubtitle>
            </Grid>
            <Grid item>
              Es la evaluación médica que tiene por objetivo determinar el
              estado de salud al momento del ingreso, y su aptitud al puesto de
              trabajo.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>Periódico:</StyledModalSubtitle>
            </Grid>
            <Grid item>
              Se realiza con el fin de monitorear la exposición a factores de
              riesgo e identificar en forma precoz, posibles alteraciones
              temporales, permanentes o agravadas del estado de salud del
              trabajador, que se asocien al puesto de trabajo y los estados pre
              patogénicos.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>De retiro:</StyledModalSubtitle>
            </Grid>
            <Grid item>
              Evaluación médica realizada al trabajador respecto de su estado y
              condición de salud días previos al cese laboral. Mediante este
              examen se busca detectar enfermedades relacionadas al trabajo,
              secuelas de accidentes de trabajo y en general lo agravado por el
              trabajo.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                Por cambios de ocupación o puesto de trabajo:
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Esta evaluación se realiza al trabajador cada vez que éste cambie
              de ocupación y/o de puesto de trabajo, de funciones, tareas o
              exposición a nuevos o mayores factores de riesgo, en los que se
              detecte un incremento de su magnitud, intensidad o frecuencia.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                Por reincorporación laboral:
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Evaluación que se realiza posterior a incapacidad temporal
              prolongada.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                Por contratos temporales de corta duración:
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Esta evaluación se realiza al trabajador para determinar el estado
              de salud al momento del ingreso, y su aptitud al puesto de
              trabajo. Se puede solicitar copia de los Exámenes Médico
              Ocupacionales con 3 meses de antigüedad (previa autorización del
              trabajador) al Servicio de Salud Ocupacional que atendió al
              trabajador por última vez.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                Otros (complementarios generales, específicos, auxiliares):
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Están enfocados a determinar el estado de salud basal del
              trabajador desde su evaluación pre-ocupacional y los cambios que
              ayuden a detectar de manera precoz la presencia de una patología
              asociada al trabajo o los estados pre patológicos.
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalInfo}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      {/* Dialog subir archivo */}
      <Dialog
        open={openModalAddFile}
        onClose={() => setOpenModalAddFile(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Certificado{" "}
          </Typography>
        </DialogTitle>

        <form onSubmit={handleUploadCertificateFile}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre del documento"}
                  inputType={"text"}
                  inputValue={setDocumentName}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalAddFile(false)}
            >
              Cancelar
            </Button>
            <Button
              // type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalAddFile}
            >
              Subir certificado
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={openModalCertificateWorker}
        onClose={() => {
          setOpenModalCertificateWorker(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Trabajador
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersInspectionResponsible}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalCertificateWorker(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Edit;
