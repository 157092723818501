import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  PictureAsPdf,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import styled from "styled-components";
import LoadingControla from "../../../../components/load/loadingControla";
import MySecurityEmergencyEquipment from "../../../../api/MySecurityEmergencyEquipment";
import MyAreas from "../../../../api/MyAreas";
import { useAppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import ButtonSignatureControla from "../../../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";
const blue = "#034AFF";
const red = "#EB5757";
const StyledTypography = styled(Typography)({
  color: "#305AD9",
  fontSize: 16,
  fontWeight: 600,
  gap: "2%",
  padding: "0.5em",
});

function Edit() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const idRegistersSecurityEmergencyEquipment = params.get("idRegistersSecurityEmergencyEquipment");

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdEquipment(id);
    setIdWorkerInvolved(id);
    setIdResponsibleWorker(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "deleteEquipment":
        handleDeleteEquipment();
        break;
      case "deleteWorkers":
        handleDeleteWorkerInvolved();
        break;
      case "deleteResponsible":
        handleDeleteResponsibleWorker();
        break;
      case "close":
        handleGetRegisterStatus();
        break;
    }
    setOpenModalConfirm(false);
  };
  // Dialogos de confirmacion FIN

  // REGISTRO DE EQUIPOS DE SEGURIDAD

  // variables del form general
  const [code, setCode] = useState("");
  const [typeEquipment, setTypeEquipment] = useState('');

  // select tipo de equipo
  const [rowsTypeEquipments, setRowsTypeEquipment] = useState([]);
  const handleTypeEquipment = async () => {
    const response = await MySecurityEmergencyEquipment.getTypeEquipment();

    if (response.success === false) {
    } else {
      setRowsTypeEquipment(response);
    }
    return response;
  };

  // update register
  const handleUpdateRegistersSecurityEmergencyEquipment = async () => {
    handleOpenLoading();
    const response =
      await MySecurityEmergencyEquipment.updateRegistersSecurityEmergencyEquipment(
        {
          idRegistersSecurityEmergencyEquipment,
          code: code,
          id_registers_security_emergency_equipment_type: typeEquipment,
          register_status: 1
        }
      );
    if (response.success === false) {
      handleOpenAlert("Ocurrió un error al crear el modelo", "error");
    } else {
      handleOpenAlert("Los datos se actualizaron con éxito", "success");
    }
    return response;
  };

  // edit register
  const handleEditRegistersSecurityEmergencyEquipment = async () => {
    handleOpenLoading()
    const response =
      await MySecurityEmergencyEquipment.editRegistersSecurityEmergencyEquipment(
        {
          idRegistersSecurityEmergencyEquipment,
        }
      );
    if (response.success === false) {
      handleOpenAlert("Ocurrió un error al traer datos", "error");
    } else {
      handleOpenAlert("", "success");
      setRegisterStatus(response.register_status === 1 ? true : false);
      setCode(response.code);
      setTypeEquipment(response.id_registers_security_emergency_equipment_type);
    }
    return response;
  };

  // EQUIPOS

  // Agregar equipos
  const [openModalCreateEquipment, setOpenModalCreateEquipment] =
    useState(false);

  const hanldeOpenModalCreateEquipment = () => {
    setNameEquipment(null);
    setDescriptionEquipment(null);
    setOpenModalCreateEquipment(true);
  };

  const [nameEquipment, setNameEquipment] = useState("");
  const [descriptionEquipment, setDescriptionEquipment] = useState("");

  const handleCreateEquipment = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MySecurityEmergencyEquipment.createEquipment({
      idRegistersSecurityEmergencyEquipment,
      name: nameEquipment,
      description: descriptionEquipment,
    });
    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      handleGetEquipments();
      setOpenModalCreateEquipment(false);
      handleOpenAlert("Se ha realizado la operación con éxito", "success");
    }

    return response;
  };

  // mostrar
  const [rowEquipments, setRowEquipment] = useState([]);
  const handleGetEquipments = async () => {
    const response = await MySecurityEmergencyEquipment.getEquipments({
      idRegistersSecurityEmergencyEquipment,
    });
    if (response.success === false) {
    } else {
      setRowEquipment(response);
    }
    return response;
  };

  // eliminar
  const [idEquipment, setIdEquipment] = useState();

  const handleDeleteEquipment = async () => {
    handleOpenLoading();
    const response = await MySecurityEmergencyEquipment.deleteWEquipment({
      idEquipment: idEquipment,
    });
    if (response.success === true) {
      await handleGetEquipments();
      handleOpenAlert("Se ha eliminado el equipo con éxito", "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  // USUARIOS ACTIVOS
  const [rowsUsersSearchsWorkers, setRowsUsersSearchsWorkers] = useState([]);
  const [rowsUsersSearchsResponsibles, setRowsUsersSearchsResponsibles] = useState([]);

  const [usersActives, setUsersActives] = useState([]);
  const handleGetUsers = async () => {
    const response = await MyUsers.users();
    setUsersActives(response.users_actives);
  }
  const handleGetUsersSearchsAddsWorkers = async () => {
    let users = usersActives;
    workersInvolved.forEach((user) => {
      users = users.filter((u) => u.id_users !== user.id_users);
    })

    setRowsUsersSearchsWorkers(users);
  };
  const handleGetUsersSearchsAddResponsibles = () => {
    let users = usersActives;
    responsibleWorkers.forEach((user) => {
      users = users.filter((u) => u.id_users !== user.id_users);
    });

    setRowsUsersSearchsResponsibles(users);
  }

  // buscar usuario por ID y setear sus datos
  const [nameResponsibleInput, setResponsibleInput] = useState(null);
  const [responsibleDocumentInput, setResponsibleDocumentInput] =
    useState(null);
  const handleSelectUsersResponsible = async (idUsers) => {
    handleOpenLoading();

    setIdResponsible(idUsers);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setResponsibleInput(response.first_name + " " + response.last_name);
      setResponsibleDocumentInput(response.document);
      handleOpenAlert(response.msg, "success");

      setOpenModalInvolvedWorkersResponsible(false);
    } else {
    }
  };


  //*************************  TRABAJADOR INVOLUCRADO *******************************************
  const [workersInvolved, setWorkersInvolved] = useState([]);

  const [areaWorkerInvolved, setAreaWorkerInvolved] = useState(0);
  const [rowsAreaWorkerInvolved, setRowsAreaWorkerInvolved] = useState([]);

  const [dateDelivery, setDateDelivery] = useState("");
  const [dateRenewal, setDateRenewal] = useState("");
  const [idResponsible, setIdResponsible] = useState(" ");

  //mostrar las áreas desde configuración
  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setRowsAreaWorkerInvolved(response);
    return response;
  };

  // agregar datos del trabajador DIALOG
  const [openModalWorkerInvolved, setOpenModalWorkerInvolved] = useState(false);
  const handleOpenModalWorkerInvolved = async () => {
    setAreaWorkerInvolved(null);
    setResponsibleInput("");
    setDateDelivery("");
    setDateRenewal("");
    await handleGetUsersSearchsAddsWorkers();
    setOpenModalWorkerInvolved(true);
  };

  // dialog para buscar el responsable de trabajador involucrado
  const [
    openModalInvolvedWorkersResponsible,
    setOpenModalInvolvedWorkersResponsible,
  ] = useState(false);
  const handleOpenModalResponsibleInvolvedWorkers = () => {
    setResponsibleInput("");
    setOpenModalInvolvedWorkersResponsible(true);
  };

  // crear trabajador involucrado
  const handleCreateWorkersInvolved = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MySecurityEmergencyEquipment.createWorkersInvolved({
      idRegistersSecurityEmergencyEquipment,
      area: areaWorkerInvolved,
      date_renewal: dateRenewal,
      date_delivery: dateDelivery,
      id_users: idResponsible,
      nameResponsibleInput: nameResponsibleInput,
      responsibleDocumentInput: responsibleDocumentInput,
    });

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      await handleGetWorkersInvolved();
      setOpenModalWorkerInvolved(false);
      handleOpenAlert(
        "Se ha agregado al trabajador involucrado con éxito",
        "success"
      );
    }
    return response;
  };

  // // mostrar trabajadores involucrado
  const handleGetWorkersInvolved = async () => {
    const response = await MySecurityEmergencyEquipment.getWorkersInvolved({
      idRegistersSecurityEmergencyEquipment:
        idRegistersSecurityEmergencyEquipment,
    });
    if (response.success !== false) {
      setWorkersInvolved(response);
    } else {
    }
  };

  // // eliminar trabajadores involucrado
  const [idWorkerInvolved, setIdWorkerInvolved] = useState();

  const handleDeleteWorkerInvolved = async () => {
    handleOpenLoading();
    const response = await MySecurityEmergencyEquipment.deleteWorkerInvolved({
      idWorkerInvolved: idWorkerInvolved,
    });
    if (response.success === true) {
      await handleGetWorkersInvolved();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  // firmar trabajador involucrado
  const handleSignWorkerInvolved = async (idRegister, idUser) => {
    handleOpenLoading();

    try {
      const response =
        await MySecurityEmergencyEquipment.updateSignWorkerInvolved({
          idResponsible: idRegister,
          id_users: idUser,
        });

      await handleGetWorkersInvolved();
      handleOpenAlert("Trabajador firmó con éxito", "success")
    } catch (e) {
      handleOpenAlert("Ocurrió un error al firmar", "error");
    }
  };

  //*************************  RESPONSABLES  *******************************************
  const [openModalResponsibleWorker, setOpenModalResponsibleWorker] =
    useState(false);

  const handleOpenModalResponsibleWorker = async () => {
    await handleGetUsersSearchsAddResponsibles();
    setOpenModalResponsibleWorker(true);
  };

  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);

  const handleGetResponsibleWorkers = async () => {
    const response = await MySecurityEmergencyEquipment.getResponsibleWorkers({
      idRegistersSecurityEmergencyEquipment:
        idRegistersSecurityEmergencyEquipment,
    });
    setResponsibleActions(response);
  };

  // //crear trabajadores responsables
  const handleStoreResponsibleWorkers = async (idUsers) => {
    handleOpenLoading();
    const response =
      await MySecurityEmergencyEquipment.createResponsibleWorkers({
        idRegistersSecurityEmergencyEquipment:
          idRegistersSecurityEmergencyEquipment,
        id_users: idUsers,
      });
    handleGetResponsibleWorkers();
    setOpenModalResponsibleWorker(false);
    handleOpenAlert("Se agregó de forma correcta el responsable", "success");
    return response;
  };
  const [idResponsibleWorker, setIdResponsibleWorker] = useState(0);

  const handleDeleteResponsibleWorker = async () => {
    const response = await MySecurityEmergencyEquipment.deleteResponsibleWorker(
      {
        idResponsible: idResponsibleWorker,
      }
    );
    await handleGetResponsibleWorkers();
    return response;
  };

  // // firmar responsable

  const handleSignResponsibleWorker = async (idRegister, idUser) => {
    handleOpenLoading();
    try {
      const response =
        await MySecurityEmergencyEquipment.updateResponsibleWorkers({
          idResponsible: idRegister,
          id_users: idUser,
        });

      await handleGetResponsibleWorkers();
      handleOpenAlert("Responsable firmó con éxito", "success");
    } catch (e) {
      handleOpenAlert("Ocurrió un error al firmar", "error");
    }
  };

  // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(1);
  const handleGetRegisterStatus = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    if (
      responsibleWorkers.length >= 1 &&
      rowEquipments.length >= 1 &&
      workersInvolved.length >= 1
    ) {
      const finded = responsibleWorkers.find((item) => item.firm === 0);
      const findedWorkers = workersInvolved.find((item) => item.firm === 0);

      if (finded === undefined && findedWorkers === undefined) {
        handleOpenLoading();
        const response =
          await MySecurityEmergencyEquipment.updateRegistersSecurityEmergencyEquipment(
            {
              idRegistersSecurityEmergencyEquipment,
              code: code,
              id_registers_security_emergency_equipment_type: typeEquipment,
              register_status: 2
            }
          );
        handleOpenAlert("El registro se cerró de forma correcta", "success");
        navigate(`/documentation/registers/generals/securityemergencyequipment/edit?idRegistersSecurityEmergencyEquipment=${idRegistersSecurityEmergencyEquipment}&readOnly=true`)
        await handleEditRegistersSecurityEmergencyEquipment();
        return response;
      } else {
        handleOpenAlert("Firmas pendientes", "error");
      }
    } else {
      handleOpenAlert("Complete las secciones obligatorias(Trabajador involucrado, equipos y trabajador responsable)", "error");
    }
  };

  useEffect(() => {
    handleTypeEquipment();
    handleEditRegistersSecurityEmergencyEquipment();
    handleGetEquipments();
    handleGetWorkersInvolved();

    handleGetResponsibleWorkers();
    handleGetUsers();

    // jalar áreas
    handleGetAreas();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={"Registro de equipos de seguridad o emergencia"}
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleGetRegisterStatus}>
            {/* tabla para mostrar accidents workers */}
            <Grid container sx={{ marginTop: "4px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  roles={permissions.DOC_REGIS_EQUIPO_SEGURIDAD_EDITAR}
                  text={"Nro registro"}
                  inputType={"text"}
                  inputValue={setCode}
                  value={code}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  roles={permissions.DOC_REGIS_EQUIPO_SEGURIDAD_EDITAR}
                  text="Seleccione tipo de equipo:"
                  inputValue={setTypeEquipment}
                  modalType={false}
                  required
                  value={typeEquipment}
                  childrenRows={rowsTypeEquipments.map((row) => (
                    <MenuItem key={row.id} value={row.id} >
                      {row.registers_security_emergency_equipment_type}
                    </MenuItem>
                  ))}
                  disabled={registerStatus ? false : true}
                />
              </Grid>
              {/* Equipos de proteccion personal */}
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                sx={{ marginTop: "8px" }}
              >
                <StyledTypography
                  sx={{
                    padding: "8px",
                    fontSize: "20px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Nombre(s) del(los) equipo(s) de seguridad o emergencia
                  entregado
                </StyledTypography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                textAlign="right"
                sx={{ marginTop: "8px" }}
              >
                {registerStatus && (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_EQUIPO_SEGURIDAD_AGREGAR_EQUIP}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar Equipo"}
                    functionOnClick={hanldeOpenModalCreateEquipment}
                  />
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Descripción
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rowEquipments.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.description}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {registerStatus && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del equipo de seguridad?",
                                    "Una vez eliminado no se podra recuperar los datos del equipo de seguridad",
                                    "Si, Eliminar",
                                    "deleteEquipment"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Datos de los trabajadores
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                textAlign="right"
                mt={3}
              >
                {registerStatus && (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_EQUIPO_SEGURIDAD_AGREGAR_DATOS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={handleOpenModalWorkerInvolved}
                  />
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Documento de Identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Área
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Fecha de entrega
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Fecha de renovación
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Firma
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Fecha de firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {workersInvolved.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row.name_user_responsible}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.document_user_responsible}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.area}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.date_delivery}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.date_renewal}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <ButtonSignatureControla
                            idUser={row.id_users}
                            firmStatus={row.firm}
                            firmUrl={row.users_firm_url}
                            functionOnClick={async () => {
                              await handleSignWorkerInvolved(row.id, row.id_users)
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.firm_date === undefined
                            ? ""
                            : row.firm_date.substring(0, 10) +
                            " " +
                            row.firm_date.substring(11, 16)}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {registerStatus && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador " +
                                    row.name_user_responsible,
                                    "Si, Eliminar",
                                    "deleteWorkers"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus && (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_EQUIPO_SEGURIDAD_AGREGAR_RESPON}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={handleOpenModalResponsibleWorker}
                  />
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Cargo
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.position_name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.firm_date === null || row.firm_date === undefined
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <ButtonSignatureControla
                            idUser={row.id_users}
                            firmStatus={row.firm}
                            firmUrl={row.firm_url}
                            functionOnClick={async () => {
                              await handleSignResponsibleWorker(row.id, row.id_users)
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus && (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador responsable",
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              {/* cerrado temporalmente */}
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                />
              </Grid> */}
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={"/documentation/registers/generals/securityemergencyequipment"}
                />
              </Grid>
              {registerStatus && (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={permissions.DOC_REGIS_EQUIPO_SEGURIDAD_ACTUALIZAR_REGIS}
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    functionOnClick={handleUpdateRegistersSecurityEmergencyEquipment}
                  />
                </Grid>
              )}
              {registerStatus && (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={permissions.DOC_REGIS_EQUIPO_SEGURIDAD_CERRAR_REGIS}
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    typeButton="submit"
                  />
                </Grid>
              )}
              {registerStatus === false && (
                <Grid item xs={12} md={3}>
                  <ButtonControla
                    iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                    backgroundColor={"#F2994A"}
                    backgroundColorHover={"#FF881E"}
                    textButton={"Ver documento PDF"}
                    typeButton={"button"}
                    url={process.env.REACT_APP_API_DJANGO + 'report/' + idRegistersSecurityEmergencyEquipment}
                    target={"_blank"}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog agregar equipo */}
      <Dialog
        open={openModalCreateEquipment}
        onClose={() => setOpenModalCreateEquipment(false)}
        maxWidth="xs"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Equipo de seguridad
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateEquipment}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre:"}
                  inputType={"text"}
                  inputValue={setNameEquipment}
                  value={nameEquipment}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción:"}
                  inputType={"textArea"}
                  inputValue={setDescriptionEquipment}
                  value={descriptionEquipment}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalCreateEquipment(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog agregar trabajador involucrado */}
      <Dialog
        open={openModalWorkerInvolved}
        onClose={() => setOpenModalWorkerInvolved(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar trabajador involucrado
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateWorkersInvolved}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Área:"
                  inputValue={setAreaWorkerInvolved}
                  modalType={true}
                  required
                  value={areaWorkerInvolved}
                  childrenRows={rowsAreaWorkerInvolved.map((rows) => (
                    <MenuItem key={rows.id_areas} value={rows.id_areas}>
                      {rows.area}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de entrega: "}
                  inputType={"date"}
                  inputValue={setDateDelivery}
                  value={dateDelivery}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de renovación: "}
                  inputType={"date"}
                  inputValue={setDateRenewal}
                  value={dateRenewal}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Trabajador involucrado: "
                  inputValue={nameResponsibleInput}
                  functionClick={handleOpenModalResponsibleInvolvedWorkers}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalWorkerInvolved(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables DEL TRABAJADOR INVOLUCRADO */}
      <Dialog
        open={openModalInvolvedWorkersResponsible}
        onClose={() => {
          setOpenModalInvolvedWorkersResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsUsersSearchsWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalInvolvedWorkersResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalResponsibleWorker}
        onClose={() => setOpenModalResponsibleWorker(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsUsersSearchsResponsibles}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => setOpenModalResponsibleWorker(false)}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

export default Edit;
