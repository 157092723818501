import { Grid, IconButton, Tooltip } from "@mui/material";
import { React } from "react";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import { validationRoles } from "../../utils/validation_rol";
import { useNavigate } from "react-router-dom";

function ButtonIconControla({
  icon,
  backgroundColor,
  textTooltip,
  functionOnClick,
  backgroundColorHover = undefined,
  isNavegationOrigin= false,
  directionUrl = "",
  paramsIntUrl={},
  directionTarget = "",
  roles = [],
  textIcon='',

}) {
  const navigate = useNavigate();
  const showButton = validationRoles(roles)

  return showButton && (
    <Grid
      container
      sx={{
        maxWidth: "65px",
      }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Tooltip title={textTooltip} placement="top">
          <IconButton
            sx={{
              backgroundColor: { backgroundColor },
              "&:hover": {
                backgroundColor:
                  backgroundColorHover === undefined
                    ? backgroundColor
                    : backgroundColorHover,
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 6px 12px 1px, rgba(0, 0, 0, 0.3) 0px 3px 7px 1px",
              },
            }}
            onClick={isNavegationOrigin? ()=>{ navigate(directionUrl,{state:paramsIntUrl})} : functionOnClick}
            href={isNavegationOrigin? null : directionUrl}
            target={directionTarget}
            texticon={textIcon}
          >
            {icon}
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default ButtonIconControla;
