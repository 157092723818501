// colocar lo de Workaccidents.jsx
import * as React from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import BannerControla from "../../../../components/cards/bannerControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";

import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import MyMypeTracking from "../../../../api/MyMypeTracking";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";

function Index() {
  const navigate = useNavigate();
  const { permissions } = useAppContext();

  const [code, setCode] = React.useState("");

  const [rowsActives, setRowsActives] = React.useState([]);
  const [openModalUpload, setOpenModalUpload] = React.useState(false);

  const handleOpenModalUpload = () => {
    setCode(null);
    setOpenModalUpload(true);
  };

  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
  };

  const hadleGetRegisters = async () => {
    const response = await MyMypeTracking.getRegisters();
    if (response.success === false) {
    } else {
      setRowsActives(response);
    }
    return response;
  };

  const handleCreateRegisters = async (event) => {
    event.preventDefault();
    const response = await MyMypeTracking.createRegisters({ code });
    handleCloseModalUpload();
    const params = {
      readOnly: false
    }

    navigate('/documentation/registers/mypes/tracking/edit?idRegistersMypeTracking=' + response.id, { state: params })
    return response;
  };

  React.useEffect(() => {
    hadleGetRegisters();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <ButtonMailboxControla
              originUrl={"/documentation/registers/mypes/tracking"}
            />

            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/bannerseguimiento.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Seguimiento"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                roles={permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_CREAR}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Crear nuevo registro"}
                functionOnClick={handleOpenModalUpload}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid item md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "50%",
                          }}
                        >
                          Código
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "30%",
                          }}
                        >
                          Estado
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {rowsActives.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "4px",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.code}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.register_status === 1 ? 'Creado' : 'Finalizado'}
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {row.register_status === 1 && (
                              <ButtonIconControla
                                roles={permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_EDIT}
                                icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                isNavegationOrigin={true}
                                directionUrl={'/documentation/registers/mypes/tracking/edit?idRegistersMypeTracking=' + row.id}
                              />)}

                              {row.register_status === 2 && (
                                <ButtonIconControla
                                  roles={permissions.DOC_REGISMYPES_SEGUIMIENTO_SSO_VER}
                                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  isNavegationOrigin={true}
                                  directionUrl={'/documentation/registers/mypes/tracking/edit?idRegistersMypeTracking=' + row.id +"&readOnly=true"}
                                  // paramsIntUrl={{ readOnly: true }}
                                />)}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalUpload}
        onClose={handleCloseModalUpload}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar código del registro de seguimiento
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateRegisters}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <InputControlaVertical
              text={"Código"}
              inputType={"text"}
              inputValue={setCode}
              modalType={true}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUpload}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}

            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>



    </Box>
  );
}

export default Index;
