import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import {
    Delete,
    VisibilityOutlined,
} from "@mui/icons-material";

import { useAppContext } from "../../../context/AppContext";

function ListLegalMatrix(props) {
    const {permissions} = useAppContext();

    const {
        legalMatrix,
        colorCell,
        handleOpenModalConfirm

    } = props

    return (
        <TableBody>
            {legalMatrix.map((row) => (
                <TableRow
                    key={row.id}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        padding: "4px",
                    }}
                >
                    <TableCell component="th" scope="row" sx={{ color: colorCell }}>
                        {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ color: colorCell }}>
                        {row.register_status === 1 ? 'Creado' : 'Finalizado'}
                    </TableCell>

                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                roles={permissions.DOC_MATRIZ_LEGAL_VER} icon={ <VisibilityOutlined sx={{ color: "white" }} />}
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={row.file}
                                directionTarget={"_blank"}
                            />
                            <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                roles={permissions.DOC_MATRIZ_LEGAL_ELIMINAR}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                    handleOpenModalConfirm(
                                        row.id,
                                        "¿Esta seguro de eliminar los datos de la matriz legal?",
                                        "Una vez eliminado no se podra recuperar los datos de la matriz legal " + row.name,
                                        "Si, Eliminar",
                                        "deleteLegalMatrix"
                                    );
                                }}
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListLegalMatrix;