import { React, useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Card, CardContent, FormControl, FormControlLabel, Grid, IconButton, LinearProgress, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import SubtitleText from '../components/text/subtitleText';
import TitleText from '../components/text/titleText';
import MyBaseline from '../api/MyBaseline';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import ButtonControla from '../components/buttons/buttonController';
import ReturnBack from '../components/buttons/returnBackControla';
import LoadingControla from '../components/load/loadingControla';

const grey = "#9191B0";
const blue = "#034AFF";

const StyledBorderLinearProgress = styled(LinearProgress)({
    height: 10,
    borderRadius: 5,
});

const StyledPercentageText = styled(Typography)({
    color: "9191B0",
    fontSize: 12
});

function BaselineAnswersTwo() {

    const params = new URLSearchParams(window.location.search);
    const id_baseline_lineaments = params.get('id_baseline_companies_lineaments');
    const id_baseline = params.get('id_baseline');

    //loading
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //

    const [pageNumber, setPageNumber] = useState(0);

    const defaultPercentage = {
        percentage: 0,
        lineament: 'Lineamiento',
        id_baseline: 0
    }

    const [indicatorSelectedPage, setIndicatorSelectedPage] = useState(1);
    const [baselineQuestions, setBaselineQuestions] = useState([]);

    const [completionPercentage, setCompletionPercentage] = useState(() => defaultPercentage);

    const handleGetBaselineQuestions = async () => {
        handleOpenLoading();
        const response = await MyBaseline.companyIndicators({ idBaselineCompaniesLineaments: id_baseline_lineaments });
        setBaselineQuestions(response);
        setPageNumber(response.length);

        setOpenLoading(false);
    }

    const handleGetCompletionPercentage = async () => {
        const response = await MyBaseline.completionLineamentPercentage({ idLineaments: id_baseline_lineaments });
        setCompletionPercentage(response);
    }

    const handleUpdateQuestionAnswer = async (idIndicators, answer) => {
        const response = await MyBaseline.updateCompanyAnswers({ idIndicators, answer });
        handleGetCompletionPercentage();
    }

    const handleUpdateQuestionComment = async (event, idBaselineCompaniesLineaments) => {
        if (event.target.value !== '') {
            const response = await MyBaseline.updateBaselineEditComment({ idBaselineCompaniesLineaments, comment: event.target.value });
        } else {
            console.log('vacio')
        }
    }

    useEffect(() => {
        handleGetBaselineQuestions();
        handleGetCompletionPercentage();
    }, []);

    return (
        <>
            <Box sx={{ height: '100%' }}>
                <ReturnBack returnBack={"/baseline/answers?id_baseline=" + id_baseline} />
                <LoadingControla
                    openLoading={openLoading}
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                    messageAlert={messageAlert}
                    typeAlert={typeAlert}
                />

                <Container>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={3} columnSpacing={3}>
                            <Grid item md={12}>
                                <SubtitleText
                                    text={"Línea base"}
                                    color={grey}>
                                </SubtitleText>
                                <TitleText
                                    text={"Respuestas de línea base"}>
                                </TitleText>
                            </Grid>
                            <Grid item md={7}>
                                <Box sx={{ width: '100%' }}>
                                    <StyledBorderLinearProgress variant="determinate" color={
                                        completionPercentage.percentage <= 39
                                            ? "error"
                                            : completionPercentage.percentage <= 99 &&
                                                completionPercentage.percentage > 39
                                                ? "warning"
                                                : "success"
                                    } value={completionPercentage.percentage} />
                                </Box>
                            </Grid>
                            <Grid item md={1}>
                                <StyledPercentageText>{completionPercentage.percentage + '%'}</StyledPercentageText>
                            </Grid>
                            <Grid item md={4}>
                                {completionPercentage.percentage === 100 ? <ButtonControla
                                    textButton={"Continuar"}
                                    variant="contained"
                                    isNavegationOrigin={true}
                                    url={'/baseline/answers?id_baseline=' + completionPercentage.id_baseline}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                >
                                </ButtonControla> : ""}

                            </Grid>
                            <Grid item md={8}>
                                <TitleText text={completionPercentage.lineament[0] + completionPercentage.lineament.slice(1).toLowerCase()} />
                            </Grid>
                            <Grid item xs={2} sm={1} md={4} lg={4} xl={4}>
                                <Stack
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                    spacing={2}>
                                    <IconButton
                                        sx={{ backgroundColor: 'white', borderRadius: '15px', color: '#305AD9' }}
                                        onClick={() => setIndicatorSelectedPage(indicatorSelectedPage - 1 === 0 ? 1 : indicatorSelectedPage - 1)}
                                    >
                                        <ArrowBackIosNew />
                                    </IconButton>
                                    {baselineQuestions.map((baselinePage, key) => (<IconButton key={key}
                                        onClick={() => setIndicatorSelectedPage((parseInt(Object.keys(baselinePage))))}
                                        sx={{
                                            backgroundColor: parseInt(Object.keys(baselinePage)) === indicatorSelectedPage ? '#305AD9' : 'white',
                                            borderRadius: '15px',
                                            color: parseInt(Object.keys(baselinePage)) === indicatorSelectedPage ? 'white' : '#305AD9'
                                        }}
                                    >
                                        {Object.keys(baselinePage)}
                                    </IconButton>))}
                                    <IconButton
                                        sx={{ backgroundColor: 'white', borderRadius: '15px', color: '#305AD9' }}
                                        onClick={() => setIndicatorSelectedPage(indicatorSelectedPage + 1 > pageNumber ? pageNumber : indicatorSelectedPage + 1)}
                                    >
                                        <ArrowForwardIos />
                                    </IconButton>
                                </Stack>
                            </Grid>

                            <Grid container item justifyContent="center" alignItems="center" paddingBottom="40px">
                                {baselineQuestions.map((baselinePage) => (
                                    <Grid container key={Object.keys(baselinePage)} spacing={3}
                                        sx={{
                                            display: (indicatorSelectedPage === parseInt(Object.keys(baselinePage)) ? '' : 'none')
                                        }}
                                    >
                                        {(Object.values(baselinePage)[0]).map((page) => (
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={page.id_baseline_companies_indicators} >

                                                <Card sx={{
                                                    maxWidth: "100%",
                                                    borderRadius: "14px",
                                                    height: 320,
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                    alignContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                >
                                                    <CardContent
                                                        sx={{
                                                            width: '100%',
                                                            textAlign: 'center',
                                                            height: '100%'
                                                        }}>
                                                        <Grid container justifyContent="center" alignItems="center">
                                                            <Grid item md={12} sx={{ height: "150px" }}>
                                                                <Typography color={blue} fontSize={15} fontWeight={600}
                                                                    sx={{
                                                                        userSelect: 'none'
                                                                    }}>
                                                                    {page.questions}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item md={10} sx={{ height: "70px" }}>
                                                                <FormControl>
                                                                    <Stack direction={'row'}
                                                                        alignItems="center"
                                                                        justifyContent="space-between"
                                                                        sx={{ width: '100%' }}>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            name="radio group"
                                                                            defaultValue={page.answer}
                                                                            row
                                                                            onChange={(event) => handleUpdateQuestionAnswer(page.id_baseline_companies_indicators, event.target.value)}
                                                                        >
                                                                            <FormControlLabel value="Si" control={<Radio />} label="Sí" labelPlacement="top" />
                                                                            <FormControlLabel value="No" control={<Radio />} label="No" labelPlacement="top" />
                                                                            <FormControlLabel value="No aplica" control={<Radio />} label="No aplica" labelPlacement="top" />
                                                                        </RadioGroup>
                                                                    </Stack>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={12} >
                                                                <TextField label="Comentario" variant="outlined" sx={{
                                                                    width:
                                                                        "100%"
                                                                }} defaultValue={page.comment} onBlur={(event) => { handleUpdateQuestionComment(event, page.id_baseline_companies_indicators) }} />
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box >
        </>
    );
}

export default BaselineAnswersTwo