import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
    Grid,
    InputLabel,
    Typography,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogContent,
    DialogActions,
    MenuItem,
} from "@mui/material";
import {
    Add,
    ArrowBack,
    Delete,
    BorderColorOutlined,
    VisibilityOutlined,
    PictureAsPdf,
} from "@mui/icons-material";

import CloseIcon from "@mui/icons-material/Close";
import SubtitleText from "../../../../components/text/subtitleText";
import TitleText from "../../../../components/text/titleText";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import LoadingControla from "../../../../components/load/loadingControla";
import SelectControla from "../../../../components/selects/selectControla";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import InputControlaVerticalIcon from "../../../../components/textfields/inputControlaVerticalIcon";
import { useEffect, useState } from "react";
import MyEvaluationSSO from "../../../../api/MyEvaluationSSO";
import MyUsers from "../../../../api/MyUsers";
import MyAreas from "../../../../api/MyAreas";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import { useAppContext } from "../../../../context/AppContext";
import MyTasksNew from "../../../../api/MyTasksNew";
import ButtonSignatureControla from "../../../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";
const blue = "#034AFF";

function Edit() {
    const { permissions } = useAppContext();
    const params = new URLSearchParams(window.location.search);

    const idEvaluation = parseInt(params.get("id_evaluation"))

    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    // Pantallas de carga y alertas FIN

    const [code, setCode] = useState("");
    const [conformityNumber, setConformityNumber] = useState("");
    const [documentUrl1, setDocumentUrl1] = useState(null);
    const [documentUrl2, setDocumentUrl2] = useState(null);
    const [statusRegister, setStatusRegister] = useState("");



    const getEvaluationSSOById = async () => {
        handleOpenLoading();
        try {
            const res = await MyEvaluationSSO.getEvaluationSSOById(idEvaluation) ?? [];

            setStatusRegister(res.register_status);
            setCode(res.nro_register);
            setConformityNumber(res.nro_nonconformities);
            setDocumentUrl1(res.document_url_audit_report);
            setDocumentUrl2(res.document_url_action_plan)

            handleOpenAlert("Se listó la evaluación con éxito", "success");

        } catch (e) {
            handleOpenAlert("Problemas al listar la evaluación", "error");
        }
    }

    const handleUpdatedEvaluation = async () => {
        handleOpenLoading();

        try {
            const data = {
                nro_register: code,
                nro_nonconformities: conformityNumber,
                document_url_audit_report: documentUrl1,
                document_url_action_plan: documentUrl2,
            }

            const res = await MyEvaluationSSO.updatedEvalutionById(data, idEvaluation);
            handleOpenAlert("Se actualizó la evaluación con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleCloseEvaluation = async () => {
        if (rowsResponsibleRegister.length >= 1 ) {
            const finded = rowsResponsibleRegister.find((item) => item.firm === 0);

            if (finded === undefined) {
                const data = {
                    register_status: 2
                }

                const resp = await MyEvaluationSSO.updateStatusEvaluationById(data, idEvaluation);

                if (!resp) {
                    alert("Error");
                } else {
                    await getEvaluationSSOById();
                }
                return resp;
            } else {
                handleOpenAlert("Registre firmas pendientes", "error");
            }
        } else {
            handleOpenAlert("Registre datos en las secciones: agregar responsable", "error");
        }
    }

    //modal responsables de auditoria
    const [openModalResponsible, setOpenModalResponsible] = useState(false);
    const [rowUsersResponsibles, setRowUsersResponsibles] = useState([]);

    const [typeResponsible, setTypeResponsible] = useState([]);

    const handleOpenModalResponsible = () => {
        setOpenModalResponsible(true);
    }

    const handleCloseModalResponsible = () => {
        setOpenModalResponsible(false);
    }

    const getUsers = async () => {
        const resp = await MyUsers.users();
        setRowUsersResponsibles(resp.users_actives);
    }

    const handleSelectUserResponsible = async (idUser) => {
        handleOpenLoading();

        const res = await MyUsers.editUsers({ idUsers: idUser });
        switch (typeResponsible) {
            case "area":
                setResponsibleArea(res.first_name + " " + res.last_name);
                break;
            case "auditedProcess":
                setResponsibleAuditedProcess(res.first_name + " " + res.last_name);
                break;
            case "NonConformities":
                setresponsibleNonconformities(res.first_name + " " + res.last_name);
                setIdResponsibleNonconformities(idUser);
            case "resultsInspection":
                setResponsibleMeasuresResultInspection(res.first_name + " " + res.last_name);
                setIdResponsibleMeasuresResultInspection(idUser);
                break;
        }

        setOpenLoading(false);
        handleCloseModalResponsible();
    }

    //modal de responsables del registro
    const [openModalCreateResponsibleRegister, setOpenModalCreateResponsibleRegister] = useState(false);

    const handleOpenModalCreateResponsibleRegister = () => {
        setOpenModalCreateResponsibleRegister(true)
    }

    const handleCloseModalCreateResponsibleRegister = () => {
        setOpenModalCreateResponsibleRegister(false)
    }

    const handleSelectUserResponsibleRegister = async (idUser) => {
        handleOpenLoading();
        try {
            const data = {
                id_users: idUser
            }

            const res = await MyEvaluationSSO.postResponsibleRegister(data, idEvaluation);
            handleCloseModalCreateResponsibleRegister();
            await getResponsibleRegister();
            handleOpenAlert("Se agregó responsable con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    //responsable del registro

    const [rowsResponsibleRegister, setRowsResponsibleRegister] = useState([]);

    const getResponsibleRegister = async () => {
        try {
            const resp = await MyEvaluationSSO.getResponsibleRegister(idEvaluation) ?? [];
            setRowsResponsibleRegister(resp);
        } catch (e) {
            handleOpenAlert("Problemas al traer responsables del registro", "error");
        }
    }

    const handleDeleteResponsibleRegister = async (idResponsibleRegister) => {
        handleOpenLoading();
        try {
            const resp = await MyEvaluationSSO.deleteResponsibleRegister(idResponsibleRegister);
            await getResponsibleRegister();
            handleOpenAlert("Se eliminó responsable con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleSignResponsibleRegister = async (row) => {
        handleOpenLoading();
        try {
            const data = {
                firm: 1,
                id_users: idUsserLogged
            }

            const resp = await MyEvaluationSSO.signResponsibleRegister(data, row.id);
            await getResponsibleRegister();
            handleOpenAlert("Responsable firmó con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error al firmar", "error");
        }
    }

    // auditoria
    const [openModalCreateAudit, setOpenModalCreateAudit] = useState(false);
    const [openModalUpdateAudit, setOpenModalUpdateAudit] = useState(false);
    const [idAudit, setIdAudit] = useState("");

    const [nameAuditor, setNameAuditor] = useState("");
    const [nroRegisterAuditor, setNroRegisterAuditor] = useState("");
    const [dateAudit, setDateAudit] = useState("");
    const [auditedProcesses, setAuditedProcesses] = useState("");
    const [responsibleAuditedProcess, setResponsibleAuditedProcess] = useState("");

    const handleOpenModalCreateAudit = () => {
        setOpenModalCreateAudit(true)
        //
        setTypeResponsible("auditedProcess");

        setNameAuditor("");
        setNroRegisterAuditor("");
        setDateAudit("");
        setAuditedProcesses("");
        setResponsibleAuditedProcess("");
    }

    const handleCloseModalCreateAudit = () => {
        setOpenModalCreateAudit(false)
    }

    const handleOpenModalUpdateAudit = (row) => {
        setOpenModalUpdateAudit(true);

        //traer datos
        setTypeResponsible("auditedProcess");
        setIdAudit(row.id);
        setNameAuditor(row.auditor_name);
        setDateAudit(row.date_audit);
        setNroRegisterAuditor(row.nro_register_auditor);
        setAuditedProcesses(row.audited_processes);
        setResponsibleAuditedProcess(row.name_responsible_audit)
    }
    const handleCloseModalUpdateAudit = () => {
        setOpenModalUpdateAudit(false);
    }

    const [rowAudits, setRowAudits] = useState([]);


    const getAudits = async () => {
        try {
            const resp = await MyEvaluationSSO.getAudit(idEvaluation) ?? [];
            setRowAudits(resp);
        } catch (e) {
            handleOpenAlert("Problemas al traer auditoría", "error");
        }
    }

    const handlePostAudit = async (e) => {
        handleOpenLoading();
        e.preventDefault();

        try {

            const data = {
                auditor_name: nameAuditor,
                nro_register_auditor: nroRegisterAuditor,
                date_audit: dateAudit,
                audited_processes: auditedProcesses,
                name_responsible_audit: responsibleAuditedProcess,
            }

            const resp = await MyEvaluationSSO.postAudit(data, idEvaluation);
            handleCloseModalCreateAudit();
            await getAudits();
            handleOpenAlert("Se agregó el proceso auditado con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleUpdateAudit = async (e) => {
        e.preventDefault();
        handleOpenLoading();
        try {
            const data = {
                auditor_name: nameAuditor,
                date_audit: dateAudit,
                nro_register_auditor: nroRegisterAuditor,
                audited_processes: auditedProcesses,
                name_responsible_audit: responsibleAuditedProcess
            }

            const resp = await MyEvaluationSSO.updateAuditById(data, idAudit);

            handleCloseModalUpdateAudit();
            await getAudits();
            handleOpenAlert("Se agregó auditoría con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleDeleteAudit = async (idAudit) => {
        handleOpenLoading();

        const resp = await MyEvaluationSSO.deleteAuditById(idAudit);
        if (resp.success == true) {
            await getAudits();
            handleOpenAlert("Proceso auditado eliminado con éxito");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    // no conformidades

    const [openModalCreateNonconformities, setOpenModalCreateNonconformities] = useState(false);
    const [openModal1UpdateNonconformitie, setOpenModal1UpdateNonconformitie] = useState(false);
    const [openModal2UpdateNonconformitie, setOpenModal2UpdateNonconformitie] = useState(false);


    const [rowsNonConformities, setRowsNonConformities] = useState([]);
    const [idNonconformitie, setIdNonconformitie] = useState("");

    const [descriptionNonconformities, setDescriptionNonconformities] = useState("");
    const [causesNonconformities, setCausesNonconformities] = useState("");
    const [correctiveMeasures, setCorrectiveMeasures] = useState("");
    const [responsibleNonconformities, setresponsibleNonconformities] = useState("");
    const [idResponsibleNonconformities, setIdResponsibleNonconformities]= useState("");
    const [executionDate, setExecutionDate] = useState("");
    const [statusSelected, setStatusSelected] = useState("");

    const rowStatusCorrectiveMeasure = [{ id_status: 1, status: "Pendiente" }, { id_status: 2, status: "En ejecución" }, { id_status: 3, status: "Realizada" }];

    const handleOpenModalCreateNonconformities = () => {
        setOpenModalCreateNonconformities(true);

        setTypeResponsible("NonConformities");
        //limpiar
        setDescriptionNonconformities("");
        setCausesNonconformities("");
        setCorrectiveMeasures("");
        setresponsibleNonconformities("");
        setExecutionDate("");
        setStatusSelected("");
    }

    const handleCloseModalCreateNonconformities = () => {
        setOpenModalCreateNonconformities(false);
    }

    const handleOpenModal1UpdateNonconformitie = (row) => {
        setOpenModal1UpdateNonconformitie(true);

        setIdNonconformitie(row.id);
        setDescriptionNonconformities(row.description_non_conformities);
        setCausesNonconformities(row.causes);
    }
    const handleCloseModal1UpdateNonconformitie = () => {
        setOpenModal1UpdateNonconformitie(false)
    }


    const handleOpenModal2UpdateNonconformitie = (row) => {
        setOpenModal2UpdateNonconformitie(true)

        setTypeResponsible("NonConformities")
        setIdNonconformitie(row.id);
        setCorrectiveMeasures(row.description_corrective_measures);
        setresponsibleNonconformities(row.name_responsible);
        setExecutionDate(row.date_execution);
        setStatusSelected(row.status_corrective_measures);
    }
    const handleCloseModal2UpdateNonconformitie = () => {
        setOpenModal2UpdateNonconformitie(false)
    }

    const getNonconformities = async () => {
        try {
            const resp = await MyEvaluationSSO.getNonconformities(idEvaluation) ?? [];
            setRowsNonConformities(resp);
        } catch (e) {
            handleOpenAlert("Problema al traer plan de acción de no conformidades", "error");
        }
    }

    const handlePostNonconformities = async (e) => {
        e.preventDefault();
        handleOpenLoading();
        try {
            const data = {
                description_non_conformities: descriptionNonconformities,
                causes: causesNonconformities,
                description_corrective_measures: correctiveMeasures,
                name_responsible: responsibleNonconformities,
                date_execution: executionDate,
                status_corrective_measures: statusSelected
            }

            const resp = await MyEvaluationSSO.postNonconformities(data, idEvaluation);
            handleCloseModalCreateNonconformities();
            await getNonconformities();

            //agregar a módulo de tareas
            const dataTask = {
                date_start: executionDate,
                date_end: null,
                description: correctiveMeasures,
                id_status: statusSelected,
                id_users: idResponsibleNonconformities,
                id_tasks_types: 2,
                title: "Tarea asignada en Evaluaciones SSO - mypes",
                origin_url: `documentation/registers/mypes/evaluationSSO/edit?id_evaluation=${idEvaluation}`
            }

            const res = await MyTasksNew.postTasks(dataTask);

            handleOpenAlert("Se registró el plan de acción con éxito", "success");
        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleUpdate1Nonconformitie = async (e) => {
        e.preventDefault();
        handleOpenLoading();
        try {
            const data = {
                description_non_conformities: descriptionNonconformities,
                causes: causesNonconformities
            }

            const resp = await MyEvaluationSSO.updateNonconformitieById(data, idNonconformitie);
            handleCloseModal1UpdateNonconformitie();
            await getNonconformities();

            handleOpenAlert("Se actualizó el plan de acción con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleUpdate2NonConformities = async (e) => {
        e.preventDefault();
        handleOpenLoading();
        try {
            const data = {
                description_corrective_measures: correctiveMeasures,
                name_responsible: responsibleNonconformities,
                date_execution: executionDate,
                status_corrective_measures: statusSelected
            }

            const resp = await MyEvaluationSSO.updateNonconformitieById(data, idNonconformitie);

            handleCloseModal2UpdateNonconformitie();
            await getNonconformities();
            handleOpenAlert("Se actualizó la medida correctiva con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleDeleteNonconformities = async (idNonConformitie) => {
        handleOpenLoading();
        try {
            const resp = await MyEvaluationSSO.deleteNonconformitieById(idNonConformitie);
            await getNonconformities();

            handleOpenAlert("Se eliminó el plan de acción con éxito", "success");
        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    //inspecciones internas
    const [openModalCreateInternalInspections, setOpenModalCreateInternalInspections] = useState(false);
    const [openModalUpdateInternalInspection1, setOpenModalUpdateInternalInspection1] = useState(false);
    const [idInspection, setIdInspection] = useState("");

    const [rowsInternalInspection, setRowsInternalInspection] = useState([]);
    const [detailTypeInspection, setDetailTypeInspection] = useState("");

    const [rowAreas, setRowAreas] = useState([]);
    const [areaSelected, setAreaSelected] = useState("");
    const [dateInspection, setDateInspection] = useState("");
    const [responsibleArea, setResponsibleArea] = useState("");
    const [responsibleInspection, setResponsibleInspection] = useState("");
    const [objectiveInspection, setObjectiveInspection] = useState("");

    const [typeInspectionSelected, setTypeInspectionSelected] = useState("");

    const rowTypeInspection = [{ idTypeInspection: 1, name: "Planeada" }, { idTypeInspection: 2, name: "No planeada" }, { idTypeInspection: 3, name: "Otro" }];

    const handleOpenModalCreateInternalInspections = () => {
        setOpenModalCreateInternalInspections(true)
        //
        setTypeResponsible("area");
        //limpiar inputs
        setAreaSelected("");
        setDateInspection("");
        setResponsibleArea("");
        setResponsibleInspection("");
        setObjectiveInspection("");
        setTypeInspectionSelected("");
    }

    const handleCloseModalCreateInternalInspections = () => {
        setOpenModalCreateInternalInspections(false)
    }

    const handleOpenModalUpdateInternalInspection1 = (row) => {
        setOpenModalUpdateInternalInspection1(true)

        //traer datos
        setIdInspection(row.id);
        setTypeResponsible("area");
        setAreaSelected(row.area);
        setDateInspection(row.date_inspection);
        setResponsibleArea(row.responsible_area);
        setResponsibleInspection(row.responsible_inspection);
        setObjectiveInspection(row.objective_inspection);
        setTypeInspectionSelected(row.type_inspection);
    }

    const handleCloseModalUpdateInternalInspection1 = () => {
        setOpenModalUpdateInternalInspection1(false)
    }

    // modal de detallar otro inspección
    const [openModalDetailTypeInspection, setOpenModalDetailTypeInspection] = useState(false);

    const handleOpenModalDetailTypeInspection = () => {
        setOpenModalDetailTypeInspection(true)
    }

    const handleCloseModalDetailTypeInspection = () => {
        setOpenModalDetailTypeInspection(false)
    }


    const getAreas = async () => {
        const resp = await MyAreas.getAreas();
        setRowAreas(resp);
    }

    const user = MyConfigurationApi.userData();
    const idUsserLogged = user.id_users;

    const handlePostDetailTypeInspection = (e) => {
        e.preventDefault();
        handleOpenLoading();
        handleCloseModalDetailTypeInspection();
        setOpenLoading(false);
    }

    const getInternalInspection = async () => {
        try {
            const resp = await MyEvaluationSSO.getInternalInspection(idEvaluation) ?? [];
            setRowsInternalInspection(resp);

        } catch (e) {
            handleOpenAlert("Problemas al traer inspecciones internas", "error");
        }
    }

    const handlePostInternalInspection = async (e) => {
        handleOpenLoading();
        e.preventDefault();
        try {
            const data = {
                area: areaSelected,
                date_inspection: dateInspection,
                responsible_area: responsibleArea,
                responsible_inspection: responsibleInspection,
                objective_inspection: objectiveInspection,
                type_inspection: typeInspectionSelected == "Otro" ? detailTypeInspection : typeInspectionSelected,
                result_inspection: "",
                description_inspection: "",
                corrective_measure: ""
            }
            const res = await MyEvaluationSSO.postInternalInspection(data, idEvaluation);

            handleCloseModalCreateInternalInspections();
            await getInternalInspection();
            handleOpenAlert("Se agregó la inspección con éxito", "success");
        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleUpdateInternalInspection = async (e) => {
        e.preventDefault();
        handleOpenLoading();
        try {
            const data = {
                date_inspection: dateInspection,
                responsible_area: responsibleArea,
                responsible_inspection: responsibleInspection,
                objective_inspection: objectiveInspection,
                type_inspection: typeInspectionSelected
            }

            const resp = await MyEvaluationSSO.updateInternalInspectionById(data, idInspection);
            handleCloseModalUpdateInternalInspection1();

            await getInternalInspection();
            handleOpenAlert("Se actualizó la inspección con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleDeleteInternalInspections = async (idInternalInspection) => {
        handleOpenLoading();

        try {
            const resp = await MyEvaluationSSO.deleteInternalInspectionById(idInternalInspection);

            await getInternalInspection();
            handleOpenAlert("Inspección interna se eliminó con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    //resultados de la inspección
    const [rowsResultInternalInspection, setRowsResultInternalInspection] = useState([]);
    const [openModalResultInspection, setOpenModalResultInspection] = useState(false);
    const [openModalUpdateResultInspection, setOpenModalUpdateResultInspection] = useState(false);
    const [idResultInspection, setIdResultInspection] = useState("");

    const [resultInspection, setResultInspection] = useState("");

    const [causesResultInspection, setCausesResultInspection] = useState("");
    const [correctiveMeasuresResultInspection, setCorrectiveMeasuresResultInspection] = useState("");
    const [responsibleMeasuresResultInspection, setResponsibleMeasuresResultInspection] = useState("");
    const [idResponsibleMeasuresResultInspection, setIdResponsibleMeasuresResultInspection] = useState("");
    const [startDateResultInspection, setStartDateResultInspection] = useState("");

    const handleOpenModalResultInspection = () => {
        setOpenModalResultInspection(true);

        setTypeResponsible("resultsInspection");

        //limpiar inputs
        setAreaSelected("");
        setResultInspection("");
        setCausesResultInspection("");
        setCorrectiveMeasuresResultInspection("")
        setResponsibleMeasuresResultInspection("")
        setStartDateResultInspection("")
    }

    const handleCloseModalResultInspection = () => {
        setOpenModalResultInspection(false);
    }


    const handleOpenModalUpdateResultInspection = (resultInspection) => {
        setOpenModalUpdateResultInspection(true);

        //pasar datos
        setIdResultInspection(resultInspection.id);
        setResultInspection(resultInspection.result_inspection);
        setCausesResultInspection(resultInspection.causes_inspection);
        setCorrectiveMeasuresResultInspection(resultInspection.corrective_measure)
    }

    const handleCloseModalUpdateResultInspection = () => {
        setOpenModalUpdateResultInspection(false);
    }

    const getResultInternalInspection = async () => {
        try {
            const resp = await MyEvaluationSSO.getResultInspection(idEvaluation) ?? [];
            const areas = await MyAreas.getAreas();

            const arrayResult = resp.map(r => {
                const area = areas.filter((a) => a.id_areas == r.id_area_inspected)[0].area;
                return { ...r, nameArea: area };
            });

            setRowsResultInternalInspection(arrayResult);

        } catch (e) {
            handleOpenAlert("Problemas al traer resultados de inspecciones internas", "error")
        }
    }

    const handlePostsResultInternalInspection = async (e) => {
        e.preventDefault();
        handleOpenLoading();
        try {
            const data = {
                id_area_inspected: areaSelected,
                result_inspection: resultInspection,
                causes_inspection: causesResultInspection,
                corrective_measure: correctiveMeasuresResultInspection,
                id_user: responsibleMeasuresResultInspection,
                start_date: startDateResultInspection
            }

            const resp = await MyEvaluationSSO.postResultInspection(data, idEvaluation);

            //agregar a módulo de tareas
            const dataTask = {
                date_start: startDateResultInspection,
                date_end: null,
                description: correctiveMeasuresResultInspection,
                id_status: 1,
                id_users: idResponsibleMeasuresResultInspection,
                id_tasks_types: 2,
                title: "Tarea asignada en Evaluaciones SSO - mype",
                origin_url: `documentation/registers/mypes/evaluationSSO/edit?id_evaluation=${idEvaluation}`
            }
            const res = await MyTasksNew.postTasks(dataTask);

            handleCloseModalResultInspection();
            await getResultInternalInspection();

            handleOpenAlert("Se agregó el resultado de inspección con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleDeleteResultInternalInspection = async (idResultInternalInspection) => {

        handleOpenLoading();
        try {
            const resp = await MyEvaluationSSO.deleteResultInspectionByID(idResultInternalInspection);

            await getResultInternalInspection();
            handleOpenAlert("Se eliminó el resultado de inspección con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleUpdateResultInspection = async (e) => {
        e.preventDefault();
        handleOpenLoading();

        try {
            const data = {
                result_inspection: resultInspection,
                causes_inspection: causesResultInspection,
                corrective_measure: correctiveMeasuresResultInspection,
            }

            const resp = await MyEvaluationSSO.updateResultInspectionById(data, idResultInspection)

            handleCloseModalUpdateResultInspection();
            await getResultInternalInspection();
            handleOpenAlert("Se actualizó el resultado de la inspección con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    useEffect(() => {
        getEvaluationSSOById();
        getAudits();
        getUsers();
        getAreas();
        getResponsibleRegister();
        getInternalInspection();
        getResultInternalInspection();
        getNonconformities();
    }, [])



    return (
        <Box sx={{ height: "100%" }}>
            <ReturnBack/>

            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <SubtitleText
                                text={"Registros de pequeñas empresas"}
                                color={colorTitle}
                            ></SubtitleText>
                            <TitleText text={"Registro de evaluación del sistema de gestión de seguridad y salud en el trabajo"}></TitleText>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <CompanyLogoControla />
                        </Grid>
                    </Grid>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container sx={{ marginTop: "4px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Nro. registro"}
                                    inputType={"text"}
                                    inputValue={setCode}
                                    value={code}
                                    readOnly={statusRegister == 2 ? true : false}
                                />
                            </Grid>

                            {/* información de auditoría */}
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                                <InputLabel
                                    sx={{
                                        padding: "8px",
                                        fontSize: "18px",
                                        color: "#305AD9",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Auditores y registros
                                </InputLabel>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    sx={{ color: "#F2994A", ml: 1 }}
                                >
                                    Este campo es obligatorio
                                </Typography>
                            </Grid>
                            {
                                statusRegister == 1 && (
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right" mt={2}>
                                        <ButtonControla
                                            roles={permissions.DOC_REGISMYPES_EVALUAC_SSO_AGREG_AUDIT}
                                            iconButton={<Add sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Agregar auditores"}
                                            functionOnClick={handleOpenModalCreateAudit}
                                        />
                                    </Grid>
                                )}

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Nombre y Apellido
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                N° de registro
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            rowAudits.map((a) => (
                                                <TableRow
                                                    key={a.id}
                                                    hover
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {a.auditor_name}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {a.nro_register_auditor}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignItems="center"
                                                            spacing={1}
                                                        >
                                                            {
                                                                statusRegister == 1 && (
                                                                    <>
                                                                        <ButtonIconControla
                                                                            icon={
                                                                                <BorderColorOutlined
                                                                                    sx={{ color: "white" }}
                                                                                />
                                                                            }
                                                                            backgroundColor={"#2D9CDB"}
                                                                            backgroundColorHover={"#33AEF4"}
                                                                            textTooltip={"Editar"}
                                                                            functionOnClick={() => handleOpenModalUpdateAudit(a)}
                                                                        />

                                                                        <ButtonIconControla
                                                                            icon={<Delete sx={{ color: "white" }} />}
                                                                            backgroundColor={"#EB5757"}
                                                                            backgroundColorHover={"#FF4040"}
                                                                            textTooltip={"Eliminar"}
                                                                            functionOnClick={() => { handleDeleteAudit(a.id) }}
                                                                        />
                                                                    </>
                                                                ) }
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }


                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    sx={{ color: "#F2994A", ml: 1 }}
                                >
                                    Este campo es obligatorio
                                </Typography>
                            </Grid>
                            <TableContainer component={Paper} sx={{ marginTop: "15px" }}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: "#305ad9", fontSize: "16px", fontWeight: "bold" }}>
                                                Fecha de auditoria
                                            </TableCell>
                                            <TableCell sx={{ color: "#305ad9", fontSize: "16px", fontWeight: "bold" }}>
                                                Procesos auditados
                                            </TableCell>
                                            <TableCell sx={{ color: "#305ad9", fontSize: "16px", fontWeight: "bold" }}>
                                                Nombre de los responsables de los procesos auditados
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            rowAudits.map((a) => (
                                                <TableRow
                                                    hover
                                                    key={a.id}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {a.date_audit}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {a.audited_processes}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {a.name_responsible_audit}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignItems="center"
                                                            spacing={1}
                                                        >
                                                            {/* <ButtonIconControla
                                                                icon={<Delete sx={{ color: "white" }} />}
                                                                backgroundColor={"#EB5757"}
                                                                backgroundColorHover={"#FF4040"}
                                                                textTooltip={"Eliminar"}
                                                                functionOnClick={() => { }}
                                                            /> */}
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} mt={3}>
                                    <InputControlaVertical
                                        text={"Nro. De no conformidades"}
                                        inputType={"number"}
                                        inputValue={setConformityNumber}
                                        value={conformityNumber}
                                        readOnly={statusRegister == 2 ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <SubtitleText text={"Información a adjuntar"} color={blue} />
                                </Grid>
                                <Grid item xs={11} md={11}>
                                    <InputControlaVerticalIcon
                                        text={"a) Informe de auditoría"}
                                        inputType={"file"}
                                        inputValue={setDocumentUrl1}
                                        textTooltip={
                                            "Indicar los hallazgos encontrados, así como no conformidades, observaciones, entre otros, con la respectiva firma del auditor o auditores."
                                        }
                                        disabled={statusRegister == 2 ? true : false}
                                    />
                                </Grid>
                                {
                                    statusRegister == 2 && (
                                        <Grid item xs={1} md={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                            <ButtonIconControla
                                                icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                                backgroundColor={"#305AD9"}
                                                backgroundColorHover={"#0E4AFF"}
                                                textTooltip={"Ver documento"}
                                                directionUrl={documentUrl1}
                                                directionTarget={"_blank"}
                                            />
                                        </Grid>
                                    ) }

                                <Grid item xs={11} md={11}>
                                    <InputControlaVerticalIcon
                                        text={
                                            "b) Plan de acción para cierre de no conformidades (posterior a la auditoría)."
                                        }
                                        inputType={"file"}
                                        inputValue={setDocumentUrl2}
                                        textTooltip={
                                            "El plan de acción contiene la descripción de las causas que originaron cada no conformidad, propuesta de las medidas correctivas para cada no conformidad, responsable de implementación, fecha de ejecución, estado de la acción correctiva."
                                        }
                                        disabled={statusRegister == 2 ? true : false}
                                    />
                                </Grid>
                                {
                                    statusRegister == 2 && (
                                        <Grid item xs={1} md={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                            <ButtonIconControla
                                                icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                                backgroundColor={"#305AD9"}
                                                backgroundColorHover={"#0E4AFF"}
                                                textTooltip={"Ver documento"}
                                                directionUrl={documentUrl2}
                                                directionTarget={"_blank"}
                                            />
                                        </Grid>
                                    ) }

                            </Grid>

                            {/* no conformidades */}
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                                <InputLabel
                                    sx={{
                                        padding: "8px",
                                        fontSize: "18px",
                                        color: "#305AD9",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Plan de acción para cierre de no conformidades
                                </InputLabel>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    sx={{ color: "#F2994A", ml: 1 }}
                                >
                                    Este campo es obligatorio
                                </Typography>
                            </Grid>
                            {
                                statusRegister == 1 && (
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right" mt={2}>
                                        <ButtonControla
                                            roles={permissions.DOC_REGISMYPES_EVALUAC_SSO_AGREG_DATOS}
                                            iconButton={<Add sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Agregar datos"}
                                            functionOnClick={handleOpenModalCreateNonconformities}
                                        />
                                    </Grid>
                                )}
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Descripción de la no conformidad
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Causas de la no conformidad
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            rowsNonConformities.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    hover
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.description_non_conformities}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.causes}
                                                    </TableCell>

                                                    {
                                                        statusRegister == 1 && (
                                                            <TableCell align="right">
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="end"
                                                                    alignItems="center"
                                                                    spacing={1}
                                                                >
                                                                    <ButtonIconControla
                                                                        icon={
                                                                            <BorderColorOutlined
                                                                                sx={{ color: "white" }}
                                                                            />
                                                                        }
                                                                        backgroundColor={"#2D9CDB"}
                                                                        backgroundColorHover={"#33AEF4"}
                                                                        textTooltip={"Editar"}
                                                                        functionOnClick={() => handleOpenModal1UpdateNonconformitie(row)}
                                                                    />
                                                                    <ButtonIconControla
                                                                        icon={<Delete sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Eliminar"}
                                                                        functionOnClick={() => handleDeleteNonconformities(row.id)}
                                                                    />
                                                                </Stack>
                                                            </TableCell>
                                                        ) }
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container>
                                <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                    sx={{ marginTop: 3, marginBottom: 1 }}
                                >
                                    <Box>
                                        <InputLabel
                                            sx={{
                                                padding: "8px",
                                                fontSize: "18px",
                                                color: "#305AD9",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Medidas correctivas de la no conformidad
                                        </InputLabel>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            sx={{ color: "#F2994A", ml: 1 }}
                                        >
                                            Este campo es obligatorio
                                        </Typography>
                                    </Box>

                                </Stack>
                            </Grid>
                            <Grid container sx={{ marginTop: "4px" }}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        color: "#305ad9",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Descripción de la medida correctiva
                                                </TableCell>

                                                <TableCell
                                                    sx={{
                                                        color: "#305ad9",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Responsable
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: "#305ad9",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Fecha de ejecución
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: "#305ad9",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Estado
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: "#305ad9",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {
                                                rowsNonConformities.map((row) => (
                                                    <TableRow
                                                        hover
                                                        key={row.id}
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                        }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.description_corrective_measures}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.name_responsible}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.date_execution}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {rowStatusCorrectiveMeasure.filter((s) =>s.id_status == row.status_corrective_measures)[0]?.status}
                                                        </TableCell>
                                                        {
                                                            statusRegister == 1 && (
                                                                <TableCell align="right">
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="end"
                                                                        alignItems="center"
                                                                    >
                                                                        {/* <ButtonIconControla
                                                                            icon={
                                                                                <BorderColorOutlined
                                                                                    sx={{ color: "white" }}
                                                                                />
                                                                            }
                                                                            backgroundColor={"#2D9CDB"}
                                                                            backgroundColorHover={"#33AEF4"}
                                                                            textTooltip={"Editar"}
                                                                            functionOnClick={() => handleOpenModal2UpdateNonconformitie(row)}
                                                                        /> */}
                                                                        <ButtonIconControla
                                                                            icon={<Delete sx={{ color: "white" }} />}
                                                                            backgroundColor={"#EB5757"}
                                                                            backgroundColorHover={"#FF4040"}
                                                                            textTooltip={"Eliminar"}
                                                                            functionOnClick={() => handleDeleteNonconformities(row.id)}
                                                                        />
                                                                    </Stack>
                                                                </TableCell>
                                                            )}
                                                    </TableRow>
                                                ))
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            {/* inspeccion interna */}
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                                <InputLabel
                                    sx={{
                                        padding: "8px",
                                        fontSize: "18px",
                                        color: "#305AD9",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Inspección Interna
                                </InputLabel>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    sx={{ color: "#F2994A", ml: 1 }}
                                >
                                    Este campo es obligatorio
                                </Typography>
                            </Grid>
                            {
                                statusRegister == 1 && (
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right" mt={2}>
                                        <ButtonControla
                                            roles={permissions.DOC_REGISMYPES_EVALUAC_SSO_AGREG_AREA}
                                            iconButton={<Add sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Agregar datos del área a inspeccionar"}
                                            functionOnClick={handleOpenModalCreateInternalInspections}
                                        />
                                    </Grid>

                                )}

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Área inspeccionada
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Fecha y hora de la inspección
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Responsable del área inspeccionada
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            rowsInternalInspection.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    hover
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.area}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.date_inspection}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.responsible_area}
                                                    </TableCell>
                                                    {
                                                        statusRegister == 1 && (
                                                            <TableCell align="right">
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="end"
                                                                    alignItems="center"
                                                                    spacing={1}
                                                                >
                                                                    <ButtonIconControla
                                                                        icon={
                                                                            <BorderColorOutlined
                                                                                sx={{ color: "white" }}
                                                                            />
                                                                        }
                                                                        backgroundColor={"#2D9CDB"}
                                                                        backgroundColorHover={"#33AEF4"}
                                                                        textTooltip={"Editar"}
                                                                        functionOnClick={() => handleOpenModalUpdateInternalInspection1(row)}
                                                                    />
                                                                    <ButtonIconControla
                                                                        icon={<Delete sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Eliminar"}
                                                                        functionOnClick={() => handleDeleteInternalInspections(row.id)}
                                                                    />
                                                                </Stack>
                                                            </TableCell>
                                                        )}
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    sx={{ color: "#F2994A", ml: 1 }}
                                >
                                    Este campo es obligatorio
                                </Typography>
                                <InputLabel
                                    sx={{
                                        padding: "8px",
                                        fontSize: "18px",
                                        color: "#305AD9",
                                        fontWeight: "bold",
                                    }}
                                >
                                </InputLabel>
                            </Grid>

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Responsable de la inspección
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Objetivo de la inspección
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Tipo de inspección
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            rowsInternalInspection.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    hover
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.responsible_inspection}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.objective_inspection}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.type_inspection}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignItems="center"
                                                            spacing={1}
                                                        >
                                                            {/* <ButtonIconControla
                                                                icon={<Delete sx={{ color: "white" }} />}
                                                                backgroundColor={"#EB5757"}
                                                                backgroundColorHover={"#FF4040"}
                                                                textTooltip={"Eliminar"}
                                                                functionOnClick={() => handleDeleteInternalInspections(row.id)}
                                                            /> */}
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>

                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    sx={{ color: "#F2994A", ml: 1 }}
                                >
                                    Este campo es obligatorio
                                </Typography>
                            </Grid>

                            {
                                statusRegister == 1 && (
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right" mt={2}>
                                        <ButtonControla
                                            roles={permissions.DOC_REGISMYPES_EVALUAC_SSO_AGREG_RESULT_INSPECT}
                                            iconButton={<Add sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Agregar resultado de inspección"}
                                            functionOnClick={handleOpenModalResultInspection}
                                        />
                                    </Grid>
                                )}


                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Área inspeccionada
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Resultado de la inspección
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Descripción de la causa ante resultados desfavorables
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Medidas correctivas
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Responsable de la implementación
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color: "#305ad9",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Fecha de ejecución
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            rowsResultInternalInspection.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    hover
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.nameArea}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.result_inspection}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.causes_inspection}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.corrective_measure}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.id_user}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.start_date}
                                                    </TableCell>
                                                    {
                                                        statusRegister == 1 && (
                                                            <TableCell align="right">
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="end"
                                                                    alignItems="center"
                                                                    spacing={1}
                                                                >
                                                                    <ButtonIconControla
                                                                        icon={
                                                                            <BorderColorOutlined
                                                                                sx={{ color: "white" }}
                                                                            />
                                                                        }
                                                                        backgroundColor={"#2D9CDB"}
                                                                        backgroundColorHover={"#33AEF4"}
                                                                        textTooltip={"Editar"}
                                                                        functionOnClick={() => handleOpenModalUpdateResultInspection(row)}
                                                                    />
                                                                    <ButtonIconControla
                                                                        icon={<Delete sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Eliminar"}
                                                                        functionOnClick={() => handleDeleteResultInternalInspection(row.id)}
                                                                    />

                                                                </Stack>
                                                            </TableCell>
                                                        )}
                                                </TableRow>

                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/* responsable del registro */}
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                                <InputLabel
                                    sx={{
                                        padding: "8px",
                                        fontSize: "18px",
                                        color: "#305AD9",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Responsable del registro
                                </InputLabel>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    sx={{ color: "#F2994A", ml: 1 }}
                                >
                                    Este campo es obligatorio
                                </Typography>
                            </Grid>

                            {
                                statusRegister == 1 && (
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right" mt={2}>
                                        <ButtonControla
                                            roles={permissions.DOC_REGISMYPES_EVALUAC_SSO_AGREGAR_RESPONS}
                                            iconButton={<Add sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Agregar responsables"}
                                            functionOnClick={handleOpenModalCreateResponsibleRegister}
                                        />
                                    </Grid>
                                ) }
                            <Grid container sx={{ marginTop: "4px" }}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        color: "#305ad9",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                    align="center"
                                                >
                                                    Nombre y Apellido
                                                </TableCell>

                                                <TableCell
                                                    sx={{
                                                        color: "#305ad9",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                    align="center"
                                                >
                                                    Cargo
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: "#305ad9",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                    align="center"
                                                >
                                                    Fecha de firma
                                                </TableCell>

                                                <TableCell
                                                    sx={{
                                                        color: "#305ad9",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                    align="center"
                                                >
                                                    Firma
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {rowsResponsibleRegister.map((row) => (
                                                <TableRow
                                                    hover
                                                    key={row.id_users}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row" align="center">
                                                        {row.first_name + " " + row.last_name}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="center">
                                                        {row.position_name}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="center">
                                                        {row.firm_date}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="center">
                                                        <ButtonSignatureControla
                                                            idUser={row.id_users}
                                                            firmStatus={row.firm}
                                                            firmUrl={row.firm_url}
                                                            functionOnClick={async() => {
                                                                await handleSignResponsibleRegister(row)
                                                            }}
                                                        />
                                                    </TableCell>
                                                    {
                                                        statusRegister == 1 && (
                                                            <TableCell align="right">
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="end"
                                                                    alignItems="center"
                                                                >
                                                                    <ButtonIconControla
                                                                        icon={<Delete sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Eliminar"}
                                                                        functionOnClick={async() => await handleDeleteResponsibleRegister(row.id)}
                                                                    />

                                                                </Stack>
                                                            </TableCell>
                                                        ) }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack
                                    direction={"row"}
                                    spacing={2}
                                    justifyContent={"center"}
                                    mt={2}
                                    mb={2}
                                >
                                    <Box>
                                        <ButtonControla
                                            iconButton={<ArrowBack sx={{ color: "white" }} />}
                                            backgroundColor={"#C61C39"}
                                            backgroundColorHover={"#D61F3E"}
                                            textButton={"Regresar"}
                                            typeButton={"button"}
                                            isNavegationOrigin={true}
                                            url={`/documentation/registers/mypes/evaluationSSO`}
                                        />
                                    </Box>
                                    {
                                        statusRegister == 1 ? (
                                            <>
                                                <Box>
                                                    <ButtonControla
                                                        roles={permissions.DOC_REGISMYPES_EVALUAC_SSO_UPDATE}
                                                        backgroundColor={"#169073"}
                                                        backgroundColorHover={"#1BAA88"}
                                                        textButton={"Actualizar"}
                                                        functionOnClick={handleUpdatedEvaluation}
                                                        disabled={false}
                                                    />
                                                </Box>

                                                <Box>
                                                    <ButtonControla
                                                        roles={permissions.DOC_REGISMYPES_EVALUAC_SSO_CLOSE}
                                                        iconButton={<CloseIcon sx={{ color: "white" }} />}
                                                        backgroundColor={"black"}
                                                        backgroundColorHover={"#C4C4C4"}
                                                        textButton={"Cerrar registro"}
                                                        functionOnClick={handleCloseEvaluation}
                                                    />
                                                </Box>
                                            </>

                                        ) : (
                                            <Grid item xs={12} md={3}>
                                                <ButtonControla
                                                    iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                                                    backgroundColor={"#F2994A"}
                                                    backgroundColorHover={"#FF881E"}
                                                    textButton={"Ver documento PDF"}
                                                    typeButton={"button"}
                                                    url={
                                                        process.env.REACT_APP_API_DJANGO + "report/" +
                                                        idEvaluation
                                                    }
                                                    target="_blank"
                                                />
                                                </Grid>
                                        )
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

            </Container>

            {/* dialog de auditoria */}
            <Dialog
                open={openModalCreateAudit}
                onClose={handleCloseModalCreateAudit}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Agregar auditor"
                    functionOnClose={handleCloseModalCreateAudit}
                />
                <form onSubmit={handlePostAudit}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <InputControlaVertical
                            text={"Nombre del auditor:"}
                            inputType={"text"}
                            inputValue={setNameAuditor}
                            value={nameAuditor}
                            modalType={true}
                            required
                        />
                        <InputControlaVertical
                            text={"Nro registro del auditor:"}
                            inputType={"text"}
                            inputValue={setNroRegisterAuditor}
                            value={nroRegisterAuditor}
                            modalType={true}
                            required
                        />
                        <InputControlaVertical
                            text={"Fecha de auditoría:"}
                            inputType={"date"}
                            inputValue={setDateAudit}
                            value={dateAudit}
                            modalType={true}
                            required
                        />
                        <InputControlaVertical
                            text={"Proceso auditado:"}
                            inputType={"textArea"}
                            inputValue={setAuditedProcesses}
                            value={auditedProcesses}
                            modalType={true}
                            required
                        />

                        <SearchButtonControla
                            titleLabel="Nombre del responsable del proceso auditado: "
                            inputValue={responsibleAuditedProcess}
                            functionClick={handleOpenModalResponsible}
                            modalType={true}
                            required
                        />
                    </DialogContent>

                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalCreateAudit}
                            />
                            <ButtonControla
                                textButton={"Crear"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>

            {/* dialogo actualizar auditoria */}
            <Dialog
                open={openModalUpdateAudit}
                onClose={handleCloseModalUpdateAudit}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Actualizar auditoría"
                    functionOnClose={handleCloseModalUpdateAudit}
                />
                <form onSubmit={handleUpdateAudit}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <InputControlaVertical
                            text={"Nombre del auditor:"}
                            inputType={"text"}
                            inputValue={setNameAuditor}
                            value={nameAuditor}
                            modalType={true}
                            required
                        />
                        <InputControlaVertical
                            text={"Nro registro del auditor:"}
                            inputType={"text"}
                            inputValue={setNroRegisterAuditor}
                            value={nroRegisterAuditor}
                            modalType={true}
                            required
                        />
                        <InputControlaVertical
                            text={"Fecha de auditoría:"}
                            inputType={"date"}
                            inputValue={setDateAudit}
                            value={dateAudit}
                            modalType={true}
                            required
                        />
                        <InputControlaVertical
                            text={"Proceso auditado:"}
                            inputType={"textArea"}
                            inputValue={setAuditedProcesses}
                            value={auditedProcesses}
                            modalType={true}
                            required
                        />

                        <SearchButtonControla
                            titleLabel="Nombre del responsable del proceso auditado: "
                            inputValue={responsibleAuditedProcess}
                            functionClick={handleOpenModalResponsible}
                            modalType={true}
                            required
                        />
                    </DialogContent>

                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalUpdateAudit}
                            />
                            <ButtonControla
                                textButton={"Actualizar"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>

            {/* dialog de no conformidades */}
            <Dialog
                open={openModalCreateNonconformities}
                onClose={handleCloseModalCreateNonconformities}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Agregar plan de acción para cierre de no conformidades"
                    functionOnClose={handleCloseModalCreateNonconformities}
                />

                <form onSubmit={handlePostNonconformities}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <InputControlaVertical
                            text={"Descripción de la no conformidad:"}
                            inputType={"textArea"}
                            inputValue={setDescriptionNonconformities}
                            value={descriptionNonconformities}
                            modalType={true}
                            required
                        />
                        <InputControlaVertical
                            text={"Causas de la no conformidad:"}
                            inputType={"textArea"}
                            inputValue={setCausesNonconformities}
                            value={causesNonconformities}
                            modalType={true}
                            required
                        />
                        <InputControlaVertical
                            text={"Descripción de medidas correctivas:"}
                            inputType={"textArea"}
                            inputValue={setCorrectiveMeasures}
                            value={correctiveMeasures}
                            modalType={true}
                            required
                        />
                        <SearchButtonControla
                            titleLabel="Nombre del responsable: "
                            inputValue={responsibleNonconformities}
                            functionClick={handleOpenModalResponsible}
                            modalType={true}
                            required
                        />

                        <InputControlaVertical
                            text={"Fecha de ejecución:"}
                            inputType={"date"}
                            inputValue={setExecutionDate}
                            value={executionDate}
                            modalType={true}
                            required
                        />

                        <SelectControla
                            text="Estado:"
                            inputValue={setStatusSelected}
                            modalType={true}
                            required
                            value={statusSelected}
                            childrenRows={rowStatusCorrectiveMeasure.map((row) => (
                                <MenuItem key={row.id_status} value={row.id_status}>
                                    {row.status}
                                </MenuItem>
                            ))}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalCreateNonconformities}
                            />
                            <ButtonControla
                                textButton={"Crear"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />

                        </Stack>
                    </DialogActions>

                </form>

            </Dialog>

            {/* dialogo1 de actualizar no conformidad */}
            <Dialog
                open={openModal1UpdateNonconformitie}
                onClose={handleCloseModal1UpdateNonconformitie}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Actualizar plan de acción para cierre de no conformidades"
                    functionOnClose={handleCloseModal1UpdateNonconformitie}
                />

                <form onSubmit={handleUpdate1Nonconformitie}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <InputControlaVertical
                            text={"Descripción de la no conformidad:"}
                            inputType={"textArea"}
                            inputValue={setDescriptionNonconformities}
                            value={descriptionNonconformities}
                            modalType={true}
                            required
                        />
                        <InputControlaVertical
                            text={"Causas de la no conformidad:"}
                            inputType={"textArea"}
                            inputValue={setCausesNonconformities}
                            value={causesNonconformities}
                            modalType={true}
                            required
                        />
                    </DialogContent>

                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModal1UpdateNonconformitie}
                            />
                            <ButtonControla
                                textButton={"Actualizar"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>

            {/* dialogo2 de actualizar no conformidad */}
            <Dialog
                open={openModal2UpdateNonconformitie}
                onClose={handleCloseModal2UpdateNonconformitie}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Actualizar medida correctiva"
                    functionOnClose={handleCloseModal2UpdateNonconformitie}
                />

                <form onSubmit={handleUpdate2NonConformities}>
                    <DialogContent sx={{ paddingTop: "0" }}>

                        <InputControlaVertical
                            text={"Descripción de medidas correctivas:"}
                            inputType={"textArea"}
                            inputValue={setCorrectiveMeasures}
                            value={correctiveMeasures}
                            modalType={true}
                            required
                        />
                        <SearchButtonControla
                            titleLabel="Nombre del responsable: "
                            inputValue={responsibleNonconformities}
                            functionClick={handleOpenModalResponsible}
                            modalType={true}
                            required
                        />

                        <InputControlaVertical
                            text={"Fecha de ejecución:"}
                            inputType={"date"}
                            inputValue={setExecutionDate}
                            value={executionDate}
                            modalType={true}
                            required
                        />

                        <SelectControla
                            text="Estado:"
                            inputValue={setStatusSelected}
                            modalType={true}
                            required
                            value={statusSelected}
                            childrenRows={rowStatusCorrectiveMeasure.map((row) => (
                                <MenuItem key={row.id_status} value={row.id_status}>
                                    {row.status}
                                </MenuItem>
                            ))}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModal2UpdateNonconformitie}
                            />
                            <ButtonControla
                                textButton={"Actualizar"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>

            {/* dialogo de inspecciones internas */}
            <Dialog
                open={openModalCreateInternalInspections}
                onClose={handleCloseModalCreateInternalInspections}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Agregar inspección interna"
                    functionOnClose={handleCloseModalCreateInternalInspections}
                />
                <form onSubmit={handlePostInternalInspection}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <SelectControla
                            text="Área inspeccionada:"
                            inputValue={setAreaSelected}
                            modalType={true}
                            required
                            value={areaSelected}
                            childrenRows={rowAreas.map((row) => (
                                <MenuItem key={row.id_areas} value={row.id_areas}>
                                    {row.area}
                                </MenuItem>
                            ))}
                        />

                        <InputControlaVertical
                            text={"Fecha y hora de la inspección:"}
                            inputType={"datetime-local"}
                            inputValue={setDateInspection}
                            value={dateInspection}
                            modalType={true}
                            required
                        />

                        <SearchButtonControla
                            titleLabel="Responsable del área inspeccionada: "
                            inputValue={responsibleArea}
                            functionClick={handleOpenModalResponsible}
                            modalType={true}
                            required
                        />

                        <InputControlaVertical
                            text={"Responsable de la inspección:"}
                            inputType={"text"}
                            inputValue={setResponsibleInspection}
                            value={responsibleInspection}
                            modalType={true}
                            required
                        />

                        <InputControlaVertical
                            text={"Objetivo de la inspección:"}
                            inputType={"textArea"}
                            inputValue={setObjectiveInspection}
                            value={objectiveInspection}
                            modalType={true}
                            required
                        />

                        <SelectControla
                            text="Tipo de la inspección:"
                            inputValue={setTypeInspectionSelected}
                            onChange={(e) => {
                                setTypeInspectionSelected(e.target.value);
                                if (e.target.value == "Otro") {
                                    handleOpenModalDetailTypeInspection();
                                }
                            }}
                            modalType={true}
                            required
                            value={typeInspectionSelected}
                            childrenRows={rowTypeInspection.map((row) => (
                                <MenuItem key={row.idTypeInspection} value={row.name}>
                                    {row.name}
                                </MenuItem>
                            ))}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalCreateInternalInspections}
                            />
                            <ButtonControla
                                textButton={"Crear"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>

                </form>

            </Dialog>

            {/* dialogo de actualizar inspecciones internas */}
            <Dialog
                open={openModalUpdateInternalInspection1}
                onClose={handleCloseModalUpdateInternalInspection1}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Actualizar la inspección interna"
                    functionOnClose={handleCloseModalUpdateInternalInspection1}
                />
                <form onSubmit={handleUpdateInternalInspection}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <SelectControla
                            text="Área inspeccionada:"
                            inputValue={setAreaSelected}
                            modalType={true}
                            required
                            value={areaSelected}
                            childrenRows={rowAreas.map((row) => (
                                <MenuItem key={row.id_areas} value={row.id_areas}>
                                    {row.area}
                                </MenuItem>
                            ))}
                            disabled
                        />

                        <InputControlaVertical
                            text={"Fecha y hora de la inspección:"}
                            inputType={"datetime-local"}
                            inputValue={setDateInspection}
                            value={dateInspection}
                            modalType={true}
                            required
                        />

                        <SearchButtonControla
                            titleLabel="Responsable del área inspeccionada: "
                            inputValue={responsibleArea}
                            functionClick={handleOpenModalResponsible}
                            modalType={true}
                            required
                        />

                        <InputControlaVertical
                            text={"Responsable de la inspección:"}
                            inputType={"text"}
                            inputValue={setResponsibleInspection}
                            value={responsibleInspection}
                            modalType={true}
                            required
                        />

                        <InputControlaVertical
                            text={"Objetivo de la inspección:"}
                            inputType={"textArea"}
                            inputValue={setObjectiveInspection}
                            value={objectiveInspection}
                            modalType={true}
                            required
                        />

                        <SelectControla
                            text="Tipo de la inspección:"
                            inputValue={setTypeInspectionSelected}
                            modalType={true}
                            required
                            value={typeInspectionSelected}
                            childrenRows={rowTypeInspection.map((row) => (
                                <MenuItem key={row.idTypeInspection} value={row.name}>
                                    {row.name}
                                </MenuItem>
                            ))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalUpdateInternalInspection1}
                            />
                            <ButtonControla
                                textButton={"Actualizar"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>

            </Dialog>

            {/* detallar tipo otro  */}
            <Dialog
                open={openModalDetailTypeInspection}
                onClose={handleCloseModalDetailTypeInspection}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Detallar tipo de inspección:"
                    functionOnClose={handleCloseModalDetailTypeInspection}
                />
                <form onSubmit={handlePostDetailTypeInspection}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <InputControlaVertical
                            text={"Descripción:"}
                            inputType={"textArea"}
                            inputValue={setDetailTypeInspection}
                            value={detailTypeInspection}
                            modalType={true}
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalDetailTypeInspection}
                            />
                            <ButtonControla
                                textButton={"Agregar"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>

            {/* dialogo de agregar resultado de inspección */}
            <Dialog
                open={openModalResultInspection}
                onClose={handleCloseModalResultInspection}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Agregar resultados de área inspeccionada"
                    functionOnClose={handleCloseModalResultInspection}
                />
                <form onSubmit={handlePostsResultInternalInspection}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <SelectControla
                            text="Área inspeccionada:"
                            inputValue={setAreaSelected}
                            modalType={true}
                            required
                            value={areaSelected}
                            childrenRows={rowsInternalInspection.map((row) => (
                                <MenuItem key={row.id} value={row.id_areas}>
                                    {row.area}
                                </MenuItem>
                            ))}
                        />

                        <InputControlaVertical
                            text={"Resultado de la inspección:"}
                            inputType={"textArea"}
                            inputValue={setResultInspection}
                            value={resultInspection}
                            modalType={true}
                            required
                        />
                        <InputControlaVertical
                            text={"Descripción de la causa ante resultados desfavorables:"}
                            inputType={"textArea"}
                            inputValue={setCausesResultInspection}
                            value={causesResultInspection}
                            modalType={true}
                            required
                        />

                        <InputControlaVertical
                            text={"Medidas correctivas:"}
                            inputType={"textArea"}
                            inputValue={setCorrectiveMeasuresResultInspection}
                            value={correctiveMeasuresResultInspection}
                            modalType={true}
                            required
                        />

                        <SearchButtonControla
                            titleLabel="Nombre del responsable: "
                            inputValue={responsibleMeasuresResultInspection}
                            functionClick={handleOpenModalResponsible}
                            modalType={true}
                            required
                        />

                        <InputControlaVertical
                            text={"Fecha de ejecución:"}
                            inputType={"date"}
                            inputValue={setStartDateResultInspection}
                            value={startDateResultInspection}
                            modalType={true}
                            required
                        />

                    </DialogContent>

                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalResultInspection}
                            />
                            <ButtonControla
                                textButton={"Crear"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>

                </form>
            </Dialog>

            {/* dialogo de actualizar resultado de inspección */}
            <Dialog
                open={openModalUpdateResultInspection}
                onClose={handleCloseModalUpdateResultInspection}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Actualizar resultados de área inspeccionada"
                    functionOnClose={handleCloseModalUpdateResultInspection}
                />
                <form onSubmit={handleUpdateResultInspection}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <InputControlaVertical
                            text={"Resultado de la inspección:"}
                            inputType={"textArea"}
                            inputValue={setResultInspection}
                            value={resultInspection}
                            modalType={true}
                            required
                        />
                        <InputControlaVertical
                            text={"Descripción de la causa ante resultados desfavorables:"}
                            inputType={"textArea"}
                            inputValue={setCausesResultInspection}
                            value={causesResultInspection}
                            modalType={true}
                            required
                        />

                        {/* <InputControlaVertical
                            text={"Medidas correctivas:"}
                            inputType={"textArea"}
                            inputValue={setCorrectiveMeasuresResultInspection}
                            value={correctiveMeasuresResultInspection}
                            modalType={true}
                            required
                        /> */}
                    </DialogContent>

                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalUpdateResultInspection}
                            />
                            <ButtonControla
                                textButton={"Actualizar"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>

            {/* dialog de responsables */}
            <Dialog
                open={openModalResponsible}
                onClose={handleCloseModalResponsible}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Seleccionar responsable"
                    functionOnClose={handleCloseModalResponsible}

                />
                <DialogContent sx={{ paddingTop: "0" }}>
                    <SearchUsersControla
                        rowsUser={rowUsersResponsibles}
                        handleUsersActionButton={handleSelectUserResponsible}
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonControla
                        textButton={"Cancelar"}
                        backgroundColor={"#CBCBFF"}
                        functionOnClick={handleCloseModalResponsible}
                    />
                </DialogActions>
            </Dialog>

            {/* dialog responsable del registro */}
            <Dialog
                open={openModalCreateResponsibleRegister}
                onClose={handleCloseModalCreateResponsibleRegister}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Seleccionar responsable del registro"
                    functionOnClose={handleCloseModalCreateResponsibleRegister}
                />
                <DialogContent sx={{ paddingTop: "0" }}>
                    <SearchUsersControla
                        rowsUser={rowUsersResponsibles}
                        handleUsersActionButton={handleSelectUserResponsibleRegister}
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonControla
                        textButton={"Cancelar"}
                        backgroundColor={"#CBCBFF"}
                        functionOnClick={handleCloseModalCreateResponsibleRegister}
                    />
                </DialogActions>
            </Dialog>
        </Box>
    )

}
export default Edit;