import MyConfigurationApi from "./MyConfigurationApi";

function getSectionsModules(id_companies) {
  const url = `configurationSections/get/${id_companies}`;

  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function getSubModules(id_config_section) {
  const url = `getSubModulesByModule/${id_config_section}`;

  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

const MyConfigurationModulesApi = {
  getSectionsModules,
  getSubModules
}

export default MyConfigurationModulesApi;
