import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import {
    AssignmentTurnedIn,
    Delete,
    Publish,
    VisibilityOutlined,
} from "@mui/icons-material";

import { useAppContext } from "../../../context/AppContext";

function ListRit(props) {
    const {permissions} = useAppContext();

    const {
        rit,
        colorCell,
        handleOpenModalConfirm
    } = props
    return (
        <TableBody>
            {rit.map((row) => (
                <TableRow
                    key={row.id}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        padding: "4px",
                    }}
                >
                    <TableCell component="th" scope="row" sx={{ color: colorCell }}>
                        {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ color: colorCell }}>
                        {row.register_status === 1
                            ? "Creado"
                            : row.register_status === 4
                                ? "Publicado"
                                : ""}
                    </TableCell>

                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonIconControla
                                roles={permissions.DOC_RIT_VER}
                                icon={
                                    <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={row.file}
                                directionTarget={"_blank"}
                            />
                            {/* <ButtonIconControla
                                icon={<ArchiveIcon sx={{ color: "white" }} />}
                                backgroundColor={"#F7C80B"}
                                backgroundColorHover={"#F7C80B"}
                                textTooltip={"Archivar"}
                                // directionUrl={row.file}
                                // directionTarget={"_blank"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Está seguro de archivar este RIT?",
                                    "Puede recuperar el documento en la sección de Archivados",
                                    "Sí, archivar",
                                    "archiveRit"
                                  );
                                }}
                              /> */}
                            {
                                row.register_status !== 4 ? (
                                    <>
                                        <ButtonIconControla
                                            roles={permissions.DOC_RIT_CUMPLIMIENTO}
                                            icon={
                                                <AssignmentTurnedIn
                                                    sx={{ color: "white" }}
                                                />
                                            }
                                            backgroundColor={"#FFDD00"}
                                            backgroundColorHover={"#F8E14E"}
                                            textTooltip={"Cumplimientos"}
                                            directionUrl={"/documentation/rit/fulfillments?idRit=" + row.id}
                                            directionTarget={"_blank"}
                                        />
                                    </>
                                ) : <></>
                            }
                            {row.register_status == 4 && (
                                <ButtonIconControla
                                    roles={permissions.DOC_RIT_ELIMINAR}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar b"}
                                    functionOnClick={() => {
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de eliminar el documento: " +
                                            row.name +
                                            "?",
                                            "",
                                            "Si, eliminar",
                                            "deleteRit"
                                        );
                                    }}
                                />
                            )}
                            {row.register_status === 1 ? (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_RIT_PUBLICAR}
                                        icon={<Publish sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Publicar"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                row.id,
                                                "¿Estás seguro de publicar?",
                                                'Una vez publicado no podrás revertir la operación del documento:  "' +
                                                row.name +
                                                '"',
                                                "Si, publicar",
                                                "publishRit"
                                            );
                                        }}
                                    />{" "}
                                    <ButtonIconControla
                                        roles={permissions.DOC_RIT_ELIMINAR}
                                        icon={<Delete sx={{ color: "white" }} />}
                                        backgroundColor={"#EB5757"}
                                        backgroundColorHover={"#FF4040"}
                                        textTooltip={"Eliminar"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                row.id,
                                                "¿Estás seguro de eliminar el documento: " +
                                                row.name +
                                                "?",
                                                "",
                                                "Si, eliminar",
                                                "deleteRit"
                                            );
                                        }}
                                    />{" "}
                                </>
                            ) : (
                                <></>
                            )}
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListRit;