import MyConfigurationApi from "./MyConfigurationApi";

function getHealthAndSafetyStatistics (){
    const url = "form_health_safety_statistics_records";
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function getHealthAndSafetyStatisticsById(idHealthAndSafetyStatistics){
    const url = "form_health_safety_statistics_records/" + idHealthAndSafetyStatistics;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function postHealthAndSafetyStatistics(data){
    const url = "form_health_safety_statistics_records";
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

function updateHealthAndSafetyStatistics(data,idHealthAndSafetyStatistics){
    const url = "form_health_safety_statistics_records/" + idHealthAndSafetyStatistics;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });
}

function getResults (idHealthAndSafetyStatistics){
    const url = `sec/form_health_safety_statistics_records_result_involved/${idHealthAndSafetyStatistics}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function postResults(data, idHealthAndSafetyStatistics){
    const url = `sec/form_health_safety_statistics_records_result_involved/${idHealthAndSafetyStatistics}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

function updateResult (data,idResult){
    const url = `sec/form_health_safety_statistics_records_result_involved/detail/${idResult}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });
}

function deleteResult(idResult){
    const url = `sec/form_health_safety_statistics_records_result_involved/detail/${idResult}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}

function getCauses (idHealthAndSafetyStatistics){
    const url = `sec/form_health_safety_statistics_records_analysis_causes_involved/${idHealthAndSafetyStatistics}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function postCauses(data, idHealthAndSafetyStatistics){
    const url = `sec/form_health_safety_statistics_records_analysis_causes_involved/${idHealthAndSafetyStatistics}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

function updateCauses(data, idCauses){
    const url = `sec/form_health_safety_statistics_records_analysis_causes_involved/detail/${idCauses}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });
}

function deleteCauses(idCauses){
    const url = `sec/form_health_safety_statistics_records_analysis_causes_involved/detail/${idCauses}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}

function getConclusions(idHealthAndSafetyStatistics){
    const url = `sec/form_health_safety_statistics_records_conclusions_recommendations_involved/${idHealthAndSafetyStatistics}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function postConclusions(data,idHealthAndSafetyStatistics){
    const url = `sec/form_health_safety_statistics_records_conclusions_recommendations_involved/${idHealthAndSafetyStatistics}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "POST",
        url: url,
    });

}

function updateConclusions(data, idConclusions){
    const url = `sec/form_health_safety_statistics_records_conclusions_recommendations_involved/detail/${idConclusions}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });
}

function deleteConclusions(idConclusions){
    const url = `sec/form_health_safety_statistics_records_conclusions_recommendations_involved/detail/${idConclusions}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}

function getResponsibles (idHealthAndSafetyStatistics){
    const url = `${idHealthAndSafetyStatistics}/responsible`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function postResponsible(data, idHealthAndSafetyStatistics ){
    const url = `${idHealthAndSafetyStatistics}/responsible`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

function deleteResponsible(idResponsible){
    const url = `responsible/detail/${idResponsible}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}

function signResponsible(data, idResponsible){
    const url = `responsible/detail/${idResponsible}`;
    return MyConfigurationApi.connectFormApi({
        dataJson: data,
        method: "PUT",
        url: url,
    });
}

const MyHealthAndSafetyStatistics = {
    getHealthAndSafetyStatistics,
    getHealthAndSafetyStatisticsById,
    postHealthAndSafetyStatistics,
    updateHealthAndSafetyStatistics,
    getResults,
    postResults,
    updateResult,
    deleteResult,
    getCauses,
    postCauses,
    updateCauses,
    deleteCauses,
    getConclusions,
    postConclusions,
    updateConclusions,
    deleteConclusions,
    getResponsibles,
    postResponsible,
    deleteResponsible,
    signResponsible




};

export default MyHealthAndSafetyStatistics;