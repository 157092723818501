import MyConfigurationApi from "../../api/MyConfigurationApi";
import { CSVLink } from "react-csv";
import { FileDownloadOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import {
    Button,
  } from "@mui/material";

const white = "#ffffff";
const StyledButtonDownload = styled(Button)({
    textTransform: "none",
    backgroundColor: white,
    borderRadius: 16,
    '&:hover': {
        background: white,
      }
});

function ButtonDownloadCsvControla({
    backgroundColor,
    color,
    textButton,
    // backgroundColorHover = undefined,
    roles = [],
    // tooltipText,
    data = []
}) {
    const userData = MyConfigurationApi.userData();
    let usersRoles = userData.roles;
    console.log(roles)

    let showButton = false;
    roles = roles.length === 0 ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16] : roles;

    roles.map((rol) => {
        if (usersRoles.includes(rol)) {
            showButton = true;
        }
    });

    if (showButton === true) {
        return(
            <StyledButtonDownload
                  variant="text"
                  startIcon={<FileDownloadOutlined />}
                  style={{
                    backgroundColor: backgroundColor,
                    borderRadius: "15px",
                    color: color,
                    padding: "5px  0 5px 25px ",
                  }}
                >
                  <CSVLink
                    variant="text"
                    size="large"
                    style={{
                      backgroundColor: backgroundColor,
                      borderRadius: "15px",
                      color: color,
                      padding: "5px  25px 5px 0 ",
                      textDecoration: "none",
                    }}
                    data={data}
                    filename={"datos.csv"}
                    separator={";"}
                  >
                    {textButton}
                  </CSVLink>
                </StyledButtonDownload>
        );
    } else {
        return "";
    }


}

export default ButtonDownloadCsvControla;