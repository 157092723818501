import { React, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {  MenuItem,Dialog,DialogContent,DialogActions,Button, Card, CardContent, CardMedia, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { Add, BorderColorOutlined,Edit,Delete } from '@mui/icons-material';
import SubtitleText from '../../../../components/text/subtitleText';
import TitleText from '../../../../components/text/titleText';
import MyAts from '../../../../api/MyAts';
import MyUsers from '../../../../api/MyUsers';
import InputControlaVertical from '../../../../components/textfields/inputControlaVertical';
import CompanyLogoControla from '../../../../components/images/companyLogoControla';
import ButtonControla from '../../../../components/buttons/buttonController';
import ButtonIconControla from '../../../../components/buttons/buttonIconControla';
import SelectControla from '../../../../components/selects/selectControla';
import SearchEppsControla from '../../../../components/search/searchEppsControla';
import SearchButtonControla from '../../../../components/search/searchButtonControla';
import SearchUsersControla from '../../../../components/search/searchUsersControla';
import LoadingControla from '../../../../components/load/loadingControla';
import DialogConfirmControla from '../../../../components/dialog/dialogConfirmControla';
import DialogTitleControla from '../../../../components/dialog/dialogTitleControla';
import MySessionStorage from '../../../../api/MySessionStorage';
import MyAreas from '../../../../api/MyAreas';
import { useAppContext } from '../../../../context/AppContext';
import { useNavigate } from 'react-router-dom';
import ReturnBack from '../../../../components/buttons/returnBackControla';

const grey = "#9191B0";
const blue = "#034AFF";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";

const StyledPrintButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: "#F2F2F2",
    color: blue,
    width: "100%"
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: 800,
    overflow: 'visible',
    border: '1px solid #000',
    backgroundColor: 'background.paper',
    borderRadius: '16px',
    boxShadow: 24,
    p: 4,
};

const StyledCancelButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: lilac,
    color: 'black',
    width: "100%"
});

const StyledAddButton = styled(Button)({
    textTransform: 'none',
    backgroundColor: skyblue,
    borderRadius: 16
});

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
})

function Model() {
    const { permissions } = useAppContext();
    const navigate = useNavigate();
    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert('');
        setTypeAlert('success');
    }

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    // Pantallas de carga y alertas FIN


    // Dialogos de confirmacion INICIO

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState('');
    const [contentText, setContentText] = useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = useState('');


    const handleOpenModalConfirm = (id,titleText, contentText, buttonTextConfirm) => {
        setIdAtsTaks(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setOpenModalConfirm(true);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }


    // Dialogos de confirmacion FIN

    const params = new URLSearchParams(window.location.search);
    const id_ats = params.get('id_ats');

    const [openModalEpps, setOpenModalEpps] = useState(false);
    const [rowsEpps, setRowsEpps] = useState([]);

    const handleOpenModalEpps = () => {
        handleAtsNoEpps();
        setOpenModalEpps(true);
    }

    const handleCloseModalEpps = () => {
        setOpenModalEpps(false);
    }

    const handleSelectEpps = async (idEpps) => {
        handleOpenLoading();
        const response = await MyAts.eppsStore({ id_ats, id_epps: idEpps });

        if (response.success !== false) {
            setRowsEpps([]);
            setOpenModalEpps(false);
            handleAtsNoEpps();
            handleAtsEpps();
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert('Hubo un error', 'error');
        }
        return response;
    }

    const handleSelectDeleteEpps = async (idAtsEpps) => {
        handleOpenLoading();
        const response = await MyAts.eppsDelete({ id_ats_epps: idAtsEpps });

        if (response.success !== false) {
            setRowsEpps([]);
            setOpenModalEpps(false);
            handleAtsNoEpps();
            handleAtsEpps();
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert('Hubo un error', 'error');
        }
        return response;
    }




    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [areas, setAreas] = useState([]);

    const [version, setVersion] = useState('');
    const [createDate, setCreateDate] = useState('');

    const [place, setPlace] = useState('');
    const [hourStart, setHourStart] = useState('');
    const [hourFinish, setHourFinish] = useState('');
    const [description, setDescription] = useState('');
    const [tools, setTools] = useState('');
    const [procedure, setProcedure] = useState('');
    const [observation, setObservation] = useState('');


    const [rowsEppsAts, setRowsEppsAts] = useState([]);

    // cargar areas
    const [areaSelected, setAreaSelected] = useState(0);
    const getAreas = async () => {
        const resp = await MyAreas.getAreas();
        setAreas(resp)
    }

    const handleEditAts = async () => {
        const response = await MyAts.editAts({ id_ats });

        if (response.success === false) {
            alert('Error');
        } else {
            setName(response.data.name);
            setCode(response.data.code);
            setVersion(response.data.version);
            setAreaSelected(response.data.area);

            setPlace(response.data.place);
            setHourStart(response.data.start_time);
            setHourFinish(response.data.end_time);
            setDescription(response.data.description);
            setTools(response.data.tools);
            setProcedure(response.data.procedure);
            setObservation(response.data.observation);
            setCreateDate(response.data.timestamp_created.substring(0, 10))

        }
        return response;
    };

    const handleAtsNoEpps = async () => {
        const response = await MyAts.eppsNoAtsGet({ id_ats });

        if (response.success === false) {
            alert('Error');
        } else {
            setRowsEpps(response);
        }
        return response;
    };

    const handleAtsEpps = async () => {
        const response = await MyAts.eppsAtsGet({ id_ats });

        if (response.success === false) {
            alert('Error listando los epps');
        } else {
            setRowsEppsAts(response);
        }
        return response;
    };

    //tareas
    const [rowsTasks, setRowsTasks] = useState([]);
    const [idAtsTaks, setIdAtsTaks]= useState(null);
    const [dataUserSelected, setDataUserSelected] = useState('');
    const [idUserSelected, setIdUserSelected] = useState(0);
    const [stepsTasks, setStepsTasks] = useState('');
    const [dangersTasks, setDangersTasks] = useState('');
    const [risksTasks, setRisksTasks] = useState('');
    const [measuresTasks, setMeasuresTasks] = useState('');

    const [openModalTasks, setOpenModalTasks] = useState(false);
    const [typeModalTaks, setTypeModalTaks] = useState("");

    const handleOpenModalTasks = (typeModal, data) => {
        setOpenModalTasks(true);

        setTypeModalTaks(typeModal);

        if(typeModal == "update"){
            setIdAtsTaks(data.id_ats_tasks);
            setDataUserSelected(data.first_name + ' ' + data.last_name);
            setStepsTasks(data.steps);
            setDangersTasks(data.dangers);
            setRisksTasks(data.risks);
            setMeasuresTasks(data.measures);
        }else{
            setDataUserSelected(null);
            setStepsTasks(null);
            setDangersTasks(null);
            setRisksTasks(null);
            setMeasuresTasks(null);
        }
    }
    const handleCloseModalTasks = () => {
        setOpenModalTasks(false);
    }

    const handleActionModalTasks = (e) => {
        e.preventDefault();

        switch(typeModalTaks){
            case "create":
                handlePostTasks();
                break;
            case "update":
                handleUpdateTasks();
                break;
        }

    }

    const handleGetTasks = async() => {
        const response = await MyAts.atsTasksGet({ id_ats }) ?? [];
        setRowsTasks(response);
    }

    const handlePostTasks = async() => {
        handleOpenLoading();

        const resp = await MyAts.createAtsTasks({ id_ats, id_users: idUserSelected, steps: stepsTasks, dangers: dangersTasks, risks: risksTasks, measures: measuresTasks });
        if(resp.success ==true){
            await handleGetTasks();
            handleOpenAlert("Se agregó la tarea al modelo con éxito", "success");
        }else{
            handleOpenAlert("Ocurrió un error al registrar la tarea", "error");   
        }

        handleCloseModalTasks();
    }

    const handleUpdateTasks = async() => {
        handleOpenLoading();

        const resp = await  MyAts.updateAtsTasks({id_ats_tasks: idAtsTaks , id_users: idUserSelected,steps:stepsTasks  ,dangers: dangersTasks,risks: risksTasks, measures: measuresTasks });
        if(resp.success == true){
            await handleGetTasks();
            handleOpenAlert("Se actualizó la tarea con éxito", "success");
        }else{
            handleOpenAlert("Ocurrió un error al actualizar", "error");
        }

        handleCloseModalTasks();
    }

    const handleDeleteTasks = async() => {
        handleOpenLoading();

        const resp = await MyAts.tasksAtsDelete({ id_ats_tasks:idAtsTaks });

        if(resp.success == true){
            await handleGetTasks(); 
            handleOpenAlert("Se eliminó la tarea con éxito", "success");
        }else{
            handleOpenAlert("Ocurrió un error al eliminar", "error");
        }
        
        handleCloseModalConfirm();
    }

    //modal de usuarios
    const [openModalSelectUser, setOpenModalSelectUser] = useState(false);
    const [rowsUsers, setRowsUsers] = useState([]);

    const handleOpenModalSelectUser = () => {
        // handleUsersNoTasks();
        setOpenModalSelectUser(true)
    };
    const handleCloseModalSelectUser = () => setOpenModalSelectUser(false);

    const handleGetUsers = async() => {
        const resp = await MyUsers.users();
        setRowsUsers(resp.users_actives);
    }

    const handleSelectUser = async(idUser) =>{
        const response = await MyUsers.editUsers({ idUsers: idUser });

        if(response.success == true){
            setIdUserSelected(idUser);
            setDataUserSelected(response.first_name + ' ' + response.last_name);
            handleCloseModalSelectUser();
        }else{
            alert("Problema al traer datos del usuario seleccionado");
        }
    }

    useEffect(() => {
        handleEditAts();
        handleAtsEpps();
        getAreas();

        handleGetTasks();

        handleGetUsers();
    }, []);

    const handlePutUpdateAts = async () => {
        const area = areaSelected
        handleOpenLoading();
        const response = await MyAts.updateAts({ id_ats, name, code, version, area, place, start_time: hourStart, end_time: hourFinish, description: description, tools, procedure, observation: observation, createDate });

        if (response.success !== false) {
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert('Hubo un error al actualizar', 'success');
        }
        return response;
    };

    const handleStoreAtsModel = async () => {
        handleOpenLoading();
        const response = await MyAts.storeAtsModel({ id_ats: id_ats });

        if (response.success !== false) {
            MySessionStorage.setSession('messageAlert', response.msg);
            MySessionStorage.setSession('typeAlert', 'success');

            const params = {
                readOnly: false
            }

            navigate('/ats/edit?id_ats=' + response.id_ats, { state: params })

        } else {
            handleOpenAlert('Hubo un error al actualizar', 'success');
        }
        return response;
    };



    return (
        <>
            <Box sx={{ height: "100%" }}>

                <ReturnBack/>

                <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

                <DialogConfirmControla openModalConfirm={openModalConfirm} handleCloseModalConfirm={handleCloseModalConfirm} titleText={titleText} contentText={contentText} buttonTextConfirm={buttonTextConfirm} handleActionConfirm={handleDeleteTasks} />

                <SearchEppsControla openModalEpps={openModalEpps} handleCloseModalEpps={handleCloseModalEpps} handleClickSelectEpps={handleSelectEpps} rowsEpps={rowsEpps} />

                <Container sx={{ paddingBottom: 4 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                            <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
                                <SubtitleText text={"ATS"} color={grey}></SubtitleText>
                                <TitleText text={"Editar o usar modelo de analisis de trabajo seguro"} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                                <CompanyLogoControla />
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={9}>
                                <InputControlaVertical text={'Nombre del ATS:'} inputType={'text'} inputValue={setName} modalType={false} value={name} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2} xl={3}>
                                <InputControlaVertical text={'Código:'} inputType={'text'} inputValue={setCode} modalType={false} value={code} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2} xl={3}>
                                <InputControlaVertical text={'Versión'} inputType={'text'} inputValue={setVersion} modalType={false} value={version} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2} xl={3}>
                                <InputControlaVertical text={'Fecha'} inputType={'date'} inputValue={setCreateDate} modalType={false} value={createDate} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <SelectControla
                                    text="Área:"
                                    inputValue={setAreaSelected}
                                    value={areaSelected}
                                    childrenRows={areas.map((a) => (
                                        <MenuItem key={a.id_areas} value={a.id_areas}>
                                            {a.area}
                                        </MenuItem>
                                    ))}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Lugar:'} inputType={'text'} inputValue={setPlace} modalType={false} value={place} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Hora de Inicio:'} inputType={'time'} inputValue={setHourStart} modalType={false} value={hourStart} />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Hora de Fin:'} inputType={'time'} inputValue={setHourFinish} modalType={false} value={hourFinish} />
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <InputControlaVertical text={'Descripción del Trabajo:'} inputType={'textArea'} inputValue={setDescription} modalType={false} value={description} />
                            </Grid> */}

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                <StyledTypography sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>Tareas</StyledTypography>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                <ButtonControla
                                    iconButton={<Add sx={{ color: "white" }} />}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={"Agregar tarea"}
                                    functionOnClick={()=> handleOpenModalTasks("create",null)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Paper sx={{ overflow: 'hidden' }}>
                                    <TableContainer sx={{ minWidth: 650 }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ color: blue }} align="center">Nombre</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Pasos de la tarea</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Peligros</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Riesgos potenciales</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Medidas preventivas</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowsTasks
                                                    .map((rowsTasks) => (
                                                        <TableRow hover key={rowsTasks.id_ats_tasks} >
                                                            <TableCell >
                                                                {rowsTasks.first_name + ' ' + rowsTasks.last_name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowsTasks.steps}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowsTasks.dangers}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowsTasks.risks}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowsTasks.measures}
                                                            </TableCell>
                                                            <TableCell align="center" >
                                                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                                                    <ButtonIconControla
                                                                        icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={"#2D9CDB"}
                                                                        backgroundColorHover={"#33AEF4"} textTooltip={'Editar'}
                                                                        functionOnClick={() => handleOpenModalTasks("update", rowsTasks)}
                                                                    />

                                                                    <ButtonIconControla
                                                                        icon={<Delete sx={{ color: 'white' }} />} backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"} textTooltip={'Eliminar'}
                                                                        functionOnClick={() => handleOpenModalConfirm(
                                                                            rowsTasks.id_ats_tasks,
                                                                            "¿Estás seguro de eliminar la tarea?",
                                                                            "Una vez eliminado no se podrá recuperar la información de la tarea",
                                                                            "Si, eliminar"
                                                                        )} />
                                                                </Stack>
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                <StyledTypography sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>EPPS</StyledTypography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">

                                <ButtonControla
                                    roles={permissions.DOC_HG_PROC_ATS_MOD_AGREGAR_EPP}
                                    iconButton={<Add sx={{ color: "white" }} />}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={"Agregar EPP"}
                                    functionOnClick={handleOpenModalEpps}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >

                                <Paper sx={{ overflow: 'hidden' }}>
                                    <Grid container spacing={2} sx={{ width: '100%', margin: 'auto' }} >
                                        {rowsEppsAts
                                            .map((rowsEpps) => (

                                                <Grid item key={rowsEpps.id_epps} xs={12} sm={6} md={4} lg={3} xl={3} sx={{ p: 2 }}>
                                                    <Card sx={{
                                                        display: 'flex', justifyContent: 'space-between', height: 80,
                                                        "&:hover": {
                                                            cursor: 'pointer',
                                                            border: "1px solid #0F1255",
                                                            boxShadow: '0px 4px #0F1255',
                                                        },
                                                    }} onClick={() => { handleSelectDeleteEpps(rowsEpps.id_ats_epps) }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                                <Typography sx={{ fontSize: '14px' }}>
                                                                    {rowsEpps.epp}
                                                                </Typography>
                                                            </CardContent>
                                                        </Box>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ maxWidth: 80, marginLeft: 0, maxHeight: 80, objectFit: 'contain' }}
                                                            image={rowsEpps.photo_url}
                                                            alt="Live from space album cover"
                                                        />
                                                    </Card>

                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical text={'Herramientas, equipo y material:'} inputType={'textArea'} inputValue={setTools} modalType={false} value={tools} />
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical text={'Procedimiento:'} inputType={'textArea'} inputValue={setProcedure} modalType={false} value={procedure} />
                            </Grid> */}

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical text={'Observaciones:'} inputType={'textArea'} inputValue={setObservation} modalType={false} value={observation} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                                    <Box>
                                        <ButtonControla
                                            roles={permissions.DOC_HG_PROC_ATS_MOD_REGRESAR}
                                            backgroundColor={'#CBCBFF'}
                                            textButton={'Cancelar'}
                                            backgroundColorHover={'#CBCBFF'}
                                            typeButton={'button'}
                                            isNavegationOrigin={true}
                                            url={'/ats'}
                                        />
                                    </Box>
                                    <Box>
                                        <ButtonControla
                                            roles={permissions.DOC_HG_PROC_ATS_MOD_ACTUALIZAR_MODELO}
                                            iconButton={<Edit sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Actualizar modelo"}
                                            typeButton={'button'}
                                            functionOnClick={handlePutUpdateAts}
                                        />
                                    </Box>
                                    <Box>
                                        <ButtonControla
                                            roles={permissions.DOC_HG_PROC_ATS_MOD_USAR_MODELO}
                                            backgroundColor={"#F2994A"}
                                            backgroundColorHover={"#FF881E"}
                                            textButton={"Usar modelo"}
                                            typeButton={'button'}
                                            functionOnClick={handleStoreAtsModel}

                                        />
                                    </Box>

                                </Stack>
                            </Grid>
                        </Grid>


                    </Box>
                </Container>

                {/* dialogo de tareas */}
                <Dialog
                    open={openModalTasks}
                    onClose={handleCloseModalTasks}
                    maxWidth="md"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText={typeModalTaks=="create" ?'Agregar Tarea' : 'Actualizar Tarea'} functionOnClose={handleCloseModalTasks} />
                    <form onSubmit={handleActionModalTasks}>
                        <DialogContent >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <SearchButtonControla titleLabel={'Usuario:'} inputValue={dataUserSelected} functionClick={handleOpenModalSelectUser} modalType={true} required />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                    <InputControlaVertical text={'Pasos de la tarea:'} inputType={'textArea'} inputValue={setStepsTasks} value={stepsTasks} modalType={true} required />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                    <InputControlaVertical text={'Peligros:'} inputType={'textArea'} inputValue={setDangersTasks} value={dangersTasks} modalType={true} required />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                    <InputControlaVertical text={'Riesgos potenciales:'} inputType={'textArea'} inputValue={setRisksTasks} value={risksTasks} modalType={true} required />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                    <InputControlaVertical text={'Medidas preventivas:'} inputType={'textArea'} inputValue={setMeasuresTasks} value={measuresTasks} modalType={true} required />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Stack direction={'row'} spacing={2}>
                                <Box>
                                    <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalTasks} />
                                </Box>
                                <Box>
                                    <ButtonControla iconButton={<Add />} backgroundColor={'#169073'} textButton={typeModalTaks == "create"? 'Agregar': "Actualizar"} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                </Box>
                            </Stack>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* dialogo de seleccionar  usuarios */}
                <Dialog
                    open={openModalSelectUser}
                    onClose={handleCloseModalSelectUser}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText='Buscar Usuario' functionOnClose={handleCloseModalSelectUser} />
                    <DialogContent >
                        <SearchUsersControla rowsUser={rowsUsers} handleUsersActionButton={handleSelectUser} />
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={'row'} spacing={2}>
                            <Box>
                                <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalSelectUser} />
                            </Box>
                        </Stack>
                    </DialogActions>
                </Dialog>
            </Box >
        </>
    )
}

export default Model