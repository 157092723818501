// import * as React from "react";
import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Grid, Modal, Typography, Button, MobileStepper } from "@mui/material";
import Container from "@mui/material/Container";
import BannerHomeButtonControla from "../cards/bannerHomeButtonControla";
import BannerVerticalControla from "../cards/bannerVerticalControla";
import { useNavigate } from "react-router-dom";
import DialogInformativeControla from "../dialog/dialogInformativeControla";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import MyConfigurationApi from "../../api/MyConfigurationApi";
import { useModulesFree } from "../../baseline/hooks/userModulesFree";
import { useAppContext } from "../../context/AppContext";
import LoadingControla from "../load/loadingControla";
import SideNav from  "../sidenav/layoutSidenav";


const grey = "#9191B0";
const blue = "#034AFF";

const steps = [
  {
    label: 'Paso 1',
    imgPath: 'https://firebasestorage.googleapis.com/v0/b/controla-general/o/paso1.png?alt=media&token=4a319c60-de22-4cbd-9a1b-bbeab650baa2',
    description: 'Haz clic en “Evalúa tu sistema de gestión” para comenzar.',
  },
  {
    label: 'Paso 2',
    imgPath: 'https://firebasestorage.googleapis.com/v0/b/controla-general/o/paso2.png?alt=media&token=5733abc5-4485-45d7-8570-6c1ffd1f76b6',
    description: 'Accede a “Mis diagnósticos” para ver y gestionar tus evaluaciones.',
  },
  {
    label: 'Paso 3',
    imgPath: 'https://firebasestorage.googleapis.com/v0/b/controla-general/o/paso3.png?alt=media&token=b5bc996c-7356-4766-98c0-7ef3cdf7177b',
    description: 'Selecciona “Iniciar línea base” para comenzar la evaluación.',
  },
  {
    label: 'Paso 4',
    imgPath: 'https://firebasestorage.googleapis.com/v0/b/controla-general/o/paso4.png?alt=media&token=ef7b4a18-e87e-4d00-8588-f7ec04e48acc',
    description: 'Completa las preguntas y obtendrás un informe detallado sobre la situación de tu empresa en SSO.',
  }
];

function HomeView() {
  const navigate = useNavigate();
  const{sectionsModules} = useAppContext();

  const user = MyConfigurationApi.userData();
  const nameUser = user.first_name;

  const {isBaselineFree} = useModulesFree();
  
  const isFree = user.is_free;
  const [open, setOpen] = useState(user.is_free == null ? false : true);
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openModalInformative, setOpenModalInformative] = useState(false);

  const handleOpenModalInformative = () => {
    setOpenModalInformative(true);
  }

  const handleCloseModalInformative = () => {
    setOpenModalInformative(false);
  }

  const handleValidateisFreeUser = (url) => {

      if (isFree == 1 || isBaselineFree) {
        handleOpenModalInformative();
      } else {
        navigate(url);
      }
  }

  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(true);
  const [messageAlert, setMessageAlert] = useState('');
  const [typeAlert, setTypeAlert] = useState('success');

  const handleOpenLoading = () => {
      setOpenAlert(false);
      setOpenLoading(true);
      setMessageAlert('');
      setTypeAlert('success');
  }

  const handleOpenAlert = (alertMessage, alertType) => {
      setOpenAlert(true);
      setOpenLoading(false);
      setMessageAlert(alertMessage);
      setTypeAlert(alertType);
  }

  const handleCloseAlert = () => {
      setOpenAlert(false);
  }

  // Pantallas de carga y alertas FIN
 
  useEffect(()=>{
    if(sectionsModules.length !== 0){
      setOpenLoading(false);
    }
  }, [sectionsModules])

  


  return (
    <Box sx={{ display: "flex", backgroundColor: "#F5F4F6" }}>
      <SideNav />
      <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />
      <Container>
        <Box sx={{ flexGrow: 1}}>
          <Grid container rowSpacing={2} columnSpacing={2}>

            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
              <BannerHomeButtonControla
                color1={"#3863FB"}
                color2={"#161A78"}
                title={`Hola ${nameUser}  ¡Bienvenido a CONTROLA!`}
                subtitle={
                  "Espacio perfecto para organizar tu trabajo en el sistema de gestión SSO "
                }
                textButton={"Comenzar ahora"}
                image={"/assets/images/dashboard/bienvenido.png"}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <BannerHomeButtonControla
                color1={"#1638F2"}
                color2={"#5683F6"}
                subtitle={"Recuerda revisar regularmente tu buzón "}
                image={"/assets/images/dashboard/buzon.png"}
                functionClick={() => handleValidateisFreeUser("/mailbox")}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} columnSpacing={2}
              sx={{ display: 'flex', flexWrap: 'wrap', columnGap: "20px" }}>
              <BannerVerticalControla
                color1={"#7575CC"}
                color2={"#4242B1"}
                title={"Organiza tu documentación fácilmente"}
                image={"/assets/images/dashboard/tareas.png"}
                functionClick={() => handleValidateisFreeUser("/documentation")}
              />

              <BannerVerticalControla
                roles={[1, 7, 8, 12, 13]}
                color1={"#9B51E0"}
                color2={"#7432B1"}
                title={"Recuerda agendar la reunión mensual de tu comité"}
                image={"/assets/images/dashboard/buzon.png"}
                functionClick={() => handleValidateisFreeUser("/committee/scheduleMeetings")}
              />

              <BannerVerticalControla
                roles={[1, 2, 5, 6, 9, 10, 11, 15]}
                color1={"#0D579C"}
                color2={"#95CCFF"}
                title={"Revisa tus tareas regularmente"}
                image={"/assets/images/doccreationimage.png"}
                functionClick={() => handleValidateisFreeUser("/activities/task/mytasks")}
              />

              <BannerVerticalControla
                roles={[1, 2, 5, 6, 9, 10, 11, 12, 15]}
                color1={"#1638F2"}
                color2={"#5683F6"}
                title={"Realiza tus capacitaciones"}
                image={"/assets/images/dashboard/bienvenido.png"}
                functionClick={() => handleValidateisFreeUser('/trainings')}
              />

              <BannerVerticalControla
                roles={[1, 3, 4, 7, 8]}
                color1={"#0D579C"}
                color2={"#95CCFF"}
                title={"Evalúa tu sistema y toma acción"}
                image={"/assets/images/dashboard/recursos.png"}
                url={"/baseline"}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: '#fff',
            borderRadius: '16px',
            outline: 'none',
            padding: '16px',
          }}
        >
          <Box
            component="img"
            sx={{
              width: '100%',
              height: 'auto',
            }}
            src={steps[activeStep].imgPath}
            alt={steps[activeStep].label}
          />
          <Typography variant="h6" sx={{ color: '#000' }}>
            {steps[activeStep].label}
          </Typography>
          <Typography variant="body1" sx={{ color: '#000' }}>
            {steps[activeStep].description}
          </Typography>
          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              bgcolor: 'transparent',
              marginTop: '16px',
              color: '#000',
            }}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                Siguiente
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
                Anterior
              </Button>
            }
          />
        </Box>
      </Modal>

      <DialogInformativeControla
        open={openModalInformative}
        onClose={handleCloseModalInformative}
        message={"Acceso limitado. Activa tu suscripción para desbloquear esta función premium."}
      />
    </Box>
  );
}
export default HomeView;
