import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import { Archive, AssignmentTurnedIn, BorderColorOutlined, Delete, Email, FileUploadOutlined, Send } from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useAppContext } from "../../../context/AppContext";

const ListBorrador = (props) => {
  const { permissions } = useAppContext();

  const {
    rowsSsoPolitics,
    colorCell,
    idUserLogged,
    handleOpenModalConfirm } = props

  return (
    <TableBody>
      {rowsSsoPolitics.map((row) =>
        row.register_status != 4 &&
          row.register_status != 5 &&
          row.document_url == "" ? (
          <TableRow
            hover
            key={row.id}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell
              sx={{ color: colorCell }}
              component="th"
              scope="row"
            >
              {row.title}
            </TableCell>
            <TableCell sx={{ color: colorCell }}>
              {row.register_status === 1
                ? "Creado"
                : row.register_status === 3
                  ? "Enviado a comité"
                  : row.register_status === 7
                    ? "Enviado a trabajadores"
                    : row.register_status === 8
                      ? "Enviado a gerencia"
                      : row.register_status === 9
                        ? "Firmado por Gerente"
                        : ""}
            </TableCell>
            <TableCell sx={{ color: colorCell }}>
              <Stack
                direction="row"
                justifyContent="end"
                alignItems="center"
              >
                {row.register_status !== 8 && (
                  <>
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_BORRADOR_CUMPLIM}
                      icon={<AssignmentTurnedIn sx={{ color: "white" }}/> }
                      backgroundColor={"#FFDD00"}
                      backgroundColorHover={"#F8E14E"}
                      textTooltip={"Cumplimientos"}
                      directionUrl={"/documentation/ssopolitics/fulfillments?idSsoPolitics=" + row.id}
                      directionTarget={"_blank"}
                    />
                  </>
                )}

                {
                  row.approved_sign != 1 ? (
                    row.register_status !== 4 &&
                      row.register_status !== 9 ? (
                      <ButtonIconControla
                        roles={
                          row.register_status == 8 ? permissions.DOC_SSO_BORRADOR_EDITAR_ENVIADO_GERENCIA :
                            (row.register_status == 7 && row.id_users_revised == idUserLogged ? [1, 7, 8, 9] :
                              (row.register_status == 3 && row.id_users_approved == idUserLogged ? [1, 7, 8, 12, 13] :
                                permissions.DOC_SSO_BORRADOR_EDITAR))
                        }
                        icon={<BorderColorOutlined sx={{ color: "white" }} />}
                        backgroundColor={"#2D9CDB"}
                        backgroundColorHover={"#33AEF4"}
                        textTooltip={"Editar"}
                        isNavegationOrigin={true}
                        directionUrl={row.doc_env != 1
                          ? "/documentation/ssopolitics/template?idSsoPolitics=" +
                          row.id
                          : "/documentation/ssopolitics/templateThree?idSsoPolitics=" +
                          row.id}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    row.register_status == 8 ? (
                      <ButtonIconControla
                        roles={permissions.DOC_SSO_BORRADOR_EDITAR_ENVIADO_GERENCIA}
                        icon={<BorderColorOutlined sx={{ color: "white" }} />}
                        backgroundColor={"#2D9CDB"}
                        backgroundColorHover={"#33AEF4"}
                        textTooltip={"Editar"}
                        isNavegationOrigin={true}
                        directionUrl={row.doc_env != 1
                          ? "/documentation/ssopolitics/template?idSsoPolitics=" +
                          row.id
                          : "/documentation/ssopolitics/templateThree?idSsoPolitics=" +
                          row.id}
                      />
                    ) : ""
                  )
                }

                {row.register_status === 1 ? (
                  <>
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_BORRADOR_ENVIA_TRABAJ}
                      icon={<Send sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1AA483"}
                      textTooltip={"Enviar a trabajadores"}
                      functionOnClick={() => {
                        handleOpenModalConfirm(
                          row.id,
                          "¿Estás seguro de enviar a Trabajadores?",
                          "",
                          "Si, enviar",
                          "workers"
                        );
                      }}
                    />
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_BORRADOR_VER}
                      icon={<VisibilityOutlined sx={{ color: "white" }}/> }
                      backgroundColor={"#305AD9"}
                      backgroundColorHover={"#0E4AFF"}
                      textTooltip={"Ver"}
                      isNavegationOrigin={true}
                      directionUrl={
                        row.doc_env != 1
                          ? "/documentation/ssopolitics/template?idSsoPolitics=" +
                          row.id + " &readOnly=" + true
                          : "/documentation/ssopolitics/templateThree?idSsoPolitics=" +
                          row.id + " &readOnly=" + true
                      }
                    />
                  </>
                ) : row.register_status === 7 ? (
                  <>
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_BORRADOR_ENVIA_COMIT}
                      icon={<Send sx={{ color: "white" }} />}
                      backgroundColor={"#581845"}
                      backgroundColorHover={"#6D1F56"}
                      textTooltip={
                        row.id_users_approved != ""
                          ? "Enviar a comité"
                          : "Debe tener asignado un aprobador"
                      }
                      functionOnClick={
                        row.id_users_approved != ""
                          ? () => {
                            handleOpenModalConfirm(
                              row.id,
                              "¿Estás seguro de enviar a Comite?",
                              " Recuerda que para enviar al comité, debes haber revisado el buzón de sugerencias de los trabajadores",
                              "Si, enviar",
                              "committee"
                            );
                          }
                          : () => console.log("no")
                      }
                    />
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_BORRADOR_BUZON}
                      icon={<Email sx={{ color: "white" }} />}
                      backgroundColor={"#FFC300"}
                      backgroundColorHover={"#FFC300"}
                      textTooltip={"Buzón de Sugerencias"}
                      directionUrl={"/mailbox/consults"}
                      directionTarget={"_blank"}
                    />

                    <ButtonIconControla
                      roles={permissions.DOC_SSO_BORRADOR_VER_TRABAJ}
                      icon={ <VisibilityOutlined sx={{ color: "white" }}/>}
                      backgroundColor={"#305AD9"}
                      backgroundColorHover={"#0E4AFF"}
                      textTooltip={"Ver"}
                      directionUrl={
                        row.doc_env != 1
                          ? "/documentation/ssopolitics/template?idSsoPolitics=" +
                          row.id + " &readOnly=" + true
                          : "/documentation/ssopolitics/templateThree?idSsoPolitics=" +
                          row.id + " &readOnly=" + true
                      }
                      isNavegationOrigin={true}
                    />
                  </>
                ) : row.register_status === 3 &&
                  row.approved_sign !== "" &&
                  row.revised_sign !== "" &&
                  row.develop_sign ? (
                  <>
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_BORRADOR_ENVIA_GEREN}
                      icon={<Send sx={{ color: "white" }} />}
                      backgroundColor={"#F01212"}
                      textTooltip={"Enviar a gerencia"}
                      functionOnClick={() => {
                        handleOpenModalConfirm(
                          row.id,
                          "¿Estás seguro de enviar a Gerencia?",
                          "Recuerda que para enviar a Gerencia, la política debe estar aprobada por el Comité",
                          "Si, enviar",
                          "management"
                        );
                      }}
                    />
                  </>
                ) : row.register_status === 9 &&
                  row.manager_sign != "" ? (
                  <>
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_BORRADOR_PUBLICAR}
                      icon={ <FileUploadOutlined sx={{ color: "white" }}/>}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1AA483"}
                      textTooltip={"Publicar"}
                      functionOnClick={() => {
                        handleOpenModalConfirm(
                          row.id,
                          "¿Estás seguro de publicar la política?",
                          'Una vez publicado se cerrará la política:  "' +
                          row.title +
                          '"',
                          "Si, publicar",
                          "publish"
                        );
                      }}
                    />
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_BORRADOR_VER_GEREN_FIRM}
                      icon={<VisibilityOutlined sx={{ color: "white" }} />}
                      backgroundColor={"#305AD9"}
                      backgroundColorHover={"#0E4AFF"}
                      textTooltip={"Ver"}
                      isNavegationOrigin={true}
                      directionUrl={
                        row.doc_env != 1
                          ? "/documentation/ssopolitics/template?idSsoPolitics=" +
                          row.id + " &readOnly=" + true
                          : "/documentation/ssopolitics/templateThree?idSsoPolitics=" +
                          row.id + " &readOnly=" + true
                      }
                    />
                  </>
                ) : row.register_status === 8 ? (
                  <>
                    {" "}
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_BORRADOR_VER_GEREN_NO_FIRM}
                      icon={ <VisibilityOutlined sx={{ color: "white" }} /> }
                      backgroundColor={"#305AD9"}
                      backgroundColorHover={"#0E4AFF"}
                      textTooltip={"Ver"}
                      isNavegationOrigin={true}
                      directionUrl={
                        row.doc_env != 1
                          ? "/documentation/ssopolitics/template?idSsoPolitics=" +
                          row.id + " &readOnly=" + true
                          : "/documentation/ssopolitics/templateThree?idSsoPolitics=" +
                          row.id + " &readOnly=" + true
                      }
                    />
                  </>
                ) : row.register_status === 9 ? (
                  <>
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_BORRADOR_VER_GEREN_FIRM}
                      icon={<VisibilityOutlined sx={{ color: "white" }}/>}
                      backgroundColor={"#305AD9"}
                      backgroundColorHover={"#0E4AFF"}
                      textTooltip={"Ver"}
                      isNavegationOrigin={true}
                      directionUrl={
                        row.doc_env != 1
                          ? "/documentation/ssopolitics/template?idSsoPolitics=" +
                          row.id + " &readOnly=" + true
                          : "/documentation/ssopolitics/templateThree?idSsoPolitics=" +
                          row.id + " &readOnly=" + true
                      }
                    />
                  </>
                ) : (
                  <></>
                )}

                {row.register_status === 3 && (
                  <>
                    <ButtonIconControla
                      roles={permissions.DOC_SSO_BORRADOR_VER_COMIT_FIRM}
                      icon={ <VisibilityOutlined sx={{ color: "white" }}/>}
                      backgroundColor={"#305AD9"}
                      backgroundColorHover={"#0E4AFF"}
                      textTooltip={"Ver"}
                      isNavegationOrigin={true}
                      directionUrl={
                        row.doc_env != 1
                          ? "/documentation/ssopolitics/template?idSsoPolitics=" +
                          row.id + " &readOnly=" + true
                          : "/documentation/ssopolitics/templateThree?idSsoPolitics=" +
                          row.id + " &readOnly=" + true
                      }
                    />
                  </>
                )}

                <ButtonIconControla
                  roles={permissions.DOC_SSO_BORRADOR_ARCHIVAR}
                  icon={<Archive sx={{ color: "white" }} />}
                  backgroundColor={"#F2994A"}
                  backgroundColorHover={"#FF881E"}
                  textTooltip={"Archivar"}
                  functionOnClick={() =>
                    handleOpenModalConfirm(
                      row.id,
                      "¿Estás seguro de archivar la Política de SSO?",
                      'SSO a archivar:  "' + row.title + '"',
                      "Si, archivar",
                      "archived"
                    )
                  }
                />

                {row.register_status == 1 && (
                  <ButtonIconControla
                    roles={permissions.DOC_SSO_BORRADOR_ELIMINAR}
                    icon={<Delete sx={{ color: "white" }} />}
                    backgroundColor={"#EB5757"}
                    backgroundColorHover={"#FF4040"}
                    textTooltip={"Eliminar"}
                    functionOnClick={() => {
                      handleOpenModalConfirm(
                        row.id,
                        "¿Estás seguro de eliminar el documento?",
                        'Una vez eliminado no se podra recuperar la Política de SSO:  "' +
                        row.title +
                        '"',
                        "Si, Eliminar",
                        "delete_template"
                      );
                    }}
                  />
                )}
              </Stack>
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )
      )}
    </TableBody>
  )
}

export default ListBorrador