import MyConfigurationApi from "./MyConfigurationApi";

function getAreasReferenced() {
  const url = "areas";
  // const url = "configuration_areas";
  return MyConfigurationApi.connectFormApiGeneral({
    dataJson: null,
    method: "GET",
    url: url,
  });
}


function getAreas() {
  const url = "areas/get";
  // const url = "configuration_areas";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function createAreas({ area }) {
  const url = "areas/store";
  // const url = "configuration_areas";

  const data = {
    area: area,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function updateAreas({ idArea, area }) {
  const url = "areas/update/" + idArea;
  // const url = "configuration_areas/"+idArea;

  const data = {
    area: area,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function deleteAreas({ idArea }) {
  const url = "areas/delete/" + idArea;
  // const url = "configuration_areas/"+idArea;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

function getPositionsByIdArea(idArea){
  const url = "users/positions/area/get/" + idArea;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });

}

const MyAreas = {
  getAreas,
  createAreas,
  updateAreas,
  deleteAreas,
  getAreasReferenced,
  getPositionsByIdArea
};

export default MyAreas;
