import MyConfigurationApi from "../../api/MyConfigurationApi";
import { styled } from "@mui/material/styles";
import {
    Button,
  } from "@mui/material";

  
const white = "#ffffff";
const StyledAddButton = styled(Button)({
    textTransform: "none",
    backgroundColor: white,
    borderRadius: 16,
    '&:hover': {
        background: white,
      }
});

function Button2Controla ({
    backgroundColor,
    color,
    textButton,
    functionOnClick,
    roles = []
}){
    const userData = MyConfigurationApi.userData();
    let usersRoles = userData.roles;
    console.log(roles)

    let showButton = false;
    roles = roles.length === 0 ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16] : roles;

    roles.map((rol) => {
        if (usersRoles.includes(rol)) {
            showButton = true;
        }
    });
    if (showButton === true) {
        return(
            <StyledAddButton
                variant="text"
                size="large"
                style={{
                    backgroundColor: backgroundColor,
                    borderRadius: "15px",
                    color: color ,
                    padding: "6px 18px",
                }}
                onClick={functionOnClick}
            >
                {textButton}
            </StyledAddButton>

        );
    }else {
        return "";
    }


}

export default Button2Controla;