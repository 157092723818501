import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import {
  Add,
  Close,
  Delete,
  CheckCircleOutline,
  HighlightOff,
  ArrowBack,
} from "@mui/icons-material";
import SubtitleText from "../../components/text/subtitleText";
import TitleText from "../../components/text/titleText";
import MyUsers from "../../api/MyUsers";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import SelectYesNotControla from "../../components/selects/selectYesNotControla";
import ButtonControla from "../../components/buttons/buttonController";
import SelectControla from "../../components/selects/selectControla";
import MyCampus from "../../api/MyCampus";
import MyCompanies from "../../api/MyCompanies";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import ReturnBack from "../../components/buttons/returnBackControla";

const grey = "#9191B0";
function Edit() {
  const params = new URLSearchParams(window.location.search);
  const idUsers = params.get("idUsers");

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, /*vertical, horizontal,*/ openAlert } =
    stateAlert;

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  const [openModalCreateRol, setOpenModalCreateRol] = useState(false);

  const [idUsersRoles, setIdUsersRoles] = useState("");

  const [active, setActive] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [timestampBirth, setTimestampBirth] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [documentType, setDocumentType] = useState(1);
  const [document, setDocument] = useState("");
  const [disability, setDisability] = useState("");
  const [timestampStart, setTimestampStart] = useState("");
  const [timestampEnd, setTimestampEnd] = useState("");
  const [position, setPosition] = useState("");
  const [campus, setCampus] = useState("");
  const [sctr, setSctr] = useState("");
  const [unionized, setUnionized] = useState("");
  const [jobType, setJobType] = useState("");
  const [healthEntity, setHealthEntity] = useState("");
  const [firm, setFirm] = useState(null);

  const [rowsRoles, setRowsRoles] = useState([]);
  const [rowsRolesUsers, setRowsRolesUsers] = useState([]);

  const [rowsDocumentsTypes, setRowsDocumentsTypes] = useState([]);
  const [rowsGenders, setRowsGenders] = useState([]);
  const [rowsPositions, setRowsPositions] = useState([]);
  const [rowsCampus, setRowsCampus] = useState([]);
  const [rowsJobTypes, setRowsJobTypes] = useState([]);
  const [rowsHealthEntity, setRowsHealthEntity] = useState([]);

  const handleGetDocumentsTypes = async () => {
    const response = await MyUsers.documentsTypes();
    if (response.success !== false) {
      setRowsDocumentsTypes(response);
    } else {
      alert("Error al listar los documentos!");
    }
  };

  const handleGetGenders = async () => {
    const response = await MyUsers.genders();
    if (response.success !== false) {
      setRowsGenders(response);
    } else {
      alert("Error al listar los generos!");
    }
  };

  const handleGetPositions = async () => {
    const response = await MyUsers.positions();
    if (response.success !== false) {
      setRowsPositions(response);
    } else {
      alert("Error al listar los cargos!");
    }
  };

  const handleGetCampus = async () => {
    const response = await MyCampus.campus();
    if (response.success !== false) {
      setRowsCampus(response);
    } else {
      alert("Error al listar las sedes!");
    }
  };

  const handleGetJobTypes = async () => {
    const response = await MyUsers.jobTypes();
    console.log(response);
    if (response.success !== false) {
      setRowsJobTypes(response);
    } else {
      alert("Error al listar los tipos de puesto!");
    }
  };

  const handleGetHealthEntity = async () => {
    const response = await MyCompanies.healthEntity();
    console.log(response);
    if (response.success !== false) {
      setRowsHealthEntity(response);
    } else {
      alert("Error al listar las entidades de salud!");
    }
  };

  const handleEditUsers = async () => {
    const response = await MyUsers.editUsers({ idUsers });
    console.log(response);
    if (response.success !== false) {
      setActive(response.active);
      setFirstName(response.first_name);
      setLastName(response.last_name);
      setEmail(response.email);
      setTimestampBirth(response.birthday);
      setPhone(response.phone);
      setGender(response.id_users_genders);
      setDocumentType(response.id_users_documents_types);
      setDocument(response.document);
      setDisability(response.disability);
      setTimestampStart(response.start_date);
      setTimestampEnd(response.end_date);
      setPosition(response.id_positions);
      setCampus(response.id_campus);
      setSctr(response.sctr);
      setUnionized(response.unionized);
      setJobType(response.id_job_types);
      setHealthEntity(response.id_health_entity);
    } else {
      alert("Error al listar al usuario!");
    }
  };

  const handleGetUsersRoles = async () => {
    const response = await MyUsers.usersRoles({ idUsers });
    console.log(response);
    if (response.success !== false) {
      setRowsRoles(response);
    } else {
      alert("Error al listar los roles del usuario!");
    }
  };

  useEffect(() => {
    handleEditUsers();
    handleGetUsersRoles();
    handleGetDocumentsTypes();
    handleGetGenders();
    handleGetPositions();
    handleGetCampus();
    handleGetJobTypes();
    handleGetHealthEntity();
  }, []);

  const handleUpdateUsers = async (event) => {
    event.preventDefault();
    setOpenBackdrop(true);
    const response = await MyUsers.updateUsers({
      idUsers: idUsers,
      first_name: firstName,
      last_name: lastName,
      id_genders: gender,
      phone: phone,
      // address: ,
      // department,
      id_documents_types: documentType,
      document: document,
      birthday: timestampBirth,
      email: email,
      // id_educations_levels,
      id_positions: position,
      // code,
      disability: disability,
      sctr: sctr,
      unionized: unionized,
      id_campus: campus,
      // id_countries,
      // id_occupations,
      // id_labor_regimes,
      // id_contracts_types,
      id_job_types: jobType,
      // id_occupational_categories,
      // id_regime_categories,
      // id_regime_types,
      start_date: timestampStart,
      end_date: timestampEnd,
      id_health_entity: healthEntity,
      // active,
      firm_url: firm,
    });
    console.log(response);
    if (response.success !== false) {
      setStateAlert({
        openAlert: true,
        severityAlert: "success",
        messageAlert: response.msg,
      });
      setOpenBackdrop(false);
    } else {
      setStateAlert({
        openAlert: true,
        severityAlert: "error",
        messageAlert: response.msg,
      });
      setOpenBackdrop(false);
    }
  };

  const handleOpenModalCreateRol = async () => {
    handleGetSearchUsersRoles();
    setOpenModalCreateRol(true);
  };

  const handleGetSearchUsersRoles = async () => {
    const response = await MyUsers.usersRolesUsers({ idUsers });
    if (response.success !== false) {
      setRowsRolesUsers(response);
    } else {
      alert("Error al listar los roles del usuario!");
    }
  };

  const handleStoreUsersRoles = async (event) => {
    event.preventDefault();
    setOpenBackdrop(true);
    const response = await MyUsers.storeUsersRoles({ idUsers, idUsersRoles });
    if (response.success !== false) {
      handleGetUsersRoles();
      setStateAlert({
        openAlert: true,
        severityAlert: "success",
        messageAlert: response.msg,
      });
      setOpenBackdrop(false);
      setOpenModalCreateRol(false);
    } else {
      alert("Error al listar los roles del usuario!");
    }
  };

  const handleDeleteUsersRoles = async (idUsersRelsRoles) => {
    setOpenBackdrop(true);
    const response = await MyUsers.deleteUsersRoles({ idUsersRelsRoles });
    if (response.success !== false) {
      handleGetUsersRoles();
      setStateAlert({
        openAlert: true,
        severityAlert: "success",
        messageAlert: response.msg,
      });
      setOpenBackdrop(false);
    } else {
      alert("Error al eliminar los roles del usuario!");
    }
  };

  const handleUpdateActiveUsers = async (active) => {
    setOpenBackdrop(true);
    const response = await MyUsers.updateActiveUsers({ idUsers, active });
    if (response.success !== false) {
      handleGetUsersRoles();
      setStateAlert({
        openAlert: true,
        severityAlert: "success",
        messageAlert: response.msg,
      });
      setActive(active);
      setOpenBackdrop(false);
    } else {
      alert("Error al actualizar el usuario!");
    }
  };

  return (
    <>
      <Box >
        <ReturnBack/>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openAlert}
          onClose={handleCloseAlert}
          key={"top" + "center"}
          autoHideDuration={3000}
        >
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  handleCloseAlert();
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            severity={severityAlert}
            size="small"
          >
            {messageAlert}
          </Alert>
        </Snackbar>

        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
                <SubtitleText text={"Usuarios"} color={grey}></SubtitleText>
                {active == 1 ? (
                  <TitleText text={"Editar usuario"} />
                ) : (
                  <TitleText text={"Usario deshabilitado"} />
                )}
              </Grid>
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                <CompanyLogoControla />
              </Grid>
            </Grid>
            <form onSubmit={handleUpdateUsers}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Nombres:"}
                    inputType={"text"}
                    inputValue={setFirstName}
                    modalType={false}
                    value={firstName}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Apellidos:"}
                    inputType={"text"}
                    inputValue={setLastName}
                    modalType={false}
                    value={lastName}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Correo:"}
                    inputType={"text"}
                    inputValue={setEmail}
                    modalType={false}
                    value={email}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Fecha de nacimiento:"}
                    inputType={"date"}
                    inputValue={setTimestampBirth}
                    modalType={false}
                    value={timestampBirth}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"N° de celular:"}
                    inputType={"number"}
                    inputValue={setPhone}
                    modalType={false}
                    value={phone}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectControla
                    text={"Género:"}
                    inputValue={setGender}
                    modalType={false}
                    childrenRows={rowsGenders.map((row) => (
                      <MenuItem
                        key={row.id_users_genders}
                        value={row.id_users_genders}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    value={gender}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectControla
                    text={"Tipo de documento:"}
                    inputValue={setDocumentType}
                    modalType={false}
                    childrenRows={rowsDocumentsTypes.map((row) => (
                      <MenuItem
                        key={row.id_users_documents_types}
                        value={row.id_users_documents_types}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    value={documentType}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Número de documento:"}
                    inputType={"text"}
                    inputValue={setDocument}
                    modalType={false}
                    value={document}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectYesNotControla
                    text={"Discapacidad:"}
                    inputValue={setDisability}
                    modalType={false}
                    value={disability}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Fecha de inicio:"}
                    inputType={"date"}
                    inputValue={setTimestampStart}
                    modalType={false}
                    value={timestampStart}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Fecha de fin:"}
                    inputType={"date"}
                    inputValue={setTimestampEnd}
                    modalType={false}
                    value={timestampEnd}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectControla
                    text={"Cargo:"}
                    inputValue={setPosition}
                    modalType={false}
                    childrenRows={rowsPositions.map((row) => (
                      <MenuItem key={row.id_positions} value={row.id_positions}>
                        {row.name}
                      </MenuItem>
                    ))}
                    value={position}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <SelectControla text={'Sede:'} inputValue={setCampus} modalType={false} childrenRows={
                                        rowsCampus.map((row) => (
                                            <MenuItem key={row.id_campus}
                                                value={row.id_campus}
                                            >
                                                {row.name}
                                            </MenuItem>
                                        ))

                                    } value={campus} />

                                </Grid> */}
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectYesNotControla
                    text={"SCTR:"}
                    inputValue={setSctr}
                    modalType={false}
                    value={sctr}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectYesNotControla
                    text={"Sindicalizado:"}
                    inputValue={setUnionized}
                    modalType={false}
                    value={unionized}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectControla
                    text={"Tipo de puesto:"}
                    inputValue={setJobType}
                    modalType={false}
                    childrenRows={rowsJobTypes.map((row) => (
                      <MenuItem key={row.id_job_types} value={row.id_job_types}>
                        {row.name}
                      </MenuItem>
                    ))}
                    value={jobType}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <SelectControla
                    text={"Entidad de salud:"}
                    inputValue={setHealthEntity}
                    modalType={false}
                    childrenRows={rowsHealthEntity.map((row) => (
                      <MenuItem
                        key={row.id_health_entity}
                        value={row.id_health_entity}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                    value={healthEntity}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <InputControlaVertical
                    text={"Firma:"}
                    inputType={"file"}
                    inputValue={setFirm}
                    modalType={false}
                  />
                </Grid>
              </Grid>

              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="start"
                sx={{ marginTop: 3 }}
              >
                <Box>
                  <ButtonControla
                    roles={[1,5,6,7,8]}
                    iconButton={<ArrowBack sx={{ color: "white" }} />}
                    textButton={"Regresar"}
                    backgroundColor={"#CBCBFF"}
                    backgroundColorHover={"#d6d6ff"}
                    isNavegationOrigin={true}
                    url={ "/settings/users/"}
                  />
                </Box>
                <Box>
                  {active === 1 ? (
                    <ButtonControla
                     roles={[1,5,6,7,8]}
                      iconButton={<HighlightOff />}
                      textButton={"Deshabilitar"}
                      backgroundColor={"#F2994A"}
                      backgroundColorHover={"#FF881E"}
                      typeButton={"button"}
                      functionOnClick={() => {
                        handleUpdateActiveUsers(0);
                      }}
                    />
                  ) : (
                    <ButtonControla
                    roles={[1,5,6,7,8]}
                      iconButton={<CheckCircleOutline />}
                      textButton={"Habilitar"}
                      backgroundColor={"#F2994A"}
                      backgroundColorHover={"#FF881E"}
                      typeButton={"button"}
                      functionOnClick={() => {
                        handleUpdateActiveUsers(1);
                      }}
                    />
                  )}
                  {/* CheckCircleOutline,  */}
                </Box>
                {active === 1 ? (
                  <Box>
                    <ButtonControla
                      roles={[1,5,6,7,8]}
                      iconButton={<Add />}
                      textButton={"Actualizar"}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      typeButton={"submit"}
                    />
                  </Box>
                ) : (
                  ""
                )}
              </Stack>
            </form>

            <Divider sx={{ marginTop: 3 }} />
            {active === 1 ? (
              <Stack
                direction="row"
                justifyContent={"space-between"}
                sx={{ marginTop: 3, marginBottom: 1 }}
              >
                <Box>
                  {/* <SubtitleText text={"Datos del trabajador"} color={blue} /> */}
                  <InputLabel
                    sx={{
                      padding: "8px",
                      fontSize: "18px",
                      color: "#305AD9",
                      fontWeight: "bold",
                    }}
                  >
                    Roles del usuario
                  </InputLabel>
                </Box>
                <Box>
                  <ButtonControla
                    roles={[1,5,6,7,8]}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar rol"}
                    functionOnClick={handleOpenModalCreateRol}
                  />
                </Box>
              </Stack>
            ) : (
              ""
            )}
            {/* tabla para mostrar roles */}
            <Grid container sx={{ marginTop: 1, marginBottom: 4 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  {active === 1 ? (
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Rol
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                  ) : (
                    ""
                  )}

                  <TableBody>
                    {rowsRoles.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users_rels_roles}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            <ButtonIconControla
                              roles={[1,5,6,7,8]}
                              icon={<Delete sx={{ color: "white" }} />}
                              backgroundColor={"#EB5757"}
                              backgroundColorHover={"#FF4040"}
                              textTooltip={"Eliminar"}
                              functionOnClick={() =>
                                handleDeleteUsersRoles(row.id_users_rels_roles)
                              }
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Dialog
              open={openModalCreateRol}
              onClose={() => {
                setOpenModalCreateRol(false);
              }}
              maxWidth="sm"
              fullWidth={true}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitle>
                <Typography
                  id="modal-modal-title"
                  sx={{
                    color: "#1638F2",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                >
                  Agregar Rol
                </Typography>
              </DialogTitle>
              <form onSubmit={handleStoreUsersRoles}>
                <DialogContent sx={{ paddingTop: "0" }}>
                  <SelectControla
                    text={"Rol:"}
                    inputType={"text"}
                    inputValue={setIdUsersRoles}
                    modalType={true}
                    required
                    childrenRows={rowsRolesUsers.map((row) => (
                      <MenuItem
                        key={row.id_users_roles}
                        value={row.id_users_roles}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenModalCreateRol(false);
                    }}
                  >
                    Cancelar
                  </Button>

                  <Button type="submit">Agregar</Button>
                </DialogActions>
              </form>
            </Dialog>
          </Box>
        </Container>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openBackdrop}
        >
          {/* <CircularProgress color="inherit" /> */}
          <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
        </Backdrop>
      </Box>
    </>
  );
}

export default Edit;
