import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  FormControl,
  Radio,
  CircularProgress,
} from "@mui/material";
import {
  InfoOutlined,
} from "@mui/icons-material";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import SideNav from "../../components/sidenav/sideNav";
import SubtitleText from "../../components/text/subtitleText";
import ButtonControla from "../../components/buttons/buttonController";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import MySsoPolitics from "../../api/MySsoPolitics";
import LoadingControla from "../../components/load/loadingControla";

const blue = "#034AFF";
const purple = "#8B8BD8";

function Fulfillments() {
  const params = new URLSearchParams(window.location.search);
  const idSsoPolitics = params.get("idSsoPolitics");

  // variables
  // cumplimientos
  const [fulfillments, setFulfillments] = useState([]);

  const [loading, setLoading] = useState(false);

  const [orgSigned, setOrgSigned] = useState("");

  const handleChangeOrgSigned = (event) => {
    setOrgSigned(event.target.value);
  };

  const [dated, setDated] = useState("");
  const handleChangeDated = (event) => {
    setDated(event.target.value);
  };

  const [concise, setConcise] = useState("");
  const handleChangeConcise = (event) => {
    setConcise(event.target.value);
  };

  const [coherent, setCoherent] = useState("");
  const handleChangeCoherent = (event) => {
    setCoherent(event.target.value);
  };

  const [memberProtection, setMemberProtection] = useState("");
  const handleChangeMemberProtection = (event) => {
    setMemberProtection(event.target.value);
  };

  const [normativityCompliance, setNormativityCompliance] = useState("");
  const handleChangeNormativityCompliance = (event) => {
    setNormativityCompliance(event.target.value);
  };

  const [consultCommitment, setConsultCommitment] = useState(null);
  const handleChangeConsultCommitment = (event) => {
    setConsultCommitment(event.target.value);
  };

  const [continuousImprovement, setContinuousImprovement] = useState(null);
  const handleChangeContinuousImprovement = (event) => {
    setContinuousImprovement(event.target.value);
  };

  const [clearRedaction, setClearRedaction] = useState(null);
  const handleChangeClearRedaction = (event) => {
    setClearRedaction(event.target.value);
  };

  const [recurringReview, setRecurringReview] = useState(null);
  const handleChangeRecurringReview = (event) => {
    setRecurringReview(event.target.value);
  };

  const [diffused, setDiffused] = useState(null);
  const handleChangeDiffused = (event) => {
    setDiffused(event.target.value);
  };

  const [provisioned, setProvisioned] = useState(null);
  const handleChangeProvisioned = (event) => {
    setProvisioned(event.target.value);
  };

  const [exposed, setExposed] = useState(null);
  const handleChangeExposed = (event) => {
    setExposed(event.target.value);
  }

  const fulfillmentRequirements = [
    {
      id: 1,
      text: "Considerar el compromiso de consulta y participación en los elementos del Sistema de gestión de Seguridad y Salud en el trabajo.",
      tooltip: "Tener en cuenta siempre la opinión de los trabajadores, sindicatos, Comités o Supervisores SST.",
      stateValue: consultCommitment,
      handleChange: handleChangeConsultCommitment
    },
    {
      id: 2,
      text: "Considerar el compromiso de mejora continua del desempeño del Sistema de Gestión de la Seguridad y Salud en el Trabajo.",
      tooltip: "La revisión de la política se debe efectuar al menos una vez al año, en la revisión se debe verificar si la política se adecúa a la situación actual de la organización. Permite plantear nuevos objetivos cuando los antiguos han sido alcanzados, introducir elementos nuevos o precisar los ya existentes. La revisión forma parte del proceso de mejora continua y por eso debe asumirse con el mayor detalle y un alto compromiso. Colocar versión 01 y así sucesivamente; al igual que el documento inicial, las siguientes versiones deben estar documentadas.",
      stateValue: continuousImprovement,
      handleChange: handleChangeContinuousImprovement
    },
    {
      id: 3,
      text: "Considerar el compromiso de protección de todos los miembros de la organización.",
      tooltip: "El compromiso de proteger no es sólo a los trabjadores en planilla sino a todos los miembros de la organización, incluidos: tercerizados, contratistas, recibo por honorarios, practicantes e incluye prevención de lesiones, dolencias, enfermedades e incidentes relacionados.",
      stateValue: memberProtection,
      handleChange: handleChangeMemberProtection
    },
    {
      id: 4,
      text: "Considerar el cumplimiento de la normatividad.",
      tooltip: "Incluye requisitos legales y acuerdos voluntarios que la organizacióno suscriba.",
      stateValue: normativityCompliance,
      handleChange: handleChangeNormativityCompliance
    },
    {
      id: 5,
      text: "Estar a disposición de las partes interesadas externas.",
      tooltip: "Garantizar que esté lista para ser enviada a los terceros externos y/o publicada en el Sistema de Gestión para contratistas (evidencia de su disponibilidad).",
      stateValue: provisioned,
      handleChange: handleChangeProvisioned
    },
    {
      id: 6,
      text: "Estar fechada.",
      tooltip: "Debe ser fecha cierta (día, mes y año) considerando también que la fecha de la firma de la Política debe ser posterior a la fecha en que se presentó al Comité o Supervisor SSO.",
      stateValue: dated,
      handleChange: handleChangeDated
    },
    {
      id: 7,
      text: "Estar firmada por la máxima autoridad de la organización.",
      tooltip: "La firma puede ser en original, digital o escaneada y la debe hacer el Gerente General o su equivalente, algunas organizaciones lo nombran Director, Presidente, CEO, etc.",
      stateValue: orgSigned,
      handleChange: handleChangeOrgSigned
    },
    {
      id: 8,
      text: "Estar redactada con claridad.",
      tooltip: "La empresa debe contar con indicadores y metas cada vez más altas y exigentes.",
      stateValue: clearRedaction,
      handleChange: handleChangeClearRedaction
    },
    {
      id: 9,
      text: "Ser coherente.",
      tooltip: "Recuerda que la Política debe estar alineada con la misión, la visión, los objetivos y los valores corporativos.",
      stateValue: coherent,
      handleChange: handleChangeCoherent
    },
    {
      id: 10,
      text: "Ser concisa.",
      tooltip: "Su contenido es específico, adecuado y apropiado para la organización.",
      stateValue: concise,
      handleChange: handleChangeConcise
    },
    {
      id: 11,
      text: "Ser consultada y estar documentada y revisada periódicamente (anualmente).",
      tooltip: "La revisión de la política se debe efectuar al menos una vez al año, en la revisión se debe verificar si la política se adecúa a la situación actual de la organización. Permite plantear nuevos objetivos cuando los antiguos han sido alcanzados, introducir elementos nuevos o precisar los ya existentes. La revisión forma parte del proceso de mejora continua y por eso debe asumirse con el mayor detalle y un alto compromiso.",
      stateValue: recurringReview,
      handleChange: handleChangeRecurringReview
    },
    {
      id: 12,
      text: "Ser difundida en la organización.",
      tooltip: "Es fundamental que todas las personas vinculadas con la organización conozcan su política. Verificar que se ponga como parte del Reglamento Interno de Seguridad y Salud en el Trabajo. También es aconsejable que se incluya en folletos, como fondo de pantalla, en el anverso del carnet de los trabajadores y en todos aquellos espacios y medios que permitan garantizar una difusión y apropiación real del documento.",
      stateValue: diffused,
      handleChange: handleChangeDiffused
    },
    {
      id: 13,
      text: "Ser expuesta por escrito y fácilmente accesible",
      tooltip: "La política debe estas publicada de manera visible tanto en la página web de la empresa, un sistema de gestión, en sus instalaciones (ubícala en un punto por el que ingresan todos a las instalaciones, si tienen varios ingresos podría haber una copia en cada uno de ellos). La publicación responde a la imagen corporativa de la empresa",
      stateValue: exposed,
      handleChange: handleChangeExposed
    }
  ];

  // funciones
  const handleGetFulfillments = async () => {
    setLoading(true);

    const response = await MySsoPolitics.editSsoPoliticsTemplate({
      idSsoPoliticsTemplate: idSsoPolitics,
      firmada_organizacion: orgSigned,
      fechada: dated,
      concisa: concise,
      coherente: coherent,
      proteccion_miembros: memberProtection,
      cumplimiento_normatividad: normativityCompliance,
      compromiso_consulta_participacion_sso: consultCommitment,
      compromiso_mejora_continua: continuousImprovement,
      redactada_claridad: clearRedaction,
      consultada_documentada_revisada_periodicamente: recurringReview,
      difundida_organizacion: diffused,
      disposicion_partes_interesadas: provisioned,
    });
    setFulfillments(response);
    setOrgSigned(response.firmada_organizacion);
    setDated(response.fechada);
    setConcise(response.concisa);
    setCoherent(response.coherente);
    setMemberProtection(response.proteccion_miembros);
    setNormativityCompliance(response.cumplimiento_normatividad);
    setConsultCommitment(response.compromiso_consulta_participacion_sso);
    setContinuousImprovement(response.compromiso_mejora_continua);
    setClearRedaction(response.redactada_claridad);
    setRecurringReview(response.consultada_documentada_revisada_periodicamente);
    setDiffused(response.difundida_organizacion);
    setProvisioned(response.disposicion_partes_interesadas);
    setExposed(response.expuesta_escrito);

    setLoading(false);
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const handleOpenAlert = (alertMessage) => {
    setOpenAlert(true);
    setLoading(false);
    setErrorMessage(alertMessage);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSaveFulfillments = async (event) => {
    event.preventDefault();
    const response = await MySsoPolitics.postFulfillmentsSsoPolitics({
      idSsoPolitics: idSsoPolitics,
      orgSigned: orgSigned,
      dated: dated,
      concise: concise,
      coherent: coherent,
      memberProtection: memberProtection,
      normativityCompliance: normativityCompliance,
      consultCommitment: consultCommitment,
      continuousImprovement: continuousImprovement,
      clearRedaction: clearRedaction,
      recurringReview: recurringReview,
      diffused: diffused,
      provisioned: provisioned,
      exposed: exposed,
    });

    // Control de errores del response
    if (response?.error === true) {
      handleOpenAlert(response?.message)
      return;
    }
    window.location.href = "/documentation/ssopolitics";
  };

  useEffect(() => {
    handleGetFulfillments();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/ssopolitics" />
      <Container>
        <Box>
          <form onSubmit={handleSaveFulfillments}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Cumplimientos"}
                  color={"#9191B0"}
                ></SubtitleText>
                <strong style={{ color: blue, lineHeight: "1.3rem", fontSize: "1.6rem", fontWeight: "500" }}>
                  A continuación te presentamos una lista para que verifiques si la política de tu organización cumple los requisitos legales:
                </strong>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2, paddingTop: 2 }}>
              <TableContainer component={Paper}>
                <Table sx={{ width: "100%" }} aria-label="simple table responsive">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: blue }} align="left">
                        #
                      </TableCell>
                      <TableCell sx={{ color: blue }} align="center">
                        Requisito de Cumplimiento
                      </TableCell>
                      <TableCell sx={{ color: blue }} align="center">
                        Cumple
                      </TableCell>
                      <TableCell sx={{ color: blue }} align="center">
                        No Cumple
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <div className="loadingMask" style={{
                            width: "100%",
                            height: "100%",
                            minHeight: "80vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                            <CircularProgress size="3rem" />
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      fulfillmentRequirements.map((fulfillment, index) => (
                        <TableRow
                          key={index}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell sx={{ color: blue }} align="left">
                            <Grid item>
                              {fulfillment.id}
                            </Grid>
                          </TableCell>
                          <TableCell sx={{ color: blue }} align="left">
                            <Grid item>
                              {fulfillment.text}
                            </Grid>
                            <Grid item>
                              {fulfillment.tooltip
                                ? <ButtonIconControla
                                  icon={<InfoOutlined sx={{ color: "white" }} />}
                                  backgroundColor={"#FFC300"}
                                  backgroundColorHover={"#FACB32"}
                                  textTooltip={fulfillment.tooltip}
                                />
                                : null}
                            </Grid>
                          </TableCell>
                          <TableCell align="center">
                            <FormControl>
                              <Radio
                                checked={fulfillment.stateValue === "true"}
                                value={true}
                                onChange={fulfillment.handleChange}
                                name="radio-buttons"
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell align="center">
                            <FormControl>
                              <Radio
                                checked={fulfillment.stateValue === "false"}
                                value={false}
                                onChange={fulfillment.handleChange}
                                name="radio-buttons"
                              />
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))
                    )}

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid container sx={{ mt: 4, paddingBottom: 10, width: "100%", display: "flex", justifyContent: "space-evenly" }}>
              <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                <ButtonControla
                  textButton={"Guardar"}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1AA483"}
                  typeButton={"submit"}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                <ButtonControla
                  textButton={"Regresar"}
                  backgroundColor={purple}
                  functionOnClick={() =>
                    (window.location.href = "/documentation/ssopolitics")
                  }
                />
              </Grid>
            </Grid>
          </form>
        </Box>

        {/* Alerta de error */}
        <LoadingControla
          openLoading={loading}
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          messageAlert={errorMessage}
          typeAlert={"error"}
        />
      </Container>
    </Box>
  );
}
export default Fulfillments;
