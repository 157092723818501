import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import Link from '@mui/material/Link';

function ListRecommendations(props) {
    const {
        recommendations,
        colorCell
    } = props

    return (
        <TableBody>
            {
                recommendations.map((r, index) => (
                    <TableRow
                        hover
                        key={index}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <TableCell align="center" sx={{ color: colorCell }}>
                            {r.description}
                        </TableCell>
                        <TableCell align="center" sx={{ color: colorCell }}>
                            {r.origin !== null ? (<Link href={r.origin}>ver acta</Link>) : "no link"}
                        </TableCell>
                        <TableCell align="center" sx={{ color: colorCell }}>
                            {r.first_name + " " + r.last_name}
                        </TableCell>
                        <TableCell align="center" sx={{ color: colorCell }}>
                            {r.start_date}
                        </TableCell>
                        <TableCell align="center" sx={{ color: colorCell }}>
                            {r.end_date}
                        </TableCell>
                        <TableCell align="center" sx={{ color: colorCell }}>
                            {r.nameStatus}
                        </TableCell>
                    </TableRow>
                ))

            }
        </TableBody>
    )

}
export default ListRecommendations;