import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import {
    BorderColorOutlined,
    Archive,
    Delete,
    Send,
    Email,
} from "@mui/icons-material";

import { useAppContext } from "../../../../../context/AppContext";

function ListActives(props) {
    const { permissions } = useAppContext();
    const {
        rowsIpercSearch,
        documentType,
        colorcell,
        handleOpenModalConfirm,
        handleModalEdit,
        handleClickOpenDelete
    } = props


    return (
        <TableBody
            sx={{ display: documentType !== 0 ? "none" : "" }}
        >
            {rowsIpercSearch.map((rowsActives) => (
                <TableRow
                    hover
                    key={rowsActives.id_iperc}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >

                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorcell }}
                        align="center"
                    >
                        {rowsActives.name}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorcell }}
                        align="center"
                    >
                        {rowsActives.area}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorcell }}
                        align="center"
                    >
                        {rowsActives.status}
                    </TableCell>
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {rowsActives.id_iperc_statuses === 1 ? (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_IPERC_ENVIAR_A_TRABAJADORES}
                                        icon={<Send sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Enviar a trabajadores"}

                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                rowsActives.id_iperc,
                                                "¿Estás seguro de enviar a trabajadores? ",
                                                "Una vez enviado no podrás revertir la acción.",
                                                "Sí, enviar",
                                                "sendWorkers"
                                            );
                                        }}
                                    />
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_IPERC_EDITAR}
                                        icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#2D9CDB"}
                                        backgroundColorHover={"#33AEF4"}
                                        textTooltip={"Editar"}
                                        functionOnClick={() =>
                                            handleModalEdit(rowsActives.id_iperc)
                                        }
                                    />
                                </>
                            ) : rowsActives.id_iperc_statuses === 2 ? (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_IPERC_ENVIAR_A_COMITE}
                                        icon={<Send sx={{ color: "white" }} />}
                                        backgroundColor={"#581845"}
                                        backgroundColorHover={"#581845"}
                                        textTooltip={"Enviar a comite"}

                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                rowsActives.id_iperc,
                                                "¿Estás seguro de enviar al comité?  ",
                                                "Recuerda que para enviar al comité debes tener en cuenta las sugerencias de los trabajadores.",
                                                "Sí, enviar",
                                                "comite"
                                            );
                                        }}
                                    />
                                    <ButtonIconControla
                                        roles={[1, 7, 8]}
                                        icon={<Email sx={{ color: "white" }} />}
                                        backgroundColor={"#FFC300"}
                                        backgroundColorHover={"#FFC300"}
                                        textTooltip={"Buzón de participación"}
                                        directionUrl={"/mailbox"}
                                        directionTarget={"_blank"}
                                    />

                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_IPERC_EDITAR}
                                        icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#2D9CDB"}
                                        backgroundColorHover={"#33AEF4"}
                                        textTooltip={"Editar"}
                                        functionOnClick={() =>
                                            handleModalEdit(rowsActives.id_iperc)
                                        }
                                    />
                                </>
                            ) : rowsActives.id_iperc_statuses === 3 ? (
                                <>
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_IPERC_PUBLICAR}
                                        icon={<FileUploadOutlinedIcon sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Publicar"}

                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                rowsActives.id_iperc,
                                                "¿Estás seguro de publicar?",
                                                "Ingresa la fecha del acta de comité en el cuál se aprueba el IPERC.",
                                                "Sí, publicar",
                                                "public"
                                            );
                                        }}
                                    />
                                    <ButtonIconControla
                                        roles={permissions.DOC_HG_PROC_IPERC_EDITAR}
                                        icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#2D9CDB"}
                                        backgroundColorHover={"#33AEF4"}
                                        textTooltip={"Editar"}
                                        functionOnClick={() =>
                                            handleModalEdit(rowsActives.id_iperc)
                                        }
                                    />
                                </>
                            ) : (
                                <></>
                            )}

                            <ButtonIconControla
                                role={permissions.DOC_HG_PROC_IPERC_VER}
                                icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={rowsActives.document_url}
                                directionTarget={"_blank"}
                            />
                            <ButtonIconControla
                                roles={permissions.DOC_HG_PROC_IPERC_ARCHIVAR}
                                icon={<Archive sx={{ color: "white" }} />}
                                backgroundColor={"#F2994A"}
                                backgroundColorHover={"#FF881E"}
                                textTooltip={"Archivar"}

                                functionOnClick={() =>
                                    handleOpenModalConfirm(
                                        rowsActives.id_iperc,
                                        "¿Estás seguro de archivar el documento? ",
                                        '',
                                        "Si, archivar",
                                        "archive"
                                    )
                                }
                            />
                            {rowsActives.id_iperc_statuses === 1 ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_HG_PROC_IPERC_ELIMINAR}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() =>
                                        handleClickOpenDelete(
                                            rowsActives.id_iperc
                                        )
                                    }
                                />
                            ) : (
                                <></>
                            )}
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )
}

export default ListActives;