import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

async function createInternalRegulationsCommittee(data) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (data.document !== null && data.document !== undefined) {
    data.document = await uploadFileCompanies(data.document, `committee/internal-regulations-committee/${year}/${month}/${day}/`);
  }

  const url = "committee-regulations/create";
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function getInternalRegulationsCommittee (){
    const url = "committee-regulations/get";
    return MyConfigurationApi.connectApi({
      dataJson: null,
      method: "GET",
      url: url,
    });

}

function getInternalRegulationsCommitteeById (idRegulationsCommitte) {
  const url = "committee-regulations/" + idRegulationsCommitte;
    return MyConfigurationApi.connectApi({
      dataJson: null,
      method: "GET",
      url: url,
    });
}

async function updatedInternalRegulationsCommittee( data, idRegulationsCommitte) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (data.document !== null && data.document !== undefined) {
    data.document = await uploadFileCompanies(data.document, `committee/internal-regulations-committee/${year}/${month}/${day}/`);
  }
  
  const url = "committee-regulations/update/" + idRegulationsCommitte;
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });

}

function deleteInternalRegulationsCommittee (idRegulationsCommitte){
  const url = "committee-regulations/remove/" + idRegulationsCommitte;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });

}

function updateStatusInternalRegulationsCommittee (idRegulationsCommitte, data){
  const url = "committee-regulations/update/status/" + idRegulationsCommitte;
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });

}

const MyInternalRegulationsCommittee = {
    createInternalRegulationsCommittee,
    getInternalRegulationsCommittee,
    getInternalRegulationsCommitteeById,
    updatedInternalRegulationsCommittee,
    deleteInternalRegulationsCommittee,
    updateStatusInternalRegulationsCommittee

}

export default MyInternalRegulationsCommittee;