import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import { Archive, Visibility, VisibilityOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";

import { useAppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useModulesFree } from "../hooks/userModulesFree";

function ListCurrent(props) {
  const navigate = useNavigate();

  const { baselineActiveRows, colorCell, handleArchiveBaseline } = props;

  const { permissions } = useAppContext();
  const { isBaselineFree } = useModulesFree();

  const handleActionsButtons = (rowActive) => {
    if (rowActive.document_url !== null) {
      return (
        <ButtonIconControla
          roles={permissions.LINE_DIAG_VIGE_VER}
          icon={<VisibilityOutlined sx={{ color: "white" }} />}
          backgroundColor={"#305AD9"}
          backgroundColorHover={"#0E4AFF"}
          textTooltip={"Ver"}
          directionUrl={rowActive.document_url}
          directionTarget={"_blank"}
        />
      );
    } else {
      if (isBaselineFree) {
        return (
          <ButtonIconControla
            roles={permissions.LINE_DIAG_VIGE_VER_RESULTADOS}
            icon={<Visibility sx={{ color: "white" }} />}
            backgroundColor={"#305AD9"}
            textTooltip={"Ver resultados"}
            isNavegationOrigin={true}
            directionUrl={
              "/baseline/show/results?id_baseline=" + rowActive.id_baseline
            }
          />
        );
      }

      return (
        <>
          <ButtonIconControla
            roles={permissions.LINE_DIAG_VIGE_VER_ACCIONES_DE_MEJORA}
            icon={<Visibility sx={{ color: "white" }} />}
            backgroundColor={"#ffc107"}
            textTooltip={"Acciones de mejora"}
            isNavegationOrigin={true}
            directionUrl={
              "/baseline/show/improvementactions?id_baseline=" +
              rowActive.id_baseline
            }
          />
          <ButtonIconControla
            roles={permissions.LINE_DIAG_VIGE_VER_RESULTADOS}
            icon={<Visibility sx={{ color: "white" }} />}
            backgroundColor={"#305AD9"}
            textTooltip={"Ver resultados"}
            isNavegationOrigin={true}
            directionUrl={
              "/baseline/show/results?id_baseline=" + rowActive.id_baseline
            }
          />
          <ButtonIconControla
            roles={permissions.LINE_DIAG_VIGE_ARCHIVAR}
            icon={<Archive sx={{ color: "white" }} />}
            backgroundColor={"#F2994A"}
            backgroundColorHover={"#FF881E"}
            textTooltip={"Archivar"}
            functionOnClick={() => handleArchiveBaseline(rowActive.id_baseline)}
          />
        </>
      );
    }
  };

  return (
    <TableBody>
      {baselineActiveRows.map((rowActive, key) => (
        <TableRow
          hover
          key={key}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell component="th" scope="row" sx={{ color: colorCell }}>
            {rowActive.name === null || rowActive.name === undefined
              ? ""
              : rowActive.name}
          </TableCell>
          <TableCell sx={{ color: colorCell }}>
            {rowActive.document_url !== null ? (
              "Sin Resultado"
            ) : (
              <Chip
                sx={{
                  backgroundColor:
                    rowActive.percentage < 40
                      ? "#d32f2f"
                      : rowActive.percentage >= 40 && rowActive.percentage < 80
                      ? "#ff9800"
                      : "#2e7d32",
                  "&:hover": {
                    backgroundColor:
                      rowActive.percentage < 40
                        ? "#d32f2f"
                        : rowActive.percentage >= 40 &&
                          rowActive.percentage < 80
                        ? "#ff9800"
                        : "#2e7d32",
                    color: "#fff",
                  },
                  color: "white",
                }}
                label={rowActive.percentage + "%"}
                onClick={() => {
                  navigate(
                    "/baseline/results?id_baseline=" + rowActive.id_baseline
                  );
                }}
                // color={rowActive.percentage < 40 ? 'error' : rowActive.percentage >= 40 && rowActive.percentage < 80 ? 'warning':'success'}
              />
            )}
          </TableCell>
          <TableCell align="center" sx={{ color: colorCell }}>
            {rowActive.document_url !== null ? "ARCHIVO" : "SISTEMA"}
          </TableCell>

          <TableCell align="right">
            <Stack direction="row" justifyContent="end" alignItems="center">
              {handleActionsButtons(rowActive)}
            </Stack>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
export default ListCurrent;
