import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
    Alert,
    Backdrop,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    Stack,
    Snackbar,
} from '@mui/material';
import {
    Add,
    Delete,
    BorderColorOutlined,
} from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import LockIcon from '@mui/icons-material/Lock';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import SubtitleText from "../../../../components/text/subtitleText";
import TitleText from "../../../../components/text/titleText";
import BannerControla from '../../../../components/cards/bannerControla';
import ButtonControla from "../../../../components/buttons/buttonController";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import MyProceedingsBook from '../../../../api/MyProceedingsBook';
import MyMeeting from '../../../../api/MyMeetings';
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import MyConfigurationApi from '../../../../api/MyConfigurationApi';
import DialogInformativeControla from '../../../../components/dialog/dialogInformativeControla';


import { useNavigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../../../../context/AppContext';
import ReturnBack from '../../../../components/buttons/returnBackControla';

const blue = "#034AFF";
const purple = "#8B8BD8";

const colorTitle = "#9191B0";
function ProceedingsMeeting() {
    const {permissions} = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();

    const typeBook = location.state.typeBook;
   
    //Message Alert
    const [stateAlert, setStateAlert] = useState({
        openAlert: false,
        vertical: "top",
        horizontal: "center",
        severityAlert: "success",
        messageAlert: "",
    });

    const { severityAlert, messageAlert, vertical, horizontal, openAlert } = stateAlert;

    const handleOpenAlert = (severityAlert, messageAlert) => {
        setStateAlert({
            openAlert: true,
            vertical: "top",
            horizontal: "right",
            severityAlert: severityAlert,
            messageAlert: messageAlert,
        });
    }

    const handleCloseAlert = () => {
        setStateAlert({ ...stateAlert, openAlert: false });
    }
    //***/
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    }

    // *modal de confirmación *
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [idProceeding, setIdProceeding] = useState("");

    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm, buttonActionType, idProceeding) => {
        setOpenModalConfirm(true);

        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setIdProceeding(idProceeding);
    }
    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }
    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case "delete":
                handleDeleteProceeding();
                break;
            case "close":
                handleCloseProceeding();
                break;
            case "publish":
                handlePublishProceeding();
                break;
        }
        handleCloseModalConfirm();
    }

    const [openModalListMeeting, setOpenModalListMeeting] = useState(false);

    const handleOpenModalListMeeting = () => {
        setOpenModalListMeeting(true);
    }

    const handleCloseModalListMeeting = () => {
        setOpenModalListMeeting(false);
    }

    const [proceedings, setProceedings] = useState([]);
    const [currentMeeting, setcurrentMeeting] = useState([]);

    const getProceedingsByIdBook = async () => {
        const response = await MyProceedingsBook.getMinutesByIdBook(location.state.id_book);
        setProceedings(response.data);
    }

    const handleAddScheduledProceeding = async () => {
        setOpenBackdrop(true);
        const date = new Date();
        const today = date.getDate();
        const month = date.getMonth() + 1;

        //obtener fecha hoy
        const dateProceeding = `${date.getFullYear()}-${month.toString().length === 1 ? "0" + month.toString() : month }-${today.toString().length === 1 ? "0" + today : today}`;

        const response = await MyMeeting.getMeeting();

        const meeting = response.data.filter((m) => m.date === dateProceeding & m.status === 2);

        //traer actas del libro
        const res = await MyProceedingsBook.getMinutesByIdBook(location.state.id_book);

        meeting.forEach((m, index) => {
            res.data.forEach((pro) => {
                if (pro.id_committee_meeting === m.id_committee_meeting) {
                    delete (meeting[index]);
                }
            });
        });

        const meetWhitOutProceeding = meeting.filter((m) => m !== undefined);

        setcurrentMeeting(meetWhitOutProceeding);
        setValue(null);
        handleCloseBackdrop();
        handleOpenModalListMeeting();
    }

    const handleDeleteProceeding = async () => {
        setOpenBackdrop(true);

        const res = await MyProceedingsBook.deleteProceeding(idProceeding);
        if (res.success === true) {
            handleOpenAlert("success", "Se eliminó el acta con éxito");
            getProceedingsByIdBook();
        } else {
            handleOpenAlert("error", "Ocurrió un error");
        }
        getProceedingsByIdBook();

        handleCloseBackdrop();
    }

    const handleCloseProceeding = async () => {
        setOpenBackdrop(true);
        let contSignature = 0;
        let contAttended = 0;
        const res = await MyProceedingsBook.getParticipantsByProceedings(idProceeding);
        res.data.forEach((p) => {
            if (p.attended === 1 && p.signature === "1" && p.committee_position === 1) {
                contSignature = contSignature + 1;
            }
            if (p.attended === 1 && p.committee_position === 1) {
                contAttended = contAttended + 1;
            }
        })

        if (contAttended === contSignature && contAttended !== 0) {
            const dataStatusProceeding = {
                status: 2
            }
            const resp = await MyProceedingsBook.updateProceedings(dataStatusProceeding, idProceeding);

            if (resp.success === true) {
                handleOpenAlert("success", "Acta cerrada con éxito");
                getProceedingsByIdBook();
            } else {
                handleOpenAlert("error", "Ocurrió un error");
            }
            handleCloseBackdrop();
        } else {
            handleCloseBackdrop();
            setOpenModalInformative(true);
            setMessageModalInformative("No se puede cerrar el acta, verificar la firma y asistencia de los miembros titulares");
        }
    }

    const handlePublishProceeding = async () => {
        setOpenBackdrop(true);
        const statusProceeding = {
            id_status_request: 3
        }
        const r = await MyProceedingsBook.updateStatusProceeding(statusProceeding, idProceeding);
        if (r.success === true) {
            handleOpenAlert("success", "Se notificó el acta con éxito");
            getProceedingsByIdBook();
        } else {
            handleOpenAlert("error", "Ocurrió un error");
        }

        handleCloseBackdrop();
    }

    const handleAddProceedingsWithoutMeeting = async () => {


        if (proceedings.some((proceeding) => proceeding.status == 1)) {
            setOpenModalInformative(true);
            setMessageModalInformative("Debe cerrar el acta anterior para poder generar nueva acta en el sistema");

        } else {
            // solo generar acta sin reunión con estado cerrado

            const data_proceeding = {
                id_committe_meeting: null,
                id_minute_book: location.state.id_book
            }
            const res = await MyProceedingsBook.createProceedings(data_proceeding);

            //registrar participantes del acta
            const data_idProceeding = {
                id_minutes: res.data.id_minutes
            }
            const response = await MyProceedingsBook.postParticipantsProceedings(data_idProceeding);

            if (res.success === true && response.success === true) {
                navigate(`/editProceedingsMeeting?id_proceeding=${res.data.id_minutes}`);
            } else {
                console.log("Ocurrió un error");
            }

        }






    }

    const [value, setValue] = useState("");

    const handleChangeMeeting = (event) => {
        setValue(event.target.value);
    }

    const getUseCreated = () => {
        return MyConfigurationApi.userData();
    }

    const handlePostProceedingWithMeeting = async (event) => {
        event.preventDefault();
        //value => id de la reunión seleccionada

        if (value === null) {

        } else {
            setOpenBackdrop(true);
            //verificar si existe un acta que no está cerrada
            if (proceedings.some((proceeding) => proceeding.status == 1)) {
                handleCloseBackdrop();
                handleCloseModalListMeeting();
                setOpenModalInformative(true);
                setMessageModalInformative("Debe cerrar el acta anterior para poder generar nueva acta en el sistema");
            } else {
                //crear acta para la reunión
                const idProceedingsMeeting = {
                    id_committe_meeting: value,
                    id_minute_book: location.state.id_book
                }
                const resp = await MyProceedingsBook.createProceedings(idProceedingsMeeting);

                //registrar participantes al acta
                const data_idProceeding = {
                    id_minutes: resp.data.id_minutes
                }
                await MyProceedingsBook.postParticipantsProceedings(data_idProceeding);
                navigate("/editProceedingsMeeting?id_proceeding=" + resp.data.id_minutes);

            }


        }
    }

    //modal de información 
    const [openModalInformative, setOpenModalInformative ] = useState(false);
    const [messageModalInformative, setMessageModalInformative] = useState("");

    const handleCloseModalInformative = ()=> {
        setOpenModalInformative(false);
    }




    useEffect(() => {
        getProceedingsByIdBook();
    }, []);

    return (
        <Box>
            <ReturnBack/>

            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />

            {/* componente Alert */}
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={3000}
            >
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleCloseAlert();
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity={severityAlert}
                    size="small"
                >
                    {messageAlert}
                </Alert>
            </Snackbar>
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>

                        <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                            <BannerControla
                                image="/assets/images/banners/committee/bannerActas.png"
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Actas"
                            />
                        </Grid>
                    </Grid>
                    {
                        typeBook === "archivedBook" ? (
                            <Grid container direction={"row"} justifyContent={"center"}>
                                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                                    <TitleText text={"Libro de actas : " + location.state.name_book}></TitleText>
                                    <SubtitleText
                                        text={`Fecha de inicio: ${location.state.date_created} -  Fecha de fin: ${location.state.end_date}`}
                                        color={colorTitle}
                                    ></SubtitleText>
                                </Grid>
                            </Grid>

                        ) : ""
                    }

                    {
                        typeBook === "currentBook" ? (
                            <Grid container sx={{ mt: 5 }}>
                                <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
                                    <Stack direction={"row"} spacing={2}>

                                        <Box>
                                            <ButtonControla
                                                roles={permissions.COMITE_ACTA_CREAR_NO_PROGRAM}
                                                iconButton={<Add sx={{ color: "white" }} />}
                                                textButton={"Agregar acta programada"}
                                                backgroundColor={"#2D9CDB"}
                                                backgroundColorHover={"#1976d2"}
                                                functionOnClick={handleAddScheduledProceeding}
                                            />
                                        </Box>
                                        <Box>
                                            <ButtonControla
                                                roles={permissions.COMITE_ACTA_CREAR_NO_PROGRAM}
                                                iconButton={<Add sx={{ color: "white" }} />}
                                                backgroundColor={"#1cb591"}
                                                backgroundColorHover={"#169073"}
                                                textButton={"Agregar acta no programada"}
                                                tooltipText={"Usar sino programaste fecha en sistema"}
                                                functionOnClick={() => { handleAddProceedingsWithoutMeeting() }}
                                            />
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>

                        ) : ""
                    }




                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper sx={{ overflow: "hidden" }}>
                                <TableContainer>
                                    <Table
                                        stickyHeader
                                        sx={{ width: "100%", minWidth: "700px" }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Fecha
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Tipo de sesión
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Quorum
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Estado
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Acciones
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {proceedings.map((proceeding, index) => (
                                                <TableRow key={index}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {proceeding.date === null ? "--" : proceeding.date}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {proceeding.session === "1" ? "Ordinario" : (proceeding.session === "2" ? "Extraordinario" : "--")}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {proceeding.quorum === null ? 0 : proceeding.quorum}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {proceeding.status === 1 ? "Creado" : (proceeding.status === 2 ? "Cerrado" : "notificado")}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="right"
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignContent="center"
                                                        >
                                                            {
                                                                proceeding.status === 1 && typeBook === "currentBook" ? (
                                                                    <ButtonIconControla
                                                                        roles={permissions.COMITE_ACTA_EDIT}
                                                                        icon={
                                                                            <BorderColorOutlined
                                                                                sx={{ color: "white" }}
                                                                            />
                                                                        }
                                                                        backgroundColor={"#2D9CDB"}
                                                                        backgroundColorHover={"#33AEF4"}
                                                                        textTooltip={"Editar"}
                                                                        isNavegationOrigin={true}
                                                                        directionUrl={"/editProceedingsMeeting?id_proceeding=" + proceeding.id_minutes}
                                                                    />

                                                                ) : ""
                                                            }

                                                            <ButtonIconControla
                                                                roles={permissions.COMITE_ACTA_VER}
                                                                icon={
                                                                    <VisibilityOutlined
                                                                        sx={{ color: "white" }}
                                                                    />
                                                                }
                                                                backgroundColor={"#305AD9"}
                                                                backgroundColorHover={"#0E4AFF"}
                                                                textTooltip={"Ver"}
                                                                isNavegationOrigin={true}
                                                                directionUrl={"/viewProceedingsMeeting?id_proceeding=" + proceeding.id_minutes}
                                                            />
                                                            {
                                                                proceeding.status === 2 && typeBook === "currentBook" ? (
                                                                    <ButtonIconControla
                                                                        roles={permissions.COMITE_ACTA_NOTIFICAR}
                                                                        icon={
                                                                            <FileUploadOutlinedIcon
                                                                                sx={{ color: "white" }}
                                                                            />
                                                                        }
                                                                        backgroundColor={"#169073"}
                                                                        backgroundColorHover={"#1AA483"}
                                                                        textTooltip={"Notificar"}
                                                                        functionOnClick={() => {
                                                                            handleOpenModalConfirm(
                                                                                "¿Estás seguro de notificar esta acta?",
                                                                                "Una vez notificado no podrás editar el acta",
                                                                                "Sí, notificar",
                                                                                "publish",
                                                                                proceeding.id_minutes
                                                                            );
                                                                        }}
                                                                    />
                                                                ) : ""
                                                            }

                                                            {
                                                                proceeding.status === 1 && typeBook === "currentBook" ? (
                                                                    <ButtonIconControla
                                                                        roles={permissions.COMITE_ACTA_CERRAR}
                                                                        icon={<LockIcon sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Cerrar Acta"}
                                                                        functionOnClick={() => {
                                                                            handleOpenModalConfirm(
                                                                                "¿Estás seguro de cerrar esta acta?",
                                                                                "Una vez cerrada no podrás editar el acta",
                                                                                "Sí, cerrar",
                                                                                "close",
                                                                                proceeding.id_minutes
                                                                            );
                                                                        }}
                                                                    />

                                                                ) : ""
                                                            }
                                                            {
                                                                proceeding.status === 1 && typeBook === "currentBook" ? (
                                                                    <ButtonIconControla
                                                                        roles={permissions.COMITE_ACTA_ELIM}
                                                                        icon={<Delete sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Eliminar"}
                                                                        functionOnClick={() => {
                                                                            handleOpenModalConfirm(
                                                                                "¿Estás seguro de eliminar esta acta?",
                                                                                "Una vez eliminada no podrás recuperar el acta",
                                                                                "Sí, eliminar",
                                                                                "delete",
                                                                                proceeding.id_minutes
                                                                            );

                                                                        }}
                                                                    />

                                                                ) : ""

                                                            }
                                                        </Stack>

                                                    </TableCell>
                                                </TableRow>
                                            ))}


                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>

                </Box>
            </Container>

            {/* modal de lista de reuniones de fecha del sistema */}
            <Dialog
                open={openModalListMeeting}
                onClose={handleCloseModalListMeeting}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitleControla
                    titleText={"Lista de reuniones programadas para hoy"}
                    functionOnClose={handleCloseModalListMeeting}
                />
                <form onSubmit={handlePostProceedingWithMeeting}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        {
                            currentMeeting.length === 0 ? (
                                <Typography
                                    id="modal-modal-title"
                                    sx={{
                                        fontSize: '18px',
                                        color: "#1638F2",
                                    }}
                                >
                                    No existe reunión agendada para hoy o ya existen actas generadas
                                </Typography>

                            ) : (
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group"
                                        sx={{
                                            fontSize: '18px',
                                            color: "#1638F2",
                                        }}
                                    >Seleccione solo una de las reuniones para generar acta:</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChangeMeeting}
                                    >
                                        {currentMeeting.map((p, index) => (
                                            <FormControlLabel key={index}
                                                sx={{
                                                    fontSize: '18px',
                                                    color: "#1638F2",
                                                }}
                                                value={p.id_committee_meeting} control={<Radio required={value === null ? true : false} />} label={p.description} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            )
                        }

                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={handleCloseModalListMeeting}
                                />
                            </Box>
                            {
                                currentMeeting.length === 0 ? "" : (
                                    <Box>
                                        <ButtonControla
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Crear acta"}
                                            typeButton={"submit"}
                                        // functionOnClick={() => { handlePostProceedingWithMeeting() }}
                                        />
                                    </Box>

                                )
                            }


                        </Stack>
                    </DialogActions>

                </form>
            </Dialog>

            {/* modal de información */}
            <DialogInformativeControla
             open={openModalInformative}
             onClose={handleCloseModalInformative}
             message={messageModalInformative}
            />


            {/* mostrar loading  */}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 9999,
                }}
                open={openBackdrop}
            >
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>
        </Box>
    );

}
export default ProceedingsMeeting;

