import { React, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { MenuItem, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, InputLabel } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { Add, BorderColorOutlined, Edit as EditIcon, Delete, PictureAsPdf } from '@mui/icons-material';
import SubtitleText from '../../../../components/text/subtitleText';
import TitleText from '../../../../components/text/titleText';
import MyAts from '../../../../api/MyAts';
import MyUsers from '../../../../api/MyUsers';
import InputControlaVertical from '../../../../components/textfields/inputControlaVertical';
import CompanyLogoControla from '../../../../components/images/companyLogoControla';
import ButtonControla from '../../../../components/buttons/buttonController';
import SearchUsersControla from '../../../../components/search/searchUsersControla';
import DialogTitleControla from '../../../../components/dialog/dialogTitleControla';
import LoadingControla from '../../../../components/load/loadingControla';
import ButtonIconControla from '../../../../components/buttons/buttonIconControla';
import SearchButtonControla from '../../../../components/search/searchButtonControla';
import SearchEppsControla from '../../../../components/search/searchEppsControla';
import SelectControla from '../../../../components/selects/selectControla';
import DialogConfirmControla from '../../../../components/dialog/dialogConfirmControla';
import { useAppContext } from '../../../../context/AppContext';
import MyAreas from '../../../../api/MyAreas';
import ButtonSignatureControla from '../../../../components/buttons/buttonSignatureControla';
import ReturnBack from '../../../../components/buttons/returnBackControla';



const grey = "#9191B0";
const blue = "#034AFF";


const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
})


function Edit() {
    const { permissions } = useAppContext();

    const params = new URLSearchParams(window.location.search);
    const id_ats = params.get('id_ats');
    const readOnly = params.get('readOnly') == "true" ? true : false;

    const rolesEdit = permissions.DOC_HG_PROC_ATS_MOD_EDITAR;

    let title = "";

    readOnly == true ? (title = "Análisis de Trabajo Seguro") : (title = "Editar Análisis de Trabajo Seguro");

    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert('');
        setTypeAlert('success');
    }

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    // Pantallas de carga y alertas FIN


    // Dialogos de confirmacion INICIO
    const [idAction, setIdAction] = useState('');

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState('');
    const [contentText, setContentText] = useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = useState('');
    const [buttonActionType, setButtonActionType] = useState('');


    const handleOpenModalConfirm = (id, titleText, contentText, buttonTextConfirm, buttonActionType) => {
        setIdAction(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case 'deleteTasks':
                handleDeleteAtsTasks();
                break;
            case 'deleteExecutor':
                handleDeleteAtsExecutor();
                break;
        }
        setOpenModalConfirm(false);
    }

    // Dialogos de confirmacion FIN

    const [idUserSelected, setIdUserSelected] = useState(0);
    const [dataUserSelected, setDataUserSelected] = useState('');


    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [version, setVersion] = useState('');
    const [areaSelected, setAreaSelected] = useState("");
    const [areas, setAreas] = useState([]);

    const [place, setPlace] = useState('');
    const [hourStart, setHourStart] = useState('');
    const [hourFinish, setHourFinish] = useState('');

    const [typeSupervisor, setTypeSupervisor] = useState('');

    const [workSupervisor, setWorkSupervisor] = useState("");
    const [workTeamLeader, setWorkTeamLeader] = useState("");
    const [areaSupervisor, setAreaSupervisor] = useState("");
    const [idWorkSupervisor, setIdWorkSupervisor] = useState("");
    const [idworkTeamLeader, setIdworkTeamLeader] = useState("");
    const [idAreaSupervisor, setIdAreaSupervisor] = useState("");

    const [firmWorkSupervisor, setFirmWorkSupervisor] = useState(null);
    const [firmUrlWorkSupervisor, setFirmUrlWorkSupervisor] = useState(null);

    const [firmAreaSupervisor, setFirmAreaSupervisor] = useState(null);
    const [firmUrlAreaSupervisor, setFirmUrlAreaSupervisor] = useState(null);

    const [dateFirmWorkSupervisor, setDateFirmWorkSupervisor] = useState("");
    const [dateFirmAreaSupervisor, setDateFirmAreaSupervisor] = useState("");

    const [description, setDescription] = useState('');
    const [tools, setTools] = useState('');
    const [procedure, setProcedure] = useState('');
    const [observation, setObservation] = useState('');
    const [pdfGenerateUrl, setPdfGenerateUrl] = useState('');

    const [createDate, setCreateDate] = useState('');

    const [idStatuses, setIdStatuses] = useState(false);


    const [rowsExecutor, setRowsExecutor] = useState([]);
    const [rowsTasks, setRowsTasks] = useState([]);

    const [rowsUsersNoTasks, setRowsUsersNoTasks] = useState([]);

    const [openModalSelectUser, setOpenModalSelectUser] = useState(false);


    const [openModalCreateTasks, setOpenModalCreateTasks] = useState(false);
    const [openModalEditTasks, setOpenModalEditTasks] = useState(false);

    const [openModalCreateSupervisor, setOpenModalCreateSupervisor] = useState(false);
    const [titleModalCreateSupervisor, setTitleModalCreateSupervisor] = useState("");

    const [idTasks, setIdTasks] = useState('');
    const [stepsTasks, setStepsTasks] = useState('');
    const [dangersTasks, setDangersTasks] = useState('');
    const [risksTasks, setRisksTasks] = useState('');
    const [measuresTasks, setMeasuresTasks] = useState('');

    const handleOpenModalCreateSupervisor = (typeSupervisor) => {
        setOpenModalCreateSupervisor(true);
        setTypeSupervisor(typeSupervisor);

        switch (typeSupervisor) {
            case "workSupervisor":
                setTitleModalCreateSupervisor("Agregar supervisor responsable del trabajo");
                break;
            case "WorkTeamLeader":
                setTitleModalCreateSupervisor("Agregar lider del equipo de trabajo");
                break;
            case "areaSupervisor":
                setTitleModalCreateSupervisor("Agregar supervisor de área");
                break;
        }
    }
    const handleCloseModalCreateSupervisor = () => {
        setOpenModalCreateSupervisor(false);
    }

    const [openModalCreateExecutor, setOpenModalCreateExecutor] = useState(false);
    const handleOpenModalCreateExecutor = () => {
        setOpenModalCreateExecutor(true);
    }
    const handleCloseModalCreateExecutor = () => {
        setOpenModalCreateExecutor(false);
    }

    const handlePostExecutor = async (id_users) => {
        handleOpenLoading();

        const resp = await MyAts.createAtsResponsible({ id_ats, id_users });
        if (resp.success == true) {
            handleGetExecutor();
            handleOpenAlert("Se agregó personal ejecutor con éxito", "success")
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
        handleCloseModalCreateExecutor();
    }

    const handleGetExecutor = async () => {
        const resp = await MyAts.responsibleAtsGet({ id_ats });
        setRowsExecutor(resp);
    }

    const handleFirmExecutor = async (idAtsExecutor) => {
        handleOpenLoading();
        const response = await MyAts.responsibleAtsUpdateFirmStart({ id_ats_responsible: idAtsExecutor });
        if (response.success == true) {
            await handleGetExecutor();
            handleOpenAlert("El personal ejecutor ha firmado con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }


    const handleOpenModalSelectUser = () => {
        handleUsersNoTasks();
        setOpenModalSelectUser(true)
    };
    const handleCloseModalSelectUser = () => setOpenModalSelectUser(false);

    const handleOpenModalCreateTasks = () => {
        setIdUserSelected(0);
        setDataUserSelected('');
        handleUsersNoTasks();
        setOpenModalCreateTasks(true)
    };
    const handleCloseModalCreateTasks = () => setOpenModalCreateTasks(false);

    const handleOpenModalEditTasks = (idTask,userSelected,step, danger, risk, measure) => {

        setDataUserSelected(userSelected);
        setIdTasks(idTask);
        setStepsTasks(step);
        setDangersTasks(danger);
        setRisksTasks(risk);
        setMeasuresTasks(measure);
        setOpenModalEditTasks(true)
    };
    const handleCloseModalEditTasks = () => setOpenModalEditTasks(false);

    const handleSelectUser = async (idUsers) => {
        const response = await MyUsers.editUsers({ idUsers: idUsers });

        if (response.success !== false) {
            setIdUserSelected(idUsers);
            setDataUserSelected(response.document + ' - ' + response.first_name + ' ' + response.last_name)
            handleCloseModalSelectUser();
        } else {
            alert('ERROR');
        }
        return response;
    };

    const handleEditAts = async () => {
        handleOpenLoading();

        if (rolesEdit != undefined) {

            const response = await MyAts.editAts({ id_ats });

            const resp1 = await MyUsers.editUsers({ idUsers: response.data.supervisor_responsible_work })
            const resp2 = await MyUsers.editUsers({ idUsers: response.data.supervisor_area })
            const resp3 = await MyUsers.editUsers({ idUsers: response.data.team_leader_work })


            if (response.success === false) {
                alert('Error');
            } else {
                setName(response.data.name);
                setCode(response.data.code);
                setVersion(response.data.version)
                setAreaSelected(response.data.area);

                setPlace(response.data.place);
                setHourStart(response.data.start_time);
                setHourFinish(response.data.end_time);
                setDescription(response.data.description);
                setTools(response.data.tools);
                setProcedure(response.data.procedure);
                setObservation(response.data.observation);
                setPdfGenerateUrl(response.data.pdf_generate_url);
                setIdStatuses(response.data.id_ats_statuses);

                setDateFirmAreaSupervisor(response.data.date_firm_supervisor_area);
                setDateFirmWorkSupervisor(response.data.date_firm_supervisor_responsible_work);
                //traer los nuevos campos

                if (response.data.supervisor_responsible_work != null) {
                    setWorkSupervisor(resp1.first_name + " " + resp1.last_name)
                }
                setIdWorkSupervisor(response.data.supervisor_responsible_work);
                setFirmWorkSupervisor(response.data.firm_supervisor_responsible_work);
                setFirmUrlWorkSupervisor(response.data.supervisor_responsible_work_firm_url);


                if (response.data.supervisor_area != null) {
                    setAreaSupervisor(resp2.first_name + " " + resp2.last_name)
                }
                setIdAreaSupervisor(response.data.supervisor_area);
                setFirmAreaSupervisor(response.data.firm_supervisor_area);
                setFirmUrlAreaSupervisor(response.data.supervisor_area_firm_url);


                if (response.data.team_leader_work != null) {
                    setWorkTeamLeader(resp3.first_name + " " + resp3.last_name)

                }
                setIdworkTeamLeader(response.data.team_leader_work)

                setCreateDate(response.data.timestamp_created.substring(0, 10))
            }

            setOpenLoading(false);
            return response;
        }

    };


    const handleAtsTasks = async () => {
        const response = await MyAts.atsTasksGet({ id_ats });

        if (response.success === false) {
            alert('Error');
        } else {
            setRowsTasks(response);
        }
        return response;
    };

    const handleUsersNoTasks = async () => {
        const response = await MyAts.tasksNoAtsGet({ id_ats: id_ats });

        if (response.success !== false) {
            setRowsUsersNoTasks(response);
        } else {
            alert('Error');

        }
        return response;
    };

    const [rowsEppsAts, setRowsEppsAts] = useState([]);

    const handleAtsEpps = async () => {

        const response = await MyAts.eppsAtsGet({ id_ats });

        if (response.success === false) {
            alert('Error listando los epps');
        } else {
            setRowsEppsAts(response);
        }
        return response;
    };

    const handleAtsNoEpps = async () => {
        const response = await MyAts.eppsNoAtsGet({ id_ats });

        if (response.success === false) {
            alert('Error');
        } else {
            setRowsEpps(response);
        }
        return response;
    };
    //cargar areas
    const getAreas = async () => {
        const resp = await MyAreas.getAreas();
        setAreas(resp);
    }

    const [rowUsers, setRowUsers] = useState([]);

    const handleGetUsers = async () => {
        const resp = await MyUsers.users();
        setRowUsers(resp.users_actives)
    }

    const handlePostFirmWorkSupervisor = async () => {
        handleOpenLoading();

        const res = await MyAts.workSupervisorFirm({ id_ats: id_ats, firmWorkSupervisor: 1, workSupervisor: idWorkSupervisor, areaSupervisor: idAreaSupervisor, WorkTeamLeader: idworkTeamLeader });

        if (res.success == true) {
            await handleEditAts();
            handleOpenAlert("El supervisor responsable del trabajo firmó con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handlePostFirmAreaSupervisor = async () => {
        handleOpenLoading();

        const res = await MyAts.areaSupervisorFirm({ id_ats, firmAreaSupervisor: 1, areaSupervisor: idAreaSupervisor, workSupervisor: idWorkSupervisor, WorkTeamLeader: idworkTeamLeader });
        if (res.success == true) {
            await handleEditAts();
            handleOpenAlert("El supervisor de área firmó con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }


    useEffect(() => {
        handleGetExecutor();
        handleAtsTasks();
        handleAtsEpps();
        getAreas();
        handleGetUsers()
    }, []);

    useEffect(() => {
        handleEditAts();
    },[rolesEdit]);

    const handlePutUpdateAts = async () => {
        const area = areaSelected;
        handleOpenLoading();
        const response = await MyAts.updateAts({ id_ats, name, code, version, area, place, start_time: hourStart, end_time: hourFinish, description: description, tools, procedure, observation: observation, workSupervisor: idWorkSupervisor, areaSupervisor: idAreaSupervisor, WorkTeamLeader: idworkTeamLeader, createDate });

        if (response.success !== false) {
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert('Hubo un error al actualizar', 'success');
        }
        return response;
    };

    const handleGetUserSupervisor = async (id_users) => {
        handleOpenLoading();

        const resp = await MyUsers.editUsers({ idUsers: id_users })
        handleCloseModalCreateSupervisor();

        switch (typeSupervisor) {
            case "workSupervisor":
                setWorkSupervisor(resp.first_name + " " + resp.last_name);
                setIdWorkSupervisor(id_users);
                setFirmUrlWorkSupervisor(resp.firm_url)
                break;
            case "WorkTeamLeader":
                setWorkTeamLeader(resp.first_name + " " + resp.last_name)
                setIdworkTeamLeader(id_users);
                break;
            case "areaSupervisor":
                setAreaSupervisor(resp.first_name + " " + resp.last_name)
                setIdAreaSupervisor(id_users);
                setFirmUrlAreaSupervisor(resp.firm_url);
                break;
        }

        setOpenLoading(false);

    }


    const handleDeleteAtsExecutor = async () => {

        handleOpenLoading();
        const response = await MyAts.deleteAtsResponsible({ id_ats_responsible: idAction });
        if (response.success == true) {
            handleGetExecutor();
            handleOpenAlert("Se eliminó el personal ejecutor con éxito", 'success');
        } else {
            handleOpenAlert('Ocurrió un error', 'error');
        }
        handleCloseModalConfirm();

    }

    const handlePostCreateAtsTasks = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyAts.createAtsTasks({ id_ats, id_users: idUserSelected, steps: stepsTasks, dangers: dangersTasks, risks: risksTasks, measures: measuresTasks });
        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            await  handleAtsTasks();
            handleCloseModalCreateTasks();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleUpdateAtsTasks = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyAts.updateAtsTasks({ id_ats_tasks: idTasks,id_users:idUserSelected ,steps: stepsTasks, dangers: dangersTasks, risks: risksTasks, measures: measuresTasks });
        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            await handleAtsTasks();
            handleCloseModalEditTasks();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };


    const handleDeleteAtsTasks = async () => {
        handleOpenLoading();
        const response = await MyAts.tasksAtsDelete({ id_ats_tasks: idAction });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            await handleAtsTasks();
            handleOpenAlert(response.msg, 'success');
            handleCloseModalConfirm();
        }
        return response;
    };

    const [openModalEpps, setOpenModalEpps] = useState(false);
    const [rowsEpps, setRowsEpps] = useState([]);

    const handleOpenModalEpps = () => {
        handleAtsNoEpps();
        setOpenModalEpps(true);
    }

    const handleCloseModalEpps = () => {
        setOpenModalEpps(false);
    }

    const handleSelectEpps = async (idEpps) => {
        handleOpenLoading();
        const response = await MyAts.eppsStore({ id_ats, id_epps: idEpps });

        if (response.success !== false) {
            setRowsEpps([]);
            setOpenModalEpps(false);
            handleAtsNoEpps();
            handleAtsEpps();
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert('Hubo un error', 'error');
        }
        return response;
    }

    const handleGeneratePDF = async (event) => {
        try {
            const response = await MyAts.generatePDF({ id_ats });
            const blob = await response.blob();

            // Crear un enlace temporal para descargar el archivo
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ats-${id_ats}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch {
            handleOpenAlert('Hubo un error con la generacion del pdf', 'error');
        }
    };

    return (
        <>
            <Box sx={{ height: "100%" }}>
                <ReturnBack returnBack='/ats?idTabs=1'/>

                <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

                <SearchEppsControla openModalEpps={openModalEpps} handleCloseModalEpps={handleCloseModalEpps} handleClickSelectEpps={handleSelectEpps} rowsEpps={rowsEpps} />

                <DialogConfirmControla openModalConfirm={openModalConfirm} handleCloseModalConfirm={handleCloseModalConfirm} titleText={titleText} contentText={contentText} buttonTextConfirm={buttonTextConfirm} handleActionConfirm={handleActionConfirm} />

                <Container sx={{ paddingBottom: 4 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                            <Grid item md={10}>
                                <SubtitleText text={"Procedimientos"} color={grey}></SubtitleText>
                                <TitleText text={title} />
                            </Grid>

                            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                                <CompanyLogoControla />
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={9}>
                                <InputControlaVertical
                                 roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                 text={'Nombre de la tarea o trabajo:'} 
                                 inputType={'text'} 
                                 inputValue={setName} 
                                 modalType={false} 
                                 value={name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2} xl={3}>
                                <InputControlaVertical
                                 roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                 text={'Código'} 
                                 inputType={'text'} 
                                 inputValue={setCode} 
                                 modalType={false} 
                                 value={code}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2} xl={3}>
                                <InputControlaVertical
                                 roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                 text={'Versión'} 
                                 inputType={'text'} 
                                 inputValue={setVersion} 
                                 modalType={false} 
                                 value={version}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2} xl={3}>
                                <InputControlaVertical
                                 roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                 text={'Fecha'} 
                                 inputType={'date'} 
                                 inputValue={setCreateDate} 
                                 modalType={false} 
                                 value={createDate}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <SelectControla
                                    roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                    text={"Área: "}
                                    inputValue={setAreaSelected}
                                    value={areaSelected}
                                    childrenRows={areas.map((a) => (
                                        <MenuItem key={a.id_areas} value={a.id_areas}>
                                            {a.area}
                                        </MenuItem>
                                    ))}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical
                                 roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                 text={'Lugar:'}
                                 inputType={'text'}
                                 inputValue={setPlace}
                                 modalType={false}
                                 value={place}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical
                                 roles={permissions.DOC_HG_PROC_ATS_ACTI_HORA_DE_INICIO}
                                 text={'Hora de Inicio:'}
                                 inputType={'time'}
                                 inputValue={setHourStart}
                                 modalType={false}
                                 value={hourStart}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical
                                 roles={permissions.DOC_HG_PROC_ATS_ACTI_HORA_DE_FIN}
                                 text={'Hora de Fin:'}
                                 inputType={'time'}
                                 inputValue={setHourFinish}
                                 modalType={false}
                                 value={hourFinish}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                <SearchButtonControla
                                 roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                 titleLabel="Supervisor responsable del trabajo: "
                                 inputValue={workSupervisor}
                                 functionClick={() => handleOpenModalCreateSupervisor("workSupervisor")}
                                 disabledButton={firmWorkSupervisor != null ? true : false}
                                 required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                <SearchButtonControla
                                 roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                 titleLabel="Lider del equipo de trabajo: "
                                 inputValue={workTeamLeader}
                                 functionClick={() => handleOpenModalCreateSupervisor("WorkTeamLeader")}
                                 required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                <SearchButtonControla
                                 roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                 titleLabel="Supervisor de área: "
                                 inputValue={areaSupervisor}
                                 functionClick={() => handleOpenModalCreateSupervisor("areaSupervisor")}  
                                 disabledButton={firmAreaSupervisor != null ? true : false}
                                 required
                                />
                            </Grid>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                <StyledTypography sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>Tareas</StyledTypography>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                {
                                    !readOnly && (
                                        <ButtonControla
                                            roles={permissions.DOC_HG_PROC_ATS_ACTI_AGREGAR_TAREAS}
                                            iconButton={<Add sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Agregar tarea"}
                                            functionOnClick={handleOpenModalCreateTasks}
                                        />
                                    )
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Paper sx={{ overflow: 'hidden' }}>
                                    <TableContainer sx={{ minWidth: 650 }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ color: blue }} align="center">Nombre</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Pasos de la tarea</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Peligros</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Riesgos potenciales</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Medidas preventivas</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowsTasks
                                                    .map((rowsTasks) => (
                                                        <TableRow hover key={rowsTasks.id_ats_tasks} >
                                                            <TableCell >
                                                                {rowsTasks.first_name + ' ' + rowsTasks.last_name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowsTasks.steps}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowsTasks.dangers}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowsTasks.risks}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowsTasks.measures}
                                                            </TableCell>
                                                            <TableCell align="center" >
                                                                {
                                                                    !readOnly && (
                                                                        <Stack alignItems={'center'} direction={'row'} spacing={1}>

                                                                            <ButtonIconControla
                                                                                roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                                                                icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={"#2D9CDB"}
                                                                                backgroundColorHover={"#33AEF4"} textTooltip={'Editar'}
                                                                                functionOnClick={() => { handleOpenModalEditTasks(rowsTasks.id_ats_tasks,rowsTasks.first_name + ' ' +rowsTasks.last_name,rowsTasks.steps, rowsTasks.dangers, rowsTasks.risks, rowsTasks.measures) }}
                                                                            />

                                                                            <ButtonIconControla
                                                                                roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                                                                icon={<Delete sx={{ color: 'white' }} />} backgroundColor={"#EB5757"}
                                                                                backgroundColorHover={"#FF4040"} textTooltip={'Eliminar'}
                                                                                functionOnClick={() => handleOpenModalConfirm(rowsTasks.id_ats_tasks, 'Eliminar tarea', '¿Estas seguro de eliminar esta tarea?', 'Si, eliminar', 'deleteTasks')} />
                                                                        </Stack>
                                                                    )
                                                                }
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                <StyledTypography sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>EPPS</StyledTypography>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                {readOnly ? " " : (
                                    <ButtonControla
                                        roles={permissions.DOC_HG_PROC_ATS_ACTI_AGREGAR_EPP}
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar EPP"}
                                        functionOnClick={handleOpenModalEpps}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Paper sx={{ overflow: 'hidden' }}>
                                    <Grid container spacing={2} sx={{ width: '100%', margin: 'auto' }} >
                                        {rowsEppsAts
                                            .map((rowsEpps) => (

                                                <Grid item key={rowsEpps.id_epps} xs={12} sm={6} md={4} lg={3} xl={3} sx={{ p: 2 }}>
                                                    <Card sx={{
                                                        display: 'flex', justifyContent: 'space-between', height: 80,
                                                    }} >
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                                <Typography sx={{ fontSize: '14px' }}>
                                                                    {rowsEpps.epp}
                                                                </Typography>
                                                            </CardContent>
                                                        </Box>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ maxWidth: 80, marginLeft: 0, maxHeight: 80, objectFit: 'contain' }}
                                                            image={rowsEpps.photo_url}
                                                            alt="Live from space album cover"
                                                        />
                                                    </Card>

                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                <StyledTypography sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>Personal Ejecutor</StyledTypography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                {
                                    readOnly ? "" : (
                                        <ButtonControla
                                            roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                            iconButton={<Add sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Agregar personal ejecutor"}
                                            functionOnClick={handleOpenModalCreateExecutor}
                                        />
                                    )
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Paper sx={{ overflow: 'hidden' }}>
                                    <TableContainer sx={{ minWidth: 650 }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ color: blue }} align="center">Nombres y apellidos</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Firma</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowsExecutor
                                                    .map((executor) => (
                                                        <TableRow hover key={executor.id_users} >
                                                            <TableCell >
                                                                {executor.first_name + " " + executor.last_name}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Stack alignItems={'center'}>
                                                                    <ButtonSignatureControla
                                                                       idUser={executor.id_users}
                                                                       firmStatus={ executor.firm_start}
                                                                       firmUrl={executor.firm_url}
                                                                       functionOnClick={() => { handleFirmExecutor(executor.id_ats_responsible) }}
                                                                    />
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align="end" >
                                                                {
                                                                    readOnly ? "" : (
                                                                        <Stack alignItems={'end'} direction={'row'} spacing={1}>
                                                                            <ButtonIconControla
                                                                                roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                                                                icon={<Delete sx={{ color: 'white' }} />} backgroundColor={"#EB5757"}
                                                                                backgroundColorHover={"#FF4040"} textTooltip={'Eliminar'}
                                                                                functionOnClick={() => {
                                                                                    handleOpenModalConfirm(
                                                                                        executor.id_ats_responsible,
                                                                                        "Eliminar Personal Ejecutor",
                                                                                        "¿ Éstas seguro de eliminar a este personal ejecutor ?",
                                                                                        "Si, eliminar",
                                                                                        "deleteExecutor"
                                                                                    )
                                                                                }} />
                                                                        </Stack>
                                                                    )
                                                                }
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>




                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                 roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                 text={'Herramientas, equipo y material:'}
                                 inputType={'textArea'}
                                 inputValue={setTools}
                                 modalType={false}
                                 value={tools}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                 roles={permissions.DOC_HG_PROC_ATS_MOD_EDITAR}
                                 text={'Observaciones:'}
                                 inputType={'textArea'}
                                 inputValue={setObservation}
                                 modalType={false}
                                 value={observation}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Supervisor Responsable del Trabajo:"}
                                    inputType={"text"}
                                    inputValue={setWorkSupervisor}
                                    value={workSupervisor}
                                    modalType={false}
                                    readOnly
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                                <InputControlaVertical
                                    text={"Fecha de Firma:"}
                                    inputType={"date"}
                                    inputValue={setDateFirmWorkSupervisor}
                                    value={dateFirmWorkSupervisor}
                                    modalType={false}
                                    readOnly
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} lg={1} xl={1}
                                sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center" 
                                }}
                            > 
                                <Stack>
                                    <Box>
                                        <InputLabel
                                            sx={{
                                                padding: "8px",
                                                fontSize: "18px",
                                                color: "#305AD9",
                                                fontWeight: "bold",
                                                textAlign: "center"
                                            }}
                                        >
                                            Firma
                                        </InputLabel>
                                    </Box>

                                    <Box sx={{ textAlign: "center" }}>
                                        <ButtonSignatureControla
                                            idUser={idWorkSupervisor}
                                            firmStatus={firmWorkSupervisor}
                                            firmUrl={firmUrlWorkSupervisor}
                                            functionOnClick={handlePostFirmWorkSupervisor}
                                        />
                                    </Box>
                                </Stack>
                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Supervisor de área:"}
                                    inputType={"text"}
                                    inputValue={setAreaSupervisor}
                                    value={areaSupervisor}
                                    modalType={false}
                                    readOnly
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                                <InputControlaVertical
                                    text={"Fecha de Firma:"}
                                    inputType={"date"}
                                    inputValue={setDateFirmAreaSupervisor}
                                    value={dateFirmAreaSupervisor}
                                    modalType={false}
                                    readOnly
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} lg={1} xl={1}
                                sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent:"center" 
                                }}
                            >
                                <Stack>
                                    <Box>
                                        <InputLabel
                                            sx={{
                                                padding: "8px",
                                                fontSize: "18px",
                                                color: "#305AD9",
                                                fontWeight: "bold",
                                                textAlign: "center"
                                            }}
                                        >
                                            Firma
                                        </InputLabel>
                                    </Box>

                                    <Box sx={{ textAlign: "center" }}>
                                        <ButtonSignatureControla
                                            idUser={idAreaSupervisor}
                                            firmStatus={firmAreaSupervisor}
                                            firmUrl={firmUrlAreaSupervisor}
                                            functionOnClick={handlePostFirmAreaSupervisor}
                                        />
                                    </Box>
                                </Stack>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: 4 }}>
                                <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                                    <Box>
                                        <ButtonControla
                                            backgroundColor={'#CBCBFF'}
                                            textButton={'Regresar'}
                                            backgroundColorHover={'#CBCBFF'}
                                            typeButton={'button'}
                                            isNavegationOrigin={true}
                                            url={'/ats?idTabs=1'}
                                        />
                                    </Box>
                                    <Box>
                                        {idStatuses == 3 &&
                                            <ButtonControla
                                                iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                                                backgroundColor={"#F2994A"}
                                                backgroundColorHover={"#FF881E"}
                                                textButton={"Ver documento generado"}
                                                typeButton={'button'}
                                                functionOnClick={handleGeneratePDF}
                                                target='__blank'
                                            />
                                        }
                                    </Box>
                                    <Box>
                                        {readOnly ? "" : (
                                            <ButtonControla
                                                roles={permissions.DOC_HG_PROC_ATS_ACTI_ACTUALIZAR}
                                                iconButton={<EditIcon sx={{ color: "white" }} />}
                                                backgroundColor={"#169073"}
                                                backgroundColorHover={"#1BAA88"}
                                                textButton={"Actualizar ATS"}
                                                typeButton={'button'}
                                                functionOnClick={handlePutUpdateAts}
                                            />
                                        )
                                        }
                                    </Box>
                                </Stack>
                            </Grid>

                        </Grid>


                        <Dialog
                            open={openModalCreateTasks}
                            onClose={handleCloseModalCreateTasks}
                            maxWidth="md"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Agregar Tarea' functionOnClose={handleCloseModalCreateTasks} />
                            <form onSubmit={handlePostCreateAtsTasks}>
                                <DialogContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                            <SearchButtonControla titleLabel={'Usuario:'} inputValue={dataUserSelected} functionClick={handleOpenModalSelectUser} modalType={true} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Pasos de la tarea:'} inputType={'textArea'} inputValue={setStepsTasks} modalType={true} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Peligros:'} inputType={'textArea'} inputValue={setDangersTasks} modalType={true} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Riesgos potenciales:'} inputType={'textArea'} inputValue={setRisksTasks} modalType={true} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Medidas preventivas:'} inputType={'textArea'} inputValue={setMeasuresTasks} modalType={true} required />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Stack direction={'row'} spacing={2}>
                                        <Box>
                                            <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalCreateTasks} />
                                        </Box>
                                        <Box>
                                            <ButtonControla iconButton={<Add />} backgroundColor={'#169073'} textButton={'Agregar'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                        </Box>
                                    </Stack>
                                </DialogActions>
                            </form>
                        </Dialog>

                        <Dialog
                            open={openModalEditTasks}
                            onClose={handleCloseModalEditTasks}
                            maxWidth="md"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Editar Tarea' functionOnClose={handleCloseModalEditTasks} />
                            <form onSubmit={handleUpdateAtsTasks}>
                                <DialogContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                            <SearchButtonControla titleLabel={'Usuario:'} inputValue={dataUserSelected}  functionClick={handleOpenModalSelectUser} modalType={true} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Pasos de la tarea:'} inputType={'textArea'} inputValue={setStepsTasks} modalType={true} value={stepsTasks} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Peligros:'} inputType={'textArea'} inputValue={setDangersTasks} modalType={true} value={dangersTasks} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Riesgos potenciales:'} inputType={'textArea'} inputValue={setRisksTasks} modalType={true} value={risksTasks} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Medidas preventivas:'} inputType={'textArea'} inputValue={setMeasuresTasks} modalType={true} value={measuresTasks} required />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Stack direction={'row'} spacing={2}>
                                        <Box>
                                            <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalEditTasks} />
                                        </Box>
                                        <Box>
                                            <ButtonControla iconButton={<Add />} backgroundColor={'#169073'} textButton={'Actualizar'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                        </Box>
                                    </Stack>
                                </DialogActions>
                            </form>
                        </Dialog>

                        <Dialog
                            open={openModalSelectUser}
                            onClose={handleCloseModalSelectUser}
                            maxWidth="sm"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Buscar Usuario' functionOnClose={handleCloseModalSelectUser} />
                            <DialogContent >
                                <SearchUsersControla rowsUser={rowsUsersNoTasks} handleUsersActionButton={handleSelectUser} />
                            </DialogContent>
                            <DialogActions>
                                <Stack direction={'row'} spacing={2}>
                                    <Box>
                                        <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalSelectUser} />
                                    </Box>
                                </Stack>
                            </DialogActions>
                        </Dialog>

                        {/* buscar supervisor responsable */}
                        <Dialog
                            open={openModalCreateSupervisor}
                            onClose={handleCloseModalCreateSupervisor}
                            maxWidth="sm"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText={titleModalCreateSupervisor} functionOnClose={handleCloseModalCreateSupervisor} />
                            <DialogContent>
                                <SearchUsersControla rowsUser={rowUsers} handleUsersActionButton={handleGetUserSupervisor} />

                            </DialogContent>
                            <DialogActions>
                                <Stack direction={'row'} spacing={2}>
                                    <Box>
                                        <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalCreateSupervisor} />
                                    </Box>
                                </Stack>
                            </DialogActions>

                        </Dialog>

                        {/* agregar personal ejecutor */}
                        <Dialog
                            open={openModalCreateExecutor}
                            onClose={handleCloseModalCreateExecutor}
                            maxWidth="sm"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText={"Agregar personal ejecutor"} functionOnClose={handleCloseModalCreateExecutor} />
                            <DialogContent>
                                <SearchUsersControla rowsUser={rowUsers} handleUsersActionButton={handlePostExecutor} />

                            </DialogContent>
                            <DialogActions>
                                <Stack direction={'row'} spacing={2}>
                                    <Box>
                                        <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalCreateExecutor} />
                                    </Box>
                                </Stack>
                            </DialogActions>
                        </Dialog>

                    </Box>
                </Container>
            </Box >
        </>
    )
}

export default Edit