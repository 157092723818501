import MyConfigurationApi from "../MyConfigurationApi";

function storeContractorsRelationsEmployees(data) {
    const url = 'contractors-relations-employees';
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: 'POST',
        url: url
    });
}

function deleteRelationEmployee(id) {
    const url = 'contractors-relations-employees/' + id;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: 'DELETE',
        url: url
    });
}

const MyContractorsRelationEmployees = {
    storeContractorsRelationsEmployees,
    deleteRelationEmployee
};

export default MyContractorsRelationEmployees;