import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import {
    Visibility,
    VisibilityOutlined,
  } from "@mui/icons-material";

import { useAppContext } from "../../context/AppContext";

function ListArchived(props) {
    const {permissions} = useAppContext();

    const {
        baselineArchivedRows,
        colorCell
    } = props
    return (
        <TableBody
            // sx={{ display: documentType !== 2 ? "none" : "" }}
        >
            {baselineArchivedRows.map((rowArchived) => (
                <TableRow
                    hover
                    key={rowArchived.id_baseline}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell component="th" scope="row" sx={{ color: colorCell }}>
                        {rowArchived.name}
                    </TableCell>
                    <TableCell align="center" sx={{ color: colorCell }}>
                        {rowArchived.document_url !== null
                            ? "ARCHIVO"
                            : "SISTEMA"}
                    </TableCell>
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {/* <ButtonIconControla
                                icon={
                                  <BorderColorOutlined
                                    sx={{ color: "white" }}
                                  />
                                }
                                backgroundColor={"#2D9CDB"}
                                textTooltip={"Editar"}
                                functionOnClick={() =>
                                  (window.location.href =
                                    "" + rowArchived.id_baseline)
                                }
                              /> */}
                            {rowArchived.document_url !== null ? (
                                <ButtonIconControla
                                    roles={permissions.LINE_DIAG_ARCHI_VER}
                                    icon={
                                        <VisibilityOutlined
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#305AD9"}
                                    backgroundColorHover={"#0E4AFF"}
                                    textTooltip={"Ver"}
                                    directionUrl={
                                        rowArchived.document_url
                                    }
                                    directionTarget={"_blank"}
                                />
                            ) : (
                                <ButtonIconControla
                                    roles={permissions.LINE_DIAG_ARCHI_VER}
                                    icon={<Visibility sx={{ color: "white" }} />}
                                    backgroundColor={"#305AD9"}
                                    textTooltip={"Ver"}
                                    isNavegationOrigin={true}
                                    directionUrl={"/baseline/show/results?id_baseline=" +
                                    rowArchived.id_baseline}
                                />
                            )}

                            {/* <ButtonIconControla
                                icon={<Download sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                textTooltip={"Descargar"}
                                functionOnClick={() =>
                                (window.location.href =
                                  "" + rowArchived.id_baseline)
                                }
                              /> */}
                            {/* 
                              <ButtonIconControla
                                icon={<Print sx={{ color: "white" }} />}
                                backgroundColor={"#F2994A"}
                                textTooltip={"Imprimir"}
                                functionOnClick={() =>
                                  (window.location.href =
                                    "" + rowArchived.id_baseline)
                                }
                              /> */}

                            {/* <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() =>
                                  (window.location.href =
                                    "" + rowArchived.id_baseline)
                                }
                              /> */}
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>

    )

}
export default ListArchived;