import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import {
    BorderColorOutlined,
    Delete,
    Archive,
    FileUploadOutlined,
} from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useAppContext } from "../../../context/AppContext";

function ListActives(props) {
    const { permissions } = useAppContext();

    const {
        rowsAnnualP,
        colorCell,
        handleOpenModalUploadFileEdit,
        handleOpenModalConfirm
    } = props
    return (
        <TableBody >
            {rowsAnnualP.map((row) =>
                (row.register_status != 5 &&
                    row.document_url != null) ||
                    (row.register_status == 4 &&
                        row.document_url == null) ? (
                    <TableRow
                        hover
                        key={row.id}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <TableCell sx={{ color: colorCell }}>
                            {row.title}
                        </TableCell>
                        <TableCell sx={{ color: colorCell }}>
                            {row.register_status === 1
                                ? "Creado"
                                : "Publicado"}
                        </TableCell>
                        <TableCell sx={{ color: colorCell }}>
                            <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                            >
                                {row.register_status === 1 && (
                                    <ButtonIconControla
                                        roles={permissions.DOC_PLAN_ANUAL_SSO_ACTIVOS_EDIT}
                                        icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#2D9CDB"}
                                        backgroundColorHover={"#33AEF4"}
                                        textTooltip={"Editar"}
                                        // el editar de activos debe enviar a un modal
                                        functionOnClick={() => {
                                            handleOpenModalUploadFileEdit(row.id);
                                        }}
                                    />
                                )}

                                {row.register_status === 1 && (
                                    <ButtonIconControla
                                        roles={permissions.DOC_PLAN_ANUAL_SSO_ACTIVOS_PUBLIC}
                                        icon={<FileUploadOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1AA483"}
                                        textTooltip={"Publicar"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                row.id,
                                                "¿Estás seguro de publicar?",
                                                "",
                                                "Si, publicar",
                                                "publish"
                                            );
                                        }}
                                    />
                                )}

                                {row.hasOwnProperty("document_url") ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_PLAN_ANUAL_SSO_ACTIVOS_VER}
                                        icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        directionUrl={row.document_url}
                                        directionTarget={"_blank"}
                                    />
                                )
                                    : row.register_status !== 1 ? (
                                        <ButtonIconControla
                                            roles={permissions.DOC_PLAN_ANUAL_SSO_ACTIVOS_VER}
                                            icon={<VisibilityOutlined sx={{ color: "white" }} />}
                                            backgroundColor={"#305AD9"}
                                            backgroundColorHover={"#0E4AFF"}
                                            textTooltip={"Ver"}
                                            isNavegationOrigin={true}
                                            directionUrl={"/documentation/annualplan/template?idAnnualPlan=" + row.id + "&readOnly=true"}
                                        />
                                    ) : ("")}

                                {row.register_status === 3 && (
                                    <ButtonIconControla
                                        roles={permissions.DOC_PLAN_ANUAL_SSO_ACTIVOS_ARCHIVAR}
                                        icon={<Archive sx={{ color: "white" }} />}
                                        backgroundColor={"#F2994A"}
                                        backgroundColorHover={"#FF881E"}
                                        textTooltip={"Archivar"}
                                    />
                                )}
                                <ButtonIconControla
                                    roles={permissions.DOC_PLAN_ANUAL_SSO_ACTIVOS_ARCHIVAR}
                                    icon={<Archive sx={{ color: "white" }} />}
                                    backgroundColor={"#F2994A"}
                                    backgroundColorHover={"#FF881E"}
                                    textTooltip={"Archivar"}
                                    functionOnClick={() =>
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de archivar el documento?",
                                            "",
                                            "Si, archivar",
                                            "archived"
                                        )
                                    }
                                />
                                {row.register_status == 1 && (
                                    <ButtonIconControla
                                        roles={permissions.DOC_PLAN_ANUAL_SSO_ACTIVOS_ELIMINAR}
                                        icon={<Delete sx={{ color: "white" }} />}
                                        backgroundColor={"#EB5757"}
                                        backgroundColorHover={"#FF4040"}
                                        textTooltip={"Eliminar"}
                                        functionOnClick={() => {
                                            handleOpenModalConfirm(
                                                row.id,
                                                "¿Estás seguro de eliminar el documento?",
                                                "",
                                                "Si, Eliminar",
                                                "delete_template"
                                            );
                                        }}
                                    />
                                )}
                            </Stack>
                        </TableCell>
                    </TableRow>
                ) : (""))}
        </TableBody>
    )
}
export default ListActives;