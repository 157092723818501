import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import {  Add, ArrowBack } from "@mui/icons-material";
import Container from "@mui/material/Container";

import LoadingControla from "../../components/load/loadingControla";
import SubtitleText from "../../components/text/subtitleText";
import ButtonControla from "../../components/buttons/buttonController";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import TitleText from "../../components/text/titleText";
import MyAnnualPlan from "../../api/MyAnnualPlan";
import SearchButtonControla from "../../components/search/searchButtonControla";
import MyUsers from "../../api/MyUsers";
import SearchUsersControla from "../../components/search/searchUsersControla";
import { EditorAnnualPlanControla } from "../../components/editor/editorAnnualPlanControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import ButtonSignatureControla from "../../components/buttons/buttonSignatureControla";
import ReturnBack from "../../components/buttons/returnBackControla";

const colorTitle = "#9191B0";

function Template() {

  const {permissions} = useAppContext();
  const navigate = useNavigate(); 

  const params = new URLSearchParams(window.location.search);
  const idAnnualPlan = params.get("idAnnualPlan");
  const readOnly = params.get("readOnly") == "true" ? true : false;

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  const [usersActives, setUsersActives] = useState([]);
  const handleGetUsersActives = async () => {
    const response = await MyUsers.users();
    if (response.success === false) {
      alert("Error");
    } else {
      setUsersActives(response.users_actives);
    }
  };


  const [version, setVersion] = useState(null);
  const [title, setTitle] = useState(null);
  const [documentText, setDocumentText] = useState(null);
  const [code, setCode] = useState(null);
  const [develop, setDevelop] = useState("");
  const [dateDevelop, setDateDevelop] = useState("");
  const [revised, setRevised] = useState("");
  const [dateRevised, setDateRevised] = useState("");
  const [approved, setApproved] = useState("");
  const [dateApproved, setDateApproved] = useState("");

  const [developUrlFirm, setDevelopUrlFirm] = useState("");
  const [revisedUrlFirm, setRevisedUrlFirm] = useState("");
  const [approvedUrlFirm, setApprovedUrlFirm] = useState("");
  const [developSign, setDevelopSign] = useState("");
  const [revisedSign, setRevisedSign] = useState("");
  const [approvedSign, setApprovedSign] = useState("");

  const templateText = (textdata) => {
    setDocumentText(textdata);
  };
  // jalar y mostrar datos del usuario preparado
  const [idUsersDevelop, setIdUsersDevelop] = useState("");
  const [openModalDevelop, setOpenModalDevelop] = useState(false);
  const handleOpenModalDevelop = () => {
    setOpenModalDevelop(true);
  };

  const handleGetUsersDevelop = async (idUser) => {
    handleOpenLoading();
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setDevelop(response.first_name + " " + response.last_name);
      setIdUsersDevelop(response.id_users);
      setDevelopUrlFirm(response.firm_url);
      setOpenModalDevelop(false);
      handleOpenAlert("", "success");
    }
  };

  const handleDevelopSignature = async() => {
    handleOpenLoading();
    if(idAnnualPlan == null){
      setDevelopSign(1);
      handleOpenAlert("Se firmó con éxito", "success");
    }else{
      try{
        await MyAnnualPlan.signDevelop({idAnnualPlanTemplate : idAnnualPlan});
        await handleEditAnnualPlanTemplate();
        handleOpenAlert("Se firmó con éxito", "success");
      }catch(e){
        handleOpenAlert("Ocurrió un error al firmar", "error");
      }
    }
  }

  // jalar y mostrar datos del usuario revisado
  const [idUsersRevised, setIdUsersRevised] = useState("");
  const [openModalRevised, setOpenModalRevised] = useState(false);
  const handleOpenModalRevised = () => {
    setOpenModalRevised(true);
  };

  const handleGetUsersRevised = async (idUser) => {
    handleOpenLoading();

    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setRevised(response.first_name + " " + response.last_name);
      setIdUsersRevised(response.id_users);
      setRevisedUrlFirm(response.firm_url);
      setOpenModalRevised(false);
      handleOpenAlert("", "success");
    }
  };

  const handleRevisedSignature = async () => {
    handleOpenLoading();
    if(idAnnualPlan == null){
      setRevisedSign(1);
      handleOpenAlert("Se firmó con éxito", "success");
    }else{
      try{
        await MyAnnualPlan.signRevised({idAnnualPlanTemplate: idAnnualPlan, dateRevised: dateRevised });
        await handleEditAnnualPlanTemplate();
        handleOpenAlert("Se firmó con éxito", "success");
      }catch(e){
        handleOpenAlert("Ocurrió un error al firmar", "error");
      }
    }
  }

  // jalar y mostrar datos del usuario revisado
  const [idUsersApproved, setIdUsersApproved] = useState("");
  const [openModalApproved, setOpenModalApproved] = useState(false);
  const handleOpenModalApproved = () => {
    setOpenModalApproved(true);
  };

  const handleGetUsersApproved = async (idUser) => {
    handleOpenLoading();

    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setApproved(response.first_name + " " + response.last_name);
      setIdUsersApproved(response.id_users);
      setApprovedUrlFirm(response.firm_url);
      setOpenModalApproved(false);
      handleOpenAlert("", "success");
    }
  };

  const handleApprovedSignature = async() => {
    handleOpenLoading();
    if(idAnnualPlan == null){
      setApprovedSign(1);
      handleOpenAlert("Se firmó con éxito", "success");
    }else{
      try{
        await MyAnnualPlan.signApproved({idAnnualPlanTemplate: idAnnualPlan, dateApproved: dateApproved })
        await handleEditAnnualPlanTemplate();
        handleOpenAlert("Se firmó con éxito", "success")
      }catch(e){
        handleOpenAlert("Ocurrió un error al firmar", "error");
      }
    }
  }

  //   crear
  const handleCreateAnnualPlanTemplate = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyAnnualPlan.createAnnualPlanTemplate({
      code: code,
      develop: develop,
      revised: revised,
      approved: approved,
      version: version,
      title: title,
      document_text: documentText,
      idUsersDevelop,
      idUsersRevised,
      idUsersApproved,
      dateDevelop,
      dateRevised,
      dateApproved,
      //
      developUrlFirm,
      revisedUrlFirm,
      approvedUrlFirm,
      developSign,
      revisedSign,
      approvedSign
    });
    if (response.success !== false) {
      handleOpenAlert("El documento se ha creado con éxito", "success");
      navigate("/documentation/AnnualPlan?idTabs=0");
    } else {
      handleOpenAlert("Ocurrió un error al crear el archivo", "error");
    }
    return response;
  };

  // Editar temlplate
  // editar annual plan document

  const handleEditAnnualPlanTemplate = async () => {
    handleOpenLoading();

    if (idAnnualPlan != null) {
      const response = await MyAnnualPlan.editAnnualPlanTemplate({
        idAnnualPlanTemplate: idAnnualPlan,
      });
      setDevelop(response.develop);
      setIdUsersDevelop(response.id_users_develop);
      setCode(response.code);
      setApproved(response.approved);
      setIdUsersApproved(response.id_users_approved);
      setDevelop(response.develop);
      setRevised(response.revised);
      setIdUsersRevised(response.id_users_revised);
      setVersion(response.version);
      setTitle(response.title);
      setDocumentText(response.document_text);
      setDateApproved(response.date_approved);
      setDateRevised(response.date_revised);
      setDateDevelop(response.date_develop);
      setRegisterStatus(response.register_status);

      setDevelopUrlFirm(response.develop_url_firm);
      setRevisedUrlFirm(response.revised_url_firm);
      setApprovedUrlFirm(response.approved_url_firm);
      setDevelopSign(response.develop_sign);
      setRevisedSign(response.revised_sign);
      setApprovedSign(response.approved_sign);
    }

    setOpenLoading(false);
  };
  const handleUpdateAnnualPlanTemplate = async () => {
    handleOpenLoading();
    const response = await MyAnnualPlan.updateAnnualPlanTemplate({
      idAnnualPlanTemplate: idAnnualPlan,
      code: code,
      develop: develop,
      idUsersDevelop: idUsersDevelop,
      revised: revised,
      idUsersRevised: idUsersRevised ,
      approved: approved,
      idUsersApproved: idUsersApproved,
      version: version,
      title: title,
      document_text: documentText,
      dateDevelop: dateDevelop,
      dateApproved: dateApproved,
      dateRevised: dateRevised,

      //
      developUrlFirm,
      revisedUrlFirm,
      approvedUrlFirm,
      developSign,
      revisedSign,
      approvedSign
    });
    handleOpenAlert("Los datos fueron editados de forma correcta", "success");
    return response;
  };

  ////Status register

  const [registerStatus, setRegisterStatus] = useState(0);

  const userData = MyConfigurationApi.userData();
  const idUserLogged = userData.id_users;

  // Dialogos de aviso de falta de fecha INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
  }; 
  

  useEffect(() => {
    handleEditAnnualPlanTemplate();
    handleGetUsersActives();
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <ReturnBack/>

      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <Container>
        <form onSubmit={handleCreateAnnualPlanTemplate}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Plan anual"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={
                  registerStatus === 0
                    ? "Plantilla Plan Anual"
                    : "Documento Plan Anual"
                }
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <InputControlaVertical
                roles={permissions.DOC_PLAN_ANUAL_SSO_EDIT}
                text={"Código:"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <InputControlaVertical
                roles={permissions.DOC_PLAN_ANUAL_SSO_EDIT}
                text={"Versión:"}
                inputType={"text"}
                inputValue={setVersion}
                value={version}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                roles={permissions.DOC_PLAN_ANUAL_SSO_EDIT}
                text={"Título documento:"}
                inputType={"text"}
                inputValue={setTitle}
                value={title}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <SearchButtonControla
                roles={permissions.DOC_PLAN_ANUAL_SSO_EDIT}
                titleLabel="Elaborado por: "
                inputValue={develop}
                functionClick={handleOpenModalDevelop}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"Fecha de Elaboración:"}
                inputType={"date"}
                inputValue={setDateDevelop}
                value={dateDevelop}
                required
                readOnly={readOnly? true:(idUsersDevelop == idUserLogged ? false : true)}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Stack>
                <Box>
                  <InputLabel
                    sx={{
                      padding: "8px",
                      fontSize: "18px",
                      color: "#305AD9",
                      fontWeight: "bold",
                      textAlign: "center"
                    }}
                  >
                    Firma
                  </InputLabel>
                </Box>

                <Box sx={{textAlign: "center"}}>
                  <ButtonSignatureControla
                      idUser={idUsersDevelop}
                      firmStatus={developSign}
                      firmUrl={developUrlFirm}
                      functionOnClick={() => handleDevelopSignature()}
                  />
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <SearchButtonControla
                roles={permissions.DOC_PLAN_ANUAL_SSO_EDIT}
                titleLabel="Revisado por: "
                inputValue={revised}
                functionClick={handleOpenModalRevised}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"Fecha de Revisión:"}
                inputType={"date"}
                inputValue={setDateRevised}
                value={dateRevised}
                modalType={false}
                readOnly={readOnly? true:(idUsersRevised == idUserLogged ? false : true)}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Stack>
                <Box>
                  <InputLabel
                    sx={{
                      padding: "8px",
                      fontSize: "18px",
                      color: "#305AD9",
                      fontWeight: "bold",
                      textAlign: "center"
                    }}
                  >
                    Firma
                  </InputLabel>
                </Box>

                <Box sx={{textAlign: "center"}}>
                  <ButtonSignatureControla
                      idUser={idUsersRevised}
                      firmStatus={revisedSign}
                      firmUrl={revisedUrlFirm}
                      functionOnClick={
                        dateRevised == ""
                        ? () => handleOpenModalConfirm()
                        : () => handleRevisedSignature()
                      }
                  />
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <SearchButtonControla
                roles={permissions.DOC_PLAN_ANUAL_SSO_EDIT}
                titleLabel="Aprobado por: "
                inputValue={approved}
                functionClick={handleOpenModalApproved}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"Fecha de Aprobación:"}
                inputType={"date"}
                inputValue={setDateApproved}
                value={dateApproved}
                modalType={false}
                readOnly={readOnly? true:(idUsersApproved == idUserLogged ? false : true)}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Stack>
                <Box>
                  <InputLabel
                   sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                  >
                    Firma
                  </InputLabel>
                </Box>

                <Box sx={{textAlign: "center"}}>
                  <ButtonSignatureControla
                     idUser={idUsersApproved}
                     firmStatus={approvedSign}
                     firmUrl={approvedUrlFirm}
                     functionOnClick={
                      dateApproved == ""
                        ? () => handleOpenModalConfirm()
                        : () => handleApprovedSignature()
                     }
                  />
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EditorAnnualPlanControla
                roles={permissions.DOC_PLAN_ANUAL_SSO_EDIT}
                dataEdit={documentText}
                handleDataEditor={templateText}
              />
            </Grid>
          </Grid>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"center"}
            mt={2}
            mb={2}
          >
            <Box>
              <ButtonControla
                iconButton={<ArrowBack sx={{ color: "white" }} />}
                backgroundColor={"#C61C39"}
                backgroundColorHover={"#D61F3E"}
                textButton={"Regresar"}
                typeButton={"button"}
                isNavegationOrigin={true}
                url={"/documentation/AnnualPlan"}
              />
            </Box>{" "}
            {idAnnualPlan === null || idAnnualPlan === undefined ? (
              <Box>
                <ButtonControla
                  iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Guardar"}
                  typeButton={"submit"}
                />
              </Box>
            ) : (
              readOnly ? "" : (
                <Box>
                  <ButtonControla
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar"}
                    functionOnClick={() => handleUpdateAnnualPlanTemplate()}
                  />
                </Box>
              )
            )}
          </Stack>
        </form>
      </Container>

      {/* Buscar responsables */}
      {/* Elaborado por */}
      <Dialog
        open={openModalDevelop}
        onClose={() => {
          setOpenModalDevelop(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de elaborar la plantilla Plan Anual
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersDevelop}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalDevelop(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Revisado por */}
      <Dialog
        open={openModalRevised}
        onClose={() => {
          setOpenModalRevised(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Revisar la plantilla Plan Anual
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersRevised}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalRevised(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Revisado por */}
      <Dialog
        open={openModalApproved}
        onClose={() => {
          setOpenModalApproved(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Aprobar la plantilla Plan Anual
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersApproved}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalApproved(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialogo de aviso de asignar fecha */}
      <Dialog
        open={openModalConfirm}
        onClose={() => setOpenModalConfirm(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Para firmar es necesario consignar fecha.
          </Typography>
        </DialogTitle>

        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{
              textTransform: "none",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => setOpenModalConfirm(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Template;
