import {Stack, TableBody,TableRow, TableCell } from "@mui/material";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import { Delete } from "@mui/icons-material";
import Link from '@mui/material/Link';

function ListSuggestions(props) {
    const {
        colorCell,
        suggestions,
        handleOpenModalSeeDetail,
        handleGetSuggestionById,
        handleDeleteSuggestion
    } = props
    return (
        <TableBody>
            {
                suggestions.map((s) => (
                    <TableRow
                        hover
                        key={s.id_suggestions}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <TableCell
                            component="th"
                            scope="row"
                            sx={{ color: colorCell }}
                            align="center"
                        >
                            {s.comment}
                        </TableCell>
                        <TableCell
                            component="th"
                            scope="row"
                            sx={{ color: colorCell }}
                            align="center"
                        >
                            {s.created_at.substr(0, 19)}
                        </TableCell>
                        <TableCell
                            component="th"
                            scope="row"
                            sx={{ color: colorCell }}
                            align="center"
                        >
                            <Link href={s.url}>{s.url}</Link>
                        </TableCell>
                        <TableCell align="right">
                            <Stack
                                direction="row"
                                justifyContent="end"
                                alignContent="center"
                            >
                                <ButtonIconControla
                                    icon={
                                        <VisibilityOutlined
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#305AD9"}
                                    backgroundColorHover={"#0E4AFF"}
                                    textTooltip={"Ver detalle"}
                                    functionOnClick={() => {
                                        handleGetSuggestionById(s.id_suggestions);
                                        handleOpenModalSeeDetail();
                                    }}
                                />
                                <ButtonIconControla
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() => handleDeleteSuggestion(s.id_suggestions)}
                                />
                            </Stack>
                        </TableCell>

                    </TableRow>

                ))
            }

        </TableBody>
    );

}
export default ListSuggestions;